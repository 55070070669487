
import './Modal.scss'
import deleteAccount from '@/Assets/images/deleteAccount.png';
import AppModal from '../Modals/AppModal';
import * as commonServices from "../../services/common";

function ModalRestoreAccount({ showModal, setShowModal, restoreAccount }) {

    return (
        <>
            <AppModal
                title={
                    <div
                        style={{
                            alignItems: "center",
                            justifyContent: "flex-start",
                            display: "flex",
                            gap: "10px",
                            width: "100%",
                            maxWidth: "90vw", // maxW: "90vw"
                            overflowWrap: "break-word",
                        }}
                    >
                        <img
                            style={{ width: "48px", minWidth: "48px" }}
                            onError={commonServices.imgError}
                            src={deleteAccount}
                            alt="Delete Account"
                        />
                        <div
                            style={{
                                maxWidth: "calc(100% - 48px)",
                                display: "flex",
                                flexWrap: "wrap",
                                overflowWrap: "break-word",
                            }}
                        >
                            Account Deleted
                        </div>
                    </div>
                }
                isOpen={showModal}
                closeModal={() => { setShowModal(prev => !prev); }}
                submit={restoreAccount}
                isFooter={true}
                submitText={"Restore"}
                cancelText={"Exit"}
                size={"md"}
            >

                <div className="modal_body">
                    <div className="row info-content">
                        <p>Your account has been deleted, but it can still be restored.</p>
                        <p>Note: After a period of 30 days from the initial request, your account will be permanently deleted. </p>
                    </div>
                </div>
            </AppModal>
        </>
    )
}

export default ModalRestoreAccount