import React from 'react'
import styles from './fourTemplate.module.css'
import { isNotEmpty } from '../../../utils/arrayUtils'
import logo from "@/Assets/images/watermark.png"

import * as commonServices from '@/services/common';
import Progress from '../../_widgets/ProgressBar/ProgressBar'

const FourTemplate = ({ profile, components, downloadType, odHeading, customSections }) => {

  const renderTemplateCap = (label, value) => {
    return <div className={styles.row} >
      <div className={styles.label} dangerouslySetInnerHTML={{ __html: label || '' }}></div>
      <div className={styles.valueCap} >{value}</div>
    </div>
  }
  const renderTemplate = (label, value) => {
    return <div className={styles.row} >
      <div className={styles.label} dangerouslySetInnerHTML={{ __html: label || '' }}></div>
      <div className={styles.value} >{value}</div>
    </div>
  }

  const renderTemplateReference = (label, value) => {
    return <div className={styles.row} >
      <div className={styles.label} ></div>
      <div className={styles.value} dangerouslySetInnerHTML={{ __html: label || '' }}></div>
    </div>
  }

  const renderProgressBar = (label, count) => {
    return <div className='mt-2'>
      <div className={styles.label} dangerouslySetInnerHTML={{ __html: label || '' }}></div>
      <Progress background="grey" height="6px" progress={count} strokeWidth={3} strokeColor="#000" />
    </div>
  }


  return (
    <div className={styles.wrapper} >
      {downloadType === 1 || downloadType === null ?
        <div
          style={{
            position: "absolute",
            justifyContent: "center",
            top: "10px",
            right: "10px",
            display: "flex",
            gap: "5px",
            alignItems: "center"
          }}
        >

          <img height="18px" onError={commonServices.imgError}
            src={logo ?? 'N/A'}
            alt="/Assets/images/watermark.png" />
        </div>
        : null}

      <div className={styles.leftMenu} >
        <div className={styles.secOne} >
          <div className={styles.name} >{profile.first_name} {profile.last_name}</div>
          <div className={styles.des} >{profile?.designation}</div>
        </div>
        <div className={styles.secTwo}>
          <div className={styles.details} >
            {profile?.email ? <h2 className={styles.hightlightTitle}> Personal Details</h2> : null}
            {profile?.contact ? renderTemplate("Phone", profile?.contact) : null}
            {profile?.email ? renderTemplate("Email", profile?.email) : null}
          </div>
        </div>

        {
          components.map((component, index) => {
            switch (component.component) {
              case "websites_social_links": return isNotEmpty(profile?.userWorkProfileDetail?.website) ?
                <div className={styles.secThree} >
                  <h2 className={styles.hightlightTitle} >Links</h2>
                  {
                    profile?.userWorkProfileDetail?.website?.map((item, index) => (
                      renderTemplate(item?.name, item?.url)
                    ))
                  }
                </div>
                : null

              case "skills": return isNotEmpty(profile?.userWorkProfileDetail?.skill) ?
                <div className={styles.secFour} >
                  <h2 className={styles.hightlightTitle} >Skills</h2>
                  {
                    profile?.userWorkProfileDetail?.skill?.map((item, index) =>
                      (renderProgressBar(item?.skill_name, item?.experience >= profile?.user_work_profile?.total_experience ? 100 : (item?.experience / profile.user_work_profile.total_experience) * 100)))
                  }
                </div> : null

              case "hobbies": return <> {customSections.hobbies && profile?.hobbies ?
                <div className={styles.secThree} >
                  <h2 className={styles.hightlightTitle} >Hobbies</h2>
                  {renderTemplate(profile?.hobbies, '')}
                </div> : null} </>

              case "languages": return <> {customSections.languages && isNotEmpty(profile?.userWorkProfileDetail?.language) ?
                <div className={styles.secThree} >
                  <h2 className={styles.hightlightTitle} >Languages</h2>
                  {
                    profile?.userWorkProfileDetail?.language?.map((item, index) => (
                      renderTemplateCap(item?.name, item?.experience)
                    ))
                  }
                </div> : null} </>

              case "references": return <> {customSections.references && profile?.reference ?
                <div className={styles.secThree} >
                  <h2 className={styles.hightlightTitle} >References</h2>
                  {renderTemplateReference(profile?.reference, '')}
                </div> : null} </>

              case "achievements": return <> {customSections.achievements && profile?.achievements ?
                <div className={styles.secThree} >
                  <h2 className={styles.hightlightTitle} >Achievements</h2>
                  {renderTemplate(profile?.achievements, '')}
                </div> : null} </>

              case "details": return <> {customSections.details && profile[odHeading] ?
                <div className={styles.secThree} >
                  <h2 className={styles.hightlightTitle} >{odHeading}</h2>
                  {renderTemplate(profile[odHeading], '')}
                </div> : null} </>

              case "extra": return <> {customSections.extra && profile?.extra_curricular_activity ?
                <div className={styles.secThree} >
                  <h2 className={styles.hightlightTitle} >Extra-Curricular Activities</h2>
                  {renderTemplate(profile?.extra_curricular_activity, '')}
                </div> : null} </>

            }
          })
        }
      </div>
      <div className={styles.rightMenu} >
        {
          components.map((component, index) => {
            switch (component.component) {
              case "professional_summary": return profile?.description ? <div className={styles.profileSec} >
                <h2 className={styles.heading}>Professional Summary</h2>
                <div className={styles.profileP} dangerouslySetInnerHTML={{ __html: profile.description || '' }}></div>
              </div> : null
              case "employment": return isNotEmpty(profile?.userWorkProfileDetail?.experience) ? <div className={styles.expWrapper}>
                <h2 className={styles.heading}>Employment History</h2>
                <div className="expWrp" >
                  {
                    profile?.userWorkProfileDetail?.experience?.map((exp) => {
                      return <div className={styles.exp} >
                        <div className={styles.companyName} >{exp.companyName}</div>
                        <div className={styles.designation} >{exp.designation}</div>
                        <div className={styles.from} >{exp.from}</div>
                        <div className={styles.profileP} dangerouslySetInnerHTML={{ __html: exp?.description || '' }}></div>
                      </div>
                    })
                  }
                </div>
              </div> : null

              case "education": return isNotEmpty(profile?.userWorkProfileDetail?.degree) ? <div className={styles.education} >
                <h2 className={styles.heading}>Education</h2>
                <div className="eduWrp" >
                  {
                    profile?.userWorkProfileDetail?.degree?.map((edu) => {
                      return <div>
                        <div className={styles.companyName} >{edu.awarded_by}</div>
                        <div className={styles.designation} >{edu.title}</div>
                        <div className={styles.from} >{edu.from_date} - {edu?.is_present === "1" ? "Present" : edu?.to_date}</div>
                      </div>
                    })
                  }
                </div>
              </div> : null

              case "courses": return <> {customSections.courses && isNotEmpty(profile?.userWorkProfileDetail?.course) ? <div className={styles.education} >
                <h2 className={styles.heading}>Courses</h2>
                <div className="eduWrp" >
                  {
                    profile?.userWorkProfileDetail?.course?.map((edu) => {
                      return <div>
                        <div className={styles.companyName} >{edu.awarded_by}</div>
                        <div className={styles.designation} >{edu.title}</div>
                        <div className={styles.from} >{edu.from_date} - {edu?.is_present === "1" ? "Present" : edu?.to_date}</div>
                        <div className={styles.profileP} dangerouslySetInnerHTML={{ __html: edu?.description || '' }}></div>
                      </div>
                    })
                  }
                </div>
              </div> : null} </>

              case "internships": return <> {customSections.internships && isNotEmpty(profile?.userWorkProfileDetail?.internship) ? <div className={styles.education} >
                <h2 className={styles.heading}>Internships</h2>
                <div className="eduWrp" >
                  {
                    profile?.userWorkProfileDetail?.internship?.map((edu) => {
                      return <div>
                        <div className={styles.companyName} >{edu.awarded_by}</div>
                        <div className={styles.designation} >{edu.title}</div>
                        <div className={styles.from} >{edu.from_date} - {edu?.is_present === "1" ? "Present" : edu?.to_date}</div>
                        <div className={styles.profileP} dangerouslySetInnerHTML={{ __html: edu?.description || '' }}></div>
                      </div>
                    })
                  }
                </div>
              </div> : null} </>
            }
          })
        }
      </div>
    </div>
  )
}

export default FourTemplate;