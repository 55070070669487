export const analyticsConstant = {
    SYSTEM_UP_TIME: 'SYSTEM UP TIME',
    CANDIDATE_ONBOARDING: 'CANDIDATE_ONBOARDING',
    EVALUATOR_ONBOARDING: 'EVALUATOR_ONBOARDING',
    COMPANY_ONBOARDING: 'COMPANY_ONBOARDING',
    LOGIN: 'LOGIN',
    JOB: 'JOB',
    GIG: 'GIG',
    API_TIME: 'API_TIME',
    STEP1: 'PERSONAL DETAILS',
    STEP2: 'EMAIL OTP',
    STEP3: 'MOBILE OTP',
    STEP4: 'COMPLETE PROFILE',
    VALIDATION_ERROR: 'VALIDATION_ERROR',
    VALIDATION_FAILED: 'VALIDATION_FAILED',
    FAILED: 'FAILED',
    COMPLETE: 'COMPLETE',
    VIEW: 'VIEW',
    APPLY: 'APPLY',
}