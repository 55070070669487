/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
import "./Steps.scss";
import emailImg from "../../../../Assets/images/sendOtpImg.png";
import * as ApiHelper from "../../../../services/apiHelper";
import * as toast from "../../../../wrapper/toast"
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";
import * as errors from '../../../../constants/stringConstants';
import { updateHeaderMessageIcon } from '../../../../utils/messageUtil';
import { READ, UNREAD } from '../../../../constants/messageConstants';
import * as storageConstants from "../../../../constants/storageConstants";
import * as commonServices from "../../../../services/common";
import { initiateFirebase, handleVerifyOTP, handleSendOTP } from "../../../../config/firebase";
import { OTP_SENT_SUCCESS, OTP_SENT_FAILURE } from "../../../../constants/stringConstants";
import { trackCandidateOnboarding, trackEvaluatorOnboarding } from "../../../../helpers/analytics";
import { log } from "@craco/craco/lib/logger";
import { analyticsConstant } from "../../../../constants/analyticsConstant";
import { isValidNumber } from "../../../../utils/validation";
import Button from "../../../_widgets/Button/Button";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: var(--green);
  left: calc(50% - 75px);
  position: fixed;
`;

export default function MobileVerification({ userId, selectedProfile, currentStep, setCurrentStep, profile, setProfile, isHeaderUpdate, setIsHeaderUpdate, final, setFinal, setIsShowModalApplyJob, setReferralData, setNextStep }) {

    const [formData, setFormData] = useState({
        user_id: userId === null ? profile?.id : userId,
        otp: "",
    });

    let [loading, setLoading] = useState(false);
    let [color] = useState("#000000");
    const [activeInput, setActiveInput] = useState(0);

    const [otp, setOtp] = useState(['', '', '', '', '', '']);
    const otpInputRefs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];

    const handleInputChange = (index, e) => {
        const value = e.target.value;

        if (isValidNumber(value)) {
            const newOTP = [...otp];
            newOTP[index] = value;
            setOtp(newOTP);

            if (index < otp.length - 1 && value !== '') {
                otpInputRefs[index + 1].current.focus();
            }
        }
    };

    const handleKeyDown = (index, e) => {
        if (e.key === 'Backspace' && index > 0) {
            const newOTP = [...otp];

            if (newOTP[index] === '') {
                otpInputRefs[index - 1].current.focus();
                newOTP[index - 1] = '';
                setOtp(newOTP);
            } else {
                newOTP[index] = '';
                setOtp(newOTP);
            }
        }
    };

    useEffect(() => {
        initiateFirebase();
        handleSendOtp();
        otpInputRefs[0].current.focus();
    }, []);

    useEffect(() => {
        setFormData({
            ...formData,
            otp: otp.join(""),
        });
    }, [otp]);

    useEffect(() => {
        let inputs = document.querySelectorAll("input");
        if (activeInput < inputs.length) {
            inputs[activeInput].focus();
        }
    }, [activeInput]);

    const verifyOTP = () => {
        if (formData.otp === '') {
            toast.warn(errors.OTP_REQUIRED);
            return false;
        }
        setLoading(true);
        handleVerifyOTP(final, formData.otp, verificationSuccess, verificationFailure);
    }

    const verificationSuccess = () => {
        ApiHelper.otpVerify(formData).then((response) => {
            if (response.isSuccess === true) {
                setLoading(false);
                toast.success(response.message);
                if (response.data !== null) {
                    commonServices.storeLocalData(storageConstants.AUTH, response.data.token);
                    commonServices.storeLocalData(storageConstants.USER_ROLE, response.data.role_type);
                    commonServices.storeLocalData(storageConstants.PROFILE, response.data.profile);
                    setIsHeaderUpdate(!isHeaderUpdate);
                    if (response?.data?.user_unread_messages > 0) {
                        updateHeaderMessageIcon(UNREAD);
                    } else {
                        updateHeaderMessageIcon(READ);
                    }
                    setIsShowModalApplyJob(response?.data?.profile?.show_apply_popup);
                    setReferralData(response?.data?.profile?.referral_data);
                    setNextStep(response?.data?.next_step);
                    setCurrentStep(4);
                    logEvent(analyticsConstant.STEP4)

                } else {
                    setCurrentStep(2);
                    logEvent(analyticsConstant.STEP2)
                }

            } else {
                setLoading(false);
                toast.warn(response.message);
                logEvent(analyticsConstant.FAILED, response.message)
            }
        });
    }

    const verificationFailure = () => {
        setLoading(false);
        toast.warn('Invalid OTP');
        logEvent(analyticsConstant.VALIDATION_FAILED, "Invalid OTP: Mobile")
    }

    const handleSendOtp = () => {
        setLoading(true);
        handleSendOTP(profile?.contact, handleSendOTPSucess, handleSendOTPFail);

    }

    const handleSendOTPSucess = (confirmationResult) => {
        setLoading(false);
        setFinal(confirmationResult);
        toast.success(OTP_SENT_SUCCESS)
    }

    const handleSendOTPFail = () => {
        setLoading(false);
        toast.warn(OTP_SENT_FAILURE)
    }

    const logEvent = (step, data) => {
        switch (selectedProfile) {
            case "candidate":
                trackCandidateOnboarding(step, data);
                break;
            case "evaluator":
                trackEvaluatorOnboarding(step, data);
                break;
            default:
                break;
        }
    }

    return (
        <>
            {/* <div className="right_content mobile_verify steps">
                {loading ? (
                    <div className="loader">
                        <ClipLoader color={color} loading={true} css={override} size={150} />{" "}
                    </div>
                ) : null}
                <div className="row head d-flex">
                    <div className="col-9">
                        <h2 className="main-heading">
                            Verify Mobile
                        </h2>
                    </div>
                    <div className="col-3 header-btns">
                        <button style={{ marginLeft: 'auto' }} className="next" onClick={() => { verifyOTP() }}>Next</button>
                    </div>
                </div>
                <img className="mb-3" style={{
                    margin: "0 auto"
                }} src={emailImg} alt='email' />
                <h5 className="mb-4">Check your messages</h5>
                <p>We’ve sent a verification code to phone number
                    <strong></strong></p>

                <div className="otp-sec mt-3">
                    {otp.map((digit, index) => (
                        <input
                            class="otp"
                            key={index}
                            type="text"
                            value={digit}
                            onChange={(e) => handleInputChange(index, e)}
                            onKeyDown={(e) => handleKeyDown(index, e)}
                            maxLength={1}
                            ref={otpInputRefs[index]}
                        />
                    ))}
                </div>

                <button className="verify-mobile-btn mt-5" onClick={() => { verifyOTP() }}>Verify Mobile</button>
                <p className="mb-5 mt-3 resend-mobile">
                    Didn’t receive the message?
                    <a className="hand-hover" href={undefined} onClick={() => { handleSendOtp(); setOtp(['', '', '', '', '', '']); }}> Click to resend</a>
                </p>
            </div> */}


            {/* changes according to register process */}
            <div className="registration-section mobile_verify steps">
                {loading ? (
                    <div className="loader">
                        <ClipLoader color={color} loading={true} css={override} size={150} />{" "}
                    </div>
                ) : null}
                <div className="registration-section-header">
                    <h2 className="main-heading">Verify Mobile</h2>
                    <Button buttonType="primary" text="Next" onClick={() => verifyOTP()} />
                </div>
                <div className="registration-section-body">
                    <div className="registration-section-body-logo">
                        <img src={emailImg} alt="mobile image" />
                    </div>
                    <div className="registration-section-body-content">
                        <h5>Check your messages</h5>
                        <p className="mt-3">We’ve sent a verification code to phone number <strong>{profile?.contact !== null ? profile?.contact.replace(/\d(?=\d{4})/g, "*") : ""}</strong></p>
                        <div className="otp-sec">
                            {otp.map((digit, index) => (
                                <input
                                    class="otp"
                                    key={index}
                                    type="text"
                                    value={digit}
                                    onChange={(e) => handleInputChange(index, e)}
                                    onKeyDown={(e) => handleKeyDown(index, e)}
                                    maxLength={1}
                                    ref={otpInputRefs[index]}
                                />
                            ))}
                        </div>
                        <Button buttonType="primary" onClick={() => { verifyOTP() }} text="Verify Mobile" />
                        <p className="mt-3 mb-3">Didn’t receive the message?</p>
                        <Button
                            buttonType="text"
                            text="Click to resend"
                            onClick={() => { handleSendOtp(); setOtp(['', '', '', '', '', '']); }} />
                    </div>

                </div>

            </div>


            {/* changes according to register process ends */}

        </>

    )
}