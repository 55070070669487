import React from 'react'
import { MaxWidthContainer } from '@Components/Common/MaxWidthContainer'

function Privacy() {
  return (
    <MaxWidthContainer pageKey="privacy">
      <div className='privacy'>
        Privacy Page
      </div>
    </MaxWidthContainer>
  )
}

export default Privacy