import React from "react";
import AppModal from "./AppModal";
import * as toast from "@/wrapper/toast";
import { useState } from "react";

import * as errors from "@/constants/stringConstants";

const ModalUserChatMute = ({
  showModal,
  name,
  setShowModal,
  onSubmit,
  selectedChatMessages,
}) => {
  const [info, setInfo] = useState({ status_duration: "" });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = () => {
    if (info.status_duration === "") {
      toast.warn(errors.MUTE_DURATION_REQUIRED);
      return;
    }
    onSubmit({
      is_muted: "1",
      mute_duration: info?.status_duration,
      last_message_see_id:
        selectedChatMessages[selectedChatMessages.length - 1]?.id,
    });
    setShowModal((prev) => !prev);
    setInfo({ status_duration: "" });
  };

  return (
    <>
      <AppModal
        title={"Mute " + name + " For:"}
        isOpen={showModal}
        closeModal={() => {
          setShowModal((prev) => !prev);
        }}
        submit={handleSubmit}
        isFooter={true}
        submitText={"Mute Notifications"}
        size={"md"}
      >
        <div className="modal_body">
          <div className="row">
            <div className="col">
              <fieldset>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      fontSize: "16px",
                      fontWeight: 500,
                      color: "#84818A",
                    }}
                  >
                    <input
                      type="radio"
                      id="contactChoice1"
                      name="status_duration"
                      value="1"
                      onChange={handleChange}
                    />
                    <label for="contactChoice1">8 Hours</label>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      fontSize: "16px",
                      fontWeight: 500,
                      color: "#84818A",
                    }}
                  >
                    <input
                      type="radio"
                      id="contactChoice2"
                      name="status_duration"
                      value="7"
                      onChange={handleChange}
                    />
                    <label for="contactChoice2">1 Week</label>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      fontSize: "16px",
                      fontWeight: 500,
                      color: "#84818A",
                    }}
                  >
                    <input
                      type="radio"
                      id="contactChoice3"
                      name="status_duration"
                      value="30"
                      onChange={handleChange}
                    />
                    <label for="contactChoice3">1 Month</label>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      fontSize: "16px",
                      fontWeight: 500,
                      color: "#84818A",
                    }}
                  >
                    <input
                      type="radio"
                      id="contactChoice4"
                      name="status_duration"
                      value="99"
                      onChange={handleChange}
                    />
                    <label for="contactChoice4">Always</label>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
        </div>
      </AppModal>
    </>
  );
};

export default ModalUserChatMute;
