/* eslint-disable */
import React, { useState } from "react";
import "./Modal.scss";
import * as toast from "@/wrapper/toast";
import { getCandidateExperienceText } from "@/services/common";

import { REFERRAL_POLICY_REQUIRED } from "@/constants/stringConstants";
import AppModal from "./AppModal";
import { redirectToPrivacy } from "../../utils/redirectUtil";

function ModalReferralConfirm({
  showModal,
  setShowModal,
  modalData,
  boostJob,
  confirmModalInputs,
  setConfirmModalInputs,
}) {
  const [isPolicyAccepted, setIsPolicyAccepted] = useState(false);

  const onSubmit = (e) => {
    if (isPolicyAccepted) {
      boostJob(e, "boost");
      setShowModal(!showModal);
    } else {
      toast.warn(REFERRAL_POLICY_REQUIRED);
    }
  };

  const onClick = (e) => {
    let elementName = e.target.name;
    let isChecked = e.target.checked;
    let tempData = { ...confirmModalInputs };
    if (elementName === "policy") {
      setIsPolicyAccepted(isChecked);
    } else {
      tempData[elementName] = isChecked;
      setConfirmModalInputs(tempData);
    }
  };

  const onChange = (e) => {
    let elementName = e.target.name;
    let value = e.target.value;
    let tempData = { ...confirmModalInputs };
    tempData[elementName] = value;
    setConfirmModalInputs(tempData);
  };
  return (
    <>
      <AppModal
        title={"Confirmation Referrals"}
        isOpen={showModal}
        closeModal={() => setShowModal((prev) => !prev)}
        submit={(e) => onSubmit(e)}
        size={"lg"}
        isFooter={true}
      >
        <div className="modal_body">
          <h5 className="refer-confirm-heading mb-3">
            Any referred profile that matches these criteria will lead to a
            successful referral.
          </h5>
          <div className="row referral-data">
            <div className="col">
              <div className="row" style={{ marginBottom: "0px" }}>
                <div className="col check-div">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="isLocation"
                    id="location"
                    defaultChecked={confirmModalInputs.isLocation}
                    onClick={(e) => {
                      onClick(e);
                    }}
                  />
                  <label className="form-check-label" for="location">
                    Location
                  </label>
                </div>
              </div>
              <div className="row" style={{ marginBottom: "0px" }}>
                <div className="col">
                  <p>
                    {modalData?.job_locations !== null
                      ? modalData?.job_locations?.split(",").join(", ")
                      : ""}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row referral-data">
            <div className="col">
              <div className="row" style={{ marginBottom: "0px" }}>
                <div className="col check-div">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="isSkill"
                    id="skill"
                    defaultChecked={confirmModalInputs.isSkill}
                    onClick={(e) => {
                      onClick(e);
                    }}
                  />
                  <label className="form-check-label" for="skill">
                    Required Skills
                  </label>
                </div>
              </div>
              <div className="row" style={{ marginBottom: "0px" }}>
                <div className="col">
                  <p>
                    {modalData?.job_required_skills !== null &&
                    modalData?.job_required_skills?.length > 0
                      ? modalData?.job_required_skills
                          ?.toString()
                          .split(",")
                          .join(", ")
                      : ""}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row referral-data">
            <div className="col">
              <div className="row" style={{ marginBottom: "0px" }}>
                <div className="col check-div">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="isExperience"
                    id="experience"
                    defaultChecked={confirmModalInputs.isExperience}
                    onClick={(e) => {
                      onClick(e);
                    }}
                  />
                  <label className="form-check-label" for="experience">
                    Experience
                  </label>
                </div>
              </div>
              <div className="row" style={{ marginBottom: "0px" }}>
                <div className="col">
                  <p>
                    {getCandidateExperienceText(
                      modalData.minimum_experience_required
                    )}
                    -
                    {getCandidateExperienceText(
                      modalData.maximum_experience_required
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row referral-data">
            <div className="col">
              <div className="row" style={{ marginBottom: "0px" }}>
                <div className="col check-div">
                  <label
                    className="form-check-label"
                    style={{ margin: "0 0 0 0" }}
                  >
                    Other Requirements
                  </label>
                </div>
              </div>
              <div className="row" style={{ marginBottom: "0px" }}>
                <div className="col">
                  <input
                    className="requirement-text"
                    value={confirmModalInputs.otherRequirements}
                    name="otherRequirements"
                    type="text"
                    placeholder="Type here..."
                    onChange={(e) => onChange(e)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col">
              <div className="row" style={{ marginBottom: "0px" }}>
                <div className="col check-div">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="policy"
                    id="policy"
                    defaultChecked={isPolicyAccepted}
                    onClick={(e) => {
                      onClick(e);
                    }}
                  />
                  <label className="form-check-label" for="policy">
                    I agree to the TrueTalent{" "}
                  </label>
                  <a
                    href={undefined}
                    onClick={() => redirectToPrivacy()}
                    className="green-text hand-hover form-check-label"
                  >
                    {" "}
                    Global Referral policy.
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="row">
                                <div className="col"></div>
                                <div className="col">
                                    <div className="form_buttons">
                                        <button className="btn btn-primary" style={{ marginRight: "10px", outline: "0px" }} onClick={() => setShowModal(prev => !prev)}>Cancel</button>
                                        <input type="submit" value={"Boost Now"} onClick={(e) => onSubmit(e)} />
                                    </div>
                                </div>
                            </div> */}
        </div>
      </AppModal>

      {/* {showModal ? (
                <>

                    <div className="modal" onClick={() => setShowModal(prev => !prev)}>
                    </div>

                    <div className="modal_content">
                        <div className="modal_top">
                            <div style={{ display: 'flex' }}>
                                <h2 style={{ marginLeft: '5px' }}>Confirmation Referrals</h2>
                            </div>
                            <button onClick={() => setShowModal(prev => !prev)} ><img onError={commonServices.imgError} src="../../Assets/svgs/x.svg" alt="" /></button>
                        </div>
                        <hr />
                        
                    </div>
                </>

            ) : null} */}
    </>
  );
}

export default ModalReferralConfirm;
