import React from "react";
import "./Table.scss";
import CustomPagination from '@Components/Pagination/Pagination';

const Table = (props) => {

    return (
        <div className="table__section">
            <div className="table__wrapper">
                <table className="table">
                    <thead>
                        <tr>
                            {props?.columns && (props?.columns.map((column, i) => {
                                return <th key={i} scope="col" className={column.align}>{column.header? column.header : column}</th>
                            }

                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {props?.data && (props?.data?.map((data, i) => {
                            return <tr key={i} className="results" onClick={() => data?.rowClick !== undefined ? data?.rowClick(data?.rowClickData) : null}>
                                {data?.data && (data?.data?.map((cell, index) => {
                                    return <td key={index}><div className="d-flex align-items-center text-wrap gap-1 text-break">{cell}</div></td>
                                }

                                ))}
                            </tr>
                        }

                        ))}
                    </tbody>
                </table>
            </div>

            {props.applyPagination === false ?
                null
                : < CustomPagination
                    activePage={props?.activePage}
                    onPageChange={props?.handlePaginationChange}
                    totalData={props?.pagination?.total}
                    pageLength={props?.pagination?.per_page}
                    lastPage={props?.pagination?.last_page}
                    tag={props?.pagination?.tag || "pages"}
                    dataFrom={props?.pagination.from}
                    dataTo={props?.pagination?.to}
                />

            }
        </div>
    );
};

export default Table;