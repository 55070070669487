import * as commonServices from '../services/common';
import * as storageConstants from '../constants/storageConstants';
import { COMPANY_ADMIN_ROLE, CANDIDATE_ROLE, COMPANY_USER_ROLE, EVALUATOR_ROLE, PARTNER_ADMIN_ROLE, PARTNER_USER_ROLE } from '../constants/roleConstants';


export function isLoggedIn() {
    return commonServices.getLocalData(storageConstants.AUTH) ? true : false;
}

export function isCandidate() {
    return CANDIDATE_ROLE === commonServices.getLocalData(storageConstants.USER_ROLE);
}

export function isEvaluator() {
    return EVALUATOR_ROLE === commonServices.getLocalData(storageConstants.USER_ROLE);
}

export function isCompany() {
    return COMPANY_ADMIN_ROLE === commonServices.getLocalData(storageConstants.USER_ROLE) || COMPANY_USER_ROLE === commonServices.getLocalData(storageConstants.USER_ROLE);
}

export function isPartner() {
    return PARTNER_ADMIN_ROLE === commonServices.getLocalData(storageConstants.USER_ROLE) || PARTNER_USER_ROLE === commonServices.getLocalData(storageConstants.USER_ROLE);
}


export function isPartnerAdmin() {
    return PARTNER_ADMIN_ROLE === commonServices.getLocalData(storageConstants.USER_ROLE);
}

export function getCandidateProfileProgress() {
    var profile = commonServices.getLocalData(storageConstants.PROFILE);
    return profile?.progress;
}

export function isCandidateProfileIncomplete() {
    return getCandidateProfileProgress() < 100;
}

export function getCompanyProfileProgress() {
    var profile = commonServices.getLocalData(storageConstants.PROFILE);
    return profile?.company?.progress;
}

export function isCompanyProfileIncomplete() {
    return getCompanyProfileProgress() < 100;
}

export function isCompanyAdmin() {
    return COMPANY_ADMIN_ROLE === commonServices.getLocalData(storageConstants.USER_ROLE);
}

export function isIncompleteProfile() {
    if (isLoggedIn()) {
        return ((isCandidate() && isCandidateProfileIncomplete()) || (isCompanyAdmin() && isCompanyProfileIncomplete()));
    } else {
        return false;
    }
}

export function getUserCompanyId() {
    if (isLoggedIn() && (isCompanyAdmin() || isPartnerAdmin())) {
        var profile = commonServices.getLocalData(storageConstants.PROFILE);
        return profile?.company?.eid;
    }
    return null;
}

export function getUserId() {
    if (isLoggedIn()) {
        var profile = commonServices.getLocalData(storageConstants.PROFILE);
        return profile?.id;
    }
    return null;
}

export function getUserBasicDetails() {
    if (isLoggedIn()) {
        var profile = commonServices.getLocalData(storageConstants.PROFILE);
        var data = [];
        data.push(profile?.full_name);
        data.push(profile?.email);
        return data;
    }
    return null;
}

export function isUserStatusPending() {
    var profile = commonServices.getLocalData(storageConstants.PROFILE);
    return profile?.status === "pending";
}

export function getCompanyRemainingCash() {
    if (isLoggedIn() && (isCompanyAdmin() || isPartnerAdmin())) {
        var profile = commonServices.getLocalData(storageConstants.PROFILE);
        return profile?.company?.remaining_views;
    }
    return 0;
}