import React, { useEffect, useState } from "react";
import Text from "../_widgets/Text/Text";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";

import { Bar } from "react-chartjs-2";

import { BsCircleFill, BsCalendar } from "react-icons/bs";
import Loader from "../Common/Loader";
import NoDataScreen from "../Common/NoDataScreen";
import dayjs from "dayjs";
import NoDataView from "../NoDataView/NoDataView";
import { MaxWidthContainer } from "../Common/MaxWidthContainer";
import * as toast from "@/wrapper/toast";
import DateRangePicker from "../Common/DateRangePicker";
import IconResource from "../_widgets/IconResource/IconResource";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title
);

export const options = {
  plugins: {
    title: {
      display: false,
    },
  },
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

const EvaluatorDashboardLayout = ({
  navigate,
  loading,
  isDataExists,
  dashboardData,
  bargraphData,
  doughnutGraphData,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  datePickerStartRef,
  datePickerEndRef,
}) => {
  const doughnutDataset = doughnutGraphData?.datasets[0];

  return (
    <>
      <MaxWidthContainer
        pageTitle={"Evaluator Dashboard"}
        className="main-body eval"
      >
        <div className="tt-container">
          {loading ? (
            <Loader />
          ) : isDataExists ? (
            <>
              <div className="mb-4 gap-2 d-flex justify-content-between align-items-start">
                <Text
                  type="h2"
                  text="Dashboard"
                  fontSize={"xl"}
                  fontWeight={600}
                />
                <div>
                  <DateRangePicker
                    showDateShortcuts
                    datePickerStartRef={datePickerStartRef}
                    datePickerEndRef={datePickerEndRef}
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    onChange={(start, end) => { setStartDate(start); setEndDate(end) }}
                  />
                </div>
              </div>

              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fit, minmax(0px, 1fr))",
                  gap: "1rem",
                  width: "100%",
                }}
              >
                <div className="h-100" style={{ gridColumn: "span 4" }}>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "repeat(3, 1fr)",
                      gap: "1rem",
                      width: "100%",
                    }}
                  >
                    <div style={{ gridColumn: "span 1" }}>
                      <div
                        style={{
                          border: "1px solid #CBD5E0",
                          padding: "24px",
                          borderRadius: "10px",
                          height: "100%",
                        }}
                      >
                        <Text
                          text="Interview Duration"
                          type=""
                          style={{ fontSize: "var(--p1)" }}
                        />
                        <div className="d-flex justify-content-between align-items-center">
                          <Text
                            text={`${dashboardData?.total_hours} Hrs`}
                            type=""
                            style={{ fontSize: "var(--h2)", fontWeight: 700 }}
                            fontWeight={700}
                          />
                        </div>
                      </div>
                    </div>
                    <div style={{ gridColumn: "span 1" }}>
                      <div
                        style={{
                          border: "1px solid #CBD5E0",
                          padding: "24px",
                          borderRadius: "10px",
                          height: "100%",
                        }}
                      >
                        <Text
                          text="Earnings"
                          type=""
                          style={{ fontSize: "var(--p1)" }}
                        />
                        <div className="d-flex justify-content-between align-items-center">
                          <Text
                            text={`₹ ${dashboardData?.total_earning}`}
                            type=""
                            style={{ fontSize: "var(--h2)", fontWeight: 700 }}
                            fontWeight={700}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      style={{ gridColumn: "span 1" }}
                      colSpan={[3, 3, 1, 1]}
                    >
                      <div
                        className="border p-4 rounded-3"
                        style={{
                          height: "100%",
                          borderColor: "#CBD5E0",
                        }}
                      >
                        <Text
                          text="Interviews Conducted"
                          type=""
                          style={{ fontSize: "var(--p1)" }}
                        />
                        <div className="d-flex justify-content-between align-items-center">
                          <Text
                            text={dashboardData?.interviews_conducted}
                            type=""
                            style={{ fontSize: "var(--h2)", fontWeight: 700 }}
                            fontWeight={700}
                          />
                        </div>
                      </div>
                    </div>

                    <div style={{ gridColumn: "span 2" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          border: "1px solid #CBD5E0",
                          padding: "24px",
                          borderRadius: "10px",
                          height: "100%",
                        }}
                      >
                        <Text
                          text="Interviews"
                          type="h2"
                          className="mb-4"
                          fontSize={"lg"}
                          fontWeight={600}
                        />

                        {doughnutGraphData?.labels.length > 0 ? (
                          <Bar
                            height={"220px"}
                            options={options}
                            data={bargraphData}
                          />
                        ) : (
                          <NoDataView text={"No data found !"} />
                        )}
                      </div>
                    </div>
                    <div style={{ gridColumn: "span 1" }}>
                      <div
                        style={{
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                          border: "1px solid #CBD5E0",
                          padding: "24px",
                          borderRadius: "10px",
                        }}
                      >
                        <Text
                          text="Interviews"
                          type="h2"
                          className="mb-4"
                          fontSize={"lg"}
                          fontWeight={600}
                        />

                        <div className="px-4">
                          {doughnutGraphData?.labels.length > 0 ? (
                            <Doughnut
                              data={doughnutGraphData}
                              width={"30vw"}
                              options={{
                                plugins: {
                                  legend: {
                                    display: false,
                                  },
                                },
                                cutout: "90%",
                                responsive: true,
                              }}
                            />
                          ) : (
                            <NoDataView text={"No data found !"} />
                          )}
                        </div>
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns: "repeat(2, 1fr)",
                            gap: "1rem",
                            marginTop: "50px",
                          }}
                        >
                          {doughnutDataset?.data?.map((item, index) => {
                            return (
                              <div style={{ gridColumn: "span 1" }} colSpan={1}>
                                <div>
                                  <Text
                                    text={doughnutGraphData?.labels[index]}
                                    type=""
                                    textTransform={"capitalize"}
                                    style={{ fontSize: "var(--p1)" }}
                                  />

                                  <div className="d-flex align-items-center gap-2">
                                    <div
                                      style={{
                                        width: "10px",
                                        height: "10px",
                                        borderRadius: "50%",
                                        backgroundColor:
                                          doughnutDataset?.backgroundColor[
                                          index
                                          ],
                                      }}
                                    ></div>
                                    <Text
                                      text={item}
                                      type=""
                                      fontSize={"lg"}
                                      fontWeight={600}
                                      display={"flex"}
                                      alignItems={"center"}
                                      gap={2}
                                    />
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{ gridColumn: "span 2" }}
                  className="h-100"
                  colSpan={[6, 6, 6, 2]}
                >
                  <div
                    style={{
                      border: "1px solid #CBD5E0",
                      padding: "24px",
                      borderRadius: "10px",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div>
                      <Text
                        text="Upcoming Interviews"
                        type="h2"
                        fontSize="lg"
                        fontWeight={600}
                      />
                    </div>

                    {dashboardData?.upcoming_interviews?.length > 0 ? (
                      dashboardData?.upcoming_interviews?.map((item, index) => {
                        return (
                          <div
                            style={{
                              marginTop: "25px",
                              padding: "5px 15px 15px 15px",
                              backgroundColor: "#20C9AC1A",
                              borderRadius: "4px",
                            }}
                          >
                            <div
                              style={{
                                marginBottom: "10px",
                                marginTop: "5px",
                                background: "#20C9AC",
                                height: "3px",
                              }}
                            ></div>
                            <Text
                              text={item?.meeting_date_time}
                              type=""
                              style={{ fontSize: "var(--p1)" }}
                              color={"#20C9AC"}
                            />
                            <Text
                              text={item?.meeting_title}
                              type=""
                              fontWeight={500}
                            />
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "1rem", // gap: 4 * 0.25 = 1rem
                                marginTop: "1rem", // mt: 4 * 0.25 = 1rem
                              }}
                            >
                              {item?.skills?.map((skill, index) => {
                                return (
                                  <div
                                    style={{
                                      backgroundColor: "#fff",
                                      padding: "5px",
                                      borderRadius: "4px",
                                    }}
                                  >
                                    <Text
                                      text={skill?.name}
                                      type="t-icon"
                                      tIcon={
                                        <BsCircleFill
                                          color={"#20C9AC"}
                                          size={6}
                                        />
                                      }
                                      style={{ fontSize: "var(--p1)" }}
                                      display={"flex"}
                                      gap={2}
                                      alignItems={"center"}
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <NoDataView
                        text={"No upcoming interviews !"}
                        showIcon={false}
                      />
                    )}

                    <div
                      style={{
                        marginTop: "auto",
                        padding: "10px",
                        border: "1px dashed #718096", // border: "1px solid gray.500"
                        borderRadius: "4px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        navigate("/evaluator/calendar?tab=2");
                      }}
                    >
                      <Text
                        className="d-flex align-items-center gap-2 justify-content-center"
                        type="t-icon"
                        text={"Set Working Hours"}
                        tIcon={<BsCalendar size={20} />}
                        display={"flex"}
                        gap={"10px"}
                        textAlign={"center"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <NoDataScreen />
          )}
          { }
        </div>
      </MaxWidthContainer>
    </>
  );
};

export default EvaluatorDashboardLayout;
