import React, { useEffect } from "react";
import { MAX_WIDTH } from "@/helpers/style";
import { Helmet } from 'react-helmet';
import { getCurrentUrl } from "@/utils/redirectUtil";
import { seoObj } from "@/utils/seo";
import { useLocation } from "react-router-dom";

export const MaxWidthContainer = React.forwardRef(
    ({ pageKey, children, ...rest }, ref) => {

        const [pageTitle, setPageTitle] = React.useState("TrueTalent - The Ultimate Hiring Platform");
        const [pageDescription, setPageDescription] = React.useState("TrueTalent - The Ultimate Hiring Platform for small and medium enterprises, startups and hiring agencies to source the best talent from the industry.");
        const [pageKeywords, setPageKeywords] = React.useState("");


        // let pageTitle = (pageKey !== undefined && seoObj[pageKey]?.title !== undefined) ? seoObj[pageKey]?.title : "TrueTalent - The Ultimate Hiring Platform";

        // let pageDescription = (pageKey !== undefined && seoObj[pageKey]?.description !== undefined) ? seoObj[pageKey]?.description : "TrueTalent - The Ultimate Hiring Platform for small and medium enterprises, startups and hiring agencies to source the best talent from the industry.";

        // let pageKeywords = (pageKey !== undefined && seoObj[pageKey]?.targeted_keywords !== undefined) ? seoObj[pageKey]?.targeted_keywords : "";

        let url = getCurrentUrl();

        const location = useLocation();


        useEffect(() => {
            if (pageKey !== undefined) {
                setPageTitle(seoObj[pageKey]?.title !== undefined ? seoObj[pageKey]?.title : "TrueTalent - The Ultimate Hiring Platform")
            }
            if (pageKey !== undefined) {
                setPageDescription(seoObj[pageKey]?.description !== undefined ? seoObj[pageKey]?.description : "TrueTalent - The Ultimate Hiring Platform for small and medium enterprises, startups and hiring agencies to source the best talent from the industry.")
            }
            if (pageKey !== undefined) {
                setPageKeywords(seoObj[pageKey]?.targeted_keywords !== undefined ? seoObj[pageKey]?.targeted_keywords : "")
            }


        }, [location]);


        return (
            <div
                // maxW="100vw"
                // overflowX="hidden"
                // justify="center"
                // padding={["0 1rem", "0 10rem"]}
                // className="tt-container"
                {...rest}
                ref={ref}

            >
                <Helmet>
                    <title>{pageTitle}</title>

                    <meta name="title" content={pageTitle} />

                    <meta name="description" content={pageDescription} />
                    <meta name="keywords" content={pageKeywords} />

                    <meta property="og:url" content={url} />
                    <meta property="og:title" content={pageTitle} />
                    <meta property="og:description" content={pageDescription} />

                    <meta property="twitter:url" content={url} />
                    <meta property="twitter:title" content={pageTitle} />
                    <meta property="twitter:description" content={pageDescription} />
                </Helmet>
                <div>
                    {children}
                </div>
            </div>
        );
    }
);

MaxWidthContainer.displayName = "MaxWidthContainer";
