import React, { useEffect, useRef, useState } from 'react'
import {
    Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale,
    LinearScale,
    BarElement,
    Title
} from "chart.js";
import { useNavigate } from 'react-router-dom';
import { getEvaluatorDashboard } from '@/services/apiHelper';
import * as toast from "@/wrapper/toast";
import EvaluatorDashboardLayout from './DashboardLayout';
import { changeDateFormat } from '../../utils/dateUtil';


const EvaluatorDashboard = () => {

    const datePickerStartRef = useRef();
    const datePickerEndRef = useRef();

    const navigate = useNavigate();

    const [loading, setLoading] = React.useState(false);
    const [isDataExists, setIsDataExists] = React.useState(false);

    // const [dateRange, setDateRange] = React.useState({
    //     startDate: null,
    //     endDate: null,
    // });
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const [dashboardData, setDashboardData] = React.useState({
        total_hours: 0,
        interviews_conducted: 0,
    });

    const [bargraphData, setBargraphData] = React.useState({
        labels: [],
        datasets: [
            {
            }
        ]
    });

    const [doughnutGraphData, setDoughnutGraphData] = useState({
        labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
        datasets: [
        ]
    });


    const getDashboardData = async () => {

        try {
            setLoading(true);
            const params = `?from_date=${changeDateFormat(startDate, "YYYY-MM-DD")}&to_date=${changeDateFormat(endDate, "YYYY-MM-DD")}`
            const res = await getEvaluatorDashboard(startDate && endDate ? params : "");

            if (res.isSuccess) {
                setDashboardData({ ...dashboardData, ...res.data, });
                if (res?.data?.pie_chart?.interviews_by_status_graph?.data.length > 0) {
                    setIsDataExists(true);
                }
                if (!startDate && !endDate) {
                    setStartDate(new Date(res?.data?.from_date))
                    setEndDate(new Date(res?.data?.to_date))
                }
                /// BARGRAPH DATA

                const labels = res?.data?.bar_graph?.data?.map((item) => item.label);

                const datasetLength = res?.data?.bar_graph?.data[0]?.data?.length;

                const datasets = [...Array(datasetLength)].map((item, index) => {
                    return {
                        label: index === 0 ? "Total" : "Completed",
                        data: res?.data?.bar_graph?.data?.map((item) => item.data[index]),
                        backgroundColor: res?.data?.bar_graph?.data[0]?.backgroundColor[index],
                    }
                });

                setBargraphData({
                    labels,
                    datasets
                });


                ///// DOUGHNUT DATA

                const doughnutLabels = res?.data?.pie_chart?.interviews_by_status_graph?.labels;

                const datasetsDoughnut = res?.data?.pie_chart?.interviews_by_status_graph?.data;

                const backgroundColor = res?.data?.pie_chart?.interviews_by_status_graph?.backgroundColor;

                setDoughnutGraphData({
                    labels: doughnutLabels,
                    datasets: [
                        {
                            data: datasetsDoughnut,
                            backgroundColor: backgroundColor,
                            borderColor: backgroundColor,
                            borderWidth: 1,
                        }
                    ]
                });


            }
        } catch (err) {
            console.log(err);
            const errMsg = err?.response?.data?.message || "something went wrong";
            toast.warn(errMsg);
        } finally {
            setLoading(false);
        }

    }

    useEffect(() => {
        getDashboardData();
    }, [startDate, endDate])


    return (
        <EvaluatorDashboardLayout
            navigate={navigate}
            loading={loading}
            setEndDate={setEndDate}
            setStartDate={setStartDate}
            endDate={endDate}
            startDate={startDate}
            isDataExists={isDataExists}
            dashboardData={dashboardData}
            bargraphData={bargraphData}
            doughnutGraphData={doughnutGraphData}
            datePickerStartRef={datePickerStartRef}
            datePickerEndRef={datePickerEndRef}
        />
    )
}

export default EvaluatorDashboard