import React from 'react';
import * as commonServices from '@/services/common';
import Card from '../../Common/Card';
import { createSlugFromString } from '@/utils/stringUtils';
import emptyResult from '@/Assets/svgs/empty_result.svg';

const Gigs = ({
    profile, Text
}) => {

    return (
        <div className="container" id="featured-3">
            <div className="job__card__section row row-cols-1">
                {profile?.gigs && profile?.gigs.length > 0 ? profile?.gigs.map((value, key) => {
                    return (<div onClick={() => window.open('/gig/details/' + value.eid + '/' + createSlugFromString(value?.title), '_blank')} className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-12 hand-hover" style={{ padding: '10px' }}>
                        <Card key={value.id} data={value} type={'gig'} />
                    </div>)

                })
                    : <div className='no_jobs'><img onError={commonServices.imgError} src={emptyResult} alt="" /><Text text="No gig found!" type="h2"></Text></div>
                }
            </div>
        </div>
    )
}

export default Gigs