import React from "react";
import "./ProgressBar.scss";
import { ProgressBar } from "react-bootstrap";

const Progress = (props) => {

    const renderProgressBar = () => {
        if (props.progress > 100) {
            return null;
        }
        switch (props?.type) {
            case "with-text":
                return (
                    <div>
                        <p><span className="red-text">{props.progress}%</span> {props?.text}</p>
                        <ProgressBar style={{ height: `${props?.height}` }} variant={props?.background} now={props?.progress} strokeWidth={props?.strokeWidth} strokeColor={props?.strokeColor} />
                    </div>
                )

            default:
                return (
                    <>
                        <ProgressBar variant={props?.background} style={{ height: `${props?.height}` }} now={props.progress} strokeWidth={props?.stroke} strokeColor={props?.strokeColor} />
                    </>
                )
        }
    }

    return (<>
        {renderProgressBar()}
    </>
    );
};

export default Progress;
