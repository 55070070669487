import React from 'react';
import './Modal.scss';
import { isDocMedia, isPdfMedia, isExcelMedia, isPptMedia } from '@/utils/messageUtil';
import messageMediaDownload from '@/Assets/svgs/preview-message-media-download.svg';
import AppModal from './AppModal';



function ModalMessageMediaPreview({ showModal, setShowModal, data }) {

    return (
        <>

            <AppModal
                title={"Preview"}
                isOpen={showModal}
                closeModal={() => { setShowModal(false) }}
                isFooter={false}
                size={"xl"}
            >

                <div className="modal_body">
                    <div className='preview-media-message row'>
                        <div className='col-6'>
                            {isDocMedia(data.type) ? <img className='preview-media-message-img' src='./Assets/svgs/word.svg' alt="" /> :
                                isPdfMedia(data.type) ? <img className='preview-media-message-img' src='./Assets/svgs/pdf.svg' alt="" /> :
                                    isExcelMedia(data.type) ? <img className='preview-media-message-img' src='./Assets/svgs/excel.svg' alt="" /> :
                                        isPptMedia(data.type) ? <img className='preview-media-message-img' src='./Assets/svgs/ppt.svg' alt="" />
                                            : <img className='preview-media-message-img' src={data?.path} alt="" />}


                        </div>
                        <div className='preview-media-message-text col-6'>
                            <div>
                                <span>{data?.original_name} </span>
                                {/* <p>{data?.size_mb} mb</p> */}
                            </div>
                            <div style={{ marginTop: "inherit" }}>
                                <a
                                    style={{
                                        fontSize: "var(--p1)",
                                        display: "flex",
                                        gap: "10px",
                                        width: "fit-content",
                                    }}
                                    className='preview-download-btn' href={data?.path}><img src={messageMediaDownload} alt="" />  Download {data?.type ? data?.type.toUpperCase() : ''}</a>
                            </div>
                        </div>
                    </div>

                </div>

            </AppModal>
        </>
    )
}

export default ModalMessageMediaPreview
