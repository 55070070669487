import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import "./CompanyUsers.scss";
import * as storageConstants from "@/constants/storageConstants";
import * as ApiHelper from "@/services/apiHelper";
import {
  RESUMES,
  FEATURED_JOBS,
  FEATURED_GIGS,
  FEATURED_JOB_SLOTS_HISTORY,
  FEATURED_GIG_SLOTS_HISTORY,
} from "@/constants/tabConstants";
import { css } from "@emotion/react";
import * as toast from "@/wrapper/toast";
import * as commonServices from "@/services/common";
import { useDownloadExcel } from "react-export-table-to-excel";
import { isCompanyAdmin, isPartnerAdmin } from "@/utils/authUtil";
import { momentToDate } from "../../utils/dateUtil";
import { getPaymentStatusAndMessage } from "../../utils/stringUtils";
import { getCurrentTab, addTabInHistory } from "../../utils/redirectUtil";
import CompanyUsersLayout from "./CompanyUsersLayout";
import { getCompanyRemainingCash } from "../../utils/authUtil";

function CompanyUsers() {
  const { state } = useLocation();
  const [showPaymentGateay, setShowPaymentGateay] = useState(
    state !== null ? state.showPaymentGateay : false
  );
  const tableRef = useRef(null);
  const profile = commonServices.getLocalData(storageConstants.PROFILE);
  const [users, setUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [availableCash, setAvailableCash] = useState(0);
  const [ttCashDollar, setTtCashDollar] = useState(1);
  const [companyId, setCompanyId] = useState("");
  const [editUserId, setEditUserId] = useState(null);
  const [editUserFirstName, setEditUserFirstName] = useState(null);
  const [editUserLastName, setEditUserLastName] = useState(null);
  const [editUserEmail, setEditUserEmail] = useState(null);
  const [editUserAllowedCash, setEditUserAllowedCash] = useState(null);
  let [loading, setLoading] = useState(false);
  const [showModalAddCompanyUser, setShowModalAddCompanyUser] = useState(false);
  const [showModalBuyCash, setShowModalBuyCash] = useState(false);
  const [btClientToken, setBtClientToken] = useState("");

  const [showModalViewTransactions, setShowModalViewTransactions] =
    useState(false);
  const [currentTransactions, setCurrentTransactions] = useState([]);
  const [headingText, setHeadingText] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyTransactions, setCompanyTransactions] = useState([]);
  const [isCompanyTransactions, setIsCompanyTransactions] = useState(false);

  const [activeTab, setActiveTab] = useState(
    isCompanyAdmin() || isPartnerAdmin() ? "recruiters" : "resumes"
  );

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isDateFilter, setIsDateFilter] = useState(false);
  let [downloadXLSLoading, setDownloadXLSLoading] = useState(false);
  const [chart1Data, setChart1Data] = useState({});
  const [chart2Data, setChart2Data] = useState({});
  const [totalDownloads, setTotalDownloads] = useState(0);
  const Chart1Ref = useRef();
  const Chart2Ref = useRef();
  const [masterData, setMasterData] = useState(
    commonServices.getLocalData(storageConstants.DATA)
  );
  const [isPaymentEnabled, setIsPaymentEnabled] = useState(false);

  let [searchParams] = useSearchParams();
  const [showConfetti, setShowConfetti] = useState(false);
  const [showInformationModal, setShowInformationModal] = useState(false);
  const [informationModalTitle, setInformationModalTitle] = useState("");
  const [informationModalText, setInformationModalText] = useState("");
  const [activeRecruitersPage, setActiveRecruitersPage] = useState(1);
  const [lastTab, setlastTab] = useState("");
  const [showModalDeactivateUser, setShowModalDeactivateUser] = useState(false);
  const [deactivateUser, setDeactivateUser] = useState(null);
  const [targetDeactivateUser, setTargetDeactivateUser] = useState(null);

  // useEffect(()=>{
  //     if(lastTab === "featured-gigs"){
  //         setActiveTab("featured-gigs")
  //         setlastTab("")
  //     }
  // },[activeTab])

  const handleRecruitersPaginationChange = (e, { activePage }) => {
    window.scrollTo(0, 0);
    setActiveRecruitersPage(activePage);
  };

  var tab = getCurrentTab();

  const isActiveTab = (tab) => {
    return activeTab === tab;
  };

  const onTabClick = (tab) => {
    addTabInHistory(tab);
    setActiveTab(tab);
  };

  const getUsers = async () => {
    setLoading(true);
    var queryString = "?start_date=";
    if (startDate !== null) {
      var fromDate = new Date(startDate - startDate.getTimezoneOffset() * 60000)
        .toISOString()
        .substring(0, 10)
        .split("-")
        .join("-");
      queryString += fromDate;
    }
    queryString += "&end_date=";
    if (endDate !== null) {
      var toDate = new Date(endDate - endDate.getTimezoneOffset() * 60000)
        .toISOString()
        .substring(0, 10)
        .split("-")
        .join("-");
      queryString += toDate;
    }
    queryString += "&pageNumber=" + activeRecruitersPage;
    await ApiHelper.getCompanyUsers(queryString).then((response) => {
      if (response.isSuccess === true) {
        setUsers(response?.data?.users);
        setAllUsers(response?.data?.all_users);
        setAvailableCash(response.data.company.remaining_views);
        setTtCashDollar(response.data.ttcash_dollar);
        setCompanyId(response.data.company.id);
        setBtClientToken(response.data.bt_client_token);
        setCompanyName(response.data.company.name);
        setCompanyTransactions(response.data.company.view_transactions);
        setStartDate(momentToDate(response.data.start_date));
        setEndDate(momentToDate(response.data.end_date));
        setLoading(false);
      } else {
        setLoading(false);
        toast.warn(response.message);
        console.log("error");
      }
    });
  };

  useEffect(() => {
    getUsers();
  }, [activeRecruitersPage]);

  const getGraphData = async () => {
    setLoading(true);
    await ApiHelper.getCompanyReporting().then((response) => {
      if (response.isSuccess === true) {
        setLoading(false);
        setTotalDownloads(response.data.total_downloads);
        const labels = response.data.graph1_data.labels;

        const data = {
          labels,
          datasets: [
            {
              label: response.data.graph1_data.datasets[0].label,
              data: response.data.graph1_data.datasets[0].data,
              backgroundColor:
                response.data.graph1_data.datasets[0].backgroundColor,
            },
          ],
        };
        setChart1Data(data);

        const data2 = {
          labels: response.data.graph2_data.labels,
          datasets: [
            {
              label: response.data.graph2_data.datasets[0].label,
              data: response.data.graph2_data.datasets[0].data,
              backgroundColor:
                response?.data.graph2_data?.datasets[0]?.backgroundColor,
              borderColor: response?.data.graph2_data?.datasets[0]?.borderColor,
              hoverBackgroundColor: response?.data.graph2_data?.datasets[0]
                ?.hoverBackgroundColor
                ? response?.data.graph2_data?.datasets[0]
                  ?.hoverBackgroundColor[0]
                : "#20C9AC",
              hoverBorderColor: response?.data?.graph2_data?.datasets[0]
                ?.hoverBorderColor
                ? response?.data?.graph2_data?.datasets[0]?.hoverBorderColor[0]
                : "#20C9AC",
            },
          ],
          textCenter: response.data.total_downloads + " Downloads",
        };
        setChart2Data(data2);
      } else {
        setLoading(false);
        toast.warn(response.message);
        console.log("error");
      }
    });
  };

  useEffect(() => {
    // if (activeTab === 'recruiters') {
    // getUsers();
    // }
    // getGraphData();
    var tid = searchParams.get("tid");
    if (tid !== undefined && tid !== null) {
      checkPayment(tid);
    }
    if (tab !== null) {
      setActiveTab(tab);
    }
  }, []);

  const openAddEditUserModal = (
    userId = null,
    firstName = null,
    lastName = null,
    email = null,
    allowedCash = null
  ) => {
    setEditUserId(userId);
    setEditUserFirstName(firstName);
    setEditUserLastName(lastName);
    setEditUserEmail(email);
    setEditUserAllowedCash(allowedCash);
    setShowModalAddCompanyUser((prev) => !prev);
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "TrueTalent Cash",
    sheet: "TrueTalent",
  });

  const exportTransactions = async () => {
    const formData = new FormData();
    var sendStartDate = null;
    var sendEndDate = null;
    if (startDate !== null) {
      sendStartDate = new Date(
        startDate - startDate.getTimezoneOffset() * 60000
      )
        .toISOString()
        .substring(0, 10)
        .split("-")
        .join("-");
    }

    if (endDate !== null) {
      sendEndDate = new Date(endDate - endDate.getTimezoneOffset() * 60000)
        .toISOString()
        .substring(0, 10)
        .split("-")
        .join("-");
    }
    formData.append("start_date", sendStartDate);
    formData.append("end_date", sendEndDate);
    setDownloadXLSLoading(true);
    await ApiHelper.exportTransactions(formData).then((response) => {
      setDownloadXLSLoading(false);
      if (response.data !== null) {
        let a = document.createElement("a");
        a.href = response.data.file_url;
        a.click();
      } else {
        toast.success(response.message);
      }
    });
  };

  const openBuyCashModal = () => {
    if (!isPaymentMethodEnabled()) {
      return false;
    }
    setShowModalBuyCash((prev) => !prev);
  };

  const isCreditTransaction = (type) => {
    return type.toLowerCase() === "credit";
  };

  const openTransactionModal = (
    heading,
    transactions,
    companyTransactionCheck
  ) => {
    if (!isPaymentMethodEnabled()) {
      return true;
    }
    setHeadingText(heading);
    setCurrentTransactions(transactions);
    setIsCompanyTransactions(companyTransactionCheck);
    setShowModalViewTransactions((prev) => !prev);
  };

  const updateUserStatus = async (e, user, status = "") => {
    let isChecked = e.target.checked;
    let jobs_count = user?.jobs_count;
    let gigs_count = user?.gigs_count;
    let remaining_views = user?.remaining_views;
    if (
      !isChecked &&
      (jobs_count > 0 || gigs_count > 0 || remaining_views > 0)
    ) {
      setDeactivateUser(user);
      setShowModalDeactivateUser(true);
      return;
    }
    let userId = user?.id;
    const formData = new FormData();
    formData.append("user_id", userId);
    formData.append("status", status);
    setLoading(true);
    await ApiHelper.updateCompanyUserStatus(formData).then((response) => {
      setLoading(false);
      if (response.isSuccess === true) {
        // getUsers();
        toast.success(response.message);
      } else {
        toast.warn(response.message);
      }
      getUsers();
    });
  };

  const updateUserRole = async (userId) => {
    const formData = new FormData();
    formData.append("user_id", userId);
    setLoading(true);
    await ApiHelper.updateCompanyUserRole(formData).then((response) => {
      setLoading(false);
      if (response.isSuccess === true) {
        toast.success(response.message);
      } else {
        toast.warn(response.message);
      }
      getUsers();
    });
  };

  const isPaymentMethodEnabled = () => {
    return isPaymentEnabled === "1";
  };

  useEffect(() => {
    if (
      masterData &&
      masterData.system_config.is_payment_enabled !== undefined
    ) {
      setIsPaymentEnabled(masterData.system_config.is_payment_enabled.value);
    }
  }, [masterData]);

  useEffect(() => {
    if (
      isPaymentEnabled &&
      showPaymentGateay &&
      getCompanyRemainingCash() <= 0
    ) {
      openBuyCashModal();
      setShowPaymentGateay(false);
    }
  }, [isPaymentEnabled]);

  const checkPayment = async (tid) => {
    setLoading(true);
    let formData = new FormData();
    formData.append("tid", tid);
    await ApiHelper.verifyPayment(formData).then((response) => {
      setLoading(false);
      if (response.isSuccess === true) {
        let data = getPaymentStatusAndMessage(response.data);
        if (data["status"] !== undefined && data["message"] !== undefined) {
          setInformationModalTitle(data["status"]);
          setInformationModalText(data["message"]);
          setShowInformationModal(true);
          if (data["show_confetti"] !== undefined && data["show_confetti"]) {
            setShowConfetti(true);
          }
        }
      } else {
        toast.warn(response.message);
      }
    });
  };

  useEffect(() => {
    if (activeTab === "resumes") {
      getGraphData();
    }
    if (activeTab === "recruiters") {
      getUsers();
    }
  }, [activeTab]);

  var tabsData = [];

  if (isCompanyAdmin() || isPartnerAdmin()) {
    tabsData.push({
      text: "Recruiters",
      isActive: isActiveTab("recruiters"),
      onClick: (tab) => onTabClick(tab),
      onClickData: "recruiters",
      id: "list-recruiters-list",
      href: "#list-recruiters",
    });
  }

  tabsData.push(
    {
      text: "Resume Dashboard",
      isActive: isActiveTab(RESUMES),
      onClick: (tab) => onTabClick(tab),
      onClickData: RESUMES,
      id: "list-manage-resume-list",
      href: "#list-manage-resume",
    },
    {
      text: "Featured Jobs",
      isActive: isActiveTab(FEATURED_JOBS),
      onClick: (tab) => onTabClick(tab),
      onClickData: FEATURED_JOBS,
      id: "list-featured-jobs-list",
      href: "#list-featured-jobs",
    },
    {
      text: "Featured Gigs",
      isActive: isActiveTab(FEATURED_GIGS),
      onClick: (tab) => onTabClick(tab),
      onClickData: FEATURED_GIGS,
      id: "list-featured-gigs-list",
      href: "#list-featured-gigs",
    },
    {
      text: "Featured Job Slots History",
      isActive: isActiveTab(FEATURED_JOB_SLOTS_HISTORY),
      onClick: (tab) => onTabClick(tab),
      onClickData: FEATURED_JOB_SLOTS_HISTORY,
      id: "list-featured-job-slots-history-list",
      href: "#list-featured-job-slots-history",
    },
    {
      text: "Featured Gig Slots History",
      isActive: isActiveTab(FEATURED_GIG_SLOTS_HISTORY),
      onClick: (tab) => onTabClick(tab),
      onClickData: FEATURED_GIG_SLOTS_HISTORY,
      id: "list-featured-gig-slots-history-list",
      href: "#list-featured-gig-slots-history",
    }
  );

  const [transactionData, setTransactionData] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [userId, setuserId] = useState(null);

  function getTransactionData(id = null) {
    setuserId(id);
    const queryParams =
      id !== null
        ? `?pageNumber=${pageNumber}&perPage=${perPage}&user_id=${id}`
        : `?pageNumber=${pageNumber}&perPage=${perPage}`;

    setLoading(true);
    ApiHelper.getTransactions(queryParams).then((response) => {
      if (response.isSuccess === true) {
        setTransactionData(response.data);
      } else {
        toast.warn(response.message);
      }
      setLoading(false);
    });
  }

  function handlePaginationChange(e, { activePage }) {
    window.scrollTo(0, 0);
    setPageNumber(activePage);
  }

  useEffect(() => {
    getTransactionData(userId);
  }, [pageNumber]);

  return (
    <CompanyUsersLayout
      showConfetti={showConfetti}
      setShowConfetti={setShowConfetti}
      activeTab={activeTab}
      downloadXLSLoading={downloadXLSLoading}
      exportTransactions={exportTransactions}
      showModalAddCompanyUser={showModalAddCompanyUser}
      setShowModalAddCompanyUser={setShowModalAddCompanyUser}
      editUserId={editUserId}
      editUserFirstName={editUserFirstName}
      editUserLastName={editUserLastName}
      editUserEmail={editUserEmail}
      editUserAllowedCash={editUserAllowedCash}
      getUsers={getUsers}
      availableCash={availableCash}
      btClientToken={btClientToken}
      companyId={companyId}
      ttCashDollar={ttCashDollar}
      setShowModalBuyCash={setShowModalBuyCash}
      showModalBuyCash={showModalBuyCash}
      showModalViewTransactions={showModalViewTransactions}
      setShowModalViewTransactions={setShowModalViewTransactions}
      currentTransactions={currentTransactions}
      isCompanyTransactions={isCompanyTransactions}
      headingText={headingText}
      showInformationModal={showInformationModal}
      setShowInformationModal={setShowInformationModal}
      informationModalTitle={informationModalTitle}
      informationModalText={informationModalText}
      loading={loading}
      setActiveTab={setActiveTab}
      openTransactionModal={openTransactionModal}
      companyName={companyName}
      companyTransactions={companyTransactions}
      isPaymentMethodEnabled={isPaymentMethodEnabled}
      openBuyCashModal={openBuyCashModal}
      openAddEditUserModal={openAddEditUserModal}
      users={users}
      allUsers={allUsers}
      profile={profile}
      updateUserStatus={updateUserStatus}
      updateUserRole={updateUserRole}
      chart1Data={chart1Data}
      Chart1Ref={Chart1Ref}
      totalDownloads={totalDownloads}
      chart2Data={chart2Data}
      Chart2Ref={Chart2Ref}
      setLoading={setLoading}
      tabsData={tabsData}
      activeRecruitersPage={activeRecruitersPage}
      handleRecruitersPaginationChange={handleRecruitersPaginationChange}
      getGraphData={getGraphData}
      getTransactionData={getTransactionData}
      transactionData={transactionData}
      handlePaginationChange={handlePaginationChange}
      pageNumber={pageNumber}
      setlastTab={setlastTab}
      setPageNumber={setPageNumber}
      showModalDeactivateUser={showModalDeactivateUser}
      setShowModalDeactivateUser={setShowModalDeactivateUser}
      deactivateUser={deactivateUser}
      setDeactivateUser={setDeactivateUser}
      targetDeactivateUser={targetDeactivateUser}
      setTargetDeactivateUser={setTargetDeactivateUser}
    />
  );
}

export default CompanyUsers;
