/* eslint-disable */
import React, { useState } from "react";
import "./Modal.scss";
import {
  isNullOrEmpty,
  getYoutubeVideoId,
  createSlugFromString,
} from "@/utils/stringUtils";
import * as commonServices from "@/services/common";
import defaultAvatar from "@/Assets/svgs/default_avatar.svg";
import Card from "../Common/Card";
import AppModal from "./AppModal";
import { textCapitalise } from "@/utils/stringUtils";
import emptyResult from "@/Assets/svgs/empty_result.svg";

const ModalCompanyProfilePreview = ({
  showModal,
  setShowModal,
  profile,
  updateCompanyProfile,
  masterData,
}) => {
  const [currentTab, setCurrentTab] = useState("profile");

  const recruiterProfile = (id) => {
    window.open("/company/recruiter/" + id, "_blank");
  };

  return (
    <>
      <AppModal
        isOpen={showModal}
        closeModal={() => setShowModal((prev) => !prev)}
        title={"Company Profile Preview"}
        isFooter={true}
        size={"xl"}
        submit={() => updateCompanyProfile("update")}
      >
        <div className="modal_body">
          <div
            className="company-profile"
            style={{ padding: "0px 0px 0px 0px" }}
          >
            <div
              className="banner"
              style={{
                backgroundImage: `url(${profile?.cover_pic})`,
                height: "200px",
                border: "solid 1px var(--gray)",
                borderTopLeftRadius: "16px",
                borderTopRightRadius: "16px",
                position: "relative",
              }}
            >
              {/* <img style={{ height: '100%', width: '100%' }} src={profile?.cover_pic} ></img> */}
              <div className="profile_img img-border">
                <img
                  style={{ objectFit: "cover", objectPosition: "center" }}
                  width={100}
                  onError={commonServices.imgError}
                  src={profile?.logo ?? defaultAvatar}
                  alt=""
                />
              </div>
              {/* <span className='setDeleteClass'><DeleteIcon className='deleteIcon' /></span> */}
            </div>

            <div className="tabs list-group" id="list-tab" role="tablist">
              <div
                className="tab__list hand-hover active"
                id="list-home-list"
                data-bs-toggle="list"
                href="#list-home"
                role="tab"
                aria-controls="list-home"
                onClick={() => setCurrentTab("profile")}
              >
                <p>Who we are</p>
              </div>

              <div
                className="tab__list hand-hover"
                id="list-jobs-list"
                data-bs-toggle="list"
                href="#list-jobs"
                role="tab"
                aria-controls="list-jobs"
                onClick={() => setCurrentTab("jobs")}
              >
                <p>Jobs</p>
              </div>

              <div
                className="tab__list hand-hover"
                id="list-gigs-list"
                data-bs-toggle="list"
                href="#list-gigs"
                role="tab"
                aria-controls="list-gigs"
                onClick={() => setCurrentTab("gigs")}
              >
                <p>Gigs</p>
              </div>

              <div
                className="tab__list hand-hover"
                id="list-recruiters-list"
                data-bs-toggle="list"
                href="#list-recruiters"
                role="tab"
                aria-controls="list-recruiters"
                onClick={() => setCurrentTab("recruiters")}
              >
                <p>Recruiters</p>
              </div>
            </div>

            <div className="tab-content" id="nav-tabContent">
              {currentTab === "profile" ? (
                <div
                  className="tab__pane tab_1 d-flex gap-3 fade show active"
                  id="list-home"
                  role="tabpanel"
                  aria-labelledby="list-home-list"
                >
                  <div
                    className="left"
                    style={{
                      border: "solid 1px var(--gray)",
                      borderRadius: "16px",
                      padding: "30px 30px",
                    }}
                  >
                    <div
                      className="d-flex items-center w-full justify-between"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <h2>{profile?.name}</h2>
                      <div
                      // style={{ border: 'solid 1px black', padding: "10px", borderRadius: '50%', height: '20px', width: "10px" }}
                      ></div>
                    </div>
                    <div
                      className="d-flex gen-details"
                      style={{
                        gap: "5px",
                        marginTop: "10px",
                        alignItems: "center",
                      }}
                    >
                      <i class="bi bi-geo-alt-fill"></i>
                      <p style={{ color: "var(--gray8)" }}>
                        {" "}
                        {profile?.address}
                      </p>
                      <span>
                        <i class="bi bi-dot"></i>
                      </span>
                      <p style={{ color: "var(--gray8)" }}>
                        Posted {profile?.updated_at}
                      </p>
                    </div>

                    <br />

                    <div
                      class="container text-start"
                      style={{ margin: "10px 0", padding: 0, width: "80%" }}
                    >
                      <div class="row">
                        <div class="col">
                          <p
                            style={{
                              color: "var(--gray8)",
                              fontSize: "0.875em",
                            }}
                          >
                            Employee
                          </p>
                          <div
                            style={{
                              color: "var(--black3)",
                              fontSize: "1em",
                              fontWeight: 500,
                            }}
                          >
                            <i class="bi bi-person"></i> {profile?.size?.name}
                          </div>
                        </div>
                        <div class="col">
                          <p
                            style={{
                              color: "var(--gray8)",
                              fontSize: "0.875em",
                            }}
                          >
                            Business Field
                          </p>
                          <div
                            style={{
                              color: "var(--black3)",
                              fontSize: "1em",
                              fontWeight: 500,
                            }}
                          >
                            <i class="bi bi-person"></i>{" "}
                            {profile?.industry?.name}
                          </div>
                        </div>
                        <div class="col ">
                          <p
                            style={{
                              color: "var(--gray8)",
                              fontSize: "0.875em",
                            }}
                          >
                            Status
                          </p>
                          <div
                            style={{
                              color: "var(--black3)",
                              fontSize: "1em",
                              fontWeight: 500,
                            }}
                          >
                            <i class="bi bi-briefcase"></i>{" "}
                            {profile?.jobs && profile?.jobs.length > 0
                              ? "Hiring"
                              : ""}
                          </div>
                        </div>
                      </div>
                    </div>

                    <br />

                    <h4>About Company</h4>
                    <p className="desc">{profile?.description}</p>

                    <br />

                    <h4>Working at {profile?.name}</h4>
                    {profile?.links &&
                      profile?.links.map((link, i) => {
                        return (
                          <div
                            className="video__frame"
                            style={{ marginBottom: "15px", marginTop: "10px" }}
                            key={i}
                          >
                            <iframe
                              width="853"
                              height="380"
                              src={
                                "https://www.youtube.com/embed/" +
                                getYoutubeVideoId(link.value)
                              }
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                              title="Embedded youtube"
                            />
                          </div>
                        );
                      })}

                    <br />
                    <h4>Photos</h4>

                    <div className="photos">
                      {profile?.medias &&
                        profile?.medias.map((media, i) => {
                          return (
                            <div className="photo">
                              <img src={media.url} />
                            </div>
                          );
                        })}
                    </div>
                  </div>

                  <div className="right">
                    <div
                      style={{
                        border: "solid 1px var(--gray)",
                        borderRadius: "16px",
                        padding: "30px 30px",
                      }}
                    >
                      <h6>Headquarters</h6>
                      <p>{profile?.address}</p>
                      <br />
                      <h6>Employees</h6>
                      <p>{profile?.size?.name}</p>
                      <br />
                      <h6>Industry</h6>
                      <p>{profile?.industry?.name}</p>
                      <br />
                      <br />
                      <h6>Additional Domains</h6>

                      {profile?.domains !== undefined &&
                        profile?.domains.map((data, i) => {
                          return <p>{data.name}</p>;
                        })}

                      <br />
                      <br />
                      <h6>Social Accounts</h6>
                      {!isNullOrEmpty(profile?.facebook) ? (
                        <>
                          <a
                            className="hand-hover"
                            href={profile.facebook}
                            target="_blank"
                            referrerPolicy="no-referrer"
                          >
                            Facebook
                          </a>
                          <br />
                        </>
                      ) : null}
                      {!isNullOrEmpty(profile?.instagram) ? (
                        <>
                          <a
                            className="hand-hover"
                            href={profile.instagram}
                            target="_blank"
                            referrerPolicy="no-referrer"
                          >
                            Instagram
                          </a>
                          <br />
                        </>
                      ) : null}
                      {!isNullOrEmpty(profile?.linkedin) ? (
                        <>
                          <a
                            className="hand-hover"
                            href={profile.linkedin}
                            target="_blank"
                            referrerPolicy="no-referrer"
                          >
                            Linkedin
                          </a>
                          <br />
                        </>
                      ) : null}
                      {!isNullOrEmpty(profile?.twitter) ? (
                        <>
                          <a
                            className="hand-hover"
                            href={profile.twitter}
                            target="_blank"
                            referrerPolicy="no-referrer"
                          >
                            Twitter
                          </a>
                        </>
                      ) : null}
                    </div>
                    <div
                      style={{
                        border: "solid 1px var(--gray)",
                        borderRadius: "16px",
                        padding: "30px 30px",
                        marginTop: "20px",
                      }}
                    >
                      <a
                        className="hand-hover"
                        href={undefined}
                        onClick={() => window.open(profile?.website, "_blank")}
                      >
                        {profile?.name} website{" "}
                        <i
                          class="bi bi-box-arrow-up-right"
                          style={{ marginLeft: "10px" }}
                        ></i>
                      </a>
                    </div>
                  </div>
                </div>
              ) : currentTab === "jobs" ? (
                <div
                  className="tab__pane tab_2 d-flex gap-3 fade show"
                  id="list-jobs"
                  role="tabpanel"
                  aria-labelledby="list-jobs-list"
                >
                  <div className="container" id="featured-3">
                    <div className="job__card__section row row-cols-1">
                      {profile?.jobs && profile?.jobs.length > 0 ? (
                        profile?.jobs.map((jobData, key) => {
                          return (
                            <div
                              onClick={() =>
                                window.open(
                                  "/job/details/" +
                                    jobData.eid +
                                    "/" +
                                    createSlugFromString(jobData.title),
                                  "_blank"
                                )
                              }
                              className="col-lg-3 col-md-4 col-sm-4 col-12 hand-hover"
                              style={{ padding: "10px" }}
                            >
                              <Card
                                key={jobData.id}
                                data={jobData}
                                type={"job"}
                              />
                            </div>
                          );
                        })
                      ) : (
                        <div className="no_jobs">
                          <img
                            onError={commonServices.imgError}
                            src={emptyResult}
                            alt=""
                          />
                          <h2>No job found!</h2>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : currentTab === "gigs" ? (
                <div
                  className="tab__pane tab_2 d-flex gap-3 fade show"
                  id="list-gigs"
                  role="tabpanel"
                  aria-labelledby="list-gigs-list"
                >
                  <div className="container" id="featured-3">
                    <div className="job__card__section row row-cols-1">
                      {profile?.gigs && profile?.gigs.length > 0 ? (
                        profile?.gigs.map((value, key) => {
                          return (
                            <div
                              onClick={() =>
                                window.open(
                                  "/gig/details/" +
                                    value.eid +
                                    "/" +
                                    createSlugFromString(value?.title),
                                  "_blank"
                                )
                              }
                              className=" col-lg-3 col-md-4 col-sm-4 col-12 hand-hover"
                              style={{ padding: "10px" }}
                            >
                              <Card key={value.id} data={value} type={"gig"} />
                            </div>
                          );
                        })
                      ) : (
                        <div className="no_jobs">
                          <img
                            onError={commonServices.imgError}
                            src={emptyResult}
                            alt=""
                          />
                          <h2>No gig found!</h2>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className="tab__pane tab_2 d-flex gap-3 fade show"
                  id="list-recruiters"
                  role="tabpanel"
                  aria-labelledby="list-recruiters-list"
                >
                  <div className="container" id="featured-3">
                    <div className="row">
                      {profile?.recruiters && profile?.recruiters.length > 0 ? (
                        profile?.recruiters.map((data, key) => {
                          return (
                            <div
                              className="col-lg-4 col-md-6 col-sm-6 col-12"
                              key={key}
                            >
                              <div className="feature recruiter-feature box-hover">
                                <div
                                  className="feature_content hand-hover recruiter-content"
                                  onClick={() => recruiterProfile(data.id)}
                                >
                                  <div className="row">
                                    <div className="col-3">
                                      <img
                                        src={
                                          profile.avatar_location ??
                                          defaultAvatar
                                        }
                                        alt=""
                                      />
                                    </div>
                                    <div className="col-9">
                                      <p
                                        className="paragraph_1 truncate-1 name"
                                        title={textCapitalise(data.full_name)}
                                      >
                                        {textCapitalise(data.full_name)}
                                      </p>
                                      {/* <p className="paragraph_3">Exp: {getCandidateExperienceText(data.total_experience)}</p> */}

                                      {/* <p className='paragraph_3 d-flex gap-1' style={{ color: '#4B8BFF' }}> <img style={{ height: "12px" }} src={mapPin} alt="" /> <span className='truncate-1' title={'Current Location'}>{data?.location_name ? data?.location_name : "NA"}</span></p> */}
                                      <button className="jobs-count">
                                        {data?.jobs_count} jobs
                                      </button>
                                      <button
                                        className="jobs-count"
                                        style={{ marginLeft: "5px" }}
                                      >
                                        {data?.gigs_count} gigs
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="no_jobs">
                          <img
                            onError={commonServices.imgError}
                            src={emptyResult}
                            alt=""
                          />
                          <h2>No recruiter found!</h2>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </AppModal>
    </>
  );
};

export default ModalCompanyProfilePreview;
