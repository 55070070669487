import { isCompany, isLoggedIn } from "./authUtil";

export function isNullOrEmpty(value) {
    return (value === undefined || value === null || value.toString().trim() === '' || typeof value === 'undefined');
}

export function valueOrDefault(value, defaultValue) {
    return isNullOrEmpty(value) ? defaultValue : value;
}

export function getYoutubeVideoId(url) {
    if (url) {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|shorts\/)([^#&?]*).*/;
        const match = url.match(regExp);

        return (match && match[2].length === 11) ? match[2] : '';
    }
    else return '';
}

export function textCapitalise(text) {
    return text === undefined ? '' : text.toLowerCase().replace(/\b(\w)/g, x => x.toUpperCase());
}

export function createSlugFromString(text) {
    return text.toLowerCase().trim().replace(/[^\w\s-]/g, '').replace(/[\s_-]+/g, '-').replace(/^-+|-+$/g, '');
}

export function removeTrailingSlashes(text) {
    return text.replace(/^\/|\/$/g, '');
}

export function newLineText(text) {
    return text.split('\r\n').map((str, index) => <p key={str + index}>{str}</p>);
}

export function capsOperators(text) {
    let newText = text.split(' ').map(str => {
        if (str.toLowerCase() === 'and' || str.toLowerCase() === 'or' || str.toLowerCase() === 'not') {
            return str.toUpperCase();
        } else {
            return str;
        }
    });
    return newText.join(' ');
}

export function getReferralJobTooltip() {
    return isLoggedIn() ? "This job is open for referals. Refer your friends and earn" : "This job is open for referals.  Log in and refer your friends to earn";
}

export function getSmartSearchTooltip() {
    if (isLoggedIn() && isCompany()) {
        return "Unlock the potential of TrueTalent AI smart matching and  discover the most fitting candidates for your needs";
    } else {
        return "This is our customized AI driven search for precise results";
    }
}

export function getKeywordSearchTooltip() {
    if (isLoggedIn() && isCompany()) {
        return "Use this option when you are searching for a name, company or job seeeker";
    } else {
        return 'Use this option for generic key word match like a "company name" or "job seeker name"';
    }
}

export function getSkillsString(skills) {
    return !isNullOrEmpty(skills) && skills.length > 0 ? skills.map(skill => skill?.name).toString().split(',').join(', ') : "N/A"
}

export function getDomainFromUrl(url) {
    var prefix = /^https?:\/\//i;
    var domain = /^[^\/:]+/; //eslint-disable-line 

    url = url.replace(prefix, "");
    if (url.charAt(0) === "/") {
        url = window.location.hostname + url;
    }

    var match = url.match(domain);
    if (match) {
        return (match[0]).replace('www.', '');
    }
    return (null);
}


export function stripHTMLTags(textWithHTML) {
    return textWithHTML.replace(/<[^>]*>/g, '');
}

export function getSubString(text, start, end) {
    return isNullOrEmpty(text) ? text : (text.length > (end - start) ? text.substring(start, end) + "..." : text.substring(start, end));
}

export function highlightText(keyword, string) {
    if (keyword && string) {
        let csv = str_getcsv(keyword);
        if (Array.isArray(csv)) {
            csv.forEach(element => {
                if (element !== '' && element.toLowerCase() !== 'not' && element.toLowerCase() !== 'and' && element.toLowerCase() !== 'or') {
                    var regex = new RegExp('(' + element + ')(?![^<]*>)', "ig");
                    string = string.replace(regex, '<span class="highlight">$1</span>');
                }
            });
        }

    }
    return string;
}

function str_getcsv(input) {
    let result = input.match(/(?:[^\s"']+|"[^"]*"|'[^']*')+/g);

    if (result !== null) {
        result = result.map(element => {
            if (element.startsWith('"') && element.endsWith('"')) {
                return element.slice(1, -1);
            }
            return element;
        });
        return result;
    } else {
        return input;
    }
}

export function removeParameterFromURL(url, parameter) {
    // Create an anchor element to utilize the browser's built-in URL parsing
    var parser = document.createElement('a');
    parser.href = url;

    // Parse the query string into an object
    var queryParams = new URLSearchParams(parser.search);

    // Remove the specified parameter
    queryParams.delete(parameter);

    // Update the search property of the URL object
    parser.search = queryParams.toString();

    // Assemble the modified URL
    var modifiedURL = parser.href;

    return modifiedURL;
}

export function getPaymentStatusAndMessage(data) {
    var response = [];
    if (data?.status === 'PAYMENT_INITIATED') {
        response['status'] = 'Payment Pending';
        response['message'] = 'Your payment is still in progress, please refresh the page after 2 minutes';
    } else if (data?.status === 'PAYMENT_SUCCESS') {
        response['show_confetti'] = true;
        response['status'] = 'Payment Success';
        response['message'] = 'Your payment has been done successfully';
    } else {
        response['status'] = 'Payment Failed';
        response['message'] = 'Your payment has been failed. If amount is debited than contact to TT Admin. Your transaction id is: ' + data?.transaction_id;
    }
    return response;
}

export function decryptBase64String(str) {
    return atob(str);
}

export const capitalizeText = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export const stringCompare = (str1, str2) => {
    return str1.toLowerCase() === str2.toLowerCase();
}