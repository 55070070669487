
import React, { useState, useEffect } from 'react';
import './Modal.scss';
import * as commonServices from '@/services/common';
import AppModal from './AppModal';
import * as toast from "@/wrapper/toast";
import * as ApiHelper from "@/services/apiHelper";
import { PuffLoader } from 'react-spinners';
import CustomPagination from '@Components/Pagination/Pagination';
import { changeDateFormat } from '../../utils/dateUtil';
import { textCapitalise, getSubString } from "@/utils/stringUtils";
import Button from '../_widgets/Button/Button';

function PartnerCandidatesAppliedJobs({ showModal, setShowModal }) {

    const [data, setData] = useState([]);
    const [loader, setLoader] = useState(false);
    const [activePage, setActivePage] = useState(1);
    const handlePaginationChange = (e, { activePage }) => {

        window.scrollTo(0, 0);
        showCandidates(activePage);
        setActivePage(activePage);
    };

    useEffect(() => {
        if (showModal) {
            showCandidates();
        }

    }, [showModal])

    const showCandidates = async (activePage = 1) => {
        setLoader(true);
        let params = "?pageNumber=" + activePage;
        await ApiHelper.getPartnerCandidatesAppliedJobs(params).then((response) => {
            setLoader(false);
            if (response.isSuccess === true) {
                setData(response?.data);
                setActivePage(response?.data?.current_page);
            } else {
                toast.warn(response.message);
            }
        });
    }

    const download = async () => {
        setLoader(true);
        let params = "?type=smart-apply";
        await ApiHelper.downloadReport(params).then((response) => {
            setLoader(false);
            if (response.isSuccess === true) {
                if (response.data !== null) {
                    let a = document.createElement('a');
                    a.href = response.data.file_url;
                    a.click();
                } else {
                    toast.success(response.message);
                }
            } else {
                toast.warn(response.message);
            }
        });
    }

    return (

        <AppModal
            isOpen={showModal}
            closeModal={() => setShowModal(prev => !prev)}
            title={`Applied Jobs (${data?.total ? data?.total : 0})`}
            size={"xl"}
            isFooter={false}

        >
            <div className="modal_body partner-candidates">
                {loader ?
                    <>
                        <div className='loader'>
                            <PuffLoader
                                color="#14BC9A"
                                loading={loader}
                                size={50}
                                height={50}
                                width={50}
                                radios={50}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                            />
                        </div>
                    </>
                    :
                    <>
                        <div className="t_details common_items">
                            <div className='row' style={{ float: "right", marginRight: "10px" }}>
                                <Button
                                    buttonType='primary'
                                    text='Export'
                                    onClick={download} />
                            </div>
                            {data?.data && data?.data.length > 0 ?
                                <>
                                    <table className="table">

                                        <thead>
                                            <tr>
                                                <th scope="col">Candidate Name</th>
                                                <th scope="col">Job Title</th>
                                                <th scope="col">Company Name</th>
                                                <th scope="col">Applied On</th>
                                                <th scope="col">Application Status</th>
                                                <th scope="col">SmartApply</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data?.data && (data?.data.map((job, i) => {
                                                return <tr className="results">
                                                    <td>{job.candidate.full_name}</td>
                                                    <td>{getSubString(job.job.title, 0, 15)}</td>
                                                    <td>{getSubString(job.job.company_details.name, 0, 15)}</td>
                                                    <td>{changeDateFormat(job.created_at)}</td>
                                                    <td>{job.recruiter_action != null ? textCapitalise(job.recruiter_action) : "In Process"}</td>
                                                    <td>{job.applied_from == "frontend" ? "No" : "Yes"}</td>
                                                </tr>
                                            }

                                            ))}
                                        </tbody>
                                    </table>
                                    < div className='my-3'>
                                        <CustomPagination
                                            activePage={activePage}
                                            currentPage={setActivePage}
                                            onPageChange={handlePaginationChange}
                                            totalData={data?.total}
                                            pageLength={data?.per_page}
                                            lastPage={data?.last_page}
                                            tag="pages"
                                            dataFrom={data.from}
                                            dataTo={data.to}
                                        />
                                    </div>
                                </>

                                : <div className='no_jobs' ><img onError={commonServices.imgError} src="../Assets/svgs/search_no_result.svg" alt="" /><h2>No job found!</h2></div>
                            }
                        </div>

                    </>
                }

            </div>

        </AppModal>
    )
}

export default PartnerCandidatesAppliedJobs
