import React from 'react'
import { Navigate } from 'react-router-dom'
import * as storageConstants from '../constants/storageConstants';
import * as commonServices from '../services/common';
import { isCompany, isPartner } from '../utils/authUtil';

function CompanyProtected(props) {
    const Cmp = props.cmp;
    var userRole = commonServices.getLocalData(storageConstants.USER_ROLE);
    var auth = commonServices.getLocalData(storageConstants.AUTH);

    var redirect = auth ? "/" : "/?auth=login&redirect=" + window.location.pathname;

    let partnerRoutes = ["/company/users"];
    let currentRoute = window.location.pathname;

    const isPartnerRoute = () => {
        return partnerRoutes.includes(currentRoute);
    }

    return (
        <>
            {auth && (isCompany() || (isPartnerRoute() && isPartner())) ? <Cmp setJobSearch={props.setJobSearch} /> : <Navigate replace to={redirect} />}
        </>
    )
}

export default CompanyProtected