import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import * as commonServices from "@/services/common";
import defaultAvatar from "@/Assets/svgs/default_avatar.svg";
import "react-phone-number-input/style.css";
import ModalAddVideo from "@/components/Modals/ModalAddVideo";
import { PuffLoader } from "react-spinners";
import { redirectBack } from "@/utils/redirectUtil";
import arrowLeft from "@/Assets/svgs/arrow-left.svg";
import ModalCompanyProfilePreview from "@/components/Modals/ModalCompanyProfilePreview";
import PartnerCompanyProfile from "@/components/PartnerCompanyProfile/PartnerCompanyProfile";
import ProgressBar from "../_widgets/ProgressBar/ProgressBar";
import Button from "../_widgets/Button/Button";
import Input from "../_widgets/Input/Input";
import Dropdown from "../_widgets/Dropdown/Dropdown";
import IconResource from "../_widgets/IconResource/IconResource";
import CustomUpload from "../_widgets/Upload/Upload";
import { Modal } from "react-bootstrap";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: var(--green);
  left: calc(50% - 75px);
  position: fixed;
`;

function AddEditCompanyLayout({
  loading,
  profile,
  showModalVideo,
  setShowModalVideo,
  setProfile,
  setDataAutoSave,
  showModalPreview,
  setShowModalPreview,
  updateCompanyProfile,
  masterData,
  coverPhotoLoading,
  uploadMedia,
  color,
  logoLoading,
  onChange,
  addDomain,
  deleteDomain,
  uploadButton,
  deleteLink,
  handleRemove,
  handleChange,
  handlePreview,
  handleCancel,
  previewTitle,
  previewImage,
  previewOpen,
  deleteCoverPic,
  fileList,
}) {
  return (
    <>
      {loading ? (
        <div className="loader">
          <ClipLoader color={color} loading={true} css={override} size={150} />{" "}
        </div>
      ) : null}

      {profile?.type === "partner" ? (
        <PartnerCompanyProfile company={profile} />
      ) : profile?.type === "hiring-company" ? (
        <div className="add_edit_company main-body">
          <ModalAddVideo
            showModal={showModalVideo}
            setShowModal={setShowModalVideo}
            profile={profile}
            setProfile={setProfile}
            setDataAutoSave={setDataAutoSave}
          />
          <ModalCompanyProfilePreview
            showModal={showModalPreview}
            setShowModal={setShowModalPreview}
            profile={profile}
            updateCompanyProfile={updateCompanyProfile}
            masterData={masterData}
          />
          <div className="body_content tt-container">
            <div className="left_content_1 fixed-content tt-container">
              <div className="row me-0 con_1 py-4">
                <div className="col-12 col-sm-4 col-md-12 col-lg-4 col-xl-4">
                  <h2
                    className="main_t"
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <a className="hand-hover" onClick={() => redirectBack()}>
                      {" "}
                      <img className="back-arrow" src={arrowLeft}></img>
                    </a>{" "}
                    Company Profile
                  </h2>
                </div>
                <div className="col-12 col-sm-4 col-md-6 col-lg-4 col-xl-3 add-edit-progress">
                  <ProgressBar
                    height="10px"
                    background="green"
                    progress={profile.progress}
                    type="with-text"
                    text={"Profile Completeness"}
                  />
                </div>
                <div className="col-12 col-sm-4 col-md-6 col-lg-4 col-xl-5">
                  <div className="form_buttons">
                    <Button
                      buttonType="secondary"
                      text="Cancel"
                      onClick={() => {
                        redirectBack();
                      }}
                    />
                    <Button
                      buttonType="primary"
                      text="Preview & Save"
                      onClick={() => updateCompanyProfile("preview")}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="left">
              <div className="left_content_2">
                <h2>Company Details</h2>

                <div className="uploadCv">
                  {profile?.cover_pic !== null ? (
                    <>
                      <span className="setDeleteClass">
                        {" "}
                        <IconResource
                          type="delete"
                          className="deleteIcon hand-hover"
                          onClick={() => {
                            deleteCoverPic();
                          }}
                        />{" "}
                      </span>
                    </>
                  ) : null}
                  <div
                    className="upload_cv"
                    style={{
                      backgroundImage: `url(${profile?.cover_pic})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "auto",
                      backgroundPosition: "center",
                    }}
                  >
                    <div className="upload_r">
                      <div className="upload_resume ">
                        {coverPhotoLoading === false ? (
                          <>
                            {" "}
                            <input
                              type="file"
                              accept="image/*"
                              onChange={(e) =>
                                uploadMedia(e, "company_cover_pic")
                              }
                            />
                            <div className="preview">
                              <a className="hand-hover" href={undefined}>
                                <img
                                  style={{
                                    margin: "auto",
                                  }}
                                  onError={commonServices.imgError}
                                  src="../Assets/svgs/upload_black.svg"
                                  alt=""
                                />
                                {profile?.cover_pic !== null
                                  ? "Change"
                                  : "Upload"}{" "}
                                Cover Photo
                              </a>
                            </div>
                          </>
                        ) : null}
                        <PuffLoader
                          color={color}
                          loading={coverPhotoLoading}
                          css={override}
                          size={50}
                          height={50}
                          width={50}
                          radios={50}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-flex">
                  <div className="profile_img img-border">
                    <img
                      onError={commonServices.imgError}
                      src={profile?.logo ?? defaultAvatar}
                      alt=""
                    />
                  </div>
                  <div className="">
                    {logoLoading === false ? (
                      <CustomUpload
                        text="Upload Logo"
                        accept="image/*"
                        onChange={(e) => uploadMedia(e, "company")}
                      />
                    ) : null}
                    <PuffLoader
                      color={color}
                      loading={logoLoading}
                      cssOverride={override}
                      size={50}
                      height={50}
                      width={50}
                      radios={50}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  </div>
                </div>
              </div>

              <div className="left_content_3">
                <div className="row ">
                  <div className="col-12 mb-3">
                    <Input
                      type="text"
                      disabled
                      readOnly
                      id="name"
                      placeholder="Company Name"
                      isRequired={true}
                      value={profile?.name ? profile?.name : ""}
                      name="name"
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                  <div className="col-12 mb-3">
                    <Input
                      type="text"
                      id="address"
                      placeholder="Address"
                      isRequired={true}
                      value={profile?.address ? profile?.address : ""}
                      name="address"
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                  <div className="col-12 col-md-6 mb-3">
                    <Dropdown
                      showDefaultOption={true}
                      label={"Employees"}
                      id="size_id"
                      name="size_id"
                      onChange={(e) => onChange(e)}
                      value={profile?.size_id}
                      select={masterData.company_sizes}
                    />
                  </div>
                  <div className="col-12 col-md-6 mb-3">
                    <Input
                      type="text"
                      disabled
                      readOnly
                      placeholder="Website"
                      value={profile?.website ? profile?.website : ""}
                      name="website"
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                  <div className="col-12 mb-3">
                    <Dropdown
                      showDefaultOption={true}
                      label={"Industry"}
                      id="industry-domain"
                      name="industry_domain_id"
                      onChange={(e) => onChange(e)}
                      value={profile?.industry_domain_id}
                      select={masterData.industry_domains}
                    />
                  </div>
                  <div className="col-12 mb-3">
                    <Input
                      type="text"
                      label="Additional Domains"
                      placeholder="Type and press enter to add multiple domains"
                      onKeyPress={(e) => {
                        addDomain(e);
                      }}
                      name="domains"
                    />
                    <div
                      className="d-flex f_t_box mt-2"
                      style={{ maxWidth: "100%" }}
                    >
                      {profile?.domains !== undefined &&
                        profile?.domains.length > 0 ? (
                        <>
                          {" "}
                          {profile?.domains.map((data, k) => {
                            return (
                              <div className="f_t gap-1" key={k}>
                                <p>{data.name}</p>
                                <button
                                  onClick={(e) => {
                                    deleteDomain(e, k);
                                  }}
                                  className="PARTNER_jt btn px-1"
                                >
                                  <img src="../Assets/svgs/x.svg" alt="" />
                                </button>
                              </div>
                            );
                          })}
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>

                <h2>Social Accounts</h2>

                <div className="row">
                  <div className="col-12 col-md-6 mb-3">
                    <Input
                      type="text"
                      placeholder="Instagram"
                      value={profile?.instagram ? profile?.instagram : ""}
                      name="instagram"
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                  <div className="col-12 col-md-6 mb-3">
                    <Input
                      type="text"
                      placeholder="Linkedin"
                      value={profile?.linkedin ? profile?.linkedin : ""}
                      name="linkedin"
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                  <div className="col-12 col-md-6 mb-3">
                    <Input
                      type="text"
                      placeholder="Twitter"
                      value={profile?.twitter ? profile?.twitter : ""}
                      name="twitter"
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                  <div className="col-12 col-md-6 mb-3">
                    <Input
                      type="text"
                      placeholder="Facebook"
                      value={profile?.facebook ? profile?.facebook : ""}
                      name="facebook"
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 mb-1">
                    <h2>About Company</h2>
                    <p>
                      Please write two to three sentences outlining your
                      organisation's unique attributes, separating it from
                      others in a way that would attract great talent in the
                      market.
                    </p>
                    {/* <textarea id="" cols="110" rows="5" value={profile?.description ? profile?.description : ''} name="description" onChange={(e) => onChange(e)}></textarea> */}
                  </div>
                  <div className="col-12 mb-3">
                    <Input
                      type="textarea"
                      id=""
                      cols="110"
                      rows="5"
                      name="description"
                      placeholder="Enter Description"
                      onChange={(e) => onChange(e)}
                      value={profile?.description ? profile?.description : ""}
                    />
                  </div>
                </div>

                <div className="row mb-3" id="Education">
                  <div className="col-12">
                    <h2>Add Video</h2>
                    {profile?.links &&
                      profile?.links.map((link, i) => {
                        return (
                          <div
                            className="emp_box "
                            style={{
                              position: "relative",
                              display: "flex",
                              width: "100%",
                              marginTop: "10px",
                            }}
                          >
                            <div
                              style={{
                                position: "absolute",
                                left: "-30px",
                                height: "100%",
                              }}
                            >
                              <img
                                onError={commonServices.imgError}
                                src="../Assets/svgs/more_vertical.svg"
                                alt=""
                              />
                              <img
                                onError={commonServices.imgError}
                                src="../Assets/svgs/more_vertical.svg"
                                alt=""
                              />
                            </div>
                            <div
                              className="emp_box_det hand-hover"
                              style={{ width: "100%" }}
                              onClick={() => {
                                setShowModalVideo((prev) => !prev);
                              }}
                            >
                              <div>
                                <p className="green_text">{link?.title}</p>
                                <p>{link?.value}</p>
                              </div>
                              <img
                                onError={commonServices.imgError}
                                src="../Assets/svgs/chevron_down.svg"
                                alt=""
                              />
                            </div>
                            <button
                              style={{ right: "-30px" }}
                              alt=""
                              className="trash"
                              onClick={(e) => deleteLink(e, i)}
                            >
                              <img
                                src="../Assets/svgs/trash.svg"
                                alt=""
                                width={20}
                              />{" "}
                            </button>
                          </div>
                        );
                      })}

                    <div className="mt-2 col-md-3 col-6">
                      <a
                        className="add-more"
                        onClick={() => {
                          setShowModalVideo((prev) => !prev);
                        }}
                      >
                        {" "}
                        <img src="../Assets/svgs/plus_circle.svg"></img> Add
                        More
                      </a>
                    </div>

                    {/* <button className="add_btn " style={{ marginTop: "15px", display: "flex" }} onClick={() => { setShowModalVideo(prev => !prev); }}><img onError={commonServices.imgError} src="../Assets/svgs/plus_circle.svg" alt="" /> Add More</button> */}
                  </div>
                </div>

                <div className="row mb-3" id="Media">
                  <div className="col">
                    <h2>Add Photos</h2>
                  </div>

                  <div className="img-upload__wrapper">
                    <div className="photos">
                      <Modal backdrop="static" show={previewOpen} onHide={handleCancel}>
                        <Modal.Header closeButton>
                          <Modal.Title>{previewTitle}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <img
                            alt="example"
                            style={{
                              width: "100%",
                            }}
                            src={previewImage}
                          />
                        </Modal.Body>
                      </Modal>
                    </div>
                    <CustomUpload
                      onChange={(e) => {
                        uploadMedia(e, "company_media");
                      }}
                      type="image"
                      fileList={fileList}
                      handlePreview={handlePreview}
                      onRemove={handleRemove}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default AddEditCompanyLayout;
