import React from "react";

import AppModal from "./AppModal";

const ModalUserChatDelete = ({ showModal, setShowModal, onSubmit }) => {
  const handleSubmit = () => {
    onSubmit();
    setShowModal((prev) => !prev);
  };

  return (
    <>
      <AppModal
        title={"Delete Chat"}
        isOpen={showModal}
        closeModal={() => {
          setShowModal((prev) => !prev);
        }}
        submit={handleSubmit}
        isFooter={true}
        size={"md"}
      >
        <div className="modal_body">
          <div className="row">
            <div className="col">
              <p>Are you sure you want to delete this chat?</p>
            </div>
          </div>
        </div>
      </AppModal>
    </>
  );
};

export default ModalUserChatDelete;
