import React, { useState, useEffect } from 'react'
import { Bar, Pie, getElementAtEvent, Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
} from "chart.js";
import { PuffLoader } from 'react-spinners';
import { css } from "@emotion/react";
import './Common.scss';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: var(--green);
  left: calc(50% - 75px);
  position: fixed;
`;

function Chart({ chartData = {}, text = "", setGraphDataIndex = "", setGraphDatasetIndex = "", chartRef, graphType = "bar", loading = false }) {

  let [color, setColor] = useState("#14BC9A");

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    ArcElement,
    Legend,
    ChartDataLabels
  );

  const options = {
    plugins: {
      title: {
        display: true,
        text: text
      },
      datalabels: {
        display: chartData?.showDataLabels !== undefined ? chartData?.showDataLabels : false,
        color: 'black'
      }
    },
    responsive: true,
    scales: {
      x: {
        stacked: true
      },
      y: {
        stacked: true
      }
    }
  };

  const barVerticalOptions = {
    plugins: {
      title: {
        display: true,
        text: text
      },
      datalabels: {
        display: chartData?.showDataLabels !== undefined ? chartData?.showDataLabels : false,
        color: 'black'
      },
      legend: {
        display: chartData?.isLegend !== undefined ? chartData?.isLegend : true,
      }
    },
    scales: {
      x: {
        display: chartData?.showXAxisLabels !== undefined ? chartData?.showXAxisLabels : true,
        grid: {
          display: false
        }
      },
      y: {
        display: chartData?.showYAxisLabels !== undefined ? chartData?.showYAxisLabels : true,
      },
    },
    responsive: true,
    borderRadius: chartData?.borderRadius !== undefined ? chartData?.borderRadius : 10,
    barThickness: chartData?.barThickness !== undefined ? chartData?.barThickness : 5,
    indexAxis: graphType === 'horizontalBar' ? 'y' : 'x',
  };

  const doughnutOptions = {
    plugins: {
      title: {
        display: true,
        text: text
      },
      legend: {
        display: chartData?.isLegend !== undefined ? chartData?.isLegend : true,
        position: chartData?.legendPosition !== undefined ? chartData?.legendPosition : "top",
        align: "middle"
      },
      datalabels: {
        display: chartData?.showDataLabels !== undefined ? chartData?.showDataLabels : false,
        color: 'black',
      }
    },
    responsive: true,
    cutout: chartData?.cutout !== undefined ? chartData?.cutout : "90%"
  };

  const pieOptions = {
    plugins: {
      title: {
        display: true,
        text: text
      },
      datalabels: {
        display: chartData?.showDataLabels !== undefined ? chartData?.showDataLabels : false,
        color: 'black'
      }
    },
    responsive: true
  };

  const textCenter = {
    id: 'textCenter',
    beforeDatasetsDraw(chart, args, pluginOptions) {
      const { ctx, data } = chart;

      ctx.save();
      ctx.font = 'bolder Plus Jakarta Sans", sans-serif';
      ctx.fillStyle = '#2E2C34';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(chartData?.textCenter !== undefined ? chartData?.textCenter : '', chart.getDatasetMeta(0).data[0].x, chart.getDatasetMeta(0).data[0].y)
    }
  }

  return (
    <>
      <PuffLoader
        color={color}
        loading={loading}
        cssOverride={override}
        size={50}
        height={50}
        width={50}
        radios={50}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
      {!loading && Object.keys(chartData).length > 0 ?
        graphType === "pie" ?
          <div className='col-md-6' style={{ "marginLeft": "25%" }}>
            <Pie
              options={pieOptions}
              ref={chartRef}
              onClick={(event) => {
                if (event.type === "click" && getElementAtEvent(chartRef.current, event)[0] !== undefined) {
                  console.log('getElementAtEvent(chartRef.current, event)-->', getElementAtEvent(chartRef.current, event));
                  setGraphDatasetIndex(getElementAtEvent(chartRef.current, event)[0].index);
                }
              }}
              data={chartData}
            />
          </div>
          : graphType === "Doughnut" ?
            <div className='col-md-6' style={{ "marginLeft": "25%" }}>
              <Doughnut
                options={doughnutOptions}
                ref={chartRef}
                data={chartData}
                plugins={[textCenter]}
              />
            </div>
            : (graphType === "verticalBar" || graphType === "horizontalBar") ?
              <Bar
                options={barVerticalOptions}
                ref={chartRef}
                onClick={(event) => {
                  if (event.type === "click" && getElementAtEvent(chartRef.current, event)[0] !== undefined) {
                    console.log(getElementAtEvent(chartRef.current, event)[0].index);
                    setGraphDataIndex(getElementAtEvent(chartRef.current, event)[0].index);
                    setGraphDatasetIndex(getElementAtEvent(chartRef.current, event)[0].datasetIndex);
                  }
                }}
                data={chartData}
              />
              :
              <Bar
                options={options}
                ref={chartRef}
                onClick={(event) => {
                  if (event.type === "click" && getElementAtEvent(chartRef.current, event)[0] !== undefined) {
                    console.log(getElementAtEvent(chartRef.current, event)[0].index);
                    setGraphDataIndex(getElementAtEvent(chartRef.current, event)[0].index);
                    setGraphDatasetIndex(getElementAtEvent(chartRef.current, event)[0].datasetIndex);
                  }
                }}
                data={chartData}
              />


        : null
      }

    </>
  )
}

export default Chart