import React from "react";
import "./Modal.scss";
import { useNavigate } from "react-router-dom";

import fireworkimage from "@/Assets/images/firework.png";
import AppModal from "./AppModal";

function ModalAddJobReferralSuccess({ showModal, setShowModal }) {
  const navigate = useNavigate();

  return (
    <>
      <AppModal
        title={"Get Referrals"}
        isOpen={showModal}
        closeModal={() => setShowModal((prev) => !prev)}
        // submit={(e) => navigate('/job/boost/' + id)}
        size={"md"}
        // isFooter={true}
      >
        <div className="modal_body py-1">
          <div className="row refer-success job-referrals info-content">
            <img src={fireworkimage} alt="success" />
            <p className="my-3">This job is now open for referrals</p>

            <div className="col-12">
              <div className="form_buttons">
                <button
                  className="btn btn-primary w-auto mt-3"
                  style={{ marginRight: "10px", outline: "0px", width: "22%" }}
                  onClick={() => navigate("/posted")}
                >
                  Go to jobs panel
                </button>
              </div>
            </div>
          </div>
        </div>
      </AppModal>
    </>
  );
}

export default ModalAddJobReferralSuccess;
