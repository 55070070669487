import React from 'react'
import AppModal from './AppModal';


const ModalUserChatUnblock = ({ showModal, setShowModal, onSubmit }) => {



    const handleSubmit = () => {
        onSubmit({ "status": 'unblock' })
        setShowModal(prev => !prev);
    }

    return (
        <>
            <AppModal
                title={"Unblock User"}
                isOpen={showModal}
                closeModal={() => { setShowModal(prev => !prev); }}
                submit={handleSubmit}
                isFooter={true}
                submitText={"Unblock"}
                size={"sm"}
            >
                <div className="modal_body">

                    <div className="row">
                        <div className="col">
                            <p>Are you sure you want to unblock this user?</p>
                        </div>
                    </div>

                </div>
            </AppModal>
        </>
    )
}

export default ModalUserChatUnblock