/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import greenDoneImage from "../../../Assets/images/greenDone.png"
import greenDotImage from "../../../Assets/images/greenDot.png"
import greyDotImage from "../../../Assets/images/greyDot.png"
import "./RegistrationSteps.scss";
import PersonalDetails from "./Steps/PersonalDetails";
import MobileVerification from "./Steps/MobileVerification";
import EmailVerification from "./Steps/EmailVerification";
import WorkProfileDetail from "./Steps/WorkProfileDetail";
import { isCandidate, isEvaluator } from "../../../utils/authUtil";
import ProfileInReview from "./Steps/ProfileInReview";
import ModalApplyJobReferralInfo from "../../Modals/ModalApplyJobReferralInfo";

export default function RegistrationSteps({ submitTncState, setTncIsOpen, currentStep, setCurrentStep, profile, setProfile, selectedProfile, referralCode, setReferralCode, isHeaderUpdate, setIsHeaderUpdate, isGoogleLogin, showModalApplyJob, referredData, setShowStep, dataFromResume }) {

    const navigate = useNavigate();
    let [isProfileInReview, setIsProfileInReview] = useState(false);
    const [showModalApply, setShowModalApply] = useState(false);
    const [showApplyPopup, setShowApplyPopup] = useState(false);
    const [isShowModalApplyJob, setIsShowModalApplyJob] = useState(showModalApplyJob);
    const [referralData, setReferralData] = useState(referredData);
    const [final, setFinal] = useState('');
    const [nextStep, setNextStep] = useState('');
    const [userId, setUserId] = useState(null);

    const getStepImage = (step) => {
        if (step === 2 && profile.is_verified !== undefined && profile.is_verified) {
            return greenDoneImage;
        } else if (currentStep === step) {
            return greenDotImage;
        } else if (currentStep > step) {
            return greenDoneImage;
        } else if (currentStep < step) {
            return greyDotImage;
        }
    }

    const navigateUser = () => {
        if (isCandidate()) {
            if (isShowModalApplyJob !== undefined && isShowModalApplyJob && referralData !== undefined && !showApplyPopup) {
                setShowModalApply(!showModalApply);
                setShowApplyPopup(true);
            } else {
                navigate('/',
                    {
                        state: {
                            "nextStep": nextStep
                        }
                    });
            }

        } else if (isEvaluator()) {
            setIsProfileInReview(!isProfileInReview);
        }
    }


    useEffect(() => {

        window.scrollTo(0, 0);

    }, [])

    return (
        <>
            {isProfileInReview ?
                <ProfileInReview />
                :
                <div className="tt-container">
                    <div className="candidate-registration main-body">
                        <div
                            id="recaptcha-container"
                            class="justify-center flex"
                            style={{ display: 'none' }}
                        ></div>
                        <ModalApplyJobReferralInfo showModal={showModalApply} setShowModal={setShowModalApply} referredData={referralData} />
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-12 mb-2">
                                <ul class="nav flex-column">
                                    <li class="nav-item">
                                        <a style={{
                                            padding: "10px 0"
                                        }} class={currentStep === 1 ? "nav-link d-flex active" : "nav-link d-flex"} >
                                            <img src={getStepImage(1)} alt="img" />
                                            <div className="detail">
                                                <p>Personal details</p>
                                                <span>Please provide your details</span>
                                            </div>

                                        </a>
                                    </li>

                                    <li class="nav-item">
                                        <a
                                            style={{
                                                padding: "10px 0"
                                            }}
                                            class={currentStep === 2 ? "nav-link d-flex active" : "nav-link d-flex"} >
                                            <img src={getStepImage(2)} alt="img" />
                                            <div className="detail">
                                                <p>Verify Email</p>
                                                <span>Enter the OTP sent to your email</span>
                                            </div>

                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a
                                            style={{
                                                padding: "10px 0"
                                            }}
                                            class={currentStep === 3 ? "nav-link d-flex active" : "nav-link d-flex"} >
                                            <img src={getStepImage(3)} alt="img" />
                                            <div className="detail">
                                                <p>Verify Mobile</p>
                                                <span>Enter the OTP sent to your mobile</span>
                                            </div>

                                        </a>
                                    </li>


                                    <li class="nav-item">
                                        <a
                                            style={{
                                                padding: "10px 0"
                                            }}
                                            class={currentStep === 4 ? "nav-link d-flex active" : "nav-link d-flex"} >
                                            <img src={getStepImage(4)} alt="img" />
                                            <div className="detail">
                                                <p> {
                                                    selectedProfile === "evaluator" ? ("Complete your profile") : ("Complete your workprofile")
                                                }</p>
                                                <span>Complete {
                                                    selectedProfile === "evaluator" ? ("your profile") : ("your workprofile")
                                                } to get better opportunities</span>
                                            </div>

                                        </a>
                                    </li>

                                </ul>
                            </div>
                            <div className="col-lg-8 col-md-8 col-sm-12">
                                {currentStep === 1 ?
                                    <PersonalDetails setUserId={setUserId} submitTncState={submitTncState} setTncIsOpen={setTncIsOpen} currentStep={currentStep} setCurrentStep={setCurrentStep} profile={profile} setProfile={setProfile} selectedProfile={selectedProfile} referralCode={referralCode} setReferralCode={setReferralCode} isHeaderUpdate={isHeaderUpdate} setIsHeaderUpdate={setIsHeaderUpdate} setFinal={setFinal} dataFromResume={dataFromResume} />
                                    :
                                    (currentStep === 2 ?
                                        <EmailVerification userId={userId} selectedProfile={selectedProfile} profile={profile} setShowStep={setShowStep} isHeaderUpdate={isHeaderUpdate} setIsHeaderUpdate={setIsHeaderUpdate} setCurrentStep={setCurrentStep} />
                                        :
                                        (currentStep === 3 ?
                                            <MobileVerification userId={userId} selectedProfile={selectedProfile} currentStep={currentStep} setCurrentStep={setCurrentStep} profile={profile} setProfile={setProfile} isHeaderUpdate={isHeaderUpdate} setIsHeaderUpdate={setIsHeaderUpdate} final={final} setFinal={setFinal} setIsShowModalApplyJob={setIsShowModalApplyJob} setReferralData={setReferralData} setNextStep={setNextStep} />
                                            :
                                            (currentStep === 4 ?
                                                <WorkProfileDetail selectedProfile={selectedProfile} navigateUser={navigateUser} isHeaderUpdate={isHeaderUpdate} setIsHeaderUpdate={setIsHeaderUpdate} />
                                                : null))
                                    )
                                }
                            </div>
                        </div>
                    </div>

                </div>
            }
        </>
    )
}