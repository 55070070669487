export function redirectBack() {
    if (Object.keys(window.history.state).length <= 1) {
        window.close();
    } else {
        window.history.go(-1);
    }
}

export function addTabInHistory(tab) {
    window.history.pushState({
        tabForId: tab
    }, null, getCurrentPathName() + "?tab=" + tab);
}

export function getCurrentPathName() {
    return window.location.pathname;
}

export function getCurrentTab() {
    var url_string = window.location.href;
    var url = new URL(url_string);
    return url.searchParams.get("tab");
}

export function addSearchParamsInHistory(name, value) {
    var queryString = window.location.search;
    var params = new URLSearchParams(queryString);

    if (params.has(name) && params.getAll(name).includes(value.toString())) {
        return;
    }

    if (name === "page" || name === "min_exp" || name === "max_exp" || name === "travel" || name === "travel_percent" || name === "eeo" || name === "start_date" || name === "end_date" || name === "min_budget" || name === "max_budget" || name === "armedForces" || name === "differentlyAbled" || name === "herCareerReboot" || name === "layoff" || name === "q") {
        var re = new RegExp("([?&])" + name + "=.*?(&|$)", "i");
        var separator = queryString.indexOf('?') !== -1 ? "&" : "?";
        if (queryString.match(re)) {
            queryString = queryString.replace(re, '$1' + name + "=" + value + '$2');
        }
        else {
            queryString = queryString + separator + name + "=" + value;
        }
    } else {
        if (queryString === "") {
            queryString = "?" + name + "=" + value;
        } else {
            queryString += "&" + name + "=" + value;
        }
    }

    window.history.pushState({}, null, getCurrentPathName() + queryString);
}

export function addMultipleSearchParamsInHistory(names, values) {
    var queryString = window.location.search;
    var params = new URLSearchParams(queryString);

    for (var i = 0; i < names.length; i++) {
        if (names[i] === 'start_date') {
            if (params.has('start_date')) {
                queryString = queryString.replace(new RegExp(`start_date=[^&]*`), `${names[i]}=${values[i]}`);
                continue;
            }
        } else if (names[i] === 'end_date') {
            if (params.has('end_date')) {
                queryString = queryString.replace(new RegExp(`end_date=[^&]*`), `${names[i]}=${values[i]}`);
                continue;
            }
        }

        if (i === 0 && queryString === "") {
            queryString += "?" + names[i] + "=" + values[i];
        } else {
            queryString += "&" + names[i] + "=" + values[i];
        }
    }

    window.history.pushState({}, null, getCurrentPathName() + queryString);
}

export function removeSearchParamsInHistory(keyRemove, valueRemove) {
    const searchParams = new URLSearchParams(window.location.search);

    var queryString = '?';

    searchParams.forEach((value, key) => {
        if (!(key.toString() === keyRemove.toString() && (value.toString() === valueRemove.toString() || valueRemove === undefined))) {
            queryString += key + "=" + value + "&";
        }
    });

    window.history.pushState({}, null, getCurrentPathName() + queryString);
}

export function removeSearchParamsInHistoryWithoutValue(keysToRemove) {
    const searchParams = new URLSearchParams(window.location.search);
    let queryString = '';

    searchParams.forEach((value, key) => {
        if (!keysToRemove.includes(key)) {
            queryString += key + "=" + value + "&";
        }
    });

    queryString = queryString.slice(0, -1); // Remove the trailing "&"

    window.history.pushState({}, null, getCurrentPathName() + (queryString ? '?' + queryString : ''));
}


export function removeMultipleSearchParamsInHistory(keyRemoveArr, valueRemoveArr) {
    const searchParams = new URLSearchParams(window.location.search);
    var queryString = '?';

    searchParams.forEach((value, key) => {
        var isExist = false;
        for (var i = 0; i < keyRemoveArr.length; i++) {
            if (key.toString() === keyRemoveArr[i].toString() && value.toString() === valueRemoveArr[i].toString()) {
                isExist = true;
                break; // Exit loop once a match is found
            }
        }
        if (!isExist) {
            queryString += key + "=" + value + "&";
        }
    });

    // Remove trailing '&' if exists
    queryString = queryString.replace(/&$/, '');

    window.history.pushState({}, null, getCurrentPathName() + queryString);
}

export function removeAllParams() {
    window.history.pushState({}, null, getCurrentPathName());
}

export function closeCurrentTab() {
    window.close();
}

export function getCurrentUrl() {
    return window.location.href;
}

export function redirectToPrivacy() {
    window.open("/terms", "_blank");
}