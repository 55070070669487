import React, { useEffect, useRef, useState } from 'react'
import * as commonServices from '@/services/common';
import * as ApiHelper from '@/services/apiHelper';
import * as toast from "@/wrapper/toast";
import * as storageConstants from '@/constants/storageConstants';
import * as errors from '@/constants/stringConstants';

import { getUserCompanyId } from '@/utils/authUtil';
import { ClearFileValues } from '../../utils/fileUtils';

import { getDomainFromUrl, isNullOrEmpty } from '@/utils/stringUtils';

import { checkUrl } from '@/utils/validation';
import { jsonToFormData } from '../../wrapper/formData';

import { useNavigate } from 'react-router-dom';

import { css } from '@emotion/react';
import EditPartnerCompanyLayout from './EditPartnerCompanyLayout';
import { removeDuplicates } from '../../utils/arrayUtils';
import { LOCATION_THRESHOLD } from '../../constants/storageConstants';
import { addDataOnFocusOut } from '../../services/common';



const override = css`
  display: block;
  margin: 0 auto;
  border-color: var(--green);
  left: calc(50% - 75px);
    position: fixed;
`;


const EditPartnerCompany = () => {

    let [color] = useState("#000000");

    const [coverPhotoLoading, setCoverPhotoLoading] = useState(false);

    const [loading, setLoading] = useState(false)

    const [profile, setProfile] = useState({
        addresses: [{
            value: ""
        }]
    })

    const [preferred_location, setPreferred_location] = useState(profile?.addresses ?? []);

    const [preferredLocationName, setPreferredLocationName] = useState(profile?.preferred_address_names ?? []);

    const [locSuggestion, setLocSuggestion] = useState([]);

    let locationRef = useRef();

    const [courseObj, setCourseObj] = useState({
        name: "",
        description: "",
        url: "",
        image: "",
        image_url: null
    })

    const [courseModalOpen, setCourseModalOpen] = useState(false)

    const [fileList, setFileList] = useState([]);

    useEffect(() => {
        let handler = (event) => {
            if (!locationRef.current.contains(event.target)) {
                addDataOnFocusOut(locSuggestion, setLocSuggestion, setPreferred_location, setPreferredLocationName);
                document.getElementById('search_location').value = '';
            }
        }
        document.addEventListener("mousedown", handler);

        return () => {
            document.removeEventListener("mousedown", handler)
        }
    });

    const triggerSelect = (event) => {
        // const list = [...document.querySelectorAll('.sugg_p')];
        const active = document.querySelector('.sugg_p.active');
        // let i = list.indexOf(active);

        if (event.which === 13 || event.keyCode === 13) {
            if (active) {
                active.click();
            }
        }

    }

    const onChangeHandler_location = (text) => {
        if (text.length > LOCATION_THRESHOLD) {
            commonServices.getLocations(text).then((locations) => {
                setLocSuggestion(locations);
            });
        } else {
            setLocSuggestion([]);
        }
    }

    const triggerClickDown = (event) => {
        if (event.which === 27 || event.keyCode === 27) {
            setLocSuggestion([]);
        } else if (event.which === 9 || event.keyCode === 9) {
            if (event.target.name === 'locations') {
                addDataOnFocusOut(locSuggestion, setLocSuggestion, setPreferred_location, setPreferredLocationName);
                event.target.value = '';
            }
        }
    }

    useEffect(() => {
        getLatestProfile();
    }, [])

    const validateData = (data) => {
        let status = true;
        if (profile.name === "") {
            status = false;
            toast.warn(errors.COMPANY_NAME_REQUIRED);
        }

        else if (!isNullOrEmpty(profile.instagram) && !checkUrl(profile.instagram)) {
            status = false;
            toast.warn(errors.VALID_INSTAGRAM_LINK);
        }
        else if (!isNullOrEmpty(profile.linkedin) && !checkUrl(profile.linkedin)) {
            status = false;
            toast.warn(errors.VALID_LINKEDIN_LINK);
        }
        else if (!isNullOrEmpty(profile.twitter) && !checkUrl(profile.twitter)) {
            status = false;
            toast.warn(errors.VALID_TWITTER_LINK);
        }
        else if (!isNullOrEmpty(profile.facebook) && !checkUrl(profile.facebook)) {
            status = false;
            toast.warn(errors.VALID_FACEBOOK_LINK);
        }

        return status;
    }

    const updateCompanyProfile = async (action = 'preview') => {

        const profileObj = {
            ...profile,
            addresses: [...profile?.addresses?.map(item => item.value)]
        }

        if (validateData(profileObj)) {

            const formDataSeralize = jsonToFormData(profileObj)

            setLoading(true);
            await ApiHelper.addEditCompany(formDataSeralize).then((response) => {
                setLoading(false);
                if (response.isSuccess === true) {
                    getLatestProfile();
                    ClearFileValues();
                    toast.success(response.message);
                }
                else {
                    toast.warn(response.message);
                }
            });

        }
    }


    const getLatestProfile = async () => {
        setLoading(true);
        await ApiHelper.getCompanyDetails(getUserCompanyId()).then((response) => {
            setLoading(false);
            if (response.isSuccess === true) {
                setProfile({
                    ...response?.data,

                    addresses: response?.data?.addresses ? [...response?.data?.addresses?.map((item, index) => ({ value: item?.address }))] : [{ value: "" }],
                    courses: response?.data?.courses ? [...response?.data?.courses] : []
                });
                setFileList(response?.data?.medias);
                var profile = commonServices.getLocalData(storageConstants.PROFILE);
                profile.company.progress = response?.data?.progress;

                commonServices.storeLocalData(storageConstants.PROFILE, profile);
            } else {
                toast.warn(response.message);
            }
        });
    }


    //// ADD NEW COURSE ////

    const addCourse = (e) => {

        if (!courseObj.title) {
            toast.warn(errors.COURSE_NAME_REQUIRED);
            return
        }
        else if (!courseObj.image) {
            toast.warn(errors.COURSE_IMAGE_REQUIRED)
            return

        }
        else if (!courseObj.url) {
            toast.warn(errors.COURSE_URL_REQUIRED)
            return

        }
        else if (!courseObj.description) {
            toast.warn(errors.COURSE_DESCRIPTION_REQUIRED)
            return
        } else if (!isNullOrEmpty(courseObj.url) && !checkUrl(courseObj.url)) {
            toast.warn(errors.VALID_COURSE_URL);
            return

        }





        setProfile({
            ...profile,
            courses: [
                ...profile.courses,
                {
                    title: courseObj.title,
                    url: courseObj.url,
                    description: courseObj.description,
                    image: courseObj.image,
                    img_src: courseObj.img_src
                }
            ]
        })

        setCourseObj({
            name: "",
            url: "",
            description: ""
        })

        setCourseModalOpen(false)

    }

    const [logoLoading, setLogoLoading] = useState(false)

    const setPuffLoading = (type, status) => {
        if (type === 'company') {
            setLogoLoading(status);
        } else if (type === 'company_cover_pic') {
            setCoverPhotoLoading(status);
        }
    }

    const uploadMedia = async (e, type) => {
        const formMedia = new FormData();
        if (type === 'company_media') {
            formMedia.append('file', e);
        } else {
            formMedia.append('file', e.target.files[0]);
        }

        formMedia.append('type', type);
        setPuffLoading(type, true);

        await ApiHelper.uploadMedia(formMedia).then((response) => {
            ClearFileValues();

            if (response.isSuccess === true) {
                setPuffLoading(type, false);
                if (type === 'company') {
                    setProfile({ ...profile, 'logo': response.data.file_url })
                } else if (type === 'company_cover_pic') {
                    setProfile({ ...profile, 'cover_pic': response.data.file_url })
                } else if (type === 'company_media') {
                    setFileList(response?.data?.data);
                    setProfile({ ...profile, 'medias': response.data.data })
                }
            }
            else {
                setPuffLoading(type, false);
                toast.warn(response.message);
            }
        });

    }

    const deleteCoverPic = async () => {
        setLoading(true);
        const formData = new FormData();
        formData.append('id', profile.id);
        formData.append('cover_pic', '');
        await ApiHelper.addEditCompany(formData).then((response) => {
            setLoading(false);
            if (response.isSuccess === true) {
                getLatestProfile();
                toast.success(response.message);
            }
            else {
                toast.warn(response.message);
            }
        });
    }

    const navigate = useNavigate()

    const setImgSrc = (e) => {

        const reader = new FileReader();

        reader.readAsDataURL(e.target.files[0]);

        reader.onload = () => {
            setCourseObj({ ...courseObj, img_src: reader?.result, image: e.target.files[0] })
        };


    }

    return (
        <EditPartnerCompanyLayout
            color={color}
            coverPhotoLoading={coverPhotoLoading}
            loading={loading}
            profile={profile}
            setProfile={setProfile}
            courseObj={courseObj}
            setCourseObj={setCourseObj}
            courseModalOpen={courseModalOpen}
            setCourseModalOpen={setCourseModalOpen}
            updateCompanyProfile={updateCompanyProfile}
            addCourse={addCourse}
            logoLoading={logoLoading}
            uploadMedia={uploadMedia}
            deleteCoverPic={deleteCoverPic}
            navigate={navigate}
            setImgSrc={setImgSrc}
            locationRef={locationRef}
            locSuggestion={locSuggestion}
            onChangeHandler_location={onChangeHandler_location}
            triggerSelect={triggerSelect}
            triggerClickDown={triggerClickDown}
        />
    )
}

export default EditPartnerCompany;