import React, { useState, useEffect } from 'react'
import './Common.scss'
import { useNavigate } from "react-router-dom";
import { isCompanyAdmin } from '../../utils/authUtil';
import Text from '../_widgets/Text/Text';
import * as toast from "@/wrapper/toast";


function Common({ image, title, callBack = '', buyTTCash }) {
  const navigate = useNavigate();

  const buyMoreCash = () => {
    if (isCompanyAdmin()) {
      navigate('/company/users', {
        state: {
          showPaymentGateay: true
        }
      });
    } else {
      toast.warn("Ask your Company Admin for more TT Cash")
    }
  }


  return (
    <div className="common">
      <div className="no-result m-auto">
        <div className="blank_content d-flex">
          <img
            src={image}
            alt={title}
          />
          <Text type='h3' text={title} className={"my-3"} />
          <div className="d-flex gap-2 align-items-center">

            <p>
              {callBack === '' ?
                <button className='go-home' onClick={() => { navigate('/') }} >Go to Home</button>
                :
                <button className='go-home' onClick={() => { callBack() }} >Go back</button>
              }
            </p>

            {buyTTCash ?
              <a href="javascript:void(0)" style={{ fontSize: "0.94em", fontWeight: "500", color: "var(--green)", textDecoration: "underline", marginLeft: "10px" }} onClick={() => { buyMoreCash() }}>Buy/Allocate</a>
              : null
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Common