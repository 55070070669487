/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
import "./UserProfileViewById.scss";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import * as storageConstants from "@/constants/storageConstants";
import defaultAvatar from "@/Assets/svgs/default_avatar.svg";
import * as ApiHelper from "@/services/apiHelper";
import * as commonServices from "@/services/common";
import ClipLoader from "react-spinners/ClipLoader";

import { css } from "@emotion/react";
import email from "@/Assets/svgs/email.svg";
import phone from "@/Assets/svgs/phone.svg";
import marker from "@/Assets/svgs/marker.svg";
import * as toast from "@/wrapper/toast";
import { APP_NAME, DATA } from "@/constants/storageConstants";
import ModalUserMessage from "../Modals/ModalUserMessage";
import { getUserId } from "@/utils/authUtil";
import { MESSAGE_REQUIRED, RESUME_LINK_NOT_FOUND } from "@/constants/stringConstants";
import { isNullOrEmpty, textCapitalise } from "@/utils/stringUtils";
import {
  getCandidateExperienceText,
  getSkillPercentage,
} from "@/services/common";
import rectangle from "@/Assets/svgs/rectangle.svg";
import save from "@/Assets/svgs/save.svg";
import message_white from "@/Assets/svgs/message-white.svg";
import { isDraftJob } from "@/utils/job";
import ModalChangeCandidateStatus from "../Modals/ModalChangeCandidateStatus";
import ReactPlayer from "react-player";
import ModalInformation from "../Modals/ModalInformation";
import { BsLock } from "react-icons/bs";
import { highlightText } from "../../utils/stringUtils";
import ReadMoreText from "../Common/ReadMoreText";
import CustomLink from "../_widgets/Link/Link";
import Button from "../_widgets/Button/Button";
import { addCandidateToSaveLater, getLocalData, viewSimilarCandidates } from "../../services/common";
import { getMonthYearFromDate, getYearFromDate } from "../../utils/dateUtil";
import { getIdWithPrefix } from "../../utils/userProfileID";
import { OverlayTrigger, ProgressBar, Tooltip } from "react-bootstrap";
import Text from "../_widgets/Text/Text";
import ModalShowSimilarCandidates from "../Modals/ModalShowSimilarCandidates";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: var(--green);
  left: calc(50% - 75px);
  position: fixed;
`;

function UserProfileViewById() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  let job_id = searchParams.get("job_id");
  let gig_id = searchParams.get("gig_id");
  const [keyword, setKeyword] = useState(null);

  useEffect(() => {
    let q = searchParams.get("q");
    setKeyword(q);
  }, [searchParams]);

  const currentUser = commonServices.getLocalData(storageConstants.PROFILE);

  let [loading, setLoading] = useState(false);
  let [color] = useState("#000000");

  const [profileId] = useState(getUserId());

  let params = useParams();
  const user_id = params.user_id;
  const componentRef = useRef();

  const [profile, setProfile] = useState([]);
  const [workProfile, setWorkProfile] = useState([]);

  const [experience, setExperience] = useState([]);
  const [degree, setDegree] = useState([]);
  const [certificate, setCertificate] = useState([]);
  const [award, setAward] = useState([]);
  const [skills, setSkills] = useState([]);
  const [evaluation, setEvaluation] = useState(false);
  const [evaluationCharge, setEvaluationCharge] = useState(0);
  const [evaluationValidity, setEvaluationValidity] = useState("N/A");

  const [showModalUserMessage, setShowModalUserMessage] = useState(false);
  const [showModalChangeStatus, setShowModalChangeStatus] = useState(false);
  const [showModalBuyEvaluation, setShowModalBuyEvaluation] = useState(false);
  const [jobDetails, setJobDetails] = useState({});
  const [currentStatus, setCurrentStatus] = useState("");

  const getProfileData = async () => {
    setLoading(true);
    let params = "?id=" + user_id;
    if (job_id !== undefined && job_id !== null) {
      params += "&job_id=" + job_id;
    }
    if (gig_id !== undefined && gig_id !== null) {
      params += "&gig_id=" + gig_id;
    }
    await ApiHelper.getUserWorkProfile(params).then((response) => {
      setLoading(false);
      if (response.isSuccess === true) {
        addProfileTracking(1, 0);
        var workProfileDetails = response.data.user_work_profile_detail;
        setProfile(response.data);
        setWorkProfile(response.data.user_work_profile);
        setEvaluation(response.data.evaluation);
        setEvaluationValidity(response.data.evaluation_validity);
        setSkills(response.data.skills);
        for (var i = 0; i < workProfileDetails.length; i++) {
          let arr = [];
          if (workProfileDetails[i].type.localeCompare("experience") === 0) {
            arr = experience;
            setExperience([]);
            arr.unshift(workProfileDetails[i]);
            setExperience(arr);
          } else if (workProfileDetails[i].type.localeCompare("degree") === 0) {
            arr = degree;
            setDegree([]);
            arr.unshift(workProfileDetails[i]);
            setDegree(arr);
          } else if (
            workProfileDetails[i].type.localeCompare("certificate") === 0
          ) {
            arr = certificate;
            setCertificate([]);
            arr.unshift(workProfileDetails[i]);
            setCertificate(arr);
          } else if (workProfileDetails[i].type.localeCompare("award") === 0) {
            arr = award;
            setAward([]);
            arr.unshift(workProfileDetails[i]);
            setAward(arr);
          }
        }
      } else {
        navigate("/access-denied?message=" + response.message);
      }
    });
  };

  const jobDetail = async () => {
    setLoading(true);
    await ApiHelper.getJobDetail(job_id).then((response) => {
      if (response.isSuccess === true) {
        setJobDetails(response.data);
        let applicants = response.data.applicants;
        if (applicants.shortlisted.length > 0) {
          const found = applicants.shortlisted.some(
            (el) => parseInt(el.user_id) === parseInt(user_id)
          );
          if (found) {
            setCurrentStatus("Shortlisted");
          }
        }

        if (applicants.rejected.length > 0) {
          const found = applicants.rejected.some(
            (el) => parseInt(el.user_id) === parseInt(user_id)
          );
          if (found) {
            setCurrentStatus("Rejected");
          }
        }
      } else if (response.code === 400) {
        toast.warn(response.message);
        navigate("/access-denied");
      } else {
        toast.warn(response.message);
      }
      setLoading(false);
    });
  };

  const addProfileTracking = (
    is_profile_viewed = 0,
    is_profile_downloaded = 0
  ) => {
    var formData = new FormData();
    formData.append("candidate_id", user_id);
    formData.append("is_profile_viewed", is_profile_viewed);
    formData.append("is_profile_downloaded", is_profile_downloaded);
    ApiHelper.addProfileTrackingData(formData).then((response) => {
      console.log(response);
    });
  };

  useEffect(() => {
    if (typeof user_id !== "undefined") {
      getProfileData();
      window.scrollTo(0, 0);
    } else {
      console.error("error");
    }

    if (job_id !== undefined && job_id !== null) {
      jobDetail();
    }

    const masterData = getLocalData(DATA);
    setEvaluationCharge(
      !isNullOrEmpty(masterData?.system_config?.evaluation_view_ttcash?.value)
        ? masterData.system_config.evaluation_view_ttcash.value
        : 0
    );
  }, []);

  const [trimSkills, setTrimSkills] = useState(false);
  const [shouldTrim, setShouldTrim] = useState(false);
  const [showMoreOrLess, setShowMoreOrLess] = useState("More");

  useEffect(() => {
    if (skills.length > 5) {
      setTrimSkills(true);
    } else {
      setTrimSkills(false);
    }
  }, [shouldTrim]);

  useEffect(() => {
    if (skills.length > 5) {
      setShouldTrim(true);
    }
  }, [skills]);

  const slicedSkills = trimSkills ? skills.slice(0, 5) : skills;

  const [selectedCriterias, setSelectedCriterias] = useState([]);
  const [showSimilarCandidatesModal, setShowSimilarCandidatesModal] = useState(null);

  const isSimilarCriteriaChecked = (type) => {
    return selectedCriterias.includes(type);
  };

  const showSimilarSearch = () => {
    if (selectedCriterias.length < 1) {
      toast.warn("Please select at least one criteria");
      return;
    }

    const data = showSimilarCandidatesModal;
    let newData = {};

    newData.skills = data?.skills?.length > 0 ? data?.skills : [];
    newData.location_id = data?.user_work_profile?.location_id;
    newData.full_name = data?.full_name;
    newData.id = data?.id;

    viewSimilarCandidates(newData, setLoading, isSimilarCriteriaChecked);
  }

  const buyCandidateEvaluation = () => {
    setLoading(true);
    ApiHelper.buyCandidateEvaluation(profile.id).then((response) => {
      setLoading(false);
      if (response.isSuccess === true) {
        setEvaluation(true);
        hideBuyEvaluationModal();
      } else {
        toast.warn(response.message);
      }
    });
  };

  const hideBuyEvaluationModal = () => {
    setShowModalBuyEvaluation(false);
  };

  const showBuyEvaluationModal = () => {
    setShowModalBuyEvaluation(true);
  };

  const [chat, setChat] = useState({
    candidate_id: user_id,
    recruiter_id: profileId,
    job_id: job_id || "",
    message: "",
  });

  const createChat = () => {
    if (!chat.message) {
      toast.warn(MESSAGE_REQUIRED);
      return;
    }

    const obj = { ...chat };

    if (!obj.job_id) {
      delete obj.job_id;
    } else {
      obj.job_id = jobDetails?.id;
    }

    ApiHelper.createChat(obj).then((response) => {
      if (response.isSuccess === true) {
        navigate("/chat");
      } else {
        toast.warn(response.message);
      }
    });
  };

  const downloadTTWorkprofile = () => {
    var a = document.createElement("a");
    a.href = process.env.REACT_APP_BASE_URL + "tt-workprofile?id=" + profile.id;
    a.click();
  };

  const isOverallFeedbackExists = () => {
    return (
      profile?.feedback !== undefined &&
      profile?.feedback !== null &&
      profile?.feedback?.overall_feedback !== undefined &&
      profile?.feedback?.overall_feedback !== null
    );
  };

  const isSkillFeedbackExists = () => {
    return (
      profile?.feedback !== undefined &&
      profile?.feedback !== null &&
      profile?.feedback?.feedbacks !== undefined &&
      profile?.feedback?.feedbacks !== null
    );
  };
  const getPreferredLocationTags = () => {
    const sliced_location_data = profile?.preferred_location_data
      ?.split(", ")
      .slice(0, 5);
    if (isNullOrEmpty(sliced_location_data)) {
      return "Any";
    } else {
      return sliced_location_data?.map((location, i) => (
        <a href={`http://maps.google.com/?q=${location}`} target="_blank">
          {profile.preferred_location_data.split(", ")[i]}
          {i === Math.min(4, sliced_location_data.length - 1) ? "" : ", "}
        </a>
      ));
    }
  };

  return (
    <>
      {/* View-similar Candidates Modal */}
      <ModalShowSimilarCandidates
        showSimilarCandidatesModal={showSimilarCandidatesModal}
        setShowSimilarCandidatesModal={setShowSimilarCandidatesModal}
        showSimilarSearch={showSimilarSearch}
        setSelectedCriterias={setSelectedCriterias}
        selectedCriterias={selectedCriterias}
        isSimilarCriteriaChecked={isSimilarCriteriaChecked}
      />

      {loading ? (
        <div className="loader">
          <ClipLoader color={color} loading={true} css={override} size={150} />
        </div>
      ) : null}
      <div id="viewPage" ref={componentRef}>
        <ModalUserMessage
          setChat={setChat}
          chat={chat}
          onSubmit={createChat}
          showModal={showModalUserMessage}
          setShowModal={setShowModalUserMessage}
        />
        <ModalChangeCandidateStatus
          showModal={showModalChangeStatus}
          setShowModal={setShowModalChangeStatus}
          job_id={jobDetails?.eid}
          candidate_id={profile?.id}
          jobDetail={jobDetail}
        />
        <ModalInformation
          showModal={showModalBuyEvaluation}
          setShowModal={setShowModalBuyEvaluation}
          title={"Unlock Candidate's Evaluation"}
          text={
            "You'll be charged by " +
            evaluationCharge +
            " TT-Cash. This transaction will be valid until " +
            evaluationValidity
          }
          buttonOneText={"No"}
          buttonOneAction={hideBuyEvaluationModal}
          buttonTwoText={"Yes"}
          buttonTwoAction={buyCandidateEvaluation}
        />
        {profile ? (
          <div className="user_profile">
            <div
              className="body_content tt-container"
              style={{ paddingTop: "30px" }}
            >
              <div className="row w-100">
                <div className="col-lg-8 col-md-8 col-sm-12 col-12 mb-3 left">
                  <div className="profile_img_div">
                    <div className="px-2 d-flex align-items-center gap-2">
                      <div className="profile_img img-border">
                        <img
                          src={profile.avatar_location ?? defaultAvatar}
                          alt=""
                        />
                      </div>

                      <div className="profile_txt w-100">
                        <div className="profile_txt_id d-flex justify-content-between align-items-top">
                          <p>
                            {APP_NAME} ID: {getIdWithPrefix(profile.id)}
                          </p>
                        </div>

                        <h2>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: highlightText(
                                keyword,
                                profile?.full_name
                                  ? textCapitalise(profile?.full_name)
                                  : ""
                              ),
                            }}
                          ></span>
                        </h2>
                        <h5 className="mt-1">
                          <span
                            dangerouslySetInnerHTML={{
                              __html: highlightText(
                                keyword,
                                workProfile?.title ? workProfile?.title : ""
                              ),
                            }}
                          ></span>
                        </h5>
                        {profile?.trained_by ? (
                          <Text
                            type=""
                            text={`Trained at <a class='hand-hover green-text' href="/company-profile/${profile?.trained_by?.eid}" target="_blank">${profile?.trained_by?.name}</a> - a TrueTalent Academia Partner`}
                            fontWeight={500}
                            mt={2}
                            opacity={0.7}
                          />
                        ) : null}
                      </div>
                    </div>
                    <div>
                      <p className="last-updated">
                        Last Updated:
                        {commonServices.dateFormat(
                          profile?.user_work_profile?.updated_at
                        )}
                      </p>
                      {workProfile.layoff !== undefined &&
                        workProfile.layoff === "1" ? (
                        <div className="layoff-container">

                          <p>Impacted by layoff</p>
                        </div>
                      ) : null}
                    </div>
                  </div>

                  {workProfile?.video_link &&
                    workProfile?.video_link !== null &&
                    workProfile?.video_link !== "" ? (
                    <div className="video-player mb-3">
                      <ReactPlayer
                        url={workProfile?.video_link}
                        controls="true"
                      />
                    </div>
                  ) : null}

                  <div className="details">
                    <ReadMoreText
                      text={workProfile.summary}
                      highlightKeywords={keyword}
                    />

                    <ul>
                      <li><p className='d-flex align-items-center'><img src={email} alt="" /><a href={`mailto:${profile?.email}`} target='_blank' dangerouslySetInnerHTML={{ __html: highlightText(keyword, profile?.email) }}></a></p></li>

                      <li>
                        {
                          profile?.phone_number || profile?.alternate_phone_number ?
                            <p><a href={`tel:${profile?.cc_phone_number + profile?.phone_number}`} target='_blank' className='d-flex align-items-center'>
                              <img src={phone} alt="" /> {profile?.cc_phone_number + profile?.phone_number}
                            </a> {profile?.alternate_phone_number ? <a href={`tel:${profile?.cc_alternate_phone_number + profile?.alternate_phone_number}`} target='_blank' className='d-flex align-items-center'>
                              {", " + profile?.cc_alternate_phone_number + profile?.alternate_phone_number}
                            </a> : null} </p>
                            : <><img src={phone} alt="" /><p>Not Disclosed</p></>}</li>

                      <li>
                        <img src={marker} alt="" />
                        <div className="w-100">
                          <p className="d-flex gap-1">
                            Current:
                            {workProfile.location_name ? (
                              <a
                                href={`https://maps.google.com/?q=${workProfile.location_name}`}
                                target="_blank"
                              >
                                {workProfile.location_name}
                              </a>
                            ) : (
                              "Not Disclosed"
                            )}
                          </p>
                          <p className="d-flex gap-1">
                            Preferred:&nbsp;
                            {getPreferredLocationTags()}
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div className="wp-details">
                    <ul>
                      <li>
                        <p>Experience</p>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: highlightText(
                              keyword,
                              getCandidateExperienceText(
                                workProfile?.total_experience
                              )
                            ),
                          }}
                        ></span>
                      </li>
                      <li>
                        <p>Expected Salary</p>
                        <span>
                          {profile?.min_salary !== null &&
                            profile?.min_salary !== undefined
                            ? commonServices.currencyFormatter(
                              profile?.min_salary
                            )
                            : "Not Disclosed"}
                        </span>
                      </li>
                      <li>
                        <p>Joining Preference</p>
                        <span>
                          {!isNullOrEmpty(workProfile?.joining_preference_name) ? workProfile?.joining_preference_name : 'None'}
                        </span>
                      </li>
                      <li>
                        <p>Travel</p>
                        <span>
                          {profile?.is_telecommute === "1"
                            ? "Ready for Travel"
                            : "Not Ready for Travel"}
                        </span>
                      </li>
                      <li>
                        <p>Job Preference</p>
                        <span>
                          {profile?.preferred_job_types !== undefined &&
                            profile?.preferred_gig_types !== undefined
                            ? profile?.preferred_job_types.length > 0 &&
                              profile?.preferred_gig_types.length > 0
                              ? "Jobs and Gigs"
                              : profile?.preferred_job_types.length > 0
                                ? "Jobs"
                                : profile?.preferred_gig_types.length > 0
                                  ? "Gigs"
                                  : "All"
                            : "All"}
                        </span>
                      </li>
                      <li>
                        <p>Preferred Job Type</p>
                        <span>
                          {profile?.preferred_job_type_data
                            ? profile?.preferred_job_type_data
                            : "All"}
                        </span>
                      </li>
                    </ul>
                  </div>

                  <div className="work_details">
                    {experience && experience.length > 0 && (
                      <div className="w_sec">
                        <h2>Work Experience</h2>
                        <hr />

                        {commonServices.sortWorkProfileArray(experience).map((element, key) => {
                          return <div key={key} className='w_det'>
                            <h2>{(element.from_date !== null) ? getMonthYearFromDate(element.from_date) : ''}  {(element.to_date !== null) ? ' - ' + getMonthYearFromDate(element.to_date) : (element.is_present === '1') ? ' - Now' : ''} {element.title ? `- ` + element.title : element.title}  </h2>
                            <p className='blue-text awarded_by' dangerouslySetInnerHTML={{ __html: highlightText(keyword, element.awarded_by) }}></p>
                            <p dangerouslySetInnerHTML={{ __html: highlightText(keyword, element.description) }}></p>
                          </div>
                        })}
                      </div>)}

                    {degree && degree.length > 0 && (
                      <div className="w_sec">
                        <h2>Education</h2>
                        <hr />

                        {commonServices
                          .sortWorkProfileArray(degree)
                          .map((element, key) => {
                            return (
                              <div key={key} className="w_det">
                                <h2>
                                  {element.from_date !== null && getYearFromDate(element.from_date) !== null
                                    ? getYearFromDate(element.from_date)
                                    : ""}
                                  {element.to_date !== null && getYearFromDate(element.to_date) !== null
                                    ? " - " +
                                    getYearFromDate(element.to_date) +
                                    ` - `
                                    : (element.is_present === '1') ? ' - Now - ' : ''}
                                  {element.title}
                                </h2>
                                <p
                                  className="blue-text awarded_by"
                                  dangerouslySetInnerHTML={{
                                    __html: highlightText(
                                      keyword,
                                      element.awarded_by
                                    ),
                                  }}
                                ></p>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: highlightText(
                                      keyword,
                                      element.description
                                    ),
                                  }}
                                ></p>
                              </div>
                            );
                          })}
                      </div>
                    )}

                    {certificate && certificate.length > 0 && (
                      <div className="w_sec">
                        <h2>Certifications</h2>
                        <hr />

                        {commonServices
                          .sortWorkProfileArray(certificate)
                          .map((element, key) => {
                            return (
                              <div key={key} className="w_det">
                                <h2>
                                  {element.from_date !== null && getYearFromDate(element.from_date) !== null ? getYearFromDate(element.from_date) + " — " : ""}
                                  {element.title}
                                </h2>
                                <p
                                  className="blue-text awarded_by"
                                  dangerouslySetInnerHTML={{
                                    __html: highlightText(
                                      keyword,
                                      element.awarded_by
                                    ),
                                  }}
                                ></p>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: highlightText(
                                      keyword,
                                      element.description
                                    ),
                                  }}
                                ></p>
                              </div>
                            );
                          })}
                      </div>
                    )}

                    {award && award.length > 0 && (
                      <div className="w_sec">
                        <h2>Awards and Accomplishments</h2>
                        <hr />

                        {commonServices
                          .sortWorkProfileArray(award)
                          .map((element, key) => {
                            return (
                              <div key={key} className="w_det">
                                <h2>
                                  {element.from_date !== null && getYearFromDate(element.from_date) !== null ? getYearFromDate(element.from_date) + " — " : ""}
                                  {element.title}
                                </h2>
                                <p
                                  className="blue-text awarded_by"
                                  dangerouslySetInnerHTML={{
                                    __html: highlightText(
                                      keyword,
                                      element.awarded_by
                                    ),
                                  }}
                                ></p>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: highlightText(
                                      keyword,
                                      element.description
                                    ),
                                  }}
                                ></p>
                              </div>
                            );
                          })}
                      </div>
                    )}

                    {workProfile &&
                      (workProfile?.her_career_reboot === "1" ||
                        workProfile?.differently_abled === "1" ||
                        workProfile?.armed_forces === "1") && (
                        <div className="w_sec">
                          <h2>Additional Information</h2>
                          <hr />

                          <div className="additional-details mt-3">
                            <ul>
                              {workProfile?.her_career_reboot === "1" ? (
                                <li>
                                  <p>Her Career Reboot</p>
                                </li>
                              ) : null}
                              {workProfile?.differently_abled === "1" ? (
                                <li>
                                  <p>Differently Abled</p>
                                </li>
                              ) : null}
                              {workProfile?.armed_forces === "1" ? (
                                <li>
                                  <p>Armed Forces</p>
                                </li>
                              ) : null}
                            </ul>
                          </div>
                        </div>
                      )}
                  </div>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-12 col-12 mb-3 right">
                  <div
                    className="action_btns"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    {currentUser &&
                      !isDraftJob(jobDetails) &&
                      currentUser.id === jobDetails.user_id ? (
                      <button
                        style={{
                          width: "100%",
                          marginBottom: "20px",
                          padding: "10px 20px",
                          borderRadius: "20px",
                          border: 0,
                          background: "var(--green)",
                          color: "white",
                          fontWeight: 600,
                        }}
                        onClick={() => {
                          setShowModalChangeStatus(!showModalChangeStatus);
                        }}
                      >
                        Update Status
                        {currentStatus !== "" ? " | " + currentStatus : null}
                      </button>
                    ) : null}

                    <div className="send-message-button">
                      <button
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100%",
                          marginBottom: "20px",
                          padding: "10px 20px",
                          borderRadius: "20px",
                          border: 0,
                          background: "var(--green)",
                          color: "white",
                          fontWeight: 600,
                        }}
                        onClick={() => {
                          // setShowModalCommunication(true);
                        }}
                      >
                        <img
                          style={{ height: "1em", marginRight: "5px" }}
                          src={message_white}
                          alt=""
                        />
                        Send a Message
                      </button>

                      <div className="send-message-options">
                        <ul>
                          <li>
                            <Button
                              buttonIcon={
                                <i
                                  class="fa fa-comments-o"
                                  aria-hidden="true"
                                ></i>
                              }
                              text="TT Chat"
                              onClick={() => setShowModalUserMessage(true)}
                            />
                          </li>
                          <li>
                            <a
                              target="_blank"
                              disabled={isNullOrEmpty(
                                workProfile?.contact_number
                              )}
                              href={`https://wa.me/${workProfile?.contact_number}`}
                            >
                              <i class="fa fa-whatsapp" aria-hidden="true"></i>
                              WhatsApp
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              href={`mailto:${profile?.email}`}
                            >
                              <i
                                class="fa fa-envelope-o"
                                aria-hidden="true"
                              ></i>
                              Email
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <button
                      className={
                        isNullOrEmpty(workProfile?.cv_link)
                          ? "download-resume-btn fade-btn"
                          : "download-resume-btn"
                      }
                      onClick={() => {
                        addProfileTracking(0, 1);
                        downloadTTWorkprofile();
                      }}
                      style={{
                        marginBottom: "20px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img src={save} alt="" />
                      Download TT Work Profile
                    </button>

                    <button
                      className={
                        isNullOrEmpty(workProfile?.cv_link)
                          ? "download-resume-btn fade-btn"
                          : "download-resume-btn"
                      }
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: "20px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (workProfile?.cv_link) {
                          addProfileTracking(0, 1);
                          const a = document.createElement('a');
                          a.href = workProfile?.cv_link;
                          a.download = `${textCapitalise(profile?.full_name ?? '')}'s Resume.pdf`;
                          a.style.display = 'none';
                          a.target = "_blank";
                          document.body.appendChild(a);
                          a.click();
                          document.body.removeChild(a);
                        } else {
                          toast.warn(RESUME_LINK_NOT_FOUND);
                        }
                      }}
                    >
                      <img src={save} alt="" />
                      Download Resume
                    </button>

                    <button
                      className={"download-resume-btn"}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: "20px",
                      }}
                      onClick={() => addCandidateToSaveLater(profile.id, setLoading)}
                    >
                      <i className="fa fa-heart-o" aria-hidden="true" style={{ marginRight: '5px' }} />
                      Save Candidate
                    </button>

                    <button
                      className={"download-resume-btn"}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onClick={() => setShowSimilarCandidatesModal(profile)}
                    >
                      <i className="fa fa-clone" aria-hidden="true" style={{ marginRight: '5px' }} />
                      Show Similar Candidate(s)
                    </button>

                  </div>

                  {skills && slicedSkills.length > 0 && (
                    <div className="skills" style={{ marginTop: "35px" }}>
                      <Text
                        type="h2"
                        text="Skills"
                        fontSize={"var(--p1)"}
                        fontWeight={600}
                      />

                      <hr />

                      {
                        <div className="skill_det">
                          {slicedSkills.map((element, key) => {
                            for (var i = 0; i < slicedSkills.length; i++) {
                              if (!isNullOrEmpty(element.id)) {
                                return (
                                  <div key={key} className="progress-div">
                                    <div className="progress-name">
                                      <div className="skill-name">
                                        <img src={rectangle} alt="" />
                                        <p
                                          dangerouslySetInnerHTML={{
                                            __html: highlightText(
                                              keyword,
                                              element.name
                                            ),
                                          }}
                                        ></p>

                                        {element.skill_certificate && (
                                          <OverlayTrigger
                                            placement="top"
                                            overlay={
                                              <Tooltip>
                                                Certifying Authority:
                                                {element.awarded_by}
                                              </Tooltip>
                                            }
                                          >
                                            <div
                                              style={{
                                                border: "solid 1px purple",
                                                width: "20px",
                                                height: "20px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                borderRadius: "5px",
                                              }}
                                            >
                                              <CustomLink
                                                type="a"
                                                text="C"
                                                aHref={
                                                  element.skill_certificate
                                                }
                                              ></CustomLink>
                                            </div>
                                          </OverlayTrigger>
                                        )}

                                        {(element?.pivot?.type === "preffered_skill" ||
                                          (element?.is_preffered_skill !==
                                            undefined &&
                                            element?.is_preffered_skill)) && (
                                            <OverlayTrigger
                                              placement="top"
                                              overlay={
                                                <Tooltip>Preffered Skill</Tooltip>
                                              }
                                            >
                                              <div
                                                style={{
                                                  border: "solid 1px green",
                                                  width: "20px",
                                                  height: "20px",
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                  borderRadius: "5px",
                                                }}
                                              >
                                                <Text
                                                  type=""
                                                  text="P"
                                                  style={{ color: "green" }}
                                                />
                                              </div>
                                            </OverlayTrigger>
                                          )}
                                      </div>

                                      <div style={{ textWrap: "nowrap" }}>
                                        <span>
                                          {(profile.added_from !== "csv" ||
                                            profile.created_at !==
                                            profile.updated_at) &&
                                            element?.pivot?.experience > 0 ? (
                                            <>
                                              (
                                              {element?.pivot?.experience <= 0
                                                ? "Fresher"
                                                : ""}
                                              {Math.floor(
                                                element?.pivot?.experience / 12
                                              ) > 0
                                                ? Math.floor(
                                                  element?.pivot?.experience /
                                                  12
                                                ) + " years"
                                                : ""}
                                              {element?.pivot?.experience % 12 >
                                                0
                                                ? " " +
                                                (element?.pivot?.experience %
                                                  12) +
                                                " months"
                                                : ""}
                                              )
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </span>
                                      </div>
                                    </div>

                                    <div className="progress">
                                      <div
                                        className="progress-bar"
                                        role="progressbar"
                                        style={{
                                          width:
                                            getSkillPercentage(
                                              workProfile?.total_experience,
                                              element?.pivot?.experience
                                            ) + "%",
                                        }}
                                        aria-valuenow="25"
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                      ></div>
                                    </div>
                                  </div>
                                );
                              }
                            }
                          })}
                        </div>
                      }

                      {skills.length > 5 && (
                        <div className="text-center mt-3">
                          <Button
                            type="text"
                            className="green-text btn"
                            text={`Show ${showMoreOrLess} Skills`}
                            onClick={() => {
                              setTrimSkills(!trimSkills);
                              setShowMoreOrLess(
                                showMoreOrLess === "More" ? "Less" : "More"
                              );
                            }}
                          />
                        </div>
                      )}
                    </div>
                  )}

                  {isSkillFeedbackExists() ? (
                    <div style={{ marginTop: "35px" }}>
                      <Text
                        type="h2"
                        text="Evaluator Rating"
                        style={{
                          fontSize: "var(--p1)",
                          fontWeight: 600,
                        }}
                      />

                      <hr
                        style={{ margin: "6px 0", background: "var(--gray5)" }}
                      />
                      {evaluation ? (
                        <div
                          className="d-flex flex-column gap-3"
                          display={"flex"}
                          flexDirection={"column"}
                          gap={3}
                        >
                          {profile?.feedback?.feedbacks?.map((item, index) => {
                            if (
                              item?.question_name !== null &&
                              item?.rating !== null
                            )
                              return (
                                <div className="w-100 gap-2">
                                  <Text
                                    type=""
                                    text={item?.question_name}
                                    style={{
                                      fontSize: "var(--p)",
                                      fontWeight: 500,
                                      marginTop: 2,
                                      opacity: 0.7,
                                    }}
                                  />

                                  <ProgressBar
                                    variant="success"
                                    now={parseInt(item?.rating) * 20}
                                    label={`${parseInt(item?.rating) * 20}%`}
                                    style={{ borderRadius: "10px" }}
                                  />
                                </div>
                              );
                          })}
                        </div>
                      ) : (
                        <a
                          href="javascript:void(0);"
                          onClick={() => {
                            showBuyEvaluationModal();
                          }}
                          className="unlockEvaluation text-center"
                        >
                          <BsLock />
                          <br />
                          Click to Unlock
                        </a>
                      )}
                    </div>
                  ) : null}

                  {isOverallFeedbackExists() ? (
                    <div style={{ marginTop: "35px" }}>
                      <Text
                        type="h2"
                        text="Evaluator Feedback"
                        style={{
                          fontSize: "var(--p1)",
                          fontWeight: 600,
                        }}
                      />

                      <hr
                        style={{ margin: "6px 0", background: "var(--gray5)" }}
                      />
                      {evaluation ? (
                        <Text
                          type=""
                          text={profile?.feedback?.overall_feedback}
                          style={{
                            fontSize: "var(--p)",
                          }}
                        />
                      ) : (
                        <a
                          href="javascript:void(0);"
                          onClick={() => {
                            showBuyEvaluationModal();
                          }}
                          className="unlockEvaluation text-center"
                        >
                          <BsLock />
                          <br />
                          Click to Unlock
                        </a>
                      )}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
}

export default UserProfileViewById;
