/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
import "./Modal.scss";
import * as storageConstants from "@/constants/storageConstants";

import defaultAvatar from "@/Assets/svgs/default_avatar.svg";

import * as ApiHelper from "@/services/apiHelper";
import { APP_NAME } from "@/constants/storageConstants";

import * as commonServices from "@/services/common";
import { textCapitalise } from "@/utils/stringUtils";
import rectangle from "@/Assets/svgs/rectangle.svg";
import email from "@/Assets/svgs/email.svg";
import phone from "@/Assets/svgs/phone.svg";
import marker from "@/Assets/svgs/marker.svg";

import {
  getCandidateExperienceText,
  getSkillPercentage,
} from "@/services/common";
import ReactPlayer from "react-player";
import AppModal from "./AppModal";
import Text from "../_widgets/Text/Text";
import { BiPlayCircle } from "react-icons/bi";
import ReadMoreText from "../Common/ReadMoreText";
import { isNullOrEmpty } from "../../utils/stringUtils";
import CustomButton from "../_widgets/Button/Button";
import { getMonthYearFromDate, getYearFromDate } from "../../utils/dateUtil";
import CustomLink from "../_widgets/Link/Link";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

function ModalWorkProfilePreview({
  showModal,
  setShowModal,
  profile,
  updateWorkProfile,
}) {
  let [loading, setLoading] = useState(false);

  const [workProfile, setWorkProfile] = useState(profile.user_work_profile);
  const [workProfileDetails, setworkProfileDetails] = useState(
    profile.userWorkProfileDetail
  );
  const [skillData, setSkillData] = useState(commonServices.getLocalData(storageConstants.SKILLS));
  // const masterData = commonServices.getLocalData(storageConstants.DATA);

  const [experience, setExperience] = useState([]);
  const [degree, setDegree] = useState([]);
  const [certificate, setCertificate] = useState([]);
  const [award, setAward] = useState([]);
  const [skills, setSkills] = useState([]);

  const setProfileDetails = async () => {
    setWorkProfile(profile.user_work_profile);
    setworkProfileDetails(profile.userWorkProfileDetail);
    setExperience(
      profile?.userWorkProfileDetail?.experience !== undefined
        ? profile?.userWorkProfileDetail?.experience
        : []
    );
    setDegree(
      profile?.userWorkProfileDetail?.degree !== undefined
        ? profile?.userWorkProfileDetail?.degree
        : []
    );
    setCertificate(
      profile?.userWorkProfileDetail?.certificate !== undefined
        ? profile?.userWorkProfileDetail?.certificate
        : []
    );
    setAward(
      profile?.userWorkProfileDetail?.award !== undefined
        ? profile?.userWorkProfileDetail?.award
        : []
    );
    setSkills(
      profile?.userWorkProfileDetail?.skill !== undefined
        ? [...profile?.userWorkProfileDetail?.skill]
        : []
    );
  };

  const getSkills = () => {
    ApiHelper.getSkills().then((response) => {
      setLoading(false);
      if (response.isSuccess === true) {
        setSkillData(response.data);
      } else {
        console.log("error");
      }
    });
  };

  useEffect(() => {
    // getSkills();

    setSkills(
      !isNullOrEmpty(profile?.userWorkProfileDetail?.skill)
        ? [...profile?.userWorkProfileDetail?.skill]
        : []
    );
  }, []);

  useEffect(() => {
    setProfileDetails();
  }, [profile]);

  const [trimSkills, setTrimSkills] = useState(false);
  const [shouldTrim, setShouldTrim] = useState(false);
  const [showMoreOrLess, setShowMoreOrLess] = useState("More");

  useEffect(() => {
    if (skills.length > 5) {
      setTrimSkills(true);
    } else {
      setTrimSkills(false);
    }
  }, [shouldTrim]);

  useEffect(() => {
    if (skills.length > 5) {
      setShouldTrim(true);
    }
  }, [skills]);

  const slicedSkills = trimSkills ? skills.slice(0, 5) : skills;

  const componentRef = useRef();

  const sortArray = (array) => {
    return array.sort(function (a, b) {
      var keyA = new Date(a.from_date),
        keyB = new Date(b.from_date);
      // Compare the 2 dates
      if (keyA > keyB) return -1;
      if (keyA < keyB) return 1;
      return 0;
    });
  };

  const sortSkillArray = (array) => {
    return array.sort(function (a, b) {
      var keyA = a.type;
      var keyB = b.type;
      if (
        keyA === "preffered_skill" ||
        (a.is_preffered_skill !== undefined && a.is_preffered_skill)
      ) {
        if (
          keyB === "preffered_skill" ||
          (b.is_preffered_skill !== undefined && b.is_preffered_skill)
        ) {
          if (a.experience > b.experience) {
            return -1;
          } else {
            return 1;
          }
        } else {
          return -1;
        }
      }
      if (a.experience > b.experience) {
        return -1;
      }
      if (a.experience < b.experience) {
        return 1;
      }
      return 0;
    });
  };

  useEffect(() => {
    if (Array.isArray(degree) && degree.length > 0) {
      var arr = degree;
      setDegree([]);
      sortArray(arr);
      setDegree(arr);
    }
  }, [degree]);

  useEffect(() => {
    if (Array.isArray(skills) && skills.length > 0) {
      var arr = skills;
      setSkills([]);
      setSkills(sortSkillArray(arr));
    }
  }, [skills]);

  useEffect(() => {
    if (Array.isArray(certificate) && certificate.length > 0) {
      var arr = certificate;
      setCertificate([]);
      sortArray(arr);
      setCertificate(arr);
    }
  }, [certificate]);

  useEffect(() => {
    if (Array.isArray(experience) && experience.length > 0) {
      var arr = experience;
      setExperience([]);
      sortArray(arr);
      setExperience(arr);
    }
  }, [experience]);

  useEffect(() => {
    if (Array.isArray(award) && award.length > 0) {
      var arr = award;
      setAward([]);
      sortArray(arr);
      setAward(arr);
    }
  }, [award])

  const getPhoneNumber = () => {
    var number = '';
    number += profile?.phone_number;
    if (!isNullOrEmpty(profile?.alternate_phone_number))
      number += !isNullOrEmpty(number) ? ', ' + profile?.cc_alternate_phone_number + profile?.alternate_phone_number : profile?.cc_alternate_phone_number + profile?.alternate_phone_number;
    return !isNullOrEmpty(number) ? number : 'N/A';
  }

  return (
    <>
      <AppModal
        isOpen={showModal}
        closeModal={() => setShowModal((prev) => !prev)}
        title={"Work Profile Preview"}
        isFooter={true}
        size={"xl"}
        submit={() => updateWorkProfile("update")}
      >
        <div className="modal_body p-2">
          <div id="viewPage" ref={componentRef}>
            {profile ? (
              <div className="user_profile">
                <div
                  className="body_content"
                  style={{ padding: "0px 0px 0px 0px" }}
                >
                  <div className="d-flex w-100">
                    <div className="left">
                      <div className="profile_img_div">
                        <div className="px-2 d-flex align-items-center gap-2">
                          <div className="profile_img">
                            <img
                              src={profile.avatar_location ?? defaultAvatar}
                              alt=""
                            />
                          </div>

                          <div>
                            <div className="profile_txt">
                              <p>
                                {APP_NAME} ID:{" "}
                                {storageConstants.PREFIX_CANDIDATE_ID}
                                {profile.id}
                              </p>
                              <h2>
                                {profile?.first_name
                                  ? textCapitalise(profile?.first_name) +
                                  " " +
                                  textCapitalise(profile?.last_name)
                                  : ""}
                              </h2>
                              <h5 className="mt-1">
                                {workProfile?.title ? workProfile?.title : ""}
                              </h5>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="profile_txt_id">
                            <p className="last-updated">
                              Last Updated:{" "}
                              {commonServices.dateFormat(profile.updated_at)}
                            </p>
                          </div>
                          {workProfile?.layoff !== undefined &&
                            workProfile?.layoff === "1" ? (
                            <div className="layoff-container">
                              {" "}
                              <p>Impacted by layoff</p>
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {workProfile?.video_link &&
                        workProfile?.video_link !== null &&
                        workProfile?.video_link !== "" ? (
                        <div className="video-player mb-3">
                          <ReactPlayer
                            // playing
                            playIcon={
                              <CustomButton
                                buttonType="light"
                                bg={"white"}
                                padding={"10px"}
                                style={{ zIndex: "999" }}
                                zIndex={"999"}
                                buttonIcon={<BiPlayCircle size={"30px"} />}
                              ></CustomButton>
                            }
                            url={workProfile?.video_link}
                            // light={"https://placehold.it/640x360.jpg"}

                            controls="true"
                          />
                        </div>
                      ) : null}

                      <div className="details">
                        <ReadMoreText text={workProfile?.summary} />

                        <ul>
                          <li><img src={email} alt="" /><p>{profile?.email}</p></li>
                          <li><img src={phone} alt="" /><p>{getPhoneNumber()}</p></li>
                          <li><img src={marker} alt="" /><p>Current: {workProfile?.location_name ? workProfile.location_name : 'N/A'} | Preferred: {profile?.preferred_location_data ? profile.preferred_location_data : 'Any'}</p></li>
                        </ul>


                      </div>

                      <div className="wp-details">
                        <ul>
                          <li>
                            <p>Experience</p>
                            <span>
                              {getCandidateExperienceText(
                                workProfile?.total_experience
                              )}
                            </span>
                          </li>
                          <li>
                            <p>Expected Salary</p>
                            <span>
                              {profile?.min_salary !== null &&
                                profile?.min_salary !== undefined
                                ? commonServices.currencyFormatter(
                                  profile?.min_salary
                                )
                                : "N/A"}
                            </span>
                          </li>
                          <li>
                            <p>Joining Preference</p>
                            <span>
                              {workProfile?.joining_preference_name !== null &&
                                workProfile?.joining_preference_name !== ""
                                ? workProfile?.joining_preference_name
                                : "N/A"}
                            </span>
                          </li>
                          <li>
                            <p>Travel</p>
                            <span>
                              {profile?.is_telecommute === "1"
                                ? "Ready for Travel"
                                : "Not Ready for Travel"}
                            </span>
                          </li>
                          <li>
                            <p>Job Preference</p>
                            <span>
                              {profile?.preferred_job_types !== undefined &&
                                profile?.preferred_gig_types !== undefined
                                ? profile?.preferred_job_types.length > 0 &&
                                  profile?.preferred_gig_types.length > 0
                                  ? "Jobs and Gigs"
                                  : profile?.preferred_job_types.length > 0
                                    ? "Jobs"
                                    : profile?.preferred_gig_types.length > 0
                                      ? "Gigs"
                                      : "N/A"
                                : "N/A"}
                            </span>
                          </li>
                          <li>
                            <p>Preferred Job Type</p>
                            <span>
                              {profile?.preferred_job_type_data
                                ? profile?.preferred_job_type_data
                                : "N/A"}
                            </span>
                          </li>
                        </ul>
                      </div>

                      <div className="work_details">
                        {experience && experience.length > 0 && (
                          <div className="w_sec">
                            <h2>Work Experience</h2>
                            <hr />

                            {commonServices.sortWorkProfileArray(experience).map((element, key) => {
                              return <div key={key} className='w_det'>
                                <h2>{(element.from_date !== null) ? getMonthYearFromDate(element.from_date) : ''}  {(element.to_date !== null) ? ' - ' + getMonthYearFromDate(element.to_date) : (element.is_present === '1') ? ' - Now' : ''} {element.title ? `- ` + element.title : element.title}  </h2>
                                <p className='blue-text awarded_by'>{element.awarded_by}</p>
                                <p dangerouslySetInnerHTML={{ __html: element.description }}></p>
                              </div>
                            })}
                          </div>)}

                        {degree && degree.length > 0 && (
                          <div className="w_sec">
                            <h2>Education</h2>
                            <hr />

                            {commonServices
                              .sortWorkProfileArray(degree)
                              .map((element, key) => {
                                return (
                                  <div key={key} className="w_det">
                                     <h2>
                                      {element.from_date !== null && getYearFromDate(element.from_date) !== null
                                        ? getYearFromDate(element.from_date)
                                        : ""}
                                      {element.to_date !== null && getYearFromDate(element.to_date) !== null
                                        ? " - " +
                                        getYearFromDate(element.to_date) +
                                        ` - `
                                        : (element.is_present === '1') ? ' - Now - ' : ''}
                                      {element.title}
                                    </h2>
                                    <p className="blue-text awarded_by">
                                      {element.awarded_by}
                                    </p>
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: element.description,
                                      }}
                                    ></p>
                                  </div>
                                );
                              })}
                          </div>
                        )}

                        <div className="work_details">
                          {certificate && certificate.length > 0 && (
                            <div className="w_sec">
                              <h2>Certifications</h2>
                              <hr />

                              {commonServices
                                .sortWorkProfileArray(certificate)
                                .map((element, key) => {
                                  return (
                                    <div key={key} className="w_det">
                                      <h2>
                                        {getYearFromDate(element.from_date)} —{" "}
                                        {element.title}
                                      </h2>
                                      <p className="blue-text awarded_by">
                                        {element.awarded_by}
                                      </p>
                                      <p
                                        dangerouslySetInnerHTML={{
                                          __html: element.description,
                                        }}
                                      ></p>
                                    </div>
                                  );
                                })}
                            </div>
                          )}
                        </div>

                        {award && award.length > 0 && (
                          <div className="w_sec">
                            <h2>Awards and Accomplishments</h2>
                            <hr />

                            {commonServices
                              .sortWorkProfileArray(award)
                              .map((element, key) => {
                                return (
                                  <div key={key} className="w_det">
                                    <h2>
                                      {getYearFromDate(element.from_date)} —{" "}
                                      {element.title}
                                    </h2>
                                    <p className="blue-text awarded_by">
                                      {element.awarded_by}
                                    </p>
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: element.description,
                                      }}
                                    ></p>
                                  </div>
                                );
                              })}
                          </div>
                        )}

                        {workProfile &&
                          (workProfile?.her_career_reboot === "1" ||
                            workProfile?.differently_abled === "1" ||
                            workProfile?.armed_forces === "1") && (
                            <div className="w_sec">
                              <h2>Additional Information</h2>
                              <hr />

                              <div className="additional-details mt-3">
                                <ul>
                                  {workProfile?.her_career_reboot === "1" ? (
                                    <li>
                                      <p>Her Career Reboot</p>
                                    </li>
                                  ) : null}
                                  {workProfile?.differently_abled === "1" ? (
                                    <li>
                                      <p>Differently Abled</p>
                                    </li>
                                  ) : null}
                                  {workProfile?.armed_forces === "1" ? (
                                    <li>
                                      <p>Armed Forces</p>
                                    </li>
                                  ) : null}
                                </ul>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>

                    {skills && slicedSkills.length > 0 && (
                      <div className="right">
                        <div
                          className="action_btns"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        ></div>

                        <div
                          div
                          className="skills"
                          style={{ marginTop: "35px" }}
                        >
                          <h2>Skills</h2>
                          <hr />

                          <div className="skill_det">
                            {slicedSkills.map((element, key) => {
                              for (var i = 0; i < slicedSkills.length; i++) {
                                if (!isNullOrEmpty(element.skill_id)) {
                                  return (
                                    <div key={key} className="progress-div">
                                      <div className="progress-name">
                                        <div className="skill-name">
                                          <img src={rectangle} alt="" />
                                          <Text
                                            text={element.skill_name}
                                            style={{
                                              whiteSpace: "nowrap",
                                            }}
                                          />

                                          {(element.skill_certificate ||
                                            element.certificate_name) && (
                                              <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                  <Tooltip id="butt0n-tooltip">
                                                    Certifying Authority:{" "}
                                                    {element.awarded_by}
                                                  </Tooltip>
                                                }
                                              >
                                                <div
                                                  style={{
                                                    border: "solid 1px purple",
                                                    width: "20px",
                                                    height: "20px",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    borderRadius: "5px",
                                                  }}
                                                >
                                                  <CustomLink
                                                    type="a"
                                                    text="C"
                                                    aHref={
                                                      element.skill_certificate
                                                    }
                                                  ></CustomLink>
                                                </div>
                                              </OverlayTrigger>
                                            )}

                                          {((element?.is_preffered_skill ===
                                            undefined &&
                                            element?.type ===
                                            "preffered_skill") ||
                                            (element?.is_preffered_skill !==
                                              undefined &&
                                              element?.is_preffered_skill)) && (
                                              <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                  <Tooltip id="butt0n-tooltip">
                                                    Preffered Skill
                                                  </Tooltip>
                                                }
                                              >
                                                <div
                                                  style={{
                                                    border: "solid 1px purple",
                                                    width: "20px",
                                                    height: "20px",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    borderRadius: "5px",
                                                  }}
                                                >
                                                  <Text
                                                    text="P"
                                                    style={{
                                                      color: "var(--green)",
                                                    }}
                                                  />
                                                </div>
                                              </OverlayTrigger>
                                            )}
                                        </div>

                                        <div style={{ textWrap: "nowrap" }}>
                                          <span>
                                            {(profile.added_from !== "csv" ||
                                              profile.created_at !==
                                              profile.updated_at) &&
                                              element.experience > 0 ? (
                                              <>
                                                (
                                                {element.experience <= 0
                                                  ? "Fresher"
                                                  : ""}
                                                {Math.floor(
                                                  element.experience / 12
                                                ) > 0
                                                  ? Math.floor(
                                                    element.experience / 12
                                                  ) + " years"
                                                  : ""}
                                                {element.experience % 12 > 0
                                                  ? " " +
                                                  (element.experience % 12) +
                                                  " months"
                                                  : ""}
                                                ){" "}
                                              </>
                                            ) : (
                                              ""
                                            )}
                                          </span>
                                        </div>
                                      </div>

                                      <div className="progress">
                                        <div
                                          className="progress-bar"
                                          role="progressbar"
                                          style={{
                                            width:
                                              getSkillPercentage(
                                                workProfile?.total_experience,
                                                element?.experience
                                              ) + "%",
                                            backgroundColor:
                                              "var(--green) !important",
                                          }}
                                          aria-valuenow="25"
                                          aria-valuemin="0"
                                          aria-valuemax="100"
                                        ></div>
                                      </div>
                                    </div>
                                  );
                                }
                              }
                            })}
                          </div>

                          {skills.length > 5 && (
                            <div className="text-center mt-3">
                              <CustomButton
                                buttonType="text"

                                text={`Show ${showMoreOrLess} Skills`}
                                onClick={() => {
                                  setTrimSkills(!trimSkills);
                                  setShowMoreOrLess(
                                    showMoreOrLess === "More" ? "Less" : "More"
                                  );
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </AppModal>
    </>
  );
}

export default ModalWorkProfilePreview;
