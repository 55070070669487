import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

export default function CandidateTabs({ onNavbarClick }) {
    const navigate = useNavigate();
    return (
        <>
            <li><a className="dropdown-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" aria-current="page" onClick={() => { onNavbarClick('/jobs') }}>My Jobs</a>
            </li>

            <li><a className="dropdown-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" aria-current="page" onClick={() => { onNavbarClick('/gigs') }}>My Gigs</a></li>

        </>
    )
}