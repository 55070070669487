import { changeDateFormat, dateTime, nowDateTime } from "./dateUtil"

export function canCancel(interview) {
    return ((interview?.status === "pending" || interview?.status === "rescheduled" || interview?.status === "accepted") && (changeDateFormat(interview?.meeting_from_time, "YYYY-MM-DD HH:mm:ss") > dateTime(1)));
}

export function canReschedule(interview) {
    return interview?.status === "pending" && changeDateFormat(interview?.meeting_from_time, "YYYY-MM-DD HH:mm:ss") > dateTime(1);
}

export function canAccept(interview) {
    return interview?.status === "pending" && changeDateFormat(interview?.meeting_from_time, "YYYY-MM-DD HH:mm:ss") > dateTime(1);
}

export function isRescheduled(interview) {
    return interview?.status === "rescheduled";
}


export function isCancelled(interview) {
    return interview?.status === "cancelled";
}

export function canJoin(interview) {
    return interview?.status === "accepted" && changeDateFormat(interview?.meeting_to_time, "YYYY-MM-DD HH:mm:ss") > nowDateTime();
}

export function isPostInterview(interview) {
    return changeDateFormat(interview?.meeting_to_time, "YYYY-MM-DD HH:mm:ss") > dateTime(24);
}