import React, { useState } from "react";
import * as ApiHelper from "@/services/apiHelper";
import "./Modal.scss";
import * as error from "@/constants/stringConstants";
import * as toast from "@/wrapper/toast";
import AppModal from "./AppModal";
import { trackJOB, trackGIG } from "../../helpers/analytics";
import { analyticsConstant } from "../../constants/analyticsConstant";

function ModalJobApply({
  showModal,
  setShowModal,
  jobDetail,
  job_name,
  job_id,
  type = "",
}) {
  const [recruiterMessage, setRecruiterMessage] = useState("");
  let [loading, setLoading] = useState(false);

  const applyJob = async () => {
    if (!job_id) {
      toast.warn(error.SOMETHING_WENT_WRONG);
    } else {
      setLoading(true);
      const formData = new FormData();
      formData.append("recruiter_msg", recruiterMessage);
      formData.append("applied", 1);
      if (type === "gig") {
        formData.append("gig_id", job_id);
        try {
          trackGIG(analyticsConstant.APPLY, job_id, jobDetail.title);
        } catch {}
        await ApiHelper.applyGig(formData).then((response) => {
          setLoading(false);
          setShowModal((prev) => !prev);
          if (response.isSuccess === true) {
            toast.success(response.message);
          } else {
            toast.warn(response.message);
          }
          jobDetail();
        });
      } else {
        formData.append("job_id", job_id);
        try {
          trackJOB(analyticsConstant.APPLY, job_id, jobDetail.title);
        } catch {}
        await ApiHelper.applySaveJob(formData).then((response) => {
          setLoading(false);
          setShowModal((prev) => !prev);
          if (response.isSuccess === true) {
            toast.success(response.message);
          } else {
            toast.warn(response.message);
          }
          jobDetail();
        });
      }
    }
  };

  return (
    <>
      <AppModal
        title={`Apply for ${job_name}`}
        isOpen={showModal}
        closeModal={() => setShowModal((prev) => !prev)}
        submit={(e) => applyJob()}
        size={"lg"}
        isFooter={true}
        loading={loading}
      >
        <div className="modal_body">
          <div className="row">
            <div className="col">
              <label className="input-lebel">Message to Recruiter</label>
              <textarea
                className="form-control form-textbox"
                rows="4"
                cols="50"
                placeholder="Type your message here.."
                onChange={(e) => setRecruiterMessage(e.target.value)}
              />
            </div>
          </div>
        </div>
      </AppModal>
    </>
  );
}

export default ModalJobApply;
