/* eslint-disable */
import React, { useState, useEffect } from "react";
import "./Modal.scss";

import * as commonServices from "@/services/common";
import * as erros from "@/constants/stringConstants";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";
import { isExist, isNotNullOrEmpty } from "@/utils/objectUtil";
import { checkUrl } from "@/utils/validation";
import * as toast from "@/wrapper/toast";
import AppModal from "./AppModal";
import Input from "../_widgets/Input/Input";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: var(--green);
  left: calc(50% - 75px);
  position: fixed;
`;

function ModalAddVideo({
  showModal,
  setShowModal,
  profile,
  setProfile,
  setDataAutoSave,
}) {
  let [loading] = useState(false);
  let [color] = useState("#000000");
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    setVideos(
      isExist(profile?.links) !== undefined && isNotNullOrEmpty(profile?.links)
        ? profile?.links
        : [{}]
    );
  }, [showModal]);

  const addVideo = () => {
    var isError = false;
    videos &&
      videos.map((element, key) => {
        var currentRow = key + 1;
        if (!videos[key].title) {
          isError = true;
          toast.warn(erros.EMPTY_TITLE_ERROR + " at row " + currentRow);
        } else if (!videos[key].value) {
          isError = true;
          toast.warn(erros.EMPTY_LINK_ERROR + " at row " + currentRow);
        } else if (!checkUrl(videos[key].value)) {
          isError = true;
          toast.warn(erros.VALID_LINK + " at row " + currentRow);
        }
      });

    if (!isError) {
      var tempArray = { ...profile };
      tempArray.links = videos;
      setProfile(tempArray);
      setShowModal(false);
      setDataAutoSave(true);
    }
  };
  const onChange = (e, key) => {
    var tempArray = [...videos];
    tempArray[key][e.target.name] = e.target.value;
    setVideos(tempArray);
  };

  const deleteRow = async (e, key) => {
    e.preventDefault();
    var tempVideoArr = [...videos];
    tempVideoArr.splice(key, 1);
    setVideos(tempVideoArr);
  };

  return (
    <>
      <AppModal
        title={"Add Video"}
        size={"lg"}
        isOpen={showModal}
        closeModal={() => setShowModal(false)}
        submit={() => {
          addVideo();
        }}
        isFooter={true}
        minH="40vh"
      >
        <div className="modal_body">
          <div className="modal_data_rows">
            {videos &&
              videos.map((element, key) => {
                return (
                  <>
                    <div className="row" key={key}>
                      <div className="col">
                        <Input
                          type="text"
                          key={"title" + key}
                          label="Video Title"
                          value={element?.title}
                          placeholder="Enter the title"
                          id="title"
                          name="title"
                          onChange={(e) => onChange(e, key)}
                        />
                      </div>
                      <div className="col ">
                        <Input
                          type="text"
                          key={"link" + key}
                          label="Video Link"
                          value={element?.value}
                          placeholder="Enter the link"
                          id="link"
                          name="value"
                          onChange={(e) => onChange(e, key)}
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-2 col-6">
                        <a
                          className="delete-row"
                          onClick={(e) => {
                            deleteRow(e, key);
                          }}
                        >
                          {" "}
                          <img
                            className=""
                            src="../Assets/svgs/trash.svg"
                          ></img>{" "}
                          Remove
                        </a>
                      </div>
                      {key === videos.length - 1 ? (
                        <div className="col-md-3 col-6">
                          <a
                            className="add-more"
                            onClick={() => {
                              setVideos([...videos, {}]);
                            }}
                          >
                            {" "}
                            <img src="../Assets/svgs/plus_circle.svg"></img> Add
                            More
                          </a>
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="col"></div>
                      <div className="col"></div>
                      <div className="col"></div>
                      <div className="col"></div>
                      <div className="col"></div>
                      <div className="col"></div>
                    </div>
                  </>
                );
              })}
          </div>
        </div>
      </AppModal>
    </>
  );
}

export default ModalAddVideo;
