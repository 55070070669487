import React from "react";

import "./Modal.scss";
import AppModal from "./AppModal";

function ModalUserMessage({
  showModal,
  setShowModal,
  chat,
  setChat,
  onSubmit,
}) {
  return (
    <>
      <AppModal
        isOpen={showModal}
        closeModal={() => {
          setShowModal((prev) => !prev);
          setChat({ ...chat, message: "" });
        }}
        title="Send a Message"
        size="md"
        submit={onSubmit}
        isFooter={true}
        submitText={"Send"}
      >
        <div className="modal_body">
          <div className="row">
            <div className="col">
              <textarea
                maxLength="300"
                name=""
                id=""
                rows="3"
                style={{
                  outline: 0,
                  padding: "10px",
                  width: "100%",
                  border: "solid 1px var(--gray5)",
                  borderRadius: "4px",
                }}
                placeholder="Type a message..."
                onChange={(e) => {
                  setChat((prev) => ({ ...prev, message: e.target.value }));
                }}
                value={chat?.message}
              ></textarea>
            </div>
          </div>
        </div>
      </AppModal>
    </>
  );
}

export default ModalUserMessage;
