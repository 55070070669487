import React from "react";
import * as commonServices from "@/services/common";
import * as storageConstants from "@/constants/storageConstants";
import { useState } from "react";
import * as toast from "@/wrapper/toast";
import AppModal from "./AppModal";

const initialState = {
  checked: false,
  block_reason_id: "",
  block_reason_comment: "",
};

const ModalUserChatBlock = ({ showModal, setShowModal, onSubmit }) => {
  const masterData = commonServices.getLocalData(storageConstants.DATA);

  const [info, setInfo] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;

    setInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = () => {
    if (!info?.block_reason_id) {
      return toast.warn("Please select a reason to block");
    }
    onSubmit({ ...info, status: info?.checked ? "block" : "report" });
    setShowModal((prev) => !prev);
    setInfo(initialState);
  };

  return (
    <>
      <AppModal
        title={"Report this contact to TrueTalent"}
        isOpen={showModal}
        closeModal={() => {
          setShowModal((prev) => !prev);
        }}
        submit={handleSubmit}
        isFooter={true}
        submitText={"Block"}
        size={"md"}
      >
        <div className="modal_body">
          <div className="row">
            <div className="col">
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  justifyCenter: "center",
                }}
              >
                <input
                  type="checkbox"
                  name=""
                  id=""
                  value={info?.checked}
                  checked={info?.checked}
                  onChange={() =>
                    setInfo((prev) => ({ ...prev, checked: !prev.checked }))
                  }
                />
                <label>I would like to block this contact</label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <label htmlFor="">Select Reason</label>
              <select
                className="form-select form-select-lg gender-dropdown"
                name="block_reason_id"
                onChange={handleChange}
              >
                <option>Select Option</option>
                {masterData?.chat_block_reasons &&
                  masterData.chat_block_reasons.map((item, index) => {
                    return (
                      <option key={index} value={item?.id}>
                        {item?.name}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <label htmlFor="">Comments (optional)</label>
              <textarea
                id=""
                rows="3"
                style={{
                  outline: 0,
                  padding: "10px",
                  width: "100%",
                  border: "solid 1px var(--gray5)",
                  borderRadius: "4px",
                }}
                name="block_reason_comment"
                onChange={handleChange}
              ></textarea>
            </div>
          </div>
        </div>
      </AppModal>
    </>
  );
};

export default ModalUserChatBlock;
