import React, { useState, useEffect, useRef } from "react";
import "./Modal.scss";
import * as commonServices from "@/services/common";

function ModalIndustryDomain({
  showModal,
  setShowModal,
  industryDomains,
  checked,
  manageDomains,
}) {
  const [searchQuery, setSearchQuery] = useState("");
  const modalRef = useRef(null);
  const [activeAlphabet, setActiveAlphabet] = useState("ALL");

  const handleAlphabetClick = (alphabet) => {
    setActiveAlphabet(alphabet);
  };

  useEffect(() => {
    setSearchQuery("");
    setActiveAlphabet("ALL");
  }, [showModal]);

  const [FilteredDomains, setFilteredDomains] = useState([]);

  const handleSearch = (text) => {
    let matches = [];
    if (text.length > 1) {
      matches = industryDomains?.filter((element) => {
        const regex = commonServices.getRegularExpression(text);
        return element.name.match(regex);
      });
      setFilteredDomains(matches);
    }
    if (text.length <= 1) {
      var i = 0;
      var arr = [];
      industryDomains?.forEach((element) => {
        if (i < 10) {
          arr.push(element);
        }
        i++;
        setFilteredDomains(arr);
      });
    }
  };

  function getDomainsWithLetter(letter) {
    const filtered_domains = industryDomains?.filter((domain) =>
      domain?.name.toLowerCase().trim().startsWith(letter.toLowerCase())
    );
    setFilteredDomains(filtered_domains);
  }

  useEffect(() => {
    getDomainsWithLetter(activeAlphabet);
  }, [activeAlphabet]);

  const onDomainClick = (e, id) => {
    manageDomains(id, e.target.checked ? 'a' : 'r');
  };

  return (
    <div className="d-flex align-items-center justify-content-center">
      {showModal ? (
        <>
          <div class="modal" ref={modalRef}>
            <div
              className="location-filter-modal modal_content"
              style={{
                borderRadius: "10px",
              }}
            >
              <div className="modal_top row modal-header--sticky centre-div">
                <div className="col-lg-3 col-md-3 col-sm-12 px-0">
                  <input
                    className="search-filter"
                    type="text"
                    placeholder="Search Domain"
                    value={searchQuery}
                    onChange={(e) => {
                      setSearchQuery(e.target.value);
                      handleSearch(e.target.value);
                    }}
                  />
                </div>
                <div className="col-lg-8 col-md-8 col-sm-12">
                  <div style={{ marginTop: "20px" }}>
                    <ul class="FilterDirectory-indices">
                      {[
                        "ALL",
                        "A",
                        "B",
                        "C",
                        "D",
                        "E",
                        "F",
                        "G",
                        "H",
                        "I",
                        "J",
                        "K",
                        "L",
                        "M",
                        "N",
                        "O",
                        "P",
                        "Q",
                        "R",
                        "S",
                        "T",
                        "U",
                        "V",
                        "W",
                        "X",
                        "Y",
                        "Z",
                      ].map((letter, k) => (
                        <li
                          key={k}
                          data-item={letter}
                          style={
                            activeAlphabet === letter
                              ? {
                                border: "solid 1px var(--green)",
                                borderRadius: "5px",
                              }
                              : {}
                          }
                          class=""
                          onClick={() => handleAlphabetClick(letter)}
                        >
                          {letter}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className="col-lg-1 col-md-1 col-sm-12">
                  <button
                    style={{ float: "right" }}
                    onClick={() => setShowModal((prev) => !prev)}
                  >
                    <img
                      onError={commonServices.imgError}
                      src="../../Assets/svgs/x.svg"
                      alt=""
                    />
                  </button>
                </div>
              </div>

              <div className="modal_body container-fluid">
                <ul
                  className="list-styl-none FilterDirectory-list"
                  style={{ padding: "0" }}
                >
                  {searchQuery.length > 1 || activeAlphabet.length === 1 ? (
                    FilteredDomains.length >= 1 ? (
                      FilteredDomains.map((domain, index) => (
                        <li key={index}>
                          <div key={domain.id} className="checkbox_div_c">
                            <input
                              type="checkbox"
                              name=""
                              className={
                                checked.includes(domain.id)
                                  ? ""
                                  : "checkboxUnchecked"
                              }
                              checked={checked.includes(domain.id)}
                              id={`domain_checked_c_m_c${domain.id}`}
                              style={{ marginRight: "0.625em" }}
                              onChange={(e) => {
                                onDomainClick(e, domain.id);
                              }}
                            />
                            <label
                              className="form-label"
                              htmlFor={`domain_checked_c_m_c${domain.id}`}
                            >
                              {domain.name}
                            </label>
                          </div>
                        </li>
                      ))
                    ) : (
                      <div className="d-flex align-items-center w-100 justify-content-center">
                        <p>No Domain Found</p>
                      </div>
                    )
                  ) : (
                    industryDomains?.map((domain, index) => (
                      <li key={index}>
                        <div key={domain.id} className="checkbox_div_c">
                          <input
                            type="checkbox"
                            name=""
                            className={
                              checked.includes(domain.id)
                                ? ""
                                : "checkboxUnchecked"
                            }
                            checked={checked.includes(domain.id)}
                            id={`loc_checked_c_m_c${domain.id}`}
                            style={{ marginRight: "0.625em" }}
                            onChange={(e) => {
                              onDomainClick(e, domain.id);
                            }}
                          />
                          <label
                            className="form-label"
                            htmlFor={`loc_checked_c_m_c${domain.id}`}
                          >
                            {domain.name}
                          </label>
                        </div>
                      </li>
                    ))
                  )}
                </ul>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
}

export default ModalIndustryDomain;
