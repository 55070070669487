import React, { useEffect, useState, useRef } from "react";
import "./AddGig.scss";
import * as storageConstants from "@/constants/storageConstants";
import * as toast from "@/wrapper/toast";
import * as erros from "@/constants/stringConstants";
import * as ApiHelper from "@/services/apiHelper";
import { useNavigate, useParams } from "react-router-dom";
import { css } from "@emotion/react";
import * as commonServices from "@/services/common";
import { LOCATION_THRESHOLD } from "@/constants/storageConstants";
import { BUDGET_LIMIT } from "@/constants/storageConstants";
import { REMOTE } from "@/constants/storageConstants";
import { addDataOnFocusOut } from "@/services/common";
import AddGigLayouts from "./AddGigLayouts";
import { isNullOrEmpty, stringCompare, stripHTMLTags } from "../../utils/stringUtils";
import { COMPANY_ADMIN_ROLE, COMPANY_USER_ROLE } from "../../constants/roleConstants";
import { USER_ROLE } from "../../constants/storageConstants";
import { GIG_DESCRIPTION_REQUIRED } from "../../constants/stringConstants";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: var(--green);
  left: calc(50% - 75px);
  position: fixed;
`;

function AddGig() {
  const navigate = useNavigate();
  const profile = JSON.parse(localStorage.getItem(storageConstants.PROFILE));
  const masterData = commonServices.getLocalData(storageConstants.DATA);

  let [loading, setLoading] = useState(false);
  let [color] = useState("#000000");
  const [locSuggestion, setLocSuggestion] = useState();

  const [preferred_location, setPreferred_location] = useState([]);

  const [preferredLocationName, setPreferredLocationName] = useState([]);

  const [additionalSkillSuggestion, setAdditionalSkillSuggestion] = useState(
    []
  );
  const [additionalSkills, setAdditionalSkills] = useState([]);

  const [gigId, setGigId] = useState("");
  const [userId, setUserId] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [gigTypeId, setGigTypeId] = useState("");
  const [gigEngagementModeId, setGigEngagementModeId] = useState("");
  const [gigEngagementModeName, setGigEngagementModeName] = useState("");
  const [minBudget, setMinBudget] = useState("");
  const [maxBudget, setMaxBudget] = useState("");
  const [totalPositions, setTotalPositions] = useState(1);
  const [status, setStatus] = useState("");
  const [closeReasonId, setCloseReasonId] = useState("");
  const [closeReasonDescription, setCloseReasonDescription] = useState("");
  const [preferredAdditionalSkillName, setPreferredAdditionalSkillName] =
    useState([]);
  const [statusType, setStatusType] = useState("");
  const [currentTab, setCurrentTab] = useState("details");
  let [descriptionLoading, setDescriptionLoading] = useState(false);

  const [isStepOneValid, setIsStepOneValid] = useState(false);

  let skillRef = useRef();
  let locationRef = useRef();
  let titleRef = useRef();
  let skillTextRef = useRef();
  let typeRef = useRef();
  let locationTextRef = useRef();
  let minBudgetRef = useRef();
  let maxBudgetRef = useRef();
  let totalPositionsRef = useRef();

  useEffect(() => {
    let handler = (event) => {
      if (!skillRef.current.contains(event.target)) {
        if (additionalSkillSuggestion.length > 0) {
          addDataOnFocusOut(
            additionalSkillSuggestion,
            setAdditionalSkillSuggestion,
            setAdditionalSkills,
            setPreferredAdditionalSkillName
          );
        } else {
          addSkillCall();
        }
        document.getElementById("additional_skills").value = "";
        setAddSkillElement("");
      }
      if (!locationRef.current.contains(event.target)) {
        addDataOnFocusOut(
          locSuggestion,
          setLocSuggestion,
          setPreferred_location,
          setPreferredLocationName
        );
        document.getElementById("search_location").value = "";
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const onChangeHandler_location = (text) => {
    if (text.length > LOCATION_THRESHOLD) {
      commonServices.getLocations(text).then((locations) => {
        setLocSuggestion(locations);
      });
    } else {
      setLocSuggestion([]);
    }
  };

  const del_location = async (e, data) => {
    e.preventDefault();
    var arrow = preferred_location;
    await setPreferred_location([]);
    for (var i = 0; i < arrow.length; i++) {
      if (parseInt(arrow[i]) === data) {
        arrow.splice(i, 1);
      }
    }
    setPreferred_location(arrow);
  };

  const onChangeHandlerAdditionalSkills = (text) => {
    if (text.length > 2) {
      commonServices.getSkillByName(text).then((skills) => {
        setAdditionalSkillSuggestion(skills);
      });
    } else {
      setAdditionalSkillSuggestion([]);
    }
  };

  const removeAdditionalSkill = async (e, data) => {
    e.preventDefault();
    var arrow = additionalSkills;
    await setAdditionalSkills("");
    for (var i = 0; i < arrow.length; i++) {
      if (parseInt(arrow[i]) === data) {
        arrow.splice(i, 1);
      }
    }
    setAdditionalSkills(arrow);
  };

  let params = useParams();
  const gig_id = params.id;

  useEffect(() => {
    if (typeof gig_id !== "undefined") {
      setLoading(true);
      ApiHelper.getGigDetail(gig_id).then((response) => {
        if (response.isSuccess === true) {
          var data = response.data;
          if (data.user_id !== profile.id) {
            navigate(-1);
            const role = commonServices.getLocalData(USER_ROLE);
            if (role === COMPANY_ADMIN_ROLE) {
              toast.warn("This Gig is posted by a Company User");
            } else if (role === COMPANY_USER_ROLE) {
              toast.warn("This Gig is posted by a Company Admin");
            }
          } else {
            setGigId(data.id);
            setUserId(data.user_id);
            setDescription(data?.description !== null ? data?.description : "");
            setTitle(data.title);
            setGigTypeId(data.gig_type_id);
            setGigEngagementModeId(data.engagement_mode_id);
            setGigEngagementModeName(data?.engagement_mode?.name);
            setMinBudget(data.min_budget);
            setMaxBudget(data.max_budget);
            setTotalPositions(data.total_positions);
            setStatus(data.status);
            setCloseReasonId(data.close_reason_id);
            setCloseReasonDescription(data.close_reason_description);
            setPreferredLocationName(data.locations);
            setPreferred_location(data?.locations.map((location) => location.id));
            setPreferredAdditionalSkillName(data.skills);
            setAdditionalSkills(data?.skills.map((skill) => skill.id));
            setStatusType(data.status);
          }
        } else {
          toast.warn(response.message);
        }
        setLoading(false);
      });
    }
  }, []);

  var gigData = {
    id: gig_id,
    title: title,
    description: description,
    gig_type_id: gigTypeId,
    total_positions: totalPositions,
    engagement_mode_id: gigEngagementModeId,
    work_locations: preferred_location,
    required_skills: additionalSkills,
    min_budget: commonServices.removeCommaFromAmount(minBudget),
    max_budget: commonServices.removeCommaFromAmount(maxBudget),
    status: status,
    close_reason_id: closeReasonId,
    close_reason_description: closeReasonDescription,
  };

  const validateData = () => {
    let status = true;
    if (title === "") {
      titleRef.current.focus();
      status = false;
      toast.warn(erros.GIG_TITLE_REQUIRED);
    } else if (title.length > 100) {
      titleRef.current.focus();
      status = false;
      toast.warn(erros.GIG_TITLE_LIMIT_ERROR);
    } else if (isNullOrEmpty(gigTypeId)) {
      typeRef.current.focus();
      status = false;
      toast.warn(erros.GIG_TYPE_REQUIRED);
    } else if (
      preferred_location.length === 0 &&
      gigEngagementModeName !== REMOTE
    ) {
      locationTextRef.current.focus();
      status = false;
      toast.warn(erros.PRE_LOCATION_REQUIRED);
    } else if (minBudget === "") {
      minBudgetRef.current.focus();
      status = false;
      toast.warn(erros.MIN_BUDGET_REQUIRED);
    } else if (maxBudget === "") {
      maxBudgetRef.current.focus();
      status = false;
      toast.warn(erros.MAX_BUDGET_REQUIRED);
    } else if (totalPositions === "") {
      totalPositionsRef.current.focus();
      status = false;
      toast.warn(erros.TOTAL_POSITIONS_REQUIRED_ERROR);
    } else if (parseInt(totalPositions) < 1) {
      totalPositionsRef.current.focus();
      status = false;
      toast.warn(erros.TOTAL_POSITIONS_LIMIT_ERROR);
    } else if (
      commonServices.removeCommaFromAmount(maxBudget) <
      commonServices.removeCommaFromAmount(minBudget)
    ) {
      minBudgetRef.current.focus();
      status = false;
      toast.warn(erros.MIN_MAX_BUDGET_ERROR);
    } else if (
      commonServices.removeCommaFromAmount(minBudget) > BUDGET_LIMIT ||
      commonServices.removeCommaFromAmount(maxBudget) > BUDGET_LIMIT
    ) {
      maxBudgetRef.current.focus();
      status = false;
      toast.warn(erros.BUDGET_LIMIT_ERROR);
    } else if (additionalSkills.length === 0) {
      skillTextRef.current.focus();
      status = false;
      toast.warn(erros.GIG_SKILL_REQUIRED);
    }

    if (status === true) setIsStepOneValid(true);
    return status;
  };

  const saveGig = (status = "draft") => {
    var isError = 0;

    if (status === "draft") {
      if (title === "") {
        isError = 1;
        toast.warn(erros.GIG_TITLE_REQUIRED);
      }
    }

    if (status === 'published') {
      if (validateData()) {
        if (isNullOrEmpty(stripHTMLTags(description))) {
          status = false;
          toast.warn(GIG_DESCRIPTION_REQUIRED);
          isError = 1;
        } else if (stripHTMLTags(description).length > 3000) {
          isError = 1;
          toast.warn('Max 3000 Characters');
        } else {
          isError = 0;
        }
      } else {
        isError = 1;
      }
    }

    if (isError === 0) {
      setLoading(true);
      if (typeof gig_id !== "undefined") {
        gigData.id = gig_id;
      }
      gigData.status = status;
      ApiHelper.addEditGig(gigData).then((response) => {
        setLoading(false);
        if (response.isSuccess === true) {
          toast.success(response.message);
          navigate("/posted-gigs");
        } else {
          toast.warn(response.message);
        }
      });
    }
  };

  const nextStep = (status = 'draft') => {
    if (status === 'published' && validateData()) {
      window.scrollTo(0, 0);
      setCurrentTab("description");
    } else if (status === 'draft') {
      window.scrollTo(0, 0);
      setCurrentTab("description");
    }
  };

  const [addSkillElement, setAddSkillElement] = useState("");

  const addSkills = (event) => {
    if (event.which === 13 || event.keyCode === 13) {
      addSkillCall();
      event.target.value = "";
    }
  };

  const addSkillCall = () => {
    if (addSkillElement !== "" && addSkillElement !== undefined) {
      const formData = new FormData();
      formData.append("name", addSkillElement);
      setLoading(true);

      ApiHelper.addSkill(formData).then((response) => {
        setLoading(false);
        if (response.isSuccess === true) {
          response.data.forEach((element) => {
            if (stringCompare(addSkillElement, element.name)) {
              setAdditionalSkills([...additionalSkills, element.id]);
              setPreferredAdditionalSkillName([
                ...preferredAdditionalSkillName,
                element,
              ]);
            }
          });
          document.getElementById("additional_skills").value = "";

          onChangeHandlerAdditionalSkills(" ");
          setAddSkillElement("");
        } else {
          toast.warn(response.message);
        }
      });
    }
  };

  function selectOnlyThis(id) {
    var myCheckbox = document.getElementsByName("myCheckbox");
    Array.prototype.forEach.call(myCheckbox, function (el) {
      el.checked = false;
    });
    id.checked = true;
  }

  const getAIDescription = () => {
    setDescriptionLoading(true);
    var queryString = "?type=gig&title=" + title;
    ApiHelper.getDescriptionByChatGPT(queryString).then((response) => {
      if (response.isSuccess === true) {
        setDescriptionLoading(false);
        setDescription(response.data.description);
      } else {
        setDescriptionLoading(false);
        toast.warn(response.message);
      }
    });
  };

  const triggerClickDown = (event) => {
    const list = [...document.querySelectorAll(".sugg_p")];
    const active = document.querySelector(".sugg_p.active");
    let i = list.indexOf(active);

    if (event.which === 40 || event.keyCode === 40) {
      if (active) {
        active.classList.remove("active");
      }

      const c = document.getElementsByClassName("sugg_p hand-hover");

      if (c.length > 0) {
        if (c[i + 1]) {
          c[i + 1].classList.add("active");
          c[i + 1].scrollIntoView({ block: "nearest", inline: "nearest" });
        }
      }
    } else if (event.which === 27 || event.keyCode === 27) {
      setLocSuggestion([]);
      setAdditionalSkillSuggestion([]);
    } else if (event.which === 9 || event.keyCode === 9) {
      if (event.target.name === "additionalSkills") {
        if (additionalSkillSuggestion.length > 0) {
          addDataOnFocusOut(
            additionalSkillSuggestion,
            setAdditionalSkillSuggestion,
            setAdditionalSkills,
            setPreferredAdditionalSkillName
          );
          setAddSkillElement("");
        } else {
          addSkillCall();
        }
        event.target.value = "";
      } else if (event.target.name === "location") {
        addDataOnFocusOut(
          locSuggestion,
          setLocSuggestion,
          setPreferred_location,
          setPreferredLocationName
        );
        event.target.value = "";
      }
    }
  };

  const triggerClickUp = (event) => {
    const list = [...document.querySelectorAll(".sugg_p")];
    const active = document.querySelector(".sugg_p.active");
    let i = list.indexOf(active);
    if (event.which === 38 || event.keyCode === 38) {
      if (active) {
        active.classList.remove("active");
      }
      const c = document.getElementsByClassName("sugg_p hand-hover");

      if (c.length > 0) {
        if (c[i - 1]) {
          c[i - 1].classList.add("active");
          c[i - 1].scrollIntoView({ block: "nearest", inline: "nearest" });
        }
      }
    }
  };

  const triggerSelect = (event) => {
    const list = [...document.querySelectorAll(".sugg_p")];
    const active = document.querySelector(".sugg_p.active");
    let i = list.indexOf(active);

    if (event.which === 13 || event.keyCode === 13) {
      const c = document.getElementsByClassName("sugg_p hand-hover");

      if (active) {
        active.click();
      }
    }
  };

  return (
    <AddGigLayouts
      loading={loading}
      color={color}
      override={override}
      gig_id={gig_id}
      currentTab={currentTab}
      nextStep={nextStep}
      statusType={statusType}
      saveGig={saveGig}
      setCurrentTab={setCurrentTab}
      titleRef={titleRef}
      title={title}
      setTitle={setTitle}
      masterData={masterData}
      typeRef={typeRef}
      setGigTypeId={setGigTypeId}
      setGigEngagementModeName={setGigEngagementModeName}
      gigEngagementModeName={gigEngagementModeName}
      locationRef={locationRef}
      commonServices={commonServices}
      locationTextRef={locationTextRef}
      onChangeHandler_location={onChangeHandler_location}
      triggerSelect={triggerSelect}
      triggerClickDown={triggerClickDown}
      locSuggestion={locSuggestion}
      setPreferred_location={setPreferred_location}
      preferred_location={preferred_location}
      setPreferredLocationName={setPreferredLocationName}
      del_location={del_location}
      minBudgetRef={minBudgetRef}
      setMinBudget={setMinBudget}
      maxBudgetRef={maxBudgetRef}
      setMaxBudget={setMaxBudget}
      totalPositionsRef={totalPositionsRef}
      totalPositions={totalPositions}
      setTotalPositions={setTotalPositions}
      skillRef={skillRef}
      skillTextRef={skillTextRef}
      triggerClickUp={triggerClickUp}
      additionalSkillSuggestion={additionalSkillSuggestion}
      additionalSkills={additionalSkills}
      setPreferredAdditionalSkillName={setPreferredAdditionalSkillName}
      preferredAdditionalSkillName={preferredAdditionalSkillName}
      onChangeHandlerAdditionalSkills={onChangeHandlerAdditionalSkills}
      setAddSkillElement={setAddSkillElement}
      addSkillElement={addSkillElement}
      descriptionLoading={descriptionLoading}
      getAIDescription={getAIDescription}
      description={description}
      setDescription={setDescription}
      gigEngagementModeId={gigEngagementModeId}
      setGigEngagementModeId={setGigEngagementModeId}
      gigTypeId={gigTypeId}
      preferredLocationName={preferredLocationName}
      minBudget={minBudget}
      maxBudget={maxBudget}
      addSkills={addSkills}
      setAdditionalSkills={setAdditionalSkills}
      removeAdditionalSkill={removeAdditionalSkill}
      isStepOneValid={isStepOneValid}
    />
  );
}

export default AddGig;
