import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { maxLengthAllowed } from '../../constants/stringConstants';
import { warn } from "../../wrapper/toast"

function WorkProfileEditor({ index, workprofileData, setWorkprofileData, element, maxHeight }) {
  const [editorHeight, setEditorHeight] = useState('400px');

  const handleChange = (value) => {
    let tempArray = [...workprofileData];
    if (index !== undefined) {
      tempArray[index]['description'] = value;
      setWorkprofileData(tempArray);
    }
  };

  const handleResize = () => {
    const editor = document.querySelector('.ql-editor');
    if (editor) {
      const currentHeight = editor.clientHeight;
      if (currentHeight > maxHeight) {
        setEditorHeight(`${maxHeight}px`);
      } else {
        setEditorHeight('auto');
      }
    }
  };

  return (
    <div style={{ maxHeight: editorHeight, overflow: 'hidden' }}>
      <ReactQuill
        theme="snow"
        value={(element && element.description) ? element.description : ''}
        onChange={(value, _, __, editor) => {
          if (editor.getLength() + 1 <= 500) {
            handleChange(value);
          } else {
            warn(maxLengthAllowed(500));
            setWorkprofileData(prev => prev);
          }
        }}
        modules={{
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['clean'],
          ],
        }}
        formats={[
          'bold', 'italic', 'underline', 'strike',
          'list', 'bullet',
        ]}
        style={{ maxHeight: editorHeight }}
        onKeyUp={handleResize}
        onBlur={handleResize}
      />
    </div>
  );
}

export default WorkProfileEditor;
