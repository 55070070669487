import React, { useEffect, useState } from 'react';

import * as toast from "@/wrapper/toast";
import './Contact.scss';

import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import * as erros from '@/constants/stringConstants';
import * as ApiHelper from '@/services/apiHelper';
import * as commonServices from '@/services/common';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { COUNTRY } from '@/constants/storageConstants';
import emailImg from "@/Assets/svgs/email.svg";
import phone from "@/Assets/svgs/phone.svg";
import marker from "@/Assets/svgs/marker.svg";
import * as storageConstants from '@/constants/storageConstants';
import { MaxWidthContainer } from '../Common/MaxWidthContainer';
import Input from "../_widgets/Input/Input";
import Button from "../_widgets/Button/Button";
import Text from "../_widgets/Text/Text";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: var(--green);
  left: calc(50% - 75px);
  position: fixed;
`;

function Contact({ isMasterDataLoaded }) {

    var masterData = commonServices.getLocalData(storageConstants.DATA);

    const [data, setData] = useState((masterData !== null && masterData.system_config !== null && masterData.system_config !== undefined) ? masterData.system_config : {});

    useEffect(() => {
        masterData = commonServices.getLocalData(storageConstants.DATA);
        setData((masterData !== null && masterData.system_config !== null && masterData.system_config !== undefined) ? masterData.system_config : {});
    }, [isMasterDataLoaded])

    let [loading, setLoading] = useState(false);
    let [color] = useState("#000000");

    const [fullName, setFullName] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [email, setEmail] = useState(null);
    const [companyName, setCompanyName] = useState(null);
    const [message, setMessage] = useState(null);

    const contactUs = async (e) => {
        e.preventDefault();

        if (!fullName) toast.warn(erros.NAME_REQUIRED);
        else if (!email) toast.warn(erros.EMAIL_REQUIRED);
        else if (!commonServices.checkEmailValid(email)) toast.warn(erros.INCORRECT_EMAIL);
        else if (!message) toast.warn(erros.MESSAGE_REQUIRED);
        else if (phoneNumber && !isValidPhoneNumber(phoneNumber)) toast.warn(erros.PHONE_NUMBER_INCORRECT);
        else {
            setLoading(true);
            var data = {
                "name": fullName,
                "phone": phoneNumber,
                "email": email,
                "company_name": companyName,
                "message": message
            }

            await ApiHelper.contact(JSON.stringify(data)).then((response) => {
                setLoading(false);
                if (response.isSuccess === true) {
                    toast.success(response.message);
                    setFullName(null);
                    setPhoneNumber(null);
                    setEmail(null);
                    setCompanyName(null);
                    setMessage(null);
                }
                else toast.warn(response.message);
            });
        }
    }

    return (
        <>
            {loading ?
                <div className='loader'>
                    <ClipLoader color={color} loading={true} css={override} size={150} /> </div> : null}
            <MaxWidthContainer pageKey="contact">

                <div className='contact_page'>
                    <div className="body tt-container">
                        <div className="left">
                            <img onError={commonServices.imgError} src="./Assets/images/contact_banner.png?1" alt="" />

                            <Text type="h2" text="We are listening" />

                            <Text type="h7" text=" Write us in case you wish you share feedbacks, suggestions or wish to add value to the project “TrueTalent”." />

                            <ul>
                                <li><img onError={commonServices.imgError} src={emailImg} alt="" /><p><a target="_blank" href={`mailto:${data?.company_email?.value}`}>{data?.company_email?.value}</a></p></li>

                                {commonServices.checkRecordExists(data?.company_phone?.value) ?
                                    <li><img onError={commonServices.imgError} src={phone} alt="" /><p><a target="_blank" href={`tel:${data?.company_phone?.value}`}>{data?.company_phone?.value}</a></p></li>
                                    : null}

                                {commonServices.checkRecordExists(data?.company_address?.value) ?
                                    <li><img onError={commonServices.imgError} src={marker} alt="" /><p><a target="_blank" href={`http://maps.google.com/?q=${data?.company_address?.value}`}>{data?.company_address?.value}</a></p></li>
                                    : null}
                            </ul>
                        </div>

                        <div className="right">
                            <Text type="h2" text="Send us a message" />

                            <div className="row">
                                <div className="col">
                                    <Input type="text" label="First & Last Name" isRequired={true} placeholder="Enter First and Last Name" id="full_name" name="full_name" value={fullName ?? ''} onChange={e => setFullName(e.target.value)} />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <Input
                                        type="phone"
                                        label="Phone Number"
                                        placeholder="Enter phone number"
                                        id="phone_number"
                                        name="phone_number"
                                        defaultCountry={COUNTRY}
                                        value={phoneNumber ?? ''}
                                        style={{ backgroundColor: "white" }}
                                        onChange={phoneNumber => setPhoneNumber(phoneNumber)
                                        } />
                                </div>

                                <div className="col">
                                    <Input type="email" label="Email Address" isRequired={true} placeholder="Enter Email Address" id="email" name="email" value={email ?? ''} onChange={e => setEmail(e.target.value)} />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <Input type="text" label="Company Name" isRequired={false} placeholder="Enter Company Name" id="company_name" name="company_name" value={companyName ?? ''} onChange={e => setCompanyName(e.target.value)} />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <Input type="textarea" label="Message" isRequired={true} placeholder="Enter your Message here..." id="message" name="message" cols="30" rows="8" value={message ?? ''} onChange={e => setMessage(e.target.value)} />
                                </div>
                            </div>

                            {/* Skipping span for now */}
                            <div className="row">
                                <div className="col">
                                    <label className="input-lebel">
                                        <span className='red'>*</span> Fields required
                                    </label>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <Button buttonType="primary" text="Contact Us Now!" onClick={contactUs} />
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </MaxWidthContainer >
        </>
    )
}

export default Contact;