/* eslint-disable */
import React, { useEffect, useState } from "react";
import "./PostedJobs.scss";
import * as ApiHelper from "@/services/apiHelper";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";
import { useNavigate } from "react-router";
import * as commonServices from "@/services/common";
import emptyResult from "@/Assets/svgs/empty_result.svg";
import * as toast from "@/wrapper/toast";

import * as storageConstants from "@/constants/storageConstants";
import { addTabInHistory, getCurrentTab } from "@/utils/redirectUtil";
import { ACTIVE, CLOSED, DRAFT } from "@/constants/tabConstants";
import { createSlugFromString } from "@/utils/stringUtils";
import referredJobImg from "@/Assets/svgs/referredJob.svg";
import PostedJobsLayouts from "./PostedJobsLayouts";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: var(--green);
  left: calc(50% - 75px);
  position: fixed;
`;

function PostedJobs({ setJobSearch }) {
  const [activeJobs, setActiveJobs] = useState();
  const [draftJobs, setDraftJobs] = useState();
  const [closedJobs, setClosedJobs] = useState();
  const [type, setType] = useState("MY_JOBS");
  const navigate = useNavigate();
  let [loading, setLoading] = useState(false);
  let [color] = useState("#000000");
  const [orderBy, setOrderBy] = useState("MOST_RECENT");
  const [orderByText, setOrderByText] = useState("Newest First");
  const [currentTab, setCurrentTab] = useState(ACTIVE);

  var tab = getCurrentTab();

  const getJobs = () => {
    setLoading(true);
    ApiHelper.getMyJobs("?order_by=" + orderBy + "&type=" + type).then(
      (response) => {
        setLoading(false);
        if (response.isSuccess === true) {
          setActiveJobs(response.data.active);
          setClosedJobs(response.data.closed);
          setDraftJobs(response.data.draft);
        } else {
          toast.warn(response.message);
          console.log("error");
        }
      }
    );
  };

  const handleJobClick = (e, job) => {
    var elementClass = e.target.className;
    if (elementClass === storageConstants.APPLICANTS) {
      var requiredSkillsArr = job.job_required_skills.split(",");
      var additionalSkillsArr =
        job.job_additional_skills !== null
          ? job.job_additional_skills.split(",")
          : [];
      var skills = requiredSkillsArr
        .concat(additionalSkillsArr)
        .map(function (x) {
          return parseInt(x, 10);
        });
      var queryParam = "?";
      for (let i = 0; i < skills.length; i++) {
        queryParam += "skills=" + skills[i] + "&";
      }
      setJobSearch(skills);
      navigate("/jobseeker/search" + queryParam);
    } else {
      navigate(
        "/job/details/" + job.eid + "/" + createSlugFromString(job.title)
      );
    }
  };


  useEffect(() => {
    if (tab !== null) {
      setCurrentTab(tab);
    }
    getJobs();
  }, [orderBy, type]);

  const onTabClick = (tab) => {
    addTabInHistory(tab);
    setCurrentTab(tab);
  }

  // useEffect(()=>{
  //   const tab = getCurrentTab()
  //   if(tab !== currentTab){
  //     setCurrentTab(tab)
  //   }
  // }, [currentTab])

  return (
    <PostedJobsLayouts
      override={override}
      activeJobs={activeJobs}
      draftJobs={draftJobs}
      closedJobs={closedJobs}
      type={type}
      setType={setType}
      loading={loading}
      color={color}
      setOrderBy={setOrderBy}
      orderByText={orderByText}
      setOrderByText={setOrderByText}
      currentTab={currentTab}
      navigate={navigate}
      handleJobClick={handleJobClick}
      tab={tab}
      setCurrentTab={setCurrentTab}
      onTabClick={onTabClick}
    />
  );
}

export default PostedJobs;
