/* eslint-disable */
import React, { useState, useEffect } from "react";
import * as ApiHelper from "@/services/apiHelper";

import "./Modal.scss";
import * as errors from "@/constants/stringConstants";
import * as commonServices from "@/services/common";
import { NO_REGISTRATION_CODE } from "@/constants/responseCodes";
import ModalContactDetails from "../Modals/ModalContactDetails";
import { BUTTON_TEXT_OK } from "@/constants/stringConstants";
import * as storageConstants from "@/constants/storageConstants";
import * as toast from "@/wrapper/toast";
import AppModal from "./AppModal";

function ModalAddEditCompanyUser({
  showModal,
  setShowModal,
  editUserId,
  editUserFirstName,
  editUserLastName,
  editUserEmail,
  editUserAllowedCash,
  getUsers,
  availableCash,
}) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [allowedCash, setAllowedCash] = useState(0);
  let [loading, setLoading] = useState(false);
  const [showModalError, setShowModalError] = useState(false);
  const [modalErrorData, setModalErrorData] = useState();
  const profile = commonServices.getLocalData(storageConstants.PROFILE);
  const [domainError, setDomainError] = useState("");

  const matchDomains = (em) => {
    try {
      var website_domain = new URL(profile?.company?.website);
    } catch (e) {
      console.error(e);
    }
    website_domain = website_domain.hostname.replace("www.", "");
    var website_domains = [];
    var domains = [];
    if (profile?.company?.domains !== undefined) {
      website_domains = profile?.company?.domains;

      website_domains.map((domain) => {
        domains.push(domain.name.toUpperCase());
        website_domain += ", " + domain.name;
      });
    }

    var email_domain = em.substring(em.lastIndexOf("@") + 1);

    if (
      email_domain.toUpperCase() !== website_domain.toUpperCase() &&
      !domains.includes(email_domain.toUpperCase())
    ) {
      setDomainError(
        'Please add an email with domain "' + website_domain + '"'
      );
    } else {
      setDomainError("");
    }
  };

  const initialiseValues = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setAllowedCash(0);
    setDomainError("");
  };

  const loadData = () => {
    if (editUserId !== null) {
      setFirstName(editUserFirstName);
      setLastName(editUserLastName);
      setEmail(editUserEmail);
      setAllowedCash(editUserAllowedCash);
    } else {
      initialiseValues();
    }
  };

  useEffect(() => {
    loadData();
  }, [editUserId]);

  const addEditUser = () => {
    var isError = 0;
    if (firstName === "") {
      isError = 1;
      toast.warn(errors.FIRST_NAME_REQUIRED);
    } else if (lastName === "") {
      isError = 1;
      toast.warn(errors.LAST_NAME_REQUIRED);
    } else if (email === "") {
      isError = 1;
      toast.warn(errors.EMAIL_REQUIRED);
    } else if (availableCash < allowedCash - editUserAllowedCash) {
      isError = 1;
      toast.warn(errors.NO_ENOUGH_TT_CASH_ERROR_MESSAGE);
    }

    if (isError === 0) {
      const formData = new FormData();
      if (editUserId !== null) {
        formData.append("id", editUserId);
      }
      formData.append("first_name", firstName);
      formData.append("last_name", lastName);
      formData.append("email", email);
      formData.append("total_profile_views", allowedCash);
      setLoading(true);
      ApiHelper.addEditCompanyUser(formData).then((response) => {
        setLoading(false);
        if (response.isSuccess === true) {
          toast.success(response.message);
          getUsers();
          if (editUserId === null) {
            initialiseValues();
          }
          setShowModal((prev) => !prev);
        } else {
          if (response.code === NO_REGISTRATION_CODE) {
            setShowModalError(true);
            setModalErrorData({
              ...modalErrorData,
              heading: errors.USER_REGISTRATION_ISSUE,
              paragraph: response.message,
              buttonText: BUTTON_TEXT_OK,
            });
          } else {
            toast.warn(response.message);
          }
        }
      });
    }
  };

  return (
    <>
      <AppModal
        title={(editUserId === null ? "Add" : "Edit") + " Hiring Specialist"}
        isOpen={showModal}
        closeModal={() => {
          loadData();
          setShowModal((prev) => !prev);
        }}
        submit={() => addEditUser()}
        isFooter={true}
        size={"md"}
        loading={loading}
      >
        <ModalContactDetails
          showModal={showModalError}
          setShowModal={setShowModalError}
          modalData={modalErrorData}
        />

        <div className="modal_body">
          <div className="w-full">
            <div className="d-fex flex-column w-100 gap-2">
              <div className="row w-100">
                <div className="col-12">
                  <label className="input-lebel">First Name</label>
                  <input
                    type="text"
                    value={firstName}
                    className="form-control"
                    onChange={(e) => setFirstName(e.target.value)}
                  ></input>
                </div>
                <div className="col-12">
                  <label className="input-lebel">Last Name</label>
                  <input
                    type="text"
                    value={lastName}
                    className="form-control"
                    onChange={(e) => setLastName(e.target.value)}
                  ></input>
                </div>
                <div className="col-12">
                  <label className="input-lebel">Email</label>
                  {editUserId === null ? (
                    <>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => {
                          setEmail(e.target.value);
                          matchDomains(e.target.value);
                        }}
                      ></input>
                      <span className="red-text">{domainError}</span>
                    </>
                  ) : (
                    <span className="form-control">{email}</span>
                  )}
                </div>
                <div className="col-12">
                  <label className="input-lebel">Allowed TT Cash</label>
                  <input
                    type="text"
                    value={commonServices.currencyFormatter(allowedCash)}
                    min="0"
                    className="form-control"
                    onChange={(e) => {
                      if (
                        commonServices.isValidCurrency(
                          commonServices.removeCommaFromAmount(e.target.value)
                        )
                      ) {
                        setAllowedCash(
                          commonServices.removeCommaFromAmount(e.target.value)
                        );
                      }
                    }}
                  ></input>
                </div>

                {allowedCash > 0 ? (
                  <div className="col-12 mt-2">
                    <span>
                      <p>
                        <b>Current TT Cash: </b>
                        {commonServices.currencyFormatter(editUserAllowedCash)}
                      </p>
                    </span>
                    <span>
                      <p>
                        <b>New TT Cash: </b>
                        {commonServices.currencyFormatter(allowedCash)}
                      </p>
                    </span>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </AppModal>
    </>
  );
}

export default ModalAddEditCompanyUser;
