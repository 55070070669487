/* eslint-disable */
import { css } from "@emotion/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import emptyResult from "@/Assets/svgs/empty_result.svg";
import * as ApiHelper from "@/services/apiHelper";
import * as commonServices from "@/services/common";
import * as toast from "@/wrapper/toast";
import * as storageConstants from "@/constants/storageConstants";
import {  getCurrentTab } from "@/utils/redirectUtil";
import { ACTIVE,  DRAFT } from "@/constants/tabConstants";
import { createSlugFromString } from "@/utils/stringUtils";
import PostedGigsLayout from "./PostedGigsLayout";
import { addTabInHistory } from "@/utils/redirectUtil";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: var(--green);
  left: calc(50% - 75px);
  position: fixed;
`;

function PostedGigs({ setJobSearch }) {
  const [activeGigs, setActiveGigs] = useState();
  const [draftGigs, setDraftGigs] = useState();
  const [closedGigs, setClosedGigs] = useState();
  const [type, setType] = useState("MY_GIGS");
  const navigate = useNavigate();
  let [loading, setLoading] = useState(false);
  let [color] = useState("#000000");
  const [orderBy, setOrderBy] = useState("MOST_RECENT");
  const [orderByText, setOrderByText] = useState("Newest First");
  const [currentTab, setCurrentTab] = useState(ACTIVE);

  var tab = getCurrentTab();

  const getGigs = async () => {
    setLoading(true);
    ApiHelper.getMyGigs(orderBy + "&type=" + type).then((response) => {
      setLoading(false);
      if (response.isSuccess === true) {
        setActiveGigs(response.data.active);
        setClosedGigs(response.data.closed);
        setDraftGigs(response.data.draft);
      } else {
        toast.warn(response.message);
        console.log("error");
      }
    });
  };

  useEffect(() => {
    if (tab !== null) {
      setCurrentTab(tab);
    }
    getGigs();
  }, []);

  useEffect(() => {
    getGigs();
  }, [orderBy, type]);

  const onTabClick = (tab) => {
    addTabInHistory(tab);
    setCurrentTab(tab);
  }

  const handleGigClick = (e, gig) => {
    var elementClass = e.target.className;
    if (elementClass === storageConstants.APPLICANTS) {
      var skills = gig.skills.map(function (x) {
        return parseInt(x.id, 10);
      });
      var queryParam = "?";
      for (let i = 0; i < skills.length; i++) {
        queryParam += "skills=" + skills[i] + "&";
      }
      setJobSearch(skills);
      navigate("/jobseeker/search" + queryParam);
    } else {
      navigate(
        "/gig/details/" + gig.eid + "/" + createSlugFromString(gig?.title)
      );
    }
  };

  return (
    <PostedGigsLayout
      color={color}
      override={override}
      emptyResult={emptyResult}
      closedGigs={closedGigs}
      navigate={navigate}
      currentTab={currentTab}
      DRAFT={DRAFT}
      draftGigs={draftGigs}
      createSlugFromString={createSlugFromString}
      commonServices={commonServices}
      activeGigs={activeGigs}
      storageConstants={storageConstants}
      handleGigClick={handleGigClick}
      loading={loading}
      type={type}
      setType={setType}
      orderByText={orderByText}
      setOrderBy={setOrderBy}
      setOrderByText={setOrderByText}
      onTabClick ={onTabClick}
    />
  );
}

export default PostedGigs;
