import React from "react";
import { Modal } from "react-bootstrap";
import Button from "../_widgets/Button/Button";
import Text from "../_widgets/Text/Text";

const AppModal = ({
  children,
  closeModal,
  isOpen,
  submit,
  submitText = "Save",
  cancelText = "Cancel",
  title,
  isFooter,
  loading,
  scrollHidden = false,
  disableSubmit = false,
  minH,
  size,
}) => {
  return (
    <Modal
      centered
      size={size ? size : "lg"}
      show={isOpen}
      onHide={closeModal}
      style={{ zIndex: 100000 }}
      scrollable
      className="no-scroll"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          overflowY: "auto",
          margin: "0",
          maxHeight: "75vh",
          minHeight: minH,
          position: "relative",
        }}
      >
        {children}
      </Modal.Body>
      {isFooter && (
        <Modal.Footer>
          <Button
            buttonType="secondary"
            onClick={closeModal}
            text={cancelText}
          ></Button>
          <Button
            buttonType="primary"
            onClick={submit}
            disabled={disableSubmit}
            text={loading ? "Loading..." : submitText}
          ></Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default AppModal;
