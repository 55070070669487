import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { isCompanyDashboardEnabled } from '@/utils/masterDataUtil';
import { canAccessDashboard, canUpdateCompanyProfile } from '@/utils/roleUtil';
import { isCompany, isCompanyAdmin, isPartnerAdmin } from '@/utils/authUtil';

export default function CompanyTabs() {
    const navigate = useNavigate();
    return (
        <>
            {isCompany() ? (<li><a className="dropdown-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={() => { navigate('/company/users') }}>User Dashboard</a> </li>) : null}
            {isCompanyAdmin() ? (<li><a className="dropdown-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={() => { navigate('/company-dashboard') }}>Company Dashboard</a> </li>) : null}
            {isPartnerAdmin() ? (<li><a className="dropdown-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={() => { navigate('/partner/dashboard') }}>Company Dashboard</a> </li>) : null}
            {isCompany() ? (<li><a className="dropdown-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" aria-current="page" onClick={() => { navigate('/posted') }}>Jobs Panel</a></li>) : null}
            {isCompany() ? (<li><a className="dropdown-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" aria-current="page" onClick={() => { navigate('/posted-gigs') }}>Gigs Panel</a></li>) : null}
            {isCompany() ? (<li><a className="dropdown-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" aria-current="page" onClick={() => { navigate('/view-later') }}>Saved Candidates</a></li>) : null}            
            {(isCompanyDashboardEnabled() && canAccessDashboard()) ? (<li><a className="dropdown-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={() => { navigate('/company/dashboard') }}>Dashboard</a> </li>) : null}
        </>
    )
}