import React from 'react'
import styles from './thirdTemplate.module.css'
import { MdOutlineMail } from "react-icons/md";
import { IoIosCall } from "react-icons/io";
import { FaStar } from "react-icons/fa";
import { FaRegStar } from 'react-icons/fa6';
import { isNotEmpty } from '../../../utils/arrayUtils';
import logo from "@/Assets/images/watermark.png"

import * as commonServices from '@/services/common';


const ThirdTemplate = ({ profile, components, downloadType, odHeading, customSections }) => {

  // Templates
  const renderLeftMenus = (icon, label) => {
    return <div className={styles.flex} >
      {/* <>{icon}</> */}
      <div className={styles.label} >{label}</div>
    </div>
  }

  const renderSkill = (label, count) => {
    return <div className={`${styles.flexRow} ${styles.skillRow}`} >
      <div>{label}</div>
      <div>{renderStar(count * 0.05)}</div>
    </div>
  }

  const renderTemplate = (label, value) => {
    return <div className={styles.flexRow} >
      <div className={styles.label} dangerouslySetInnerHTML={{ __html: label || '' }}></div>
      <div className={styles.value} >{value}</div>
    </div>
  }

  const renderTemplateReference = (label, value) => {
    return <div className={styles.flexRow} >
      <div className={styles.value} dangerouslySetInnerHTML={{ __html: label || '' }}></div>
    </div>
  }
  const renderTemplateCap = (label, value) => {
    return <div className={styles.flexRow} >
      <div className={styles.label} dangerouslySetInnerHTML={{ __html: label || '' }}></div>
      <div className={styles.valueCap} >{value}</div>
    </div>
  }

  const renderLink = (label, value) => {
    return <div className={styles} style={{ marginBottom: "10px" }}>
      <div className={styles.title} dangerouslySetInnerHTML={{ __html: label || '' }}></div>
      <div className={styles.value} >{value}</div>
    </div>
  }

  const renderStar = (count) => {
    let numbers = Array.from({ length: count });
    let emptyNumbers = Array.from({ length: 5 - count });
    return <div className={styles.rowFlex}>
      {numbers?.map((val) => {
        return <FaStar fontSize={12} />
      })}
      {emptyNumbers?.map((val) => {
        return <FaRegStar fontSize={12} />
      })}

    </div>
  }


  return (
    <div className={styles.Wrapper} >
      {downloadType === 1 || downloadType === null ?
        <div
          style={{
            position: "absolute",
            justifyContent: "center",
            top: "10px",
            right: "10px",
            display: "flex",
            gap: "5px",
            alignItems: "center"
          }}
        >

          <img height="18px" onError={commonServices.imgError}
            src={logo ?? 'N/A'}
            alt="/Assets/images/watermark.png" />
        </div>
        : null}
      <div className={styles.leftMenu} >
        <div className={styles.subMenu} >
          <div className={styles.secOne} >
            <div className={styles.name} >{profile.first_name} {profile.last_name}</div>
            <div className={styles.designation} >{profile?.designation}</div>
          </div>
          <div className={styles.secondSec} >
            {profile?.email ? <h2 style={{ marginBottom: 10 }}> Personal Details</h2> : null}
            {renderLeftMenus(<MdOutlineMail />, profile?.email)}
            {renderLeftMenus(<IoIosCall />, profile?.contact)}
          </div>
          {
            components.map((component, index) => {
              switch (component.component) {
                case "skills": return isNotEmpty(profile?.userWorkProfileDetail?.skill) ? <div className={styles.thirdSec} >
                  <div className={styles.head}>Skills</div>
                  {
                    profile?.userWorkProfileDetail?.skill?.map((item, index) =>
                      (renderSkill(item?.skill_name, item?.experience >= profile?.user_work_profile?.total_experience ? 100 : (item?.experience / profile.user_work_profile.total_experience) * 100)))
                  }
                </div> : null

                case "websites_social_links": return isNotEmpty(profile?.userWorkProfileDetail?.website) ? <div className={styles.thirdSec} >
                  <div className={styles.head}>Links</div>
                  {
                    profile?.userWorkProfileDetail?.website?.map((item, index) => (
                      renderLink(item?.name, item?.url)
                    ))
                  }
                </div> : null

                case "hobbies": return <> {customSections.hobbies && profile?.hobbies ? <div className={styles.thirdSec} >
                  <div className={styles.head}>Hobbies</div>
                  {renderTemplate(profile?.hobbies, '')}
                </div> : null} </>

                case "languages": return <> {customSections.languages && isNotEmpty(profile?.userWorkProfileDetail?.language) ? <div className={styles.thirdSec} >
                  <div className={styles.head}>Languages</div>
                  {
                    profile?.userWorkProfileDetail?.language?.map((item, index) => (
                      renderTemplateCap(item?.name, item?.experience)
                    ))
                  }
                </div> : null} </>

                case "references": return <> {customSections.references && profile?.reference ? <div className={styles.thirdSec} >
                  <div className={styles.head}>References</div>
                  {renderTemplateReference(profile?.reference, '')}
                </div> : null} </>

                case "achievements": return <> {customSections.achievements && profile?.reference ? <div className={styles.thirdSec} >
                  <div className={styles.head}>Achievements</div>
                  {renderTemplate(profile?.achievements, '')}
                </div> : null} </>

                case "details": return <> {customSections.details && profile[odHeading] ? <div className={styles.thirdSec} >
                  <div className={styles.head}>{odHeading}</div>
                  {renderTemplate(profile[odHeading], '')}
                </div> : null} </>

                case "extra": return <> {customSections.extra && profile?.extra_curricular_activity ? <div className={styles.thirdSec} >
                  <div className={styles.head}>Extra-Curricular Activities</div>
                  {renderTemplate(profile?.extra_curricular_activity, '')}
                </div> : null} </>
              }
            })
          }
        </div>
      </div>

      <div className={styles.rightMenu} >
        {components.map((component, index) => {
          switch (component.component) {
            case "professional_summary": return <div className={styles.summary} >
              {profile?.description ? <> <div className={styles.head} >Professional Summary</div>
                <div className={styles.description} dangerouslySetInnerHTML={{ __html: profile.description || '' }}></div></>
                : null}
            </div>

            case "employment": return isNotEmpty(profile?.userWorkProfileDetail?.experience) ? <div className={styles.employeeInfo} >
              <div className={styles.head} >Employment History</div>
              {
                profile?.userWorkProfileDetail?.experience?.map((exp) => {
                  return <div className={styles.exp} >
                    <div className={styles.flexRow} >
                      <div className={styles.title} >{exp.awarded_by}</div>
                      <div className={styles.titleBy} >{exp.title}</div>
                    </div>
                    <div className={styles.flexRow} >
                      <div className={styles.SubTitle} >
                        <div className={styles.row} >
                          <div className={styles.flex} >{exp.from_date} - {exp?.is_present === "1" ? "Present" : exp?.to_date}</div>
                        </div>
                      </div>
                    </div>
                    <div className={`${styles.flex} ${styles.description}`} >
                      <p dangerouslySetInnerHTML={{ __html: exp?.description || '' }}></p>
                    </div>
                  </div>
                })
              }

            </div> : null

            case "education": return isNotEmpty(profile?.userWorkProfileDetail?.degree) ? <div className={styles.education} >
              <div className={styles.head}>Education</div>
              {
                profile?.userWorkProfileDetail?.degree?.map((edu) => {
                  return <div>
                    <div className={styles.flexRow} >
                      <div className={styles.title} >{edu.awarded_by}</div>
                      <div className={styles.title} >{edu.title}</div>
                    </div>
                    <div className={styles.flexRow} >
                      <div className={styles.SubTitle} >
                        <div className={styles.row} >
                          <div className={styles.flex} >{edu.from_date} - {edu?.is_present === "1" ? "Present" : edu?.to_date}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                })
              }
            </div> : null

            case "courses": return <> {customSections.courses && isNotEmpty(profile?.userWorkProfileDetail?.course) ? <div className={styles.education} >
              <div className={styles.head}>Courses</div>
              {
                profile?.userWorkProfileDetail?.course?.map((edu) => {
                  return <div>
                    <div className={styles.flexRow} >
                      <div className={styles.title} >{edu.awarded_by}</div>
                      <div className={styles.title} >{edu.title}</div>
                    </div>
                    <div className={styles.flexRow} >
                      <div className={styles.SubTitle} >
                        <div className={styles.row} >
                          <div className={styles.flex} >{edu.from_date} - {edu?.is_present === "1" ? "Present" : edu?.to_date}</div>
                        </div>
                      </div>
                    </div>
                    <div className={`${styles.flex} ${styles.description}`} >
                      <p dangerouslySetInnerHTML={{ __html: edu?.description || '' }}></p>
                    </div>
                  </div>
                })
              }
            </div> : null} </>

            case "internships": return <> {customSections.internships && isNotEmpty(profile?.userWorkProfileDetail?.internship) ? <div className={styles.education} >
              <div className={styles.head}>Internships</div>
              {
                profile?.userWorkProfileDetail?.internship?.map((edu) => {
                  return <div>
                    <div className={styles.flexRow} >
                      <div className={styles.title} >{edu.awarded_by}</div>
                      <div className={styles.title} >{edu.title}</div>
                    </div>
                    <div className={styles.flexRow} >
                      <div className={styles.SubTitle} >
                        <div className={styles.row} >
                          <div className={styles.flex} >{edu.from_date} - {edu?.is_present === "1" ? "Present" : edu?.to_date}</div>
                        </div>
                      </div>
                    </div>
                    <div className={`${styles.flex} ${styles.description}`} >
                      <p dangerouslySetInnerHTML={{ __html: edu?.description || '' }}></p>
                    </div>
                  </div>
                })
              }
            </div> : null} </>
          }
        })
        }
      </div>
    </div>
  )
}

export default ThirdTemplate;