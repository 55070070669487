import React, { useEffect, useState, useRef } from 'react';

import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import * as ApiHelper from '@/services/apiHelper';

import * as storageConstants from '@/constants/storageConstants';
import * as commonServices from '@/services/common';
import * as toast from "@/wrapper/toast";
import { analyticsConstant } from '../../constants/analyticsConstant';
import { trackGIG } from '../../helpers/analytics';
import GigDetailsLayout from './GigDetailsLayout';
import { isNullOrEmpty } from '../../utils/stringUtils';
import { viewSimilarCandidates } from '../../services/common';
import { isCompanyAdmin } from '../../utils/authUtil';

function GigDetail() {

    let [loading, setLoading] = useState(false);
    let [color] = useState("#000000");

    let params = useParams();
    const id = params.id;
    const navigate = useNavigate();
    const currentUser = commonServices.getLocalData(storageConstants.PROFILE);
    const currentUserRole = commonServices.getLocalData(storageConstants.USER_ROLE);

    const [gigDetails, setGigDetails] = useState();
    const [showModalShare, setShowModalShare] = useState(false);
    const [showModalApply, setShowModalApply] = useState(false);
    const [showModalReport, setShowModalReport] = useState(false);
    const [showModalClose, setShowModalClose] = useState(false);
    const [keyword, setKeyword] = useState(null);

    const [selectedCriterias, setSelectedCriterias] = useState([]);
    const [showSimilarCandidatesModal, setShowSimilarCandidatesModal] = useState(null);

    const isSimilarCriteriaChecked = (type) => {
        return selectedCriterias.includes(type);
    };

    const showSimilarSearch = () => {
        if (selectedCriterias.length < 1) {
            toast.warn("Please select at least one criteria");
            return;
        }

        const data = showSimilarCandidatesModal;
        let newData = {};

        newData.skills = data?.skills?.length > 0 ? data?.skills : [];
        newData.location_id = data?.location_id;
        newData.full_name = data?.first_name + ' ' + data?.last_name;
        newData.id = data?.user_id;

        viewSimilarCandidates(newData, setLoading, isSimilarCriteriaChecked);
    }

    let [searchParams] = useSearchParams();

    useEffect(() => {
        let q = searchParams.get("q");
        setKeyword(q);
    }, [searchParams])

    const [masterData, setMasterData] = useState();

    useEffect(() => {
        const masterData = commonServices.getLocalData(storageConstants.DATA);
        setMasterData(masterData);
    }, [])

    const disableFeatureGigButton = () => {
        return gigDetails.user_remaining_amount < featureGigDuration * masterData?.system_config?.searchpage_feature_gig_slot_price?.value;
    }

    const gigDetail = async () => {
        setLoading(true);
        await ApiHelper.getGigDetail(id).then((response) => {
            if (response.isSuccess === true) {
                setGigDetails(response.data);
                trackGIG(analyticsConstant.VIEW, id, response.data.title)
            }
            else if (response.code === 400) {
                toast.warn(response.message);
                navigate('/access-denied');
            }
            else {
                toast.warn(response.message);
            }
            setLoading(false);
        });
    }

    useEffect(() => {
        gigDetail();
    }, [])

    const closeGig = (status) => {
        const formData = new FormData();
        formData.append('id', gigDetails.eid);
        formData.append('status', status);
        setLoading(true);
        ApiHelper.updateStatusGig(formData).then((response) => {
            setLoading(false);
            if (response.isSuccess === true) {
                toast.success(response.message);
            }
            else {
                toast.warn(response.message);
            }
            gigDetail();
        });
    }

    const changeApplicantStatus = (candidate_id, status) => {
        const formData = new FormData();
        formData.append('gig_id', gigDetails.eid);
        formData.append('candidate_id', candidate_id);
        formData.append('recruiter_action', status);
        setLoading(true);
        ApiHelper.changeGigApplicantStatus(formData).then((response) => {
            setLoading(false);
            if (response.isSuccess === true) {
                toast.success(response.message);
            }
            else {
                toast.warn(response.message);
            }
            gigDetail();
        });
    }

    const saveGig = () => {
        const formData = new FormData();
        var is_saved = 1 - gigDetails.is_saved;
        formData.append('gig_id', gigDetails.eid);
        formData.append('saved', is_saved);
        setLoading(true);
        ApiHelper.applyGig(formData).then((response) => {
            setLoading(false);
            if (response.isSuccess === true) {
                toast.success(response.message);
            }
            else {
                toast.warn(response.message);
            }
            gigDetail();

        });
    }

    const reNewGig = () => {
        const formData = new FormData();
        formData.append('id', gigDetails.eid);
        setLoading(true);
        ApiHelper.reNewGig(formData).then((response) => {
            setLoading(false);
            if (response.isSuccess === true) {
                toast.success(response.message);
            }
            else {

                toast.warn(response.message);
            }
            gigDetail();

        });
    }

    const duplicateGig = () => {
        const formData = new FormData();
        formData.append('gig_id', gigDetails.eid);
        setLoading(true);
        ApiHelper.duplicateGig(formData).then((response) => {
            setLoading(false);
            if (response.isSuccess === true) {
                const draftGigId = response?.data?.eid;
                toast.success(response.message);
                isNullOrEmpty(draftGigId) ? navigate("/posted-gigs?tab=draft") : navigate("/gig/edit/" + draftGigId);
            }
            else {
                toast.warn(response.message);
            }

        });
    }

    const openShareModal = () => {
        setShowModalShare(prev => !prev);
    };

    const openApplyModal = () => {
        setShowModalApply(prev => !prev);
    };

    const openReportModal = () => {
        setShowModalReport(prev => !prev);
    };

    const showReportedMessage = () => {
        toast.warn('You have already reported this job!');
    };

    const [showFeatureGigModal, setShowFeatureGigModal] = useState(false);
    const [featureGigDuration, setFeatureGigDuration] = useState();

    const [buttonLoading, setButtonLoading] = useState(false);

    const submitFeatureGig = (gig_id, duration) => {
        if (isNullOrEmpty(duration)) {
            toast.warn("Please Select a Valid Duration");
            return;
        } else if (isNullOrEmpty(gig_id)) {
            toast.warn("Invalid Gig ID");
            return;
        } else {
            setButtonLoading(true);
            const data = { gig_id, duration: parseInt(duration) };

            ApiHelper.featureGig(data).then(response => {
                if (response.isSuccess === true) {
                    toast.success(response.message);
                    setButtonLoading(false);
                    setShowFeatureGigModal(false);
                    gigDetail();
                } else {
                    setButtonLoading(false);
                    toast.warn(response.message);
                }
            })
        }
    }

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        if (queryParams.get('action') === 'buy') {
            buyMoreCash();
        }
    }, []);

    const buyMoreCash = () => {
        if (isCompanyAdmin()) {
            navigate('/company/users', {
                state: {
                    showPaymentGateay: true
                }
            });
        } else {
            toast.warn("Ask your Company Admin for more TT Cash");
        }
    }

    return (
        <GigDetailsLayout
            loading={loading}
            setLoading={setLoading}
            color={color}
            keyword={keyword}
            commonServices={commonServices}
            changeApplicantStatus={changeApplicantStatus}
            duplicateGig={duplicateGig}
            showModalApply={showModalApply}
            setShowModalApply={setShowModalApply}
            gigDetail={gigDetail}
            showModalReport={showModalReport}
            setShowModalReport={setShowModalReport}
            showModalClose={showModalClose}
            setShowModalClose={setShowModalClose}
            showModalShare={showModalShare}
            setShowModalShare={setShowModalShare}
            navigate={navigate}
            gigDetails={gigDetails}
            currentUser={currentUser}
            openReportModal={openReportModal}
            saveGig={saveGig}
            openApplyModal={openApplyModal}
            reNewGig={reNewGig}
            openShareModal={openShareModal}
            closeGig={closeGig}
            showFeatureGigModal={showFeatureGigModal}
            setShowFeatureGigModal={setShowFeatureGigModal}
            featureGigDuration={featureGigDuration}
            setFeatureGigDuration={setFeatureGigDuration}
            buttonLoading={buttonLoading}
            submitFeatureGig={submitFeatureGig}
            masterData={masterData}
            disableFeatureGigButton={disableFeatureGigButton}
            selectedCriterias={selectedCriterias}
            setSelectedCriterias={setSelectedCriterias}
            showSimilarCandidatesModal={showSimilarCandidatesModal}
            setShowSimilarCandidatesModal={setShowSimilarCandidatesModal}
            isSimilarCriteriaChecked={isSimilarCriteriaChecked}
            showSimilarSearch={showSimilarSearch}
        />
    )
}

export default GigDetail;