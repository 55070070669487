import React, { useState, useRef } from "react";
import "./input.scss";
import { isUndefined } from "../../../utils/validation";
import PhoneInput from "react-phone-number-input";
import IconResource from "../IconResource/IconResource";
import { Form, InputGroup } from "react-bootstrap";
const Input = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  let requiredClass =
    isUndefined(props?.isRequired) || !props?.isRequired ? "" : "required";
  let label =
    isUndefined(props?.showLabel) || props?.showLabel
      ? isUndefined(props?.label)
        ? props?.placeholder
        : props?.label
      : "";
  const updatedProps = {
    ...props,
    type:
      props?.type === "password"
        ? showPassword
          ? "text"
          : "password"
        : props?.type,
    className: isUndefined(props?.className)
      ? "form-control"
      : props?.className + " form-control",
    autoComplete: "off",
  };
  const renderTextBox = () => {
    switch (props?.type) {
      case "iconTextBox":
        return (
          <>
            <div className="form-input-group">
              <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon1">
                  <IconResource
                    type={props?.icon}
                    size={props?.iconSize}
                    color={props?.iconColor}
                  />
                </span>
              </div>
              <input {...updatedProps} />
            </div>
          </>
        );
      case "iconLabelTextBox":
        return (
          <>
            <div className="form-input-group">
              <label className={requiredClass} htmlFor={props?.id}>
                {label}
              </label>
              <div className="input-group mb-1">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">
                    <IconResource
                      type={props?.icon}
                      size={props?.iconSize}
                      color={props?.iconColor}
                    />
                  </span>
                </div>
                <input {...updatedProps} />
              </div>
            </div>
          </>
        );
      case "phone":
        return (
          <>
            <div className="form-input-group">
              <label className={requiredClass} htmlFor={props?.id}>
                {label}
              </label>
              <PhoneInput
                limitMaxLength={15}
                rules={{ required: true }}
                className="input-group"
                {...props}
              />
            </div>
          </>
        );
      case "textarea":
        return (
          <>
            <div className="form-input-group w-100">
              <label className={requiredClass} htmlFor={props?.id}>
                {label}
              </label>
              <textarea {...updatedProps} />
            </div>
          </>
        );
      case "submit":
        return (
          <input
            className={props?.className}
            type="submit"
            onClick={props?.onClick}
            value={props?.text}
          />
        );
      case "password":
        return (
          <>
            <div className="form-input-group">
              <label className={requiredClass} htmlFor={props?.id}>
                {label}
              </label>
              <div className="passbtn">
                <input
                  className="form-control"
                  placeholder="Enter password"
                  onChange={props?.handleChange}
                  {...updatedProps}
                />
                <button
                  type="button"
                  className="show-password-btn"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? (
                    <IconResource
                      type={"eye"}
                      fontSize={"18px"}
                      color="#ced4da"
                    />
                  ) : (
                    <IconResource
                      type={"eyeClose"}
                      fontSize={"18px"}
                      color="#ced4da"
                    />
                  )}
                </button>
              </div>
            </div>
          </>
        );
      default:
        return (
          <>
            <div className="form-input-group w-100">
              <label className={requiredClass} htmlFor={props?.id}>
                {label}
              </label>
              <input {...updatedProps} />
            </div>
          </>
        );
    }
  };
  return <>{renderTextBox()}</>;
};

export default Input;
