
import ReactStars from "react-rating-stars-component";

export const RatingWrapper = ({ onChange, edit, count, size, value }) => {


    const handleRating = (newValue) => {
        onChange(newValue);
    };

    return <ReactStars
        count={count || 5}
        size={size || 24}
        activeColor="#ffd700"
        value={value || 0}
        onChange={handleRating}
        edit={edit}
    />

}