export function isNotEmpty(arr) {
    return (arr !== undefined && arr !== null && arr.length > 0);
}

export function makeRepeated(arr, repeats) {
    return Array.from({ length: repeats }, () => arr).flat();
}

export function shuffleArray(array) {
    for (var i = array.length - 1; i > 0; i--) {

        // Generate random number
        var j = Math.floor(Math.random() * (i + 1));

        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }

    return array;
}

export function locations(data) {
    return data !== null && data !== undefined && data.length > 0
        ? data?.map(location => location.name).toString().split(',').join(', ')
        : "N/A"
}

export function convertArrayElementsToInteger(array) {
    array = array.map(function (x) {
        return parseInt(x, 10);
    });
    return array;
}

export function removeDuplicates(arr) {
    const set = new Set();

    const stringify = obj => {
        if (typeof obj === 'object' && obj !== null) {
            const keys = Object.keys(obj);
            keys.sort();
            const str = keys.map(key => `${key}:${obj[key]}`).join(',');
            return `{${str}}`;
        }
        return obj.toString();
    };

    const uniqueArray = [];

    arr.forEach(item => {
        const str = stringify(item);
        if (!set.has(str)) {
            set.add(str);
            uniqueArray.push(item);
        }
    });

    return uniqueArray;
}

export function sortArrayByName(array) {
    return array.sort((a, b) => a.name.localeCompare(b.name))
}

export function sortArrayByPercentage(array) {
    return array.sort((a, b) => a.profile_percentage < b.profile_percentage)
}

export const transformArrayFromObject = (obj) => {
    const transformedArray = [];

    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            obj[key].length > 0 && obj[key].forEach(item => {
                transformedArray.push({ [key]: item });
            });
        }
    }

    return transformedArray;
}
