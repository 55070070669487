import { getUserId } from "./authUtil";
import { EMAIL_REGEX, PHONE_NUMBER_REGEX } from "../constants/regex";

export function isMyJob(jobDetails) {
    return getUserId() === parseInt(jobDetails.user_id);
}

export function isOpenJob(jobDetails) {
    return jobDetails.status === 'published';
}

export function isClosedJob(jobDetails) {
    return jobDetails.status === 'closed';
}

export function isDraftJob(jobDetails) {
    return jobDetails.status === 'draft';
}

export function isFeaturedJob(jobDetails) {
    return jobDetails.is_featured;
}

export function isAppliedJob(jobDetails) {
    return jobDetails.is_applied === 1;
}

export function isSavedJob(jobDetails) {
    return jobDetails.is_saved === 1;
}

export function isReportedJob(jobDetails) {
    return jobDetails.is_reported === 1;
}

export function maskEmail(description) {
    if (!description) return;
    return description.replace(EMAIL_REGEX, function (desc) {
        return desc.replace(/([^@\.])/g, "*"); /* eslint-disable-line */
    });
}

export function maskPhone(description) {
    if (!description) return;

    return description.replace(PHONE_NUMBER_REGEX, function (desc) {
        return desc.replace(/\d/g, "*");
    });
}

export function maskEmailPhone(description) {
    if (!description) return;

    var emailMaskedDesc = maskEmail(description);
    return maskPhone(emailMaskedDesc);
}