/* eslint-disable */
import React, { useState, useEffect } from "react";
import "./Modal.scss";
import * as commonServices from "@/services/common";
import * as ApiHelper from "@/services/apiHelper";
import * as errors from "@/constants/stringConstants";
import fireworkimage from "@/Assets/images/firework.png";
import { COUNTRY } from "@/constants/storageConstants";
import PhoneInput, {
  isValidPhoneNumber,
  parsePhoneNumber,
} from "react-phone-number-input";
import deleteImg from "@/Assets/svgs/trash.svg";
import plusImg from "@/Assets/svgs/plus_circle.svg";
import * as toast from "@/wrapper/toast";
import AppModal from "./AppModal";
import Input from "../_widgets/Input/Input";

function ModalInviteReferralUser({
  showModal,
  setShowModal,
  id,
  url,
  getReferrals,
  audience,
}) {
  const [referrals, setReferrals] = useState([{}]);
  let [loading, setLoading] = useState(false);
  let [success, setSuccess] = useState(false);
  // let [color] = useState("#000000");

  const onChange = (text, key, index) => {
    var tempArray = [...referrals];
    tempArray[key][index] = text;
    setReferrals(tempArray);
  };

  const validateData = () => {
    let is_success = true;
    referrals &&
      referrals.map((element, key) => {
        var currentRow = key + 1;

        if (
          referrals[key].first_name === undefined ||
          referrals[key].first_name.trim() === ""
        ) {
          toast.warn(errors.FIRST_NAME_REQUIRED + " at row " + currentRow);
          is_success = false;
        } else if (
          referrals[key].last_name === undefined ||
          referrals[key].last_name.trim() === ""
        ) {
          toast.warn(errors.LAST_NAME_REQUIRED + " at row " + currentRow);
          is_success = false;
        } else if (
          referrals[key].email === undefined ||
          referrals[key].email.trim() === ""
        ) {
          toast.warn(errors.EMAIL_REQUIRED + " at row " + currentRow);
          is_success = false;
        } else if (
          !commonServices.checkEmailValid(referrals[key].email.trim())
        ) {
          toast.warn(errors.INCORRECT_EMAIL);
          is_success = false;
        } else if (
          referrals[key].phone_number !== undefined &&
          !isValidPhoneNumber(referrals[key].phone_number)
        ) {
          toast.warn(errors.PHONE_NUMBER_INCORRECT + " at row " + currentRow);
          is_success = false;
        }
      });

    return is_success;
  };

  const sendInvitation = () => {
    if (validateData()) {
      let form = new FormData();
      form.append("id", id);
      form.append("url", url);
      form.append("referrals", JSON.stringify(referrals));
      setLoading(true);
      ApiHelper.sendInvitation(form).then((response) => {
        setLoading(false);
        if (response.isSuccess === true) {
          getReferrals();
          setSuccess(!success);
        } else {
          toast.warn(response.message);
        }
      });
    }
  };

  const deleteRow = async (e, key) => {
    e.preventDefault();
    var tempProfileArr = [...referrals];
    tempProfileArr.splice(key, 1);
    setReferrals(tempProfileArr);
  };

  useEffect(() => {
    setReferrals([{}]);
  }, [showModal, success]);

  const normalizePhoneNumber1 = (input, defaultCountry = null) => {
    const parsedPhone = parsePhoneNumber(input, defaultCountry);
    if (parsedPhone) {
      return parsedPhone.number;
    } else {
      return input;
    }
  };

  return (
    <>
      <AppModal
        title={success ? "Refer a friend" : "Invite " + audience}
        isOpen={showModal}
        closeModal={() => setShowModal((prev) => !prev)}
        submit={(e) => sendInvitation()}
        size={"xl"}
        isFooter={success ? false : true}
        loading={loading}
      >
        <div className="">
          <div className="modal_body">
            {success ? (
              <div className="row refer-success my-5">
                <img src={fireworkimage} alt="success" />
                <p className="my-3">Referral sent successfully</p>
                <span className="mb-3">
                  We have just sent the referral invite Successfully!
                </span>
                <div className="col-2"></div>
                <div className="col-8">
                  <div className="form_buttons">
                    <button
                      className="btn btn-primary"
                      style={{ marginRight: "10px", outline: "0px" }}
                      onClick={() => {
                        setSuccess(!success);
                        setShowModal((prev) => !prev);
                      }}
                    >
                      Exit
                    </button>

                    <input
                      type="submit"
                      value="Refer more friends"
                      onClick={() => setSuccess(!success)}
                    />
                  </div>
                </div>
                <div className="col-2"></div>
              </div>
            ) : (
              <>
                <div className="modal_data_rows">
                  {referrals &&
                    referrals?.map((element, key) => {
                      return (
                        <>
                          <div className="row">
                            <div className="col">
                              <div className="position-relative">
                                <label
                                  className="input-lebel2 required"
                                  htmlFor=""
                                >
                                  First Name
                                </label>
                                <div className="input-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="First Name"
                                    id="first_name"
                                    value={referrals[key].first_name}
                                    name="first_name"
                                    onChange={(e) => {
                                      onChange(
                                        e.target.value,
                                        key,
                                        e.target.name
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col">
                              <div className="position-relative">
                                <label
                                  className="input-lebel2 required"
                                  htmlFor=""
                                >
                                  Last Name
                                </label>
                                <div className="input-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Last Name"
                                    id="last_name"
                                    value={referrals[key].last_name}
                                    name="last_name"
                                    onChange={(e) => {
                                      onChange(
                                        e.target.value,
                                        key,
                                        e.target.name
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col">
                              <div className="position-relative">
                                <label
                                  className="input-lebel2 required"
                                  htmlFor=""
                                >
                                  Email id
                                </label>
                                <div className="input-group">
                                  <input
                                    type="email"
                                    className="form-control"
                                    placeholder="Email id"
                                    id="email"
                                    value={referrals[key].email}
                                    name="email"
                                    onChange={(e) => {
                                      onChange(
                                        e.target.value,
                                        key,
                                        e.target.name
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col">
                              <Input
                                type="phone"
                                label="Phone Number"
                                placeholder="Enter Phone Number"
                                id="contact_number"
                                name="contact_number"
                                defaultCountry={COUNTRY}
                                value={
                                  referrals[key]?.phone_number
                                    ? normalizePhoneNumber1(
                                        referrals[key]?.phone_number
                                      )
                                    : ""
                                }
                                style={{ backgroundColor: "white" }}
                                onChange={(phoneNumber) => {
                                  var tempArray = [...referrals];
                                  tempArray[key].phone_number = phoneNumber;
                                  setReferrals(tempArray);
                                }}
                              />
                            </div>
                          </div>

                          <div className="row mb-4">
                            <div className="col-md-3 col-6">
                              <a
                                className="add-more"
                                onClick={() => {
                                  console.log(referrals.length);
                                  console.log(referrals);
                                  setReferrals([...referrals, {}]);
                                }}
                              >
                                {" "}
                                <img src={plusImg}></img> Add More
                              </a>
                            </div>

                            {referrals.length !== 1 &&
                            key === referrals.length - 1 ? (
                              <div className="col-md-2 col-6">
                                <a
                                  className="delete-row"
                                  onClick={(e) => {
                                    deleteRow(e, key);
                                  }}
                                >
                                  {" "}
                                  <img className="" src={deleteImg}></img>{" "}
                                  Remove
                                </a>
                              </div>
                            ) : (
                              ""
                            )}

                            <div className="col"></div>
                            <div className="col"></div>
                            <div className="col"></div>
                            <div className="col"></div>
                            <div className="col"></div>
                            <div className="col"></div>
                          </div>
                        </>
                      );
                    })}
                </div>
              </>
            )}
          </div>
        </div>
      </AppModal>
    </>
  );
}

export default ModalInviteReferralUser;
