import React, { useState, useEffect } from 'react';
import './FooterUpper.scss';
import { useNavigate } from "react-router-dom";
import logo from "@/Assets/images/logoWhite.png";
import * as commonServices from '@/services/common';
import * as storageConstants from '@/constants/storageConstants';
import { createSlugFromString, removeTrailingSlashes, newLineText } from '@/utils/stringUtils';
import { checkRecordExists } from '@/services/common';

function FooterHome() {
    const navigate = useNavigate();
    var masterData = commonServices.getLocalData(storageConstants.DATA);
    const [data, setData] = useState((masterData !== null && masterData.footer_content !== undefined && masterData.footer_content !== null) ? masterData.footer_content : {});
    const [contactDetails, setContactDetails] = useState((masterData !== null && masterData.system_config !== null && masterData.system_config !== undefined) ? masterData.system_config : {});
    return (
        <>
            <div className="col-lg-4 col-md-4 col-sm-12 ps-0 home-footer main">
                <img style={{ marginRight: "auto", width: "135px" }} onError={commonServices.imgError} src={logo ?? 'N/A'} alt="logo" />
                <p className='mt-3'>{checkRecordExists(contactDetails?.contact_text?.value) ? newLineText(contactDetails?.contact_text?.value) : 'Truetalent - India’s first and only Recruitment as a Service (RaaS) employment platform.'}</p>
                <p className='mt-3 heading'>Contact</p>

                <p className='mt-3'> <a target="_blank" href={`http://maps.google.com/?q=${contactDetails?.company_address?.value}`}>{checkRecordExists(contactDetails?.company_address?.value) ? contactDetails?.company_address?.value : '#88 Borewell Road, Whitefield, Bangalore, 560066, Karnataka'}</a></p>

                <p className='mt-3'><a href={"tel:" + (checkRecordExists(contactDetails?.company_phone?.value) ? contactDetails?.company_phone?.value : '+91 90906 90936')}>{checkRecordExists(contactDetails?.company_phone?.value) ? contactDetails?.company_phone?.value : '+91 90906 90936'}</a></p>

                <p className='mt-3'><a href={"mailto:" + (checkRecordExists(contactDetails?.company_email?.value) ? contactDetails?.company_email?.value : 'maya@truetalent.io')}>{checkRecordExists(contactDetails?.company_email?.value) ? contactDetails?.company_email?.value : 'maya@truetalent.io'}</a></p>
            </div>

            <div className="col-lg-2 col-md-2 col-sm-6 ps-0 home-footer jobs">
                <p className='heading'>Jobs By Skills</p>
                <ul class="list-group footer-list">
                    {data.skills !== undefined && data.skills.length > 0 ?
                        data.skills.map((skill, i) => {
                            return <li class="list-group-item hand-hover" key={"skill_" + i} onClick={() => { navigate('job/search/' + createSlugFromString(skill?.text) + '?skills=' + skill?.value) }}>{skill?.text.trim()}</li>
                        })
                        :
                        <>
                            <li class="list-group-item hand-hover" onClick={() => { navigate('job/search/sap?skills=64569') }}>SAP</li>
                            <li class="list-group-item hand-hover" onClick={() => { navigate('job/search/java?skills=64440') }}>Java</li>
                            <li class="list-group-item hand-hover" onClick={() => { navigate('job/search/react?skills=64649') }}>React</li>
                            <li class="list-group-item hand-hover" onClick={() => { navigate('job/search/full-stack?skills=72414') }}>Full Stack</li>
                            <li class="list-group-item hand-hover" onClick={() => { navigate('job/search/sales?skills=72539') }}>Sales</li>
                            <li class="list-group-item hand-hover" onClick={() => { navigate('job/search/hr?skills=66810') }}>HR</li>
                        </>
                    }

                </ul>
            </div>

            <div className="col-lg-2 col-md-2 col-sm-6 ps-0 home-footer jobs">
                <p className='heading'>Jobs By Locations</p>
                <ul class="list-group footer-list">
                    {data.location !== undefined && data.location.length > 0 ?
                        data.location.map((location, i) => {
                            return <li class="list-group-item hand-hover" key={"location_" + i} onClick={() => { navigate('job/search/' + createSlugFromString(location?.text) + '?locations=' + location?.value) }}>{location?.text.trim()}</li>
                        })
                        :
                        <>
                            <li class="list-group-item hand-hover" onClick={() => { navigate('job/search/bangalore?locations=63826') }}>Bangalore</li>
                            <li class="list-group-item hand-hover" onClick={() => { navigate('job/search/chennai?locations=63866') }}>Chennai</li>
                            <li class="list-group-item hand-hover" onClick={() => { navigate('job/search/hyderabad?locations=63921') }}>Hyderabad</li>
                            <li class="list-group-item hand-hover" onClick={() => { navigate('job/search/new-delhi?locations=64014') }}>New Delhi</li>
                            <li class="list-group-item hand-hover" onClick={() => { navigate('job/search/mumbai?locations=63997') }}>Mumbai</li>
                            <li class="list-group-item hand-hover" onClick={() => { navigate('job/search/pune?locations=64036') }}>Pune</li>
                        </>
                    }
                </ul>
            </div>

            <div className="col-lg-2 col-md-2 col-sm-6 ps-0 home-footer jobs">
                <p className='heading'>Jobs By Industries</p>
                <ul class="list-group footer-list">
                    {data.industry_domains !== undefined && data.industry_domains.length > 0 ?
                        data.industry_domains.map((domain, i) => {
                            return <li class="list-group-item hand-hover" key={"domain_" + i} onClick={() => { navigate('job/search/' + createSlugFromString(domain?.text) + '?domains=' + domain?.value) }}>{domain?.text.trim()}</li>
                        })
                        :
                        <>
                            <li class="list-group-item hand-hover" onClick={() => { navigate('job/search/technology?domains=25') }}>Technology</li>
                            <li class="list-group-item hand-hover" onClick={() => { navigate('job/search/engineering?domains=18') }}>Engineering</li>
                            <li class="list-group-item hand-hover" onClick={() => { navigate('job/search/hospitality?domains=22') }}>Hospitality</li>
                            <li class="list-group-item hand-hover" onClick={() => { navigate('job/search/retail?domains=26') }}>Retail</li>
                            <li class="list-group-item hand-hover" onClick={() => { navigate('job/search/healthcare?domains=23') }}>Healthcare</li>
                            <li class="list-group-item hand-hover" onClick={() => { navigate('job/search/banking?domains=14') }}>Banking</li>
                        </>
                    }
                </ul>
            </div>

            <div className="col-lg-2 col-md-2 col-sm-6 ps-0 home-footer about">
                <p className='heading'>Navigate</p>
                <ul class="list-group footer-list">
                    {data.nav !== undefined && data.nav.length > 0 ?
                        data.nav.map((link, i) => {
                            return <li className="list-group-item hand-hover" key={"link_" + i}><a href={undefined} onClick={() => { (link?.text === 'blog' || link?.text === 'Blog') ? window.open("/" + removeTrailingSlashes(link?.value), '_blank') : navigate("/" + removeTrailingSlashes(link?.value)); }} className="nav-link p-0 hand-hover">{link?.text.trim()}</a></li>
                        })
                        :
                        <>
                            <li className="list-group-item hand-hover"><a href={undefined} onClick={() => { navigate('/'); }} className="nav-link p-0 hand-hover">Home</a></li>
                            <li className="list-group-item hand-hover"><a href={undefined} onClick={() => { navigate('/about'); }} className="nav-link p-0 hand-hover">About Us</a></li>
                            <li className="list-group-item hand-hover"><a href={undefined} onClick={() => { navigate('/contact'); }} className="nav-link p-0 hand-hover">Contact Us</a></li>
                            {/* <li className="list-group-item hand-hover"><a href={undefined} onClick={() => { navigate('/giveaway'); }} className="nav-link p-0 hand-hover">Giveaway</a></li> */}
                            <li className="list-group-item hand-hover"><a target='_blank' href={undefined} onClick={() => { window.open("/blog", '_blank'); }} className="nav-link p-0 hand-hover">Blog</a></li>
                            <li className="list-group-item hand-hover"><a href={undefined} onClick={() => { navigate('/terms'); }} className="nav-link p-0 hand-hover">Terms of Use & Privacy Policy</a></li>
                        </>
                    }
                </ul>
            </div>
        </>

    )
}
export default FooterHome