import React, { useState, useEffect } from 'react'
import './Common.scss'
import notFound from '@/Assets/images/404.png?1';
import CommonLayout from "./Common";

function PageNotFound() {
    return (
        <CommonLayout image={notFound} title="Page Not Found!" />
    )
}

export default PageNotFound