import Button from "../_widgets/Button/Button";
import Input from "../_widgets/Input/Input";
import Text from "../_widgets/Text/Text";
import AppModal from "./AppModal";

const ModalEmailVerification = ({
  profile,
  showEmailVerificationModal,
  emailbuttonLoading,
  emailOtpSent,
  emailOtpVerified,
  verifyEmailOtp,
  doneVerifyingEmail,
  setShowEmailVerificationModal,
  emailOtp,
  setEmailOtp,
  onChange,
  resendEmailOtp,
  otpResendLoading,
  title,
}) => {
  return (
    <AppModal
      title={title}
      isOpen={showEmailVerificationModal}
      isFooter={true}
      loading={emailbuttonLoading}
      submitText={`${
        emailOtpSent && !emailOtpVerified ? "Verify OTP" : "Verify Email"
      }`}
      submit={verifyEmailOtp}
      closeModal={() => setShowEmailVerificationModal(false)}
      disableSubmit={doneVerifyingEmail || otpResendLoading}
    >
      <div className="row mb-3">
        <div className="col-12 col-md-6 pe-lg-0 pe-md-0 mb-3 mb-lg-0 mb-md-0">
          <Input
            type="password"
            placeholder="Password"
            value={profile.password ? profile.password : ""}
            name="password"
            onChange={(e) => onChange(e)}
            isRequired={true}
          />
        </div>
        <div className="col-12 col-md-6">
          <Input
            type="password"
            placeholder="Confirm Password"
            value={profile.confirm_password ? profile.confirm_password : ""}
            name="confirm_password"
            onChange={(e) => onChange(e)}
            isRequired={true}
          />
        </div>
      </div>

      <div className="col-12 col-md-6">
        <Input
          type="number"
          inputmode="numeric"
          name="emailOTP"
          isRequired={true}
          placeholder="Enter OTP"
          value={emailOtp}
          onChange={(e) => setEmailOtp(e.target.value)}
          disabled={emailOtpVerified}
        />
      </div>

      <div className="d-flex gap-1 mt-3">
        <Text
          type="span"
          style={{ fontSize: "var(--p1)" }}
          text="Didn't received OTP?"
        />
        <Button
          buttonType="text"
          text={otpResendLoading ? "Resending..." : "Resend"}
          disabled={otpResendLoading}
          onClick={() => resendEmailOtp()}
        />
      </div>
    </AppModal>
  );
};

export default ModalEmailVerification;
