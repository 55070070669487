import { useState, forwardRef, useImperativeHandle, useRef } from 'react'
import './Steps.scss'
import * as storageConstants from '../../../../constants/storageConstants';
import * as commonServices from '../../../../services/common';
import plusImg from '../../../../Assets/svgs/grayPlus.svg';
import plusCircleImg from '../../../../Assets/svgs/plus_circle.svg';
import trashImg from "../../../../Assets/svgs/trash.svg"
import { isCandidate, isEvaluator } from '../../../../utils/authUtil';
import * as toast from "../../../../wrapper/toast";
import Text from "../../../_widgets/Text/Text"
import { addSkill } from '../../../../services/apiHelper';
import * as errors from '@/constants/stringConstants';
import Upload from '../../../_widgets/Upload/Upload';
import Input from '../../../_widgets/Input/Input';
import { isNullOrEmpty } from '../../../../utils/stringUtils';
import { monthsWithoutName as months, yearsWithoutName as years } from '../../../../utils/dateUtil';

const AddSkill = forwardRef(({ skill, setSkill, selectedProfile, suggestions, addSkillAction, profile, setShowExperienceSuggestionModal, showCerticate = false }, ref) => {
    // Expose the isInvalidSkills function to the parent using useImperativeHandle
    const inputData = useRef(null)
    useImperativeHandle(ref, () => ({
        isInvalidSkills: () => {
            let isError = false;
            if (skill.length < 1) {
                isError = true;
            }
            skill && skill.map((elem, key) => {
                let currentRow = key + 1;
                let isSKillExist = false;

                isSKillExist = skill.some((el, i) => {
                    return el.skill_id === skill[key].skill_id && key !== i;
                });
                if (!isEvaluator() || selectedProfile !== "evaluator") {
                    if ((isNullOrEmpty(skill[key]?.certificate) && isNullOrEmpty(skill[key]?.skill_certificate)) && !isNullOrEmpty(skill[key].awarded_by)) {
                        console.log("certificate", skill[key])
                        isError = true;
                        toast.warn(errors.CERTIFICATE_REQUIRED + " at row " + currentRow)
                        return false;
                    } else if ((!isNullOrEmpty(skill[key]?.certificate) || !isNullOrEmpty(skill[key]?.skill_certificate)) && isNullOrEmpty(skill[key].awarded_by)) {
                        isError = true;
                        toast.warn(errors.CERTIFICATE_NAME_REQUIRED + " at row " + currentRow)
                        return false;
                    }
                }
                if (isSKillExist) {
                    isError = true;
                    toast.warn(errors.ADDED_SKILL_ERROR + " at row " + currentRow);
                    return false;
                } else if (isNullOrEmpty(skill[key].skill_name)) {
                    isError = true;
                    toast.warn(errors.EMPTY_SKILL_ERROR + " at row " + currentRow);
                    return false;
                } else if (!skill[key].skill_id) {
                    if (isNullOrEmpty(skill[key].skill_name)) {
                        isError = true;
                        toast.warn(errors.EMPTY_SKILL_ERROR + " at row " + currentRow);
                        return false;
                    } else if (skill[key].skill_name < storageConstants.SKILL_THRESHOLD) {
                        isError = true;
                        toast.warn('Please enter valid skill!' + " at row " + currentRow);
                        return false;
                    }
                } else if (isEvaluator() || selectedProfile === "evaluator") {
                    if (skill[key].experience < 96 || isNullOrEmpty(skill[key].experience)) {
                        isError = true;
                        toast.warn(errors.MINIMUM_SKILL_EVALUATOR_ERROR_MESSAGE);
                        return false;
                    } else if (skill[key].description === undefined || skill[key].description.trim() === '') {
                        isError = true;
                        toast.warn("Please indicate the total number of hours spent conducting " + skill[key].skill_name + " interviews.");
                        var callapseBtn = document.getElementById("skilButton" + key);

                        if (callapseBtn.getAttribute('aria-expanded') === 'false') {
                            callapseBtn.click();
                        }
                        document.getElementById("hours" + key).focus();
                        return false;
                    } else if (skill[key].description <= 50) {
                        isError = true;
                        toast.warn("Total number of hours spent conducting " + skill[key].skill_name + " interviews should be greater than 50 hours.");
                        return false;
                    }

                }
            });

            return isError;
        },
    }));
    const [activeRow, setActiveRow] = useState(0);
    const [suggestionClicked, setSuggestionClicked] = useState(false);
    const [skillSuggestions, setSkillSuggestions] = useState([]);

    const getParticularSkillsByName = (text) => {
        return commonServices.getSkillByName(text);
    }

    const isPreferredSkill = (element) => {
        return (element?.type !== undefined && element?.type === 'preffered_skill');
    }


    const addSKillCall = async (skill_name) => {
        const formData = new FormData();
        formData.append('name', skill_name);
        await addSkill(formData);
    }

    const skillExperienceHandler = (e, type, key) => {
        var tempArray = [...skill];
        if (type === 'year') {
            var months = e.target.value * 12;
            tempArray[key].experience = tempArray[key].experience ? tempArray[key].experience % 12 + months : months;
            setSkill(tempArray);
        } else if (type === 'month') {
            tempArray[key].experience = tempArray[key].experience ? (Math.trunc(tempArray[key].experience / 12)) * 12 + parseInt(e.target.value) : parseInt(e.target.value);
            setSkill(tempArray);
        }

        if (profile !== undefined && tempArray[key].experience > profile?.user_work_profile?.total_experience) {
            setShowExperienceSuggestionModal(true);
        }
    }

    const deleteRow = async (e, key) => {
        e.preventDefault();
        var tempProfileArr = [...skill];
        tempProfileArr.splice(key, 1);
        setSkill(tempProfileArr);
    }
    const preferredSkillHandler = (e, key) => {
        let isChecked = e.target.checked;
        var tempArray = [...skill];

        tempArray[key][e.target.name] = isChecked;
        if (isChecked) {
            tempArray[key]["type"] = "preffered_skill";
        } else {
            tempArray[key]["type"] = "skill";
        }
        setSkill(tempArray);
        setActiveRow(key);

    }

    const onChangeHandlerSkill = async (text, key) => {
        let tempArray = [...skill];
        if (text && text.length > storageConstants.SKILL_THRESHOLD) {
            const s = await getParticularSkillsByName(text);
            setSkillSuggestions(s);

            // if (skill.find((data, dataIndex) => data?.skill_name?.toLowerCase() === text?.toLowerCase() && data.skill_name?.length > 0 && key !== dataIndex)) {
            //     toast.warn('Skill already added');
            //     return;
            // }
        } else {
            setSkillSuggestions([]);
        }

        tempArray[key].skill_name = text;
        text.length < storageConstants.SKILL_THRESHOLD && (tempArray[key].skill_id = null)
        setSkill(tempArray);
        setActiveRow(key);
    }

    const addSuggestionSkill = (id, name, experience = 0) => {
        if (skill.find((data, dataIndex) => data.skill_id === id)) {
            toast.warn('Skill already added');
            return;
        }

        setSkill([...skill, {
            "skill_id": id,
            "skill_name": name,
            'isCollapse': false,
            'experience': experience
        }]);
    }

    const onChangeHandler = (e, key) => {
        var tempArray = [...skill];
        tempArray[key][e.target.name] = e.target.value;
        setSkill(tempArray);
        setActiveRow(key);
    }

    const onChangeFileHandler = (info, key) => {
        var tempArray = [...skill];
        if (info.target.files.length > 0) {
            tempArray[key]["certificate"] = info.target.files[0];
            tempArray[key]["certificate_name"] = info.target.files[0].name;
            setSkill(tempArray);
        }
    }
    const onDeleteFileHandler = (info, key) => {
        var tempArray = [...skill];
        tempArray[key].certificate = null;
        tempArray[key].skill_certificate = null;
        tempArray[key].description = null;
        tempArray[key].certificate_name = null;
        setSkill(tempArray);
    }

    const isSkillNotCollapsed = (key) => {
        return skill[key].isCollapse !== undefined && skill[key].isCollapse !== null && !skill[key].isCollapse;
    }

    const handleKeyPress = (event, key) => {
        if (event.key === 'Enter' || event.key === 'Tab') {
            if (skillSuggestions && skillSuggestions.length > 0) {
                if (skill.find((data, dataIndex) => data.skill_id === skillSuggestions[0]?.id && key !== dataIndex)) {
                    toast.warn('Skill already added');
                    return;
                } else {
                    document.getElementById('search_skills').value = '';
                    var tempArray = [...skill];
                    tempArray[key].skill_name = skillSuggestions[0]?.name;
                    tempArray[key].skill_id = skillSuggestions[0].id;
                    setSkill(tempArray);
                    setSkillSuggestions([]);
                }
            } else {
                event.target.value.length > storageConstants.SKILL_THRESHOLD && addSKillCall(event.target.value);
            }
        } else if (event.key === "Escape") {
            setSkillSuggestions([]);
        }
    }

    const isSkillExists = (skill) => {
        return (skill.skill_name !== undefined && skill.skill_name !== "" && skill.skill_id !== undefined && skill.skill_id !== "")
    }

    const handleBlurEvent = async (key) => {
        if (!suggestionClicked) {
            let tempArray = [...skill];

            const text = tempArray[key].skill_name;

            if (text?.length > storageConstants.SKILL_THRESHOLD) {
                const s = await getParticularSkillsByName(text);

                if (s && s.length > 0) {
                    tempArray[key].skill_id = s[0].id;
                    tempArray[key].skill_name = s[0].name;
                } else {
                    await addSKillCall(text);
                    const newSkills = await getParticularSkillsByName(text);
                    tempArray[key].skill_id = newSkills[0].id;
                    tempArray[key].skill_name = newSkills[0].name;
                }
            }

            setSkill(tempArray);
            setSkillSuggestions([]);
        }
        setSuggestionClicked(false);
    }


    return (
        <div className="row mb-3 add-skills" id='Skills'>
            <h2 style={{ fontSize: "var(--h6)" }} className='required mb-2'>
                {(isEvaluator() || (selectedProfile !== undefined && selectedProfile === 'evaluator'))
                    ? 'Areas of Expertise' : 'Skills'}
            </h2>

            {suggestions !== undefined && suggestions.length > 0 ?
                <>
                    <span>Please choose the skill you would like to be evaluated on.</span>
                    <p>Please choose a primary skill from the provided list. If you wish to add another skill, please click on "Edit Work Profile" and include the additional primary skill there.</p>
                </>
                :
                <p>Choose upto 5 skills that showcase your experience/expertise.</p>
            }
            <div className="d-flex mt-3">
                {suggestions !== undefined && suggestions.length > 0 ?
                    suggestions.map((suggestion, key) => {
                        return isSkillExists(suggestion) ? <div key={key} className="top-skill hand-hover" onClick={() => addSuggestionSkill(suggestion.skill_id, suggestion.skill_name, suggestion.experience)}>
                            {suggestion.skill_name}
                            <img src={plusImg} alt="plus" />
                        </div>
                            : null
                    })
                    : <>
                        <div className="top-skill hand-hover" onClick={() => addSuggestionSkill(64440, "Java")}>
                            Java
                            <img src={plusImg} alt="plus" />
                        </div>

                        <div className="top-skill hand-hover" onClick={() => addSuggestionSkill(64649, "React")}>
                            React
                            <img src={plusImg} alt="plus" />
                        </div>

                        <div className="top-skill hand-hover" onClick={() => addSuggestionSkill(72414, "Full Stack")}>
                            Full Stack
                            <img src={plusImg} alt="plus" />
                        </div>
                    </>}
            </div>

            <div class="accordion skill_box" id="accordionExample">
                {skill && skill.map((element, key) => {
                    return <div key={key} className='accordion-parent'>
                        <div class="accordion-item">
                            <div class="accordion-header skill_box_det" id={"headingOne" + key}>
                                <button class={isSkillNotCollapsed(key) ? "accordion-button" : "accordion-button collapsed"} id={'skilButton' + key} type="button" data-bs-toggle="collapse" data-bs-target={"#collapseSkill" + key} aria-expanded={isSkillNotCollapsed(key)} aria-controls="collapseSkill">
                                    {skill[key].skill_name}
                                    <p className='experience'>
                                        {
                                            (!skill[key].experience || skill[key].experience <= 0)
                                                ?
                                                (!(isEvaluator() || (selectedProfile !== undefined && selectedProfile === 'evaluator'))
                                                    ? 'Fresher' : '0 years') : null
                                        }

                                        {Math.floor(skill[key].experience / 12) > 0 ? Math.floor(skill[key].experience / 12) + ' years' : null}{skill[key].experience % 12 > 0 ? ' ' + skill[key].experience % 12 + ' months' : null}
                                    </p>
                                </button>
                            </div>

                            <div id={"collapseSkill" + key} class={isSkillNotCollapsed(key) ? "accordion-collapse collapse show" : "accordion-collapse collapse"} aria-labelledby={"headingOne" + key} data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <div className="row  row-gap-2">
                                        <div className="col-12 col-md-4">
                                            <Input type="text" label="Skill"
                                                isRequired={true} placeholder="Enter Skill"
                                                id="search_skills" value={skill[key].skill_name}
                                                name='skill_id' onChange={(e) => { onChangeHandlerSkill(e.target.value, key); }}
                                                onKeyDown={(e) => handleKeyPress(e, key)}
                                                onBlur={() => {
                                                    setTimeout(() => {
                                                        handleBlurEvent(key);
                                                    }, 300);
                                                }}
                                            />

                                            <div className='skill_suggestion_box suggestion_box'>
                                                {skillSuggestions && activeRow === key &&
                                                    skillSuggestions.map((suggestion, i) => <p key={i} className='hand-hover' onClick={() => {
                                                        setSuggestionClicked(true);
                                                        if (skill.find((data, dataIndex) => (data.skill_id === suggestion?.id && key !== dataIndex))) {
                                                            toast.warn('Skill already added');
                                                            return;
                                                        } else {
                                                            var tempArray = [...skill];
                                                            tempArray[key].skill_name = suggestion.name;
                                                            tempArray[key].skill_id = suggestion.id;
                                                            setSkill(tempArray);
                                                            setSkillSuggestions([]);
                                                            document.getElementById('search_skills').value = '';
                                                        }
                                                    }}>{suggestion.name}</p>)}
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-4 ">
                                            <div className="form-input-group">
                                                <label className="input-lebel2 required" htmlFor="#year">
                                                    Exp. (Yrs)
                                                </label>
                                                <select className="form-select form-select-lg" id='year' value={skill && skill[key] && skill[key].experience ? Math.trunc(skill[key].experience / 12) : 0} onChange={(e) => skillExperienceHandler(e, 'year', key)} >
                                                    {years.map((data, key) => {
                                                        if (data == 1) {
                                                            return <option key={key} value={data}>{data} year</option>
                                                        } else {
                                                            return <option value={data} key={key} >{data} years</option>
                                                        }
                                                    })}

                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-4 ">
                                            <div className="form-input-group">
                                                <label className="input-lebel2 required" htmlFor="">
                                                    Months
                                                </label>
                                                <select className="form-select form-select-lg" id="month" value={skill && skill[key] && skill[key].experience ? Math.trunc(skill[key].experience % 12) : 0} onChange={(e) => skillExperienceHandler(e, 'month', key)} >
                                                    {months.map((data, key) => {
                                                        if (data == 1) {
                                                            return <option key={key} value={data}>{data} month</option>
                                                        } else {

                                                            return <option key={key} value={data}>{data} months</option>
                                                        }

                                                    })}

                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    {((showCerticate && isCandidate()) || (selectedProfile !== undefined && selectedProfile === 'candidate')) ? <>
                                        <div className='my-2 position-relative' my={2} position={"relative"}
                                            display={"flex"}
                                            flexDirection={"column"}
                                        >
                                            <Text text="Certificate Upload (Optional)" className="input-lebel2" size='xs' htmlFor='' />


                                            <Upload
                                                type="strip"
                                                text="Certificate"
                                                fileLink={skill[key]?.certificate || skill[key]?.skill_certificate}
                                                accept="image/*, .doc, .pdf, .docx, .ppt, .pptx"
                                                onChange={(e) => {
                                                    onChangeFileHandler(e, key)
                                                    console.log("value from ref", inputData.current.value)
                                                }}
                                                fileRef={inputData}
                                                onDelete={(e) => {
                                                    onDeleteFileHandler(e, key)
                                                }}
                                            />
                                        </div>

                                        <div className="my-2" >
                                            <Input _placeholder={{
                                                fontSize: "xs"
                                            }}
                                                borderColor={"#E2E8F0"}
                                                name='awarded_by'
                                                fontSize={"xs"}
                                                value={skill[key].awarded_by}
                                                onChange={(e) => { onChangeHandler(e, key); }}
                                                p={"5px"} h={"40px"} type="text" placeholder='Enter details' label='Certified by' />
                                        </div>

                                        <div className="my-2" >
                                            <div key={"preffered" + key} className="col form-check" style={{ paddingLeft: "1.5em" }}>
                                                <input type='checkbox' className="form-check-input" name='is_preffered_skill' id={"preffered" + key} checked={isPreferredSkill(element)} onChange={(e) => { preferredSkillHandler(e, key) }} />
                                                <label className="form-check-label" htmlFor={"preffered" + key}>
                                                    Is preferred skill?
                                                </label>
                                            </div>
                                        </div>
                                    </> : null}

                                    {(isEvaluator() || (selectedProfile !== undefined && selectedProfile === 'evaluator')) ?
                                        <div className="row mt-3">
                                            <div className="col-6">
                                                <div className="position-relative form-input-group">
                                                    <label className="form-input-label required" htmlFor=''>Total hours spent conducting interviews</label>
                                                    <div className="">
                                                        <input type="number" className="form-control" placeholder="Enter hours" id={'hours' + key} value={skill[key].description} name='description' onChange={(e) => { onChangeHandler(e, key); }} />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        : null}
                                </div>
                            </div>

                        </div>

                        <div className='delete-btn'>
                            <button onClick={(e) => deleteRow(e, key)}><img onError={commonServices.imgError} src={trashImg} alt="" className="trash" /></button>
                        </div>
                    </div>
                })}
            </div>

            <button className="add_btn" onClick={() => {
                if (addSkillAction !== undefined) {
                    addSkillAction();
                } else {
                    setSkill([...skill, { 'isCollapse': false }]);
                }

            }}><img onError={commonServices.imgError} src={plusCircleImg} alt="" /> Add Skills</button>
        </div>
    );
});

export default AddSkill;