
import React, { useState } from 'react';
import './Modal.scss';
import * as storageConstants from '@/constants/storageConstants';
import * as ApiHelper from '@/services/apiHelper';
import * as commonServices from '@/services/common';
import * as toast from "@/wrapper/toast";
import AppModal from './AppModal';

function ModalChangeCandidateStatus({ showModal, setShowModal, job_id, candidate_id, jobDetail }) {

    const masterData = commonServices.getLocalData(storageConstants.DATA);
    let [loading, setLoading] = useState(false);
    const [comment, setComment] = useState('');
    const [reasonId, setReasonId] = useState('');
    const [isProfileMatch, setIsProfileMatch] = useState('0');
    const [issues] = useState(masterData !== null ? masterData?.issues : []);

    const changeApplicantStatus = () => {
        let status = isProfileMatch === '1' ? "shortlisted" : "rejected";
        const formData = new FormData();
        formData.append('job_id', job_id);
        formData.append('candidate_id', candidate_id);
        formData.append('recruiter_action', status);
        formData.append('is_profile_match', isProfileMatch);
        if (isProfileMatch === '0') {
            formData.append('reason_id', reasonId);
        }

        formData.append('recruiter_comment', comment);
        setLoading(true);
        ApiHelper.changeApplicantStatus(formData).then((response) => {
            setLoading(false);
            if (response.isSuccess === true) {
                jobDetail();
                toast.success(response.message);
            }
            else {

                toast.warn(response.message);
            }
            setShowModal(!showModal);

        });
    }


    return (
        <>

            <AppModal
                isOpen={showModal}
                closeModal={() => { setShowModal(prev => !prev); }}
                title="Does this profile match your requirements?"
                size="xl"
                submit={changeApplicantStatus}
                isFooter={true}
                loading={loading}

            >
                <div className="modal_body">

                    <div className="row">
                        <div className="col">
                            <select className="form-select form-select-lg" id='match' value={isProfileMatch} onChange={(e) => setIsProfileMatch(e.target.value)} >
                                <option value="0">Does not match</option>
                                <option value="1">Match</option>


                            </select>
                        </div>
                    </div>
                    {isProfileMatch === '0' ?
                        <div className="row">
                            <div className="col">
                                <label className="input-lebel">
                                    Select Reason
                                </label>
                                <select className="form-select form-select-lg" id='issue-id' value={reasonId} onChange={(e) => setReasonId(e.target.value)} >
                                    {issues && issues.map((data, key) => {
                                        return <option key={key} value={data.id}>{data.name}</option>
                                    })}

                                </select>
                            </div>
                        </div>
                        : null}

                    <div className="row">
                        <div className="col">
                            <label className="input-lebel">
                                Comment (Optional)
                            </label>
                            <textarea className="form-control form-textbox" rows="4" cols="50" value={comment} onChange={(e) => setComment(e.target.value)} />
                        </div>
                    </div>

                    {/* <div className="row">
                        <div className="col"></div>
                        <div className="col">
                            <div className="form_buttons">
                                <button className="btn btn-primary" onClick={() => setShowModal(prev => !prev)}>Cancel</button>

                                <input type="submit" value="Save" onClick={() => changeApplicantStatus()} />
                            </div>
                        </div>
                    </div> */}

                </div>
            </AppModal>

            {/* {showModal ? (
                    <>

                        <div className="modal" onClick={() => setShowModal(prev => !prev)}>
                        </div>
                        <div className="modal_content">
                            {loading ?
                                <div className='loader'>
                                    <ClipLoader color={color} loading={true} css={override} size={150} /> </div> : null}
                            <div className="modal_top">
                                <h2>Does this profile match your requirement</h2>
                                <button onClick={() => setShowModal(prev => !prev)} ><img onError={commonServices.imgError} src="../../../Assets/svgs/x.svg" alt="" /></button>
                            </div>
                            <hr />
                           
                        </div>
                    </>

                ) : null} */}
        </>
    )
}

export default ModalChangeCandidateStatus