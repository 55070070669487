export const seoObj = {
    "home": {
        "title": "TrueTalent - The Ultimate Hiring Platform. Discover Top Industry Talent with TrueTalent.",
        "description": "Discover the hottest job opportunities from countless small, medium, and large companies all over India!  Level up your career and score those high-paying gigs! Make your next move and watch your professional ambitions come true!",
        "targeted_keywords": "High-paying jobs India, Career opportunities, Job openings across companies, Best job offers India, Top-paying careers, Job opportunities India",
        "meta_keywords": "High-paying jobs India, Career opportunities, Job openings across companies, Best job offers India, Top-paying careers, Job opportunities India"
    },
    "privacy": {
        "title": "Your personal data is safe with us at TrueTalent - The Ultimate Hiring Platform.",
        "description": "We take data privacy seriously. Your personal data is safe with us. You choose how and with whom you want to share your data.",
        "targeted_keywords": "",
        "meta_keywords": ""
    },
    "terms": {
        "title": "Your data is used to get you the best out of TrueTalent - The Ultimate Hiring Platform.",
        "description": "Get the best hiring experience by sharing the required data and accepting general terms of usage of the platform.",
        "targeted_keywords": "",
        "meta_keywords": ""
    },
    "about": {
        "title": "",
        "description": "We are the ultimate hiring platform based on Recruitment as a Service model with some amazing Pay as you use plans, so you only pay for what you need! With many large hiring companies and best talent on our platform, experience hiring 2.0 with TrueTalent!",
        "targeted_keywords": "",
        "meta_keywords": ""
    },
    "contact": {
        "title": "",
        "description": "We're all ears! Need to get in touch? Whether it's for your needs, sharing feedback, or seeking assistance, we're here for you!",
        "targeted_keywords": "",
        "meta_keywords": ""
    },
    "gig": {
        "title": "Take Control of Your Career. Explore Freelancing Jobs: Find Flexible Opportunities Across Industries",
        "description": "Discover a diverse range of freelancing/gig job opportunities across industries. Browse and apply for flexible roles that empower you to take control of your career, achieve professional growth, and enjoy the benefits of freelance work",
        "targeted_keywords": "",
        "meta_keywords": ""
    },
    "job_search": {
        "title": "",
        "description": "Empowering growth through talent discovery. TrueTalent.io - Your premier platform to connect with exceptional industry professionals, unlocking opportunities for businesses and careers alike",
        "targeted_keywords": "",
        "meta_keywords": ""
    },
    "gig_search": {
        "title": "",
        "description": "",
        "targeted_keywords": "",
        "meta_keywords": ""
    },
    "job-sap": {
        "title": "Explore SAP Jobs with TrueTalent: Join Leading Teams in Enterprise Solutions",
        "description": "Discover a range of SAP job opportunities that allow you to excel in enterprise solutions. Browse and apply for roles that empower you to contribute to business excellence through your SAP expertise",
        "targeted_keywords": "",
        "meta_keywords": "SAP job vacancies, SAP career opportunities, Enterprise solutions roles, Business excellence with SAP, Join top SAP teams"
    },
    "job-java": {
        "title": "Java Developer Opportunities: Join Top Tech Teams in Software Development",
        "description": "Discover and apply for Java Developer jobs that offer the chance to excel in Java programming. Explore a variety of roles, from backend to full stack, and contribute to software development excellence in top-tier tech teams",
        "targeted_keywords": "",
        "meta_keywords": "Java Developer job vacancies, Java programming roles, Lucrative coding careers, Software development job opportunities, Backend and full stack Java jobs"
    },
    "job-react": {
        "title": "Explore React Developer Jobs at TrueTalent: Join Top Teams in Web Development",
        "description": "Discover and apply for React Developer jobs that offer the chance to work with cutting-edge web technologies. Join top-tier tech teams and contribute to front-end excellence in the ever-evolving world of web development",
        "targeted_keywords": "",
        "meta_keywords": ""
    },
    "job-full-stack": {
        "title": "Full Stack Developer Jobs: Front-End and Back-End Opportunities at TrueTalent",
        "description": "Explore a world of exciting full stack developer jobs or gigs on TrueTalent. Find opportunities that demand your dual expertise in both front-end and back-end development, and take the next step in advancing your tech career.",
        "targeted_keywords": "",
        "meta_keywords": "Full Stack Developer job openings, Front-end and back-end roles, Full Stack Developer career opportunities, End-to-end web solutions jobs"
    },
    "job-sales": {
        "title": "Find Lucrative Sales Jobs Across Industries | Advance Your Sales Career",
        "description": "Discover diverse and rewarding sales jobs across industries. Uncover opportunities to showcase your persuasion skills, build relationships, and drive revenue growth.  Browse and apply for high-potential sales roles at TrueTalent.",
        "targeted_keywords": "",
        "meta_keywords": "Sales Jobs, Marketing Jobs, Advertising Jobs, Sales jobs openings, Sales career opportunities, Top sales roles"
    },
    "job-hr": {
        "title": "HR Career Opportunities through TrueTalent: Drive Organizational Success through People Management",
        "description": "Discover a wide range of HR job opportunities that allow you to shape strong workforces. Browse and apply for roles that empower you to drive organizational success through your expertise in human resources.",
        "targeted_keywords": "",
        "meta_keywords": "HR job vacancies, HR career opportunities, Lucrative HR positions, Human resources job openings, People management roles, Join dynamic HR teams"
    },
    "job-bangalore": {
        "title": "Bangalore Job Vacancies: Unlock Your Potential in India's Silicon Valley through TrueTalent",
        "description": "Discover a diverse range of job opportunities in Bangalore, India's vibrant tech hub. Browse TrueTalent - The Ultimate Hiring Platform and apply for roles that align with your expertise, and take a significant step towards advancing your career in the heart of Silicon Valley",
        "targeted_keywords": "",
        "meta_keywords": "Job vacancies in Bangalore, Bangalore job openings, Lucrative careers in Silicon Valley, Bangalore job market, Professional opportunities in Bangalore, Advance your career in Bangalore"

    },
    "job-chennai": {
        "title": "",
        "description": "",
        "targeted_keywords": "",
        "meta_keywords": ""
    },
    "job-new-delhi": {
        "title": "Discover Exciting Job Opportunities in Delhi through TrueTalent | Propel Your Career Forward",
        "description": "Explore a diverse array of job opportunities in Delhi. Browse TrueTalent - The Ultimate Hiring Platform and apply for roles that align with your expertise, and take a significant step towards advancing your career in this dynamic metropolitan hub",
        "targeted_keywords": "",
        "meta_keywords": ""
    },
    "job-mumbai": {
        "title": "",
        "description": "",
        "targeted_keywords": "",
        "meta_keywords": ""
    },
    "job-pune": {
        "title": "",
        "description": "",
        "targeted_keywords": "",
        "meta_keywords": ""
    },
    "job-technology": {
        "title": "",
        "description": "",
        "targeted_keywords": "",
        "meta_keywords": ""
    },
    "job-engineering": {
        "title": "",
        "description": "",
        "targeted_keywords": "",
        "meta_keywords": ""
    },
    "job-hospitality": {
        "title": "",
        "description": "",
        "targeted_keywords": "",
        "meta_keywords": ""
    },
    "job-retail": {
        "title": "",
        "description": "",
        "targeted_keywords": "",
        "meta_keywords": ""
    },
    "job-healthcare": {
        "title": "",
        "description": "",
        "targeted_keywords": "",
        "meta_keywords": ""
    },
    "job-banking": {
        "title": "",
        "description": "",
        "targeted_keywords": "",
        "meta_keywords": ""
    },
};