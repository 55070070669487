import * as commonServices from "../services/common";
import * as storageConstants from "../constants/storageConstants";

export function checkUrl(string) {
    return /^(?:(https?|ftp):\/\/)?[^\s\/$?#].[^\s]*\.[a-zA-Z]{2,}.*$/i
        .test(string.includes("www.") ? string.replace("www.", "") : string);
}

export function validateKeyword(keyword) {
    let keywordArray = keyword.split(' ');
    let response = true;
    for (var i = 1; i < keywordArray.length; i++) {
        let str = keywordArray[i].toLowerCase();
        let previousStr = keywordArray[i - 1].toLowerCase();
        if ((i > 0 &&
            ((str === 'and' || str === 'or') && (previousStr === 'and' || previousStr === 'or' || previousStr === 'not')))
            || (str === 'not' && previousStr === 'not')) {
            response = false;
            break;
        }
    }

    return response;
}

export function isValidNumber(number) {
    return /^[0-9]*$/.test(number);
}

export function isUndefined(value) {
    return value === undefined;
}