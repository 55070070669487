/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
import "./Steps.scss";
import * as ApiHelper from "../../../../services/apiHelper";
import * as toast from "../../../../wrapper/toast"
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";
import * as commonServices from '../../../../services/common';
import ModalAddEducation from '../../../Modals/ModalAddEducation';
import ModalCertificate from '../../../Modals/ModalCert';
import ModalEmp from '../../../Modals/ModalEmp';
import ModalAward from '../../../Modals/ModalAward';
import * as storageConstants from '../../../../constants/storageConstants';
import * as errors from '../../../../constants/stringConstants';
import AddSkill from "./AddSkill";
import AddLinks from "./AddLinks";
import { isExist, isNotNullOrEmpty } from '../../../../utils/objectUtil';
import { checkUrl } from '../../../../utils/validation';
import { isEvaluator } from "../../../../utils/authUtil";
import plusCircleImg from "../../../../Assets/svgs/plus_circle.svg"
import trashImg from "../../../../Assets/svgs/trash.svg"
import { jsonToFormData } from "../../../../wrapper/formData";
import { setUser, trackCandidateOnboarding, trackEvaluatorOnboarding } from "../../../../helpers/analytics";
import { analyticsConstant } from "../../../../constants/analyticsConstant";
import Button from "../../../_widgets/Button/Button";
import Text from "../../../_widgets/Text/Text";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: var(--green);
  left: calc(50% - 75px);
  position: fixed;
`;

export default function WorkProfileDetail({ selectedProfile, navigateUser }) {

    const skillChildRef = useRef();

    const [profile, setProfile] = useState({
        user_work_profile: {},
        userWorkProfileDetail: {}
    })
    let [loading, setLoading] = useState(false);
    let [color] = useState("#000000");
    const month = ["abc", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const years = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
    const months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
    const [showModalEducation, setShowModalEducation] = useState(false);
    const [showModalEmployment, setShowModalEmployment] = useState(false);
    const [showModalCertificate, setShowModalCertificate] = useState(false);
    const [showModalAward, setShowModalAward] = useState(false);

    let [dataAutoSave, setDataAutoSave] = useState(false);
    // let [isProfileInReview, setIsProfileInReview] = useState(false);
    const [skill, setSkill] = useState([]);
    const [links, setLinks] = useState([]);
    const [skillData, setSkillData] = useState([]);

    const experienceHandler = (e, type) => {
        var tempArray = { ...profile };
        if (tempArray.user_work_profile === null) {
            tempArray.user_work_profile = {};
            tempArray.user_work_profile.total_experience = 0;
        }
        if (type === 'year') {
            var months = parseInt(e.target.value) * 12;
            tempArray.user_work_profile.total_experience = tempArray.user_work_profile.total_experience % 12 + months;
            setProfile(tempArray);
        } else if (type === 'month') {
            tempArray.user_work_profile.total_experience = (Math.trunc(tempArray.user_work_profile.total_experience / 12)) * 12 + parseInt(e.target.value);
            setProfile(tempArray);
        }

    }

    const sortArray = (array) => {

        return array.sort(function (a, b) {
            var keyA = new Date(a.from_date),
                keyB = new Date(b.from_date);
            if (keyA > keyB) return -1;
            if (keyA < keyB) return 1;
            return 0;
        });

    }

    const deleteDegree = async (e, data) => {
        e.preventDefault();
        var tempProfileArr = { ...profile };
        tempProfileArr.userWorkProfileDetail.degree.splice(data, 1);
        setProfile(tempProfileArr);
    }

    const deleteEmployment = async (e, data) => {
        e.preventDefault();
        var tempProfileArr = { ...profile };
        tempProfileArr.userWorkProfileDetail.experience.splice(data, 1);
        setProfile(tempProfileArr);
    }

    const deleteCertificate = async (e, data) => {
        e.preventDefault();
        var tempProfileArr = { ...profile };
        tempProfileArr.userWorkProfileDetail.certificate.splice(data, 1);
        setProfile(tempProfileArr);
    }

    // const deleteSkills = async (e, data) => {
    //     e.preventDefault();
    //     var tempProfileArr = { ...profile };
    //     tempProfileArr.userWorkProfileDetail.skill.splice(data, 1);
    //     setProfile(tempProfileArr);
    // }
    const deleteAward = async (e, data) => {
        e.preventDefault();
        var tempProfileArr = { ...profile };
        tempProfileArr.userWorkProfileDetail.award.splice(data, 1);
        setProfile(tempProfileArr);
    }

    const getSkills = () => {
        ApiHelper.getSkills().then((response) => {
            setLoading(false);
            if (response.isSuccess === true) {
                setSkillData(response.data);
            }
            else {
                console.log('error');
            }
        });
    }

    const profileSkip = () => {
        ApiHelper.skipProfile().then((response) => {
            if (response.isSuccess !== true) {
                console.error('error in skip API');
            }
        });
    }

    const setUserSkillData = () => {
        if (profile.userWorkProfileDetail !== undefined && profile.userWorkProfileDetail.skill !== undefined && profile.userWorkProfileDetail.skill !== null) {
            (profile.userWorkProfileDetail.skill).forEach(currentSkill => {
                (skillData).forEach(element => {
                    if (element.id === currentSkill.skill_id) {
                        currentSkill.skill_name = element.name;
                    }
                });
            });
        }
        setSkill(isExist(profile.userWorkProfileDetail) && isExist(profile.userWorkProfileDetail.skill) && isNotNullOrEmpty(profile.userWorkProfileDetail.skill) ? profile.userWorkProfileDetail.skill : [{}]);
    }

    const getLatestProfile = async () => {
        setLoading(true);
        await ApiHelper.getProfile().then((response) => {
            setLoading(false);
            if (response.isSuccess === true) {
                setProfile(response.data);
                setLinks(response.data?.userWorkProfileDetail !== undefined && response.data?.userWorkProfileDetail?.link !== undefined ? response.data?.userWorkProfileDetail?.link : []);
                commonServices.storeLocalData(storageConstants.PROFILE, response.data);
            } else {
                toast.warn(response.message);
            }
        });
    }

    const isInvalidLinks = () => {
        var isError = false;
        links && links.map((element, key) => {
            var currentRow = key + 1;
            var isLinkExist = false;
            isLinkExist = links.some(function (el, i) {
                return ((el.title === links[key].title && key !== i) || (el.description === links[key].description && key !== i));

            });

            if (isLinkExist) {
                isError = true;
                toast.warn(errors.PORTFOLIO_LINK_EXISTS_ERROR + " at row " + currentRow);
                logEvent(analyticsConstant.VALIDATION_FAILED, errors.PORTFOLIO_LINK_EXISTS_ERROR + " at row " + currentRow);
                return false;
            } else if (!links[key].title) {
                isError = true;
                toast.warn(errors.EMPTY_PORTFOLIO_LABEL_ERROR + " at row " + currentRow);
                logEvent(analyticsConstant.VALIDATION_FAILED, errors.EMPTY_PORTFOLIO_LABEL_ERROR + " at row " + currentRow);

            } else if (!links[key].description) {
                isError = true;
                toast.warn(errors.EMPTY_PORTFOLIO_LINK_ERROR + " at row " + currentRow);
                logEvent(analyticsConstant.VALIDATION_FAILED, errors.EMPTY_PORTFOLIO_LINK_ERROR + " at row " + currentRow);

            } else if (!checkUrl(links[key].description)) {
                isError = true;
                toast.warn(errors.VALID_PORTFOLIO_LINK + " at row " + currentRow);
                logEvent(analyticsConstant.VALIDATION_FAILED, errors.VALID_PORTFOLIO_LINK + " at row " + currentRow);
            }



        });

        return isError;


    }

    const updateWorkProfile = async () => {

        if (profile.user_work_profile === null) {
            profile.user_work_profile = [];
        }
        const obj = {
            "total_experience": profile.user_work_profile.total_experience,
            "work_authorization_id": profile.user_work_profile ? profile.user_work_profile.work_authorization_id : null,
            "work_profile_id": profile.user_work_profile ? profile.user_work_profile.user_id : null,
            "certificate": profile.userWorkProfileDetail.certificate,
            "experience": profile.userWorkProfileDetail.experience,
            "education_degree": profile.userWorkProfileDetail.degree,
            "awards": profile.userWorkProfileDetail.award,
            "skills": skill,
            "links": links,
        }

        var isError = 0;
        // var isErrorShow = true;

        // if (!obj.education_degree || obj.education_degree.length < 1) {
        //     isError = 1;
        //     toast.warn(errors.EDUCATION_REQUIRED);
        // } else 

        if (obj.total_experience > 0 && (!obj.experience || obj.experience.length < 1)) {
            isError = 1;

            toast.warn(errors.EMPLOYMENT_HISTORY_REQUIRED);
            logEvent(analyticsConstant.VALIDATION_FAILED, errors.EMPLOYMENT_HISTORY_REQUIRED);

        } else if (skillChildRef.current.isInvalidSkills()) {
            isError = 1;
        } else if (isInvalidLinks()) {
            isError = 1;
        }
        if (isError === 0) {
            setLoading(true);
            const formDataSeralize = jsonToFormData(obj);
            await ApiHelper.updateWorkProfile(formDataSeralize).then((response) => {
                setLoading(false);
                if (response.isSuccess === true) {
                    toast.success(response.message);
                    logEvent(analyticsConstant.COMPLETE)
                    setUser(profile)
                    getLatestProfile();
                    navigateUser();
                    // window.open("/workprofile/view", '_blank');
                }
                else {
                    toast.warn(response.message);
                    logEvent(analyticsConstant.FAILED, response.message)
                }

            });

        }

        // else if (isErrorShow) {
        //     toast.warn(errors.MANDATORY_FIELDS_REQUIRED);
        // }

    }

    useEffect(() => {
        getLatestProfile();
        getSkills();
    }, [])

    useEffect(() => {
        setUserSkillData();
    }, [skillData])



    const logEvent = (step, data) => {
        switch (selectedProfile) {
            case "candidate":
                trackCandidateOnboarding(step, data);
                break;
            case "evaluator":
                trackEvaluatorOnboarding(step, data);
                break;
            default:
                break;
        }
    }

    return (
        <div className="personal-details work-profile steps registration-section">
            {loading ? (
                <div className="loader">
                    <ClipLoader color={color} loading={true} css={override} size={150} />
                </div>
            ) : null}
            <ModalAddEducation showModal={showModalEducation} setShowModal={setShowModalEducation} profile={profile} setProfile={setProfile} setDataAutoSave={setDataAutoSave} />
            <ModalEmp showModal={showModalEmployment} setShowModal={setShowModalEmployment} profile={profile} setProfile={setProfile} setDataAutoSave={setDataAutoSave} />
            <ModalCertificate showModal={showModalCertificate} setShowModal={setShowModalCertificate} profile={profile} setProfile={setProfile} setDataAutoSave={setDataAutoSave} />
            <ModalAward showModal={showModalAward} setShowModal={setShowModalAward} profile={profile} setProfile={setProfile} setDataAutoSave={setDataAutoSave} />


            <div className="registration-section-header">
                <Text text={`Complete Your ${isEvaluator() ? "Profile" : "Workprofile"}`} type="h1" className="title">
                </Text>
                <div className="d-flex gap-2">
                    <Button buttonType="secondary" text="Skip" onClick={() => { navigateUser(); profileSkip(); }} />
                    <Button buttonType="primary" text="Next" onClick={() => updateWorkProfile()} />
                </div>
            </div>
            <div className="row mb-3" id="Experience">

                <div className="col">
                    <Text type="h2" text="Total Experience" className="mb-2"></Text>
                    <div className="row">
                        <div className="col">

                            <label htmlFor="">Years</label>
                            <select className="form-select form-select-lg" id='year' value={profile?.user_work_profile ? Math.trunc(profile.user_work_profile.total_experience / 12) : 0} onChange={(e) => experienceHandler(e, 'year')} >
                                {years.map((data, key) => {
                                    return <option key={key} value={data}>{data} years</option>
                                })}

                            </select>

                        </div>

                        <div className="col">

                            <label htmlFor="">Months</label>
                            <select className="form-select" id='mon' value={profile.user_work_profile ? profile.user_work_profile.total_experience % 12 : 0} onChange={(e) => experienceHandler(e, 'month')}>
                                {months.map((data, key) => {
                                    return <option key={key} value={data}>{data} months</option>
                                })}

                            </select>
                        </div>

                    </div>
                </div>

            </div>

            <div className="row mb-3" id="Education">

                <div className="col">

                    <Text type="h2" text="Education" className="mb-2 required"></Text>
                    <Text type="h7" text="Highlight your education details with details of your specific areas of learning"></Text>

                    {profile.userWorkProfileDetail && profile.userWorkProfileDetail.degree && sortArray(profile.userWorkProfileDetail.degree).map((data, key) => {
                        return <div className="emp_box" key={key} >
                            <div className="emp_box_det" onClick={(e) => { setShowModalEducation(prev => !prev); }}>
                                <div >
                                    <p>{data.awarded_by}</p>
                                    <p className="green_text">{data.title}</p>
                                    <p>{data.from_date !== null ? month[((data.from_date).split('/'))[0] * 1] : ''} {data.from_date !== null ? (data.from_date).split('/')[2] + " - " : ''}   {(data.to_date !== null) ? <>{month[((data.to_date).split('/'))[0] * 1]} {(data.to_date).split('/')[2]}</> : <>Present</>}</p>
                                </div>
                                <img onError={commonServices.imgError} src="./Assets/svgs/chevron_down.svg" alt="" />
                            </div>
                            <button onClick={(e) => deleteDegree(e, key)}><img onError={commonServices.imgError} src={trashImg} alt="" className="trash" /></button>

                        </div>
                    })}

                    <button className="add_btn" onClick={() => { setShowModalEducation(prev => !prev); }}><img onError={commonServices.imgError} src={plusCircleImg} alt="" /> Add{profile.userWorkProfileDetail && profile.userWorkProfileDetail.degree && profile.userWorkProfileDetail.degree.length > 0 ? "/Edit" : ""} Education</button>

                </div>

            </div>

            <div className="row mb-3" id="Employment">

                <div className="col">
                    <Text type="h2" text="Employment History" className={profile?.user_work_profile?.total_experience > 0 ? 'required mb-2' : null}>

                    </Text>
                    <Text type="h7" text="Give details about your employment over the years starting with your current employment.  If you are starting your career, mention your career goals and aspirations, starting with, Looking forward to an exciting career."></Text>


                    {profile.userWorkProfileDetail && profile.userWorkProfileDetail.experience && sortArray(profile.userWorkProfileDetail.experience).map((data, key) => {
                        return <div className="emp_box" key={key} >
                            <div className="emp_box_det" onClick={(e) => { setShowModalEmployment(prev => !prev); }}>
                                <div >
                                    <p>{data.awarded_by}</p>
                                    <p className="green_text">{data.title}</p>
                                    <p>{month[((data.from_date).split('/'))[0] * 1]} {(data.from_date).split('/')[2]}  <span>-</span>  {(data.to_date !== null) ? <>{month[((data.to_date).split('/'))[0] * 1]} {(data.to_date).split('/')[2]}</> : <>Present</>}</p>
                                </div>
                                <img onError={commonServices.imgError} src="./Assets/svgs/chevron_down.svg" alt="" />
                            </div>
                            <button onClick={(e) => deleteEmployment(e, key)}><img onError={commonServices.imgError} src={trashImg} alt="" className="trash" /></button>

                        </div>
                    })}



                    <button className="add_btn" onClick={() => { setShowModalEmployment(prev => !prev); }}><img onError={commonServices.imgError} src={plusCircleImg} alt="" /> Add{(profile.userWorkProfileDetail && profile.userWorkProfileDetail.experience && profile.userWorkProfileDetail.experience.length > 0) ? '/Edit' : ''} Employment History</button>


                </div>

            </div>

            <div className="row mb-3" id="Certification">

                <div className="col">
                    <Text type="h2" text="Certifications" className="mb-2"></Text>
                    <Text type="h7" text="Show your relevant certificates (last 10 years). Use bullet points to note your achievements"></Text>
                    {profile.userWorkProfileDetail && profile.userWorkProfileDetail.certificate && sortArray(profile.userWorkProfileDetail.certificate).map((data, key) => {
                        return <div className="emp_box" key={key} >
                            <div className="emp_box_det" onClick={(e) => { setShowModalCertificate(true) }}>
                                <div >
                                    <p>{data.awarded_by}</p>
                                    <p className="green_text">{data.title}</p>
                                    <p>{month[((data.from_date).split('/'))[0] * 1]} {(data.from_date).split('/')[2]}</p>
                                </div>
                                <img onError={commonServices.imgError} src="./Assets/svgs/chevron_down.svg" alt="" />
                            </div>
                            <button onClick={(e) => deleteCertificate(e, key)}><img onError={commonServices.imgError} src={trashImg} alt="" className="trash" /></button>

                        </div>
                    })}
                    <button className="add_btn" onClick={() => { setShowModalCertificate(true) }}><img onError={commonServices.imgError} src={plusCircleImg} alt="" /> Add{profile.userWorkProfileDetail && profile.userWorkProfileDetail.certificate && profile.userWorkProfileDetail.certificate.length > 0 ? "/Edit" : ""} Certification</button>
                </div>

            </div>

            <div className="row mb-3" id="Awards">

                <div className="col">
                    <Text type="h2" text="Awards and Accomplishments" className="mb-2"></Text>
                    <Text type="h7" text="Show your relevant awards & accomplishments (last 10 years). Use bullet points to note your achievements"></Text>

                    {profile.userWorkProfileDetail && profile.userWorkProfileDetail.award && sortArray(profile.userWorkProfileDetail.award).map((data, key) => {
                        return <div className="emp_box" key={key} >
                            <div className="emp_box_det" onClick={(e) => { setShowModalAward(true) }}>
                                <div >
                                    <p>{data.awarded_by}</p>
                                    <p className="green_text">{data.title}</p>
                                    <p>{month[((data.from_date).split('/'))[0] * 1]} {(data.from_date).split('/')[2]}</p>
                                </div>
                                <img onError={commonServices.imgError} src="./Assets/svgs/chevron_down.svg" alt="" />
                            </div>
                            <button onClick={(e) => { deleteAward(e, key) }}><img onError={commonServices.imgError} src={trashImg} alt="" className="trash" /></button>

                        </div>
                    })}

                    <button className="add_btn" onClick={() => { setShowModalAward(true) }}><img onError={commonServices.imgError} src={plusCircleImg} alt="" /> Add{profile.userWorkProfileDetail && profile.userWorkProfileDetail.award && profile.userWorkProfileDetail.award.length > 0 ? "/Edit" : ""} Awards and Accomplishments</button>
                </div>

            </div>

            <AddSkill skill={skill} setSkill={setSkill} showCerticate={true} ref={skillChildRef} />

            <AddLinks links={links} setLinks={setLinks} />

            <div className="row head my-5">
                <div className="col-12 header-btns" style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                    gap: "8px"
                }}>
                    {/* <button className="back">Back</button> */}
                    <Button buttonType="secondary" text="Skip" onClick={() => { navigateUser(); profileSkip(); }} />
                    <Button buttonType="primary" text="Next" onClick={() => updateWorkProfile()} />
                </div>
            </div>
        </div>
    )
}