/* eslint-disable */
import React, { useState, useEffect } from 'react';
import * as commonServices from '@/services/common';
import emptyResult from '@/Assets/svgs/empty_result.svg';
import Table from '../../_widgets/Table/Table';
import { useNavigate } from 'react-router-dom';
import { createSlugFromString } from '@/utils/stringUtils';

function SavedGigs({ savedGigs, activePage, setActivePage, handlePaginationChange }) {

    const navigate = useNavigate();
    var savedGigsColumns = ['Gig ID', 'Gig', 'Company', 'Gig Type', 'Skills', 'Location'];
    const [savedGigsTableData, setSavedGigsTableData] = useState([]);
    useEffect(() => {

        var row = [];
        var row = savedGigs?.data && savedGigs?.data.map((gig, i) => {
            var data = [];
            data.push("J0000" + gig.eid);
            data.push(gig.title);
            data.push(gig.company_name);
            data.push(gig.type.name);
            data.push(gig.job_skills !== null ? gig.job_skills.split(',').join(', ') : gig.job_skills);
            data.push(gig.gig_locations);
            return {
                rowClick: (data) => { navigate('/gig/details/' + data['eid'] + '/' + createSlugFromString(data['title'])) },
                rowClickData: {
                    eid: gig?.eid,
                    title: gig?.title
                },
                data: data
            }
        }

        );

        setSavedGigsTableData(row)

    }, [savedGigs])
    return (
        <>
            {savedGigs?.data && savedGigs?.data.length > 0 ?
                (
                    <div className="common_items">
                        <Table columns={savedGigsColumns} data={savedGigsTableData} activePage={activePage} setActivePage={setActivePage} handlePaginationChange={handlePaginationChange} pagination={savedGigs} />
                    </div>)
                : <div className='no_jobs' ><img onError={commonServices.imgError} src={emptyResult} alt="" /><h2>No saved gig found!</h2></div>}
        </>
    )
}

export default SavedGigs