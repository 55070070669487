import { useEffect, useState } from "react";
import "./Modal.scss";
import AppModal from "./AppModal";
import Table from "../_widgets/Table/Table";
import * as commonServices from "@/services/common";
import { CURRENCY } from "@/constants/storageConstants";
import { isCandidate } from "@/utils/authUtil";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";

function ModalViewTransactionsPagination({
  loading,
  showModal,
  setPageNumber,
  setShowModal,
  transactions,
  isCompanyTransactions,
  headingText,
  getTransactions,
  activePage,
  handlePaginationChange,
}) {
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: var(--green);
    left: calc(50% - 75px);
    top: calc(50% - 75px);
    position: fixed;
  `;

  const [tableData, setTableData] = useState();

  useEffect(() => {
    let row =
      transactions?.transactions &&
      transactions?.transactions?.data?.map((tr, i) => {
        let data = [];

        !isCandidate() &&
          data.push(tr.candidate !== null ? tr.candidate.uid : "N/A");

        !isCandidate() &&
          data.push(
            <p className="text-end" style={{ width: "60px" }}>
              {tr.type !== "credit"
                ? "N/A"
                : CURRENCY + commonServices.currencyFormatter(tr.total_paid)}
            </p>
          );

        data.push(
          <p className="text-end" style={{ width: "90px" }}>
            {commonServices.currencyFormatter(tr.amount)}
          </p>
        );
        data.push(<p className="text-center">{tr.type.toUpperCase()}</p>);
        data.push(<p className="text-left">{tr?.comments}</p>);
        data.push(
          <p className="text-end" style={{ width: "60px" }}>
            {commonServices.currencyFormatter(tr.remaining)}
          </p>
        );
        data.push(`${tr.created_date}`);

        return { data: data };
      });

    setTableData(row);
  }, [transactions]);

  const columns = [
    { header: "Purchased Amt" },
    { header: "TT Cash", align: "text-center" },
    { header: "Transaction Type", align: "text-start" },
    { header: "Description", align: "text-start" },
    { header: "TT Balance" },
    { header: "Date/time" },
  ];

  isCandidate() && columns.shift();

  !isCandidate() &&
    columns.splice(
      0,
      0,
      isCompanyTransactions ? "Associated User" : "Candidate ID"
    );

  return (
    <>
      <AppModal
        isOpen={showModal}
        closeModal={() => {
          setShowModal(prev => !prev)
          if (setPageNumber) {
            setPageNumber(1);
          }
        }}
        title={`Transaction History - ${headingText}`}
        size={"xl"}
        isFooter={false}
        minH={"60vh"}
      >
        <div className="modal_body">
          {!loading ?
            <>
              {(transactions?.transactions && transactions?.transactions?.data?.length > 0) ?
                (<div className="t_details common_items">
                  <Table columns={columns} data={tableData} activePage={activePage} handlePaginationChange={handlePaginationChange} pagination={transactions?.transactions} />
                </div>)
                : <div className='no_jobs'><img onError={commonServices.imgError} src="../Assets/svgs/search_no_result.svg" alt="" /><h2>No History Of Used TT Cash!</h2></div>}
            </>
            : <PuffLoader
              color='var(--green)'
              css={override}
              size={150}
              height={150}
              width={150}
              radios={150}
              aria-label="Loading Spinner"
              data-testid="loader"
            />}
        </div>
      </AppModal>
    </>
  )
}

export default ModalViewTransactionsPagination;
