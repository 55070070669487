import React, { useEffect, useState, useRef } from 'react'
import Chart from "../Common/Chart";

function ResumeDashboard({
    loading,
    chart1Data,
    Chart1Ref,
    totalDownloads,
    chart2Data,
    Chart2Ref,
    getGraphData
}) {

    useEffect(() => {
        getGraphData();
    }, [])

    return (
        <>
            {loading
                ? null
                : <div className='row'>
                    <div className='col-lg-6 col-md-6 col-sm-12 mb-3 '>
                        <div className='graph-box'>
                            <Chart
                                chartData={chart1Data}
                                text='Resumes Viewed'
                                chartRef={Chart1Ref}
                                graphType={'verticalBar'}
                                loading={''}
                            />
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12 mb-3'>
                        <div className='graph-box'>
                            {/* <div className='download-div'>
                        <p className='download-p'>{totalDownloads}</p>
                        <span className='download-span'>Downloads</span>
                    </div> */}

                            <Chart
                                chartData={chart2Data}
                                text={'Download by Type'}
                                chartRef={Chart2Ref}
                                graphType={'Doughnut'}
                                loading={''}
                            />
                        </div>
                    </div>
                </div>}
        </>
    )
}

export default ResumeDashboard