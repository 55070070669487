/* eslint-disable */
import React, { useState } from "react";
import "./Modal.scss";
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import AppModal from "./AppModal";

function ModalShare({ showModal, setShowModal, title, url }) {
  const [copiedText, setCopiedText] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(url);
    setCopiedText(true);
  };

  return (
    <>
      <AppModal
        isOpen={showModal}
        closeModal={() => setShowModal((prev) => !prev)}
        title={`Share - ${title}`}
        size="md"
      >
        <div className="modal_body">
          <FacebookShareButton
            url={url}
            quote={title}
            className="socialMediaButton"
          >
            <FacebookIcon size={36} round={true} />
          </FacebookShareButton>
          <LinkedinShareButton
            url={url}
            quote={title}
            className="socialMediaButton"
          >
            <LinkedinIcon size={36} round={true} />
          </LinkedinShareButton>
          <WhatsappShareButton
            url={url}
            quote={title}
            className="socialMediaButton"
          >
            <WhatsappIcon size={36} round={true} />
          </WhatsappShareButton>

          <div
            className="makeStyles-copyContainer-5"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="makeStyles-copyUrl-6" style={{ width: "75%" }}>
              {url}
            </div>
            <div className="makeStyles-copyIcon-7 hand-hover">
              {copiedText ? (
                <a href={undefined}> Copied </a>
              ) : (
                <a href={undefined} onClick={copyToClipboard}>
                  {" "}
                  Copy{" "}
                </a>
              )}
            </div>
          </div>
        </div>

        <input type="hidden" id="text-copy" value={url}></input>
      </AppModal>
    </>
  );
}

export default ModalShare;
