/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./Steps.scss";
import emailImg from "../../../../Assets/svgs/email-2.svg";
import * as ApiHelper from "../../../../services/apiHelper";
import * as toast from "../../../../wrapper/toast";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";
import * as errors from '../../../../constants/stringConstants';
import * as storageConstants from "../../../../constants/storageConstants";
import * as commonServices from "../../../../services/common";
import { trackCandidateOnboarding, trackEvaluatorOnboarding } from "../../../../helpers/analytics";
import { analyticsConstant } from "../../../../constants/analyticsConstant";
import { isValidNumber } from "../../../../utils/validation";
import Button from "../../../_widgets/Button/Button";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: var(--green);
  left: calc(50% - 75px);
  position: fixed;
`;

export default function EmailVerification({ userId, selectedProfile, profile, setShowStep, isHeaderUpdate, setIsHeaderUpdate, setCurrentStep }) {

    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        user_id: userId,
        otp: "",
    });

    let [loading, setLoading] = useState(false);
    let [color] = useState("#000000");
    const [activeInput, setActiveInput] = useState(0);

    const [otp, setOtp] = useState(['', '', '', '']);
    const otpInputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];

    const handleInputChange = (index, e) => {
        const value = e.target.value;

        if (isValidNumber(value)) {
            const newOTP = [...otp];
            newOTP[index] = value;
            setOtp(newOTP);

            if (index < otp.length - 1 && value !== '') {
                otpInputRefs[index + 1].current.focus();
            }
        }
    };

    const handleKeyDown = (index, e) => {
        if (e.key === 'Backspace' && index > 0) {
            const newOTP = [...otp];

            if (newOTP[index] === '') {
                otpInputRefs[index - 1].current.focus();
                newOTP[index - 1] = '';
                setOtp(newOTP);
            } else {
                newOTP[index] = '';
                setOtp(newOTP);
            }
        }
    };

    useEffect(() => {
        setFormData({
            ...formData,
            otp: otp.join(""),
        });
    }, [otp]);

    useEffect(() => {
        otpInputRefs[0].current.focus();
    }, []);

    useEffect(() => {
        let inputs = document.querySelectorAll("input");
        if (activeInput < inputs.length) {
            inputs[activeInput].focus();
        }
    }, [activeInput]);

    const verifyOTP = async () => {
        if (formData.otp === '') {
            toast.warn(errors.OTP_REQUIRED);
            return false;
        }
        setLoading(true);

        console.log("formData---->", formData);
        console.log("profile---->", profile);

        await ApiHelper.EmailOtpVerify(formData).then((response) => {
            if (response.isSuccess === true) {
                setLoading(false);
                toast.success(response.message);
                if (response.data !== null && response.data.token !== undefined) {
                    commonServices.storeLocalData(storageConstants.AUTH, response.data.token);
                    commonServices.storeLocalData(storageConstants.USER_ROLE, response.data.role_type);
                    commonServices.storeLocalData(storageConstants.PROFILE, response.data.profile);
                    setIsHeaderUpdate(!isHeaderUpdate);
                    logEvent(analyticsConstant.STEP4)
                    setCurrentStep(4);

                } else if (response.data.is_mobile_verified !== undefined && !response.data.is_mobile_verified) {
                    setCurrentStep(3);
                    logEvent(analyticsConstant.STEP3)

                } else {
                    if (setShowStep !== undefined) {
                        setShowStep(false);
                    }
                    navigate('/login');
                }



            } else {
                setLoading(false);
                toast.warn(response.message);
                logEvent(analyticsConstant.FAILED, response.message)
            }
        });
    }


    const resendVerificationEmail = () => {
        setOtp(['', '', '', '']);
        setLoading(true);
        const formData = new FormData();

        formData.append("email", profile.email);
        ApiHelper.resendVerificationEmail(formData).then((response) => {
            if (response.isSuccess === true) {
                toast.success(response.message);
                setLoading(false);
            } else {
                setLoading(false);
                toast.warn(response.message);
            }
        });
    }

    const logEvent = (step, data) => {
        switch (selectedProfile) {
            case "candidate":
                trackCandidateOnboarding(step, data);
                break;
            case "evaluator":
                trackEvaluatorOnboarding(step, data);
                break;
            default:
                break;
        }
    }
    return (

        <div className="registration-section email_verify steps">
            {loading ? (
                <div className="loader">
                    <ClipLoader color={color} loading={true} css={override} size={150} />{" "}
                </div>
            ) : null}
            <div className="registration-section-header">
                <h2>
                    Verify Email
                </h2>
                <Button buttonType="primary" text="Next" onClick={() => verifyOTP()} />
            </div>

            <div className="registration-section-body">
                <div className="registration-section-body-logo">
                    <img src={emailImg} alt="email image" />
                </div>
                <div className="registration-section-body-content">
                    <h5 className="title">
                        Verify your email
                    </h5>
                    <p>We’ve sent an email to</p>
                    <p><strong>{profile?.email}</strong> to verify your email</p>
                    <p>address and activate your account.</p>
                    <p className="mt-3">The link in the email will expire in 24 hours.</p>
                    <div className="otp-sec">
                        {otp.map((digit, index) => (
                            <input
                                class="otp"
                                key={index}
                                type="text"
                                value={digit}
                                onChange={(e) => handleInputChange(index, e)}
                                onKeyDown={(e) => handleKeyDown(index, e)}
                                maxLength={1}
                                ref={otpInputRefs[index]}
                            />
                        ))}
                    </div>
                    <Button buttonType="primary" onClick={() => { verifyOTP() }} text="Verify Email" />
                    <p className="mt-3 mb-3">Can't find the email? Check your spam folder</p>
                    <Button buttonType="text" text="Resend Verification Email" onClick={() => resendVerificationEmail()} />
                    {/* <a className="hand-hover" href={undefined} onClick={() => resendVerificationEmail()} >Resend Verification Email</a> */}


                </div>

            </div>

        </div >
    )
}