import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the styles
import { isNullOrEmpty } from '@/utils/stringUtils';

function Editor({ desc, setDesc, placeholder = "Enter the description", onBlur, disabled }) {

    const handlePaste = (e) => {
        e.preventDefault();
        const clipboardData = e.clipboardData || window.clipboardData;
        const pastedData = clipboardData.getData('text/html');

        if (pastedData) {
            const quill = e.target.__quill;
            const range = quill.getSelection();
            quill.clipboard.dangerouslyPasteHTML(range.index, pastedData);
        }
    };

    return (
        <ReactQuill
            value={!isNullOrEmpty(desc) ? desc : ""}
            onChange={(value, delta, source) => setDesc(value, delta, source)}
            onBlur={onBlur}
            modules={{
                toolbar: [
                    ['bold', 'italic', 'underline', 'strike'],
                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                    ['clean'],
                ],
            }}
            formats={[
                'bold', 'italic', 'underline', 'strike',
                'list', 'bullet',
            ]}
            placeholder={placeholder}
            readOnly={disabled}
            onPaste={handlePaste}
        />
    );
}

export default Editor;
