import { useEffect, useState } from "react";
import "./App.css";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import Home from "@/components/Home/Home";
import Register from "@/components/Register/Register";
import Jobs from "@/components/Jobs/Jobs";
import Apply from "@/components/JobDetail/JobDetail";
import MyAccount from "@/components/MyAccount/MyAccount";
import WorkProfile from "@/components/WorkProfile/WorkProfile";
import ShowJobs from "@/components/ShowJobs/ShowJobs";
import Protected from "@/Protected/Protected";
import CompanyAdminProtected from "@/Protected/CompanyAdminProtected";
import CompanyProtected from "@/Protected/CompanyProtected";
import EvaluatorProtected from "@/Protected/EvaluatorProtected";
import Forgot from "@/components/Forgot/Forgot";
import About from "@/components/About/About";
import Contact from "@/components/Contact/Contact";
import Navbar from "@/components/Navbar/Navbar";
import LoggedNavbar from "@/components/LoggedNavbar/LoggedNavbar";
import CandidateSearch from "@/components/CandidateSearch/CandidiateSearch";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import * as ApiHelper from "@/services/apiHelper";
import * as commonServices from "@/services/common";
import * as storageConstants from "@/constants/storageConstants";
import PostedJobs from "@/components/PostedJobs/PostedJobs";
import AddJob from "@/components/AddJobs/AddJob.jsx";
import UserProfile from "@/components/UserProfile/UserProfile";
import UserProfileViewById from "@/components/UserProfileViewById/UserProfileViewById";
import CompanyUsers from "@/components/CompanyUsers/CompanyUsers";
import Dashboard from "@/components/Dashboard/Dashboard";
import Terms from "@/components/Terms/Terms";
import Privacy from "@/components/Privacy/Privacy";
import Giveaway from "@/components/Giveaway/Giveaway";
import AccessDenied from "@/components/Common/AccessDenied";
import PageNotFound from "@/components/Common/PageNotFound";
import AddEditCompany from "@/components/AddEditCompany/AddEditCompany";
import { BROWSER_STORAGE_ERROR } from "@/constants/stringConstants";
import CompanyProfile from "@/components/CompanyProfile/CompanyProfile";
import RecruiterProfile from "@/components/CompanyProfile/RecruiterProfile";
import UserChat from "@/components/Chat/UserChat";
import { configureAbly, useChannel } from "@ably-labs/react-hooks";
import { updateHeaderMessageIcon } from "./utils/messageUtil";
import { getUserId, isEvaluator, isPartner } from "./utils/authUtil";
import { UNREAD } from "@/constants/messageConstants";
import ShowGigs from "@/components/ShowGigs/ShowGigs";
import PostedGigs from "@/components/PostedGigs/PostedGigs";
import AddGig from "@/components/AddGigs/AddGig.jsx";
import GigDetail from "@/components/GigDetail/GigDetail";
import GigSearch from "@/components/GigSearch/GigSearch";
import FooterUpper from "@/components/FooterUpper/FooterUpper";
import GoogleCallback from "@/components/GoogleCallback/GoogleCallback";
import Referral from "@/components/Referral/Referral";
import ReferralJoin from "@/components/Referral/ReferralJoin";
import AddJobReferral from "@/components/AddJobReferral/AddJobReferral";
import CandidateScheduleAppointment from "@/components/BecomePro/CandidateScheduleAppointment/CandidateScheduleAppointment";
import EvaluatorDashboard from "@/components/Evaluator/Dashboard";
import Calendar from "@/components/Evaluator/Calendar";
import BecomePro from "@/components/BecomePro/BecomePro";
import BecomeProEvaluated from "@/components/BecomePro/BecomeProEvaluated";
import BecomeProMain from "@/components/BecomePro/BecomeProMain";
import EvaluatorInterview from "@/components/Evaluator/Interview";
import EvaluationFeedback from "@/components/EvaluationFeedback/EvaluationFeedback";
import ProfileInReview from "@/components/Register/RegistrationSteps/Steps/ProfileInReview";
import RegistrationStepsPage from "@/components/Auth/RegistrationSteps";
import EditPartnerCompany from "./components/AddEditCompany/EditPartnerCompany";
import ResumeBuilder from "./components/ResumeBuilder/ResumeBuilder";
import ResumeBuilderInfo from "./components/ResumeBuilderInfo/ResumeBuilderInfo";
import PartnerDashboard from "./components/PartnerDashboard/PartnerDashboard";
import CompanyDashboard from "./components/CompanyDashboard/CompanyDashboard";
import "./styles/global.scss";
import ViewLater from "./components/ViewLater/ViewLater";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getSkills } from "./services/common";



const override = css`
  display: block;
  margin: 0 auto;
  border-color: var(--green);
`;

function App() {

  const location = useLocation();

  useEffect(() => {
    const data = commonServices.getLocalData(storageConstants.DATA);

    if (data === null) {
      masterData();
    } else {
      var isFetch = false;
      var keys = Object.keys(data);
      for (var key in masterDataArr) {
        if (!keys.includes(key)) {
          isFetch = true;
          break;
        }
      }
      if (isFetch) {
        masterData();
      }
    }
  }, [location.pathname]);

  var auth = commonServices.getLocalData(storageConstants.AUTH);
  const profile = commonServices.getLocalData(storageConstants.PROFILE);

  const [avatar] = useState(profile !== null ? profile.avatar_location : null);

  const [getProfileTrigger, setGetProfileTrigger] = useState(false);
  const [isHeaderUpdate, setIsHeaderUpdate] = useState(false);

  const masterDataArr = [
    "benefits",
    "company_sizes",
    "config",
    "industry_domains",
    "issues",
    "close_job_issues",
    "job_durations",
    "job_types",
    "joining_preferences",
    "salary_range",
    "salary_types",
    "work_authorizations",
  ];

  const [activeNav, setActiveNav] = useState("");

  let [loading, setLoading] = useState(false);
  let [color] = useState("#000000");

  const [locationSearch, setLocationSearch] = useState([]);
  const [locationSearchName, setLocationSearchName] = useState([]);
  const [jobSearch, setJobSearch] = useState([]);
  const [keywordSearch, setKeywordSearch] = useState("");
  const [searchType, setSearchType] = useState("smart");
  const [showStickySearchBar, setShowStickySearchBar] = useState(false);
  const [jobSearchName, setJobSearchName] = useState([]);
  let [isMasterDataLoaded, setIsMasterDataLoaded] = useState(false);
  let [registerReset, setRegisterReset] = useState(false);
  const [isStickySearch, setIsStickySearch] = useState(false);

  const [searchFilterState, setSearchFilterState] = useState({
    skill: [],
    location: [],
    keyword: '',
    searchType: '',
  });

  const [keywordSeachFilters, setKeywordSeachFilters] = useState(
    {
      jobs:
      {
        title: true,
        description: true,
        // jobType: true,
        // salaryType: true,
        // industryDomain: true,
        companyName: true,
        skills: true,
        // locations: true,
      },
      candidates:
      {
        name: true,
        email: true,
        skills: true,
        // locations: true,
        // workAuthorization: true,
        // joiningPreference: true,
        resume: true,
        summary: true

      }
    }
  );

  configureAbly({
    key: "-BcqxA.y_IXaQ:TcCx9KXYIZBL6QW5O2jN9jtZLhn_N4MvNRr1cP7WSOA",
  });

  const masterData = async () => {
    setLoading(true);
    const response = await ApiHelper.getMasterData();
    if (response.isSuccess) {
      try {
        let data = response.data;
        data?.joining_preferences.push({
          id: -1,
          name: "Any"
        });

        commonServices.storeLocalData(storageConstants.DATA, data);
        setIsMasterDataLoaded(!isMasterDataLoaded);
      } catch (e) {
        alert(BROWSER_STORAGE_ERROR);
      }
    }

    setLoading(false);
  };

  const [channel, ably] = useChannel("message_" + getUserId(), (message) => {
    updateHeaderMessageIcon(UNREAD);
  });

  useEffect(() => {
    auth = commonServices.getLocalData(storageConstants.AUTH);
    const skills = commonServices.getLocalData(storageConstants.SKILLS);
    if (skills === null) {
      getSkills();
    }
  }, []);

  useEffect(() => {
    auth = commonServices.getLocalData(storageConstants.AUTH);
  }, [isHeaderUpdate]);

  return (
    <div className="App">
      {loading
        ? <div className='loader'><ClipLoader color={color} loading={true} css={override} size={150} /></div>
        : <>
          {auth
            ? <LoggedNavbar trigger={getProfileTrigger} isHeaderUpdate={isHeaderUpdate} setIsHeaderUpdate={setIsHeaderUpdate} showStickySearchBar={showStickySearchBar} setShowStickySearchBar={setShowStickySearchBar} setIsStickySearch={setIsStickySearch} isStickySearch={isStickySearch} searchFilterState={searchFilterState} />
            : <Navbar activeNav={activeNav} registerReset={registerReset} setRegisterReset={setRegisterReset} showStickySearchBar={showStickySearchBar} setShowStickySearchBar={setShowStickySearchBar} setIsStickySearch={setIsStickySearch} isStickySearch={isStickySearch} searchFilterState={searchFilterState} isHeaderUpdate={isHeaderUpdate} setIsHeaderUpdate={setIsHeaderUpdate}
            />
          }

          <div style={{ minHeight: "92vh" }}>
            <ToastContainer autoClose={8000} />
            <Routes>

              <Route exact path="/" element={isEvaluator() ? <Navigate to="/evaluator/dashboard" />
                :
                (
                  // isPartner() ? <Navigate to="/company/edit" /> :
                  <Home setJobSearch={setJobSearch} setLocationSearch={setLocationSearch} setLocationSearchName={setLocationSearchName} keywordSearch={keywordSearch} setKeywordSearch={setKeywordSearch} searchType={searchType} setSearchType={setSearchType} type={'job'} showStickySearchBar={showStickySearchBar} setShowStickySearchBar={setShowStickySearchBar} isStickySearch={isStickySearch} setIsStickySearch={setIsStickySearch} setSearchFilterState={setSearchFilterState} keywordSeachFilters={keywordSeachFilters} setKeywordSeachFilters={setKeywordSeachFilters} />
                )

              }></Route>

              <Route path="/gig" element={<Home setJobSearch={setJobSearch} setLocationSearch={setLocationSearch} setLocationSearchName={setLocationSearchName} keywordSearch={keywordSearch} setKeywordSearch={setKeywordSearch} searchType={searchType} setSearchType={setSearchType} type={'gig'} showStickySearchBar={showStickySearchBar} setShowStickySearchBar={setShowStickySearchBar} isStickySearch={isStickySearch} setIsStickySearch={setIsStickySearch} setSearchFilterState={setSearchFilterState} keywordSeachFilters={keywordSeachFilters} setKeywordSeachFilters={setKeywordSeachFilters} />}></Route>

              <Route path="*" status={404} element={<PageNotFound />}></Route>
              <Route path="/resume-builder" element={<ResumeBuilderInfo />}></Route>
              <Route path="/about" element={<About />}></Route>
              <Route path="/not-found" element={<PageNotFound />}></Route>
              <Route path="/access-denied" element={<AccessDenied />}></Route>
              <Route path="/contact" element={<Contact isMasterDataLoaded={isMasterDataLoaded} />}></Route>
              <Route path='/terms' element={<Terms />}></Route>
              <Route path='/privacy' element={<Privacy />}></Route>
              <Route path='/giveaway' element={<Giveaway />}></Route>

              <Route
                path="/register"
                element={
                  <Register
                    setActiveNav={setActiveNav}
                    registerReset={registerReset}
                    isHeaderUpdate={isHeaderUpdate}
                    setIsHeaderUpdate={setIsHeaderUpdate}
                  />
                }
              ></Route>

              <Route
                path="/onboarding"
                element={
                  <RegistrationStepsPage isHeaderUpdate={isHeaderUpdate} setIsHeaderUpdate={setIsHeaderUpdate} />
                }
              ></Route>

              <Route
                path="/profile-in-review"
                element={
                  <ProfileInReview

                  />
                }
              ></Route>
              <Route
                path="/login"
                element={
                  <Navigate to="/?auth=login" />
                }
              ></Route>
              <Route path="/forgot-password" element={<Forgot />}></Route>
              <Route
                path="/auth/google"
                element={
                  <GoogleCallback
                    isHeaderUpdate={isHeaderUpdate}
                    setIsHeaderUpdate={setIsHeaderUpdate}
                  />
                }
              ></Route>
              <Route path="/referral/:slug" element={<ReferralJoin />}></Route>

              <Route path="/referrals" element={<Protected cmp={Referral} />}></Route>
              <Route path="/my-account" element={<Protected cmp={MyAccount} setTrigger={setGetProfileTrigger} />}></Route>
              <Route path="/workprofile" element={<Protected cmp={WorkProfile} setTrigger={setGetProfileTrigger} />}></Route>

              <Route path="/jobseeker/search" element={<CandidateSearch jobSearch={jobSearch} locationSearch={locationSearch} locationSearchName={locationSearchName} jobSearchName={setJobSearchName} searchType={searchType} keywordSearch={keywordSearch}
                showStickySearchBar={showStickySearchBar} setShowStickySearchBar={setShowStickySearchBar} isStickySearch={isStickySearch} setIsStickySearch={setIsStickySearch}
                setSearchFilterState={setSearchFilterState} keywordSeachFilters={keywordSeachFilters} setKeywordSeachFilters={setKeywordSeachFilters}
              />} > </Route>

              <Route path="/job/search" element={<Jobs jobSearch={jobSearch} locationSearch={locationSearch} locationSearchName={locationSearchName} jobSearchName={setJobSearchName} searchType={searchType} keywordSearch={keywordSearch}
                showStickySearchBar={showStickySearchBar} setShowStickySearchBar={setShowStickySearchBar} isStickySearch={isStickySearch} setIsStickySearch={setIsStickySearch}
                setSearchFilterState={setSearchFilterState} keywordSeachFilters={keywordSeachFilters} setKeywordSeachFilters={setKeywordSeachFilters}
              />}></Route>
              <Route path="/job/search/:type" element={<Jobs jobSearch={jobSearch} locationSearch={locationSearch} locationSearchName={locationSearchName} jobSearchName={setJobSearchName} searchType={searchType} keywordSearch={keywordSearch}
                showStickySearchBar={showStickySearchBar} setShowStickySearchBar={setShowStickySearchBar} isStickySearch={isStickySearch} setIsStickySearch={setIsStickySearch}
                setSearchFilterState={setSearchFilterState} keywordSeachFilters={keywordSeachFilters} setKeywordSeachFilters={setKeywordSeachFilters}
              />}></Route>
              <Route path="/job/details/:job_id/:slug" element={<Apply />}></Route>
              <Route path="/jobs" element={<Protected cmp={ShowJobs} keywordSeachFilters={keywordSeachFilters} setKeywordSeachFilters={setKeywordSeachFilters} />}></Route>
              <Route path="/posted" element={<CompanyProtected cmp={PostedJobs} setJobSearch={setJobSearch} />} ></Route>
              <Route path="/job/add" element={<CompanyProtected cmp={AddJob} />} > </Route>
              <Route path="/job/boost/:job_id" element={<CompanyProtected cmp={AddJobReferral} />} > </Route>
              <Route path="/job/edit/:job_id" element={<CompanyProtected cmp={AddJob} />} > </Route>

              <Route path="/gig/search" element={<GigSearch jobSearch={jobSearch} locationSearch={locationSearch} locationSearchName={locationSearchName} jobSearchName={setJobSearchName} searchType={searchType} keywordSearch={keywordSearch}
                showStickySearchBar={showStickySearchBar} setShowStickySearchBar={setShowStickySearchBar} isStickySearch={isStickySearch} setIsStickySearch={setIsStickySearch}
                setSearchFilterState={setSearchFilterState} keywordSeachFilters={keywordSeachFilters} setKeywordSeachFilters={setKeywordSeachFilters}

              />}></Route>
              <Route path="/gig/details/:id/:slug" element={<GigDetail />}></Route>
              <Route path="/gigs" element={<Protected cmp={ShowGigs} setJobSearch={setJobSearch} setLocationSearch={setLocationSearch} setLocationSearchName={setLocationSearchName} />}></Route>
              <Route path="/posted-gigs" element={<CompanyProtected cmp={PostedGigs} setJobSearch={setJobSearch} />} ></Route>
              <Route path='/gig/add' element={<CompanyProtected cmp={AddGig} />}></Route>
              <Route path="/gig/edit/:id" element={<CompanyProtected cmp={AddGig} />} > </Route>
              <Route path='/view-later' element={<CompanyProtected cmp={ViewLater} />}> </Route>
              <Route path="/chat" element={<Protected cmp={UserChat} />}></Route>
              <Route path="/workprofile/view" element={<UserProfile />} ></Route>
              <Route path="/workprofile/view/:user_id" element={<CompanyProtected cmp={UserProfileViewById} />} > </Route>

              <Route path="/company/dashboard" element={<CompanyProtected cmp={Dashboard} />} > </Route>
              <Route path="/company-dashboard" element={<CompanyAdminProtected cmp={CompanyDashboard} jobSearch={jobSearch} locationSearch={locationSearch} locationSearchName={locationSearchName} jobSearchName={setJobSearchName} searchType={searchType} setSearchType={setSearchType} setKeywordSearch={setKeywordSearch} keywordSearch={keywordSearch}
                showStickySearchBar={showStickySearchBar} setShowStickySearchBar={setShowStickySearchBar} isStickySearch={isStickySearch} setIsStickySearch={setIsStickySearch}
                setSearchFilterState={setSearchFilterState} keywordSeachFilters={keywordSeachFilters} setKeywordSeachFilters={setKeywordSeachFilters} />} > </Route>
              <Route path="/company/users" element={<CompanyProtected cmp={CompanyUsers} />} > </Route>
              <Route path="/company/edit" element={<CompanyAdminProtected cmp={AddEditCompany} />} > </Route>


              <Route path="/partner/view" element={<CompanyAdminProtected cmp={AddEditCompany} />} > </Route>
              <Route path="/partner/edit" element={<CompanyAdminProtected cmp={EditPartnerCompany} />} > </Route>
              <Route path="/partner/dashboard" element={<CompanyAdminProtected cmp={PartnerDashboard} />} > </Route>


              <Route path='/company-profile/:id' element={<CompanyProfile />}></Route>
              <Route path='/company/recruiter/:id' element={<RecruiterProfile />}></Route>

              <Route path='/chat' element={<UserChat />}></Route>

              <Route path="/chat" element={<Protected cmp={UserChat} />}></Route>

              <Route path="/candidate-appointment" element={<CandidateScheduleAppointment />} ></Route>
              <Route path="/get-evaluated" element={<BecomeProMain />} ></Route>
              <Route path="/Become-pro" element={<BecomePro />}></Route>
              <Route path="/Become-pro-evaluated" element={<BecomeProEvaluated />} ></Route>

              {/* Evaluator */}

              <Route
                path="/evaluator/dashboard"
                element={<EvaluatorProtected cmp={EvaluatorDashboard} />}
              ></Route>

              <Route path="/evaluator/calendar" element={<EvaluatorProtected cmp={Calendar} />}></Route>
              <Route path="/evaluator/interviews" element={<EvaluatorProtected cmp={EvaluatorInterview} />}></Route>
              <Route path="/evaluation/feedback/:id" element={<EvaluationFeedback />}></Route>
              <Route path="/candidate/resume" element={<ResumeBuilder />}></Route>
            </Routes>
          </div>
          <FooterUpper isMasterDataLoaded={isMasterDataLoaded} />
        </>}
    </div>
  );
}

export default App;
