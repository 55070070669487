/* eslint-disable */
import React, { useState } from "react";
import "./Modal.scss";
import * as toast from "@/wrapper/toast";
import AppModal from "./AppModal";
import { useEffect } from "react";
import * as ApiHelper from "@/services/apiHelper";
import Chart from "../Common/Chart";

function ModalFeaturedJobActivity({ showModal, setShowModal, jobId }) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    setLoading(true);
    await ApiHelper.getJobActivity(jobId).then((response) => {
      if (response.isSuccess === true) {
        setData(response.data);
      } else {
        toast.warn(response.message);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    if (jobId !== null && showModal) {
      getData();
    }
  }, [showModal]);

  return (
    <>
      <AppModal
        title={"Featured job activity"}
        isOpen={showModal}
        closeModal={() => setShowModal((prev) => !prev)}
        size={"lg"}
        isFooter={false}
      >
        <div className="modal_body" style={{ fontSize: "13px" }}>
          <div className="row">
            <Chart chartData={data} loading={loading} graphType="verticalBar" />
          </div>
        </div>
      </AppModal>
    </>
  );
}

export default ModalFeaturedJobActivity;
