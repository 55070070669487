import React from "react";
import "./Modal.scss";
import { useNavigate } from "react-router-dom";
import { createSlugFromString } from "@/utils/stringUtils";
import AppModal from "./AppModal";

function ModalApplyJobReferralInfo({ showModal, setShowModal, referredData }) {
  const navigate = useNavigate();
  const onSubmit = (e) => {
    navigate(
      "/job/details/" +
        referredData?.referral?.job_data?.eid +
        "/" +
        createSlugFromString(referredData?.referral?.job_data?.title)
    );
  };

  return (
    <>
      <AppModal
        isOpen={showModal}
        closeModal={() => {
          setShowModal((prev) => !prev);
        }}
        title="Apply for Job"
        size="md"
        submit={onSubmit}
        isFooter={true}
        submitText={"Apply"}
      >
        <div className="modal_body apply-referral">
          <p className="mb-3">
            {referredData?.referred_by_user?.full_name} has referred for an
            exciting job opportunity, that they believe will add value to your
            career. Please click below to apply.
          </p>
          <img
            className="my-3"
            src={referredData?.referral?.job_data?.company_details?.logo}
            alt="logo"
          />
          <h5>{referredData?.referral?.job_data?.title}</h5>
        </div>
      </AppModal>
    </>
  );
}

export default ModalApplyJobReferralInfo;
