import React, { useRef } from 'react'
import "./Chat.scss"
import * as ApiHelper from '@/services/apiHelper';
import * as toast from "@/wrapper/toast";
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import ModalUserChatBlock from '@Components/Modals/ModalUserChatBlock';
import ModalUserChatUnblock from '@Components/Modals/ModalChatUnblock';
import ModalUserChatMute from '@Components/Modals/ModalChatMute';
import ModalUserChatDelete from '@Components/Modals/ModalChatDelete';
import messageMediaDownload from '@/Assets/svgs/message-media-download.svg';
import { getUserId } from '@/utils/authUtil';
import { configureAbly, useChannel } from "@ably-labs/react-hooks";
import { updateHeaderMessageIcon } from '@/utils/messageUtil';
import { READ, UNREAD } from '@/constants/messageConstants';
import { isNotImageMedia, isDocMedia, isPdfMedia, isExcelMedia, isPptMedia, replaceURLs } from '@/utils/messageUtil';
import ModalMessageMediaPreview from '@Components/Modals/ModalMessageMediaPreview';
import { PuffLoader } from 'react-spinners';

import defaultAvatar from '@/Assets/svgs/default_avatar.svg';
import noMsgLeft from '@/Assets/svgs/no_msg_left.svg';
import noMsg from '@/Assets/svgs/no_msg.svg';
import { isCandidate } from '@/utils/authUtil';
import { createSlugFromString } from '@/utils/stringUtils';
import { ClearFileValues } from '@/utils/fileUtils';
import Button from "../_widgets/Button/Button";
import Text from "../_widgets/Text/Text"
const initalMessage = {
    candidate_id: '',
    recruiter_id: '',
    job_id: '',
    message: ''
}

configureAbly({ key: '-BcqxA.y_IXaQ:TcCx9KXYIZBL6QW5O2jN9jtZLhn_N4MvNRr1cP7WSOA' });

const UserChatLayout = ({ loader, chatUser, chatReciever, showModalReport, setShowModalReport, handleUpdate, showModalUnblock, setShowModalUnblock, selectedChat, chatSender, showModalMute, setShowModalMute, selectedChatMessages, showModalDelete, setShowModalDelete, handleDelete, modalPreview, setModalPreview, previewData, openChatBackOnMobile, getChatMessagePage, enterPressed, openChatOnMobile, setSelectedChat, setChatuser, setPreviewData, navigate, chatRef, inputFile, setInputFile, message, setMessage, fileRef, addChat, getChatSender, getChatReciever }) => {

    return (
        <>
            <div className='user_chat main-body'>
                <ModalUserChatBlock showModal={showModalReport} setShowModal={setShowModalReport} onSubmit={handleUpdate} />
                <ModalUserChatUnblock showModal={showModalUnblock} setShowModal={setShowModalUnblock} onSubmit={handleUpdate} />

                <ModalUserChatMute name={selectedChat && selectedChat[getChatSender(selectedChat)]?.first_name} showModal={showModalMute} setShowModal={setShowModalMute} onSubmit={handleUpdate} selectedChatMessages={selectedChatMessages?.data} />
                <div className="content tt-container">

                    <ModalUserChatDelete showModal={showModalDelete} setShowModal={setShowModalDelete} onSubmit={handleDelete} />
                    <ModalMessageMediaPreview showModal={modalPreview} setShowModal={setModalPreview} data={previewData} />
                    {
                        loader ?
                            <>
                                <div style={{ margin: "auto" }}>
                                    <PuffLoader
                                        color="#14BC9A"
                                        loading={loader}
                                        size={50}
                                        height={50}
                                        width={50}
                                        radios={50}
                                        aria-label="Loading Spinner"
                                        data-testid="loader"
                                    />
                                </div>
                            </>
                            :
                            <>
                                {
                                    chatUser && chatUser.length === 0 ?
                                        <div className='empty-msg-wrapper'>
                                            <div className='empty-msg-skeleton'>
                                                <img src={noMsgLeft} style={{ width: "100%", height: "73vh" }} />
                                            </div>
                                            <div className='empty-msg-content d-flex justify-content-center '>
                                                <div className="message_box empty-msg-content-box d-flex align-items-center  flex-column gap-2">
                                                    <img src={noMsg} style={{ width: "9em", height: "auto", marginRight: "auto", marginLeft: "auto", marginBottom: "2.5em", display: "block" }} />
                                                    <h3 className='text-center' style={{ fontSize: "1.5em" }}>No messages found</h3>
                                                    <p className='text-center' style={{ fontSize: "0.875em", color: "#00000080" }}>{isCandidate() ? "You can start a new conversation when a recruiter reaches out to you." : "Start a new conversation from the work profile of a candidate."}</p>
                                                    <Button buttonType="primary" text="Back to home" onClick={() => { navigate('/'); }} />
                                                </div>


                                            </div>


                                        </div>
                                        :
                                        <>
                                            <div id="left" className="left" >

                                                {
                                                    chatUser && chatUser.map((item, index) => {
                                                        return <div id="chat_profile" className={selectedChat?.id === item?.id ? "chat_profile active" : "chat_profile inactive"} style={{ display: "flex", width: "100%", alignItems: "center" }} onClick={() => { var tempArray = [...chatUser]; tempArray[index]['unseen_messages_count'] = 0; setChatuser(tempArray); setSelectedChat(item); openChatOnMobile(); }} >
                                                            <div className="icon">
                                                                <img style={{ width: "50px", height: "50px", borderRadius: "10px", objectFit: "cover" }} src={(item[getChatReciever(item)]?.avatar_location) ?? defaultAvatar} alt="" />
                                                            </div>
                                                            <div className="chat_content"  >
                                                                <div className="chat_username" style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <p style={{ fontWeight: 600, width: '50%' }}>{item[getChatReciever(item)]?.full_name}</p>
                                                                    <span style={{ fontSize: '0.875em', textAlign: "right", width: '50%', color: 'var(--gray8)' }}>{item?.last_message_at}</span>
                                                                </div>

                                                                <div className="chat_preview truncate-1" title={item?.last_message}>
                                                                    <p>{(item?.last_message === "") ? <i class="bi bi-image" style={{ fontStyle: 'normal' }}> Media</i> : ((item?.last_message.length < 30) ? item?.last_message : (item?.last_message?.substring(0, 30) + "..."))}</p>
                                                                    {
                                                                        item?.unseen_messages_count > 0 ? <div className="chat__unread__count">
                                                                            <p>{item?.unseen_messages_count}</p>
                                                                        </div> :
                                                                            item?.last_message_by === getUserId() ? (item?.is_last_message_blocked_message ? <i class="bi bi-check"></i> : <i class="bi bi-check-all"></i>) : null

                                                                    }
                                                                </div>

                                                            </div>
                                                        </div>
                                                    })
                                                }

                                            </div>

                                            <div id="right" className="right">
                                                <div className="message_box">
                                                    <div className="message_box__header">
                                                        <div style={{ display: 'flex', alignItems: "center" }}>
                                                            <i style={{ marginRight: "10px" }} class="bi bi-arrow-left chat-back-arrow" onClick={openChatBackOnMobile}></i>
                                                            <img src={(selectedChat && selectedChat[getChatReciever(selectedChat)]?.avatar_location) ?? defaultAvatar} alt="" style={{ width: '50px', height: "50px", borderRadius: "50%", objectFit: "cover" }} />
                                                            <div className="view-app-mob">
                                                                <h6 style={{ marginBottom: "0px", marginLeft: "10px" }}>{selectedChat && selectedChat[getChatReciever(selectedChat)]?.full_name}</h6>

                                                                {
                                                                    selectedChat?.job?.eid && <a className="hand-hover view-job-mob" style={{ color: 'var(--green)', textDecoration: "underline" }} onClick={() => window.open('/job/details/' + selectedChat?.job?.eid + '/' + createSlugFromString(selectedChat?.job?.title), '_blank')}>View Job Post</a>
                                                                }
                                                            </div>
                                                            {
                                                                selectedChat?.is_muted === '1' && <i style={{ marginLeft: "10px" }} class="bi bi-volume-mute-fill"></i>
                                                            }
                                                        </div>
                                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>

                                                            {
                                                                selectedChat?.job?.eid && <a className="hand-hover view-job-desk" style={{ color: 'var(--green)', textDecoration: "underline", fontSize: "0.8125em" }} onClick={() => window.open('/job/details/' + selectedChat?.job?.eid + '/' + createSlugFromString(selectedChat?.job?.title), '_blank')}>View Job Post</a>
                                                            }


                                                            <div className="dropdown" >
                                                                <button style={{ border: "1px solid var(--gray5)", marginLeft: "0.5rem", height: "40px", width: "40px", display: "flex", alignItems: "center", justifyContent: "center", background: "transparent", borderRadius: "8px" }} className="dropdown-toggle" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                                                    <i class="bi bi-three-dots" ></i>
                                                                </button>

                                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">

                                                                    <li onClick={() => { setShowModalDelete(true) }}><a className="dropdown-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"
                                                                        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" aria-current="page" >Delete Chat</a></li>

                                                                    {
                                                                        selectedChat?.is_muted === '1' ? <li onClick={() => { handleUpdate({ is_muted: 0 }) }} ><a className="dropdown-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"
                                                                            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" aria-current="page" >Unmute Notification</a></li>
                                                                            :
                                                                            <li onClick={() => { setShowModalMute(true) }} ><a className="dropdown-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"
                                                                                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" aria-current="page" >Mute Notification</a></li>

                                                                    }

                                                                    {
                                                                        selectedChat?.status === "block" ?
                                                                            <li onClick={() => { setShowModalUnblock(true) }}><a className="dropdown-item text-danger" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"
                                                                                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" aria-current="page" >Unblock User</a></li>
                                                                            :
                                                                            <li onClick={() => { setShowModalReport(true) }} ><a className="dropdown-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"
                                                                                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" aria-current="page" >Block & Report</a></li>
                                                                    }
                                                                </ul>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="message_box__body" ref={chatRef}>

                                                        {
                                                            selectedChatMessages?.next_page_url && <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
                                                                <button className='btn' style={{ backgroundColor: "var(--gray5)" }} onClick={() => getChatMessagePage(selectedChatMessages?.current_page + 1)}>Load More ...</button>
                                                            </div>
                                                        }
                                                        {
                                                            selectedChatMessages?.data && selectedChatMessages?.data?.map((item, index) => {
                                                                {
                                                                    {
                                                                        return item.sender_id === selectedChat[getChatSender(selectedChat)].id ? <div className="message me" >
                                                                            <div className="message__icon">
                                                                                <img src={(selectedChat && selectedChat[getChatSender(selectedChat)]?.avatar_location) ?? defaultAvatar} alt="" style={{ minWidth: "50px", width: "50px", height: "50px", borderRadius: "50%", border: 'solid 1px var(--gray5)', objectFit: "contain" }} />
                                                                            </div>
                                                                            <div className="message__content">
                                                                                <div className="message__profile">
                                                                                    <p>{selectedChat && selectedChat[getChatSender(selectedChat)]?.first_name}  {selectedChat && selectedChat[getChatSender(selectedChat)]?.last_name}</p> <span> {item?.message_time}</span>
                                                                                </div>
                                                                                {
                                                                                    item.medias.length > 0 ? item.medias.map(it => (<>
                                                                                        {
                                                                                            isNotImageMedia(it.type) ?
                                                                                                <div className='media-message hand-hover' style={{ marginLeft: "25%" }} onClick={(e) => { if (e.target.id !== 'download-img') { setPreviewData(it); setModalPreview(true); } }}>
                                                                                                    <div className='media-pdf-message'>
                                                                                                        <div className='media-pdf-icon'>
                                                                                                            {isDocMedia(it.type) ? <img className='media-message-img' src='./Assets/svgs/word.svg' alt="" /> :
                                                                                                                isPdfMedia(it.type) ? <img className='media-message-img' src='./Assets/svgs/pdf.svg' alt="" /> :
                                                                                                                    isExcelMedia(it.type) ? <img className='media-message-img' src='./Assets/svgs/excel.svg' alt="" /> :
                                                                                                                        isPptMedia(it.type) ? <img className='media-message-img' src='./Assets/svgs/ppt.svg' alt="" />
                                                                                                                            : null}


                                                                                                        </div>
                                                                                                        <div className='media-message-text'>

                                                                                                            <span>{it?.original_name} </span>
                                                                                                            {/* <p>{it?.size_mb} mb</p> */}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className='media-message-download'>

                                                                                                        <a href={it?.path}><img id="download-img" src={messageMediaDownload} alt="" /></a>

                                                                                                    </div>
                                                                                                </div>
                                                                                                :
                                                                                                <div className="message__content__image hand-hover" onClick={() => { setPreviewData(it); setModalPreview(true); }}><img src={it?.path} alt="" /></div>
                                                                                        }
                                                                                    </>)) : null
                                                                                }
                                                                                {
                                                                                    item.message && <div className="message__content__text">
                                                                                        <p style={{ wordBreak: "break-word" }} dangerouslySetInnerHTML={{ __html: replaceURLs(item.message) }}></p>
                                                                                    </div>
                                                                                }

                                                                            </div>
                                                                        </div> : <div className="message other" >
                                                                            <div className="message__icon">
                                                                                <img src={(selectedChat && selectedChat[getChatReciever(selectedChat)]?.avatar_location) ?? defaultAvatar} alt="" style={{ minWidth: "50px", width: "50px", height: "50px", borderRadius: "50%", border: 'solid 1px var(--gray5)', objectFit: "contain" }} />
                                                                            </div>
                                                                            <div className="message__content">
                                                                                <div className="message__profile">
                                                                                    <p>{selectedChat && selectedChat[getChatReciever(selectedChat)]?.first_name} {selectedChat && selectedChat[getChatReciever(selectedChat)]?.last_name}</p><span> {item?.message_time}</span>
                                                                                </div>

                                                                                {
                                                                                    item.medias.length > 0 ? item.medias.map(it => (<>
                                                                                        {
                                                                                            isNotImageMedia(it.type) ?
                                                                                                <div className='media-message row hand-hover' onClick={(e) => { if (e.target.id !== 'download-img-2') { setPreviewData(it); setModalPreview(true); } }}>
                                                                                                    <div className='col-2'>
                                                                                                        {isDocMedia(it.type) ? <img className='media-message-img' src='./Assets/svgs/word.svg' alt="" /> :
                                                                                                            isPdfMedia(it.type) ? <img className='media-message-img' src='./Assets/svgs/pdf.svg' alt="" /> :
                                                                                                                isExcelMedia(it.type) ? <img className='media-message-img' src='./Assets/svgs/excel.svg' alt="" /> :
                                                                                                                    isPptMedia(it.type) ? <img className='media-message-img' src='./Assets/svgs/ppt.svg' alt="" />
                                                                                                                        : null}


                                                                                                    </div>
                                                                                                    <div className='media-message-text col-8' >

                                                                                                        <span>{it?.original_name} </span>
                                                                                                        {/* <p>{it?.size_mb} mb</p> */}
                                                                                                    </div>
                                                                                                    <div className='col-2 media-message-download'>

                                                                                                        <a href={it?.path}><img id='download-img-2' src={messageMediaDownload} alt="" /></a>

                                                                                                    </div>
                                                                                                </div>
                                                                                                :
                                                                                                <div className="message__content__image hand-hover" onClick={() => { setPreviewData(it); setModalPreview(true); }}><img src={it?.path} alt="" /></div>
                                                                                        }
                                                                                    </>)) : null
                                                                                }
                                                                                {item.message ? <div className="message__content__text">
                                                                                    <p style={{ wordBreak: "break-word" }} dangerouslySetInnerHTML={{ __html: replaceURLs(item.message) }}></p>

                                                                                </div> : null}
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                }

                                                            })
                                                        }
                                                    </div>

                                                    {
                                                        selectedChat?.status === "block" ? <div style={{ displayP: "flex", backgroundColor: '#F3F4F9', textAlign: "center", padding: '5px' }}>
                                                            {
                                                                selectedChat?.is_get_blocked === false ? <p style={{ fontWeight: 500 }}>You have blocked this user. <a onClick={() => setShowModalUnblock(true)} style={{ color: 'var(--green)', textDecoration: "underline", cursor: 'pointer' }}>Click here</a> to unblock.</p> : <p style={{ fontWeight: 500 }}>You have been blocked</p>
                                                            }
                                                        </div>
                                                            :
                                                            <>
                                                                <div className="message_box__footer flex-col">
                                                                    <div className="flex">

                                                                        {inputFile !== null && Object.keys(inputFile).map((key, index) => {
                                                                            return <div className="message__content__image" style={{ position: "relative", marginBottom: "10px" }}>
                                                                                {
                                                                                    inputFile[key].type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ? <img style={{ height: '100px', width: "100px", objectFit: "cover" }} src="./Assets/svgs/excel.svg" alt="" /> :
                                                                                        inputFile[key].type === "application/pdf" ? <img style={{ height: '100px', width: "100px", objectFit: "cover" }} src="./Assets/svgs/pdf.svg" alt="" /> :
                                                                                            inputFile[key].type === "application/msword" || inputFile[key].type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? <img style={{ height: '100px', width: "100px", objectFit: "cover" }} src="./Assets/svgs/word.svg" alt="" /> :
                                                                                                inputFile[key].type === "application/vnd.ms-powerpoint" ? <img style={{ height: '100px', width: "100px", objectFit: "cover" }} src="./Assets/svgs/ppt.svg" alt="" /> :
                                                                                                    <img style={{ height: '100px', width: "100px", objectFit: "cover" }} src={URL.createObjectURL(inputFile[key])} alt="" />
                                                                                }

                                                                                <div className="div" style={{ right: -5, top: -10, position: 'absolute', cursor: "pointer" }} onClick={() => { ClearFileValues(); setInputFile({}); }}>
                                                                                    <i class="bi bi-x-circle-fill"></i>
                                                                                </div>
                                                                            </div>

                                                                        })}
                                                                    </div>

                                                                    {
                                                                        parseInt(selectedChat?.recruiter_id) === parseInt(process.env.REACT_APP_TT_ADMIN_ID) ? <Text text="This is a read-only notification" />
                                                                            :
                                                                            <div className="d-flex flex-row w-100 justify-content-between">
                                                                                <input type="text"
                                                                                    value={message?.message}
                                                                                    placeholder='Type to add your message'
                                                                                    onKeyPress={enterPressed}
                                                                                    onChange={(e) => setMessage(prev => ({ ...prev, 'message': e.target.value }))} />
                                                                                <div className="send__action">
                                                                                    {/* <i class="bi bi-emoji-smile"></i> */}

                                                                                    <i class="bi bi-plus-lg" style={{ position: "relative", display: "flex", cursor: "pointer" }} onClick={() => {
                                                                                        fileRef.current.click()
                                                                                    }}>

                                                                                    </i>

                                                                                    <input ref={fileRef}
                                                                                        accept="image/*, .doc, .pdf, .docx, .ppt, .pptx, .xls, .xlsx"
                                                                                        type="file"
                                                                                        style={{ visibility: "hidden", width: "1px", position: 'absolute' }}
                                                                                        onChange={(e) => {
                                                                                            setInputFile(e.target.files);
                                                                                        }} />

                                                                                    <button onClick={() => addChat()}>
                                                                                        <i class="bi bi-send"></i>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                    }
                                                                </div>
                                                            </>
                                                    }

                                                </div>
                                            </div>
                                        </>
                                }
                            </>
                    }

                </div>

            </div >

        </>
    )
}

export default UserChatLayout