import React from 'react';
import './CompanyProfile.scss';
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";

import { isNullOrEmpty, getYoutubeVideoId } from '@/utils/stringUtils';
import * as commonServices from '@/services/common';
import defaultAvatar from '@/Assets/svgs/default_avatar.svg';
import Card from '../Common/Card';
import { createSlugFromString } from '@/utils/stringUtils';
import { textCapitalise } from "@/utils/stringUtils";
import emptyResult from '@/Assets/svgs/empty_result.svg';
import PartnerCompanyProfile from '../PartnerCompanyProfile/PartnerCompanyProfile';
import Tabs from '../_widgets/Tabs/Tabs';
import Profile from './TabContent/Profile';
import Jobs from './TabContent/Jobs';
import Gigs from './TabContent/Gigs';
import Recruiters from './TabContent/Recruiters';
import Text from '../_widgets/Text/Text';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: var(--green);
  left: calc(50% - 75px);
    position: fixed;
`;

const CompanyProfileLayout = ({
    loading,
    color,
    profile,
    currentTab,
    recruiterProfile,
    tabsData
}) => {

    return (
        <>
            {loading ?
                <div className='loader'>
                    <ClipLoader color={color} loading={true} css={override} size={150} /> </div> : null}
            {profile?.type === 'partner' ?
                <PartnerCompanyProfile company={profile} />
                :

                (
                    profile?.type === 'hiring-company' ?
                        <div className='company-profile tt-container main-body'>
                            <div className="banner" style={{ backgroundImage: `url(${profile?.cover_pic})`, height: '200px', border: 'solid 1px var(--gray)', borderTopLeftRadius: '16px', borderTopRightRadius: "16px", position: 'relative' }}>
                                {/* <img style={{ height: '100%', width: '100%' }} src={profile?.cover_pic} ></img> */}
                                <div className='profile_img img-border' ><img style={{ objectFit: 'cover', objectPosition: 'center', }} width={100} onError={commonServices.imgError} src={profile?.logo ?? defaultAvatar} alt="" /></div>
                                {/* <span className='setDeleteClass'><DeleteIcon className='deleteIcon' /></span> */}
                            </div>

                            <div className="tabs list-group" id="list-tab" role="tablist">
                                <Tabs tabs={tabsData} />
                            </div>

                            <div className="tab-content" id="nav-tabContent">
                                {currentTab === 'profile' ?
                                    <div className="tab__pane tab_1 d-flex gap-3 fade show active" id='list-home' role="tabpanel" aria-labelledby="list-home-list">
                                        <Profile Text={Text} profile={profile} />
                                    </div>
                                    :
                                    currentTab === 'jobs' ?
                                        <div className="tab__pane tab_2 d-flex gap-3 fade show" id='list-jobs' role="tabpanel" aria-labelledby="list-jobs-list">
                                            <Jobs Text={Text} profile={profile} />
                                        </div>
                                        :
                                        currentTab === 'gigs' ?
                                            <div className="tab__pane tab_2 d-flex gap-3 fade show" id='list-gigs' role="tabpanel" aria-labelledby="list-gigs-list">
                                                <Gigs Text={Text} profile={profile} />
                                            </div>
                                            :
                                            <div className="tab__pane tab_2 d-flex gap-3 fade show" id='list-recruiters' role="tabpanel" aria-labelledby="list-recruiters-list">
                                                <Recruiters Text={Text} profile={profile} recruiterProfile={recruiterProfile} />
                                            </div>
                                }
                            </div>

                        </div>
                        : null
                )

            }

        </>
    )
}

export default CompanyProfileLayout