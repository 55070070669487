/* eslint-disable */
import React, { useState, useEffect } from 'react';
import * as commonServices from '@/services/common';
import emptyResult from '@/Assets/svgs/empty_result.svg';
import Table from '../../_widgets/Table/Table';
import { dateFormat } from '../../../services/common';
import { useNavigate } from 'react-router-dom';
import { createSlugFromString } from '@/utils/stringUtils';

function AppliedGigs({ appliedGigs, activePage, setActivePage, handlePaginationChange }) {
    const navigate = useNavigate();
    var appliedGigsColumns = ['Gig ID', 'Gig', 'Company', 'Gig Type', 'Skills', 'Location', 'Date Applied'];
    const [appliedGigsTableData, setAppliedGigsTableData] = useState([]);
    useEffect(() => {
        var row = [];
        var row = appliedGigs?.data && appliedGigs?.data.map((gig, i) => {
            var data = [];
            data.push("J0000" + gig.eid);
            data.push(gig.title);
            data.push(gig.company_name);
            data.push(gig.type.name);
            data.push(gig.job_skills !== null ? gig.job_skills.split(',').join(', ') : gig.job_skills);
            data.push(gig.gig_locations);
            data.push(dateFormat(gig.applied_at));
            return {
                rowClick: (data) => { navigate('/gig/details/' + data['eid'] + '/' + createSlugFromString(data['title'])) },
                rowClickData: {
                    eid: gig?.eid,
                    title: gig?.title
                },
                data: data
            }
        }

        );

        setAppliedGigsTableData(row)

    }, [appliedGigs])
    return (
        <>
            {appliedGigs?.data && appliedGigs?.data.length > 0 ?
                (
                    <div className='common_items'>
                        <Table columns={appliedGigsColumns} data={appliedGigsTableData} activePage={activePage} setActivePage={setActivePage} handlePaginationChange={handlePaginationChange} pagination={appliedGigs} />
                    </div>)
                : <div className='no_jobs' ><img onError={commonServices.imgError} src={emptyResult} alt="" /><h2>No applied gig found!</h2>
                </div>}
        </>
    )
}

export default AppliedGigs