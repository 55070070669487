import unreadMessagesIcon from "../Assets/svgs/unread-messages.svg";
import messageIcon from "../Assets/svgs/message.svg";
import { UNREAD } from "../constants/messageConstants";


export function updateHeaderMessageIcon(iconType) {
    document.getElementById("message-icon").src = iconType === UNREAD ? unreadMessagesIcon : messageIcon;
}

export function isNotImageMedia(type) {
    var mediaArray = ['doc', 'docx', 'pdf', 'xlsx', 'xls', 'pptx', 'ppt'];
    return mediaArray.includes(type);
}

export function isDocMedia(type) {
    var mediaArray = ['doc', 'docx'];
    return mediaArray.includes(type);
}

export function isPdfMedia(type) {
    var mediaArray = ['pdf'];
    return mediaArray.includes(type);
}

export function isExcelMedia(type) {
    var mediaArray = ['xlsx', 'xls'];
    return mediaArray.includes(type);
}

export function isPptMedia(type) {
    var mediaArray = ['pptx', 'ppt'];
    return mediaArray.includes(type);
}

export function replaceURLs(message) {
    if (!message) return;

    var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    return message.replace(urlRegex, function (url) {
        var hyperlink = url;
        if (!hyperlink.match('^https?:\/\/')) { /* eslint-disable-line */
            hyperlink = 'http://' + hyperlink;
        }
        return '<a href="' + hyperlink + '" target="_blank" rel="noopener noreferrer">' + url + '</a>'
    });
}
