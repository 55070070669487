import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as ApiHelper from "@/services/apiHelper";
import { BsArrowLeft } from "react-icons/bs";
import { FeedbackForm, ChecklistForm } from "./FeedbackForm";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";
import { saveEvaluationFeedback } from "@/services/apiHelper";
import * as toast from "@/wrapper/toast";
import { isEvaluator } from "@/utils/authUtil";
import NoDataView from "../NoDataView/NoDataView";
import { changeDateFormat, dateTime, nowDateTime } from "@/utils/dateUtil";
import Button from "../_widgets/Button/Button";
import Text from "../_widgets/Text/Text";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: var(--green);
  left: calc(50% - 75px);
  position: fixed;
`;

const EvaluationFeedback = () => {
  let params = useParams();
  const meeting_id = params.id;
  let navigate = useNavigate();
  const [formState, setFormState] = React.useState("feedback"); // "feedback" | "checklist

  const [isClosed, setIsClosed] = React.useState(true);
  let [loading, setLoading] = useState(false);
  let [isFeedbackDisabled, setIsFeedbackDisabled] = useState(true);
  let [color] = useState("#000000");
  let [meetingDetails, setMeetingDetails] = useState(null);
  let [questions, setQuestions] = useState([]);
  let [answers, setAnswers] = useState([]);

  const [coreSkillQuestions, setCoreSkillQuestions] = useState([]);
  const [overAllFeedback, setOverAllFeedback] = useState("");
  const [softSkillQuestions, setSoftSkillQuestions] = useState([]);

  const interviewDetails = async () => {
    setLoading(true);
    await ApiHelper.getMeetingDetails(meeting_id).then((response) => {
      setLoading(false);
      if (response?.isSuccess) {
        setMeetingDetails(response?.data?.interview);

        let meetingEndTime = response?.data?.interview?.meeting_to_time;
        if (nowDateTime() >= dateTime(24, meetingEndTime)) {
          setIsFeedbackDisabled(true);
        } else {
          setIsFeedbackDisabled(false);
        }
        let allQuestions = response?.data?.questions;
        let allAnswers = response?.data?.answers;
        initializeAnswers(allQuestions, allAnswers);
        setQuestions(allQuestions);

        let tempCoreSkills = response?.data?.interview.skills?.map((skill) => {
          return {
            question_id: null,
            skill_id: skill?.id,
            question_name: skill?.name,
            feedback: "",
            rating: 0,
            type: "skill",
          };
        });

        let tempSoftSKills = allQuestions?.question?.map((question, index) => {
          return {
            question_id: question.id,
            question_name: question?.question,
            feedback: "",
            rating: 0,
            type: "skill",
          };
        });

        if (response?.data?.answers) {
          const answers = response?.data?.answers;

          tempCoreSkills.forEach((skill) => {
            answers.forEach((answer) => {
              if (skill.skill_id === answer.skill_id) {
                skill.question_id = answer.question_id;
                skill.feedback = answer.feedback;
                skill.rating = answer.rating;
              }
            });
          });

          console.log("tempCoreSkills-->", tempCoreSkills);

          tempSoftSKills.forEach((skill) => {
            answers.forEach((answer) => {
              if (skill.question_id === answer.question_id) {
                skill.feedback = answer.feedback;
                skill.rating = answer.rating;
              }
            });
          });

          const answersSoftSkills = answers.filter((answer) => {
            if (!answer?.question_id && !answer?.skill_id) {
              return {
                question_id: answer?.question_id,
                skill_id: answer?.skill_id,
                question_name: answer?.question_name,
                feedback: answer?.feedback,
                rating: answer?.rating,
                type: "skill_added",
              };
            }
          });

          const answersCoreSkills = answers.filter((answer) => {
            if (
              answer.skill_id !== null &&
              !tempCoreSkills.find((item) => item.skill_id === answer.skill_id)
            ) {
              return {
                question_id: answer?.question_id,
                skill_id: answer?.skill_id,
                question_name: answer?.question_name,
                feedback: answer?.feedback,
                rating: answer?.rating,
                type: "skill_added",
              };
            }
          });

          tempSoftSKills = [...tempSoftSKills, ...answersSoftSkills];
          tempCoreSkills = [...tempCoreSkills, ...answersCoreSkills];
        }

        setCoreSkillQuestions(tempCoreSkills);
        setSoftSkillQuestions(tempSoftSKills);
        setOverAllFeedback(response?.data?.interview?.overall_feedback || "");
      }
    });
  };

  const initializeAnswers = (allQuestions, allAnswers) => {
    let answersArray = [];
    if (
      allQuestions?.checklist !== undefined &&
      allQuestions?.checklist.length > 0
    ) {
      allQuestions.checklist.map((question, i) => {
        let is_checked = [];
        is_checked.push({
          is_checked: "0",
        });
        if (allAnswers.length > 0) {
          is_checked = allAnswers.filter((answer) => {
            console.log("answer-->", answer);
            if (answer.question_id === question?.id) {
              return answer.is_checked;
            }
          });
        }
        let obj = {
          question_id: question?.id,
          // "is_checked": is_checked[0].is_checked
        };
        answersArray.push(obj);
      });
    }
    if (
      allQuestions?.question !== undefined &&
      allQuestions?.question.length > 0
    ) {
      allQuestions.question.map((question, i) => {
        let result = [];
        result.push({
          feedback: "",
          rating: 0,
        });
        if (allAnswers.length > 0) {
          result = allAnswers.filter((answer) => {
            if (answer.question_id === question?.id) {
              return answer;
            }
          });
        }
        let obj = {
          question_id: question?.id,
          question_name: question?.question_name,
          skill_id: question?.skill_id,
          // "feedback": result[0].feedback,
          // "rating": result[0].rating
        };
        answersArray.push(obj);
      });
    }
    setAnswers(answersArray);
  };

  useEffect(() => {
    interviewDetails();
  }, []);

  const getAnswerOfQuestion = (question_id) => {
    let result = answers.filter((answer) => {
      if (answer.question_id === question_id) {
        return answer;
      }
    });

    return result.length > 0 ? result[0] : null;
  };

  const setAnswerOfChecklistQuestion = (e, question_id) => {
    let answersArray = [...answers];

    answersArray.forEach((answer) => {
      if (answer.question_id === question_id) {
        let isChecked = e.target.checked;
        if (isChecked) {
          answer.is_checked = "1";
        } else {
          answer.is_checked = "0";
        }
      }
    });
    setAnswers(answersArray);
  };

  const setAnswerOfQuestion = (data, question_id, key) => {
    let answersArray = [...answers];

    answersArray.forEach((answer) => {
      if (answer.question_id === question_id) {
        answer[key] = data;
      }
    });
    setAnswers(answersArray);
  };

  const saveFeedback = async (status = "") => {
    try {
      let reqObj = {
        evaluation_interview_id: meeting_id,
        feedbacks: [...coreSkillQuestions, ...softSkillQuestions],
        status: status,
        overall_feedback: overAllFeedback, //overall feedback
      };
      const res = await saveEvaluationFeedback(reqObj);
      if (res.isSuccess) {
        toast.success(res?.message);
        if (status === "completed") {
          navigate("/evaluator/interviews");
        }
      } else {
        toast.warn(res?.message);
      }
    } catch (error) {
      console.log(error);
      toast.warn(error);
    } finally {
      // setLoading(false)
    }
  };

  return (
    <>
      {/* <MaxWidthContainer> */}
      {loading ? (
        <div className="loader">
          <ClipLoader color={color} loading={true} css={override} size={150} />{" "}
        </div>
      ) : null}
      <div style={{ height: "100vh", paddingTop: "3rem" }}>
        <div className="h-100 d-flex align-items-center">
          {dateTime(1) <=
          changeDateFormat(
            meetingDetails?.meeting_to_time,
            "YYYY-MM-DD HH:mm:ss"
          ) ? (
            <div
              style={{
                width: "100%",
                height: "100%",
                minHeight: "50vh",
                backgroundColor: "#e6e6e6",
              }}
            >
              <iframe
                height={"100%"}
                width={"100%"}
                src={meetingDetails?.meeting_link}
              />
            </div>
          ) : (
            <NoDataView text="Interview Completed" />
          )}
          {isEvaluator() ? (
            <div
              style={{
                paddingTop: "1.5rem", // py: [6, 10] => 6 * 0.25rem = 1.5rem, 10 * 0.25rem = 2.5rem
                margin: "0 !important",
                marginRight: "0",
                position: "relative",
                display: "flex",
                flexDirection: ["column", "row"],
                gap: "0",
                justifyContent: "center",
              }}
              className="test-2 h-100"
            >
              {/* Button Group  */}
              <div
                style={{
                  top: "50%",
                  zIndex: "100",
                  paddingTop: "0.75rem", // pt: [3, 0] => 3 * 0.25rem = 0.75rem
                  width: "auto",
                  margin: "auto",
                  height: "fit-content",
                  display: "flex",
                  position: "absolute",
                  left: "-195px",
                  gap: "10px",
                  justifyContent: "center",
                  transform: "rotate(-90deg)", // Convert to inline style
                }}
              >
                <Button
                  buttonType={
                    formState === "feedback" ? "primary" : "secondary"
                  }
                  className={
                    formState === "feedback"
                      ? "btn tt-btn__primary big-b"
                      : "btn tt-btn__secondary big-b"
                  }
                  onClick={() => {
                    setFormState("feedback");
                    setIsClosed(true);
                  }}
                  text="Feedback"
                ></Button>
                <Button
                  buttonType={
                    formState === "checklist" ? "primary" : "secondary"
                  }
                  className={
                    formState === "checklist"
                      ? "btn tt-btn__primary big-b"
                      : "btn tt-btn__secondary big-b"
                  }
                  onClick={() => {
                    setFormState("checklist");
                    setIsClosed(true);
                  }}
                  text="Checklist"
                ></Button>
              </div>

              <div
                style={{
                  maxWidth: isClosed ? "40vw" : null,
                  width: isClosed ? "40vw" : "0vw",
                  position: "relative",
                  backgroundColor: "#fbfbfb",
                  zIndex: 200,
                  padding: "1rem",
                  overflowY: "scroll",
                }}
              >
                <div
                  className="testing"
                  style={{
                    zIndex: 10,
                    width: "100%",
                    position: "relative !important",
                    transition: "all 0.4s ease",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#fbfbfb",
                      zIndex: 200,
                      margin: 0,
                      minHeight: ["auto", "100vh"],
                      maxHeight: ["auto", "100vh"],
                      overflowY: "scroll",
                      position: "relative",
                      paddingTop: ["2rem", "3rem"], // py: [8, 12] => 8 * 0.25rem = 2rem, 12 * 0.25rem = 3rem
                      paddingRight: ["1rem", "2rem"], // px: [4, 8] => 4 * 0.25rem = 1rem, 8 * 0.25rem = 2rem
                      paddingBottom: ["2rem", "3rem"], // py: [8, 12] => 8 * 0.25rem = 2rem, 12 * 0.25rem = 3rem
                      paddingLeft: ["1rem", "2rem"], // px: [4, 8] => 4 * 0.25rem = 1rem, 8 * 0.25rem = 2rem
                    }}
                  >
                    <div className="d-flex align-items-center gap-2">
                      <Button
                        buttonType="light"
                        variant="ghost"
                        aria-label="Search database"
                        buttonIcon={<BsArrowLeft color="#000" size={20} />}
                        padding={0}
                        onClick={() => setIsClosed(!isClosed)}
                      />

                      <Text
                        type="h2"
                        text="Evaluation Feedback"
                        fontSize="lg"
                        fontWeight={500}
                      />
                    </div>

                    {formState === "feedback" ? (
                      <FeedbackForm
                        questions={questions}
                        meetingDetails={meetingDetails}
                        getAnswerOfQuestion={getAnswerOfQuestion}
                        setAnswerOfQuestion={setAnswerOfQuestion}
                        saveFeedback={saveFeedback}
                        isFeedbackDisabled={isFeedbackDisabled}
                        skillQuestions={coreSkillQuestions}
                        setSkillQuestions={setCoreSkillQuestions}
                        softSkillQuestions={softSkillQuestions}
                        setSoftSkillQuestions={setSoftSkillQuestions}
                        overAllFeedback={overAllFeedback}
                        setOverAllFeedback={setOverAllFeedback}
                      />
                    ) : (
                      <ChecklistForm
                        questions={questions}
                        getAnswerOfQuestion={getAnswerOfQuestion}
                        setAnswerOfChecklistQuestion={
                          setAnswerOfChecklistQuestion
                        }
                        isFeedbackDisabled={isFeedbackDisabled}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {/* </MaxWidthContainer> */}
    </>
  );
};

export default EvaluationFeedback;
