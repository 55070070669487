import React, { useEffect, useState } from "react";
import * as commonServices from "@/services/common";
import { MaxWidthContainer } from "@/components/Common/MaxWidthContainer";
import trustedImg from "../../Assets/svgs/trusted.svg";
import resultsImg from "../../Assets/svgs/results.svg";
import no1Img from "../../Assets/svgs/no1.svg";
import globeImg from "../../Assets/svgs/globe.svg";
import editImg from "../../Assets/svgs/edit.svg";
import macImg1 from "../../Assets/images/device-mac-1.gif";
import macImg2 from "../../Assets/images/device-mac-2.gif";
import "./CompanyDashboard.scss";
import { useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";
import {
  getSkillsString,
  highlightText,
  textCapitalise,
} from "../../utils/stringUtils";
import {
  addCandidateToSaveLater,
  currencyFormatter,
  getBarColor,
  getCandidateExperienceText,
  getPercentage,
} from "../../services/common";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import mapPin from "@/Assets/svgs/map-pin.svg";
import ButtonOutlined from "../Common/ButtonOutlined";
import FilterBar from "../_widgets/FIlterBar/FilterBar";
import IconResource from "../_widgets/IconResource/IconResource";
import AppModal from "../Modals/AppModal";
import Button from "../_widgets/Button/Button";
import Table from "../_widgets/Table/Table";
import DatePickerWrapper from "../Common/DatePicker";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Text from "../_widgets/Text/Text";
import ModalShowSimilarCandidates from "../Modals/ModalShowSimilarCandidates";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: var(--green);
  left: calc(50% - 75px);
  position: fixed;
`;

function CompanyDashboardLayout({
  data,
  loading,
  candidateProfile,
  isShowPercentage = false,
  FindWork,
  keywordSeachFilters,
  setKeywordSeachFilters,
  searchedLocations,
  setSearchedLocations,
  searchedSkills,
  setSearchedSkills,
  searchType,
  setSearchType,
  keyword,
  setKeyword,
  showReportsModal,
  setShowReportsModal,
  downloadReport,
  startDate,
  endDate,
  handleDateChange,
  resetDates,
  getFirstDayOfMonth,
  getToday,
  setLoading,
  selectedCriterias, setSelectedCriterias, showSimilarCandidatesModal, setShowSimilarCandidatesModal, isSimilarCriteriaChecked, showSimilarSearch
}) {
  const navigate = useNavigate();
  let [color] = useState("#000000");

  const reportsData = [
    {
      data: [
        "Transactions",
        <Button
          buttonType="primary"
          text="Download"
          onClick={() =>
            downloadReport(
              "transactions",
              startDate.toISOString().split("T")[0],
              endDate.toISOString().split("T")[0]
            )
          }
        />,
      ],
    },
    {
      data: [
        "Jobs Status",
        <Button
          buttonType="primary"
          text="Download"
          onClick={() =>
            downloadReport(
              "job-status",
              startDate.toISOString().split("T")[0],
              endDate.toISOString().split("T")[0]
            )
          }
        />,
      ],
    },
    {
      data: [
        "Gigs Status",
        <Button
          buttonType="primary"
          text="Download"
          onClick={() =>
            downloadReport(
              "gig-status",
              startDate.toISOString().split("T")[0],
              endDate.toISOString().split("T")[0]
            )
          }
        />,
      ],
    },
    {
      data: [
        "Featured jobs - Impression",
        <Button
          buttonType="primary"
          text="Download"
          onClick={() =>
            downloadReport(
              "featured-job-impressions",
              startDate.toISOString().split("T")[0],
              endDate.toISOString().split("T")[0]
            )
          }
        />,
      ],
    },
  ];
  const renderTooltip = (type) => {
    let tooltipContent;

    switch (type) {
      case "evaluated":
        tooltipContent = "Evaluated";
        break;
      case "certified":
        tooltipContent = "Certified";
        break;
      case "trained":
        tooltipContent = "Trained";
        break;
      // Add more cases for different types if needed
      default:
        tooltipContent = "Default Tooltip";
    }

    return <Tooltip id="button-tooltip">{tooltipContent}</Tooltip>;
  };

  return (
    <MaxWidthContainer pageKey="company-dashboard" className="main-body">

      {/* View-similar Candidates Modal */}
      <ModalShowSimilarCandidates
        showSimilarCandidatesModal={showSimilarCandidatesModal}
        setShowSimilarCandidatesModal={setShowSimilarCandidatesModal}
        showSimilarSearch={showSimilarSearch}
        setSelectedCriterias={setSelectedCriterias}
        selectedCriterias={selectedCriterias}
        isSimilarCriteriaChecked={isSimilarCriteriaChecked}
      />

      <div className="company_dashboard_page">
        {loading ? (
          <div className="loader">
            <ClipLoader
              color={color}
              loading={true}
              css={override}
              size={150}
            />{" "}
          </div>
        ) : null}

        <div className="">
          <div className="tt-container">
            <FilterBar
              searchedSkills={searchedSkills}
              setSearchedSkills={(_, data) => setSearchedSkills(data)}
              searchedLocations={searchedLocations}
              setSearchedLocations={(_, data) => setSearchedLocations(data)}
              FindWork={FindWork}
              searchType={searchType}
              setSearchType={setSearchType}
              keyword={keyword}
              setKeyword={setKeyword}
              searchBar={"normal"}
              module={"Candidates"}
              keywordSeachFilters={keywordSeachFilters}
              setKeywordSeachFilters={setKeywordSeachFilters}
            />
          </div>
          <div className="tt-container px-0 row">
            <div className="col-lg-9 col-md-12 col-sm-12">
              <h3 className="topTitle">
                Newest <span style={{ color: "var(--green)" }}>candidates</span>{" "}
                for you
              </h3>
              <div
                className="mt-3 mt-md-4 cards__container"
                style={{
                  gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
                }}
              >
                {data?.candidates !== undefined &&
                  data?.candidates.map((data, key) => (
                    <div>
                      <div className="view-similar">
                        {data?.is_pro_user && (
                          <div
                            style={{
                              position: "absolute",
                              backgroundColor: "var(--green)", // bg: "var(--green)"
                              width: "80px", // w: "80px"
                              transform:
                                "rotate(-45deg) translateX(-22%) translateY(-80%)", // transform: "rotate(-45deg) translateX(-22%) translateY(-80%)"
                              padding: "0.25rem", // p: 1 * 0.25 = 0.25rem
                              paddingTop: "0.5rem", // pt: 2 * 0.25 = 0.5rem
                              display: "flex", // display: "flex"
                              alignItems: "center", // alignItems: "center"
                              justifyContent: "center", // justifyContent: "center"
                              left: "0", // left: 0
                              top: "0", // top: 0
                            }}
                          >
                            <Text
                              text="PRO"
                              style={{
                                color: "white",
                                fontSize: "10px",
                                fontWeight: "bold",
                                "!important": true, // This won't actually work, just for demonstration
                              }}
                            />
                          </div>
                        )}

                        <div
                          className="feature_content hand-hover "
                          onClick={() => candidateProfile(data.id)}
                        >
                          <div className="candidate-card-head">
                            <div className="candidate-card-basic">
                              <p
                                className="paragraph_1 truncate-1"
                                title={textCapitalise(data.full_name)}
                                dangerouslySetInnerHTML={{
                                  __html: highlightText(
                                    keyword,
                                    textCapitalise(data.full_name)
                                  ),
                                }}
                              ></p>
                              <p className="paragraph_3">
                                Exp:{" "}
                                {getCandidateExperienceText(
                                  data.total_experience
                                )}
                              </p>
                            </div>
                            {isShowPercentage ? (
                              <div class="circle-rating">
                                <CircularProgressbar
                                  value={getPercentage(data.profile_percentage)}
                                  text={`${getPercentage(
                                    data.profile_percentage
                                  )}%`}
                                  styles={buildStyles({
                                    textColor: "#263238",
                                    pathColor: getBarColor(
                                      getPercentage(data.profile_percentage)
                                    ),
                                    trailColor: "#E3E3E3",
                                  })}
                                />
                              </div>
                            ) : null}
                          </div>
                          <p
                            className="paragraph_3 d-flex gap-1"
                            style={{
                              color: "#4B8BFF",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {" "}
                            <img
                              style={{ height: "12px" }}
                              src={mapPin}
                              alt=""
                            />{" "}
                            <span
                              className="truncate-1"
                              title={"Current Location"}
                            >
                              {data?.location_name ? data?.location_name : "NA"}
                            </span>
                          </p>
                          <p
                            className="paragraph_3 truncate-2 trunc-desc mt-1"
                            style={{
                              minHeight: "40px",
                              height: "40px",
                              maxHeight: "40px",
                              fontSize: "var(--p1)",
                            }}
                            title={
                              data.skills !== null
                                ? getSkillsString(data.skills)
                                : "N/A"
                            }
                            dangerouslySetInnerHTML={{
                              __html: highlightText(
                                keyword,
                                textCapitalise(
                                  data.skills !== null
                                    ? getSkillsString(data.skills)
                                    : "N/A"
                                )
                              ),
                            }}
                          ></p>

                          <div
                            className="d-flex mt-2 justify-content-between"
                            mt={2}
                            justifyContent={"space-between"}
                          >
                            <div style={{ display: "flex", gap: "10px" }}>
                              {data?.is_evaluated && (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={renderTooltip("evaluated")}
                                >
                                  <div
                                    style={{
                                      border: "solid 1px green",
                                      width: "20px",
                                      height: "20px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    <span style={{ color: "var(--green)" }}>
                                      E
                                    </span>
                                  </div>
                                </OverlayTrigger>
                              )}

                              {data?.is_certified && (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={renderTooltip("certified")}
                                >
                                  <div
                                    style={{
                                      border: "solid 1px green",
                                      width: "20px",
                                      height: "20px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    <span style={{ color: "var(--green)" }}>
                                      C
                                    </span>
                                  </div>
                                </OverlayTrigger>
                              )}

                              {data?.is_trained && (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={renderTooltip("trained")}
                                >
                                  <div
                                    style={{
                                      border: "solid 1px green",
                                      width: "20px",
                                      height: "20px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    <span style={{ color: "var(--green)" }}>
                                      T
                                    </span>
                                  </div>
                                </OverlayTrigger>
                              )}
                            </div>

                            <div
                              className="feature_content_tags d-flex"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                minHeight: "30px",
                                maxHeight: "30px",
                              }}
                            >
                              {data?.is_profile_viewed_by_user ? (
                                <p>
                                  <IconResource
                                    type="doubletick"
                                    className="green-done"
                                  />
                                </p>
                              ) : data?.is_profile_viewed_by_company_user ? (
                                <p>
                                  <IconResource
                                    type="doubletick"
                                    className="done"
                                  />
                                </p>
                              ) : null}
                              <p></p>
                              <p
                                style={{ color: "#9398A1" }}
                                className="paragraph_4 truncate-1"
                                title={commonServices.dateFormat(
                                  data.updated_at
                                )}
                              >
                                {commonServices.dateFormat(data.updated_at)}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div
                          onClick={() => setShowSimilarCandidatesModal(data)}
                          className="circular-popup"
                        >
                          <i
                            className="fa fa-clone"
                            aria-hidden="true"
                          ></i>
                          <p>View Similar</p>
                        </div>

                        <div
                          className="hover-popup"
                          onClick={() => addCandidateToSaveLater(data.id, setLoading)}
                        >
                          <Button
                            buttonType="secondary"
                            buttonIcon={
                              <i
                                className="fa fa-heart-o"
                                aria-hidden="true"
                              ></i>
                            }
                            text="Save Candidate"
                          />
                        </div>

                      </div>
                    </div>
                  ))}
              </div>

              <div style={{ marginTop: "30px" }}>
                <ButtonOutlined
                  text="View All Candidates"
                  onClick={() => {
                    navigate("/jobseeker/search");
                  }}
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-12 col-sm-12 mt-3">
              <div className="company text-center">
                <img
                  className="mb-3"
                  onError={commonServices.imgError}
                  src={globeImg}
                />
                <span>{data?.company_name}</span>
                {/* <p>Diamond</p> */}
                {/* <hr className='my-3' /> */}
                {/* <a href=''>Upgrade</a> */}
              </div>

              <div className="company-details mt-3">
                <h4 className="mb-3">Boost Job Posts</h4>
                <div
                  className="d-flex"
                  style={{ justifyContent: "space-between" }}
                >
                  <p>Featured Slots - Homepage</p>
                  <a
                    href="/company/users?tab=featured-jobs"
                  >
                    <img onError={commonServices.imgError} src={editImg} />
                  </a>
                </div>
                <span>{data?.company_active_home_slots} Available</span>
                <div
                  className="d-flex mt-1"
                  style={{ justifyContent: "space-between" }}
                >
                  <p>Featured Slots - Search</p>
                  <a
                    href="/posted"
                  >
                    <img onError={commonServices.imgError} src={editImg} />
                  </a>
                </div>
                <span>{data?.company_active_search_jobs} Available</span>
                <div
                  className="d-flex mt-1"
                  style={{ justifyContent: "space-between" }}
                >
                  <p>Global Referral</p>
                  <a
                    href="/referrals"
                  >
                    <img onError={commonServices.imgError} src={editImg} />
                  </a>
                </div>
                <span>{data?.referral_count} Open for referral</span>

                <hr className="my-3" />
                <h4 className="mb-3">Truetalent Cash</h4>
                <p>Available: {currencyFormatter(data?.available_cash)}</p>
                <div className="d-flex mt-1" style={{ gap: "10px" }}>
                  <a
                    href="/company/users"
                  >
                    Manage
                  </a>
                  <p style={{ color: "#D5E0D5" }}>|</p>
                  <a
                    href="/company/users"
                  >
                    Buy/Allocate
                  </a>
                </div>

                <hr className="my-3" />
                <h4 className="mb-3">Posted Jobs</h4>
                <div className="row mb-3">
                  <a
                    href="/posted?tab=active"
                  >
                    {data?.active_jobs_count} Active Jobs
                  </a>
                </div>
                <div className="row">
                  <a
                    href="/posted?tab=draft"
                  >
                    {data?.draft_jobs_count} Drafts
                  </a>
                </div>

                <hr className="my-3" />
                <h4 className="mb-3">Reports</h4>
                <div className="row">
                  <a
                    href="?action=reports"
                    onClick={(e) => { e.preventDefault(); setShowReportsModal(true) }}
                  >
                    View Reports
                  </a>
                </div>

                {/* Reports Modal */}
                <AppModal
                  title="Reports"
                  scrollHidden={true}
                  closeModal={() => {
                    setShowReportsModal(false);
                    resetDates();
                  }}
                  isOpen={showReportsModal}
                >
                  <div className="d-flex justify-content-center align-items-center gap-2 mb-3">
                    <div>
                      <DatePickerWrapper
                        label="Start Date: "
                        selected={startDate}
                        maxDate={getFirstDayOfMonth()}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="DD/MM/YYYY"
                        onChange={(e) => handleDateChange(e, "start")}
                      />
                    </div>
                    <div>
                      <DatePickerWrapper
                        label="End Date: "
                        selected={endDate}
                        minDate={startDate}
                        maxDate={getToday()}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="DD/MM/YYYY"
                        onChange={(e) => handleDateChange(e, "end")}
                      />
                    </div>
                  </div>

                  <Table
                    applyPagination={false}
                    columns={["Report Type", "Action"]}
                    data={reportsData}
                  />
                </AppModal>
              </div>
            </div>
          </div>
          <div className="container__box bg_green">
            {/* <div className='bg_green_absolute'></div> */}
            <div className="tt-container row mt-5 py-5">
              <div className="col-md-4">
                <div className="card1 text-center">
                  <span className="images-icon">
                    <img
                      style={{
                        margin: "auto",
                        height: "70px",
                      }}
                      onError={commonServices.imgError}
                      src={trustedImg}
                      alt=""
                    />
                  </span>
                  <h5>We’re trusted</h5>
                  <p className="content-paragraph1">
                    Over 38,000 employers trust us to deliver the talent they
                    need.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card1 text-center">
                  <span className="images-icon">
                    <img
                      style={{
                        margin: "auto",
                        height: "70px",
                      }}
                      onError={commonServices.imgError}
                      src={resultsImg}
                      alt=""
                    />
                  </span>
                  <h5>We deliver results</h5>
                  <p className="content-paragraph1">
                    84% of businesses using Truetalent find their next hire
                  </p>
                </div>
              </div>

              <div className="col-md-4">
                <div className="card1 text-center">
                  <span className="images-icon">
                    <img
                      style={{
                        margin: "auto",
                        height: "70px",
                      }}
                      onError={commonServices.imgError}
                      src={no1Img}
                      alt=""
                    />
                  </span>
                  <h5>We’re the #1 choice</h5>
                  <p className="content-paragraph1">
                    Candidates across the India choose us first : x million are
                    active right now.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="tt-container row mt-5">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <span className="">
                <img
                  onError={commonServices.imgError}
                  src={macImg1}
                  className="img-fluid"
                />
              </span>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <p className="content-title">AI + HI (Human intelligence)</p>
              <h3 className="content-sub-title">
                AI driven JD (job description) creation
              </h3>
              <p className="content-paragraph">
                TrueTalent assists you in generating a well-articulated and
                thorough JD for each job opening you have, using only the skills
                and years of experience you have at their disposal. A detailed
                JD is always advantageous for attracting the attention of
                competent applicants. In addition to TrueTalent, JD created can
                be used to post positions on any job platform or the
                organization's internal job boards.
              </p>
            </div>
          </div>

          <div className="tt-container row mt-5">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <p className="content-title">Smart Search</p>
              <h3 className="content-sub-title">
                Skill match (Beyond the key words)
              </h3>
              <p className="content-paragraph">
                While most talent search platforms provide search options based
                on keywords, which leads to a large number of irrelevant and
                low-matching profiles and ultimately makes it more difficult for
                recruiters to obtain relevant profiles, TrueTalent uses a
                customized and advanced technology algorithm to identify
                candidates whose skills precisely match the search skills.
                Multiple parameters are taken into account by the customized
                algorithm in order to provide you with the best results.
              </p>
            </div>

            <div className="col-lg-6 col-md-12 col-sm-12">
              <span className="">
                <img
                  onError={commonServices.imgError}
                  src={macImg2}
                  className="img-fluid"
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    </MaxWidthContainer>
  );
}

export default CompanyDashboardLayout;
