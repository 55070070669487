import React, { useState } from 'react';
import { highlightText } from '../../utils/stringUtils';

const ReadMoreText = ({ text, maxLength = 100, highlightKeywords }) => {
    const [isTruncated, setIsTruncated] = useState(true);

    const toggleTruncate = () => {
        setIsTruncated(!isTruncated);
    };

    return (
        <div style={{ fontSize: "var(--p1)" }}>
            {
                text ?
                    highlightKeywords ?
                        <span dangerouslySetInnerHTML={{ __html: isTruncated ? `${highlightText(highlightKeywords, text.slice(0, maxLength))}...` : highlightText(highlightKeywords, text) }} />
                        :
                        <span dangerouslySetInnerHTML={{ __html: isTruncated ? `${text.slice(0, maxLength)}...` : text }} />
                    :
                    null
            }
            {text && text.length > maxLength && (
                <span className="read-more-link green-text" style={{ cursor: 'pointer' }} onClick={toggleTruncate}>
                    {isTruncated ? ' Show more' : ' Show less'}
                </span>
            )}

        </div>
    );
};

export default ReadMoreText;
