import * as storageConstants from '../constants/storageConstants';
import * as commonServices from '../services/common';
import { isNullOrEmpty } from './stringUtils';

var masterData = commonServices.getLocalData(storageConstants.DATA);
export function isCompanyDashboardEnabled() {
    return (isNullOrEmpty(masterData?.system_config?.is_company_dashboard_enabled?.value) && masterData?.system_config?.is_company_dashboard_enabled?.value === "1");
}

export function regexForValidWebDomain() {
    return (masterData?.system_config?.validate_website_regex !== undefined)
        ? masterData.system_config.validate_website_regex : /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/; /* eslint-disable-line */
}