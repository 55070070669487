/* eslint-disable */
import React, { useState, useEffect } from 'react';
import Table from '../../_widgets/Table/Table';
import { textCapitalise } from '@/utils/stringUtils';
import Dropdown from '../../_widgets/Dropdown/Dropdown';
import Input from '../../_widgets/Input/Input';

function ProgramReferrals({ referralUsers, referralData, handlePaginationChange, referrals, setActiveReferralIndex, activeReferralIndex, setReferralData, activePage, copyLink, copyCode }) {

    var tableColumns = ['Program Name', 'Candidate ID', 'Name', 'Email', 'Phone Number', 'Candidate Status', 'Referred on'];
    const [tableData, setTableData] = useState([]);
    const [dropdownOptions, setDropdownOptions] = useState([]);
    useEffect(() => {

        var row = [];
        var row = referralUsers?.data && referralUsers?.data.map((user, i) => {
            var data = [];
            data.push(user?.referral.program_name);
            data.push(user?.referred_to_user !== null ? user?.referred_to_user.uid : 'N/A');
            data.push(user?.referred_to_user !== null ? user?.referred_to_user?.full_name : user?.first_name !== null ? user?.first_name + ' ' + user?.last_name : 'N/A');
            data.push(user?.email);
            data.push(user?.phone_number ? user?.phone_number : "N/A");
            data.push(<p className={user?.candidate_status_color}>{user?.candidate_status}</p>);
            data.push(user?.referred_on);
            return {
                data: data
            }
        }

        );

        setTableData(row)

    }, [referralUsers])

    useEffect(() => {

        var options = referrals.map((data, key) => {
            return {
                id: key,
                name: data.program_name,
            }
        });
        setDropdownOptions(options);

    }, [referrals])
    return (
        <>
            <div className="row mb-3">
                <div className='heading'>
                    <p className='mb-2'>What is TrueTalent Referral?</p>
                    <span>Employee Referral Schemes inherently give better results than other sourcing channels, however the traditional referral scheme are restricted to a limited set of people within the organisation. <br /><br />
                        TrueTalent Global Referral program, not just helps you expand your referral channel for scouting a much bigger pool of resources, but also helps you create a far larger set of brand ambassadors that helps permeate a bigger talent base with a much bigger impact, at a fraction of the cost. <br /><br />
                        From the cost perspective, when you only pay a much smaller amount for a successful sourcing referral rather than a final selection pay-out, it also helps you hire bigger numbers at much lesser cost than your traditional internal referral program.
                    </span>
                </div>
            </div>


            {referrals.length > 0 ?
                <>
                    <div className="row mb-3">
                        <div className='col-9 select'>
                            <Dropdown isRequired={false} showDefaultOption={false} label={"Select Referral Program"} id="program_referrals" name='program_referrals' value={activeReferralIndex} onChange={(e) => { setActiveReferralIndex(e.target.value); setReferralData(referrals[e.target.value]) }} select={dropdownOptions} />
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className='col-9 basic-detail'>
                            <p className='mb-2'>{referralData?.program_name}</p>
                            <span>{referralData?.program_description}</span>
                        </div>
                    </div>

                    <div className="row mb-1">
                        <div className='col-9 reward'>
                            <p className='mb-2'>Reward Details : {referralData?.amount + ' ' + referralData?.type}</p>
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className='col-9 reward'>
                            <p className='mb-2'>Successful Referrals Required: {referralData?.eligiblity_number}</p>
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className='col-lg-9 col-md-9 col-sm-12'>
                            <div className="link">
                            <Input type='text' value={referralData?.url} />
                            <a className='copy-btn hand-hover' onClick={() => { copyLink(); }}>Copy Link</a>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-3 col-sm-12 link invite-btn'>
                            <button onClick={() => { setShowModal(!showModal) }}>Invite {textCapitalise(referralData?.target_audience)}</button>
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-9'>
                            <p className='center'>OR</p>
                        </div>
                        <div className='col-3 link'>

                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className='col-9'>
                            <div className="link">
                            <Input type='text' value={referralData?.code} />
                            <a className='copy-btn hand-hover' onClick={() => { copyCode(); }}>Copy Code</a>
                            </div>
                        </div>
                        <div className='col-3 link'>

                        </div>
                    </div>
                </>
                :
                <p className='mt-3 centre-div'>At the moment, there is no ongoing referral program. Please keep an eye on this area for future updates.</p>
            }



            <div className="result_section">



                <div className='main-div'>
                    <div className="col common_items">

                        {referralUsers.data !== undefined && referralUsers.data.length > 0 ?
                            <Table columns={tableColumns} data={tableData} activePage={activePage} handlePaginationChange={handlePaginationChange} pagination={referralUsers} />
                            :
                            <p className='my-3 centre-div'>Within this section, you can view all your referrals. There are currently no referrals to display.</p>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProgramReferrals