/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router';
import * as ApiHelper from '@/services/apiHelper';
import './ShowJobs.scss';
import { addTabInHistory, getCurrentTab } from '@/utils/redirectUtil';
import * as toast from "@/wrapper/toast";
import { APPLIED, SAVED } from '@/constants/tabConstants';
import ShowJobsLayout from './ShowJobsLayout';


function ShowJobs({ keywordSeachFilters, setKeywordSeachFilters }) {

  let [loading, setLoading] = useState(false);
  let [color] = useState("#000000");

  const navigate = useNavigate();

  const [searchedLocations, setSearchedLocations] = useState([]);
  const [searchedSkills, setSearchedSkills] = useState([]);

  const [appliedJobs, setAppliedJobs] = useState([]);
  const [savedJobs, setSavedJobs] = useState([]);
  const [currentTab, setCurrentTab] = useState(APPLIED);

  const [searchType, setSearchType] = useState("smart");
  const [keyword, setKeyword] = useState("");

  var tab = getCurrentTab();
  const isActiveTab = (tab) => {
    return currentTab === tab;
  }
  const onTabClick = (tab) => {
    addTabInHistory(tab);
    setCurrentTab(tab);
    setActivePage(1);
  }

  const [activePage, setActivePage] = useState(1);
  const handlePaginationChange = (e, { activePage }) => {

    window.scrollTo(0, 0);
    setActivePage(activePage);
  };

  const getMyJobs = () => {
    setLoading(true);
    var params = "?pageNumber=" + activePage;
    ApiHelper.getMyJobs(params).then((response) => {
      setLoading(false);
      if (response.isSuccess === true) {
        setAppliedJobs(response?.data?.applied)
        setSavedJobs(response?.data?.saved);
      }
      else {
        toast.warn(response.message);
      }
    });
  }

  useEffect(() => {
    if (tab !== null) {
      setCurrentTab(tab)
    }

    getMyJobs();
  }, [])
  useEffect(() => {

    getMyJobs();
  }, [activePage])

  const FindWork = async () => {
    var queryParam = '?';
    for (let i = 0; i < searchedSkills.length; i++) {
      queryParam += "skills=" + searchedSkills[i]?.id + "&";
    }
    for (let i = 0; i < searchedLocations.length; i++) {
      queryParam += "locations=" + searchedLocations[i]?.id + "&";
    }
    queryParam += "searchType=" + searchType + "&";
    queryParam += "keyword=" + keyword + "&";
    navigate('/job/search' + queryParam);
  }

  var tabsData = [
    {
      text: "Applied Jobs (" + (appliedJobs?.total || 0) + ")",
      isActive: isActiveTab(APPLIED),
      onClick: (tab) => onTabClick(tab),
      onClickData: APPLIED,
      id: "applied_jobs",
      href: "#list-applied-jobs"
    },
    {
      text: "Saved Jobs (" + (savedJobs?.total || 0) + ")",
      isActive: isActiveTab(SAVED),
      onClick: (tab) => onTabClick(tab),
      onClickData: SAVED,
      id: "saved_jobs",
      href: "#list-saved-jobs"
    },
  ];

  return (

    <ShowJobsLayout
      loading={loading}
      color={color}
      currentTab={currentTab}
      APPLIED={APPLIED}
      SAVED={SAVED}
      appliedJobs={appliedJobs}
      savedJobs={savedJobs}
      activePage={activePage}
      setActivePage={setActivePage}
      handlePaginationChange={handlePaginationChange}
      tabsData={tabsData}
      FindWork={FindWork}
      keywordSeachFilters={keywordSeachFilters}
      setKeywordSeachFilters={setKeywordSeachFilters}
      searchedSkills={searchedSkills}
      setSearchedSkills={(_, data) => setSearchedSkills(data)}
      searchedLocations={searchedLocations}
      setSearchedLocations={(_, data) => setSearchedLocations(data)}
      searchType={searchType}
      setSearchType={setSearchType}
      keyword={keyword}
      setKeyword={setKeyword}
    />
  )
}

export default ShowJobs