/* eslint-disable */
import React from "react";
import emailImg from "../../Assets/svgs/email-2.svg";
import Button from "../_widgets/Button/Button";

export default function EmailVerification({ email, resendVerificationEmail }) {

    return (
        <>
            {/* <div className="right_content email_verify">
                <img className="mb-5" src={emailImg} alt='email' />
                <h5 className="mb-4">Verify your email</h5>
                <p>We’ve sent an email to</p>
                <p><b>{email}</b> to verify your email</p>
                <p>address and activate your account.</p>
                <p className="mt-3">The link in the email will expire in 24 hours.</p>
                <p className="mt-5 mb-3">Can't find the email?? Check your spam folder</p>
                <a className="hand-hover" href={undefined} onClick={() => resendVerificationEmail()}>Resend Verification Email</a>
            </div> */}
            <div className="registration-section">
                <div className="registration-section-body">
                    <div className="registration-section-body-logo">
                        <img src={emailImg} alt="email img" />
                    </div>
                    <div className="registration-section-body-content">
                        <h5 className="title">Verify Your Email</h5>
                        <p>We’ve sent an email to</p>
                        <p><strong>{email}</strong> to verify your email</p>
                        <p>address and activate your account.</p>
                        <p className="mt-3">The link in the email will expire in 24 hours.</p>
                        <p className="mt-3 mb-3">Can't find the email? Check your spam folder</p>
                        <Button buttonType="text" text="Resend Verification Email" onClick={() => resendVerificationEmail()} />



                    </div>


                </div>

            </div>
          
        
        </>

    )
}