import React from "react";
import { useNavigate } from 'react-router';
import { MaxWidthContainer } from "./MaxWidthContainer";

import Button from "../_widgets/Button/Button";
import dashboardNoData from "@/Assets/svgs/dashboardNoData.svg";
import Text from "../_widgets/Text/Text";

const NoDataScreen = ({ heading, image, title, text, buttonText, buttonAction }) => {

    const navigate = useNavigate();

    const getHeading = () => {
        return heading !== undefined ? heading : "Dashboard";
    }

    const getImage = () => {
        return image !== undefined ? image : dashboardNoData;
    }

    const getTitle = () => {
        return title !== undefined ? title : "Welcome to TrueTalent";
    }

    const getText = () => {
        return text !== undefined ? text : "Begin by defining your available time slots, enabling candidates to schedule appointments with you.";
    }

    const getButtonText = () => {
        return buttonText !== undefined ? buttonText : "Set Availability";
    }

    const getButtonAction = () => {
        return buttonAction !== undefined ? buttonAction() : navigate("/evaluator/calendar?tab=2");
    }

    return (
        <>

            <div>
                <div className="d-flex gap-4" gap={5}>
                    <Text
                        text={getHeading()}
                        type="h2"
                        style={{
                            fontSize: "18px",
                            fontWeight: 600
                        }}
                    />

                </div>
                <div className="d-flex flex-column align-items-center" flexDirection={"column"} alignItems={"center"}>
                    <div>
                        <img src={getImage()} alt="image" />
                    </div>
                    <Text
                        text={getTitle()}
                        type="h2"
                        style={{
                            fontSize: "20px",
                            fontWeight: 600,
                            marginTop: "3px"
                        }}
                    />

                    <Text
                        text={getText()}
                        style={{
                            fontSize: "16px",
                            fontWeight: 400,
                            color: "#9398A1",
                            marginTop: "7px"
                        }}
                    />

                    <Button
                        buttonType="primary"
                        text={getButtonText() + " "}
                        onClick={() => getButtonAction()}
                    >

                    </Button>
                </div>
            </div>

        </>
    );
};

export default NoDataScreen;
