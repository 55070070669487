import React, { useRef } from 'react'
import "./Chat.scss"
import * as ApiHelper from '@/services/apiHelper';
import * as toast from "@/wrapper/toast";
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import ModalUserChatBlock from '@Components/Modals/ModalUserChatBlock';
import ModalUserChatUnblock from '@Components/Modals/ModalChatUnblock';
import ModalUserChatMute from '@Components/Modals/ModalChatMute';
import ModalUserChatDelete from '@Components/Modals/ModalChatDelete';
import messageMediaDownload from '@/Assets/svgs/message-media-download.svg';
import { getUserId } from '@/utils/authUtil';
import { configureAbly, useChannel } from "@ably-labs/react-hooks";
import { updateHeaderMessageIcon } from '@/utils/messageUtil';
import { READ, UNREAD } from '@/constants/messageConstants';
import { isNotImageMedia, isDocMedia, isPdfMedia, isExcelMedia, isPptMedia, replaceURLs } from '@/utils/messageUtil';
import ModalMessageMediaPreview from '@Components/Modals/ModalMessageMediaPreview';
import { PuffLoader } from 'react-spinners';

import defaultAvatar from '@/Assets/svgs/default_avatar.svg';
import noMsgLeft from '@/Assets/svgs/no_msg_left.svg';
import noMsg from '@/Assets/svgs/no_msg.svg';
import { isCandidate } from '@/utils/authUtil';
import { createSlugFromString } from '@/utils/stringUtils';
import { ClearFileValues } from '@/utils/fileUtils';
import UserChatLayout from './UserChatLayout';

const initalMessage = {
  candidate_id: '',
  recruiter_id: '',
  job_id: '',
  message: ''
}

configureAbly({ key: '-BcqxA.y_IXaQ:TcCx9KXYIZBL6QW5O2jN9jtZLhn_N4MvNRr1cP7WSOA' });

const UserChat = () => {

  let [loader, setLoader] = useState(false);
  const fileRef = useRef()
  const chatRef = useRef()

  const navigate = useNavigate();

  const [chatUser, setChatuser] = useState([])

  const [selectedChat, setSelectedChat] = useState(null)
  const [selectedChatMessages, setSelectedChatMessages] = useState([])

  const [chatSender, setChatSender] = useState("candidate")
  const [chatReciever, setChatReciever] = useState("recruiter")

  const [modalPreview, setModalPreview] = useState(false);
  const [previewData, setPreviewData] = useState({});
  const [incomingMessageChatId, setIncomingMessageChatId] = useState(null);
  const [newMessageTrigger, setNewMessageTrigger] = useState(null);

  const getProfileId = () => {
    return localStorage.getItem('profile') ? JSON.parse(localStorage.getItem('profile')).id : null;
  }

  const [profileId, setProfileId] = useState(getProfileId)


  const [channel] = useChannel("chat_" + getUserId(), (message) => {
    setIncomingMessageChatId(message.data.chat_id);
    setNewMessageTrigger(Date.now());

    ApiHelper.getChat()
      .then((response) => {
        if (response.isSuccess === true) {
          setChatuser(response.data);
        }
        else {
          toast.warn(response.message);
        }
      })
  });


  const getChat = () => {
    ApiHelper.getChat()
      .then((response) => {
        setLoader(false);
        if (response.isSuccess === true) {
          setChatuser(response.data);
          setSelectedChat(response.data && response?.data?.find(item => item.id === selectedChat?.id) || response.data[0]);
          getChatMessages(response.data && response?.data?.find(item => item.id === selectedChat?.id)?.id || response.data[0]?.id)
        }
        else {
          toast.warn(response.message);
        }
      })
  }


  const getChatMessages = (id) => {
    ApiHelper.getChatMessages(id)
      .then((response) => {
        if (response.isSuccess === true) {
          response.data.messages.data = response.data?.messages?.data?.reverse()
          setSelectedChatMessages(response.data.messages);
          if (response?.data?.user_unread_messages > 0) {
            updateHeaderMessageIcon(UNREAD);
          } else {
            updateHeaderMessageIcon(READ);
          }
        }
        else {
          toast.warn(response.message);
        }
      })
  }


  const getChatMessagePage = (page) => {
    ApiHelper.getChatMessages(`${selectedChat?.id}?page=${page}`)
      .then((response) => {
        if (response.isSuccess === true) {
          setSelectedChatMessages(prev => ({ ...response?.data, data: [...response.data?.messages?.data?.reverse(), ...prev.data,] }))
        }
        else {
          toast.warn(response.message);
        }
      })
  }


  const [message, setMessage] = useState(initalMessage)
  const [inputFile, setInputFile] = useState({})

  const addChat = () => {
    if ((!message.message || !message.message.trim()) && (!inputFile || Object.keys(inputFile).length === 0)) {
      return toast.warn("Please enter message");
    }

    const formData = new FormData()
    formData.append("candidate_id", message?.candidate_id)
    formData.append("recruiter_id", message?.recruiter_id)
    if (message?.job_id) {
      formData.append("job_id", message?.job_id)
    }
    formData.append("message", message?.message)
    if (inputFile && inputFile !== "undefined") {
      Object.keys(inputFile).map((key, index) => {
        formData.append("media[]", inputFile[key])
      });

    }
    setInputFile({});

    ApiHelper.createChat(formData).then((response) => {
      if (response.isSuccess === true) {
        setMessage((prev => ({ ...prev, "message": "" })))
        ClearFileValues()
        // toast.success(response.message);
        getChat();
      }
      else {
        toast.warn(response.message);
      }
    })
  }


  useEffect(() => {
    setLoader(true);
    getChat();
  }, [])

  useEffect(() => {
    if (incomingMessageChatId === selectedChat?.id) {
      getChatMessages(incomingMessageChatId);
    }
  }, [newMessageTrigger])

  const getChatSender = (chat) => {
    if (chat.candidate_id === profileId || chat?.recruiter.id === process.env.REACT_APP_TT_ADMIN_ID) {
      return "candidate";
    }
    else {
      return "recruiter";
    }
  }

  const getChatReciever = (chat) => {
    if (chat.candidate_id === profileId || chat?.recruiter.id === process.env.REACT_APP_TT_ADMIN_ID) {
      return "recruiter";
    }
    else {
      return "candidate";
    }
  }

  useEffect(() => {

    if (selectedChat) {

      if (selectedChat.candidate_id === profileId) {
        setChatSender("candidate")
        setChatReciever("recruiter")
      }
      else {
        setChatSender("recruiter")
        setChatReciever("candidate")
      }

      getChatMessages(selectedChat?.id)
      setMessage({
        candidate_id: selectedChat?.candidate_id,
        recruiter_id: selectedChat?.recruiter_id,
        job_id: selectedChat.job_id || "",
        message: ""
      })
    }

  }, [selectedChat])


  const handleUpdate = (obj) => {

    ApiHelper.updateChat(selectedChat?.id, obj).then((response) => {
      if (response.isSuccess === true) {
        toast.success(response.message);
        getChat();
      }
      else {
        toast.warn(response.message);
      }
    })

  }



  const handleDelete = () => {

    ApiHelper.deleteChat(selectedChat?.id).then((response) => {
      if (response.isSuccess === true) {
        // toast.success(response.message);
        getChat();
      }
      else {
        toast.warn(response.message);
      }
    })

  }

  const [showModalReport, setShowModalReport] = useState(false)
  const [showModalUnblock, setShowModalUnblock] = useState(false)
  const [showModalMute, setShowModalMute] = useState(false)
  const [showModalDelete, setShowModalDelete] = useState(false)
  const openChatOnMobile = () => {
    if (window.matchMedia("(max-width: 767px)").matches) {
      document.getElementById("left").style.display = "none";
      document.getElementById("right").style.display = "block";
    }
  }
  const openChatBackOnMobile = () => {
    if (window.matchMedia("(max-width: 767px)").matches) {
      document.getElementById("right").style.display = "none";
      document.getElementById("left").style.display = "block";
    }
  }



  useEffect(() => {

    if (chatRef.current) {

      if (selectedChatMessages?.current_page === 1) {
        chatRef.current.scrollTop = chatRef.current?.scrollHeight;
      }
    }

  }, [chatRef, selectedChatMessages])


  const enterPressed = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      addChat()
    }
  }


  return (
    <UserChatLayout
      loader={loader}
      chatUser={chatUser}
      chatReciever={chatReciever}
      showModalReport={showModalReport}
      setShowModalReport={setShowModalReport}
      handleUpdate={handleUpdate}
      showModalUnblock={showModalUnblock}
      setShowModalUnblock={setShowModalUnblock}
      selectedChat={selectedChat}
      chatSender={chatSender}
      showModalMute={showModalMute}
      setShowModalMute={setShowModalMute}
      selectedChatMessages={selectedChatMessages}
      showModalDelete={showModalDelete}
      setShowModalDelete={setShowModalDelete}
      handleDelete={handleDelete}
      modalPreview={modalPreview}
      setModalPreview={setModalPreview}
      previewData={previewData}
      openChatBackOnMobile={openChatBackOnMobile}
      getChatMessagePage={getChatMessagePage}
      enterPressed={enterPressed}
      openChatOnMobile={openChatOnMobile}
      setSelectedChat={setSelectedChat}
      setChatuser={setChatuser}
      setPreviewData={setPreviewData}
      navigate={navigate}
      chatRef={chatRef}
      inputFile={inputFile}
      setInputFile={setInputFile}
      message={message}
      setMessage={setMessage}
      fileRef={fileRef}
      addChat={addChat}
      getChatSender={getChatSender}
      getChatReciever={getChatReciever}
    />
  )
}

export default UserChat