import React, { useEffect, useState, useRef } from 'react';
import * as ApiHelper from '@/services/apiHelper';
import * as toast from "@/wrapper/toast";
import PartnerDashboardLayout from './PartnerDashboardLayout';

function PartnerDashboard() {

    let [loading, setLoading] = useState(false);
    let [color] = useState("#000000");
    let [data, setData] = useState([]);
    const Chart1Ref = useRef();
    const Chart2Ref = useRef();
    const Chart3Ref = useRef();
    const Chart4Ref = useRef();
    const Chart5Ref = useRef();
    const Chart6Ref = useRef();

    const getData = async () => {
        setLoading(true);
        await ApiHelper.getPartnerDashboard().then((response) => {
            if (response.isSuccess === true) {
                setData(response?.data);
            }
            else {
                toast.warn(response.message);
                console.log('error');
            }
            setLoading(false);
        });
    }

    useEffect(() => {
        getData();
    }, []);

    return (
        <PartnerDashboardLayout
            loading={loading}
            color={color}
            data={data}
            Chart1Ref={Chart1Ref}
            Chart2Ref={Chart2Ref}
            Chart3Ref={Chart3Ref}
            Chart4Ref={Chart4Ref}
            Chart5Ref={Chart5Ref}
            Chart6Ref={Chart6Ref}
        />
    )
}

export default PartnerDashboard