/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import "./Modal.scss";

import { LOCATION_THRESHOLD } from "@/constants/storageConstants";
import * as commonServices from "@/services/common";

function ModalLocation({
  showModal,
  setShowModal,
  locations,
  selectedLocations,
  onChange,
}) {
  const [searchQuery, setSearchQuery] = useState("");
  const modalRef = useRef(null);
  const [activeAlphabet, setActiveAlphabet] = useState("A");

  const handleAlphabetClick = (alphabet) => {
    setActiveAlphabet(alphabet);
  };

  const handleAlphabetHover = (alphabet) => {
    // setActiveAlphabet(alphabet);
  };

  const handleAlphabetLeave = () => {
    // setActiveAlphabet(null);
  };

  useEffect(() => {
    setSearchQuery("");
  }, [showModal]);

  const [locationSuggestion, setLocationSuggestion] = useState([]);

  const handleSearch = (text) => {
    if (text.length === 0) {
      getLocationwithLetter(activeAlphabet);
    }

    if (text.length > LOCATION_THRESHOLD) {
      commonServices
        .getLocations(text + "&type=" + "JOB_LOCATIONS" + "&popup_search=1")
        .then((locations) => {
          setLocationSuggestion(locations);
        });
    }
  };

  const getLocationwithLetter = (letter = "A") => {
    commonServices
      .getLocations(letter + "&type=" + "JOB_LOCATIONS" + "&popup_search=1")
      .then((locations) => {
        setLocationSuggestion(locations);
      });
  };

  useEffect(() => {
    getLocationwithLetter(activeAlphabet);
  }, [activeAlphabet]);

  return (
    <div className="d-flex align-items-center justify-content-center">
      {showModal ? (
        <>
          <div class="modal" ref={modalRef}>
            <div
              className="location-filter-modal modal_content"
              style={{
                borderRadius: "10px",
              }}
            >
              <div className="modal_top row modal-header--sticky centre-div">
                <div className="col-lg-3 col-md-3 col-sm-12 px-0">
                  <input
                    className="search-filter"
                    type="text"
                    placeholder="Search location"
                    value={searchQuery}
                    onChange={(e) => {
                      setSearchQuery(e.target.value);
                      handleSearch(e.target.value);
                    }}
                  />
                </div>
                <div className="col-lg-8 col-md-8 col-sm-12">
                  <div className="mt-4">
                    <ul class="FilterDirectory-indices">
                      {[
                        "A",
                        "B",
                        "C",
                        "D",
                        "E",
                        "F",
                        "G",
                        "H",
                        "I",
                        "J",
                        "K",
                        "L",
                        "M",
                        "N",
                        "O",
                        "P",
                        "Q",
                        "R",
                        "S",
                        "T",
                        "U",
                        "V",
                        "W",
                        "X",
                        "Y",
                        "Z",
                      ].map((letter) => (
                        <li
                          data-item={letter}
                          style={
                            activeAlphabet === letter
                              ? {
                                border: "solid 1px var(--green)",
                                borderRadius: "5px",
                              }
                              : {}
                          }
                          class=""
                          onClick={() => handleAlphabetClick(letter)}
                          onMouseEnter={() => handleAlphabetHover(letter)}
                          onMouseLeave={() => handleAlphabetLeave()}
                        >
                          {letter}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="col-lg-1 col-md-1 col-sm-12">
                  <button
                    style={{ float: "right" }}
                    onClick={() => setShowModal((prev) => !prev)}
                  >
                    <img
                      onError={commonServices.imgError}
                      src="../../Assets/svgs/x.svg"
                      alt=""
                    />
                  </button>
                </div>
              </div>

              <div className="modal_body container-fluid">
                <ul
                  className="list-styl-none FilterDirectory-list"
                  style={{ padding: "0" }}
                >
                  {locationSuggestion?.map((location, index) => (
                    <li key={index}>
                      <div key={location.id} className="checkbox_div_c">
                        <input
                          type="checkbox"
                          name=""
                          defaultChecked={selectedLocations.includes(
                            location.id
                          )}
                          id={`loc_checked_c_m_c${location.id}`}
                          style={{ marginRight: "0.625em" }}
                          onChange={(e) => {
                            onChange(e, location);
                          }}
                        />
                        <label
                          className="form-label"
                          htmlFor={`loc_checked_c_m_c${location.id}`}
                        >
                          {location.name}, {location.description}
                        </label>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
}

export default ModalLocation;
