import React, { useEffect } from 'react';
import { capsOperators } from '@/utils/stringUtils';
import { validateKeyword } from '@/utils/validation';
import * as toast from "@/wrapper/toast";
import { INVALID_KEYWORD_ERROR_MESSAGE } from '@/constants/stringConstants';
import { addSearchParamsInHistory } from '@/utils/redirectUtil';

const KeywordSearchTextbox = ({ keywordSearch, setKeywordSearch, FindWork, searchBar }) => {


    const validateKeywordString = () => {
        return validateKeyword(keywordSearch);
    }


    const triggerKeyPress = (event) => {
        if (event.which === 13 || event.keyCode === 13) {
            if (validateKeywordString()) {
                addSearchParamsInHistory('q', keywordSearch);
                FindWork();
            } else {
                toast.warn(INVALID_KEYWORD_ERROR_MESSAGE);
            }

        }
        if (event.which === 32 || event.keyCode === 32) {
            setKeywordSearch(capsOperators(keywordSearch));

        }
    }

    useEffect(() => {

        if (!validateKeywordString()) {
            toast.warn(INVALID_KEYWORD_ERROR_MESSAGE);
        }

    }, [keywordSearch]);

    return (
        <input type='text' placeholder='Enter Keyword' autoComplete='off' name={searchBar + 'keyword'} value={keywordSearch} onChange={(e) => { setKeywordSearch(e.target.value) }} onKeyPress={(e) => { triggerKeyPress(e); }} />
    )

}

export default KeywordSearchTextbox