import React, { useState, useEffect } from 'react';
import { css } from "@emotion/react";
import { useLocation } from "react-router-dom";
import { useNavigate, Navigate } from "react-router-dom";
import * as ApiHelper from '@/services/apiHelper';
import * as commonServices from '@/services/common';
import ClipLoader from "react-spinners/ClipLoader";

import * as storageConstants from '@/constants/storageConstants';
import { updateHeaderMessageIcon } from '@/utils/messageUtil';
import { READ, UNREAD } from '@/constants/messageConstants';
import { SOMETHING_WENT_WRONG } from '@/constants/stringConstants';
import Register from '../Register/Register';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: var(--green);
  left: calc(50% - 75px);
  position: fixed;
`;

function GoogleCallback({ isHeaderUpdate, setIsHeaderUpdate }) {

    let [color] = useState("#000000");
    const [loading, setLoading] = useState(false);
    const [showRegister, setShowRegister] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const [profile, setProfile] = useState({});

    // const googleCallback = () => {
    //     if (!loading) {
    //         let url = "auth/google/callback" + location.search;
    //         setLoading(true);
    //         ApiHelper.googleLoginCallback(url).then((response) => {
    //             setLoading(false);
    //             if (response.isSuccess === true) {
    //                 setProfile(response.data.profile);
    //                 // toast.success(response.message);
    //                 setShowRegister(true);
    //                 // if (response.data!== null && response.data.is_user_exists) {
    //                 //     commonServices.storeLocalData(storageConstants.AUTH, response.data.token);
    //                 //     commonServices.storeLocalData(storageConstants.USER_ROLE, response.data.role_type);
    //                 //     commonServices.storeLocalData(storageConstants.PROFILE, response.data.profile);
    //                 //     setIsHeaderUpdate(!isHeaderUpdate);
    //                 //     let redirect = '/';
    //                 //     if (response?.data?.user_unread_messages > 0) {
    //                 //         updateHeaderMessageIcon(UNREAD);
    //                 //     } else {
    //                 //         updateHeaderMessageIcon(READ);
    //                 //     }
    //                 //     if (response.data.token && response.data.role_type === 'CD' && response.data.profile!== null && response.data.profile.progress < 100) {
    //                 //         redirect = '/workprofile?profileInProgress=1';
    //                 //     }

    //                 //     if (redirect!== null) {
    //                 //         navigate(redirect);
    //                 //     } else {
    //                 //         navigate('/');
    //                 //     }
    //                 // } else {
    //                 //     setShowRegister(true);
    //                 // }
    //             }
    //             else if (response.message !== '' && response.message !== SOMETHING_WENT_WRONG) {
    //                 toast.warn(response.message);
    //                 navigate('/login');
    //             }
    //         });
    //     }
    // }

    useEffect(() => {
        // googleCallback();
        setShowRegister(true);
    }, []);

    return (<>
        {
            showRegister ?
                <Register isGoogleLogin={true} isHeaderUpdate={isHeaderUpdate} setIsHeaderUpdate={setIsHeaderUpdate} />
                : null
        }
    </>
    )
}

export default GoogleCallback;