// /* eslint-disable */
import React, { useState, useEffect } from 'react'
import './Modal.scss'
import * as storageConstants from '@/constants/storageConstants';
import * as commonServices from '@/services/common';
import * as errors from '@/constants/stringConstants';
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";
import * as ApiHelper from '@/services/apiHelper';
import { isExist, isNotNullOrEmpty } from '@/utils/objectUtil';
import { BUTTON_TEXT_CONTINUE_EDITING } from "@/constants/stringConstants";
import * as toast from "@/wrapper/toast";
import AppModal from './AppModal';
import IconResource from '../_widgets/IconResource/IconResource';
import { stringCompare } from '../../utils/stringUtils';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: var(--green);
  left: calc(50% - 75px);
  position: fixed;
`;

function ModalSkills({ showModal, setShowModal, profile, setProfile, setSkillData, skillData, setDataAutoSave, setShowExperienceSuggestionModal, setExperienceSuggestionModalData }) {

    // const masterData = commonServices.getLocalData(storageConstants.DATA);
    const [skill, setSkill] = useState([]);
    const [activeRow, setActiveRow] = useState(0);
    // const [skillName, setSkillName] = useState('');
    let [loading, setLoading] = useState(false);
    let [color] = useState("#000000");
    let [update, setUpdate] = useState(false);

    const years = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]
    const months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]


    const [jtSuggestion, setJtSuggestion] = useState();


    useEffect(() => {

        if (isExist(profile)) {
            if (profile?.userWorkProfileDetail !== undefined && profile.userWorkProfileDetail.skill !== undefined && profile.userWorkProfileDetail.skill !== null) {
                (profile.userWorkProfileDetail.skill).forEach(currentSkill => {
                    (skillData).forEach(element => {
                        if (element.id === currentSkill.skill_id) {
                            currentSkill.skill_name = element.name;
                        }
                    });
                });
            }
            setSkill(isExist(profile.userWorkProfileDetail) && isExist(profile.userWorkProfileDetail.skill) && isNotNullOrEmpty(profile.userWorkProfileDetail.skill) ? profile.userWorkProfileDetail.skill : [{}]);
        }
    }, [showModal])

    const skillExperienceHandler = (e, type, key) => {
        var tempArray = [...skill];
        if (type === 'year') {
            var months = e.target.value * 12;
            tempArray[key].experience = tempArray[key].experience ? tempArray[key].experience % 12 + months : months;
            setSkill(tempArray);
        } else if (type === 'month') {
            tempArray[key].experience = tempArray[key].experience ? (Math.trunc(tempArray[key].experience / 12)) * 12 + parseInt(e.target.value) : parseInt(e.target.value);
            setSkill(tempArray);
        }

    }

    const deleteRow = async (e, key) => {
        e.preventDefault();
        var tempProfileArr = [...skill];
        tempProfileArr.splice(key, 1);
        setSkill(tempProfileArr);
    }

    const addSkill = (e) => {
        var isError = false;
        var isExperienceSuggestionShown = false;
        skill && skill.map((element, key) => {
            var currentRow = key + 1;
            var isSKillExist = false;
            if (profile.userWorkProfileDetail && profile.userWorkProfileDetail.skill) {
                isSKillExist = profile.userWorkProfileDetail.skill.some(function (el, i) {
                    return el.skill_id === skill[key].skill_id && key !== i;

                });
            }

            if (isSKillExist) {
                isError = true;
                toast.warn(errors.ADDED_SKILL_ERROR + " at row " + currentRow);
                return false;
            }
            else if (!skill[key].skill_id) {
                if (skill[key].skill_name === '') {
                    isError = true;
                    toast.warn(errors.EMPTY_SKILL_ERROR + " at row " + currentRow);
                    return false;
                } else {
                    isError = true;
                    const formData = new FormData();
                    formData.append('name', skill[key].skill_name);
                    setLoading(true);
                    ApiHelper.addSkill(formData).then((response) => {
                        if (response.isSuccess === true) {
                            setSkillData(response.data);
                            var tempSkill = [...skill];
                            response.data.forEach(element => {
                                if (stringCompare(skill[key].skill_name,element.name)) {
                                    tempSkill[key].skill_id = element.id;

                                }

                            });
                            setUpdate(true);
                            setSkill(tempSkill);

                        }
                        else {
                            isError = true;
                            toast.warn(response.message + " at row " + currentRow);
                        }
                        setLoading(false);

                    })
                }

            }
            if (skill[key].experience > profile?.user_work_profile?.total_experience) {
                isExperienceSuggestionShown = true;
            }
        });

        if (!isError) {
            setUpdate(true);
            var tempArray = { ...profile };
            tempArray.userWorkProfileDetail.skill = skill;
            setProfile(tempArray);
            if (isExperienceSuggestionShown) {

                setExperienceSuggestionModalData({
                    'icon': <IconResource type={"report"} className="red" />,
                    'heading': errors.IMPORTANT_WARNING_TITLE,
                    'paragraph': errors.TOTAL_EXPERIENCE_WARNING_TEXT,
                    'buttonText': BUTTON_TEXT_CONTINUE_EDITING,
                });
                setShowExperienceSuggestionModal(true);
            }
        }


    }

    useEffect(() => {
        if (update) {
            console.log('update useEffect');
            var tempArray = { ...profile };
            tempArray.userWorkProfileDetail.skill = skill;
            setProfile(tempArray);
            setDataAutoSave(true);
            setUpdate(false);
            setShowModal(false);
        }
    }, [skill, profile])


    const onChangeHandlerSkill = (text, key) => {
        var tempArray = [...skill];
        tempArray[key].skill_name = text;
        setSkill(tempArray);
        setActiveRow(key);
        if (text.length > storageConstants.SKILL_THRESHOLD) {
            commonServices.getSkillByName(text).then((skills) => {
                setJtSuggestion(skills);
            });
        } else {
            setJtSuggestion([]);
        }
    }


    return (
        <>
            <AppModal
                title={"Add Skills"}
                isOpen={showModal}
                closeModal={() => setShowModal(false)}
                isFooter={true}
                size={"5xl"}
                loading={loading}
                submit={addSkill}

            >
                <div className="modal_body">
                    <div className='modal_data_rows'>
                        {skill && skill.map((element, key) => {
                            return <>

                                <div className="row">
                                    <div className="col">
                                        <div className="position-relative">
                                            <label className="input-lebel" htmlFor=''>Skills</label>
                                            <div className="input-group">
                                                <input type="text" className="form-control" placeholder="Enter the skills" id='search_skills' value={skill[key].skill_name} name='skill_id' onChange={(e) => { onChangeHandlerSkill(e.target.value, key); }} />
                                            </div>
                                            <div className='suggestion_box'>
                                                {jtSuggestion && activeRow === key && jtSuggestion.map((suggestion, i) =>
                                                    <p key={i} onClick={() => { document.getElementById('search_skills').value = ''; setJtSuggestion([]); var tempArray = [...skill]; tempArray[key].skill_name = suggestion.name; tempArray[key].skill_id = suggestion.id; setSkill(tempArray); }}>{suggestion.name}</p>)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col ">
                                        <label className="input-lebel" htmlFor="">
                                            Exp. (Yrs)
                                        </label>
                                        <select className="form-select form-select-lg" id='year' value={skill && skill[key] && skill[key].experience ? Math.trunc(skill[key].experience / 12) : 0} onChange={(e) => skillExperienceHandler(e, 'year', key)} >
                                            {years.map((data, key) => {
                                                return <option value={data} key={key} >{data} years</option>
                                            })}

                                        </select>
                                    </div>

                                    <div className="col ">
                                        <label className="input-lebel" htmlFor="">
                                            Months
                                        </label>
                                        <select className="form-select form-select-lg" id="month" value={skill && skill[key] && skill[key].experience ? Math.trunc(skill[key].experience % 12) : 0} onChange={(e) => skillExperienceHandler(e, 'month', key)} >
                                            {months.map((data, key) => {
                                                return <option key={key} value={data}>{data} months</option>
                                            })}

                                        </select>
                                    </div>
                                </div>


                                <div className='row mb-3'>
                                    <div className="col-md-2 col-6">
                                        <a className='delete-row' onClick={(e) => { deleteRow(e, key) }}> <img className='' src='../Assets/svgs/trash.svg'></img> Remove</a>
                                    </div>
                                    {key === skill.length - 1 ?

                                        <div className="col-md-3 col-6">
                                            <a className='add-more' onClick={() => { setSkill([...skill, {}]) }}> <img src='../Assets/svgs/plus_circle.svg'></img> Add More</a>
                                        </div>

                                        : ''}

                                    <div className="col"></div>
                                    <div className="col"></div>
                                    <div className="col"></div>
                                    <div className="col"></div>
                                    <div className="col"></div>
                                    <div className="col"></div>
                                </div>



                            </>
                        })}
                    </div>
                    {/* <div className="row">
                                <div className="col"></div>
                                <div className="col">
                                    <div className="form_buttons">
                                        <button className="btn btn-primary" onClick={() => setShowModal(prev => !prev)}>Cancel</button>
                                        <input className='btn' type="submit" value="Save Changes" onClick={() => addSkill()} />


                                    </div>
                                </div>
                            </div> */}

                </div>

            </AppModal>
        </>
    )
}

export default ModalSkills
