import React, { useEffect, useState } from 'react'

import Link from '../_widgets/Link/Link';

import logo from "@/Assets/svgs/logo.svg"

import candidate from "@/Assets/svgs/candidate.svg";
import employer from "@/Assets/svgs/employer.svg";
import evaluator from "@/Assets/svgs/evaluator.svg";

import { useNavigate, useSearchParams } from 'react-router-dom';
import { trackCandidateOnboarding, trackCompanyOnboarding, trackEvaluatorOnboarding } from '../../helpers/analytics';





const Register = ({ onClose }) => {


    const [selectedProfile, setSelectedProfile] = useState("")

    const navigate = useNavigate()


    useEffect(() => {

        if (selectedProfile) {
            onClose()

            switch (selectedProfile) {
                case "candidate":
                    trackCandidateOnboarding("START");
                    break;
                case "employer":
                    trackCompanyOnboarding("START");
                    break;
                case "evaluator":
                    trackEvaluatorOnboarding("START");
                    break;
            }

            navigate("register", {
                state: {
                    selectedProfile: selectedProfile
                }
            })
        }

    }, [selectedProfile])

    const [searchParams, setSearchParams] = useSearchParams();

    const switchLogin = (event) => {
        setSearchParams({ "auth": "login" })
    }

    return (
        <>
            <div className='auth-container-content-wrapper register'>
                <img src={logo} className='logo' alt='company logo' />
                <p className='title'>Select Your Profile</p>
                <div className='profile-type-wrapper'>
                    <div className='individual-profile' onClick={() => setSelectedProfile("candidate")}>
                        <div className='individual-profile-img'>
                            <img src={candidate} />

                        </div>
                        <div className='individual-profile-content'>
                            <p className='profile-type text-center'>
                                I'm a candidate
                            </p>
                            <p className='profile-description text-center'>
                                I am here to find my next Job.
                            </p>

                        </div>



                    </div>
                    <div className='individual-profile' onClick={() => setSelectedProfile("employer")}>
                        <div className='individual-profile-img'>
                            <img src={employer} />

                        </div>
                        <div className='individual-profile-content'>
                            <p className='profile-type text-center'>
                                I'm an employer
                            </p>
                            <p className='profile-description text-center'>
                                I am here to look for job seekers.
                            </p>

                        </div>



                    </div>
                    <div className='individual-profile' onClick={() => setSelectedProfile("evaluator")}>
                        <div className='individual-profile-img'>
                            <img src={evaluator} />

                        </div>
                        <div className='individual-profile-content'>
                            <p className='profile-type text-center'>
                                I'm an evaluator
                            </p>
                            <p className='profile-description text-center'>
                                Here to help you identify quality candidates.
                            </p>

                        </div>



                    </div>

                </div>
                <div className='register-or-section'>
                    <p> Already have an account?</p>
                    <Link
                    type="a"
                        text="Sign In"
                        className='green-link green-link-big'
                        onClick={switchLogin}
                    > </Link>

                </div>
            </div>
        </>
    )
}

export default Register