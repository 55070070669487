import React from "react";
import "./Checkbox.scss"

const Checkbox = (props) => {

    const renderCheckbox = () => {
        switch (props?.type) {
            default:
                return (
                    <>
                    <div className="d-flex align-items-end">
                        <input disabled={props?.disabled} type="checkbox" className="form-check-input" id={props?.id} value={props?.value} checked={props?.checked} onChange={props?.onChange} />
                        <label for={props?.id} className='form-check-label ms-2'> {props?.label} </label>
                    </div>
                    </>
                )
        }
    }
    return (<>
        {renderCheckbox()}
    </>
    );
};

export default Checkbox;