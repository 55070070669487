/* eslint-disable */
import React, { useEffect, useState } from 'react'
import './Referral.scss'
import * as toast from "@/wrapper/toast";
import * as ApiHelper from '@/services/apiHelper';

import { css } from "@emotion/react";
import ReferralLayout from './ReferralLayout';
import { useNavigate } from 'react-router-dom';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: var(--green);
  left: calc(50% - 75px);
  position: fixed;
`;

function Referral() {
    let navigate = useNavigate();
    const [referrals, setReferrals] = useState([]);
    const [jobs, setJobs] = useState([]);
    const [activeReferralIndex, setActiveReferralIndex] = useState(0);
    const [activeJobIndex, setActiveJobIndex] = useState(0);
    const [referralData, setReferralData] = useState({});
    const [jobData, setJobData] = useState({});
    const [referralUsers, setReferralUsers] = useState([]);
    const [jobUsers, setJobUsers] = useState([]);
    let [loading, setLoading] = useState(false);
    let [showModal, setShowModal] = useState(false);
    let [activePage, setActivePage] = useState(1);
    let [activeJobPage] = useState(1);
    let [color] = useState("#000000");
    const [currentTab, setCurrentTab] = useState('job');

    const isActiveTab = (tab) => {
        return currentTab === tab;
    }
    const onTabClick = (tab) => {
        setCurrentTab(tab);
    }

    const getReferrals = async () => {
        setLoading(true);
        await ApiHelper.getReferrals().then((response) => {
            setLoading(false);
            if (response.isSuccess === true) {
                setReferrals(response.data.referrals);
                setJobs(response.data.referral_jobs);
                if (response.data.referrals.length > 0) {
                    setReferralData(response.data.referrals[activeReferralIndex]);
                }

                if (response.data.referral_jobs.length > 0) {
                    setJobData(response.data.referral_jobs[activeJobIndex]);
                }
            }
            else {
                toast.warn(response.message);
            }
        });
    }

    const getReferralUsers = async (params, tab = "program") => {
        setLoading(true);
        await ApiHelper.getReferralUsers(params).then((response) => {
            setLoading(false);
            if (response.isSuccess === true) {
                if (tab === "program") {
                    setReferralUsers(response.data);
                } else {
                    setJobUsers(response.data);
                }

            }
            else {
                toast.warn(response.message);
            }
        });
    }

    const copyLink = () => {
        navigator.clipboard.writeText(referralData?.url);
        toast.info('Link Copied');
    }

    const copyCode = () => {
        navigator.clipboard.writeText(referralData?.code);
        toast.info('Code Copied');
    }

    const handlePaginationChange = (e, { activePage }) => {
        setActivePage(activePage);
        getReferralUsers("?referral_id=" + referrals[activeReferralIndex]?.id + "&page=" + activePage, "program");
    };

    const handlePaginationJobChange = (e, { activePage }) => {
        setActivePage(activePage);
        getReferralUsers("?referral_id=" + jobs[activeJobIndex]?.id + "&page=" + activePage, "job");
    };



    useEffect(() => {
        getReferrals();
    }, []);

    useEffect(() => {
        getReferralUsers("?referral_id=" + referrals[activeReferralIndex]?.id + "&page=" + activePage, "program");
    }, [referralData]);

    useEffect(() => {
        getReferralUsers("?referral_id=" + jobs[activeJobIndex]?.id + "&page=" + activeJobPage, "job");
    }, [jobData]);

    var tabsData = [
        {
            text: "Referrals for Jobs",
            isActive: isActiveTab('job'),
            onClick: (tab) => onTabClick(tab),
            onClickData: 'job',
            id: "list-job-list",
            href: "#list-job"
        },
        {
            text: "Referrals for Programs",
            isActive: isActiveTab('programs'),
            onClick: (tab) => onTabClick(tab),
            onClickData: 'programs',
            id: "list-programs-list",
            href: "#list-programs"
        },
    ];


    return (
        <ReferralLayout
            loading={loading}
            showModal={showModal}
            setShowModal={setShowModal}
            referralData={referralData}
            getReferrals={getReferrals}
            tabsData={tabsData}
            currentTab={currentTab}
            referrals={referrals}
            referralUsers={referralUsers}
            jobs={jobs}
            jobUsers={jobUsers}
            navigate={navigate}
            color={color}
            activeReferralIndex={activeReferralIndex}
            setActiveReferralIndex={setActiveReferralIndex}
            setActiveJobIndex={setActiveJobIndex}
            copyLink={copyLink}
            copyCode={copyCode}
            handlePaginationChange={handlePaginationChange}
            handlePaginationJobChange={handlePaginationJobChange}
            activePage={activePage}
            activeJobIndex={activeJobIndex}
            setReferralData={setReferralData}
            setJobData={setJobData}
            activeJobPage={activeJobPage}
        />
    )
}

export default Referral
