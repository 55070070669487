/* eslint-disable */
import React, { useState } from 'react';
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import { isNullOrEmpty } from '@/utils/stringUtils';
import * as commonServices from '@/services/common';
import * as toast from "@/wrapper/toast";
import defaultAvatar from '@/Assets/svgs/default_avatar.svg';

import Text from '../_widgets/Text/Text';

import { BsDot } from "react-icons/bs";
import PartnerCandidates from '../Modals/ModalPartnerCandidates';
import PartnerCandidatesAppliedJobs from '../Modals/ModalPartnerCandidatesAppliedJobs';
import PartnerCandidatesAppliedGigs from '../Modals/ModalPartnerCandidatesAppliedGigs';
import { getUserCompanyId, isPartnerAdmin } from '../../utils/authUtil';
import Button from "../_widgets/Button/Button"

const override = css`
  /* display: block;
  margin: 0 auto;
  border-color: var(--green);
  left: calc(50% - 75px);
    position: fixed; */
`;

const PartnerCompanyProfileLayout = ({
    loading,
    color,
    showCandidatesModal,
    setShowCandidatesModal,
    showCandidatesAppliedJobsModal,
    setShowCandidatesAppliedJobsModal,
    showCandidatesAppliedGigsModal,
    setShowCandidatesAppliedGigsModal,
    profile,
    navigate
}) => {

    return (
        <>
            {loading ?
                <div className='loader'>
                    <ClipLoader color={color} loading={true} css={override} size={150} /> </div> : null}
            <PartnerCandidates showModal={showCandidatesModal} setShowModal={setShowCandidatesModal} remaining_views={profile?.remaining_views} />
            <PartnerCandidatesAppliedJobs showModal={showCandidatesAppliedJobsModal} setShowModal={setShowCandidatesAppliedJobsModal} />
            <PartnerCandidatesAppliedGigs showModal={showCandidatesAppliedGigsModal} setShowModal={setShowCandidatesAppliedGigsModal} />
            <div className='company-profile main-body'>

                <div className='tt-container'>
                    <div className='d-flex justify-content-end mb-3'>
                        {isPartnerAdmin() ?
                            <Button buttonType="primary" text="Edit" onClick={(e) => {
                                e.preventDefault();
                                navigate("/partner/edit");
                            }} />
                            : null}
                    </div>
                    <div className="banner banner-img" style={{ backgroundImage: `url(${profile?.cover_pic})`, }}>
                        {/* <img style={{ height: '100%', width: '100%' }} src={profile?.cover_pic} ></img> */}
                        <div className='profile_img img-border' >
                            <img onError={commonServices.imgError} src={profile?.logo ?? defaultAvatar} alt="" /></div>
                        {/* <span className='setDeleteClass'><DeleteIcon className='deleteIcon' /></span> */}
                    </div>

                    <div className="tab-content" id="nav-tabContent">
                        <div className="tab__pane tab_1 d-flex gap-3 fade show active" id='list-home' role="tabpanel" aria-labelledby="list-home-list">
                            <div className="left">
                                <div className='company-info'>

                                    <div className="d-flex align-items-center justify-content-between w-full">
                                        <h2>{profile?.name}</h2>
                                        {/* <div
                                        style={{ border: 'solid 1px black', padding: "10px", borderRadius: '50%', height: '20px', width: "10px" }}>
                                        </div> */}
                                    </div>
                                    <div className="d-flex align-items-center gen-details">
                                        {profile?.location ? <>
                                            <i class="bi bi-geo-alt-fill"></i>
                                            <p className='text-gray8'>  {profile?.location?.name + ", " + profile?.location?.description}</p>
                                        </> : null}
                                        <span><i class="bi bi-dot"></i></span>
                                        <p className='text-gray8'>Updated {profile?.updated_at}</p>
                                    </div>

                                    <br />

                                    <div class="container text-start" style={{ margin: '10px 0', }}>
                                        <div class="row">
                                            <div class="col">
                                                <p className='text-gray8' style={{ fontSize: '0.875em' }}>Candidates</p>
                                                <div style={{ color: 'var(--black3)', fontSize: '1em', fontWeight: 500 }}>
                                                    <i class="bi bi-person"></i> {profile?.trained_candidates + " Trained"}
                                                </div>
                                            </div>

                                            <div class="col">
                                                <p className='text-gray8' style={{ fontSize: '0.875em' }} >Courses</p>
                                                <div style={{ color: 'var(--black3)', fontSize: '1em', fontWeight: 500 }}>
                                                    <i class="bi bi-person"></i> {profile?.courses?.length + " Available Courses"}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <br />
                                    {profile?.description ? <>
                                        <h4>About The Institute</h4>
                                        <p className='desc'>{profile?.description}</p>
                                    </>
                                        : null}
                                </div>

                                {profile?.address ?
                                    <div className='box-wrapper mt-5' >

                                        <h4>Locations</h4>
                                        <br />

                                        <div className='d-flex gap-3 w-100'>{profile?.addresses?.map((item, index) => (
                                            <div className='d-flex align-items-center text-nowrap' >
                                                <BsDot size={24} />
                                                <Text
                                                    text={item?.address}
                                                />

                                            </div>
                                        ))}</div>
                                    </div>
                                    : null}

                                {profile?.courses.length > 0 ?
                                    <div className='box-wrapper mt-5'>

                                        <h4>Available Courses</h4>
                                        <br />
                                        <div className='row gy-3'>
                                            {profile?.courses && profile?.courses.map((course, i) => {
                                                return (
                                                    <div className='col-12 col-sm-6 col-md-4'>
                                                        <div className="photo">
                                                            <img className='course-img' src={course.course_image} />
                                                            <h4 style={{ marginBottom: "8px" }}>{course?.title}</h4>
                                                            <p style={{ color: 'var(--gray8)', marginBottom: "8px" }}>Updated {course?.description}</p>
                                                            <a target='_blank' className='green-text underline' href={course?.url}>Know More</a>
                                                        </div>
                                                    </div>
                                                )
                                            })}

                                        </div>

                                    </div>
                                    : null}




                            </div>

                            <div className="right">
                                <div style={{ border: 'solid 1px var(--gray)', borderRadius: '16px', padding: '30px 30px' }}>

                                    <h6>{profile?.name}'s Candidates Registered </h6>
                                    <p className='green-text hand-hover' onClick={() => { if (getUserCompanyId() === profile?.eid) { setShowCandidatesModal(true); } }}>{profile?.trained_candidates}</p>
                                    <br />

                                    <h6>Jobs Applied</h6>
                                    <p className='green-text hand-hover' onClick={() => {
                                        if (getUserCompanyId() === profile?.eid) {
                                            if (profile?.remaining_views > 0) setShowCandidatesAppliedJobsModal(true);
                                            else toast.warn("This feature is available only for Pro Users");
                                        }
                                    }}>{profile?.applied_jobs || 0}</p>
                                    <br />
                                    <h6>Gigs Applied</h6>
                                    <p className='green-text hand-hover' onClick={() => {
                                        if (getUserCompanyId() === profile?.eid) {
                                            if (profile?.remaining_views > 0) {
                                                setShowCandidatesAppliedGigsModal(true);
                                            }
                                            else toast.warn("This feature is available only for Pro Users")
                                        }
                                    }}

                                    >{profile?.applied_gigs || 0}</p>
                                    <br />
                                    <h6>Total Profile View by Companies</h6>
                                    <p>{profile?.profile_views_company_count || 0}</p>
                                    <br />
                                    <h6>Total Profile Download by Companies</h6>
                                    <p>{profile?.profile_downloads_company_count || 0}</p>
                                    <br />

                                    {
                                        profile?.candidates_by_skills?.length > 0 && <><h6>Candidates Registered On Top 3 Skill</h6> </>
                                    }
                                    <ul style={{
                                        marginLeft: "15px"
                                    }}>

                                        {profile?.candidates_by_skills && profile?.candidates_by_skills.map((candidate, i) => {
                                            return <>
                                                <li>{candidate.skill.name} : {candidate.users_count}</li>

                                            </>
                                        })}
                                    </ul>





                                </div>
                                <div style={{ border: 'solid 1px var(--gray)', borderRadius: '16px', padding: '30px 30px', marginTop: '20px' }}>


                                    <a className='hand-hover' href={undefined} onClick={() => window.open(profile?.website, '_blank')}>{profile?.name} website <i class="bi bi-box-arrow-up-right" style={{ marginLeft: "10px" }}></i></a>
                                    <br />
                                    <br />
                                    {!isNullOrEmpty(profile?.facebook) ? <><a className='hand-hover' href={profile?.facebook} onClick={() => window.open(profile?.facebook, '_blank')}>Facebook</a><br /><br /></> : null}
                                    {!isNullOrEmpty(profile?.instagram) ? <><a className='hand-hover' href={undefined} onClick={() => window.open(profile?.instagram, '_blank')}>Instagram</a><br /><br /></> : null}
                                    {!isNullOrEmpty(profile?.linkedin) ? <><a className='hand-hover' href={undefined} onClick={() => window.open(profile?.linkedin, '_blank')}>Linkedin</a><br /><br /></> : null}
                                    {!isNullOrEmpty(profile?.twitter) ? <><a className='hand-hover' href={undefined} onClick={() => window.open(profile?.twitter, '_blank')}>Twitter</a></> : null}

                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </>
    )
}

export default PartnerCompanyProfileLayout