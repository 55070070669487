import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';

import * as ApiHelper from '@/services/apiHelper';
import * as toast from "@/wrapper/toast";
import { changeDateFormat } from '../../utils/dateUtil';
import EvaluatorInterviewLayout from './InterviewLayout';

const EvaluatorInterview = () => {

    const [isOpen, setIsOpen] = useState(false);
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [isNoShowOpen, setIsNoShowOpen] = useState(false);

    const onOpen = () => {
        setIsOpen(true);
    };

    const onClose = () => {
        setIsOpen(false);
    };

    const onDeleteOpen = () => {
        setIsDeleteOpen(true);
    };

    const onDeleteClose = () => {
        setIsDeleteOpen(false);
    };

    const onNoShowOpen = () => {
        setIsNoShowOpen(true);
    };

    const onNoShowClose = () => {
        setIsNoShowOpen(false);
    };


    var today = new Date();
    today.setDate(today.getDate() + 1)

    const [date, setDate] = useState(today);

    const [selectedSlot, setSelectedSlot] = useState(null);

    const navigate = useNavigate();

    const [loading, setLoading] = React.useState(false);

    const [selectedTab, setSelectedTab] = React.useState('pending')

    const isActiveTab = (tab) => {
        return selectedTab === tab;
    }
    const onTabClick = (tab) => {
        setSelectedTab(tab);
    }
    const [selectedInterview, setSelectedInterview] = React.useState(null);

    const [reason, setReason] = React.useState("");


    const [currentQueryParameters, setSearchParams] = useSearchParams();


    React.useEffect(() => {
        if (currentQueryParameters.get("tab")) {
            const tabId = currentQueryParameters.get("tab")
            setSelectedTab(tabId)
        }
    }, [currentQueryParameters])

    const [candidateInterview, setCandidateInterview] = React.useState(
        {
            pending: [],
            accepted: [],
            completed: [],
            cancelled: []
        }
    );


    const getCandidateLatestInterview = async () => {
        setLoading(true);
        await ApiHelper.getUserInterviews().then((response) => {
            setLoading(false);
            if (response.isSuccess === true) {
                setCandidateInterview(response.data.interviews);
            } else {
                toast.warn(response.message);
            }
        });
    }

    useEffect(() => {
        getCandidateLatestInterview();
    }, []);


    const updateInterviewStatus = async (evaluation_interview_id, status) => {
        let obj = {
            "evaluation_interview_id": evaluation_interview_id,
            "status": status,
        }


        if (status === "rescheduled") {

            if (!selectedSlot || !date) {
                toast.warn("Please select date and time");
                return;
            }

            obj["slot"] = selectedSlot.slot
            obj["date"] = changeDateFormat(date, "YYYY-MM-DD")
        }

        if (status === "cancelled" || status === "rescheduled" || status === "no_show") {

            if (!reason) {
                toast.warn("Please enter reason");
                return false;
            }

            obj["reason"] = reason
        }

        setLoading(true);
        await ApiHelper.updateAppointmentStatus(obj).then((response) => {
            setLoading(false);
            if (response.isSuccess === true) {
                toast.success(response.message);
                getCandidateLatestInterview();
                closeModal();
            } else {
                toast.warn(response.message);
            }
        });
    }


    const closeModal = () => {
        onDeleteClose();
        onClose();
        onNoShowClose();
        setDate(today);
        setReason("");
    }

    const joinMeeting = (meeting_id) => {
        navigate("/evaluation/feedback/" + meeting_id);
    }

    const tabsData = [
        {
            text: "New Requests",
            isActive: isActiveTab('pending'),
            onClick: (tab) => onTabClick(tab),
            onClickData: 'pending',
            id: "list-pending-list",
            href: "#list-pending"
        },
        {
            text: "Accepted",
            isActive: isActiveTab('accepted'),
            onClick: (tab) => onTabClick(tab),
            onClickData: 'accepted',
            id: "list-accepted-list",
            href: "#list-accepted"
        },
        {
            text: "In Progress",
            isActive: isActiveTab('in_progress'),
            onClick: (tab) => onTabClick(tab),
            onClickData: 'in_progress',
            id: "list-in_progress-list",
            href: "#list-in_progress"
        },
        {
            text: "Completed",
            isActive: isActiveTab('completed'),
            onClick: (tab) => onTabClick(tab),
            onClickData: 'completed',
            id: "list-completed-list",
            href: "#list-completed"
        },
        {
            text: "No Show",
            isActive: isActiveTab('no_show'),
            onClick: (tab) => onTabClick(tab),
            onClickData: 'no_show',
            id: "list-no_show-list",
            href: "#list-no_show"
        },
        {
            text: "Cancelled",
            isActive: isActiveTab('cancelled'),
            onClick: (tab) => onTabClick(tab),
            onClickData: 'cancelled',
            id: "list-cancelled-list",
            href: "#list-cancelled"
        },
    ]


    return (
        <EvaluatorInterviewLayout
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
            isDeleteOpen={isDeleteOpen}
            onDeleteOpen={onDeleteOpen}
            onDeleteClose={onDeleteClose}
            isNoShowOpen={isNoShowOpen}
            onNoShowOpen={onNoShowOpen}
            onNoShowClose={onNoShowClose}
            date={date}
            setDate={setDate}
            selectedSlot={selectedSlot}
            setSelectedSlot={setSelectedSlot}
            loading={loading}
            selectedTab={selectedTab}
            selectedInterview={selectedInterview}
            setSelectedInterview={setSelectedInterview}
            reason={reason}
            setReason={setReason}
            candidateInterview={candidateInterview}
            updateInterviewStatus={updateInterviewStatus}
            joinMeeting={joinMeeting}
            tabsData={tabsData}
        />
    )
}

export default EvaluatorInterview