import React from 'react';
import ReactDOM from 'react-dom';
import './index.css'
import App from '@/App';
import "react-big-calendar/lib/css/react-big-calendar.css"
import { Provider, ErrorBoundary } from '@rollbar/react';
import { BrowserRouter } from 'react-router-dom';

const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
  environment: process.env.REACT_APP_ENV,
  captureUncaught: true,
  captureUnhandledRejections: true,
  captureIp: true,
  captureDeviceInfo: true,
  captureEmail: true,
  captureUsername: true,
  enabled: true,
}

ReactDOM.render(<Provider config={rollbarConfig}>
  <ErrorBoundary>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ErrorBoundary>
</Provider>, document.getElementById('root'));
