import React, { useEffect, useState } from 'react'

import { Button, Modal } from 'react-bootstrap';
import Login from './Login'
import Regsiter from './Register'
import Forgot from './ForgotPassword';

const AuthPopup = ({ type = "regsiter", isOpen, onOpen, onClose, isHeaderUpdate, setIsHeaderUpdate }) => {
    return (<>
        <Modal backdrop="static" show={isOpen} onHide={onClose} centered size="xl" className="auth-container-modal">
            <Modal.Header closeButton style={{ borderBottom: "0" }} />
            <Modal.Body className='auth-modal-body'>
                <div className='d-flex w-100 flex-row my-auto h-100 flex-md-column-reverse justify-content-center align-items-center'
                // h={"100%"}
                >

                    {
                        type === "login" ?
                            <Login onClose={onClose} isHeaderUpdate={isHeaderUpdate} setIsHeaderUpdate={setIsHeaderUpdate} />
                            :
                            type === "register" ?
                                <Regsiter onClose={onClose} />
                                :
                                type === "forgot" &&
                                <Forgot onClose={onClose} />
                    }

                </div>

            </Modal.Body>
        </Modal>
    </>
    )
}

export default AuthPopup