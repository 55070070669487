import React from 'react'
import { Navigate } from 'react-router-dom'
import * as storageConstants from '../constants/storageConstants';
import * as commonServices from '../services/common';
import { isEvaluator, isUserStatusPending } from '../utils/authUtil';

function Protected(props) {
    const Cmp = props.cmp;
    var auth = commonServices.getLocalData(storageConstants.AUTH);

    var redirect = "/?auth=login&redirect=" + window.location.pathname;
    var userRole = commonServices.getLocalData(storageConstants.USER_ROLE);
    const profile = commonServices.getLocalData(storageConstants.PROFILE);
    var isProgressRedirect = 0;

    if (auth && (userRole === "CA" || userRole === "CU") && window.location.pathname === '/jobs') {
        redirect = '/posted';
        isProgressRedirect = 1;
    }
    if (auth && (userRole === "CA" || userRole === "CU") && window.location.pathname === '/gigs') {
        redirect = '/posted-gigs';
        isProgressRedirect = 1;
    }

    if (auth && isEvaluator() && isUserStatusPending() && (window.location.pathname === '/workprofile' || window.location.pathname === '/my-account' || window.location.pathname === '/referrals' || window.location.pathname === '/chat')) {
        redirect = '/profile-in-review';
        isProgressRedirect = 1;
    }
    return (
        <div>
            {auth && isProgressRedirect === 0 ? <Cmp setJobSearch={props.setJobSearch} setLocationSearch={props.setLocationSearch} setLocationSearchName={props.setLocationSearchName} setTrigger={props.setTrigger} keywordSeachFilters={props?.keywordSeachFilters} setKeywordSeachFilters={props?.setKeywordSeachFilters} /> : <Navigate replace to={redirect} />}
        </div>
    )
}

export default Protected