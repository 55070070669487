import { ClipLoader } from "react-spinners";
import Text from "../_widgets/Text/Text";
import * as commonServices from "@/services/common";
import { css } from "@emotion/react";
import { getSkillsString, textCapitalise } from "@/utils/stringUtils";
import { highlightText } from "../../utils/stringUtils";
import mapPin from "@/Assets/svgs/map-pin.svg";
import IconResource from "../_widgets/IconResource/IconResource";
import Button from "../../components/_widgets/Button/Button";
import { getCandidateExperienceText } from "../../services/common";
import CustomPagination from "../Pagination/Pagination";
import ModalLoginRequired from "../Modals/ModalLoginRequired";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import ModalShowSimilarCandidates from "../Modals/ModalShowSimilarCandidates";

const ViewLaterLayout = ({
  loading,
  savedCandidates,
  removeFromViewLater,
  setActivePage,
  handlePaginationChange,
  activePage,
  showModal,
  setShowModal,
  candidateProfile,
  currentCandidateId,
  setLoading,
  selectedCriterias, setSelectedCriterias, showSimilarCandidatesModal, setShowSimilarCandidatesModal, isSimilarCriteriaChecked, showSimilarSearch
}) => {
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: var(--green);
    left: calc(50% - 75px);
    position: fixed;
  `;
  const renderTooltip = (type) => {
    let tooltipContent;

    switch (type) {
      case "evaluated":
        tooltipContent = "Evaluated";
        break;
      case "certified":
        tooltipContent = "Certified";
        break;
      case "trained":
        tooltipContent = "Trained";
        break;
      // Add more cases for different types if needed
      default:
        tooltipContent = "Default Tooltip";
    }

    return <Tooltip id="button-tooltip">{tooltipContent}</Tooltip>;
  };

  return (
    <div className="tt-container main-body">
      <ModalLoginRequired
        showModal={showModal}
        setShowModal={setShowModal}
        candidate_id={currentCandidateId}
      />

      {/* View-similar Candidates Modal */}
      <ModalShowSimilarCandidates
        showSimilarCandidatesModal={showSimilarCandidatesModal}
        setShowSimilarCandidatesModal={setShowSimilarCandidatesModal}
        showSimilarSearch={showSimilarSearch}
        setSelectedCriterias={setSelectedCriterias}
        selectedCriterias={selectedCriterias}
        isSimilarCriteriaChecked={isSimilarCriteriaChecked}
      />

      {loading ? (
        <div className="loader">
          <ClipLoader
            color="#000000"
            loading={true}
            css={override}
            size={150}
          />
        </div>
      ) : (
        <>
          <Text type="h2" text="Saved Candidates" />
          {savedCandidates ? (
            <div
              style={{ minHeight: "78vh" }}
              className="d-flex justify-content-between flex-column mt-4"
            >
              <div
                style={{
                  width: "100%",
                  marginTop: "15px",
                  height: "100%",
                }}
                className="cards__container"
              >
                {savedCandidates?.data?.map((data, key) => (
                  <div key={key}>
                    <div className="view-similar">
                      {data?.candidate?.is_pro_user && (
                        <div
                          style={{
                            position: "absolute",
                            backgroundColor: "var(--green)",
                            width: "80px",
                            color: "white",
                            fontSize: "var(--p)",
                            transform:
                              "rotate(-45deg) translateX(-22%) translateY(-80%)",
                            padding: "4px 10px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            left: 0,
                            top: 0,
                          }}
                        >
                          <Text type="small" text="PRO" />
                        </div>
                      )}

                      <div
                        className="feature_content hand-hover"
                        onClick={() => candidateProfile(data?.candidate_id)}
                      >
                        <div className="candidate-card-head">
                          <div className="candidate-card-basic">
                            <p className="paragraph_1 truncate-1">
                              {" "}
                              {textCapitalise(data?.candidate?.full_name)}
                            </p>
                            <p className="paragraph_2">
                              Exp:{" "}
                              {getCandidateExperienceText(
                                data?.candidate?.user_work_profile
                                  ?.total_experience
                              )}
                            </p>
                          </div>
                        </div>
                        <p
                          className="paragraph_3 d-flex gap-1"
                          style={{
                            color: "#4B8BFF",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          <img
                            style={{ height: "12px" }}
                            src={mapPin}
                            alt=""
                          />{" "}
                          <span
                            className="truncate-1"
                            title={"Current Location"}
                          >
                            {data?.candidate?.user_work_profile?.location_name
                              ? data?.candidate?.user_work_profile
                                ?.location_name
                              : "NA"}
                          </span>
                        </p>

                        <p
                          className="paragraph_2 truncate-2 trunc-desc"
                          style={{
                            minHeight: "40px",
                            height: "40px",
                            maxHeight: "40px",
                            fontSize: "var(--p1)",
                            marginTop: "0.4rem",
                          }}
                          title={
                            data.skills !== null
                              ? getSkillsString(data?.candidate?.skills)
                              : "N/A"
                          }
                          dangerouslySetInnerHTML={{
                            __html: highlightText(
                              " ",
                              textCapitalise(
                                data.candidate?.skills !== null
                                  ? getSkillsString(data?.candidate?.skills)
                                  : "N/A"
                              )
                            ),
                          }}
                        ></p>
                        <div
                          className="mt-2 d-flex justify-content-between"
                          mt={2}
                          justifyContent={"space-between"}
                        >
                          <div className="d-flex gap-2">
                            {data?.candidate?.is_evaluated && (
                              <OverlayTrigger
                                placement="top"
                                overlay={renderTooltip("evaluated")}
                              >
                                <div
                                  style={{
                                    border: "solid 1px green",
                                    width: "20px",
                                    height: "20px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRadius: "5px",
                                  }}
                                >
                                  <span style={{ color: "var(--green)" }}>
                                    E
                                  </span>
                                </div>
                              </OverlayTrigger>
                            )}

                            {data?.candidate?.is_certified && (
                              <OverlayTrigger
                                placement="top"
                                overlay={renderTooltip("certified")}
                              >
                                <div
                                  style={{
                                    border: "solid 1px green",
                                    width: "20px",
                                    height: "20px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRadius: "5px",
                                  }}
                                >
                                  <span style={{ color: "var(--green)" }}>
                                    C
                                  </span>
                                </div>
                              </OverlayTrigger>
                            )}

                            {data?.candidate?.is_trained && (
                              <OverlayTrigger
                                placement="top"
                                overlay={renderTooltip("trained")}
                              >
                                <div
                                  style={{
                                    border: "solid 1px green",
                                    width: "20px",
                                    height: "20px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRadius: "5px",
                                  }}
                                >
                                  <span style={{ color: "var(--green)" }}>
                                    T
                                  </span>
                                </div>
                              </OverlayTrigger>
                            )}
                          </div>

                          <div
                            className="feature_content_tags d-flex"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              minHeight: "30px",
                              maxHeight: "30px",
                            }}
                          >
                            {data?.candidate?.is_profile_viewed_by_user ? (
                              <p>
                                <IconResource
                                  type="doubletick"
                                  className="green-done"
                                />
                              </p>
                            ) : data?.candidate
                              ?.is_profile_viewed_by_company_user ? (
                              <p>
                                <IconResource
                                  type="doubletick"
                                  className="done"
                                />
                              </p>
                            ) : null}
                            <p></p>
                            <p
                              style={{ color: "#9398A1" }}
                              className="paragraph_4 truncate-1"
                              title={commonServices.dateFormat(
                                data?.candidate?.user_work_profile?.updated_at
                              )}
                            >
                              {commonServices.dateFormat(
                                data?.candidate?.user_work_profile?.updated_at
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="hover-popup">
                        <Button
                          buttonType="secondary"
                          onClick={() => removeFromViewLater(data.id)}
                          buttonIcon={<IconResource type={"delete"} />}
                          text="Remove"
                        />
                      </div>
                      <div
                        onClick={() => setShowSimilarCandidatesModal(data)}
                        className="circular-popup"
                      >
                        <i
                          className="fa fa-clone"
                          aria-hidden="true"
                        ></i>
                        <p>View Similar</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="my-3">
                <CustomPagination
                  activePage={activePage}
                  onPageChange={handlePaginationChange}
                  totalData={savedCandidates?.total}
                  pageLength={savedCandidates?.per_page}
                  lastPage={savedCandidates?.last_page}
                  currentPage={setActivePage}
                />
              </div>
            </div>
          ) : (
            <div className="no_jobs mt-5">
              <img
                onError={commonServices.imgError}
                src="../Assets/svgs/search_no_result.svg"
                alt=""
              />
              <h2>No Saved Candidates!</h2>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ViewLaterLayout;
