import React from 'react';
import './Modal.scss';
import AppModal from './AppModal';
import { useNavigate } from 'react-router-dom';
import Text from '../_widgets/Text/Text';

function ModalFreeTTCash({ showModal, setShowModal }) {

    const navigate = useNavigate();

    const handleSubmit = () => {
        setShowModal(false)
        navigate("/my-account?tab=smart_apply&free_cash=true")
    }

    return (
        <>

            <AppModal
                title={"Free TT Cash"}
                isOpen={showModal}
                closeModal={() => { setShowModal(false) }}
                isFooter={true}
                submit={() => { handleSubmit() }}
                size={"sm"}
                submitText='Unlock'
            >

                <div className="modal_body">
                    <div>
                        <Text
                            text="Unlock Free 1000 TT Cash 💵"
                            style={{
                                fontSize: "var(--p1)",
                                color: "gray.600",
                                fontWeight: "semibold",
                                marginBottom: "10px"
                            }}
                        />
                    </div>

                </div>

            </AppModal>
        </>
    )
}

export default ModalFreeTTCash
