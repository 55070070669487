import React from 'react';


import { isNullOrEmpty, getYoutubeVideoId } from '@/utils/stringUtils';
const Profile = ({
    profile, Text
}) => {

    return (
        <>
            <div className="left" style={{ border: 'solid 1px var(--gray)', borderRadius: '16px', padding: "30px 30px" }}>

                <div className="d-flex items-center w-full justify-between" style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
                    <Text type={"h2"} text={profile?.name} />
                    <div
                    // style={{ border: 'solid 1px black', padding: "10px", borderRadius: '50%', height: '20px', width: "10px" }}
                    >
                    </div>
                </div>
                <div className="d-flex gen-details" style={{ gap: '5px', marginTop: "10px", alignItems: "center" }}>
                    <i class="bi bi-geo-alt-fill"></i>
                    <Text text={profile?.address} style={{ color: 'var(--gray8)' }} />
                    <Text type="span" text='<i class="bi bi-dot"></i>'></Text>
                    <Text text={`Posted ${profile?.updated_at}`} style={{ color: 'var(--gray8)' }}></Text>
                </div>

                <br />

                <div class="container text-start" style={{ margin: '10px 0', padding: 0, width: '80%' }}>
                    <div class="row">
                        <div class="col">
                            <Text text="Employee" style={{ color: 'var(--gray8)', fontSize: '0.875em' }}></Text>
                            <div style={{ color: 'var(--black3)', fontSize: '1em', fontWeight: 500 }}>
                                <i class="bi bi-person"></i> {profile?.size?.name}
                            </div>
                        </div>
                        <div class="col">
                            <Text text="Business Field" style={{ color: 'var(--gray8)', fontSize: '0.875em' }} ></Text>
                            <div style={{ color: 'var(--black3)', fontSize: '1em', fontWeight: 500 }}>
                                <i class="bi bi-person"></i> {profile?.industry?.name}
                            </div>
                        </div>
                        <div class="col ">
                            <Text text="Status" style={{ color: 'var(--gray8)', fontSize: '0.875em' }}></Text>
                            <div style={{ color: 'var(--black3)', fontSize: '1em', fontWeight: 500 }}>

                                <i class="bi bi-briefcase"></i> {profile?.jobs && profile?.jobs.length > 0 ? 'Hiring' : ''}
                            </div>
                        </div>
                    </div>
                </div>

                <br />

                <Text type="h4" text="About Company"></Text>
                <Text text={profile?.description} className='desc'></Text>

                <br />

                <Text text={`Working at ${profile?.name}`} type="h4"></Text>
                {profile?.links && profile?.links?.map((link, i) => {
                    return <div className="video__frame" style={{ marginBottom: "15px", marginTop: "10px" }} key={i}>
                        <iframe
                            width="853"
                            height="380"
                            src={"https://www.youtube.com/embed/" + getYoutubeVideoId(link?.value ?? '')}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="Embedded youtube"
                        />
                    </div>
                })}

                <br />
                <Text type="h4" text="Photos"></Text>

                <div className="photos">
                    {profile?.medias && profile?.medias.map((media, i) => {
                        return <div className="photo" key={i}>
                            <img src={media.url} />
                        </div>
                    })}

                </div>

            </div>

            <div className="right">
                <div style={{ border: 'solid 1px var(--gray)', borderRadius: '16px', padding: '30px 30px' }}>

                    <Text type="h6" text="Headquarters"></Text>
                    <Text text={profile?.address}></Text>
                    <br />
                    <Text type="h6" text="Employees"></Text>
                    <Text text={profile?.size?.name}></Text>
                    <br />
                    <Text type="h6" text="Industry"></Text>
                    <Text text={profile?.industry?.name}></Text>
                    <br />
                    <br />
                    <Text type="h6" text="Additional Domains"></Text>

                    {profile?.domains !== undefined && profile?.domains.map((data, i) => {
                        return <Text text={data.name}></Text>
                    })}

                    <br />
                    <br />

                    <Text type="h6" text="Social Accounts"></Text>

                    {!isNullOrEmpty(profile?.facebook) ? <><p className='hand-hover'>Facebook</p><br /></> : null}
                    {!isNullOrEmpty(profile?.instagram) ? <><p className='hand-hover'>Instagram</p><br /></> : null}
                    {!isNullOrEmpty(profile?.linkedin) ? <><p className='hand-hover'>Linkedin</p><br /></> : null}
                    {!isNullOrEmpty(profile?.twitter) ? <><p className='hand-hover'>Twitter</p></> : null}

                </div>
                <div style={{ border: 'solid 1px var(--gray)', borderRadius: '16px', padding: '30px 30px', marginTop: '20px' }}>


                    <a className='hand-hover' href={undefined} onClick={() => window.open(profile?.website, '_blank')}>{profile?.name} website <i class="bi bi-box-arrow-up-right" style={{ marginLeft: "10px" }}></i></a>


                </div>
            </div>

        </>
    )
}

export default Profile