import { useState } from 'react';
import './Modal.scss';
import * as commonServices from '@/services/common';
import emailImg from "@/Assets/svgs/email.svg";
import phone from "@/Assets/svgs/phone.svg";
import marker from "@/Assets/svgs/marker.svg";
import * as storageConstants from '@/constants/storageConstants';
import AppModal from './AppModal';

function ModalContactDetails({ showModal, setShowModal, modalData }) {

    var masterData = commonServices.getLocalData(storageConstants.DATA);
    const [data] = useState((masterData !== null && masterData.system_config !== null && masterData.system_config !== undefined) ? masterData.system_config : {});

    return (
        <AppModal
            title={modalData?.heading}
            isOpen={showModal}
            closeModal={() => setShowModal(prev => !prev)}
            size={"md"}
        >
            <div className="modal_body">
                <div className="row info-content">
                    <div className="col">
                        <span className="input-lebel">
                            {modalData?.paragraph}
                        </span>

                        {modalData?.showContactDetails !== undefined && modalData?.showContactDetails ?
                            <ul className='contact-details'>
                                <li><img onError={commonServices.imgError} src={emailImg} alt="" /><p>maya@truetalent.io</p></li>
                                {commonServices.checkRecordExists(data?.company_phone?.value) ?
                                    <li><img onError={commonServices.imgError} src={phone} alt="" /><p>{data?.company_phone?.value}</p></li>
                                    : null}
                                {commonServices.checkRecordExists(data?.company_address?.value) ?
                                    <li><img onError={commonServices.imgError} src={marker} alt="" /><p>{data?.company_address?.value}</p></li>
                                    : null}
                            </ul>
                            : null}
                    </div>
                </div>

                <div className="row">
                    <div className="col"></div>
                    <div className="col">
                        <div className="form_buttons">
                            <input type="submit" value={modalData?.buttonText} onClick={() => setShowModal(prev => !prev)} />
                        </div>
                    </div>
                </div>
            </div>
        </AppModal>
    )
}

export default ModalContactDetails;
