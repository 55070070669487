/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react'
import './Modal.scss';
import * as commonServices from '@/services/common';
import * as storageConstants from '@/constants/storageConstants';
import * as erros from '@/constants/stringConstants';
import InputMask from 'react-input-mask';
import { isExist, isNotNullOrEmpty } from '@/utils/objectUtil';
import * as toast from "@/wrapper/toast";
import AppModal from './AppModal';
import DatePickerWrapper from '../Common/DatePicker';
import Input from '../_widgets/Input/Input';

function Modal1({ showModal, setShowModal, profile, setProfile, setDataAutoSave, type = "degree" }) {
    const [degree, setDegree] = useState([]);
    const datePicker1Ref = useRef();
    const datePicker2Ref = useRef();
    const datePicker3Ref = useRef();

    useEffect(() => {
        setDegree(isExist(profile.userWorkProfileDetail) && isExist(profile.userWorkProfileDetail[type]) && isNotNullOrEmpty(profile.userWorkProfileDetail[type]) ? profile.userWorkProfileDetail[type] : [{}]);
    }, [showModal])

    const onChange = (e, key) => {

        var tempArray = [...degree];
        tempArray[key][e.target.name] = e.target.value;
        setDegree(tempArray);
    }

    const changePresent = (e, key) => {
        let tempArray = []
        if (e.target.checked) {
            tempArray = [...degree];
            tempArray[key]['is_present'] = '1';
            tempArray[key]['to_date'] = null;
            setDegree(tempArray);
        } else {
            tempArray = [...degree];
            tempArray[key]['is_present'] = '0';
            setDegree(tempArray);
        }

    }

    const deleteRow = async (e, key) => {
        e.preventDefault();
        var tempProfileArr = [...degree];
        tempProfileArr.splice(key, 1);
        setDegree(tempProfileArr);
    }

    const addDegree = (e) => {
        var isError = false;
        degree && degree.map((element, key) => {
            var currentRow = key + 1;
            if (!degree[key].awarded_by) {
                isError = true;
                toast.warn(erros.EMPTY_SCHOOL_ERROR + " at row " + currentRow);
            } else if (!degree[key].title) {
                isError = true;
                toast.warn(erros.EMPTY_DEGREE_ERROR + " at row " + currentRow);
            } else if (!degree[key].from_date) {
                isError = true;
                toast.warn(erros.EMPTY_FROM_DATE_ERROR + " at row " + currentRow);
            }
            else if ((!degree[key].to_date && degree[key].is_present === '0') || (!degree[key].to_date && !degree[key].is_present)) {
                isError = true;
                toast.warn(erros.EMPTY_TO_DATE_ERROR + " at row " + currentRow);
            } else if (degree[key].is_present === '0' && new Date(degree[key].from_date) > new Date(degree[key].to_date)) {
                isError = true;
                toast.warn(erros.EDUCATION_FROM_TO_DATE_ERROR + " at row " + currentRow);
            }
        });

        if (!isError) {
            var tempArray = { ...profile };
            if (tempArray.userWorkProfileDetail === undefined) {
                tempArray.userWorkProfileDetail = {};
            }
            tempArray.userWorkProfileDetail[type] = degree;
            setProfile(tempArray);
            setDataAutoSave(true);
            setShowModal(false);
        }


    }


    return (
        <>
            <AppModal
                title={"Add " + (type === "degree" ? "Education" : "Course")}
                size={"xl"}
                isOpen={showModal}
                closeModal={() => setShowModal(false)}
                submit={() => { addDegree(); }}
                isFooter={true}
            >
                <div style={{ minHeight: "400px" }} className="modal_body">
                    <div className=''>
                        {degree && degree.map((element, key) => {
                            return <>
                                <div className="row row-gap-2">
                                    <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                        <Input maxLength={30} label="School/University/Institution" type="text" value={degree && degree[key] && degree[key].awarded_by ? degree[key].awarded_by : ""} id='awarded_by' placeholder="Enter School/University/Institution" name='awarded_by' onChange={(e) => onChange(e, key)} />
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                        <Input maxLength={30} label="Degree/Qualification" type="text" value={degree && degree[key] && degree[key].title ? degree[key].title : ""} id='title' placeholder="Enter Degree/Qualification" name='title' onChange={(e) => onChange(e, key)} />
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 ">
                                        <DatePickerWrapper
                                            dateRef={datePicker1Ref}
                                            label="Attended From"
                                            customInput={<InputMask mask="99/99/9999" />}
                                            selected={degree && degree[key] && degree[key].from_date ? new Date(degree[key].from_date) : null}
                                            onChange={(date) => { datePicker1Ref?.current?.setOpen(false); date.setDate(date.getDate() + 1); var fDate = date.toISOString().substring(0, 10).split('-').reverse(); var tempArray = [...degree]; tempArray[key]['from_date'] = fDate[1] + '/' + fDate[0] + '/' + fDate[2]; setDegree(tempArray); }}
                                            dateFormat="dd/MM/yyyy"
                                            maxDate={new Date()}
                                            showYearDropdown
                                            yearDropdownItemNumber={storageConstants.YEARS_TOTAL}
                                            scrollableYearDropdown
                                            placeholderText="DD/MM/YYYY" />
                                        {/* <label className="input-lebel" >
                                            Attended From
                                        </label>
                                        <div className="input-group">
                                            <span className="input-group-text" id="basic-addon1"><img className='input-icon' onError={commonServices.imgError} src="/Assets/svgs/calendar.svg" alt="" /></span>
                                        </div> */}
                                    </div>

                                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 ">
                                        {degree && degree[key] && degree[key].is_present && degree[key].is_present === "1" ?
                                            <div>

                                                <DatePickerWrapper
                                                    dateRef={datePicker2Ref}
                                                    label="Attended Till"
                                                    customInput={<InputMask mask="99/99/9999" />}
                                                    selected={degree && degree[key] && degree[key].to_date ? new Date(degree[key].to_date) : null}
                                                    onChange={(date) => { datePicker2Ref?.current?.setOpen(false); date.setDate(date.getDate() + 1); var fDate = date.toISOString().substring(0, 10).split('-').reverse(); var tempArray = [...degree]; tempArray[key]['from_date'] = fDate[1] + '/' + fDate[0] + '/' + fDate[2]; tempArray[key]['is_present'] = '0'; setDegree(tempArray); }} dateFormat="dd/MM/yyyy" minDate={degree && degree[key] && degree[key].from_date ? degree[key].from_date : ''} maxDate={new Date()} showYearDropdown yearDropdownItemNumber={storageConstants.YEARS_TOTAL} scrollableYearDropdown
                                                    disabled
                                                    placeholderText="Present"
                                                />
                                                <div className="col d-flex justify-content-start">
                                                    <input type="checkbox" className='present-check' id={"c_b" + key} onChange={(e) => changePresent(e, key)} checked={degree && degree[key] && degree[key].is_present && degree[key].is_present === "1" ? true : false} />
                                                    <label htmlFor={"c_b" + key} className="input-lebel3" > Present</label>
                                                </div>
                                            </div>
                                            :
                                            <div>
                                                <DatePickerWrapper
                                                    dateRef={datePicker3Ref}
                                                    label="Attended Till"
                                                    customInput={<InputMask mask="99/99/9999" />}
                                                    selected={degree && degree[key] && degree[key].to_date ? new Date(degree[key].to_date) : null}
                                                    onChange={(date) => { datePicker3Ref?.current?.setOpen(false); date.setDate(date.getDate() + 1); var fDate = date.toISOString().substring(0, 10).split('-').reverse(); var tempArray = [...degree]; tempArray[key]['to_date'] = fDate[1] + '/' + fDate[0] + '/' + fDate[2]; tempArray[key]['is_present'] = '0'; setDegree(tempArray); }} dateFormat="dd/MM/yyyy"
                                                    placeholderText="DD/MM/YYYY" minDate={degree && degree[key] && degree[key].from_date ? new Date(degree[key].from_date) : null} maxDate={new Date()} showYearDropdown yearDropdownItemNumber={storageConstants.YEARS_TOTAL} scrollableYearDropdown
                                                />
                                                <div className="col d-flex justify-content-start">
                                                    <input type="checkbox" className='present-check' id={"c_b" + key} onChange={(e) => changePresent(e, key)} checked={degree && degree[key] && degree[key].is_present && degree[key].is_present === "1" ? true : false} />
                                                    <label htmlFor={"c_b" + key} className="input-lebel3" > Present</label>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>



                                <div className='row mb-4'>
                                    <div className="col-md-2 col-6">
                                        <a className='delete-row' onClick={(e) => { deleteRow(e, key) }}> <img className='' src='../Assets/svgs/trash.svg'></img> Remove</a>
                                    </div>
                                    {key === degree.length - 1 ?

                                        <div className="col-md-3 col-6">
                                            <a className='add-more' onClick={() => { setDegree([...degree, {}]) }}> <img src='../Assets/svgs/plus_circle.svg'></img> Add More</a>
                                        </div>

                                        : ''}

                                    <div className="col"></div>
                                    <div className="col"></div>
                                    <div className="col"></div>
                                    <div className="col"></div>
                                    <div className="col"></div>
                                    <div className="col"></div>
                                </div>
                            </>
                        })}
                    </div>
                    {degree?.size > 0 ? <></> :
                        <div className='h-100'>
                            <p style={{ border: "1px dotted", cursor: "not-allowed", color: "var(--gray8)", padding: "0.5rem", minHeight: "50px", textAlign: "center", borderRadius: "var(--border-radius)", fontSize: "var(--p1)", width: "100%", height: "100%", lineHeight: "20px" }}>
                                Highlight your education details with details of your specific areas of learning
                            </p>
                        </div>
                    }
                </div>
            </AppModal>

        </>
    )
}

export default Modal1;