import React from 'react'
import RegistrationSteps from '../Register/RegistrationSteps/RegistrationSteps'
import { useLocation } from 'react-router-dom'

const RegistrationStepsPage = ({ isHeaderUpdate, setIsHeaderUpdate }) => {

    const { state } = useLocation()


    const [currentStep, setCurrentStep] = React.useState(state.currentStep || 1)
    const [profile, setProfile] = React.useState(state.profile || {})
    const [selectedProfile] = React.useState(state.selectedProfile || "")


    const { showModalApplyJob, referredData, setShowStep } = state


    return (
        <RegistrationSteps selectedProfile={selectedProfile} currentStep={currentStep} setCurrentStep={setCurrentStep} profile={profile} setProfile={setProfile} showModalApplyJob={showModalApplyJob} referredData={referredData} isHeaderUpdate={isHeaderUpdate} setIsHeaderUpdate={setIsHeaderUpdate} setShowStep={setShowStep} />
    )
}

export default RegistrationStepsPage