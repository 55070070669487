import React from 'react'

const ButtonOutlined = ({ onClick, text }) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <button style={{ borderRadius: '30px', padding: '10px 20px', border: 'solid 1px var(--green)', color: 'var(--green)', background: 'transparent', fontWeight: 600, fontSize: "0.875em" }}
                onClick={onClick} className="button_1"
            >{text}</button>
        </div>
    )
}

export default ButtonOutlined