import React, { useState } from "react";
import "./Modal.scss";
import * as storageConstants from "@/constants/storageConstants";
import * as ApiHelper from "@/services/apiHelper";
import * as commonServices from "@/services/common";
import * as error from "@/constants/stringConstants";
import * as toast from "@/wrapper/toast";
import AppModal from "./AppModal";

function ModalJobReport({
  showModal,
  setShowModal,
  jobDetail,
  job_name,
  job_id,
  type = "",
}) {
  const masterData = commonServices.getLocalData(storageConstants.DATA);
  let [loading, setLoading] = useState(false);
  // let [color] = useState("#000000");
  const [flagMessage, setFlagMessage] = useState("");
  const [issueId, setIssueId] = useState("");
  const [issues] = useState(masterData?.issues);

  const reportJob = async () => {
    if (!job_id) {
      toast.warn(error.SOMETHING_WENT_WRONG);
    } else if (issueId === null || issueId === "") {
      toast.warn("Please select issue!");
    } else {
      setLoading(true);
      const formData = new FormData();
      formData.append("flag_msg", flagMessage);
      formData.append("issue_id", issueId);
      if (type === "gig") {
        formData.append("gig_id", job_id);
        await ApiHelper.reportGig(formData).then((response) => {
          setLoading(false);
          setShowModal((prev) => !prev);
          if (response.isSuccess === true) {
            toast.success(response.message);
          } else {
            toast.warn(response.message);
          }
          jobDetail();
        });
      } else {
        formData.append("job_id", job_id);
        await ApiHelper.reportJob(formData).then((response) => {
          setLoading(false);
          setShowModal((prev) => !prev);
          if (response.isSuccess === true) {
            toast.success(response.message);
          } else {
            toast.warn(response.message);
          }
          jobDetail();
        });
      }
    }
  };

  return (
    <>
      <AppModal
        isOpen={showModal}
        closeModal={() => setShowModal((prev) => !prev)}
        title={`Flag - ${job_name}`}
        size="lg"
        submit={reportJob}
        isFooter={true}
        loading={loading}
      >
        <div className="modal_body">
          <div className="">
            <div className="row">
              <div className="col">
                <label className="input-lebel">Issue</label>
                <select
                  className="form-select form-select-lg"
                  id="issue-id"
                  value={issueId}
                  onChange={() =>
                    setIssueId(document.getElementById("issue-id").value)
                  }
                >
                  <option value="">Select issue</option>
                  {issues &&
                    issues.map((data, key) => {
                      return (
                        <option key={key} value={data.id}>
                          {data.name}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <label className="input-lebel">
                  Additional information(Optional)
                </label>
                <textarea
                  className="form-control form-textbox"
                  rows="4"
                  cols="50"
                  placeholder="Please include your issue in more detail if possible."
                  onChange={(e) => setFlagMessage(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </AppModal>
    </>
  );
}

export default ModalJobReport;
