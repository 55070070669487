import React, { useState } from 'react';
import './About.scss';
import * as commonServices from '@/services/common';
import { MaxWidthContainer } from '@/components/Common/MaxWidthContainer';
import Text from "../_widgets/Text/Text";

function About() {
    // const navigate = useNavigate();
    const [empCandstatus, setEmpCandstatus] = useState(0);

    const handleClick = (e) => {
        setEmpCandstatus(e)
    }

    const colors = {
        green: '#14BC9A',
        orange: '#F59A74'
    }

    return (
        <MaxWidthContainer pageKey="about">
            <div className='about_page'>
                <div className='row text-center'>
                    <div className="bg_container_box bg_green">
                        {/* BANNER SECTION */}
                        {/* <div className='bg_green_absolute'></div> */}
                        <div className="tt-container">
                            <div className='mt-5 main-heading'>
                                <span className='exp-hr'>Experience Hiring 2.0 with <span style={{ color: colors.green }}>TrueTalent</span></span>
                                {/* <p className='exp-pra mt-3 text-center'>TrueTalent is the first and only Recruitment as a Service (RaaS) employment platform in India. It is one of the most advanced job search platforms, taking hiring to the next level for both employing companies and job seekers, while maintaining an extremely simple and user-friendly interface.</p> */}
                            </div>
                            <div className='mt-5 about-banner'>
                                <img
                                    style={{ margin: "auto" }}
                                    onError={commonServices.imgError}
                                    src="./Assets/images/aboutus.png?1"
                                    alt="" className='image2' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='body1'>
                    <div className="card_sec1">
                        <div className="card1">
                            <div className="tt-container">
                                <div className='mt-4 text-center'>
                                    <Text type="p" text="WHY TRUETALENT" style={{ color: colors.orange }} />
                                </div>
                                <div className='row mt-3'>
                                    <div className='col-lg-6 col-md-6 col-sm-12 text-center' >
                                        <div className='employee-button'>
                                            <p className='emp-button'
                                                onClick={() => { handleClick(0) }}
                                                style={{ color: empCandstatus === 0 ? colors.green : '' }}>For Employee</p>
                                            <hr
                                                style={{ color: empCandstatus === 0 ? colors.green : '' }} />
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-sm-12 text-center'>
                                        <div className='candidate-button'>
                                            <p className='emp-button'
                                                onClick={() => { handleClick(1) }}
                                                style={{ color: empCandstatus === 1 ? colors.green : '' }}> For Candidate</p>
                                            <hr
                                                style={{ color: empCandstatus === 1 ? colors.green : '' }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {empCandstatus === 0 ?
                                <div className='employer-section mt-5'>
                                    <div className='section-first'>
                                        <div className='row mt-3'>
                                            <div className='col-md-12 text-center'>
                                                <Text type='h3' text='Why Hiring Companies Love Us?' />
                                            </div>
                                        </div>
                                    </div>

                                    <div className='section-second' >
                                        <div className='tt-container row mt-5 mb-5'>
                                            <div className='col-lg-4 col-md-4 col-sm-12 mb-4' >
                                                <div className='card1 text-center' >
                                                    <span className='images-icon'>
                                                        <img style={{
                                                            margin: "auto"
                                                        }} onError={commonServices.imgError} src="./Assets/images/aboutus/card1.png" alt="" />
                                                    </span>
                                                    <Text type='h5' text='Advanced Features' />

                                                    <Text type='h7' text="TrueTalent's advanced features makes hiring talented job seekers an easy and cost-effective process for companies of all sizes." className='content-paragraph1' />

                                                </div>
                                            </div>
                                            <div className='col-lg-4 col-md-4 col-sm-12 mb-4' >
                                                <div className='card1 text-center'>
                                                    <span className='images-icon'>
                                                        <img
                                                            style={{
                                                                margin: "auto"
                                                            }}
                                                            onError={commonServices.imgError} src="./Assets/images/aboutus/card2.png" alt="" />
                                                    </span>

                                                    <Text type='h5' text='Pricing Plans' />

                                                    <Text type='h7' text="TrueTalent has a suitable option and pricing plan for you, regardless of whether you are seeking to fill a single position in the near future or hundreds of positions across an array of skills and industries." className='content-paragraph1' />
                                                </div>
                                            </div>

                                            <div className='col-lg-4 col-md-4 col-sm-12 mb-4' >
                                                <div className='card1 text-center'>
                                                    <span className='images-icon'>
                                                        <img
                                                            style={{
                                                                margin: "auto"
                                                            }}
                                                            onError={commonServices.imgError} src="./Assets/images/aboutus/card3.png" alt="" />
                                                    </span>
                                                    <Text type='h5' text='Pay as you go' />

                                                    <Text type='h7' text="With a Pay-as-you-go pricing model, TrueTalent's costs are always proportional to your requirements." className='content-paragraph1' />
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className='section-third' >
                                        <div className="bg_container_box bg_green">
                                            {/* <div className='bg_green_absolute'></div> */}

                                            <div className='tt-container row'>
                                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                                    <span className=''>
                                                        <img onError={commonServices.imgError} src="./Assets/images/aboutus/s1.png" alt="" className='image left-section' />
                                                    </span>
                                                </div>
                                                <div className='col-lg-6 col-md-6 col-sm-12 section1 right-section'>
                                                    <Text type='h7' text="AI + HI (Human intelligence)" className='content-title' />

                                                    <Text type='h3' text='AI driven JD (Job Description) Creation' className='content-sub-title' />

                                                    <Text type='h7' text="TrueTalent assists you in generating a well-articulated and thorough JD for each job opening you have, using only the skills and years of experience you have at their disposal. A detailed JD is always advantageous for attracting the attention of competent applicants. In addition to TrueTalent, JD created can be used to post positions on any job platform or the organization's internal job boards." className='content-paragraph' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='section-fourth text-center' >
                                        <div className='row tt-container '>
                                            <div className='col-lg-6 col-md-6 col-sm-12 mt-5 section1 left-section'>
                                                <Text type='h7' text="Smart Search" className='content-title' />

                                                <Text type='h3' text='Skill match (Beyond the Keywords)' className='content-sub-title' />

                                                <Text type='h7' text="While most talent search platforms provide search options based on keywords, which leads to a large number of irrelevant and low-matching profiles and ultimately makes it more difficult for recruiters to obtain relevant profiles, TrueTalent uses a customized and advanced technology algorithm to identify candidates whose skills precisely match the search skills. Multiple parameters are taken into account by the customized algorithm in order to provide you with the best results." className='content-paragraph' />
                                            </div>
                                            <div className='col-lg-6 col-md-6 col-sm-12 mt-5 px-5'>
                                                <span className=''>
                                                    <img onError={commonServices.imgError} src="./Assets/images/aboutus/s2.png" alt="" className='image right-section' />
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='section-fifth   text-center mt-5' >
                                        <div className="bg_container_box bg_green">
                                            {/* <div className='bg_green_absolute'></div> */}
                                            <div className='row tt-container'>
                                                <div className='col-lg-6 col-md-6 col-sm-12 '>
                                                    <span className=''>
                                                        <img onError={commonServices.imgError} src="./Assets/images/aboutus/s3.png" alt="" className='image left-section' />
                                                    </span>
                                                </div>
                                                <div className='col-lg-6 col-md-6 col-sm-12 section1 right-section px-5'>
                                                    <Text type='h7' text="Employment Branding" className='content-title' />

                                                    <Text type='h3' text='Free Job posting and Employer Branding' className='content-sub-title' />

                                                    <Text type='h7' text="At TrueTalent, employing companies have the unrestricted ability to post as many job openings as they desire at no additional cost, as we are not only a candidate search platform but also an employer branding platform that assists businesses in increasing their Employer Brand Value." className='content-paragraph' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='section-sixth text-center mt-5' >
                                        <div className='row tt-container'>
                                            <div className='col-lg-6 col-md-6 col-sm-12 section1 mt-5 left-section '>
                                                <Text type='h7' text="User Access" className='content-title' />

                                                <Text type='h3' text='Free multi-user access' className='content-sub-title' />

                                                <Text type='h7' text="We believe what you receive should be shared with everyone on your team at no additional cost, so there are no fees for additional logins. In fact, we do not charge for any login, not even the first one." className='content-paragraph' />
                                            </div>
                                            <div className='col-lg-6 col-md-6 col-sm-12 px-5'>
                                                <span className=''>
                                                    <img onError={commonServices.imgError} src="./Assets/images/aboutus/s4.png" alt="" className='image right-section' />
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='section-seventh  text-center mt-5' >
                                        <div className="bg_container_box bg_green">
                                            {/* <div className='bg_green_absolute'></div> */}
                                            <div className='row tt-container'>
                                                <div className='col-lg-6 col-md-6 col-sm-12 '>
                                                    <span className=''>
                                                        <img onError={commonServices.imgError} src="./Assets/images/aboutus/s5.png" alt="" className='image left-section' />
                                                    </span>
                                                </div>
                                                <div className='col-lg-6 col-md-6 col-sm-12 section1 mt-5 right-section px-5'>
                                                    <Text type='h7' text="Payment" className='content-title' />

                                                    <Text type='h3' text='Pay-as-you-go' className='content-sub-title' />

                                                    <Text type='h7' text="What should least burden hiring companies is the sourcing cost, as it is only one of the many costs associated with hiring employees. Hence all our plans are pay-as-you-go. Hence all our plans are pay-as-you-go. You spend only what you need and avoid all bulk and high onetime purchases when you use TrueTalent." className='content-paragraph' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='section-eight mt-5 mb-5' >
                                        <div className='row tt-container'>
                                            <div className='col-lg-6 col-md-6 col-sm-12 section1 mt-5 left-section'>
                                                <Text type='h7' text="Job Type" className='content-title' />

                                                <Text type='h3' text='Full Time / Gig work' className='content-sub-title' />

                                                <Text type='h7' text="TrueTalent is the only job platform that provides employers with the options to hire both full time employees as well as gig (freelance) workers. And all these through a single sign-on. So whether you are looking for hiring a full time employee or looking to hire for a short assignment, you need to look no further than TrueTalent." className='content-paragraph' />
                                            </div>
                                            <div className='col-lg-6 col-md-6 col-sm-12 px-5'>
                                                <span className=''>
                                                    <img onError={commonServices.imgError} src="./Assets/images/aboutus/s6.png" alt="" className='image right-section' />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className='candidate-section mt-5'>
                                    <div className='section-first'>
                                        <div className='row mt-3'>
                                            <div className='col-md-12 text-center mt-5'>
                                                <Text type='h3' text='Why Jobseekers Prefer Our Platform?' />
                                                {/* <p className='why-hire'>TrueTalent has some of the most unique features that differentiates us from the other job search platforms.
                                                    As a candidate when you come to TrueTalent, you not just get great opportunities for your next job, but create a long-term career growth plan.
                                                    <br /><br /><br /> */}
                                                <Text type='h7' text="Here is how we help you build a successful career:" className='why-hire mt-2' />
                                            </div>
                                        </div>
                                    </div>

                                    <div className='section-third text-center mt-5' >
                                        <div className="bg_container_box bg_green">
                                            {/* <div className='bg_green_absolute'></div> */}
                                            <div className='row tt-container'>
                                                <div className='col-lg-6 col-md-6 col-sm-12 px-5 '>
                                                    <span className=''>
                                                        <img onError={commonServices.imgError} src="./Assets/images/aboutus/candidates/c1.png" alt="" className='image' />
                                                    </span>
                                                </div>
                                                <div className='col-lg-6 col-md-6 col-sm-12 section1 mt-5'>
                                                    <Text type='h7' text="Unique Profile" className='content-title' />

                                                    <Text type='h3' text='Increased visibility to Recruiters' className='content-sub-title' />

                                                    <Text type='h7' text="TrueTalent helps you stand out among job seekers by providing a unique online presence and profile. Recruiters can access your in-depth profile and offer opportunities that match your skills and aptitude." className='content-paragraph' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='section-fourth text-center mt-5' >
                                        <div className='row tt-container'>
                                            <div className='col-lg-6 col-md-6 col-sm-12 section1 mt-5 '>
                                                <Text type='h7' text="Recommendations" className='content-title' />

                                                <Text type='h3' text='Personalized Job Recommendations' className='content-sub-title' />

                                                <Text type='h7' text="TrueTalent uses smart algorithms to provide personalized job recommendations based on your skills and experience, increasing the chances of finding the perfect job." className='content-paragraph' />
                                            </div>
                                            <div className='col-lg-6 col-md-6 col-sm-12 px-5'>
                                                <span className=''>
                                                    <img onError={commonServices.imgError} src="./Assets/images/aboutus/candidates/c2.png" alt="" className='image' />
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='section-fifth text-center mt-5' >
                                        <div className="bg_container_box bg_green">
                                            {/* <div className='bg_green_absolute'></div> */}
                                            <div className='row tt-container'>
                                                <div className='col-lg-6 col-md-6 col-sm-12  '>
                                                    <span className=''>
                                                        <img onError={commonServices.imgError} src="./Assets/images/aboutus/candidates/c3.png" alt="" className='image' />
                                                    </span>
                                                </div>
                                                <div className='col-lg-6 col-md-6 col-sm-12 section1 px-5'>
                                                    <Text type='h7' text="Personal Branding" className='content-title' />

                                                    <Text type='h3' text='Resume Maker' className='content-sub-title' />

                                                    <Text type='h7' text="Our online resume maker allows you to create a professional and compelling resume that highlights your skills and competencies, increasing your chances of landing the perfect job." className='content-paragraph' />
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className='section-sixth text-center mt-5' >
                                        <div className='row tt-container'>
                                            <div className='col-lg-6 col-md-6 col-sm-12 section1 mt-5'>
                                                <Text type='h7' text="Personalization" className='content-title' />

                                                <Text type='h3' text='Job Notifications' className='content-sub-title' />

                                                <Text type='h7' text="Stay up to date with the latest job opportunities by receiving job alerts and notifications right to your email or phone. Never miss out on great job opportunities again." className='content-paragraph' />
                                            </div>
                                            <div className='col-lg-6 col-md-6 col-sm-12 px-5'>
                                                <span className=''>
                                                    <img onError={commonServices.imgError} src="./Assets/images/aboutus/candidates/c4.png" alt="" className='image' />
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='section-seventh text-center mt-5 ' >
                                        <div className="bg_container_box bg_green">
                                            {/* <div className='bg_green_absolute'></div> */}
                                            <div className='row tt-container'>
                                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                                    <span className=''>
                                                        <img onError={commonServices.imgError} src="./Assets/images/aboutus/candidates/c5.png" alt="" className='image' />
                                                    </span>
                                                </div>
                                                <div className='col-lg-6 col-md-6 col-sm-12 section1 mt-5 px-5'>
                                                    <Text type='h7' text="Winning Proposition" className='content-title' />

                                                    <Text type='h3' text='Get ahead in the Job Race' className='content-sub-title' />

                                                    <Text type='h7' text="Our powerful tools give you everything you need to prepare for job interviews, including personalized feedback and Al-powered tips to help you improve your resume and cover letter. We also offer career counseling services to help you take your next steps towards success." className='content-paragraph' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='section-eight text-center mt-5' >
                                        <div className='row tt-container'>
                                            <div className='col-lg-6 col-md-6 col-sm-12 section1 mt-5'>
                                                <Text type='h7' text="Great Connections" className='content-title' />

                                                <Text type='h3' text='Connect with Recruiters directly' className='content-sub-title' />

                                                <Text type='h7' text="TrueTalent makes it easy for you to directly connect with recruiters one-on-one, cutting down on time and hassle of traditional job applications." className='content-paragraph' />
                                            </div>
                                            <div className='col-lg-6 col-md-6 col-sm-12 px-5'>
                                                <span className=''>
                                                    <img onError={commonServices.imgError} src="./Assets/images/aboutus/candidates/c6.png" alt="" className='image' />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div >
        </MaxWidthContainer>
    )
}

export default About;