
/* eslint-disable */
import React, { useState } from 'react'
import './Steps.scss'
import * as commonServices from '../../../../services/common';
import plusImg from '../../../../Assets/svgs/grayPlus.svg';
import plusCircleImg from '../../../../Assets/svgs/plus_circle.svg';
import trashImg from "../../../../Assets/svgs/trash.svg";
import Input from '../../../_widgets/Input/Input';
import Text from '../../../_widgets/Text/Text';
import Button from '../../../_widgets/Button/Button';
import IconResource from '../../../_widgets/IconResource/IconResource';




export default function AddLinks({ links, setLinks }) {

    const [activeRow, setActiveRow] = useState(0);

    const deleteRow = async (e, key) => {
        e.preventDefault();
        var tempProfileArr = [...links];
        tempProfileArr.splice(key, 1);
        setLinks(tempProfileArr);
    }


    const onChangeHandlerLink = (e, key) => {
        var tempArray = [...links];
        tempArray[key][e.target.name] = e.target.value;
        setLinks(tempArray);
        setActiveRow(key);
    }

    const addSuggestionLink = (title, description) => {
        setLinks([...links, {
            "title": title,
            "description": description
        }]);
    }
    const isSkillNotCollapsed = (key) => {
        return links[key].isCollapse !== undefined && links[key].isCollapse !== null && !links[key].isCollapse;
    }

    return (
        <div className="row mb-3 add-skills" id='Skills'>
            <Text type="h2" text="Websites & Portfolio Links">
            </Text>
            <Text type={"h7"} className="mt-2" text="You can add links to websites you want hiring managers to see! Perhaps It will be a description to your portfolio, LinkedIn profile, or personal website."></Text>
            <div className="d-flex mt-3">
                <div className="top-skill hand-hover" onClick={() => addSuggestionLink("Github", "https://www.github.com")}>
                    Github
                    <img src={plusImg} alt="plus" />
                </div>

                <div className="top-skill hand-hover" onClick={() => addSuggestionLink("Dribbble", "https://www.dribbble.com")}>
                    Dribbble
                    <img src={plusImg} alt="plus" />
                </div>

                <div className="top-skill hand-hover" onClick={() => addSuggestionLink("Linkedin", "https://www.linkedin.com")}>
                    Linkedin
                    <img src={plusImg} alt="plus" />
                </div>


            </div>

            <div class="accordion skill_box" id="accordionExample">
                {links && links.map((element, key) => {
                    return <div className='accordion-parent'>
                        <div class="accordion-item">
                            <div class="accordion-header skill_box_det" id={"headingOne" + key}>
                                <button class={isSkillNotCollapsed(key) ? "accordion-button" : "accordion-button collapsed"} type="button" data-bs-toggle="collapse" data-bs-target={"#collapseLink" + key} aria-expanded="false" aria-controls="collapseLink">
                                    {links[key].title}
                                    <p className='experience'>
                                        {links[key].description}
                                    </p>

                                </button>
                            </div>
                            <div id={"collapseLink" + key} class={isSkillNotCollapsed(key) ? "accordion-collapse collapse show" : "accordion-collapse collapse"} aria-labelledby={"headingOne" + key} data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <div className="row row-gap-2">
                                        <div className="col-12 col-sm-6">
                                            <Input type="text"
                                                placeholder="Enter the label"
                                                label="Label"
                                                isRequired={true}
                                                id='title'
                                                value={links[key].title}
                                                name='title'
                                                onChange={(e) => { onChangeHandlerLink(e, key); }} />

                                            {/* <div className="position-relative">
                                                <label className="input-lebel2 required" htmlFor=''>Label</label>
                                                <div className="input-group">
                                                    <input type="text" className="form-control" placeholder="Enter the label" id='title' value={links[key].title} name='title' onChange={(e) => { onChangeHandlerLink(e, key); }} />
                                                </div>
                                            </div> */}
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <Input type="text"
                                                placeholder="Enter the description"
                                                label="Link"
                                                isRequired={true}
                                                id='description'
                                                value={links[key].description}
                                                name='description'
                                                onChange={(e) => { onChangeHandlerLink(e, key); }} />
                                            {/* <div className="position-relative">
                                                <label className="input-lebel2 required" htmlFor=''>Link</label>
                                                <div className="input-group">
                                                    <input 
                                                    type="text" 
                                                    placeholder="Enter the description" 
                                                    id='description' 
                                                    value={links[key].description} 
                                                    name='description' 
                                                    onChange={(e) => { onChangeHandlerLink(e, key); }} 
                                                    className="form-control" 
                                                    />
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='delete-btn'>
                            <button onClick={(e) => deleteRow(e, key)}><img onError={commonServices.imgError} src={trashImg} alt="" className="trash" /></button>
                        </div>
                    </div>
                })}
            </div>

            <div className='d-flex align-items-center gap-2 pointer' onClick={() => { setLinks([...links, { 'isCollapse': false }]) }}>
                <IconResource type="roundPlus" size={20} color='var(--green)' />
                <Button text={"Add Link"} buttonType="text" />
            </div>
        </div>
    )
}
