/* eslint-disable */

import React, { useEffect, useState, useRef } from "react";
import "./MyAccount.scss";
import { useNavigate, useSearchParams } from "react-router-dom";

import * as erros from "@/constants/stringConstants";
import * as storageConstants from "@/constants/storageConstants";
import * as ApiHelper from "@/services/apiHelper";
import * as commonServices from "@/services/common";
import { LOCATION_THRESHOLD } from "@/constants/storageConstants";
import { addDataOnFocusOut } from "@/services/common";
import { isCandidate } from "@/utils/authUtil";
import * as toast from "@/wrapper/toast";
import { isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import { getPaymentStatusAndMessage, isNullOrEmpty, stringCompare } from '../../utils/stringUtils';
import MyAccountLayout from './MyAccountLayout';
import { removeDuplicates } from '../../utils/arrayUtils';

function MyAccount({ setAvatar, setTrigger }) {
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isNoShowOpen, setIsNoShowOpen] = useState(false);

  const onDeleteOpen = () => {
    setIsDeleteOpen(true);
  };

  const onDeleteClose = () => {
    setIsDeleteOpen(false);
  };

  const onNoShowOpen = () => {
    setIsNoShowOpen(true);
  };

  const onNoShowClose = () => {
    setIsNoShowOpen(false);
  };

  const masterData = commonServices.getLocalData(storageConstants.DATA);
  const [profile, setProfile] = useState(
    commonServices.getLocalData(storageConstants.PROFILE)
  );
  const userRole = commonServices.getLocalData(storageConstants.USER_ROLE);
  const [reason, setReason] = useState("");
  const [selectedInterview, setSelectedInterview] = React.useState(null);

  const [showModalViewTransactions, setShowModalViewTransactions] =
    useState(false);
  const [headingText, setHeadingText] = useState("");
  const [isCompanyTransactions] = useState(false);

  let [loading, setLoading] = useState(false);
  let [color] = useState("#000000");

  const [showModal, setShowModal] = useState(false);
  const [showModalDeleteAccount, setShowModalDeleteAccount] = useState(false);
  const [showModalError, setShowModalError] = useState(false);
  const datePickerRef = useRef();
  const [modalErrorData, setModalErrorData] = useState({});

  const [showConfetti, setShowConfetti] = useState(false);
  const [showInformationModal, setShowInformationModal] = useState(false);
  const [informationModalTitle, setInformationModalTitle] = useState("");
  const [informationModalText, setInformationModalText] = useState("");

  const [currentTab, setCurrentTab] = useState("profile");
  var url_string = window.location.href;
  var url = new URL(url_string);
  var tab = url.searchParams.get("tab");
  let [searchParams] = useSearchParams();

  var dropdownGenderOptions = [
    {
      id: "Male",
      name: "Male",
    },
    {
      id: "Female",
      name: "Female",
    },
    {
      id: "Prefer not to say",
      name: "Prefer not to say",
    },
  ];

  const onChange = (e, type = "") => {
    if (type === "") {
      setProfile({
        ...profile,
        [e.target.name]: e.target.value,
      });
    } else {
      var tempProfile = { ...profile };
      if (tempProfile[type] === null) {
        tempProfile[type] = [];
      }
      tempProfile[type][e.target.name] = e.target.value;

      setProfile(tempProfile);
    }
  };

  const checkPayment = async (tid) => {
    setLoading(true);
    let formData = new FormData();
    formData.append("tid", tid);
    await ApiHelper.verifyPayment(formData).then((response) => {
      setLoading(false);
      if (response.isSuccess === true) {
        let data = getPaymentStatusAndMessage(response.data);
        if (data["status"] !== undefined && data["message"] !== undefined) {
          setInformationModalTitle(data["status"]);
          setInformationModalText(data["message"]);
          setShowInformationModal(true);
          if (data["show_confetti"] !== undefined && data["show_confetti"]) {
            setShowConfetti(true);
          }
        }
      } else {
        toast.warn(response.message);
      }
    });
  };

  const openModal = () => {
    setShowModal((prev) => !prev);
  };

  const [oldPasswordShown, setOldPasswordShown] = useState(false);
  const [newPasswordShown, setNewPasswordShown] = useState(false);
  const [confirmNewPasswordShown, setConfirmNewPasswordShown] = useState(false);

  let blocked_count = 0;
  const [preferredSkills, setPreferredSkills] = useState(
    profile?.preferred_skills
  );
  const [preferredSkillName, setPreferredSkillName] = useState(
    profile?.preferred_skill_names
  );

  const [skillSuggestion, setSkillSuggestion] = useState([]);

  const [preferred_location, setPreferred_location] = useState(
    profile?.preferred_location
  );
  const [preferredLocationName, setPreferredLocationName] = useState(
    profile?.preferred_location_names ? profile?.preferred_location_names : []
  );

  const [locSuggestion, setLocSuggestion] = useState();

  const [companyLogo, setCompanyLogo] = useState(
    profile.company ? profile.company.logo : ""
  );
  const [companyDescription] = useState(
    profile.company && profile.company.description !== null
      ? profile.company.description
      : ""
  );
  const [companyFacebook] = useState(
    profile.company && profile.company.facebook !== null
      ? profile.company.facebook
      : ""
  );
  const [companyLinkedin] = useState(
    profile.company && profile.company.linkedin !== null
      ? profile.company.linkedin
      : ""
  );
  const [companyTwitter] = useState(
    profile.company && profile.company.twitter !== null
      ? profile.company.twitter
      : ""
  );
  const [companyEeo, setCompanyEeo] = useState(
    profile.company ? profile.company.equal_opportunity_employer : ""
  );
  const [userTransactions, setUserTransactions] = useState(
    profile.company ? profile.userTransactions : ""
  );
  const [industryDomain] = useState(
    profile.company ? profile.company.industry_domain : ""
  );

  const [isFullTimeChecked, setIsFullTimeChecked] = useState(
    profile.job_type !== undefined && profile.job_type.length > 0 ? true : false
  );
  const [isGigChecked, setIsGigChecked] = useState(
    profile.gig_type !== undefined && profile.gig_type.length > 0 ? true : false
  );
  const [gigType, setGigType] = useState(
    profile.gig_type !== undefined ? profile.gig_type : []
  );

  const [companySearch, setCompanySearch] = useState("");
  const [candidateInterview, setCandidateInterview] = useState(null);
  const [canInterviewReschedule, setCanInterviewReschedule] = useState(false);

  let skillRef = useRef();
  let locationRef = useRef();
  const [becomePro, setBecomePro] = useState({});
  const [isActive, setIsActive] = useState(false);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    let handler = (event) => {
      if (!skillRef.current.contains(event.target)) {
        if (skillSuggestion.length > 0) {
          addDataOnFocusOut(
            skillSuggestion,
            setSkillSuggestion,
            setPreferredSkills,
            setPreferredSkillName
          );
        } else {
          addSkillCall();
        }
        if (document.getElementById("search_skills") !== null) {
          document.getElementById("search_skills").value = "";
        }
      }
      if (!locationRef.current.contains(event.target)) {
        addDataOnFocusOut(
          locSuggestion,
          setLocSuggestion,
          setPreferred_location,
          setPreferredLocationName
        );
        document.getElementById("search_location").value = "";
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  useEffect(() => {
    if (tab !== null) {
      setCurrentTab(tab);
    }
    loadCompany();
    getLatestProfile();
    getCandidateLatestInterview();
  }, [tab]);

  useEffect(() => {
    if (profile.company) {
      setUserTransactions(profile.userTransactions);
      setCompanyEeo(profile.company.equal_opportunity_employer);
    }
  }, [profile]);

  const [old_password, setOld_password] = useState("");
  const [new_password, setNew_password] = useState("");
  const [confirm_password, setConfirm_password] = useState("");

  const [autoApplyInfo, setAutoApplyInfo] = useState({
    is_auto_job_apply: 0,
    auto_apply_minimum_match_percentage: 0,
    auto_apply_minimum_balance: 0,
    maximum_number_of_auto_apply_in_week: 0,
  });

  var obj2 = {
    old_password: old_password,
    new_password: new_password,
    confirm_password: confirm_password,
  };

  const [company, setCompany] = useState([]);
  const [suggestions, setSuggestions] = useState([]);

  const [add_picture, setAdd_picture] = useState();
  const [addCompanyLogo] = useState();

  const formDataAdd = new FormData();

  formDataAdd.append("file", add_picture);
  formDataAdd.append("type", "profile");

  const AddPicture = async (e) => {
    setLoading(true);

    await ApiHelper.uploadMedia(formDataAdd).then((response) => {
      setLoading(false);
      if (response.isSuccess === true) {
        setProfile({
          ...profile,
          avatar_location: response.data.file_url,
        });
        toast.success(response.message);
        fileRef.current.value = "";
        profile.avatar_location = response.data.file_url;
        commonServices.storeLocalData(storageConstants.PROFILE, profile);
        setTrigger((prev) => !prev);
      } else {
        toast.warn(response.message);
      }
    });
  };

  const getCandidateLatestInterview = async () => {
    setLoading(true);
    await ApiHelper.getUserInterviews().then((response) => {
      setLoading(false);
      if (response.isSuccess === true) {
        if (response.data.interviews !== null) {
          setCandidateInterview(response?.data?.interviews);
          setCanInterviewReschedule(response?.data?.is_evaluator_available);
        } else {
          becomeProRedirection();
        }
      } else {
        toast.warn(response.message);
      }
    });
  };

  const becomeProRedirection = async () => {
    setLoading(true);
    await ApiHelper.checkCandidateProStatus().then((response) => {
      setLoading(false);
      if (
        response?.data?.invite === null ||
        response?.data?.invite?.status === "requested"
      ) {
        setIsActive(true);
        setBecomePro(response?.data);
      }
    });
  };

  const [showSmartApplyModal, setShowSmartApplyModal] = useState(false);

  const [isSubscriptionActive, setIsSubscriptionActive] = useState(
    isNullOrEmpty(profile?.smart_apply_subscription) ? false : true
  );

  const [autoApply, setAutoApply] = useState(
    isNullOrEmpty(profile?.smart_apply_subscription)
      ? false
      : !isNullOrEmpty(profile?.user_work_profile?.is_auto_job_apply) &&
        profile?.user_work_profile?.is_auto_job_apply === "1"
        ? true
        : false
  );

  const [minMatchPercentage, setMinMatchPercentage] = useState(
    isNullOrEmpty(
      profile?.user_work_profile?.auto_apply_minimum_match_percentage
    )
      ? null
      : profile?.user_work_profile?.auto_apply_minimum_match_percentage
  );

  const [smartApplySubscriptionInfo, setSmartApplySubscriptionInfo] = useState(
    isNullOrEmpty(profile?.smart_apply_subscription)
      ? null
      : profile?.smart_apply_subscription
  );

  const getLatestProfile = async () => {
    setLoading(true);
    await ApiHelper.getProfile().then((response) => {
      setLoading(false);
      if (response.isSuccess === true) {
        if (!isNullOrEmpty(response.data.alternate_phone_number) && !response.data.alternate_phone_number.includes("+91")) {
          response.data.alternate_phone_number = response.data.cc_alternate_phone_number + response.data.alternate_phone_number;
        }else{
          response.data.alternate_phone_number = response.data.cc_phone_number + response.data.phone_number;
        }
        setProfile(response.data);

        setIsSubscriptionActive(
          isNullOrEmpty(response.data?.smart_apply_subscription) ? false : true
        );
        if(response?.data?.job_type && response?.data?.job_type.length > 0){
          setIsFullTimeChecked(true);
        }else{
          setIsFullTimeChecked(false);
        }

        if(response?.data?.gig_type && response?.data?.gig_type.length > 0){
          setIsGigChecked(true);
        }else{
          setIsGigChecked(false);
        }
        setAutoApply(
          isNullOrEmpty(response.data?.smart_apply_subscription)
            ? false
            : !isNullOrEmpty(
              response.data?.user_work_profile?.is_auto_job_apply
            ) && response.data?.user_work_profile?.is_auto_job_apply === "1"
              ? true
              : false
        );

        setMinMatchPercentage(
          isNullOrEmpty(
            response.data?.user_work_profile
              ?.auto_apply_minimum_match_percentage
          )
            ? null
            : response.data?.user_work_profile
              ?.auto_apply_minimum_match_percentage
        );

        setSmartApplySubscriptionInfo(
          isNullOrEmpty(response.data?.smart_apply_subscription)
            ? null
            : response.data?.smart_apply_subscription
        );

        if (isCandidate()) {
          setHeadingText("Transaction");
        }

        commonServices.storeLocalData(storageConstants.PROFILE, response.data);
        setTrigger((prev) => !prev);
      } else {
        toast.warn(response.message);
      }
    });
  };

  const closeModal = () => {
    onDeleteClose();
    setReason("");
  };

  const updateInterviewStatus = async (evaluation_interview_id, status) => {
    let obj = {
      evaluation_interview_id: evaluation_interview_id,
      status: status,
    };

    if (
      status === "cancelled" ||
      status === "rescheduled" ||
      status === "no_show"
    ) {
      if (!reason) {
        toast.warn("Please enter reason");
        return;
      }

      obj["reason"] = reason;

      if (status === "no_show") onNoShowClose();
    }

    setLoading(true);
    await ApiHelper.updateAppointmentStatus(obj).then((response) => {
      setLoading(false);
      if (response.isSuccess === true) {
        toast.success(response.message);
        closeModal();
        getCandidateLatestInterview();
      } else {
        toast.warn(response.message);
      }
    });
  };

  useEffect(() => {
    becomeProRedirection();
  }, [refresh]);

  useEffect(() => {
    if (add_picture) {
      AddPicture();
    }
  }, [add_picture]);

  const uploadCompanyLogo = async (e) => {
    const formDataAdd = new FormData();
    formDataAdd.append("file", addCompanyLogo);
    formDataAdd.append("type", "company");
    setLoading(true);

    await ApiHelper.uploadMedia(formDataAdd).then((response) => {
      setLoading(false);
      if (response.isSuccess === true) {
        getLatestProfile();
        setCompanyLogo(response.data.file_url);
        //setAvatar(response.data.file_url);
        toast.success(response.message);
      } else {
        toast.warn(response.message);
      }
    });
  };

  useEffect(() => {
    if (addCompanyLogo) {
      uploadCompanyLogo();
    }
  }, [addCompanyLogo]);

  const loadCompany = async () => {
    document.getElementById("addNewC").classList.add("visible");
    await ApiHelper.getCompanyList().then((response) => {
      if (response.isSuccess === true) {
        setCompany(response.data);
      } else {
        toast.warn(response.message);
      }
    });
  };

  const onJobTypeChange = async (e) => {
    let isChecked = e.target.checked;
    var selectedVal = parseInt(e.target.value);
    if (isChecked) {
      if (!profile?.job_type.includes(selectedVal)) {
        let tempProfile = { ...profile };

        tempProfile?.job_type.push(selectedVal);
        setProfile(tempProfile);
      }
    } else {
      let tempProfile = { ...profile };
      var arr = tempProfile?.job_type;

      for (var i = 0; i < arr.length; i++) {
        if (parseInt(arr[i]) === selectedVal) {
          arr.splice(i, 1);
        }
      }
      tempProfile.job_type = arr;
      setProfile(tempProfile);
    }
  };

  const onGigTypeChange = async (e) => {
    let isChecked = e.target.checked;
    var selectedVal = parseInt(e.target.value);
    if (isChecked) {
      if (!gigType.includes(selectedVal)) {
        setGigType([...gigType, selectedVal]);
      }
    } else {
      var arr = [...gigType];

      for (var i = 0; i < arr.length; i++) {
        if (parseInt(arr[i]) === selectedVal) {
          arr.splice(i, 1);
        }
      }
      setGigType(arr);
    }
  };

  const del_location = async (e, data) => {
    e.preventDefault();
    var arrow = [...preferred_location];
    arrow = removeDuplicates(arrow);
    for (var i = 0; i < arrow.length; i++) {
      if (parseInt(arrow[i]) === data) {
        console.log(true);
        arrow.splice(i, 1);
      }
    }
    setPreferred_location(arrow);

    var array = [...preferredLocationName];

    for (var i = 0; i < array.length; i++) {
      if (parseInt(array[i]?.id) === data) {
        array.splice(i, 1);
      }
    }
    setPreferredLocationName(array);
  };

  const del_skill = async (e, data) => {
    e.preventDefault();
    var arrow = preferredSkills;
    await setPreferredSkills([]);
    var tempArray = [...preferredSkillName];

    for (var i = 0; i < arrow.length; i++) {
      if (parseInt(arrow[i]) === data) {
        arrow.splice(i, 1);
      }
      if (tempArray[i] !== undefined && parseInt(tempArray[i].id) === data) {
        tempArray.splice(i, 1);
      }
    }
    setPreferredSkillName(tempArray);
    setPreferredSkills(arrow);
  };

  const updatePass = async (e) => {
    e.preventDefault();
    if (!old_password) {
      toast.warn(erros.OLD_PASSWORD_REQUIRED);
    } else if (!new_password) {
      toast.warn(erros.NEW_PASSWORD_REQUIRED);
    } else if (!commonServices.isValidPassword(new_password)) {
      toast.warn(erros.INVALID_PASSWORD);
    } else if (!confirm_password) {
      toast.warn(erros.NEW_CONFIRM_PASSWORD_REQUIRED);
    } else {
      setLoading(true);
      await ApiHelper.changePassword(JSON.stringify(obj2)).then((response) => {
        setLoading(false);
        if (response.isSuccess === true) {
          toast.success(response.message);
          setOld_password("");
          setNew_password("");
          setConfirm_password("");
        } else {
          toast.warn(response.message);
        }
      });
    }
  };

  const updateProfile = async (e, tab = "") => {
    e.preventDefault();
    const parsedNumber = parsePhoneNumber(profile?.alternate_phone_number ?? '');
    const countryCode = parsedNumber?.countryCallingCode;
    const numberOnly = parsedNumber?.nationalNumber;

    var companyObj = {
      description: companyDescription,
      facebook: companyFacebook,
      linkedin: companyLinkedin,
      twitter: companyTwitter,
      equal_opportunity_employer: companyEeo,
      industry_domain_id: industryDomain,
    };

    var obj = {
      "first_name": profile?.first_name,
      "last_name": profile?.last_name,
      "alternate_phone_number": numberOnly,
      "cc_alternate_phone_number": "+" + countryCode,
      "date_of_birth": profile?.date_of_birth,
      "gender": profile?.gender,
      "job_type": profile?.job_type,
      "gig_type": gigType,
      "preferred_location": preferred_location,
      "is_preferred_skills": profile?.is_preferred_skills,
      "preferred_skills": preferredSkills,
      "min_salary": commonServices.removeCommaFromAmount(profile?.min_salary),
      "notification_new_jobs": profile?.notification_new_jobs,
      "notification_profile_viewed": profile?.notification_profile_viewed,
      "company": companyObj,
      "tab": tab,
    }

    if (tab === "smartApply" && autoApplyInfo) {
      if (autoApplyInfo.auto_apply_minimum_match_percentage < 60) {
        toast.error(erros.MIN_MATCH_PERCENT_ERROR_MESSAGE);
        return;
      } else if (autoApplyInfo.auto_apply_minimum_match_percentage > 100) {
        toast.error("Match Percentage should be less than 100%");
        return;
      } else {
        obj["is_auto_job_apply"] = String(autoApplyInfo.is_auto_job_apply);
        obj["auto_apply_minimum_match_percentage"] =
          autoApplyInfo.auto_apply_minimum_match_percentage;
        obj["auto_apply_minimum_balance"] =
          autoApplyInfo.auto_apply_minimum_balance;
        obj["maximum_number_of_auto_apply_in_week"] =
          autoApplyInfo.maximum_number_of_auto_apply_in_week;
      }
    }

    if (validateData(obj)) {
      setLoading(true);
      await ApiHelper.updateProfile(JSON.stringify(obj)).then((response) => {
        setLoading(false);
        if (response.isSuccess === true) {
          getLatestProfile();
          toast.success(response.message);

          if (response.data?.free_tt_cash_credited) {
            setFreeTTCash({
              ...freeTTCash,
              step: 0,
              showModal: true,
              success: true,
            });
          }
        } else {
          toast.warn(response.message);
        }
      });
    }
  };

  const validateData = (obj) => {
    let isValid = true;
    if (!obj.first_name) {
      isValid = false;
      toast.warn(erros.FIRST_NAME_REQUIRED);
    } else if (obj.first_name.length > 30) {
      isValid = false;
      toast.warn(erros.FIRST_NAME_LIMIT_ERROR);
    } else if (!obj.last_name) {
      isValid = false;
      toast.warn(erros.LAST_NAME_REQUIRED);
    } else if (obj.last_name.length > 30) {
      isValid = false;
      toast.warn(erros.LAST_NAME_LIMIT_ERROR);
    } else if (profile.alternate_phone_number === undefined) {
      isValid = false;
      toast.warn(erros.PHONE_NUMBER_REQUIRED);
    } else if (!isValidPhoneNumber(profile?.alternate_phone_number)) {
      isValid = false;
      toast.warn(erros.PHONE_NUMBER_INCORRECT);
    } else {
      return isValid;
    }
  }

  const updatePreferences = (e) => {
    e.preventDefault();

    if (!profile?.min_salary) {
      toast.warn(erros.MIN_SALARY_REQUIRED);
    } else if (
      parseInt(profile?.is_preferred_skills) === 1 &&
      preferredSkills.length < 1
    ) {
      toast.warn(erros.EMPTY_SKILL_ERROR);
    } else if (isFullTimeChecked && profile?.job_type.length < 1) {
      toast.warn(erros.MANDATORY_FIELDS_REQUIRED);
    } else if (isGigChecked && gigType.length < 1) {
      toast.warn(erros.MANDATORY_FIELDS_REQUIRED);
    } else {
      updateProfile(e);
    }
  };

  const setMinSalaryValue = (e) => {
    e.preventDefault();
    if (commonServices.isValidCurrency(e.target.value)) {
      if (commonServices.removeCommaFromAmount(e.target.value) <= 999999999) {
        const min_sal = commonServices.currencyFormatter(e.target.value);
        setProfile({ ...profile, min_salary: min_sal });
      } else {
        toast.warn(erros.MAX_SALARY_AMT_REACHED);
      }
    }
  };

  useEffect(() => {
    commonServices.storeLocalData(storageConstants.PROFILE, profile);
  }, [updateProfile]);

  const onChangeHandler = (text) => {
    setCompanySearch(text);
    let matches = [];
    if (text.length > 3) {
      matches = company.filter((company) => {
        const regex = commonServices.getRegularExpression(text);
        return company.name.match(regex);
      });
    }
    if (text.length > 5 && matches.length === 0) {
      document.getElementById("addNewC").classList.remove("d-none");
    }
    if (text.length === 0) {
      document.getElementById("addNewC").classList.add("d-none");
    }
    setSuggestions(matches);
  };

  const onChangeHandler_location = (text) => {
    if (text.length > LOCATION_THRESHOLD) {
      commonServices.getLocations(text).then((locations) => {
        setLocSuggestion(locations);
      });
    } else {
      setLocSuggestion([]);
    }
  };

  const onChangeHandlerSkill = (text, event) => {
    setSkillName(text);
    if (text.length > storageConstants.SKILL_THRESHOLD) {
      commonServices.getSkillByName(text).then((skills) => {
        setSkillSuggestion(skills);
      });
    } else {
      setSkillSuggestion([]);
    }
  };

  // TODO: Need to review why it is not being used
  const addSkill = (event) => {
    if (event.which === 13 || event.keyCode === 13) {
      addSkillCall();
    }
  };

  const [skillName, setSkillName] = useState("");

  const addSkillCall = () => {
    let skillAdd = document.getElementById("search_skills")?.value;
    if (
      skillAdd !== "" &&
      skillAdd !== undefined &&
      skillSuggestion.length < 1
    ) {
      const formData = new FormData();
      formData.append("name", skillAdd);
      setLoading(true);

      ApiHelper.addSkill(formData).then((response) => {
        setLoading(false);
        if (response.isSuccess === true) {
          response.data.forEach(element => {
            if (stringCompare(skillName, element.name)) {

              setPreferredSkills([...preferredSkills, element.id]);
              setPreferredSkillName([...preferredSkillName, element]);
            }

          })
          document.getElementById('search_skills').value = '';

          onChangeHandlerSkill("");
        } else {
          toast.warn(response.message);
        }
      });
    }
  };

  const BlockCompany = async (e) => {
    const blockData = new FormData();
    setLoading(true);
    blockData.append("company_id", e);
    blockData.append("is_blocking", 1);
    await ApiHelper.blockUnblockCompany(blockData).then((response) => {
      setLoading(false);
      if (response.isSuccess === true) {
        toast.success(response.message);
      } else {
        toast.warn(response.message);
      }
      loadCompany();
    });
  };

  const UnBlockCompany = async (e) => {
    const blockData = new FormData();
    setLoading(true);
    blockData.append("company_id", e);
    blockData.append("is_blocking", 0);
    await ApiHelper.blockUnblockCompany(blockData).then((response) => {
      setLoading(false);
      if (response.isSuccess === true) {
        toast.success(response.message);
      } else {
        toast.warn(response.message);
      }
      loadCompany();
    });
  };

  const navigate = useNavigate();

  const handleNotificationProfileViewed = (e) => {
    let isChecked = e.target.checked;
    if (isChecked) {
      setProfile({ ...profile, notification_profile_viewed: "1" });
    } else {
      setProfile({ ...profile, notification_profile_viewed: "0" });
    }
  };

  const handleNotificationNewJob = (e) => {
    let isChecked = e.target.checked;
    if (isChecked) {
      setProfile({ ...profile, "profile?.notification_new_jobs": "1" });
      setNotification_new_jobs("1");
    } else {
      setProfile({ ...profile, "profile?.notification_new_jobs": "0" });
    }
  };

  const triggerClickDown = (event) => {
    if (event.which === 27 || event.keyCode === 27) {
      setLocSuggestion([]);
      setSkillSuggestion([]);
    } else if (event.which === 9 || event.keyCode === 9) {
      if (event.target.name === "skills") {
        if (skillSuggestion.length > 0) {
          addDataOnFocusOut(
            skillSuggestion,
            setSkillSuggestion,
            setPreferredSkills,
            setPreferredSkillName
          );
        } else {
          addSkillCall();
        }
        event.target.value = "";
      } else if (event.target.name === "locations") {
        addDataOnFocusOut(
          locSuggestion,
          setLocSuggestion,
          setPreferred_location,
          setPreferredLocationName
        );
        event.target.value = "";
      }
    }
  };

  const triggerSelect = (event) => {
    // const list = [...document.querySelectorAll('.sugg_p')];
    const active = document.querySelector(".sugg_p.active");
    // let i = list.indexOf(active);

    if (event.which === 13 || event.keyCode === 13) {
      // const c = document.getElementsByClassName('sugg_p hand-hover');

      if (active) {
        active.click();
      }
    }
  };

  const fileRef = useRef(null);

  const [showModalBuyCash, setShowModalBuyCash] = useState(false);
  const [ttCashDollar, setTtCashDollar] = useState(1);

  const openBuyCashModal = () => {
    // if (!isPaymentMethodEnabled()) {
    //     return false;
    // }
    setShowModalBuyCash((prev) => !prev);
  };

  // SmartApply

  const [freeTTCash, setFreeTTCash] = useState({
    step: 0,
    state: false,
    showModal: false,
  });

  useEffect(() => {
    if (url.searchParams.get("free_cash")) {
      setFreeTTCash({
        step: 1,
        state: true,
        success: false,
        showModal: false,
      });
    }
    var tid = searchParams.get("tid");
    if (tid !== undefined && tid !== null) {
      checkPayment(tid);
    }
  }, []);

  const normalizePhoneNumber = (input, defaultCountry = null) => {
    const parsedPhone = parsePhoneNumber(input, defaultCountry);
    if (parsedPhone) {
      return parsedPhone.number;
    } else {
      return input;
    }
  };

  const [transactionData, setTransactionData] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [perPage] = useState(10);

  function getTransactionData() {
    const queryParams = `?pageNumber=${pageNumber}&perPage=${perPage}&user_id=${profile?.id}`;

    setLoading(true);
    ApiHelper.getTransactions(queryParams).then((response) => {
      if (response.isSuccess === true) {
        setTransactionData(response.data);
      } else {
        toast.warn(response.message);
      }
      setLoading(false);
    });
  }

  function handlePaginationChange(e, { activePage }) {
    window.scrollTo(0, 0);
    setPageNumber(activePage);
  }

  useEffect(() => {
    getTransactionData();
  }, [pageNumber]);

  const todayDate = () => {
    const today = new Date();

    const day = String(today.getDate()).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const year = today.getFullYear();

    return day + "/" + month + "/" + year;
  };

  const oneMonthFromToday = () => {
    const today = new Date();

    const futureDate = new Date(today);
    futureDate.setDate(today.getDate() + 30);

    const day = String(futureDate.getDate()).padStart(2, "0");
    const month = String(futureDate.getMonth() + 1).padStart(2, "0");
    const year = futureDate.getFullYear();

    return day + "/" + month + "/" + year;
  };

  function smartApply() {
    if (minMatchPercentage > 100) {
      toast.error("Match Percentage should be less than 100%");
      return;
    } else {
      const data = {
        is_auto_job_apply: autoApply === true ? "1" : "0",
        is_auto_job_apply_enabled: autoApply === true ? "1" : "0",
        auto_apply_minimum_match_percentage:
          isNullOrEmpty(minMatchPercentage) || minMatchPercentage < 60
            ? 60
            : minMatchPercentage,
      };

      setLoading(true);
      ApiHelper.smartApply(data).then((response) => {
        if (response.isSuccess === true) {
          ApiHelper.getProfile().then((res) => {
            if (res.isSuccess === true) {
              toast.success(response.message);
              setLoading(false);

              setIsSubscriptionActive(
                isNullOrEmpty(res.data?.smart_apply_subscription) ? false : true
              );

              setAutoApply(
                isNullOrEmpty(res.data?.smart_apply_subscription)
                  ? false
                  : !isNullOrEmpty(
                    res.data?.user_work_profile?.is_auto_job_apply
                  ) && res.data?.user_work_profile?.is_auto_job_apply === "1"
                    ? true
                    : false
              );

              setMinMatchPercentage(
                isNullOrEmpty(
                  res.data?.user_work_profile
                    ?.auto_apply_minimum_match_percentage
                )
                  ? null
                  : res.data?.user_work_profile
                    ?.auto_apply_minimum_match_percentage
              );

              setSmartApplySubscriptionInfo(
                isNullOrEmpty(res.data?.smart_apply_subscription)
                  ? null
                  : res.data?.smart_apply_subscription
              );

              if (isNullOrEmpty(profile?.smart_apply_subscription)) {
                setShowSmartApplyModal(false);
                setInformationModalTitle(response.message);
                setInformationModalText(
                  `Your Current TT SmartApply Subscription is Active from ${res.data?.smart_apply_subscription.from_date} to ${res.data?.smart_apply_subscription.to_date}`
                );
                setShowInformationModal(true);
                setShowConfetti(true);
              }

              setProfile(res.data);
            } else {
              toast.warn(res.message);
              setLoading(false);
            }
          });
        } else {
          toast.warn(response.message);
          setLoading(false);
        }
      });
    }
  }

  return (
    <MyAccountLayout
      profile={profile}
      setProfile={setProfile}
      freeTTCash={freeTTCash}
      showConfetti={showConfetti}
      setShowConfetti={setShowConfetti}
      loading={loading}
      showModal={showModal}
      setShowModal={setShowModal}
      companySearch={companySearch}
      loadCompany={loadCompany}
      showModalDeleteAccount={showModalDeleteAccount}
      setShowModalDeleteAccount={setShowModalDeleteAccount}
      showModalError={showModalError}
      setShowModalError={setShowModalError}
      modalErrorData={modalErrorData}
      setModalErrorData={setModalErrorData}
      showModalViewTransactions={showModalViewTransactions}
      setShowModalViewTransactions={setShowModalViewTransactions}
      isCompanyTransactions={isCompanyTransactions}
      headingText={headingText}
      showInformationModal={showInformationModal}
      setShowInformationModal={setShowInformationModal}
      informationModalTitle={informationModalTitle}
      informationModalText={informationModalText}
      showModalBuyCash={showModalBuyCash}
      setShowModalBuyCash={setShowModalBuyCash}
      ttCashDollar={ttCashDollar}
      getLatestProfile={getLatestProfile}
      fileRef={fileRef}
      currentTab={currentTab}
      userRole={userRole}
      datePickerRef={datePickerRef}
      updateProfile={updateProfile}
      isFullTimeChecked={isFullTimeChecked}
      masterData={masterData}
      isGigChecked={isGigChecked}
      gigType={gigType}
      locationRef={locationRef}
      locSuggestion={locSuggestion}
      preferredLocationName={preferredLocationName}
      preferred_location={preferred_location}
      skillRef={skillRef}
      skillSuggestion={skillSuggestion}
      preferredSkillName={preferredSkillName}
      preferredSkills={preferredSkills}
      updatePreferences={updatePreferences}
      autoApplyInfo={autoApplyInfo}
      openModal={openModal}
      suggestions={suggestions}
      company={company}
      oldPasswordShown={oldPasswordShown}
      old_password={old_password}
      newPasswordShown={newPasswordShown}
      new_password={new_password}
      confirmNewPasswordShown={confirmNewPasswordShown}
      confirm_password={confirm_password}
      updatePass={updatePass}
      candidateInterview={candidateInterview}
      userTransactions={userTransactions}
      isDeleteOpen={isDeleteOpen}
      onDeleteClose={onDeleteClose}
      reason={reason}
      isActive={isActive}
      color={color}
      blocked_count={blocked_count}
      setAdd_picture={setAdd_picture}
      normalizePhoneNumber={normalizePhoneNumber}
      onChange={onChange}
      dropdownGenderOptions={dropdownGenderOptions}
      setIsFullTimeChecked={setIsFullTimeChecked}
      setGigType={setGigType}
      setIsGigChecked={setIsGigChecked}
      onJobTypeChange={onJobTypeChange}
      onGigTypeChange={onGigTypeChange}
      onChangeHandler_location={onChangeHandler_location}
      setPreferred_location={setPreferred_location}
      setPreferredLocationName={setPreferredLocationName}
      setPreferredSkills={setPreferredSkills}
      setPreferredSkillName={setPreferredSkillName}
      onChangeHandlerSkill={onChangeHandlerSkill}
      del_skill={del_skill}
      setMinSalaryValue={setMinSalaryValue}
      setOld_password={setOld_password}
      setNew_password={setNew_password}
      setConfirm_password={setConfirm_password}
      setOldPasswordShown={setOldPasswordShown}
      setNewPasswordShown={setNewPasswordShown}
      setConfirmNewPasswordShown={setConfirmNewPasswordShown}
      del_location={del_location}
      setAutoApplyInfo={setAutoApplyInfo}
      openBuyCashModal={openBuyCashModal}
      setFreeTTCash={setFreeTTCash}
      onChangeHandler={onChangeHandler}
      setSuggestions={setSuggestions}
      BlockCompany={BlockCompany}
      UnBlockCompany={UnBlockCompany}
      updateInterviewStatus={updateInterviewStatus}
      triggerClickDown={triggerClickDown}
      becomePro={becomePro}
      setRefresh={setRefresh}
      selectedInterview={selectedInterview}
      setSelectedInterview={setSelectedInterview}
      isNoShowOpen={isNoShowOpen}
      onNoShowOpen={onNoShowOpen}
      onNoShowClose={onNoShowClose}
      setReason={setReason}
      onDeleteOpen={onDeleteOpen}
      canInterviewReschedule={canInterviewReschedule}
      triggerSelect={triggerSelect}
      addSkill={addSkill}
      getTransactionData={getTransactionData}
      transactionData={transactionData}
      handlePaginationChange={handlePaginationChange}
      pageNumber={pageNumber}
      smartApply={smartApply}
      autoApply={autoApply}
      setAutoApply={setAutoApply}
      minMatchPercentage={minMatchPercentage}
      setMinMatchPercentage={setMinMatchPercentage}
      showSmartApplyModal={showSmartApplyModal}
      setShowSmartApplyModal={setShowSmartApplyModal}
      navigate={navigate}
      smartApplySubscriptionInfo={smartApplySubscriptionInfo}
      todayDate={todayDate}
      oneMonthFromToday={oneMonthFromToday}
      isSubscriptionActive={isSubscriptionActive}
      setIsSubscriptionActive={setIsSubscriptionActive}
    />
  );
}

export default MyAccount;
