import axios from 'axios';
import { handleResponse, handleError } from './response';
import * as storageConstants from '../constants/storageConstants';
import * as commonServices from './common';

// Define your api url from any source.
// Pulling from your .env file when on the server or from localhost when locally
const BASE_URL = process.env.REACT_APP_BASE_URL;

export const getHeaders = () => {
  return {
    'Accept': 'application/json',
    'Authorization': commonServices.getLocalData(storageConstants.AUTH) !== null ? `Bearer ${commonServices.getLocalData(storageConstants.AUTH)}` : null,
    'Content-Type': 'application/json',
    'x-api-key': process.env.REACT_APP_CLIENT_ID
  };
};

/** @param {string} resource */
export const getAll = (resource, model = '') => {
  const headers = getHeaders();
  const startTime = Date.now(); // Track start time
  const url = `${BASE_URL}${resource}${model}`;

  return axios
    .get(url, { headers })
    .then((response) => handleResponse(response, startTime, url))
    .catch((error) => handleError(error, startTime));
};

export const get = (resource, id) => {
  const headers = getHeaders();
  const startTime = Date.now(); // Track start time
  const url = `${BASE_URL}${resource}${id}`;

  return axios
    .get(url, { headers })
    .then((response) => handleResponse(response, startTime, url))
    .catch((error) => handleError(error, startTime));
};

export const post = (resource, model = {}) => {
  const headers = getHeaders();
  const startTime = Date.now(); // Track start time
  const url = `${BASE_URL}${resource}`;

  return axios
    .post(url, model, { headers })
    .then((response) => handleResponse(response, startTime, url))
    .catch((error) => handleError(error, startTime));
};

export const deleteAPI = (id) => {
  const headers = getHeaders();
  const startTime = Date.now(); // Track start time
  const url = `${BASE_URL}${id}`;

  return axios
    .delete(url, { headers })
    .then((response) => handleResponse(response, startTime, url))
    .catch((error) => handleError(error, startTime));
};

export const verifyEmail = (resource) => {
  const headers = getHeaders();
  const startTime = Date.now(); // Track start time

  return axios
    .get(resource, { headers })
    .then((response) => handleResponse(response, startTime, resource))
    .catch((error) => handleError(error, startTime));
};
