import { useEffect, useRef } from 'react';
import * as commonServices from '@/services/common';
import { KEYBOARD_SHORTCUT_TEXT, ADD_SKILL_SUGGESTION } from '@/constants/stringConstants';
import SearchType from './SearchType';
import KeywordSearchTextbox from './KeywordSearchTextbox';

function StickyFindFilter({ isStickySearch, skillSuggestions, locationSuggestions, buttonName, FindWork, skills, locations, onSkillChange, addSkills, onSkillSuggestionClick, deleteSearchedSkill, onLocationChange, onLocationSuggestionClick, deleteSearchedLocation, triggerClickDown, triggerClickUp, triggerSelect, saveSearchCriteria, searchType, setSearchType, searchedSkills, setSearchedSkills, searchBar, keywordSeachFilters, setKeywordSeachFilters, keyword, setKeyword, activePage, isKeywordReset, setIsKeywordReset, setSkills, setSearchedLocations, setSkillSuggestions, setLocationSuggestions, setLocations, needExtraActions, addSkillCall, addChips }) {

    const skillRef = useRef();
    const locationRef = useRef();

    useEffect(() => {
        const handler = (event) => {
            if (isStickySearch) {
                if (!skillRef.current.contains(event.target)) {
                    let element = document.getElementById('search_skill');
                    if (skillSuggestions.length > 0) {
                        setSearchedSkills('skills', value => {
                            if (value.some(val => (needExtraActions ? val : val.id) === skillSuggestions[0].id)) {
                                return [...value];
                            }
                            return needExtraActions ? [...value, skillSuggestions[0].id] : [...value, skillSuggestions[0]];
                        });

                        setSkills(value => {
                            if (value.some(val => val.id === skillSuggestions[0].id)) {
                                return [...value];
                            }
                            return [...value, skillSuggestions[0]];
                        });

                        needExtraActions && addChips("skills", skillSuggestions[0].id);
                        setSkillSuggestions([]);
                    } else {
                        if (element !== null)
                            addSkillCall(element.value);
                    }
                    if (element !== null)
                        element.value = '';
                }
                if (!locationRef.current.contains(event.target)) {
                    if (locationSuggestions.length > 0) {
                        setSearchedLocations((value) => {
                            if (value.some(val => (needExtraActions ? val : val.id) === locationSuggestions[0].id)) {
                                return [...value];
                            }
                            return needExtraActions ? [...value, locationSuggestions[0].id] : [...value, locationSuggestions[0]];
                        });
                        setLocations(value => {
                            if (value.some(val => val.id === locationSuggestions[0].id)) {
                                return [...value];
                            }
                            return [...value, locationSuggestions[0]];
                        });

                        needExtraActions && addChips("locations", locationSuggestions[0].id);
                        setLocationSuggestions([]);
                    }
                    document.getElementById('search_location').value = '';
                }
            }
        }
        document.addEventListener("mousedown", handler);

        return () => document.removeEventListener("mousedown", handler);
    });

    return (
        <div className='tt-container py-2'>
            <div className='sticky-search-item '>
                <div className="search_section">
                    <div className="job_search" ref={skillRef}>
                        <div className="search_box">
                            <img onError={commonServices.imgError} src=" ../../Assets/svgs/search.svg" alt="" />
                            <div className='d-flex f_t_box' id='f_t_box_skills_sticky'>
                                {skills.length > 0 && skills.map((skill, k) => {
                                    return <div className="f_t" key={k}>
                                        <p>{skill.name}</p>
                                        <button onClick={e => deleteSearchedSkill(e, skill.id)} className="del_jt"><img src="../../Assets/svgs/x.svg" alt="" /></button>
                                    </div>
                                })}
                            </div>

                            {searchType === 'smart' ?
                                <input type="text" name='skill' placeholder={skills.length > 0 ? '' : 'Skills'} id='search_skill' onChange={e => onSkillChange(e.target.value)} onKeyPress={e => { addSkills(e); triggerSelect(e) }} onKeyDown={(e) => triggerClickDown(e)} onKeyUp={(e) => triggerClickUp(e)} />
                                : <KeywordSearchTextbox keywordSearch={keyword} setKeywordSearch={setKeyword} FindWork={FindWork} searchBar={"sticky"} />}
                        </div>

                        <div className={skillSuggestions.length > 0 ? 'suggestion_box active' : "suggestion_box"}>
                            <div>
                                {skillSuggestions.length > 0 && skillSuggestions.map((suggestion, i) =>
                                    <p key={i} className={i === 0 ? 'sugg_p hand-hover active' : 'sugg_p hand-hover'} onClick={() => onSkillSuggestionClick(suggestion)}>{suggestion.name}</p>)}
                            </div>

                            {skillSuggestions.length > 0 ? ((document.getElementById('search_skill') && document.getElementById('search_skill').value.length > 3) ? <div style={{ position: "sticky", bottom: 0, background: "#f5f5f5", padding: "10px", color: "#9398A1", fontSize: "0.875em" }}>{ADD_SKILL_SUGGESTION}</div> : <div style={{ position: "sticky", bottom: 0, background: "#f5f5f5", padding: "10px", color: "#9398A1", fontSize: "0.875em" }}>{KEYBOARD_SHORTCUT_TEXT}</div>) : null}
                        </div>
                    </div>

                    <div className="location_search" ref={locationRef}>
                        <div className='search_box'>
                            <img onError={commonServices.imgError} src='../../Assets/svgs/map_pin.svg' alt="" />
                            <div className='d-flex f_t_box' id='f_t_box_locations_sticky'>
                                {locations.length > 0 && locations.map((data, k) => {
                                    return <div className="f_t" key={k}>
                                        <p>{data.name}, {data.description}</p>
                                        <button onClick={(e) => { deleteSearchedLocation(e, data.id) }} className="del_jt"><img src='../../Assets/svgs/x.svg' alt="" /></button>
                                    </div>
                                })}
                            </div>

                            <input type="text" name='location' autoComplete='off' placeholder={locations.length > 0 ? ' ' : "City"} id='search_location' onChange={(e) => onLocationChange(e.target.value, 'JOB_LOCATIONS')} onKeyPress={(e) => { triggerSelect(e) }} onKeyDown={(e) => triggerClickDown(e)} onKeyUp={(e) => triggerClickUp(e)} />
                        </div>

                        <div className={locationSuggestions.length > 0 ? 'suggestion_box active' : "suggestion_box"}>
                            <div>
                                {locationSuggestions.length > 0 && locationSuggestions.map((suggestion, i) =>
                                    <p key={i} className={i === 0 ? 'sugg_p hand-hover active' : 'sugg_p hand-hover'} onClick={() => onLocationSuggestionClick(suggestion)}>{suggestion.name}, {suggestion.description}</p>)}
                            </div>

                            {locationSuggestions.length > 0 && <div style={{ position: "sticky", bottom: 0, background: "#f5f5f5", padding: "10px", color: "#9398A1", fontSize: "0.875em" }}>{KEYBOARD_SHORTCUT_TEXT}</div>}
                        </div>
                    </div>

                    <div className="search_button" onClick={() => FindWork(activePage)}>
                        <p>Find {buttonName}</p>
                    </div>
                </div>
            </div>

            <SearchType searchType={searchType} setSearchType={setSearchType} keywordSearch={keyword} setKeywordSearch={setKeyword} job_type={searchedSkills} setJob_type={setSearchedSkills} searchBar={searchBar} module={buttonName} keywordSeachFilters={keywordSeachFilters} setKeywordSeachFilters={setKeywordSeachFilters} isKeywordReset={isKeywordReset} setIsKeywordReset={setIsKeywordReset} saveSearchCriteria={saveSearchCriteria} />
        </div>
    )
}

export default StickyFindFilter;
