const isDebug = true;
export function consoleLog(string, value = "") {
    if (isDebug) {
        console.log(string, value);
    }
}

export function consoleLogE(string, value = "") {
    if (isDebug) {
        console.error(string, value);
    }
}
