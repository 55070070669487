import React, { useEffect, useState, useRef } from 'react';
import './Dashboard.scss';
import * as ApiHelper from '@/services/apiHelper';

import { css } from "@emotion/react";
import Footer from '../FooterUpper/FooterUpper';
import Chart from "../Common/Chart";
import * as commonServices from '@/services/common';
import * as toast from "@/wrapper/toast";
import { momentResult, momentYear } from '../../utils/dateUtil';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: var(--green);
  left: calc(50% - 75px);
  position: fixed;
`;

function Dashboard() {

    let [loading, setLoading] = useState(false);
    let [graph1, setGraph1] = useState({});
    let [graph2, setGraph2] = useState({});
    let [graph3, setGraph3] = useState({});
    let [graph4, setGraph4] = useState({});
    let [dataYear, setDataYear] = useState();
    let [years, setYears] = useState([]);
    const graph1Ref = useRef();
    const graph2Ref = useRef();
    const graph3Ref = useRef();
    const graph4Ref = useRef();
    const currentYear = momentYear();


    const getCharts = async () => {
        setLoading(true);
        await ApiHelper.companyDashboard().then((response) => {
            if (response.isSuccess === true) {
                setDataYear(response.data.data_year);
                var a = momentResult([currentYear]);
                var b = momentResult([response.data.first_year]);
                var yearDiff = a.diff(b, 'years');
                setYears(commonServices.getYearsList(yearDiff));

                setGraph1({
                    ...graph1,
                    title: response.data.graph1.title,
                    labels: response.data.graph1.labels,
                    datasets: response.data.graph1.data
                });
                setGraph2({
                    ...graph2,
                    title: response.data.graph2.title,
                    labels: response.data.graph2.labels,
                    datasets: response.data.graph2.data
                });
                setGraph3({
                    ...graph3,
                    title: response.data.graph3.title,
                    labels: response.data.graph3.labels,
                    datasets: response.data.graph3.data
                });
                setGraph4({
                    ...graph4,
                    title: response.data.graph4.title,
                    labels: response.data.graph4.labels,
                    datasets: response.data.graph4.data
                });
            }
            else {
                toast.warn(response.message);
                console.log('error');
            }
            setLoading(false);
        });
    }

    useEffect(() => {
        getCharts();
    }, []);

    return (
        <>
            <div className="company_users">
                <div className="body_section">
                    <div className="search">
                        <h2>Dashboard</h2>
                        <div className='sort_by'>
                            <div className="result_header_sort">
                                <p>Year:</p>
                                <div className="dropdown">
                                    <a className="btn btn-secondary dropdown-toggle" href={undefined} role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                        {dataYear}
                                    </a>

                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                        <li><a className="dropdown-item" href={undefined}>2022</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container py-3" id="featured-3">
                        <div className="row row-cols-1 py-3">
                            <div className="col-6" key={'ch1'}>
                                <Chart
                                    chartData={graph1}
                                    text={graph1.title}
                                    chartRef={graph1Ref}
                                />
                            </div>
                            <div className="col-6" key={'ch2'}>
                                <Chart
                                    chartData={graph2}
                                    text={graph2.title}
                                    chartRef={graph2Ref}
                                />
                            </div>
                        </div>
                        <div className="row row-cols-1 py-3">
                            <div className="col-6" key={'ch3'}>
                                <Chart
                                    chartData={graph3}
                                    text={graph3.title}
                                    chartRef={graph3Ref}
                                />
                            </div>
                            <div className="col-6" key={'ch4'}>
                                <Chart
                                    chartData={graph4}
                                    text={graph4.title}
                                    chartRef={graph4Ref}
                                />
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <Footer />
        </>
    )
}

export default Dashboard
