import React, { useEffect, useState } from "react";
import Editor from "../Common/Editor";
import * as ApiHelper from "@/services/apiHelper";
import * as toast from "@/wrapper/toast";
import Text from "../_widgets/Text/Text";

import IconResource from "../_widgets/IconResource/IconResource";

import * as storageConstants from "@/constants/storageConstants";
import * as commonServices from "@/services/common";
import { isNullOrEmpty } from "@/utils/stringUtils";
import Button from "../_widgets/Button/Button";

import { AiTwotoneDelete } from "react-icons/ai";
import { RatingWrapper } from "../../wrapper/ratingWrapper";
import { Form } from "react-bootstrap";

export const FeedbackForm = ({
  questions,
  meetingDetails,
  getAnswerOfQuestion,
  setAnswerOfQuestion,
  saveFeedback,
  isFeedbackDisabled,
  skillQuestions,
  setSkillQuestions,
  softSkillQuestions,
  setSoftSkillQuestions,
  overAllFeedback,
  setOverAllFeedback,
}) => {
  const addCoreSkills = async () => {
    if (
      skillQuestions.filter((skill) => skill.type === "skill_added").length > 1
    ) {
      toast.warn("You can add only 2 core skill");
      return;
    }

    setSkillQuestions([
      ...skillQuestions,
      {
        type: "skill_added",
        question_name: "",
        rating: 0,
        feedback: "",
        question_id: null,
      },
    ]);
  };

  const addSoftSkills = async () => {
    if (
      softSkillQuestions.filter((skill) => skill.type === "skill_added")
        .length > 1
    ) {
      toast.warn("You can add only 2 soft skill");
      return;
    }

    setSoftSkillQuestions([
      ...softSkillQuestions,
      {
        type: "skill_added",
        question_name: "",
        rating: 0,
        feedback: "",
        question_id: null,
      },
    ]);
  };

  const checkFeedback = () => {
    if (overAllFeedback === "") {
      toast.warn("Please add overall feedback");
      return;
    }

    saveFeedback();
  };

  const [jtSuggestion, setJtSuggestion] = useState([]);
  const [skill, setSkill] = useState([]);
  const [activeRow, setActiveRow] = useState(null);

  const onChangeHandlerSkill = (text, key) => {
    setActiveRow(key);

    var tempArray = [...skillQuestions];

    if (text) {
      if (text.length > storageConstants.SKILL_THRESHOLD) {
        if (
          skill.find(
            (data) =>
              data?.skill_name?.toLowerCase() === text?.toLowerCase() &&
              data.skill_name?.length > 0
          )
        ) {
          toast.warn("Skill already added");
          return;
        }
      }
    }

    tempArray[key].question_name = text;
    setSkillQuestions(tempArray);

    if (text && text.length > storageConstants.SKILL_THRESHOLD) {
      commonServices.getSkillByName(text).then((skills) => {
        setJtSuggestion(skills);
      });
    } else {
      setJtSuggestion([]);
    }
  };

  const handleKeyPress = (event, key) => {
    if (event.key === "Enter" || event.key === "Tab") {
      if (jtSuggestion && jtSuggestion.length > 0) {
        document.getElementById("search_skills").value = "";
        var tempArray = [...skillQuestions];
        tempArray[key].question_name = jtSuggestion[0]?.name;
        tempArray[key].skill_id = jtSuggestion[0].id;
        setJtSuggestion([]);
        setSkillQuestions(tempArray);
      }
    }

    if (event.key === "Escape") {
      setJtSuggestion([]);
    }
  };

  const getSkills = () => {
    ApiHelper.getSkills().then((response) => {
      // setLoading(false);
      if (response.isSuccess === true) {
        setSkill(response.data);
      } else {
        console.log("error");
      }
    });
  };

  useEffect(() => {
    getSkills();
  }, []);

  return (
    <div
    // maxH={"calc(100vh - 200px)"}
    // overflowY={"scroll"}
    >
      <div className="d-flex align-items-center gap-3 my-4">
        <img
          src={
            meetingDetails !== null
              ? meetingDetails?.candidate?.avatar_location
              : ""
          }
          alt="Avatar"
          style={{ width: "40px", height: "40px", borderRadius: "50%" }}
        />{" "}
        <div>
          <Text
            text={
              meetingDetails !== null
                ? meetingDetails?.candidate?.full_name
                : ""
            }
            className="text-start"
            fontSize={"md"}
            textAlign={"left"}
          />

          <Text
            text={
              meetingDetails !== null ? meetingDetails?.candidate?.email : ""
            }
            fontSize={"sm"}
            textAlign={"left"}
            color={"gray.500"}
          />
        </div>
      </div>

      <Text
        text={
          meetingDetails !== null
            ? meetingDetails?.candidate?.user_work_profile?.summary
            : ""
        }
        mt={4}
        fontSize={"sm"}
      />

      <div className="mt-4 d-flex flex-column gap-3 pb-3">
        <div className="d-flex flex-column gap-2">
          <Text type="h2" text="Overall Feedback" fontWeight={600} />

          <Editor
            desc={isNullOrEmpty(overAllFeedback) ? "" : overAllFeedback}
            setDesc={(data) => setOverAllFeedback(data)}
            disabled={isFeedbackDisabled}
          />
        </div>

        <div className="d-flex flex-column gap-3">
          <Text type="h2" text="Core Skills" fontWeight={600} />

          {skillQuestions?.map((skill, i) => {
            return (
              <div className="d-flex flex-column gap-2">
                {/* <div className="input-group"> */}

                <div className="d-flex align-items-center gap-2">
                  <input
                    placeholder="Enter Skills"
                    size="md"
                    disabled={skill.type === "skill_added" ? false : true}
                    value={skill.question_name}
                    name="skill_id"
                    onChange={(e) => {
                      onChangeHandlerSkill(e.target.value, i);
                    }}
                    onBlur={(e) => {
                      setTimeout(() => {
                        setJtSuggestion([]);
                      }, 200);
                    }}
                    onKeyDown={(e) => handleKeyPress(e, i)}
                    style={{
                      width: "100%",
                      padding: "8px",
                      borderRadius: "4px",
                      border: "1px solid #cbd5e0",
                      backgroundColor:
                        skill.type === "skill_added" ? "#ffffff" : "#e2e8f0",
                      color:
                        skill.type === "skill_added" ? "#000000" : "#a0aec0",
                      cursor:
                        skill.type === "skill_added" ? "auto" : "not-allowed",
                    }}
                  />

                  {skill.type === "skill_added" ? (
                    <Button
                      p={2}
                      colorScheme="transparent"
                      w={"fit-content"}
                      cursor={"pointer"}
                      onClick={() => {
                        var tempArray = [...skillQuestions];
                        tempArray.splice(i, 1);
                        setSkillQuestions(tempArray);
                      }}
                      buttonType="light"
                      buttonIcon={
                        <AiTwotoneDelete
                          color="red"
                          style={{ cursor: "pointer" }}
                          size={20}
                        />
                      }
                    ></Button>
                  ) : null}
                </div>
                {/* <input type="text" className="form-control" placeholder="Enter skill" id='search_skills' value={skill.question_name} name='skill_id' onChange={(e) => { onChangeHandlerSkill(e.target.value, i); }}
                                        onBlur={(e) => {
                                            setTimeout(() => { setJtSuggestion([]); }, 200)
                                        }}
                                        onKeyDown={(e) => handleKeyPress(e, i)}
                                        disabled={skill.type === "skill_added" ? false : true}
                                    /> */}

                <div className="skill_suggestion_box suggestion_box">
                  {jtSuggestion &&
                    activeRow === i &&
                    jtSuggestion.map((suggestion, i) => (
                      <p
                        key={i}
                        onClick={() => {
                          var tempArray = [...skillQuestions];
                          tempArray[activeRow].question_name = suggestion.name;
                          tempArray[activeRow].skill_id = suggestion.id;
                          setSkillQuestions(tempArray);
                          setJtSuggestion([]);
                        }}
                      >
                        {suggestion.name}
                      </p>
                    ))}
                </div>

                <RatingWrapper
                  count={5}
                  size={24}
                  activeColor="#ffd700"
                  value={skill?.rating || 0}
                  onChange={(rating) => {
                    const newSkillQuestions = [...skillQuestions];
                    newSkillQuestions[i].rating = rating;
                    setSkillQuestions(newSkillQuestions);
                  }}
                  edit={!isFeedbackDisabled}
                />
                <Editor
                  desc={!isNullOrEmpty(skill.feedback) ? skill.feedback : ""}
                  setDesc={(data) => {
                    const newSkillQuestions = [...skillQuestions];
                    newSkillQuestions[i].feedback = data;
                    setSkillQuestions(newSkillQuestions);
                  }}
                  disabled={isFeedbackDisabled}
                />
              </div>
            );
          })}

          <Button
            buttonType="light"
            text="Add Another"
            className="btn tt-btn__light d-flex align-items-center gap-2 flex-row-reverse"
            onClick={() => addCoreSkills()}
            buttonIcon={
              <IconResource type="roundPlus" size="20" color="var(--green)" />
            }
          >
            <IconResource type="roundPlus" size={20} color="var(--green)" /> Add
            Another
          </Button>
        </div>

        <div className="d-flex flex-column gap-2">
          <Text type="h2" text="Soft Skills" fontWeight={600} />

          {softSkillQuestions?.map((skill, i) => {
            return (
              <div className="d-flex flex-column gap-2">
                <div className="d-flex align-items-center gap-2">
                  <input
                    value={skill.question_name}
                    placeholder="Enter Skills"
                    size="md"
                    disabled={skill.type === "skill_added" ? false : true}
                    onChange={(e) => {
                      const newSkillQuestions = [...softSkillQuestions];
                      newSkillQuestions[i].question_name = e.target.value;
                      setSoftSkillQuestions(newSkillQuestions);
                    }}
                    style={{
                      width: "100%",
                      padding: "8px",
                      borderRadius: "4px",
                      border: "1px solid #cbd5e0",
                      backgroundColor:
                        skill.type === "skill_added" ? "#ffffff" : "#e2e8f0",
                      color:
                        skill.type === "skill_added" ? "#000000" : "#a0aec0",
                      cursor:
                        skill.type === "skill_added" ? "auto" : "not-allowed",
                    }}
                  />
                  {skill.type === "skill_added" ? (
                    <Button
                      buttonType="light"
                      buttonIcon={
                        <AiTwotoneDelete
                          color="red"
                          style={{ cursor: "pointer" }}
                          size={20}
                        />
                      }
                      onClick={() => {
                        var tempArray = [...softSkillQuestions];
                        tempArray.splice(i, 1);
                        setSoftSkillQuestions(tempArray);
                      }}
                    ></Button>
                  ) : null}
                </div>
                <RatingWrapper
                  count={5}
                  size={24}
                  activeColor="#ffd700"
                  value={skill.rating}
                  onChange={(rating) => {
                    const newSkillQuestions = [...softSkillQuestions];
                    newSkillQuestions[i].rating = rating;
                    setSoftSkillQuestions(newSkillQuestions);
                  }}
                  edit={!isFeedbackDisabled}
                />
                <Editor
                  desc={!isNullOrEmpty(skill.feedback) ? skill.feedback : 0}
                  setDesc={(data) => {
                    const newSkillQuestions = [...softSkillQuestions];
                    newSkillQuestions[i].feedback = data;
                    setSoftSkillQuestions(newSkillQuestions);
                  }}
                  disabled={isFeedbackDisabled}
                />
              </div>
            );
          })}

          <Button
            buttonType="light"
            className="btn tt-btn__light d-flex align-items-center gap-2 flex-row-reverse"
            buttonIcon={
              <IconResource type="roundPlus" size="20" color="var(--green)" />
            }
            onClick={() => addSoftSkills()}
            text="Add Another"
          >
            <IconResource type="roundPlus" size={20} color="var(--green)" /> Add
            Another
          </Button>
        </div>

        {!isFeedbackDisabled ? (
          <div className="d-flex ajustify-content-end">
            <Button
              buttonType="secondary"
              text="Save"
              style={{ marginRight: "10px" }}
              onClick={() => checkFeedback()}
            ></Button>
            <Button
              buttonType="primary"
              text="Save & End Meeting"
              onClick={() => saveFeedback("completed")}
            ></Button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export const ChecklistForm = ({
  questions,
  getAnswerOfQuestion,
  setAnswerOfChecklistQuestion,
  isFeedbackDisabled,
}) => {
  return (
    <div
      style={{
        backgroundColor: "#F0F0F0",
        borderRadius: "20px",
        padding: "1.25rem",
        marginTop: "1.5rem",
      }}
    >
      <Text
        text="Standard Questions Checklist"
        fontWeight={600}
        fontSize={"md"}
      />

      <div defaultValue="1">
        <div className="mt-3">
          {questions?.checklist !== undefined && questions?.checklist.length > 0
            ? questions.checklist.map((question, i) => {
                return (
                  <Form.Check
                    type="checkbox"
                    id={`checkbox-${question.id}`}
                    checked={
                      getAnswerOfQuestion(question?.id) !== null &&
                      getAnswerOfQuestion(question?.id).is_checked === "1"
                    }
                    onChange={(e) =>
                      setAnswerOfChecklistQuestion(e, question?.id)
                    }
                    disabled={isFeedbackDisabled}
                    label={question?.question}
                  />
                );
              })
            : null}
        </div>
      </div>
    </div>
  );
};
