import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from "react-router-dom";
import * as storageConstants from '@/constants/storageConstants';
import * as commonServices from '@/services/common';
import * as ApiHelper from '@/services/apiHelper';
import * as toast from "@/wrapper/toast";
import { LOCATION_THRESHOLD } from '@/constants/storageConstants';
import { KEYBOARD_SHORTCUT_TEXT, ADD_SKILL_SUGGESTION } from '@/constants/stringConstants';
import './Common.scss';
// Import Swiper styles
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import searchIcon from "@/Assets/svgs/search.svg"
import searchIconWhite from "@/Assets/svgs/searchw.svg"
import { consoleLog, consoleLogE } from '@/utils/console';
import { isNotEmpty, shuffleArray } from '@/utils/arrayUtils';
import { addDataOnFocusOut } from '@/services/common';
import SearchType from './SearchType';
import KeywordSearchTextbox from './KeywordSearchTextbox';
import { stringCompare } from '../../utils/stringUtils';

function StickySearchFilter({ setJobSearch, setLocationSearch, setLocationSearchName, job_type, setJob_type, location, setLocation, preferredSkillName, setPreferredSkillName, preferredLocationName, setPreferredLocationName, type, setLoading, keywordSearch, setKeywordSearch, searchType, setSearchType, setSearchFilterState, selectedSearch, setSelectedSearch, keywordSeachFilters, setKeywordSeachFilters }) {

    const navigate = useNavigate();
    const masterData = commonServices.getLocalData(storageConstants.DATA);
    const user_role = commonServices.getLocalData(storageConstants.USER_ROLE);
    // const [job_type, setJob_type] = useState([]);
    // const [location, setLocation] = useState([]);
    // const [preferredLocationName, setPreferredLocationName] = useState([]);
    // const [preferredSkillName, setPreferredSkillName] = useState([]);
    const [jobLocations, setJobLocations] = useState([]);
    const [featuredJobs, setFeaturedJobs] = useState([]);
    const [homepageLogos, setHomepageLogos] = useState([]);

    const [jtSuggestion, setJtSuggestion] = useState([]);
    const [locSuggestion, setLocSuggestion] = useState();
    const [wordCloudWords, setWordCloudWords] = useState([]);
    const [featuredGigs, setFeaturedGigs] = useState([]);

    let skillRef = useRef();
    let skillRef1 = useRef();
    let locationRef = useRef();
    let locationRef1 = useRef();

    useEffect(() => {
        if (selectedSearch === 'job') {
            let handler = (event) => {
                if (!skillRef.current.contains(event.target)) {
                    if (jtSuggestion.length > 0) {
                        addDataOnFocusOut(jtSuggestion, setJtSuggestion, setJob_type, setPreferredSkillName);
                    } else {
                        addSkillCall();
                    }
                    if (document.getElementById('search_job_type1') !== null) {
                        document.getElementById('search_job_type1').value = '';
                    }
                }
                if (!locationRef.current.contains(event.target)) {
                    addDataOnFocusOut(locSuggestion, setLocSuggestion, setLocation, setPreferredLocationName);
                    document.getElementById('search_location_1').value = '';
                }
            }
            document.addEventListener("mousedown", handler);

            return () => {
                document.removeEventListener("mousedown", handler)
            }
        }
        else if (selectedSearch === 'jobseeker' || selectedSearch === 'gig') {
            let handler = (event) => {
                if (!skillRef1.current.contains(event.target)) {
                    if (jtSuggestion.length > 0) {
                        addDataOnFocusOut(jtSuggestion, setJtSuggestion, setJob_type, setPreferredSkillName);
                    } else {
                        addSkillCall();
                    }
                    if (document.getElementById('search_job_type') !== null) {
                        document.getElementById('search_job_type').value = '';
                    }
                }
                if (!locationRef1.current.contains(event.target)) {
                    addDataOnFocusOut(locSuggestion, setLocSuggestion, setLocation, setPreferredLocationName);
                    document.getElementById('search_location').value = '';
                }
            }
            document.addEventListener("mousedown", handler);

            return () => {
                document.removeEventListener("mousedown", handler)
            }
        }
    });

    const initiateWordCloudData = () => {
        if (isNotEmpty(masterData?.industry_domains)) {
            var arr = [];
            masterData?.industry_domains.map((domain) => {
                for (let index = 0; index < 5; index++) {
                    arr.push({
                        text: domain.name,
                        value: Math.random() * 100,
                        id: domain.id,
                        originalSize: 0
                    });
                }
            });
            setWordCloudWords(shuffleArray(arr));
        }
    }

    const onChangeHandlerSkill = (text) => {
        if (text.length > storageConstants.SKILL_THRESHOLD) {
            commonServices.getSkillByName(text).then((skills) => {
                setJtSuggestion(skills);
            });
        } else {
            setJtSuggestion([]);
        }
    }

    const onChangeHandler_location = (text, locType = '') => {
        if (user_role && (user_role === 'CA' || user_role === 'CU')) {
            locType = 'USER_PROFILE_LOCATIONS';
        } else if (selectedSearch === "job" || type === "job") {
            locType = 'JOB_LOCATIONS';
        } else if (selectedSearch === 'gig' || type === 'gig') {
            locType = 'GIG_LOCATIONS';
        } else if (selectedSearch === 'jobseeker') {
            locType = 'USER_PROFILE_LOCATIONS';
        }
        if (text.length > LOCATION_THRESHOLD) {
            commonServices.getLocations(text + '&type=' + locType).then((locations) => {
                setLocSuggestion(locations);
            });
        } else {
            setLocSuggestion([]);
        }
    }

    const del_job_type = async (e, data) => {
        e.preventDefault();
        var arr = job_type;

        await setJob_type([]);

        for (var i = 0; i < arr.length; i++) {

            if (parseInt(arr[i]) === data) {
                arr.splice(i, 1);
            }

        }
        setJob_type(arr);
    }

    const del_location = async (e, data) => {
        e.preventDefault();
        var arr = location;
        await setLocation('');

        for (var i = 0; i < arr.length; i++) {

            if (parseInt(arr[i]) === data) {
                arr.splice(i, 1);
            }

        }
        setLocation(arr);
    }

    const [addSkillElement, setAddSkillElement] = useState('');


    const addSkills = (event) => {
        if (event.which === 13 || event.keyCode === 13) {

            addSkillCall();
            event.target.value = '';
        }
    }

    const addSkillCall = () => {
        if (addSkillElement !== '' && addSkillElement !== undefined) {
            const formData = new FormData();
            formData.append('name', addSkillElement);
            setLoading(true);
            ApiHelper.addSkill(formData).then((response) => {
                setLoading(false);
                if (response.isSuccess === true) {
                    var i = 0;
                    if (jtSuggestion.length > 0) {
                        setJob_type((value) => {
                            if (value.includes(jtSuggestion[i].id)) {
                                return [...value]
                            }
                            return [...value, jtSuggestion[i].id]
                        });


                        setPreferredSkillName((value) => {
                            if (value.some(val => val.id === jtSuggestion[i].id)) {
                                return [...value]
                            }
                            return [...value, jtSuggestion[i]]
                        });
                    } else {


                        response.data.forEach(element => {
                            if (stringCompare(addSkillElement,element.name)) {

                                setJob_type((value) => {
                                    if (value.includes(element.id)) {
                                        return [...value]
                                    }
                                    return [...value, element.id]
                                });


                                setPreferredSkillName((value) => {
                                    if (value.some(val => val.id === element.id)) {
                                        return [...value]
                                    }
                                    return [...value, element]
                                });

                            }
                        })
                    }

                    onChangeHandlerSkill('');
                    setAddSkillElement('');
                    setLoading(false);

                }
                else {
                    toast.warn(response.message);
                }

            })
        }

    }

    const FindWork = async (data) => {

        await setLocationSearch(location);
        await setJobSearch(job_type);
        var queryParam = '?';
        for (let i = 0; i < job_type.length; i++) {
            queryParam += "skills=" + job_type[i] + "&";
        }
        for (let i = 0; i < location.length; i++) {
            queryParam += "locations=" + location[i] + "&";
        }
        await setLocationSearchName(preferredLocationName);
        if (user_role) {
            if (user_role === 'CA' || user_role === 'CU') {
                navigate('/jobseeker/search' + queryParam);
            } else {
                navigate(`/${data}/search` + queryParam);
            }
        } else {
            navigate(`/${data}/search` + queryParam);
        }
    }
    useEffect(() => {
        if (user_role) {

            if (user_role === 'CA' || user_role === 'CU') {
                setSelectedSearch('jobseeker');
            } else {
                setSelectedSearch(type);

            }
        }

    }, [user_role])

    useEffect(() => {
        if (user_role) {

            if (user_role === 'CA' || user_role === 'CU') {
                setSelectedSearch('jobseeker');
            } else {
                setSelectedSearch(type);

            }
        } else {
            setSelectedSearch(type);
        }

    }, [type])

    useEffect(() => {
        setLoading(false);
        initiateWordCloudData();
        ApiHelper.getFeaturedJobs().then((response) => {
            setLoading(false);
            if (response.isSuccess === true) {
                setFeaturedJobs(response.data);
            }
            else {
                consoleLogE('getFeaturedJobs', response.message);
                toast.warn(response.message);
            }
        });

        ApiHelper.getHomepageLogos().then((response) => {
            setLoading(false);
            if (response.isSuccess === true) {
                setHomepageLogos(response.data);
            }
            else {
                consoleLogE('getHomepageLogos', response.message);
            }
        });

        ApiHelper.getLocations().then((response) => {
            setLoading(false);
            if (response.isSuccess === true) {
                setJobLocations(response.data);
            }
            else {
                consoleLogE('getLocations', response.message);
            }
        });

        ApiHelper.getFeaturedGigs().then((response) => {
            setLoading(false);
            if (response.isSuccess === true) {
                setFeaturedGigs(response.data.data);
            }
            else {
                consoleLogE('getFeaturedGigs', response.message);
                toast.warn(response.message);
            }
        });
    }, []);

    const triggerClickDown = (event) => {
        const list = [...document.querySelectorAll('.sugg_p')];
        const active = document.querySelector('.sugg_p.active');
        let i = list.indexOf(active);

        if (event.which === 40 || event.keyCode === 40) {

            if (active) {
                active.classList.remove('active')
            }

            const c = document.getElementsByClassName('sugg_p hand-hover');

            if (c.length > 0) {
                if (c[i + 1]) {
                    c[i + 1].classList.add('active');
                    c[i + 1].scrollIntoView({ block: "nearest", inline: "nearest" });
                }
            }

        } else if (event.which === 27 || event.keyCode === 27) {
            setJtSuggestion([]);
            setLocSuggestion([]);
        } else if (event.which === 9 || event.keyCode === 9) {
            if (event.target.name === 'skill') {
                if (jtSuggestion.length > 0) {
                    addDataOnFocusOut(jtSuggestion, setJtSuggestion, setJob_type, setPreferredSkillName);
                } else {
                    addSkillCall();
                }
                event.target.value = '';
            } else if (event.target.name === 'location') {
                addDataOnFocusOut(locSuggestion, setLocSuggestion, setLocation, setPreferredLocationName);
                event.target.value = '';
            }
        }
    }

    const triggerClickUp = (event) => {
        const list = [...document.querySelectorAll('.sugg_p')];
        const active = document.querySelector('.sugg_p.active');
        let i = list.indexOf(active);
        if (event.which === 38 || event.keyCode === 38) {
            if (active) {
                active.classList.remove('active')
            }
            const c = document.getElementsByClassName('sugg_p hand-hover');

            if (c.length > 0) {
                if (c[i - 1]) {
                    c[i - 1].classList.add('active');
                    c[i - 1].scrollIntoView({ block: "nearest", inline: "nearest" });
                }
            }

        }
    }


    const triggerSelect = (event) => {
        const list = [...document.querySelectorAll('.sugg_p')];
        const active = document.querySelector('.sugg_p.active');
        let i = list.indexOf(active);

        if (event.which === 13 || event.keyCode === 13) {
            const c = document.getElementsByClassName('sugg_p hand-hover');

            if (active) {
                active.click();
            }
        }

    }

    const [suggestionBoxState, setSuggestionBoxState] = useState({
        jobs: false,
        candidates: false,
    });

    useEffect(() => {
        var locElement1 = document.getElementById('f_t_box_location1');
        var locElement2 = document.getElementById('f_t_box_location2');
        if (locElement1 !== null) {
            locElement1.scrollLeft = locElement1.scrollWidth;
        }
        if (locElement2 !== null) {
            locElement2.scrollLeft = locElement2.scrollWidth;
        }
    }, [location]);

    useEffect(() => {
        var skillElement1 = document.getElementById('f_t_box_skills1');
        var skillElement2 = document.getElementById('f_t_box_skills2');
        if (skillElement1 !== null) {
            skillElement1.scrollLeft = skillElement1.scrollWidth;
        }
        if (skillElement2 !== null) {
            skillElement2.scrollLeft = skillElement2.scrollWidth;
        }
    }, [job_type]);

    return (
        <div className="tt-container">
            <div className='sticky-search-item '><ul className="nav b_s_find nav-tabs" id="myTab" role="tablist">
                {
                    user_role ?
                        (user_role === 'CA' || user_role === 'CU')
                            ? (

                                <li className="nav-item" >
                                    <button style={{ cursor: "auto" }} className="nav-link find-btn active" id="home-tab" onClick={() => setSelectedSearch('jobseeker')}>Search Candidates</button>
                                </li>

                            )
                            : <>
                                {type === 'job' ? (
                                    <li className="nav-item" >
                                        <button style={{ cursor: "auto" }} className="nav-link find-btn active " id="home-tab" onClick={() => setSelectedSearch("job")}>Search Jobs</button>
                                    </li>

                                ) : (
                                    <li className="nav-item" >
                                        <button style={{ cursor: "auto" }} className="nav-link find-btn active " id="home-tab" onClick={() => setSelectedSearch("gig")}>Search Gigs</button>
                                    </li>
                                )}
                            </>
                        : <>
                            {type === 'job'
                                ? (
                                    <li className="nav-item" >
                                        <button className={(selectedSearch === 'job' || selectedSearch === 'gig') ? " find-btn active" : " find-btn"} onClick={() => {
                                            setSelectedSearch("job")
                                            setLocation([]);
                                            setJob_type([]);
                                        }}>Search Jobs</button>
                                    </li>
                                )
                                : (
                                    <li className="nav-item" >
                                        <button className={(selectedSearch === 'job' || selectedSearch === 'gig') ? " find-btn active" : " find-btn"} onClick={() => {
                                            setSelectedSearch("gig")
                                            setLocation([]);
                                            setJob_type([]);
                                        }}>Search Gigs</button>
                                    </li>
                                )}

                            <li className="nav-item" >
                                <button className={selectedSearch === "jobseeker" ? " find-btn active" : " find-btn"} onClick={() => {
                                    setSelectedSearch("jobseeker")
                                    setLocation([]);
                                    setJob_type([]);
                                }}>Search Candidates</button>

                            </li>
                        </>}

            </ul>
                <div className="banner_search" >

                    <div className={jtSuggestion?.length > 0 ? "tab-content b_s_search active" : "tab-content b_s_search"} id="myTabContent">
                        {
                            selectedSearch === 'job' ?
                                <div className="tab__pane fade show active" id="home" >
                                    <div className="b_s_search">
                                        <div className='search_bar border-right skill-search-bar' ref={skillRef}>
                                            <div className="search_input">
                                                <img onError={commonServices.imgError} src="/Assets/svgs/search.svg" alt="" />
                                                <div className='d-flex f_t_box' id='f_t_box_skills1'>

                                                    {preferredSkillName ? <> {
                                                        job_type.map((jType, i) => {
                                                            return preferredSkillName.map((skill, k) => {
                                                                if (skill.id === jType)
                                                                    return <div className="f_t" key={k}>
                                                                        <p>{skill.name}</p>
                                                                        <button onClick={(e) => { del_job_type(e, skill.id) }} className="del_jt"><img src="../../Assets/svgs/x.svg" alt="" /></button>
                                                                    </div>

                                                            })
                                                        })}</> : null}
                                                </div>
                                                {searchType === 'smart' ?
                                                    <input type="text" placeholder={job_type.length > 0 ? '' : `Skill/${type === "job" ? "Job" : "Gig"} roles`} id='search_job_type1' name='skill' onChange={(e) => { onChangeHandlerSkill(e.target.value); setAddSkillElement(e.target.value) }} onKeyPress={(e) => { addSkills(e); triggerSelect(e) }} onKeyDown={(e) => triggerClickDown(e)} onKeyUp={(e) => triggerClickUp(e)}
                                                        onFocus={() => { setSuggestionBoxState({ jobs: true, candidates: false }) }}
                                                    // onBlur={(e) => { e.target.value = ''; addDataOnFocusOut(jtSuggestion, setJtSuggestion, setJob_type, setPreferredSkillName); }}
                                                    />
                                                    :

                                                    <KeywordSearchTextbox keywordSearch={keywordSearch} setKeywordSearch={setKeywordSearch} FindWork={FindWork} searchBar={"sticky"} />
                                                }
                                            </div>

                                            {
                                                suggestionBoxState.jobs &&
                                                <div style={{ height: "auto", position: "relative" }}>

                                                    <div className={jtSuggestion?.length > 0 ? 'suggestion_box active' : "suggestion_box"}>
                                                        <div >
                                                            {jtSuggestion && jtSuggestion.map((suggestion, i) =>
                                                                <p key={i} className={i === 0 ? 'sugg_p hand-hover active' : 'sugg_p hand-hover'} onClick={() => {
                                                                    setJob_type((value) => {
                                                                        if (value.includes(suggestion.id)) {
                                                                            return [...value]
                                                                        }
                                                                        return [...value, suggestion.id]
                                                                    }); setPreferredSkillName((value) => {
                                                                        if (value.some(val => val.id === suggestion.id)) {
                                                                            return [...value]
                                                                        }
                                                                        return [...value, suggestion]
                                                                    });
                                                                    if (document.getElementById('search_job_type1') !== null) {
                                                                        document.getElementById('search_job_type1').value = '';
                                                                    }
                                                                    onChangeHandlerSkill('');
                                                                    setAddSkillElement('');
                                                                }}>{suggestion.name} </p>)}

                                                        </div>
                                                        {
                                                            jtSuggestion?.length > 0 ? <div style={{ position: "sticky", bottom: 0, background: "#f5f5f5", padding: "10px", color: "#9398A1", fontSize: "0.875em" }}>{KEYBOARD_SHORTCUT_TEXT}</div> : addSkillElement.length > 3 ? <div style={{ position: "sticky", bottom: 0, background: "#f5f5f5", padding: "10px", color: "#9398A1", fontSize: "0.875em" }}>{ADD_SKILL_SUGGESTION}</div> : null
                                                        }
                                                    </div>
                                                </div>
                                            }
                                        </div>

                                        <div className='search_bar location-search-bar' ref={locationRef}>
                                            <div className="search_input">
                                                <img onError={commonServices.imgError} src="/Assets/svgs/map_pin.svg" alt="" />
                                                <div className='d-flex f_t_box' id='f_t_box_location1'>

                                                    {preferredLocationName ? <> {
                                                        location && location.map((loc, i) => {
                                                            return preferredLocationName.map((data, k) => {
                                                                if (data.id === loc)
                                                                    return <div className="f_t" key={k}>
                                                                        <p>{data.name}, {data.description}</p>
                                                                        <button onClick={(e) => { del_location(e, data.id) }} className="del_jt"><img src="../../Assets/svgs/x.svg" alt="" /></button>
                                                                    </div>

                                                            })
                                                        })}</> : null}
                                                </div>
                                                <input type="text" placeholder={location?.length > 0 ? '' : "City"} id='search_location_1' name='location' autoComplete='off' onChange={(e) => user_role ? (user_role === 'CA' || user_role === 'CU') ? onChangeHandler_location(e.target.value, 'USER_PROFILE_LOCATIONS') : onChangeHandler_location(e.target.value, 'JOB_LOCATIONS') : onChangeHandler_location(e.target.value, 'JOB_LOCATIONS')} onKeyPress={(e) => { triggerSelect(e) }} onKeyDown={(e) => triggerClickDown(e)} onKeyUp={(e) => triggerClickUp(e)}
                                                    onFocus={() => { setSuggestionBoxState({ jobs: false, candidates: true }) }}
                                                // onBlur={(e) => { e.target.value = ''; addDataOnFocusOut(locSuggestion, setLocSuggestion, setLocation, setPreferredLocationName); }}
                                                />
                                            </div>

                                            {
                                                suggestionBoxState.candidates &&

                                                <div className={locSuggestion?.length > 0 ? ' suggestion_box active' : "suggestion_box"}>
                                                    <div>

                                                        {locSuggestion && locSuggestion.map((suggestion, i) =>
                                                            <p key={i} className={i === 0 ? 'sugg_p hand-hover active' : 'sugg_p hand-hover'}
                                                                onClick={() => {
                                                                    var isLocationExist = location.includes(suggestion.id) ? true : false;
                                                                    setLocation(isLocationExist ? location : [...location, suggestion.id]);
                                                                    document.getElementById('search_location_1').value = '';
                                                                    onChangeHandler_location('');
                                                                    setPreferredLocationName((value) => {
                                                                        if (value.some(val => val.id === suggestion.id)) {
                                                                            return [...value]
                                                                        }
                                                                        return [...value, suggestion]
                                                                    });
                                                                }}>{suggestion.name}, {suggestion.description}</p>)}
                                                    </div>
                                                    {
                                                        locSuggestion?.length > 0 ? <div style={{ position: "sticky", bottom: 0, background: "#f5f5f5", padding: "10px", color: "#9398A1", fontSize: "0.875em" }}>{KEYBOARD_SHORTCUT_TEXT}</div> : null
                                                    }
                                                </div>
                                            }

                                        </div>
                                        <div className="btn_search_bar">

                                            {user_role ?
                                                (user_role === 'CA' || user_role === 'CU') ?
                                                    <button className="btn btn-search" onClick={() => { FindWork(selectedSearch) }}>
                                                        <img src={searchIconWhite} alt="" />  Search
                                                    </button> :
                                                    <button className="btn btn-search" onClick={() => { FindWork(type) }}>
                                                        <img src={searchIconWhite} alt="" /> Search
                                                    </button> :
                                                <button className="btn btn-search" onClick={() => FindWork(selectedSearch)}>
                                                    <img src={searchIconWhite} alt="" /> Search
                                                </button>
                                            }
                                        </div>

                                    </div>
                                </div > :
                                <div className="tab__pane fade show active" id="profile">
                                    <div className="b_s_search">
                                        <div className='search_bar border-right skill-search-bar' ref={skillRef1}>
                                            <div className="search_input">
                                                <img onError={commonServices.imgError} src="/Assets/svgs/search.svg" alt="" />
                                                <div className=' f_t_box' id='f_t_box_skills2'>


                                                    {preferredSkillName ? <> {
                                                        job_type.map((jType, i) => {
                                                            return preferredSkillName.map((skill, k) => {
                                                                if (skill.id === jType)
                                                                    return <div className="f_t" key={k}>
                                                                        <p>{skill.name}</p>
                                                                        <button onClick={(e) => { del_job_type(e, skill.id) }} className="del_jt"><img src="../../Assets/svgs/x.svg" alt="" /></button>
                                                                    </div>

                                                            })
                                                        })}</> : null}
                                                </div>
                                                {searchType === 'smart' ?
                                                    <input type="text" name='skill' placeholder={job_type.length > 0 ? '' : `Skill/${type === "job" ? "Job" : "Gig"} roles`} id='search_job_type' onChange={(e) => { onChangeHandlerSkill(e.target.value); setAddSkillElement(e.target.value) }} onKeyPress={(e) => { addSkills(e); triggerSelect(e) }} onKeyDown={(e) => triggerClickDown(e)} onKeyUp={(e) => triggerClickUp(e)}
                                                        onFocus={() => { setSuggestionBoxState({ jobs: true, candidates: false }) }}
                                                    // onBlur={(e) => { e.target.value = ''; addDataOnFocusOut(jtSuggestion, setJtSuggestion, setJob_type, setPreferredSkillName); }}
                                                    />
                                                    : <KeywordSearchTextbox keywordSearch={keywordSearch} setKeywordSearch={setKeywordSearch} FindWork={FindWork} searchBar={"sticky"} />}
                                            </div>
                                            {
                                                suggestionBoxState.jobs &&
                                                <div className={jtSuggestion?.length > 0 ? ' suggestion_box active' : "suggestion_box"}>

                                                    <div>

                                                        {jtSuggestion && jtSuggestion.map((suggestion, i) =>
                                                            <p key={i} className={i === 0 ? 'sugg_p hand-hover active' : 'sugg_p hand-hover'} onClick={() => {
                                                                setJob_type((value) => {
                                                                    if (value.includes(suggestion.id)) {
                                                                        return [...value]
                                                                    }
                                                                    return [...value, suggestion.id]
                                                                }); setPreferredSkillName((value) => {
                                                                    if (value.some(val => val.id === suggestion.id)) {
                                                                        return [...value]
                                                                    }
                                                                    return [...value, suggestion]
                                                                });
                                                                if (document.getElementById('search_job_type') !== null) {
                                                                    document.getElementById('search_job_type').value = '';
                                                                }
                                                                onChangeHandlerSkill('');
                                                                setAddSkillElement('');
                                                            }}>{suggestion.name} </p>)}
                                                    </div>
                                                    {
                                                        jtSuggestion?.length > 0 ? <div style={{ position: "sticky", bottom: 0, background: "#f5f5f5", padding: "10px", color: "#9398A1", fontSize: "0.875em" }}>{KEYBOARD_SHORTCUT_TEXT}</div> : addSkillElement.length > 3 ? <div style={{ position: "sticky", bottom: 0, background: "#f5f5f5", padding: "10px", color: "#9398A1", fontSize: "0.875em" }}>{ADD_SKILL_SUGGESTION}</div> : null
                                                    }
                                                </div>
                                            }

                                        </div>

                                        <div className='search_bar location-search-bar' ref={locationRef1}>
                                            <div className="search_input">
                                                <img onError={commonServices.imgError} src="/Assets/svgs/map_pin.svg" alt="" />
                                                <div className='d-flex f_t_box' id='f_t_box_location2'>
                                                    {preferredLocationName ? <> {
                                                        location && location.map((loc, i) => {
                                                            return preferredLocationName.map((data, k) => {
                                                                if (data.id === loc)
                                                                    return <div className="f_t" key={k}>
                                                                        <p>{data.name}, {data.description}</p>
                                                                        <button onClick={(e) => { del_location(e, data.id) }} className="del_jt"><img src="../../Assets/svgs/x.svg" alt="" /></button>
                                                                    </div>

                                                            })
                                                        })}</> : null}
                                                </div>
                                                <input type="text" name='location' placeholder={location.length > 0 ? '' : "City"} autoComplete='off' id='search_location' onChange={(e) => onChangeHandler_location(e.target.value, 'USER_PROFILE_LOCATIONS')} onKeyPress={(e) => { triggerSelect(e) }} onKeyDown={(e) => triggerClickDown(e)} onKeyUp={(e) => triggerClickUp(e)}
                                                    onFocus={() => { setSuggestionBoxState({ jobs: false, candidates: true }) }}
                                                // onBlur={(e) => { e.target.value = ''; addDataOnFocusOut(locSuggestion, setLocSuggestion, setLocation, setPreferredLocationName); }}
                                                />
                                            </div>

                                            {
                                                suggestionBoxState.candidates &&
                                                <div className={locSuggestion?.length > 0 ? ' suggestion_box active' : "suggestion_box"}>
                                                    <div>
                                                        {locSuggestion && locSuggestion.map((suggestion, i) =>
                                                            <p className={i === 0 ? 'sugg_p hand-hover active' : 'sugg_p hand-hover'} key={i}
                                                                onClick={() => {
                                                                    var isLocationExist = location.includes(suggestion.id) ? true : false;
                                                                    setLocation(isLocationExist ? location : [...location, suggestion.id]);
                                                                    document.getElementById('search_location').value = '';
                                                                    onChangeHandler_location('');
                                                                    setPreferredLocationName((value) => {
                                                                        if (value.some(val => val.id === suggestion.id)) {
                                                                            return [...value]
                                                                        }
                                                                        return [...value, suggestion]
                                                                    });
                                                                }}>{suggestion.name}, {suggestion.description}</p>)}
                                                    </div>
                                                    {
                                                        locSuggestion?.length > 0 ? <div style={{ position: "sticky", bottom: 0, background: "#f5f5f5", padding: "10px", color: "#9398A1", fontSize: "0.875em" }}>{KEYBOARD_SHORTCUT_TEXT}</div> : null
                                                    }
                                                </div>
                                            }

                                        </div>

                                        <div className="btn_search_bar">

                                            {user_role ?
                                                (user_role === 'CA' || user_role === 'CU') ?
                                                    <button className="btn btn-search" onClick={() => { FindWork(selectedSearch) }}>
                                                        <img src={searchIconWhite} alt="" />  Search
                                                    </button> :
                                                    <button className="btn btn-search" onClick={() => { FindWork(type) }}>
                                                        <img src={searchIconWhite} alt="" /> Search
                                                    </button> :
                                                <button className="btn btn-search" onClick={() => FindWork(selectedSearch)}>
                                                    <img src={searchIconWhite} alt="" /> Search
                                                </button>
                                            }
                                        </div>

                                    </div >
                                </div >
                        }
                    </div >


                </div >
            </div>
            <SearchType searchType={searchType} setSearchType={setSearchType} setKeywordSearch={setKeywordSearch} job_type={job_type} setJob_type={setJob_type} searchBar={"sticky"} keywordSeachFilters={keywordSeachFilters} setKeywordSeachFilters={setKeywordSeachFilters} module={selectedSearch} />
        </div>
    )
}

export default StickySearchFilter