import React, { useEffect, useState } from 'react'
import { MaxWidthContainer } from '../Common/MaxWidthContainer'
import { PuffLoader } from 'react-spinners'
import * as commonServices from '@/services/common';
import { AiOutlineDelete } from 'react-icons/ai';
import Text from "../_widgets/Text/Text"

import { RiDeleteBinLine } from 'react-icons/ri'

import AppModal from '../Modals/AppModal';

import { ClearFileValues } from '../../utils/fileUtils';

import Loader from "@/components/Common/Loader"

import defaultAvatar from '@/Assets/svgs/default_avatar.svg';
import { css } from '@emotion/react';
import ProgressBar from '../_widgets/ProgressBar/ProgressBar';
import Upload from '../_widgets/Upload/Upload';
import Input from '../_widgets/Input/Input';
import Button from '../_widgets/Button/Button';
import IconResource from '../_widgets/IconResource/IconResource';
import Dropdown from '../_widgets/Dropdown/Dropdown';


const override = css`
  display: block;
  margin: 0 auto;
  border-color: var(--green);
  left: calc(50% - 75px);
    position: fixed;
`;

const EditPartnerCompanyLayout = ({ color, coverPhotoLoading, loading, profile, setProfile, courseObj, setCourseObj, courseModalOpen, setCourseModalOpen, updateCompanyProfile, addCourse, logoLoading, uploadMedia, deleteCoverPic, navigate, setImgSrc, locationRef, locSuggestion, onChangeHandler_location, triggerClickDown, triggerSelect
}) => {

    return (
        <MaxWidthContainer className="main-body">
            <div className='tt-container'>
                {
                    loading && <Loader />
                }

                <Text type="h2" text="Institute Profile"
                />

                <div className='my-4'>
                    <ProgressBar progress={profile.progress} background="green" height="10px" type="with-text" text={"Profile Completeness"} />
                </div>


                <div
                    className='common_items py-5'

                >
                    <Text text="Details" type="h2" style={{ marginBottom: "20px" }} fontWeight={600} fontSize={"md"} mb={"20px"} />

                    <div className='uploadCv'
                        style={{
                            position: "relative"
                        }}
                    >
                        {profile?.cover_pic !== null ? <> <Button
                            buttonType="light"
                            style={{
                                position: "absolute",
                                right: "10px",
                                top: "10px",
                                boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                                backgroundColor: "#E2E8F0",
                                borderRadius: "50%",
                                padding: "0",
                                border: "none",
                                cursor: "pointer"
                            }}
                            onClick={deleteCoverPic}
                            buttonIcon={<RiDeleteBinLine color='red' />}
                        /></> : null}
                        <div className="upload_cv" style={{
                            backgroundImage: `url(${profile?.cover_pic})`, backgroundRepeat: "no-repeat",
                            backgroundSize: "auto",
                            backgroundPosition: "center",
                        }}>

                            <div className='upload_r'>
                                <div

                                    className='upload_resume rounded p-2 bg-transparent'
                                >
                                    {coverPhotoLoading === false ? <> <input type='file' accept='image/*'
                                        onChange={(e) => uploadMedia(e, 'company_cover_pic')}
                                    />

                                        <div className="preview">
                                            <a style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "10px",
                                                bg: "#f7f7f7"
                                            }} className='hand-hover' href={undefined}>
                                                <img style={{
                                                    margin: "auto",
                                                    width: "20px"
                                                }} onError={commonServices.imgError} src="../Assets/svgs/upload_black.svg" alt="" />
                                                <Text text={`${profile?.cover_pic !== null ? "Change" : "Upload"} Cover Photo`} fontSize={"sm"} />
                                            </a>
                                        </div>
                                    </> : null}
                                    <PuffLoader
                                        loading={coverPhotoLoading}
                                        size={50}
                                        height={50}
                                        width={50}
                                        radios={50}
                                        aria-label="Loading Spinner"
                                        data-testid="loader"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex">
                        <div className="profile_img img-border"><img onError={commonServices.imgError} src={profile?.logo ?? defaultAvatar} alt="" /></div>
                        <div className='upload_picturee'
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }}>
                            {logoLoading === false ? <Upload text="Upload Logo" accept='image/*' onChange={(e) => uploadMedia(e, 'company')} /> : null}
                            <PuffLoader
                                color={color}
                                loading={logoLoading}
                                cssOverride={override}
                                size={50}
                                height={50}
                                width={50}
                                radios={50}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                            />
                        </div>
                    </div>
                </div>

                <div className="d-flex flex-column gap-4">
                    <div className='row'>
                        <div className='col-md-6'>
                            <Input
                                label="Institute Name"
                                value={profile?.name}
                                type={"text"}
                                placeholder={"Enter Institute Name"}
                                onChange={(e) => {
                                    setProfile({ ...profile, name: e.target.value })
                                }}
                            />

                        </div>

                        <div className='col-md-6'>
                            <Input
                                disabled
                                readOnly
                                label="Website"
                                value={profile?.website}
                                type={"text"}
                                placeholder={"Enter Website"}
                            />
                        </div>
                    </div>

                    <div className="d-flex flex-column gap-1 w-100">

                        <Input
                            label="Locations"
                            showLabel={true}
                            value={profile?.addresses[0]?.value}
                            type={"text"} placeholder={"Location Name"}
                            onChange={(e) => {
                                const newAddress = [...profile.addresses]
                                newAddress[0] = e.target.value
                                setProfile({
                                    ...profile,
                                    addresses: newAddress
                                })
                            }}
                        />

                        {profile?.addresses?.length > 0 ? <> {profile?.addresses?.slice(1).map((item, index) => <div className="position-relative">
                            <Input
                                showLabel={false}
                                value={item?.value}
                                type={"text"} placeholder={"Location Name"}
                                onChange={(e) => {
                                    const newAddress = [...profile.addresses]
                                    newAddress[index + 1].value = e.target.value
                                    setProfile({
                                        ...profile,
                                        addresses: newAddress
                                    })
                                }}

                            />
                            <Button
                                buttonType="light"
                                style={{
                                    position: "absolute",
                                    right: "-40px",
                                    top: "0",
                                    boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                                    backgroundColor: "#E2E8F0",
                                    borderRadius: "50%",
                                    padding: "0",
                                    border: "none",
                                    cursor: "pointer"
                                }}
                                onClick={() => {
                                    const newAdd = [...profile.addresses]
                                    newAdd.splice(index + 1, 1)

                                    setProfile({
                                        ...profile,
                                        addresses: newAdd
                                    })
                                }}

                                buttonIcon={<RiDeleteBinLine color='red' />}
                            />
                        </div>)}</>
                            : null
                        }

                        <div className='d-flex align-items-center justify-content-end mt-2'>
                            <Button
                                w="100%"
                                buttonType="primary"
                                text="Add More"
                                p="0"
                                onClick={(e) => {
                                    setProfile({ ...profile, addresses: [...profile.addresses, { value: "" }] })
                                }}

                            >
                            </Button>
                        </div>
                    </div>

                    <div className='d-flex flex-column justify-content-start mt-4 w-100 gap-2'>

                        <Text type="h2" text="Courses" className="mb-2" />

                        <div className='d-flex w-100 flex-column gap-2'>

                            {
                                profile?.courses && profile?.courses?.map((item, index) => <div className='position-relative d-flex align-items-center w-100 gap-2'>
                                    <div className="border rounded-3 w-100 p-2 d-flex align-items-center" style={{ border: "1px solid #E5E7EB" }}>


                                        <div className="d-flex gap-2 align-items-center justify-content-center">
                                            <div className="d-flex align-items-center justify-content-center" style={{ height: "80px", width: "80px", borderRadius: "10px", backgroundColor: "#EDF2F7" }}>
                                                {
                                                    item?.course_image ?
                                                        <>
                                                            <img height="400px" width="100%" src={item?.course_image} />
                                                        </>
                                                        : <img
                                                            src={item?.img_src}
                                                            width="100%"
                                                            height="auto"
                                                        />
                                                }

                                            </div>
                                            <div>

                                                <Text text={item?.title} style={{ color: "var(--green)", fontSize: "0.875rem", fontWeight: 600 }} fontSize={"0.875em"} color={"var(--green)"} fontWeight={600} />
                                                <Text text={item?.url} style={{ color: "var(--green)", fontSize: "0.875rem", fontWeight: 600 }} fontSize={"0.75em"} color={"gray.500"} ml={"auto"} />
                                            </div>
                                        </div>
                                    </div>

                                    <Button
                                        style={{
                                            position: "absolute",
                                            right: "-40px",
                                            boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                                            backgroundColor: "#E2E8F0",
                                            borderRadius: "50%",
                                            padding: "0",
                                            border: "none",
                                            cursor: "pointer"
                                        }}
                                        onClick={() => {
                                            let newCourse = [...profile.courses]
                                            newCourse.splice(index, 1)
                                            setProfile({
                                                ...profile,
                                                courses: newCourse
                                            })
                                        }}
                                        colorScheme="transparent"
                                        aria-label="Search database"
                                        p={0}
                                        buttonIcon={<RiDeleteBinLine color='red' />}
                                    />
                                </div>)
                            }

                        </div>

                        <div className='w-100 mt-0'>
                            <div className='d-flex align-items-center gap-1 hand-hover' onClick={(e) => {
                                setCourseModalOpen(true)
                            }}>
                                <IconResource color="var(--green)" type='plus' />
                                <Button
                                    buttonType="text"
                                    text="Add More"
                                />
                            </div>

                        </div>

                    </div>

                    <div className='mt-4 w-100 d-flex flex-column gap-2' >

                        <Text text="Social Accounts" type="h2" w="100%" fontWeight={600} fontSize={"md"} mb={"20px"} />

                        <div className='row'>
                            <div className='col-md-6'>
                                <Input
                                    value={profile?.instagram}
                                    label="Instagram"
                                    type={"text"} placeholder={"Enter Instagram"}
                                    onChange={(e) => {
                                        setProfile({
                                            ...profile,
                                            instagram: e.target.value
                                        })
                                    }}
                                />
                            </div>

                            <div className='col-md-6' >
                                <Input
                                    label="Linkedin"
                                    value={profile?.linkedin}
                                    onChange={(e) => {
                                        setProfile({
                                            ...profile,
                                            linkedin: e.target.value
                                        })
                                    }}

                                    type={"text"} placeholder={"Enter Linkedin"}
                                />
                            </div>
                        </div>


                        <div className='row' >
                            <div className='col-md-6'>
                                <Input
                                    label="Twitter"
                                    value={profile?.twitter}
                                    onChange={(e) => {
                                        setProfile({
                                            ...profile,
                                            twitter: e.target.value
                                        })
                                    }}
                                    type={"text"} placeholder={"Enter Twitter"}
                                />
                            </div>

                            <div className='col-md-6'>
                                <Input
                                    label="Facebook"
                                    value={profile?.facebook}
                                    onChange={(e) => {
                                        setProfile({
                                            ...profile,
                                            facebook: e.target.value
                                        })
                                    }}
                                    type={"text"} placeholder={"Enter Facebook"}
                                />
                            </div>
                        </div>

                    </div>

                    <div className='mt-4 w-100% d-flex flex-column gap-2'>

                        <Text text="About" type="h2" className="mb-2" w="100%" fontWeight={600} fontSize={"md"} mb={"5px"} />
                        <Input
                            type="textarea"
                            label="Please write two to three sentences outlining your organisation's unique attributes, separating it from others in a way that would attract great talent in the market."
                            value={profile?.description}
                            onChange={(e) => {
                                setProfile({
                                    ...profile,
                                    description: e.target.value
                                })
                            }}
                        />

                    </div>



                </div>

                <div className='mt-4 d-flex justify-content-end w-100 gap-2'>
                    <Button buttonType="secondary" text="Cancel" onClick={(e) => {
                        navigate("/partner/view")
                    }} />

                    <Button buttonType="primary" text="Save" onClick={updateCompanyProfile} />

                </div>

                <AppModal
                    closeModal={() => setCourseModalOpen(false)}
                    isOpen={courseModalOpen}
                    size='xl'
                    title={"Courses"}
                    isFooter={true}
                    submit={() => addCourse()}
                >
                    <div className="d-flex flex-column gap-2">

                        <div className='common_items'>

                            <div className='uploadCv'
                                style={{
                                    position: "relative"
                                }}
                            >
                                <div className="upload_cv" style={{
                                    backgroundImage: `url(${courseObj?.img_src})`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "auto",
                                    backgroundPosition: "center",
                                }}>

                                    <div className='upload_r'>

                                        <div
                                            className='upload_resume rounded p-2 bg-transparent '
                                        >
                                            {!courseObj?.image ?
                                                <>
                                                    <input type='file' accept='image/*' onChange={(e) => {

                                                        setImgSrc(e)

                                                        setCourseObj({
                                                            ...courseObj,
                                                            image: e.target.files[0]
                                                        })
                                                    }}
                                                    />


                                                    <div className="preview">
                                                        <a style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            gap: "10px"
                                                        }} className='hand-hover' href={undefined}>
                                                            <img style={{
                                                                margin: "auto",
                                                                width: "20px"
                                                            }} onError={commonServices.imgError} src="../Assets/svgs/upload_black.svg" alt="" />
                                                            <Text text={`${courseObj?.image_url !== null ? "Change" : "Upload"} Cover Photo`} fontSize={"sm"} />
                                                        </a>
                                                    </div>


                                                </> :
                                                <>
                                                    <Text text={courseObj?.image?.name?.length > 5 ? <>{courseObj?.image?.name.slice(0, 5)}...</> : courseObj?.image?.name} fontSize={"sm"} />

                                                    <div
                                                        onClick={() => {
                                                            ClearFileValues();
                                                            setCourseObj({ ...courseObj, image: null, img_src: null })
                                                        }}
                                                    >
                                                        <AiOutlineDelete color='red' />
                                                    </div>
                                                </>
                                            }
                                            <PuffLoader
                                                loading={coverPhotoLoading}
                                                size={50}
                                                height={50}
                                                width={50}
                                                radios={50}
                                                aria-label="Loading Spinner"
                                                data-testid="loader"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="w-100 d-flex flex-column gap-1">
                            <Input
                                label="Course Name"
                                type={"text"} placeholder={"Enter Course Name"}
                                value={courseObj?.title}
                                onChange={(e) => {
                                    setCourseObj({
                                        ...courseObj,
                                        title: e.target.value
                                    })
                                }}
                            />
                        </div>

                        <div className="w-100 d-flex flex-column gap-1">
                            <Input
                                label="Course URL"
                                type={"text"} placeholder={"Enter Course URL"}
                                value={courseObj?.url}
                                onChange={(e) => {
                                    setCourseObj({
                                        ...courseObj,
                                        url: e.target.value
                                    })
                                }}
                            />
                        </div>

                        <div className="w-100 d-flex flex-column gap-1">
                            <Input
                                type="textarea"
                                label="Description"
                                value={courseObj?.description}
                                onChange={(e) => {
                                    setCourseObj({
                                        ...courseObj,
                                        description: e.target.value
                                    })
                                }}
                            />
                        </div>


                    </div>
                </AppModal>

            </div >

        </MaxWidthContainer >
    )
}

export default EditPartnerCompanyLayout