import React, { useEffect, useState, useRef } from "react";
import * as commonServices from "@/services/common";
import Button from "../../_widgets/Button/Button";
import Table from "../../_widgets/Table/Table";
import { Form } from "react-bootstrap";
import Text from "../../_widgets/Text/Text";

function Recruiters({
  availableCash,
  openTransactionModal,
  companyName,
  companyTransactions,
  isPaymentMethodEnabled,
  openBuyCashModal,
  openAddEditUserModal,
  users,
  profile,
  updateUserStatus,
  updateUserRole,
  activeRecruitersPage,
  handleRecruitersPaginationChange,
  loading,
  setLoading,
  getTransactionData,
}) {
  var tableColumns = [
    "User ID",
    "Name",
    "Email",
    "User TT Cash",
    "Edit",
    "User Rights",
  ];

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setLoading(true);
    var row = [];

    row =
      users?.data &&
      users?.data.map((user, i) => {
        var data = [];
        const handleStatusChange = (e) => {
          const status = e.target.checked ? "active" : ""; // Determine the status based on isChecked
          updateUserStatus(e, user?.id, status);
        };
        data.push(user?.uid);
        data.push(user?.full_name);
        data.push(user?.email);
        data.push(
          <div className="d-flex align-items-center gap-1">
            <a
              className="text-end"
              style={{ width: "70px", textDecoration: "none" }}
              onClick={() => {
                getTransactionData(user?.id);
                openTransactionModal(
                  user.full_name,
                  user.view_transactions,
                  false
                );
              }}
            >
              <Text
                style={{ textDecoration: "none", color: "black" }}
                type="amount"
                amount={user.remaining_views}
              />
            </a>
            <span
              onClick={() => {
                openAddEditUserModal(
                  user.id,
                  user.first_name,
                  user.last_name,
                  user.email,
                  user.remaining_views
                );
              }}
              title="Click to edit user details!"
            >
              <img
                onError={commonServices.imgError}
                style={{
                  marginLeft: "0.5rem",
                  cursor: "pointer",
                  minWidth: "22px",
                  width: "22px",
                }}
                className="edit"
                src=".././Assets/svgs/plus_circle.svg"
                alt=""
              />
            </span>
          </div>
        );
        data.push(
          <>
            <span
              onClick={() => {
                openAddEditUserModal(
                  user.id,
                  user.first_name,
                  user.last_name,
                  user.email,
                  user.remaining_views
                );
              }}
              title="Click to edit user details!"
            >
              <img
                onError={commonServices.imgError}
                style={{
                  marginLeft: "0.5rem",
                  cursor: "pointer",
                  minWidth: "22px",
                  width: "22px",
                }}
                className="edit"
                src=".././Assets/svgs/edit.svg"
                alt=""
              />
            </span>
          </>
        );
        data.push(
          <>
            {profile.id === user.id ? (
              <div className="d-flex align-items-start">
                <div className="d-flex align-items-center ">
                  <div>
                    <label className="switch disabled">
                      <Form>
                        <Form.Check // prettier-ignore
                          type="switch"
                          id="auto-apply"
                          checked={user?.status === "active" ? true : false}
                          value={user?.status === "active" ? true : false}
                          onChange={(e) => updateUserStatus(e, user?.id)}
                        />
                      </Form>
                    </label>
                    <label className="input-lebel-switch disabled" htmlFor="">
                      Active
                    </label>
                  </div>
                  <div>
                    <label className="switch disabled">
                      <Form>
                        <Form.Check // prettier-ignore
                          type="switch"
                          id="auto-apply"
                          checked={user?.role_type === "CA" ? true : false}
                          value={user?.role_type === "CA" ? true : false}
                          onChange={(e) => updateUserRole(e, user?.id)}
                        />
                      </Form>
                    </label>
                    <label className="input-lebel-switch disabled" htmlFor="">
                      Administrator
                    </label>
                  </div>
                </div>
              </div>
            ) : user.need_to_review_user ? (
              <div className="form_buttons">
                <Button
                  buttonType="primary"
                  text="Accept"
                  onClick={(e) => {
                    updateUserStatus(e, user);
                  }}
                />
                <Button
                  buttonType="danger"
                  text="Reject/Block"
                  onClick={(e) => {
                    updateUserStatus(e, user, "block");
                  }}
                />
              </div>
            ) : (
              <div className="d-flex align-items-start">
                <div className="d-flex align-items-center">
                  <div>
                    <label className="switch">
                      <Form>
                        <Form.Check // prettier-ignore
                          type="switch"
                          id="auto-apply"
                          checked={user?.status === "active" ? true : false}
                          value={user?.status === "active" ? true : false}
                          onChange={(e) => updateUserStatus(e, user)}
                        />
                      </Form>
                    </label>

                    <label className="input-lebel-switch" htmlFor="">
                      Active
                    </label>
                  </div>
                  <div>
                    <label className="switch">
                      <Form>
                        <Form.Check // prettier-ignore
                          type="switch"
                          id="auto-apply"
                          checked={user?.role_type === "CA" ? true : false}
                          value={user?.role_type === "CA" ? true : false}
                          onChange={(e) => updateUserRole(user?.id)}
                        />
                      </Form>
                    </label>
                    <label className="input-lebel-switch" htmlFor="">
                      Administrator
                    </label>
                  </div>
                </div>
              </div>
            )}
          </>
        );

        return {
          data: data,
        };
      });

    setTableData(row);
    setLoading(false);
  }, [users]);

  return (
    <>
      {loading ? null : (
        <div className="row">
          <div
            className="col-lg-6 mb-3"
            style={{ padingLeft: "0px !important" }}
          >
            <div className="cash">
              <div style={{ width: "72%" }}>
                <Text
                  type="amount"
                  className="hand-hover"
                  text={"Total Balance TT Cash:"}
                  amount={availableCash}
                  onClick={() => {
                    getTransactionData();
                    openTransactionModal(
                      companyName,
                      companyTransactions,
                      true
                    );
                  }}
                ></Text>
              </div>
              <a
                href={undefined}
                className={
                  isPaymentMethodEnabled()
                    ? "buy-cash hand-hover green-text"
                    : "buy-cash"
                }
                onClick={() => {
                  openBuyCashModal();
                }}
              >
                Buy More
              </a>
            </div>
          </div>

          <div className="col-lg-6 ">
            <Button
              style={{ float: "right" }}
              buttonType="primary"
              onClick={() => {
                openAddEditUserModal();
              }}
              text={"Add Hiring Specialist"}
            />
          </div>
        </div>
      )}

      {loading ? null : users?.data && users?.data.length > 0 ? (
        <Table
          columns={tableColumns}
          data={tableData}
          activePage={activeRecruitersPage}
          handlePaginationChange={handleRecruitersPaginationChange}
          pagination={users}
        />
      ) : (
        <div className="no_jobs">
          <img
            onError={commonServices.imgError}
            src="@/Assets/svgs/search_no_result.svg"
            alt=""
          />
          <h2>No user found!</h2>
        </div>
      )}
    </>
  );
}

export default Recruiters;
