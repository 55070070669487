import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import './CompanyProfile.scss';
import * as toast from "@/wrapper/toast";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";

import * as ApiHelper from '@/services/apiHelper';
import { isNullOrEmpty, getYoutubeVideoId } from '@/utils/stringUtils';
import * as commonServices from '@/services/common';
import defaultAvatar from '@/Assets/svgs/default_avatar.svg';
import Card from '../Common/Card';
import { createSlugFromString } from '@/utils/stringUtils';
import { textCapitalise } from "@/utils/stringUtils";
import mapPin from "@/Assets/svgs/map-pin.svg";
import emptyResult from '@/Assets/svgs/empty_result.svg';
import PartnerCompanyProfile from '../PartnerCompanyProfile/PartnerCompanyProfile';
import CompanyProfileLayout from './CompanyProfileLayout';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: var(--green);
  left: calc(50% - 75px);
    position: fixed;
`;

const CompanyProfile = () => {

    let [loading, setLoading] = useState(false);
    let [color] = useState("#000000");
    let params = useParams();
    const company_id = params.id;

    const [profile, setProfile] = useState([]);
    const [currentTab, setCurrentTab] = useState('profile');

    const isActiveTab = (tab) => {
        return currentTab === tab;
    }
    const onTabClick = (tab) => {
        setCurrentTab(tab);
    }

    var tabsData = [
        {
            text: "Who we are",
            isActive: isActiveTab('profile'),
            onClick: (tab) => onTabClick(tab),
            onClickData: 'profile',
            id: "list-home-list",
            href: "#list-home"
        },
        {
            text: "Jobs",
            isActive: isActiveTab('jobs'),
            onClick: (tab) => onTabClick(tab),
            onClickData: 'jobs',
            id: "list-jobs-list",
            href: "#list-jobs"
        },
        {
            text: "Gigs",
            isActive: isActiveTab('gigs'),
            onClick: (tab) => onTabClick(tab),
            onClickData: 'gigs',
            id: "list-gigs-list",
            href: "#list-gigs"
        },
        {
            text: "Recruiters",
            isActive: isActiveTab('recruiters'),
            onClick: (tab) => onTabClick(tab),
            onClickData: 'recruiters',
            id: "list-recruiters-list",
            href: "#list-recruiters"
        },
    ];

    useEffect(() => {
        getLatestProfile();
    }, [])

    const getLatestProfile = async () => {
        setLoading(true);
        await ApiHelper.getCompanyDetails(company_id).then((response) => {
            setLoading(false);
            if (response.isSuccess === true) {
                setProfile(response?.data);
            } else {
                toast.warn(response.message);
            }
        });
    }

    const recruiterProfile = (id) => {
        window.open("/company/recruiter/" + id, '_blank');
    };


    return (
        <CompanyProfileLayout
            loading={loading}
            color={color}
            profile={profile}
            currentTab={currentTab}
            recruiterProfile={recruiterProfile}
            tabsData={tabsData}
        />
    )
}

export default CompanyProfile