/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react'
import './Modal.scss'
import * as commonServices from '@/services/common';
import * as storageConstants from '@/constants/storageConstants';
import Editor from '../Common/WorkProfileEditor';
import * as erros from '@/constants/stringConstants';
import InputMask from 'react-input-mask';
import { isExist, isNotNullOrEmpty } from '@/utils/objectUtil';
import * as toast from "@/wrapper/toast";
import AppModal from './AppModal';
import DatePickerWrapper from '../Common/DatePicker';
import Input from '../_widgets/Input/Input';

function ModalCert({ showModal, setShowModal, profile, setProfile, setDataAutoSave }) {

    const [certificate, setCertificate] = useState([]);
    const datePickerRef = useRef();
    useEffect(() => {
        setCertificate(isExist(profile.userWorkProfileDetail) && isExist(profile.userWorkProfileDetail.certificate) && isNotNullOrEmpty(profile.userWorkProfileDetail.certificate) ? profile.userWorkProfileDetail.certificate : [{}]);
    }, [showModal])

    const onChange = (e, key) => {
        var tempArray = [...certificate];
        tempArray[key][e.target.name] = e.target.value;
        setCertificate(tempArray);
    }

    const deleteRow = async (e, key) => {
        e.preventDefault();
        var tempProfileArr = [...certificate];
        tempProfileArr.splice(key, 1);
        setCertificate(tempProfileArr);
    }

    const addCertificate = (e) => {
        var isError = false;
        certificate && certificate.map((element, key) => {
            var currentRow = key + 1;
            if (!certificate[key].awarded_by) {
                isError = true;
                toast.warn(erros.EMPTY_ORGANISATION_ERROR + " at row " + currentRow);
            } else if (!certificate[key].title) {
                isError = true;
                toast.warn(erros.EMPTY_CERTIFICATE_TITLE_ERROR + " at row " + currentRow);
            } else if (!certificate[key].from_date) {
                isError = true;
                toast.warn(erros.EMPTY_AWARDED_ON_DATE_ERROR + " at row " + currentRow);
            }
        });

        if (!isError) {
            var tempArray = { ...profile };
            tempArray.userWorkProfileDetail.certificate = certificate;
            setProfile(tempArray);
            setDataAutoSave(true);
            setShowModal(false);
        }


    }

    return (
        <>
            <AppModal
                title={"Add Industry Certification"}
                isOpen={showModal}
                closeModal={() => setShowModal(prev => !prev)}
                isFooter={true}
                size={"xl"}
                submit={addCertificate}
            >

                <div className="modal_body">
                    <div className=''>
                        {certificate && certificate.map((element, key) => {
                            return <>
                                <div className="row row-gap-2">
                                    <div className="col-12 col-sm-6  col-md-4">
                                        <Input label="Awarded By" type="text" id='awarded_by' placeholder="Enter Organisation/Authority Name" value={certificate && certificate[key] && certificate[key].awarded_by ? certificate[key].awarded_by : ""} name='awarded_by' onChange={(e) => onChange(e, key)} />
                                    </div>
                                    <div className="col-12 col-sm-6  col-md-4">
                                        <Input label="Title" type="text" id='title' placeholder="Enter Certification Title" value={certificate && certificate[key] && certificate[key].title ? certificate[key].title : ""} name='title' onChange={(e) => onChange(e, key)} />

                                    </div>
                                    <div className="col-12 col-sm-6  col-md-4">
                                        <DatePickerWrapper
                                            ref={datePickerRef}
                                            label="Awarded On"
                                            customInput={<InputMask mask="99/99/9999" />}
                                            selected={certificate && certificate[key] && certificate[key].from_date ? new Date(certificate[key].from_date) : null} onChange={(date) => { date.setDate(date.getDate() + 1); var fDate = date.toISOString().substring(0, 10).split('-').reverse(); var tempArray = [...certificate]; tempArray[key]['from_date'] = fDate[1] + '/' + fDate[0] + '/' + fDate[2]; setCertificate(tempArray); }}
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="DD/MM/YYYY"
                                            maxDate={new Date()} showYearDropdown yearDropdownItemNumber={storageConstants.YEARS_TOTAL}
                                            scrollableYearDropdown />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col">
                                        <label htmlFor="" className="input-lebel">
                                            Description
                                        </label>
                                        <Editor index={key} workprofileData={certificate} setWorkprofileData={setCertificate} element={element} />
                                    </div>
                                </div>

                                <div className='row mb-3'>
                                    <div className="col-md-2 col-6">
                                        <a className='delete-row' onClick={(e) => { deleteRow(e, key) }}> <img className='' src='../Assets/svgs/trash.svg'></img> Remove</a>
                                    </div>
                                    {key === certificate.length - 1 ?

                                        <div className="col-md-3 col-6">
                                            <a className='add-more' onClick={() => { setCertificate([...certificate, {}]) }}> <img src='../Assets/svgs/plus_circle.svg'></img> Add More</a>
                                        </div>

                                        : ''}

                                    <div className="col"></div>
                                    <div className="col"></div>
                                    <div className="col"></div>
                                    <div className="col"></div>
                                    <div className="col"></div>
                                    <div className="col"></div>
                                </div>
                            </>
                        })}
                    </div>
                </div>

            </AppModal>

        </>
    )
}

export default ModalCert
