import React from "react";
import "./Tabs.scss"

const Tabs = (props) => {

    const render = () => {
        switch (props?.type) {
            default:
                return (
                    <div className="list-group" id="list-tab" role="tablist">
                        {props?.tabs && (props?.tabs.map((tab, i) => {
                            return <a className={"list-group-item list-group-item-action " + (tab?.isActive ? 'active' : '')} key={"tab_" + tab?.id} id={tab?.id} data-bs-toggle="list" href={tab?.href} role="tab" aria-controls="list-home" onClick={() => tab?.onClick(tab?.onClickData)}>{tab?.text}</a>
                        }
                        ))}
                    </div>
                )
        }
    }
    return (<>
        {render()}
    </>
    );
};

export default Tabs;