import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./CandidateScheduleAppointment.scss";
import * as toast from "@/wrapper/toast";
import BookTimeSlot from "./BookTimeSlot/BookTimeSlot";
import left_arrow from "@/Assets/images/arrow-left.svg";
import { MaxWidthContainer } from "../../Common/MaxWidthContainer";
import book_successfull from "@/Assets/svgs/successful_book.svg";
import { Modal } from "react-bootstrap";
import Text from "../../_widgets/Text/Text";

import * as ApiHelper from "@/services/apiHelper";
import AddSkill from "../../Register/RegistrationSteps/Steps/AddSkill";
import { isExist, isNotNullOrEmpty } from "@/utils/objectUtil";
import { NO_SKILL_ERROR_MESSAGE } from "@/constants/stringConstants";
import { BOOK_APPOINTMENT_SUCCESS_MESSAGE } from "@/constants/stringConstants";
import { bookAppointment } from "@/services/apiHelper";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import { changeDateFormat } from "@/utils/dateUtil";
import { redirectBack } from "@/utils/redirectUtil";
import Button from "../../_widgets/Button/Button";
// import Button from '../_widgets/Button/Button';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: var(--green);
  left: calc(50% - 75px);
  position: fixed;
`;

const CandidateScheduleAppointment = () => {
  const skillChildRef = useRef();

  var today = new Date();
  let navigate = useNavigate();
  const [date, setDate] = useState(today.setDate(today.getDate() + 1));
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const onOpen = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };
  let [color] = useState("#000000");

  const [loading, setLoading] = useState(false);
  const [isGetSlots, setIsGetSlots] = useState(false);
  const [profile, setProfile] = useState({});
  const [skill, setSkill] = useState([]);
  const [interviewSkill, setInterviewSkill] = useState([]);
  const [skillData, setSkillData] = useState([]);
  const [currentTab, setCurrentTab] = useState("skills");
  const [selectedSlot, setSelectedSlot] = useState(null);
  let [meetingDetails, setMeetingDetails] = useState(null);

  var url_string = window.location.href;
  var url = new URL(url_string);
  var id = url.searchParams.get("id");

  const validateData = () => {
    return interviewSkill.length > 0;
  };

  const nextStep = () => {
    if (currentTab === "skills" && validateData()) {
      setCurrentTab("calendar");
      setIsGetSlots(!isGetSlots);
    } else if (currentTab === "calendar") {
      setCurrentTab("skills");
    } else {
      toast.warn(NO_SKILL_ERROR_MESSAGE);
    }
  };

  const backToHome = () => {
    navigate("/");
  };
  const showInterview = () => {
    navigate("/my-account?tab=interview");
  };

  const scheduleAppointment = async () => {
    try {
      if (selectedSlot === null) {
        toast.warn("Please select time slot");
        return;
      }

      let reqObj = {
        date: changeDateFormat(date, "YYYY-MM-DD"),
        skills: interviewSkill,
        slots: selectedSlot,
      };
      setLoading(true);
      const res = await bookAppointment(reqObj);
      if (res.isSuccess) {
        onOpen();
      } else {
        toast.warn(res.message);
      }
    } catch (error) {
      console.log("err", error.response.message);
      toast.warn(error.message);
    } finally {
      setLoading(false);
    }
  };

  const getLatestProfile = async () => {
    setLoading(true);
    await ApiHelper.getProfile().then((response) => {
      setLoading(false);
      if (response.isSuccess === true) {
        setProfile(response.data);
      } else {
        toast.warn(response.message);
      }
    });
  };

  const getSkills = () => {
    ApiHelper.getSkills().then((response) => {
      setLoading(false);
      if (response.isSuccess === true) {
        setSkillData(response.data);
      } else {
        console.log("error");
      }
    });
  };

  const setUserSkillData = () => {
    if (
      profile.userWorkProfileDetail !== undefined &&
      profile.userWorkProfileDetail.skill !== undefined &&
      profile.userWorkProfileDetail.skill !== null
    ) {
      profile.userWorkProfileDetail.skill.forEach((currentSkill) => {
        skillData.forEach((element) => {
          if (element.id === currentSkill.skill_id) {
            currentSkill.skill_name = element.name;
          }
        });
      });
    }
    setSkill(
      isExist(profile.userWorkProfileDetail) &&
        isExist(profile.userWorkProfileDetail.skill) &&
        isNotNullOrEmpty(profile.userWorkProfileDetail.skill)
        ? profile.userWorkProfileDetail.skill
        : [{}]
    );
  };

  const checkIsProUser = async () => {
    setLoading(true);
    await ApiHelper.checkCandidateProStatus().then((response) => {
      setLoading(false);
      if (
        response?.data?.invite === null ||
        response?.data?.invite?.status === "requested"
      ) {
        navigate("/get-evaluated");
      } else {
        getLatestProfile();
        getSkills();
      }
    });
  };

  const userCanAddAppointment = async () => {
    setLoading(true);
    await ApiHelper.canUserBookAppointment().then((response) => {
      setLoading(false);
      if (!response?.data?.can_add) {
        navigate("/my-account?tab=interview");
      }
    });
  };

  const addSkillAction = () => {
    navigate("/workprofile?el=Skills&redirect=/candidate-appointment");
  };

  useEffect(() => {
    checkIsProUser();
    userCanAddAppointment();
    if (id !== undefined && id !== null) {
      setLoading(true);
      ApiHelper.getMeetingDetails(id).then((response) => {
        setLoading(false);
        if (response?.isSuccess) {
          setMeetingDetails(response?.data?.interview);
          setInterviewSkill(response?.data?.interview?.skills);
        }
      });
    }
  }, []);

  useEffect(() => {
    setUserSkillData();
  }, [skillData]);

  return (
    <>
      <MaxWidthContainer>
        {loading ? (
          <div className="loader">
            <ClipLoader
              color={color}
              loading={true}
              css={override}
              size={150}
            />{" "}
          </div>
        ) : null}
        <div>
          <div className="appointment_main tt-container">
            <div className="schedule_heading w-100 d-flex flex-column flex-sm-row align-items-start  justify-content-between ">
              <div className="sc_appointment d-flex mb-2 mb-sm-0">
                <img
                  src={left_arrow}
                  alt=""
                  className="hand-hover"
                  onClick={() => redirectBack()}
                />
                <span>Schedule Appointment</span>
              </div>

              <div className="form_buttons appointment_button_wrapper d-flex justify-content-end w-100 w-sm-50 ">
                {currentTab === "skills" ? (
                  <>
                    <Button
                      buttonType="secondary"
                      onClick={() => backToHome()}
                      text="Cancel"
                    />
                    <Button
                      buttonType="primary"
                      onClick={() => nextStep()}
                      text="Next"
                    />
                  </>
                ) : (
                  <>
                    <Button
                      buttonType="secondary"
                      onClick={() => nextStep()}
                      text="Back"
                    />
                    <Button
                      buttonType="primary"
                      text="Confirm Appointment"
                      onClick={() => scheduleAppointment()}
                    />
                  </>
                )}
              </div>
            </div>
            <ul
              class="candidate-pills-wrapper nav nav-pills my-4"
              id="pills-tab"
              role="tablist"
            >
              <li class="nav-item candidate-tab-item" role="presentation">
                <button
                  onClick={() => nextStep()}
                  class={
                    currentTab === "skills" ? "nav-link active" : "nav-link"
                  }
                  id="pills-candidate-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-candidate-home"
                  type="button"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                >
                  Select Skill
                </button>
              </li>
              <li class="nav-item candidate-tab-item" role="presentation">
                <button
                  onClick={() => nextStep()}
                  class={
                    currentTab === "calendar" ? "nav-link active" : "nav-link"
                  }
                  id={
                    validateData()
                      ? "pills-candidate-profile-tab"
                      : "tab-disbaled"
                  }
                  data-bs-toggle="pill"
                  data-bs-target="#pills-candidate-profile"
                  type="button"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                >
                  Book Time Slot
                </button>
              </li>
            </ul>
            <div class="tab-content mb-5" id="pills-tabContent">
              <div
                class={
                  currentTab === "skills"
                    ? "tab-pane fade show active"
                    : "tab-pane fade"
                }
                id="pills-candidate-home"
                role="tabpanel"
                aria-labelledby="pills-candidate-home-tab"
                tabindex="0"
              >
                <AddSkill
                  skill={interviewSkill}
                  setSkill={setInterviewSkill}
                  suggestions={skill}
                  addSkillAction={addSkillAction}
                  ref={skillChildRef}
                />
              </div>
              <div
                class={
                  currentTab === "calendar"
                    ? "tab-pane fade show active"
                    : "tab-pane fade"
                }
                id="pills-candidate-profile"
                role="tabpanel"
                aria-labelledby="pills-candidate-profile-tab"
                tabindex="0"
              >
                <BookTimeSlot
                  interviewSkill={interviewSkill}
                  isGetSlots={isGetSlots}
                  date={date}
                  setDate={setDate}
                  selectedSlot={selectedSlot}
                  setSelectedSlot={setSelectedSlot}
                />
              </div>
            </div>
          </div>
        </div>

        <Modal backdrop="static" show={isOpen} onHide={onClose} centered>
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex flex-column align-items-center gap-4 py-5">
              <img src={book_successfull} alt="image" />
              <Text
                text="Interview Scheduled Successfully"
                type="h2"
                style={{
                  fontSize: "18px",
                  fontWeight: 600,
                  marginTop: "7px",
                }}
              />
              <Text
                text="Your interview has been scheduled successfully. Check your email for meeting information."
                style={{
                  fontSize: "16px",
                  fontWeight: 400,
                  color: "#9398A1",
                  marginTop: "5px",
                }}
              />
              {/* <Button
                buttonType="primary"
                text="Back to home"
                onClick={backToHome}
              ></Button> */}
            </div>
          </Modal.Body>
        </Modal>
      </MaxWidthContainer>
    </>
  );
};

export default CandidateScheduleAppointment;
