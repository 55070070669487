export function removeNavShadow() {
    const nav = document.getElementById('navbar-light');
    nav.style.boxShadow = '0px 0px 0px 0px';
}

export function addNavShadow() {
    const nav = document.getElementById('navbar-light');
    nav.style.boxShadow = '0px 1px 5px var(--black)';
}

export function scrollToElement(id, block = 'center') {
    const container = document.getElementById(id);
    container.scrollIntoView({
        block: block,
        behavior: 'smooth'
    });
};