import React, { useEffect, useState } from "react";
import * as ApiHelper from "@/services/apiHelper";
import { useNavigate } from "react-router-dom";
import BecomePro from "./BecomePro";
import BecomeProEvaluated from "./BecomeProEvaluated";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: var(--green);
  left: calc(50% - 75px);
  position: fixed;
`;

const BecomeProMain = () => {
  let navigate = useNavigate();
  const [becomePro, setBecomePro] = useState({});
  const [isActive, setIsActive] = useState(false);
  const [refresh, setRefresh] = useState(false);
  let [loading, setLoading] = useState(false);
  let [color] = useState("#000000");

  const becomeProRedirection = async () => {
    setLoading(true);
    await ApiHelper.checkCandidateProStatus().then((response) => {
      setLoading(false);
      if (
        response?.data?.invite === null ||
        response?.data?.invite?.status === "requested"
      ) {
        setIsActive(true);
        setBecomePro(response?.data);
      }
    });
  };

  const userCanAddAppointment = async () => {
    setLoading(true);
    await ApiHelper.canUserBookAppointment().then((response) => {
      setLoading(false);
      if (!response?.data?.can_add) {
        navigate('/my-account?tab=interview');
      }
    });
  };

  useEffect(() => {
    becomeProRedirection();
    userCanAddAppointment();
  }, [refresh]);
  return (
    <>
      {loading ?
        <div className='loader'>
          <ClipLoader color={color} loading={true} css={override} size={150} /> </div> : null}
      {isActive ? <BecomePro becomePro={becomePro} setRefresh={setRefresh} /> : <BecomeProEvaluated />}
    </>
  );
};

export default BecomeProMain;
