import React, { useState } from "react";
import "./Modal.scss";
import * as commonServices from "@/services/common";
import * as ApiHelper from "@/services/apiHelper";
import { css } from "@emotion/react";
import deleteAccount from "@/Assets/images/deleteAccount.png";
import { BUTTON_TEXT_OK } from "@/constants/stringConstants";
import * as toast from "@/wrapper/toast";
import AppModal from "./AppModal";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: var(--green);
  left: calc(50% - 75px);
  position: fixed;
`;

function ModalDeleteAccount({
  showModal,
  setShowModal,
  showModalError,
  setShowModalError,
  modalErrorData,
  setModalErrorData,
}) {
  let [loading, setLoading] = useState(false);
  let [color] = useState("#000000");

  const deleteMyAccount = () => {
    setLoading(true);
    ApiHelper.deleteAccount().then((response) => {
      setLoading(false);

      if (response.isSuccess === true) {
        toast.success(response.message);
      } else {
        if (response.code === 201) {
          setShowModalError(!showModalError);
          setModalErrorData({
            ...modalErrorData,
            heading: "Delete Account Request",
            paragraph: "Please contact to TT Admin to delete your account!",
            buttonText: BUTTON_TEXT_OK,
            showContactDetails: true,
          });
        } else if (response.code === 203) {
          toast.success(response.message);
          commonServices.clearLocalStorageData();
          window.location.href = "/login";
        } else {
          toast.warn(response.message);
        }
      }
      setShowModal(!showModal);
    });
  };

  return (
    <>
      <AppModal
        title={"Delete Account?"}
        isOpen={showModal}
        closeModal={() => {
          setShowModal((prev) => !prev);
        }}
        submit={deleteMyAccount}
        isFooter={true}
        submitText={"Delete"}
        size={"md"}
      >
        <div className="modal_body">
          <div className="info-content">
            <p>
              If you confirm, you will be automatically logged out. To restore
              your account, simply log in and click on the "Restore" option
              within a 30-day period.
            </p>
          </div>
        </div>
      </AppModal>
    </>
  );
}

export default ModalDeleteAccount;
