import React from "react";
import { useNavigate } from "react-router";
import { MaxWidthContainer } from "@Components/Common/MaxWidthContainer";

import Text from "../_widgets/Text/Text";
import Button from "../_widgets/Button/Button";
import left_arrow from "@/Assets/images/arrow-left.svg";
import become_pro_evaluated from "@/Assets/svgs/become_pro_evaluated.svg";
import { redirectBack } from "@/utils/redirectUtil";
import "./BecomeProEvaluated.scss";

const BecomeProEvaluated = ({ isShowTitle }) => {
  const navigate = useNavigate();

  const scheduleAppointment = () => {
    navigate("/candidate-appointment");
  };

  return (
    <>
      <MaxWidthContainer className="get-evaluated_page tt-container">
        <div>
          {isShowTitle === undefined || isShowTitle ? (
            <div className="d-flex gap-3 align-items-center">
              <img
                src={left_arrow}
                alt=""
                className="hand-hover"
                onClick={() => redirectBack()}
              />
              <Text
                text={"Get Evaluated"}
                type={"h5"}
                style={{ fontSize: "var(--h5)" }}
              />
            </div>
          ) : null}
          <div
            className="d-flex flex-column align-items-center"
            flexDirection={"column"}
            alignItems={"center"}
          >
            {isShowTitle === undefined || isShowTitle ? (
              <div>
                <img src={become_pro_evaluated} alt="image" />
              </div>
            ) : null}
            <Text
              text="Congratulations!!"
              style={{
                fontSize: "var(--h5)",
                fontWeight: 600,
                marginTop: "7px",
              }}
            />
            <Text
              text="You have received an invite to get evaluated."
              style={{
                fontSize: "var(--h5)",
                fontWeight: 600,
                marginTop: "7px",
              }}
            />
            <Text
              text="Book an appointment now and get better opportunities of landing a job."
              style={{
                fontSize: "16px",
                fontWeight: 400,
                color: "#9398A1",
                marginTop: "7px",
              }}
            />
          </div>
        </div>
        {isShowTitle === undefined || isShowTitle ? (
          <div
            style={{
              margin: "2rem 0",
              backgroundColor: "#F9F9F9",
              padding: "12px",
            }}
          >
            <Text
              text="Why you should get evaluated?"
              style={{
                fontSize: "1rem",
                fontWeight: 600,
                marginBottom: "14px",
              }}
            />
            <Text
              text="Do you often find yourself engaging in multiple interview cycles, experiencing rejections, and receiving inadequate/constructive feedback from the interviewers? Undergo video assessment on TrueTalent conducted by an industry specialist. Gain valuable insights into your current strengths and growth areas through detailed feedback. Sidestep the initial rounds of interviews across multiple companies and instead, secure direct interviews with the decision-makers within these firms"
              style={{
                fontSize: "16px",
                fontWeight: 400,
                color: "#9398A1",
                marginTop: "5px",
              }}
            />
          </div>
        ) : null}

        <div style={{ marginBottom: "50px" }}>
          <div
            className="d-flex flex-column align-items-center"
            flexDirection={"column"}
            alignItems={"center"}
          >
            <Button
              style={{ padding: "0.8rem 2rem", fontSize: "var(--p2)" }}
              buttonType="primary"
              text="Book Appointment"
              onClick={() => scheduleAppointment()}
            ></Button>
          </div>
        </div>
      </MaxWidthContainer>
    </>
  );
};

export default BecomeProEvaluated;
