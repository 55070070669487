import React from 'react'
import './Terms.scss'
import { MaxWidthContainer } from "@Components/Common/MaxWidthContainer";
import Text from "../_widgets/Text/Text";

function Terms() {
  return (
    <MaxWidthContainer pageKey="terms" >
      <div className='terms_page main-body' style={{
        textAlign: "left"
      }}>
        <div className='body tt-container'>
          <div className="post_sec">
            <div className="post_full">
              <Text type='h2' text='Terms of Use & Privacy Policy' /> <br />
              <TermsContent />
            </div>
          </div>
        </div>
      </div>
    </MaxWidthContainer >
  )
}

export const TermsContent = () => {
  return <div className='terms_page tt-container' style={{
    textAlign: "left",
    paddingTop: 0,
    marginTop: 0
  }}>
    <div className=''>
      <div className="">
        <Text type='h7' text='TrueTalent.io and its parent company, FindrPro Technology Solutions Private Limited (&quot;FindrPro&quot;), are dedicated to protecting the privacy of our users while delivering customized career management services online and offline. This TrueTalent.io Privacy Statement covers our policies for the collection, use, and sharing of your information in connection with our parent company, FindrPro, and its affiliates operating on our behalf (collectively known as, &quot;TrueTalent&quot;).' />
        <p><br /></p>

        <Text type='h7' text='Note: All references to &quot;we&quot;, &quot;us&quot;, &quot;our website&quot;, or TrueTalent relate to the website www.TrueTalent.io (the &quot;Website&quot; or &quot;Sites&quot;). TrueTalent provides links to the websites of several other businesses (clients &amp;partners) to whom this policy does not apply.' />
        <p><br /></p>

        <Text type='h7' text='TrueTalent is dedicated to protecting the privacy of your Personally Identifiable Information (&quot;PII&quot;). Your loyalty as a job seeker, client, or visitor is important to us, and we trust that our concerns for your privacy, as outlined in this policy (the &quot;Privacy Policy&quot; or &quot;Policy&quot;), indicate our dedication to making your experience with TrueTalent rewarding.' />
        <p><br /></p>

        <Text type='h7' text='This Privacy Statement regulates your interactions with the Sites and your use of our online services (collectively, the &quot;Services&quot;). We do not disclose personal information about our users and visitors without their consent, except as specified in our Privacy Policy, our Terms of Service Agreement, and other published guidelines. This Privacy Statement only applies to the information collected by the websites and mobile apps that provide a link to this Privacy Statement. Consequently, we urge you to check the whole Privacy Statement. Please choose one of the links below to examine the corresponding section.' />
        <p><br /></p>

        <Text type='h7' text='1. The information we acquire' />
        <Text type='h7' text='2. How we use the data we collect' />
        <Text type='h7' text='3. How we share information we gather' />
        <Text type='h7' text='4. Tracking Technologies' />
        <Text type='h7' text='5. Security' />
        <Text type='h7' text='6. Refunds' />
        <Text type='h7' text='7. Surveys & Contests' />
        <Text type='h7' text='8. Referrals' />
        <Text type='h7' text='9. Social Media Features' />
        <Text type='h7' text='10. Data Subject Rights' />
        <Text type='h7' text='11. Alterations to Services' />
        <Text type='h7' text='12. Changes to this Privacy Policy' />
        <Text type='h7' text='13. Indemnity and Release' />
        <Text type='h7' text='14. Copyright' />
        <Text type='h7' text='15. Questions' />
        <p><br /></p>

        <Text type='h5' text="1. The information we acquire" />
        <p><br /></p>

        <Text type='h5' text="We Collect Information About You:" />
        <Text type='h7' text='In certain areas of the Sites, such as those in which you complete a profile, submit your resume, create a new account, or enter TrueTalent-sponsored contests or promotions, we may request certain personal information from you, including your email address, first and last name, postal code, phone number, profession, work experience, social media information, photographs, ethnicity, gender, citizenship, and work eligibility. In such cases, we may attempt to determine which informative pieces are needed and which are optional.' />
        <p><br /></p>

        <Text type='h5' text="Personal Information We Collect Passively:" />
        <Text type='h7' text='We passively collect and aggregate information revealing, among other things, which pages were viewed, in what sequence, and which links were &quot;clicked&quot; when visitors utilise the Services. Collecting this information requires the collection and logging of IP addresses, MAC addresses, operating systems, device types, mobile ad identifiers, browsing history, search history, and information regarding an individual&apos;s interaction with an internet website, application, or advertisement, as well as the Internet Service Provider (ISP) and browser software utilised by each user of the Services. In some situations, we may gather geolocation-based information to assist in identifying available opportunities in your region.' />
        <Text type='h7' text='The information we gather enables us to assess the most popular parts of the Sites and the efficacy of our marketing campaigns. In addition, we may share some of this information with advertisers in order to tell them of the number of persons who have watched and/or clicked on their ads.' />
        <p><br /></p>
        <Text type='h7' text='Children &amp; Minors - TrueTalent&apos;s offerings are unavailable to anybody under the age of 18. We do not intentionally gather personal information from minors less than 18 years old.' />
        <p><br /></p>
        <Text type='h7' text='Please be aware that we cannot regulate the actions of our Service users. All members and visitors should be aware that when personal information is disclosed in a resume, profile, or other media, it may be collected and utilised by third parties to send unwanted email from parties outside TrueTalent. Please email <a href="mailto:maya@TrueTalent.io">privacy@TrueTalent.io</a> if you come across a TrueTalent user who is inappropriately collecting or utilising information about you or other users.' />
        <p><br /></p>

        <Text type='h5' text="Profile Enhancement" />
        <Text type='h7' text='FindrPro may acquire indexed material, including information about you, from a range of publically accessible websites in order to deliver services to our clients. Personal information, such as blog postings, may be included in indexed material. In general, such indexed material is accessible through ordinary search engines or by visiting public websites directly. We integrate this information with what we already know about you. This assists us in updating, expanding, and analysing our data, identifying new consumers, and providing goods and services that may be of interest to you. Whether or not a person has an account with us, FindrPro may add this information to your profile or use this material to establish a profile. If you want to have the information we gather removed, please email us at <a href="mailto:maya@TrueTalent.io">privacy@TrueTalent.io</a> . FindrPro does not control the indexed material collected from third-party sites, and we cannot promise that we will not gather information from a publicly accessible source in the future.' />

        <Text type='h7' text='Job and industry information, such as skills, title, experience, qualifications, and so on, and any other publicly available information, such as social profiles, are examples of the types of personal information that may be obtained from public sources or purchased from third parties and combined with information we already have about you.' />
        <p><br /></p>

        <Text type='h5' text="2.&nbsp;What we do with the information we collect" />
        <p><br /></p>
        <Text type='h7' text='Please take some time to get acquainted with the many ways we utilise the information we collect. TrueTalent urges all third parties engaged, including but not limited to its advertising, to follow our rules on visitor and user privacy and to otherwise handle personal information responsibly. However, if you come across any third party linked with, or claiming to be associated with, TrueTalent and believe they are inappropriately collecting or exploiting information about you, please email <a href="mailto:maya@TrueTalent.io">privacy@TrueTalent.io</a> .' />
        <p><br /></p>
        <Text type='h7' text='We have established and are maintaining a system for transfers of information beyond the country of collection that is compatible with relevant laws in India and this policy. We may also need to transmit your information in order to provide you with services in compliance with our current agreements with you, such as the Terms of Service.' />
        <Text type='h7' text='TrueTalent processes or uses your personal data as mentioned above based on legitimate interest. If you disagree with this method, you may file an objection to TrueTalent&apos;s processing or use of your personal data as outlined below.' />
        <p><br /></p>
        <Text type='h7' text='We use passively collected information in aggregate form to build higher quality, more useful Services by performing statistical analyses of the collective characteristics and behaviour of our visitors and users, as well as measuring demographics and interests regarding specific areas of the Services. We may give advertisers, users, and other current and future business partners with statistical statistics based on this data. We may also utilise aggregate data to notify these third parties of the number of individuals who have viewed and clicked on links to their websites.' />
        <p><br /></p>
        <Text type='h7' text='How we utilise personal information:&nbsp;The personal information a user contributes may be used for the following purposes:' />
        <Text type='h7' text='1. To comprehend a user&apos;s requirements and develop information that is relevant to the user;' />
        <Text type='h7' text='2. To do statistical research;' />
        <Text type='h7' text='3. Conduct market research and planning via the distribution of user surveys;' />
        <Text type='h7' text='4. To notify TrueTalent services, information, or product referrals when a user asks that TrueTalent transmit such information to referrals;' />
        <Text type='h7' text='5. Enhancing services, information, and goods;' />
        <Text type='h7' text='6. To assist a user in completing a transaction, or to offer services or customer support to TrueTalent product users and receivers;' />
        <Text type='h7' text='7. Communicate with users and recipients of TrueTalent goods and services.' />
        <Text type='h7' text='8. To send users emails regarding services, information, goods, and offers from our business partners (as described in section C, How we share information we collect);' />
        <Text type='h7' text='9. To tailor the Sites to the user;' />
        <Text type='h7' text='10. To alert the user of any changes to the Sites that may have an impact on the user;' />
        <Text type='h7' text='11. Enforcing the Sites&apos; terms of service;' />
        <Text type='h7' text='12. To enable the user to buy things, access services, or otherwise participate in activities that the user chooses;' />
        <Text type='h7' text='13. Permitting us to contact you by phone, email, and/or social media. Text messages or notifications may be included in certain situations, and carrier costs may apply.' />
        <Text type='h7' text='14. To support, customise, and develop our website, apps, and product or service offerings;' />
        <Text type='h7' text='15. To set up, manage, modify, service, and protect your account with us.' />
        <Text type='h7' text='16. To audit our contacts with you, such as by counting impressions or confirming the quality and efficacy of material, including advertisements;' />
        <Text type='h7' text='17. Participating in any prosecution or enforcement of laws or agreements aimed at preventing or punishing harmful, misleading, fraudulent, or unlawful activities;' />
        <Text type='h7' text='18. To ensure the safety, security, and integrity of our website, apps, goods and services, databases, and other technological assets, as well as our company, including the identification of security problems.' />
        <Text type='h7' text='19. To debug, discover, and correct faults, as well as to implement equivalent functional upgrades;' />
        <Text type='h7' text='20. Create, enhance, and distribute marketing and advertising;' />
        <Text type='h7' text='21. Internal applications such as research, analytics, development, audits, account management, and security;' />
        <Text type='h7' text='22. For legal and operational compliance purposes, such as ensuring that our operations are effectively implementing this policy;&nbsp;' />
        <Text type='h7' text='23. To engage in or enable internal uses consistent with our relationship with you or compatible with the context in which you provided the information, such as internal research for technology development; and&nbsp;' />
        <Text type='h7' text='24. For any other purpose described to you before or at the time of collection.' />
        <p><br /></p>
        <Text type='h7' text='Forums, Blogs, and Survey Results' />
        <Text type='h7' text='User names, identifications (&quot;IDs&quot;), and email addresses (along with any additional information a user may choose to post) may be publicly accessible on the Sites when a user voluntarily and publicly discloses personal information, such as when a user uses features to send a message to another user, or as part of a message posted to a public forum or publicly-released software application. Once posted, users may not be able to edit or delete public messages. Your information may be read, collected, and used by anyone who access these places. To have your personal information removed from these pages, please contact us at <a href="mailto:privacy@TrueTalent.io">privacy@TrueTalent.io</a>. In rare situations, we may not be able to erase your personal information; if this is the case, we will let you know why.' />
        <Text type='h7' text='In the case that survey answers are made public, individuals will be told at the time they participate in the survey. TrueTalent may, but is not limited to, sharing survey results with Site users, providing advertisers with information on user preferences and/or demographics, and disclosing aggregate use statistics in press communications. Where surveys enable users to provide written comments and where TrueTalent informs users of the potential of such disclosure at the time they complete the survey, TrueTalent maintains the right to reveal any information submitted by users, so long as no personally identifiable information is exposed. Participation in surveys is voluntary; TrueTalent does not administer obligatory questionnaires.' />
        <p><br /></p>
        <p><br /></p>

        <Text type='h5' text="3. How we share information we gather" />
        <p><br /></p>

        <Text type='h5' text="Disclosure to Service Providers" />
        <Text type='h7' text='TrueTalent utilises your personal information in a variety of ways, subject to the restrictions stated above. TrueTalent may share your personal information with service partners that assist us with business-related tasks, such as payment card processing, email and social network marketing, customer care, and forum administration. These service providers are only permitted to use your personal data to the extent required to offer these services to us.' />
        <br />

        <Text type='h5' text="Offering to Our Clients" />
        <Text type='h7' text='Regarding job applications, TrueTalent will not reveal your profile or resume on our Sites to potential employers without your approval or unless instructed to do so by you.' />
        <Text type='h7' text='Applicants should only upload their profiles and resumes on our websites if they want such material to be accessed by our clients. Job seekers may choose for their profile and resume not to be searchable by our clients at any time by going into their account and altering the status of their profile, or by contacting us at <a href="mailto:privacy@TrueTalent.io">privacy@TrueTalent.io</a> and we will make the adjustment for them.' />
        <Text type='h7' text='Sharing with Third Parties and Preferences Regarding Marketing' />
        <Text type='h7' text='If you &quot;opt in&quot; to receive promotional materials from third parties, the information you submit to us may be shared with third parties whose products or services we feel might be of interest to you. You may &quot;out out&quot; of receiving such communications at any time by utilising the &quot;opt-out&quot; link contained in each email, modifying your subscription options on the &quot;user settings&quot; page of your TrueTalent account, or emailing us at privacy@TrueTalent.io. If you have submitted more than one email address to our service, you may continue to receive communications until you unsubscribe each email address individually. To opt out of receiving information directly from third parties, you must contact those third parties directly.' />
        <Text type='h7' text='In order to effectively tailor our services, these Sites may share your personal information with third party consultants and business partners, including, but not limited to, other lead generation companies (collectively &quot;Third Party Contractors&quot;) in order to collect, compile, organise, and market to you services that are related. When you submit any personal information on this Site, we shall request your permission to share with Third Party Contractors any and all personal information required to allow expanded service offerings. Contact us at <a href="mailto:privacy@TrueTalent.io">privacy@TrueTalent.io</a> if you do not want us to share your information with these firms.' />
        <br />

        <Text type='h5' text="Sharing for Other Purposes" />
        <Text type='h7' text='Please note that TrueTalent reserves the right to disclose information submitted by or concerning any visitor or user as we reasonably feel is necessary to protect our systems or business. We may also disclose your personal information as required by law, such as to comply with a legal process, when we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or respond to a government request; for example in response to a court order or in response to a law enforcement or government agency&apos;s request. We also may disclose such information to third parties: (i) in connection with prevention of fraud, deception, or other malicious activities, (ii) where we believe it is necessary to investigate, prevent, or take action regarding illegal activities, (iii) in situations that may involve violations of our terms of use or other rules, (iv) to protect our rights and the rights and safety of others, and (v) as otherwise required by law. We cooperate with law enforcement agencies in identifying those who may be using our servers or Services for illegal activities. We also reserve the right to report any suspected illegal activity to law enforcement for investigation or prosecution, or to suspend or terminate your use of the Services in connection with any suspected illegal or infringing activity or if you are in violation of our Terms of Use Agreement or other published guidelines.' />

        <Text type='h7' text='If TrueTalent is involved in a merger, acquisition, or sale of all or a portion of its assets, you will be notified via email and/or a prominent notice on the Site of any change in ownership or uses of your personal information, as well as any choices you may have regarding your personal information. TrueTalent reserves the right to assign, sell, license, or otherwise transfer to a third party your personal information in connection with an assignment, sale, joint venture, or other transfer or disposition of any portion or all of TrueTalent&apos;s or its affiliated entities&apos; assets or stock.</p>
        <>We may disclose personal information to third parties to whom you or your authorized representatives direct us to disclose your personal information. &nbsp;We may disclose personal information to other parties identified by you when collecting your personal information before or at the time of collection, or to which you separately consented.' />
        <br />

        <Text type='h5' text="Co-Branding and Framing" />
        <Text type='h7' text='The Sites may use framing and masking methods to make it seem as if the user is still on the Site while, in fact, the user is visiting a third-party website. Moreover, we have co-branding ties with a number of our partners. In these partnerships, you will find our service platform on the website of our partner. In this case, both parties gather your data, and both parties&apos; privacy rules control the data collection. We will not disclose your information with third parties in this and other connections without your permission. When you leave our Sites, we urge you to read the privacy policies of any website that collects personally identifiable information.' />
        <Text type='h7' text='You may request further details about our disclosures as outlined below.' />
        <p><br /></p>

        <Text type='h5' text="4. Tracking Technologies" />
        <p><br /></p>
        <Text type='h7' text='TrueTalent and its partners, analytics, and service providers use technologies such cookies, beacons (including pixels), and javascripts. These technologies are often used for assessing trends, managing the site, monitoring users&apos; movements throughout the site, and accumulating demographic data about our user base as a whole.' />
        <Text type='h7' text='Cookies' />
        <Text type='h7' text='On the Sites, cookies are used. A &quot;browser&quot; or &quot;HTTP&quot; cookie is a unique text file that may be used for data analysis and allows a website to personalise the content shown to a user depending on the user&apos;s browsing behaviour. TrueTalent may use browser cookies to customise a user&apos;s pages on our Sites, remember a user when he or she registers for goods or services, prevent fraud, or monitor visits to our Sites. If a user does not want TrueTalent or its partners to deploy browser cookies in the user&apos;s browser when the user visits the Sites, the user may configure the browser to refuse cookies or to alert the user when a website attempts to install a cookie in the browser programme. A user&apos;s ability to access certain goods, features, functionalities, or services on our Sites may be hindered if cookies are rejected.' />
        <Text type='h7' text='Pixels' />
        <Text type='h7' text='We employ GIF files with transparent pixels to control web advertising. These pixels are supplied by our advertising partners. These files allow our ad management partner to detect a unique cookie on your web browser, which helps us to determine which adverts attract people to our websites. This information does not include your name, address, phone number, or email address.' />
        <p><br /></p>
        <Text type='h7' text='Internet beacons' />
        <Text type='h7' text='We use web beacons on occasion. A web beacon is a string of code that delivers a visual image on a web page or in an email message for the purpose of sending data or counting the number of times a given web page has been visited. Web beacons allow TrueTalent to identify a browser&apos;s cookie when a browser visits a Site and to determine which banner advertisements attract visitors to each Site. For instance, when a user views a web page, the website&apos;s code may include instructions to get a single-pixel graphic picture from another server. Web beacons are not installed on the computer of a user. Such web beacons may be delivered by TrueTalent&apos;s advertising partners in order to manage and optimise online advertising.' />
        <Text type='h7' text='Log files' />
        <Text type='h7' text='TrueTalent web servers may automatically record aggregate information such as a user&apos;s IP address, domain name, browser type, access date and time, and other log file data.' />
        <p><br /></p>
        <Text type='h7' text='Advertisements' />
        <Text type='h7' text='Advertisements on the Sites may be shown to visitors by third parties. TrueTalent&apos;s use of third-party advertising technology on the Sites use data collected from a user&apos;s visits to the Sites to target advertising within the Sites. In addition, TrueTalent&apos;s advertisers may use other third-party advertising technologies to target advertisements on the Sites. In order to serve adverts on the Sites, our partners may set or identify a unique cookie on a user&apos;s browser. Visitors are shown advertisements based on information about their visits to the Sites, such as the number of times they have seen a certain ad (but not their names, addresses, or other personal information). Utilizing cookies allows our partners to deliver advertisements to users based on their visits to the Sites and other sites on the Internet. As with other cookies, and in accordance with TrueTalent&apos;s stated policy on cookies, the user may block or remove these cookies from their hard drive or memory.' />
        <p><br /></p>
        <Text type='h7' text='Advertising Selections' />
        <Text type='h7' text='We collaborate with third party service providers to display advertisements on our website. Our third-party partners may use cookies or similar technology to tailor ads to your browsing habits and interests. If you would prefer not to get customised adverts based on your browser or device use, you may typically opt out of receiving personalised advertisements. Please note that adverts will continue to appear, but will no longer be customised to your interests.' />
        <p><br /></p>
        <Text type='h7' text='Links to external websites' />
        <Text type='h7' text='Links to third-party websites or widgets that facilitate the sharing of content via social networks are given exclusively for the user&apos;s convenience. When a user clicks on one of these links, they leave the Sites. TrueTalent has not evaluated and is not responsible for the content or privacy policies of any of these third-party sites. TrueTalent does not support or make any claims regarding the information, services, or products available on third-party websites. If a user chooses to visit one of the connected websites, TrueTalent recommends that they review their privacy policies. Such sites are accessed at the user&apos;s own risk.' />
        <p><br /></p>

        <Text type='h5' text="5. Security" />
        <p><br /></p>
        <Text type='h7' text='We value the protection of your personal information. When you submit sensitive data (such as a credit card number) on our purchase forms, we encrypt its transfer.' />
        <Text type='h7' text='We adhere to widely established standards for the transfer and storage of the personal information you provide to us. However, no form of Internet transmission or computer storage is completely safe. We cannot thus guarantee that information will not be accessed, disclosed, changed, or deleted if any of our physical, technological, or administrative measures are breached. You are responsible for protecting the security of your login credentials. Please help maintain the security of your account by choosing a strong password.' />
        <Text type='h7' text='Contact us at <a href="mailto:security@truetalent.io">security@TrueTalent.io</a> if you have any queries about the security of our website.' />
        <p><br /></p>

        <Text type='h5' text="6. Refunds" />
        <p><br /></p>
        <Text type='h7' text='We at FindrPro, strive to provide our customers with the best possible experience using our platform. If for any reason the original purchase fails, we offer a refund policy subject to the following conditions:' />
        <ul>
          <li><Text type='h7' text='Refund requests must be made within 14 days of the initial transaction date' /></li>
          <li><Text type='h7' text='Refunds will be processed within 14 business days of the request' /></li>
          <li><Text type='h7' text='The transaction must not have been disputed or reversed' /></li>
          <li><Text type='h7' text='The transaction must not have been subject to any fraudulent activity or unauthorized use' /></li>
          <li><Text type='h7' text='The refund will be issued to the same payment method used for the original purchase' /></li>
          <li><Text type='h7' text='If a promotional discount was applied to the original purchase, the refunded amount will be adjusted accordingly' /></li>
          <li><Text type='h7' text='Refunds are not available for subscription-based, pay-as-you-use services. Purchase balance remains with the customer wallet with in the platform, without any expiry.' /></li>
        </ul>
        <Text type='h7' text='To request a refund, please reach out to your customer success executive. Alternately, please drop us an email at <a href="mailto:Maya@TrueTalent.io">Maya@TrueTalent.io</a>. Our team will be happy to assist you with your request.' />
        <Text type='h7' text='Please note that our refund policy is subject to change at any time without notice. We encourage you to review this policy periodically to ensure that you are aware of any updates or changes.' />
        <p><br /></p>

        <Text type='h5' text="7. Surveys" />
        <p><br /></p>
        <Text type='h7' text='TrueTalent may conduct surveys on occasion to improve the delivery of adverts and content, as well as to better understand your requirements about the Services. Except in aggregate form, we will not share your survey information with any other parties.' />
        <Text type='h7' text='On occasion, we may offer you the chance to participate in competitions or surveys on our website. We will seek specific personal information from you if you want to join. You have the option of whether or not to provide this information since participation in these surveys or competitions is fully optional. Typically, the required information comprises contact data (such as name and shipping address) and demographic data (such as pin code). Insofar as they contradict with this policy, the sweepstakes rules shall govern that specific promotion or sweepstakes; please read them carefully. We may use this information to notify contest winners and offer prizes, to monitor the usage of the Sites, to customise the Sites, to send participants an email, and to gather demographic, interest, and opinion information from participants. We may hire a third-party service provider to conduct these surveys or competitions; this firm is forbidden from utilising the personal information of our users for any other reason. Unless we provide you with prior warning and option, we will not disclose the personal information you submit via a contest or survey with any third parties.' />
        <p><br /></p>

        <Text type='h5' text="8. Referrals" />
        <p><br /></p>
        <Text type='h7' text="If you want to utilise our referral service to inform a friend about a job opening, we will need that friend's email address. This recipient will automatically get a one-time email asking them to visit the site. We only use this information to deliver this one-time email, and we do not store these email addresses until they click the referral mail and register themselves on our site." />
        <p><br /></p>

        <Text type='h5' text="9. Social Media Features" />
        <p><br /></p>
        <Text type='h7' text="There are social media elements on the Sites, such as the Facebook like button and widgets that run on the Sites. These services may gather your IP address, the URL of the page you are viewing on our websites, and may establish a cookie to allow appropriate operation. Widgets and features related to social media are either hosted by a third party or directly on our website. Your interactions with these features are controlled by the provider's privacy practices." />
        <p><br /></p>

        <Text type='h5' text="10. Data Subject Rights" />
        <p><br /></p>
        <Text type='h7' text='TrueTalent may provide you with information on the personal data it processes about you at any moment, as well as the correction or deletion of such data. If your personally identifiable information changes, or if you no longer desire our service, you may correct, update, amend, delete/remove, or deactivate it by making the change on your account page or by contacting our Customer Support at <a href="mailto:privacy@truetalent.io">privacy@truetalent.io</a> or by telephone or postal mail at the contact information listed below.' />
        <Text type='h7' text='We may only be able to do some of these acts for you, depending on the regulations that apply to your personal information. If you request a certain action and we decline to do it, we will explain your legal rights, the basis for our rejection, and any available remedy. Note that if you request TrueTalent to remove your personal information, you will no longer be able to utilise any TrueTalent service that involves the use of your personal information. If you have submitted more than one email address to our service, you may continue to receive communications until you request to be removed from each email address.' />
        <Text type='h7' text="You can request that we restrict your personal data from any further processing in any of the following events: (i) you state that the personal data TrueTalent has about you is incorrect, (but only for as long as TrueTalent is required to check the accuracy of the relevant personal data), (ii) there is no legal basis for TrueTalent to process your personal data and you demand that TrueTalent restricts your personal data from further processing, (iii) TrueTalent no longer requires your personal data but you claim that you require TrueTalent to retain such data in order to claim or exercise legal rights or to defend against third party claims or (iv) if you object to the processing of your personal data by TrueTalent (based on TrueTalent's legitimate interest as further set out in Section A above), which may be required when determining if TrueTalent has a prevailing interest or legal obligation in processing your personal data." />
        <Text type='h7' text='We will respond to your request without undue delay within 30 days. TrueTalent reserves the right to take reasonable steps to verify customer identity prior to granting access or processing changes or corrections. Please direct any such request to <a href="mailto:privacy@truetalent.io">privacy@TrueTalent.io</a> Storage of Your Private Information We will store your data for as long as your account is active or as required to deliver you services. Contact us at privacy@truetalent.io if you desire to deactivate your account or request that we no longer use your information to give you Services. We will preserve and utilise your information as required by law, to settle disputes, and to enforce our agreements. Blogs: The Sites may provide blogs or community forums with public access. You should be aware that any information you provide in these places might be viewed, collected, and used by other individuals who get access to them. Contact us at privacy@truetalent.ion to request removal of your personal information from our blog or community forum. In rare situations, we may not be able to erase your personal information; if this is the case, we will let you know why.' />
        <Text type='h7' text='We publish testimonials on the Sites, which may include personally identifiable information. Prior to publishing a testimonial, we request permission through email or a release agreement to publish the name along with the testimonial. Contact us at <a href="mailto:privacy@truetalent.io">privacy@truetalent.io</a> if you would like to edit or remove your testimonial.' />
        <Text type='h7' text='You may sign up to receive emails and other correspondence from us. If you no longer want to receive this information, you may modify your email choices by clicking the "Unsubscribe" link in the emails we send you, modifying your account page, or emailing us at <a href="mailto:privacy@truetalent.io">privacy@truetalent.io</a>' />
        <p><br /></p>

        <Text type='h5' text="11. Alterations to Services" />
        <p><br /></p>
        <Text type='h7' text='TrueTalent retains the right to change or stop, temporarily or permanently, the service (or any portion thereof) at any time and without notice. You agree that the Company shall have no liability to you or any other person in the event that the service is modified, suspended, or discontinued.' />
        <p><br /></p>

        <Text type='h5' text="12. Changes to this Privacy Policy" />
        <p><br /></p>
        <Text type='h7' text='This policy may be revised to reflect changes in our information practises. If we make any substantial changes, we will post an announcement on the Site prior to the change taking effect. Please revisit this page occasionally for the most up-to-date information on our privacy policies.' />
        <Text type='h7' text='Note that by using the Services, you consent to this Privacy Statement. Please do not use the Services if you do not agree with this Policy. Your continuing use of the Services after modifications to this Policy will signify your acceptance of the changes; thus, please book mark this page and visit it regularly. If you have any concerns about this Privacy Statement or any other privacy-related matters relating to the Services, please contact us at <a href="mailto:privacy@truetalent.io">privacy@TrueTalent.io</a> ' />
        <p><br /></p>

        <Text type='h5' text="13. Indemnity and Release" />
        <p><br /></p>
        <Text type='h7' text="You agree to protect, defend, indemnify, and hold harmless the Company and the Website, as well as its affiliates, officers, agents, associates, or other partners and employees, from any claim or demand, including reasonable attorney's fees, made by any third party due to the content you submit, post, or transmit through the service, your use of the service, your connection to the service, or your violation of any rights of another user." />
        <p><br /></p>

        <Text type='h5' text="14. Copyright" />
        <p><br /></p>
        <Text type='h7' text="Unless otherwise specified, all content displayed on this website, including graphical pictures, belongs to the website. You are permitted to print or download service excerpts for personal use only. However, any copies of these pages saved to a disc or other storage device may only be viewed or printed for personal use. You may not (directly or through the use of any software programme) establish an electronic or structured manual database by regularly downloading and storing all or any portion of the information from the service. No portion of the service may be reproduced, transmitted, or stored in another website, nor may its information or any portion thereof be disseminated in any electronic or non-electronic form or incorporated into any public or private electronic retrieval system or service without the Company's prior written consent." />
        <p><br /></p>

        <Text type='h5' text='15. Questions?' />
        <p><br /></p>
        <Text type='h7' text='You can contact us at <a href="mailto:privacy@truetalent.io">privacy@truetalent.io</a> with any questions or concerns about this Privacy Statement. We are committed to examining and resolving concerns about the acquisition or use of your personal data. You may also reach us by phone on +91 9731 50 9731.' />
        <p><br /></p>
        <Text type='h7' text='To assist us efficiently resolve the problem, please give the following:' />
        <Text type='h7' text='The specific data privacy complaint (please provide as much detail as possible, including country, your understanding of the data privacy infringement and issues, and the requested remedy); Your full name and how we can contact you; and any prior correspondence on this particular data privacy issue.' />
        <p><br /></p>

      </div>
    </div>

  </div>
}

export default Terms;