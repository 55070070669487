import React from 'react'


import emptyResult from '@/Assets/svgs/empty_result.svg';

import * as commonServices from '@/services/common';
import Text from "../_widgets/Text/Text"


const NoDataView = ({ text, showIcon = true }) => {
    return (
        <div className='w-100 py-5' width="100%" py={6} >
            <div className='no_jobs'>
                {
                    showIcon ?
                        <img onError={commonServices.imgError} src={emptyResult}
                            alt="" /> : null
                }
                <Text
                    text={text}
                    style={{
                        fontSize: "xl",
                        fontWeight: 500,
                        marginTop: "4px",
                        color: "#9398A1",
                        textAlign: "center",
                    }}
                />
            </div>
        </div>
    )
}

export default NoDataView