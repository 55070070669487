
import React, { useState, useEffect } from 'react';
import './Modal.scss';
import * as commonServices from '@/services/common';
import AppModal from './AppModal';
import * as toast from "@/wrapper/toast";
import * as ApiHelper from "@/services/apiHelper";
import { PuffLoader } from 'react-spinners';
import CustomPagination from '@Components/Pagination/Pagination';
import { changeDateFormat } from '../../utils/dateUtil';
import { getSkillsString, getSubString } from '../../utils/stringUtils';
import Button from '../_widgets/Button/Button';

function PartnerCandidates({ showModal, setShowModal, remaining_views }) {

    const [data, setData] = useState([]);
    const [loader, setLoader] = useState(false);
    const [activePage, setActivePage] = useState(1);
    const handlePaginationChange = (e, { activePage }) => {

        window.scrollTo(0, 0);
        showCandidates(activePage);
        setActivePage(activePage);
    };

    useEffect(() => {
        if (showModal) {
            showCandidates();
        }
    }, [showModal])

    const showCandidates = async (activePage = 1) => {
        setLoader(true);
        let params = "?pageNumber=" + activePage;
        await ApiHelper.getPartnerCandidates(params).then((response) => {
            setLoader(false);
            if (response.isSuccess === true) {
                setData(response?.data);
                setActivePage(response?.data?.current_page);
            } else {
                toast.warn(response.message);
            }
        });
    }

    const download = async () => {
        setLoader(true);
        let params = "?type=partner-candidate";
        await ApiHelper.downloadReport(params).then((response) => {
            setLoader(false);
            if (response.isSuccess === true) {
                if (response.data !== null) {
                    let a = document.createElement('a');
                    a.href = response.data.file_url;
                    a.click();
                } else {
                    toast.success(response.message);
                }
            } else {
                toast.warn(response.message);
            }
        });
    }

    return (

        <AppModal
            isOpen={showModal}
            closeModal={() => setShowModal(prev => !prev)}
            title={`View Candidates (${data?.total ? data?.total : 0})`}
            size={"xl"}
            isFooter={false}

        >

            <div className="modal_body partner-candidates">
                {loader ?
                    <>
                        <div className='loader'>
                            <PuffLoader
                                color="#14BC9A"
                                loading={loader}
                                size={50}
                                height={50}
                                width={50}
                                radios={50}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                            />
                        </div>
                    </>
                    :
                    <>

                        <div className="t_details common_items">
                            <div className='row' style={{ float: "right", marginRight: "10px" }}>
                                <Button
                                    buttonType='primary'
                                    text='Export'
                                    onClick={() => {
                                        if (remaining_views > 0) download()
                                        else toast.warn("This feature is available only for Pro Users")
                                    }}
                                />
                            </div>
                            {data?.data && data?.data.length > 0 ?
                                <>
                                    <table className="table">

                                        <thead>
                                            <tr>

                                                <th scope="col">Name</th>
                                                <th scope="col">Email</th>
                                                <th scope="col">Skills</th>
                                                <th scope="col">Jobs Applied</th>
                                                <th scope="col">Gigs Applied</th>
                                                <th scope="col">Registered On</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data?.data && (data?.data.map((user, i) => {
                                                return <tr className="results">
                                                    <td title={user.full_name}>{getSubString(user.full_name, 0, 20)}</td>
                                                    <td>{user.email}</td>
                                                    <td title={getSkillsString(user.skills)}>{getSubString(getSkillsString(user.skills), 0, 20)}</td>
                                                    <td>{user.applied_jobs_count}</td>
                                                    <td>{user.applied_gigs_count}</td>
                                                    <td>{changeDateFormat(user.created_at)}</td>
                                                </tr>
                                            }

                                            ))}
                                        </tbody>
                                    </table>
                                    < div className='my-3'>
                                        <CustomPagination
                                            activePage={activePage}
                                            currentPage={setActivePage}
                                            onPageChange={handlePaginationChange}
                                            totalData={data?.total}
                                            pageLength={data?.per_page}
                                            lastPage={data?.last_page}
                                            tag="pages"
                                            dataFrom={data.from}
                                            dataTo={data.to}
                                        />
                                    </div>
                                </>

                                : <div className='no_jobs' ><img onError={commonServices.imgError} src="../Assets/svgs/search_no_result.svg" alt="" /><h2>No candidate found!</h2></div>
                            }
                        </div>
                    </>
                }

            </div>

        </AppModal>
    )
}

export default PartnerCandidates;