import React from "react";
import { isUndefined } from "../../../utils/validation";
import "./Dropdown.scss";
import IconResource from "../IconResource/IconResource";
import Input from "../Input/Input";
import { KEYBOARD_SHORTCUT_TEXT } from "../../../constants/stringConstants";

const Dropdown = (props) => {
    let requiredClass = isUndefined(props?.isRequired) || !props?.isRequired ? '' : 'required';
    let label = isUndefined(props?.label) ? props?.placeholder : props?.label;
    let showDefaultOption = isUndefined(props?.showDefaultOption) ? false : props?.showDefaultOption;
    const renderDropdown = () => {
        switch (props?.type) {
            case "suggestonWithIcon":
                return (
                    <>
                        <Input type="iconLabelTextBox" icon={props?.icon} label={props?.label} placeholder={props?.placeholder} id={props?.id} value={props?.value} autoComplete='off' name={props?.name} onChange={props?.onChange} onKeyPress={props?.onKeyPress} onKeyDown={props?.onKeyDown} />
                        <div style={{ height: "auto", position: "relative" }}>
                            <div className={props?.suggestionArray?.length > 0 ? ' suggestion_box active' : "suggestion_box"}>
                                <div >
                                    {props?.suggestionArray && props?.suggestionArray.map((suggestion, i) =>
                                        <p key={i} className={i === 0 ? 'sugg_p hand-hover active' : 'sugg_p hand-hover'} onClick={() => props?.onSuggestionClick(suggestion)}>{suggestion.name}</p>)}
                                </div>

                                {
                                    props?.suggestionArray?.length > 0 ? <div style={{ position: "sticky", bottom: 0, background: "#f5f5f5", padding: "10px", color: "#9398A1", fontSize: "0.875em" }}>{KEYBOARD_SHORTCUT_TEXT}</div> : null
                                }
                            </div>
                        </div>
                        {props?.showHelpText !== undefined && props?.showHelpText ?
                            <p>Set job preferred location on <a className='pref-loc' href='/my-account?tab=preferences' target='_blank'>My Account</a> page</p>
                            : null}
                    </>)
            default:
                return (
                    <>
                        <div className={"form-input-group "}>
                            {label ? <label
                                className={requiredClass}
                                htmlFor={props?.id}
                            >{label} {<IconResource
                                type={props?.icon}
                                className={props?.iconClass}
                                title={props?.iconTitle}
                                size={props?.iconSize}
                                color={props?.iconColor} />}
                            </label> : null}

                            <select
                                id={props?.id}
                                key={props?.value}
                                className={`form-select ${props?.className}`}
                                name={props?.name}
                                value={props?.value}
                                ref={props?.ref}
                                onChange={props?.onChange}
                                disabled={props?.disabled}
                                style={props?.style}
                                onBlur={props?.onBlur}
                                placeholder={props?.place_holder}
                                {...props}
                            >
                                {showDefaultOption
                                    ? <option key='' value=''>Select {label ? label : props?.place_holder}</option>
                                    : null}
                                {props?.select !== undefined &&
                                    props?.select.map((data, i) => {
                                        return <option key={props?.id + "_" + i}
                                            value={data.id ? data.id : data}>
                                            {data.name ? data.name : data} {props?.textValue ? (data == 1 ? props?.textValue : (props?.textValue + "s")) : ''}
                                        </option>

                                    })
                                }
                            </select>
                        </div>
                    </>
                )
        }
    }

    return (<>{renderDropdown()}</>);
};

export default Dropdown;