import { useNavigate } from 'react-router'
import './Modal.scss';
import * as commonServices from '@/services/common';
import Text from '../_widgets/Text/Text';

function ModalHomeImagePopup({ showModal, setShowModal, popupData, setPopupData }) {
    const isMobileView = window.innerWidth <= 767; // You can adjust the threshold for what is considered "mobile"

    const navigate = useNavigate();

    const getImageSrc = () => {
        let src = '';

        if (popupData?.web_img || popupData?.mobile_img) {
            if (!isMobileView) {
                src = popupData?.web_img ?? popupData?.mobile_img;
            } else if (isMobileView) {
                src = popupData?.mobile_img ?? popupData?.web_img;
            }
        }

        return src;
    }

    return (
        showModal && <div class="modal giveaway_modal">
            <div className="giveaway_modal_content">
                <div className="modal_body giveaway_modal_body">
                    <div className="giveaway-img">
                        {getImageSrc()
                            ? <img
                                src={getImageSrc()}
                                alt='Banner'
                                style={{ width: '100%', height: 'auto' }} />
                            : popupData?.text
                                ? <Text type='h5' text={popupData?.text} />
                                : null}

                        <button className='close-btn' onClick={() => setShowModal(false)} ><img onError={commonServices.imgError} src="../../Assets/svgs/x.svg" alt="cross" /></button>
                    </div>

                    <div className="row">
                        <div className="col"></div>
                        <div className="col">
                            <div className='d-flex gap-3' >
                                {popupData?.button1Text &&
                                    <button
                                        className="btn btn-primary"
                                        onClick={popupData?.button1Action
                                            ? () => navigate(popupData?.button1Action)
                                            : null}
                                        style={{ margin: "20px 0px !important", fontSize: "1em" }}>
                                        {popupData?.button1Text}
                                    </button>}

                                {popupData?.button2Text &&
                                    <button className="btn btn-secondary"
                                        onClick={popupData?.button2Action
                                            ? () => navigate(popupData?.button2Action)
                                            : null}
                                        style={{ margin: "20px 0px !important" }}>
                                        {popupData?.button2Text}
                                    </button>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default ModalHomeImagePopup;
