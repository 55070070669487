import React, { useState } from "react";
import mapPin from "@/Assets/svgs/map-pin.svg";
import { useNavigate } from "react-router-dom";
import { locations } from "@/utils/arrayUtils";
import { REMOTE } from "@/constants/storageConstants";
import { getCandidateExperienceText } from "@/services/common";

const Card = ({ data, type }) => {
  const navigate = useNavigate();

  if (type === "job") {
    let jobData = data;
    const ReadMore = ({ children, paramLimit }) => {
      let limit = 15;
      if (paramLimit !== undefined) {
        limit = paramLimit;
      }
      const text = children;
      const [isReadMore, setIsReadMore] = useState(true);
      const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
      };
      return (
        <>
          <span>
            {isReadMore && text !== null ? text.slice(0, limit) : text}
            <span onClick={toggleReadMore} className="read-or-hide">
              {text !== null && text.slice(0, limit).length >= limit
                ? isReadMore
                  ? "...+More"
                  : " less"
                : ""}
            </span>
          </span>
        </>
      );
    };
    return (
      <div className="card__hover main-card">
        <div
          className="job__card justify-content-between box-hover white-background"
          style={{
            backgroundColor: "#fff",
          }}
        >
          <div className="image_container">
            <img
              className="featured__img"
              src={
                jobData?.company_logo
                  ? jobData?.company_logo
                  : jobData?.company?.logo
                    ? jobData?.company?.logo
                    : null
              }
              alt=""
            />
          </div>

          <div className="job__content pb-2">
            <p className="paragraph_1 truncate-2" title={jobData.title}>
              {jobData.title}
            </p>
            <p className="paragraph_2 truncate-1">
              {jobData?.job_types
                ? jobData?.job_types
                : jobData?.job_type?.name
                  ? jobData?.job_type?.name
                  : null}{" "}
              .{" "}
              {getCandidateExperienceText(jobData.minimum_experience_required)}-
              {getCandidateExperienceText(jobData.maximum_experience_required)}
            </p>
            <p className="paragraph_3 d-flex gap-1">
              {" "}
              <span className="map-pin">
                <img src={mapPin} alt="" />
              </span>{" "}
              <span
                className="truncate-1"
                title={jobData?.job_locations ? jobData.job_locations : "India"}
              >
                {jobData?.job_locations ? jobData.job_locations : "India"}
              </span>
            </p>
          </div>

          <div
            className="footer"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p
              className="paragraph_3_name truncate-1"
              title={
                jobData?.company_name
                  ? jobData?.company_name
                  : jobData?.company?.name
                    ? jobData?.company?.name
                    : null
              }
              style={{ fontWeight: 600 }}
            >
              {jobData?.company_name
                ? jobData?.company_name
                : jobData?.company?.name
                  ? jobData?.company?.name
                  : null}
            </p>{" "}
            <p
              style={{ color: "#9398A1" }}
              className="paragraph_4 truncate-1"
              title={jobData.posted_date}
            >
              {jobData.posted_date}
            </p>
          </div>
        </div>
      </div>
    );
  }
  if (type === "gig") {
    const gigData = data;
    let description = gigData?.description;
    const ReadMore = ({ children }) => {
      let limit = "50";
      const text = children;
      const [isReadMore, setIsReadMore] = useState(true);
      const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
      };
      return (
        <>
          {isReadMore ? text.slice(0, limit) : text}
          <span onClick={toggleReadMore} className="read-or-hide">
            {text.slice(0, limit).length >= limit
              ? isReadMore
                ? "...See more"
                : " See less"
              : ""}
          </span>
        </>
      );
    };

    return (
      <div className="card__hover main-card">
        <div className="job__card justify-content-between box-hover white-background">
          <div className="image_container">
            <img className="featured__img" src={gigData.company.logo} alt="" />
          </div>

          <div className="job__content pb-2">
            <p className="paragraph_1 truncate-2" title={gigData.title}>
              {gigData.title}
            </p>
            <p className="paragraph_2 truncate-1">{gigData.type.name}</p>
            <p className="paragraph_3 d-flex gap-1">
              <span className="map-pin">
                <img src={mapPin} alt="" />
              </span>{" "}
              <span
                className="truncate-1"
                title={
                  gigData?.engagement_mode_id !== null &&
                    gigData?.engagement_mode?.name === REMOTE
                    ? gigData?.engagement_mode?.name
                    : gigData?.locations
                      ? locations(gigData.locations)
                      : "India"
                }
              >
                {gigData?.engagement_mode_id !== null &&
                  gigData?.engagement_mode?.name === REMOTE
                  ? gigData?.engagement_mode?.name
                  : gigData?.locations
                    ? locations(gigData.locations)
                    : "India"}
              </span>
            </p>
          </div>

          <div
            className="footer"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p
              className="paragraph_3_name truncate-1"
              title={gigData.company.name}
              style={{ fontWeight: 600 }}
            >
              {gigData.company.name}
            </p>{" "}
            <p className="paragraph_4 truncate-1" title={gigData?.posted_date}>
              {gigData?.posted_date}
            </p>
          </div>
        </div>
      </div>
    );
  }
};

export default Card;
