import React from 'react'
import { Navigate } from 'react-router-dom'
import * as storageConstants from '../constants/storageConstants';
import * as commonServices from '../services/common';
import { isEvaluator, isUserStatusPending } from '../utils/authUtil';

function EvaluatorProtected(props) {
    const Cmp = props.cmp;
    var userRole = commonServices.getLocalData(storageConstants.USER_ROLE);
    var auth = commonServices.getLocalData(storageConstants.AUTH);
    let isRedirect = 0;
    var redirect = auth ? "/" : "/?auth=login&redirect=" + window.location.pathname;

    if (isUserStatusPending()) {
        redirect = '/profile-in-review';
        isRedirect = 1;
    }

    return (
        <>
            {auth && isEvaluator() && isRedirect === 0 ? <Cmp /> : <Navigate replace to={redirect} />}
        </>
    )
}

export default EvaluatorProtected