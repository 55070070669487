import React, { useCallback, useEffect, useRef, useState } from "react";
import { MaxWidthContainer } from "../Common/MaxWidthContainer";
import Text from "../_widgets/Text/Text";
import Input from "../Common/InputComponent/Input";
import Dropdown from "../_widgets/Dropdown/Dropdown";
import "./ResumeBuilder.scss";
import Button from "../_widgets/Button/Button";

import html2pdf from "html2pdf.js";

import Editor from "@/components/Common/Editor";

import { LiaAngleDownSolid, LiaAngleUpSolid } from "react-icons/lia";
import { FaCheckCircle, FaEdit } from "react-icons/fa";
import { PiPottedPlant } from "react-icons/pi";
import { LuFileBadge } from "react-icons/lu";
import { PiBagSimple } from "react-icons/pi";
import { FaRegChessKing } from "react-icons/fa6";
import { MdLanguage } from "react-icons/md";
import { TfiAnnouncement, TfiLayoutGrid2, TfiMedall } from "react-icons/tfi";

import { AiOutlineDelete } from "react-icons/ai";

import ModalEmp from "../Modals/ModalEmp";
import ModalSkills from "../Modals/ModalSkills";
import ModalAddEducation from "../Modals/ModalAddEducation";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import * as toast from "@/wrapper/toast";
import * as ApiHelper from "@/services/apiHelper";
import { isExist } from "../../utils/objectUtil";

import Loader from "@/components/Common/Loader";
import { CURRENCY } from "@/constants/storageConstants";
import * as storageConstants from "@/constants/storageConstants";
import * as commonServices from "@/services/common";

import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import { getUserBasicDetails, isLoggedIn } from "../../utils/authUtil";
import ModalInformation from "../Modals/ModalInformation";
import { useSearchParams } from "react-router-dom";
import { jsonToFormData } from "../../wrapper/formData";
import OneTemplate from "./templates/OneTemplate";
import SecondTemplate from "./templates/secondTemplate";
import ThirdTemplate from "./templates/thirdTemplate";
import FourTemplate from "./templates/fourTemplate";
import congratsImage from "@/Assets/images/congrats.png";
import ModalSelectResumeTemplate from "../Modals/ModalSelectResumeTemplate";
import ModalResumeWithAI from "../Modals/ModalResumeWithAI";
import { isNullOrEmpty, removeParameterFromURL } from "../../utils/stringUtils";
import * as errors from "@/constants/stringConstants";
import CustomButton from "../_widgets/Button/Button";
import useRazorpay from "react-razorpay";
import { Form, ProgressBar } from "react-bootstrap";
import IconResource from "../_widgets/IconResource/IconResource";
import { checkUrl } from "../../utils/validation";
import { warn } from "../../wrapper/toast";
import { MAXIMUM_ADDITIONAL_DOMAINS_ERROR_MESSAGE, MAXIMUM_NUMBER_ALLOWED, maxLengthAllowed, PHONE_NUMBER_INCORRECT, VALID_WEBSITE_URL } from "../../constants/stringConstants";
import { isValidPhoneNumber } from "react-phone-number-input";

const years = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23, 24, 25, 26, 27, 28, 29, 30,
];
const months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

// Create styles
const styles = {
  page: {
    flexDirection: "row",
    backgroundColor: "#656E83",
    padding: "10px",
    // paddingTop: "0px",
    // height: "100vh",
    // minHeight: "100vh",
    height: "100%",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  textHeading: {
    fontSize: "15px",
    fontWeight: 700,
    color: "#000000",
    textDecoration: "underline",
  },
  textHeading2: {
    fontSize: "11px",
    fontWeight: 700,
    color: "#000000",
    // textDecoration: "underline"
  },
  textPara: {
    fontSize: "10px",
    marginTop: "5px",
    fontWeight: 400,
    color: "#000000B2",
  },
  listItem: {
    marginTop: "10px",
    flexDirection: "row",
    marginBottom: 5,
    alignItems: "flex-start",
  },
  dot: {
    width: 5,
    height: 5,
    backgroundColor: "black",
    borderRadius: "50%",
    marginRight: 5,
  },
  listPara: {
    marginTop: "-2px",
    fontSize: "9px",
    fontWeight: 400,
    color: "#000000B2",
  },
};

const suggestionSkill = [
  {
    id: 64440,
    name: "Java",
    experience: 0,
  },
  {
    id: 64649,
    name: "React",
    experience: 0,
  },
  {
    id: 72414,
    name: "Full Stack",
    experience: 0,
  },
];

const pageStyle = "@page {size: A4 landscape;}";

/// Custom Section Options

const ResumeBuilder = () => {
  const [effectStates, setEffectStates] = useState({
    isUserSkillData: false,
  });

  const [modalState, setModalState] = useState({
    showModalEducation: false,
    showModalEmployment: false,
    showModalCertificate: false,
    showModalSkills: false,
    showModalAward: false,
    showModalCourse: false,
    showModalInternship: false,
  });

  const [customSections, setCustomSections] = useState({
    courses: false,
    extra: false,
    internships: false,
    hobbies: false,
    languages: false,
    references: false,
    achievements: false,
    details: false,
  });
  const [masterData, setMasterData] = useState(
    commonServices.getLocalData(storageConstants.DATA)
  );
  const Razorpay = useRazorpay();
  const [profile, setProfile] = useState({});

  const [localStates, setLocalStates] = useState({
    showExperienceLevel: false,
    autoSaveResume: false,
    showPDF: true,
    isDraggable: false,
  });
  const [loading, setLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isSentOtp, setIsSentOtp] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpVerified, setOtpVerified] = useState(false);
  const [needRegistration, setNeedRegistration] = useState(
    isLoggedIn() ? false : true
  );
  const [showDownloadResumeLink, setShowDownloadResumeLink] = useState(false);
  const [amount, setAmount] = useState(0);
  const [tempUserID, setTempUserId] = useState(0);
  const [template, setTemplate] = useState(1);
  const [downloadType, setDownloadType] = useState(null);
  const [validity, setValidity] = useState(null);

  const [skillData, setSkillData] = useState([]);
  let [searchParams] = useSearchParams();

  const [showTemplateModal, setShowTemplateModal] = useState(false);
  const [showAIResumeModal, setShowAIResumeModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [odHeading, setOdHeading] = useState("Other Details");
  const customSectionOptions = [
    {
      id: 1,
      name: "Courses",
      icon: <LuFileBadge size={20} />,
      key: "courses",
    },
    {
      id: 2,
      name: "Extra-Curricular Activities",
      icon: <PiPottedPlant size={20} />,
      key: "extra",
    },
    {
      id: 3,
      name: "Internships",
      icon: <PiBagSimple size={20} />,
      key: "internships",
    },
    {
      id: 4,
      name: "Hobbies",
      icon: <FaRegChessKing size={20} />,
      key: "hobbies",
    },
    {
      id: 5,
      name: "Spoken Languages",
      icon: <MdLanguage size={22} />,
      key: "languages",
    },
    {
      id: 6,
      name: "References",
      icon: <TfiAnnouncement size={20} />,
      key: "references",
    },
    {
      id: 7,
      name: "Achievements",
      icon: <TfiMedall size={20} />,
      key: "achievements",
    },
    {
      id: 8,
      name: odHeading,
      icon: <TfiLayoutGrid2 size={20} />,
      key: "details",
    },
  ];

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const handleSaveClick = () => {
    setIsEditing(false);
    // You can save the edited text or perform any other actions here
  };

  const handleInputChange = (e) => {
    setOdHeading(e.target.value);
  };

  useEffect(() => {
    var tid = searchParams.get("tid");
    var templateInput = searchParams.get("template");
    if (tid !== undefined && tid !== null) {
      checkPayment(tid);
    }
    if (templateInput !== undefined && templateInput !== null) {
      setTemplate(parseInt(templateInput));
    }
  }, [searchParams]);

  const checkPayment = async (tid) => {
    setLoading(true);
    let formData = new FormData();
    formData.append("tid", tid);
    await ApiHelper.resumeVerifyPayment(formData).then((response) => {
      if (response.isSuccess === true) {
        setLoading(false);
        setShowDownloadResumeLink(true);
        setOtpVerified(true);
        setDownloadType(response?.data?.download_type);
        setValidity(response?.data?.validity);
        if (response?.data?.user_id !== null) {
          getResume();
        } else {
          setTempUserId(response?.data?.temp_user_id);
        }
      } else {
        setLoading(false);
        toast.warn(response.message);
      }
    });
  };

  useEffect(() => {
    if (otpVerified) {
      getResume();
    }
  }, [tempUserID]);

  const onOptionChange = (e, amount) => {
    if (e.target.checked) {
      setAmount(amount);
    }
  };

  const registerUser = async () => {
    const obj = {
      first_name: profile.first_name,
      last_name: profile.last_name,
      email: profile.email,
      password: profile.password,
      confirm_password: profile.confirm_password,
      contact: profile.contact,
      contact_number: profile.contact,
      summary: profile.summary,
      location_id: profile.location_id,
      location_name: profile.location_name,
      resume_file: profile.resume_file !== undefined ? profile.resume_file : "",
      avatar: profile.avatar !== undefined ? profile.avatar : "",
      skills: profile?.userWorkProfileDetail?.skill,
    };
    if (profile.location_id !== undefined) {
      obj["location_id"] = profile.location_id;
    }
    if (profile.location_name !== undefined) {
      obj["location_name"] = profile.location_name;
    }
    if (profile.id !== undefined) {
      obj["id"] = profile.id;
    }
    setLoading(true);

    const formDataSeralize = jsonToFormData(obj);

    await ApiHelper.resumeCandidateRegister(formDataSeralize).then(
      (response) => {
        if (response.isSuccess === true) {
          toast.success(response.message);
          setLoading(false);
          if (amount <= 0) {
            setShowDownloadResumeLink(true);
          } else {
            placeOrder();
          }
        } else {
          setLoading(false);
          toast.warn(response.message);
        }
      }
    );
  };

  const downloadResume = () => {
    if (!isLoggedIn() && !otpVerified) {
      toast.error("Please verify your email");
      return false;
    } else if (downloadType === null) {
      toast.error("Please select any one option");
      return false;
    } else if (needRegistration) {
      registerUser();
    } else if (amount <= 0) {
      setShowDownloadResumeLink(true);
    } else {
      placeOrder();
    }
  };

  const verifyPayment = async (
    razorPayPaymentId,
    razorPayOrderId,
    razorPaySignature
  ) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("razorpay_order_id", razorPayOrderId);
    formData.append("razorpay_payment_id", razorPayPaymentId);
    formData.append("razorpay_signature", razorPaySignature);
    formData.append("is_resume_payment", true);

    ApiHelper.verifyRazorpayPayment(formData).then((response) => {
      if (response.isSuccess === true) {
        console.log("response-->", response);
        // toast.success(response.message);
        setLoading(false);
        setShowDownloadResumeLink(true);
        setOtpVerified(true);
        // setDownloadType(response?.data?.download_type);
        // setValidity(response?.data?.validity)
      } else {
        toast.warn(response.message);
        setLoading(false);
      }
    });
  };

  const initiateRazorpay = async (
    razorPayKey,
    razorPayOrderId,
    amount,
    currency
  ) => {
    var userDetails = getUserBasicDetails();
    var options = {
      key: razorPayKey,
      amount: amount,
      currency: currency,
      order_id: razorPayOrderId,
      handler: function (response) {
        verifyPayment(
          response.razorpay_payment_id,
          response.razorpay_order_id,
          response.razorpay_signature
        );
      },
      prefill: {
        name: userDetails["full_name"],
        email: userDetails["email"],
        contact: "",
      },
    };
    var rzp = new Razorpay(options);
    rzp.on("payment.failed", function (response) {
      toast.warn(response.error.description);
      console.error("errorResponse", response);
    });
    rzp.open();
  };

  const placeOrder = async () => {
    if (!isLoggedIn() && !otpVerified) {
      toast.error("Please verify your email");
      return false;
    } else if (downloadType === null) {
      toast.error("Please select any one option");
      return false;
    }
    setLoading(true);

    const tempAmount = commonServices.currencyFormatter(
      amount * (1 + gstPercent / 100)
    );

    const formData = new FormData();
    formData.append("amount", tempAmount);
    formData.append("is_resume_payment", true);
    formData.append("download_type", downloadType);
    formData.append(
      "redirect_url",
      removeParameterFromURL(window.location.href, "tid")
    );
    if (!isLoggedIn()) {
      formData.append("email", profile?.email);
    }

    ApiHelper.placeOrder(formData).then((response) => {
      if (response.isSuccess === true) {
        if (
          response.data.show_download_link !== undefined &&
          response.data.show_download_link
        ) {
          setShowDownloadResumeLink(true);
          setValidity(response.data.validity);
        } else {
          if (response?.data?.gateway === "phonepe") {
            var a = document.createElement("a");
            a.href = response.data.url;
            a.click();
          } else if (response?.data?.gateway === "razorpay") {
            initiateRazorpay(
              response.data.key,
              response.data.order_id,
              response.data.amount,
              response.data.currency
            );
          } else {
            toast.warn(response.message);
          }
        }
        setLoading(false);
      } else {
        toast.warn(response.message);
        setLoading(false);
      }
    });
  };

  const getProfile = async () => {
    setLoading(true);
    await ApiHelper.getProfile().then((response) => {
      setLoading(false);
      if (response.isSuccess === true) {
        setProfile(response.data);
      } else {
        toast.warn(response.message);
      }
    });
  };

  const getSkills = () => {
    ApiHelper.getSkills().then((response) => {
      setLoading(false);
      if (response.isSuccess === true) {
        setSkillData(response.data);
        setEffectStates({
          isUserSkillData: false,
        });
      } else {
        console.log("error");
      }
    });
  };

  const setUserSkillData = () => {
    if (
      profile?.userWorkProfileDetail !== undefined &&
      profile?.userWorkProfileDetail?.skill !== undefined &&
      profile.userWorkProfileDetail?.skill !== null
    ) {
      const tempSkill = [...profile.userWorkProfileDetail.skill];
      tempSkill.forEach((currentSkill) => {
        skillData.forEach((element) => {
          if (element.id === currentSkill.skill_id) {
            currentSkill.skill_name = element.name;
          }
          currentSkill.isCollapsed = true;
        });
      });
      setProfile({
        ...profile,
        userWorkProfileDetail: {
          ...profile?.userWorkProfileDetail,
          skill: tempSkill,
        },
      });
      setEffectStates({
        isUserSkillData: true,
      });
    }
  };

  useEffect(() => {
    // getProfile();
    getResume();
    var guest = commonServices.getLocalData(storageConstants.GUEST_USER);
    if (guest !== null) {
      setTempUserId(guest?.id);
      setOtpVerified(true);
      setIsSentOtp(true);
    }
  }, []);

  useEffect(() => {
    getSkills();
  }, []);

  useEffect(() => {
    let discount = 0;
    let amount = 0;
    if (downloadType === 1) {
      amount = masterData.system_config.pdf_with_watermark_price.value;
    } else if (downloadType === 2) {
      amount = masterData.system_config.pdf_without_watermark_price.value;
    }
    if (!needRegistration && downloadType !== null) {
      setAmount(amount);
    } else {
      discount = parseInt(
        masterData?.system_config?.resume_discount_on_signup?.value
      );
      amount = parseFloat(amount);
      setAmount(amount - (amount * discount) / 100);
    }
  }, [needRegistration]);

  useEffect(() => {
    if (
      !effectStates?.isUserSkillData &&
      isExist(profile) &&
      isExist(skillData)
    ) {
      setUserSkillData();
    }
  }, [
    skillData,
    profile?.userWorkProfileDetail?.skill,
    effectStates?.isUserSkillData,
  ]);

  const addSuggestionSkill = (id, name, experience = 0) => {
    if (
      profile?.userWorkProfileDetail?.skill?.find(
        (data) => data.skill_id === id
      )
    ) {
      toast.warn("Skill already added");
      return;
    }
    let tempProfile = { ...profile };
    if (tempProfile?.userWorkProfileDetail === undefined) {
      tempProfile.userWorkProfileDetail = [];
    }
    if (tempProfile?.userWorkProfileDetail?.skill === undefined) {
      tempProfile.userWorkProfileDetail.skill = [];
    }
    if (tempProfile?.user_work_profile === undefined) {
      tempProfile.user_work_profile = {};
    }
    const tempArr = [
      ...tempProfile?.userWorkProfileDetail?.skill,
      {
        skill_id: id,
        skill_name: name,
        isCollapsed: false,
        experience: experience,
      },
    ];

    setProfile({
      ...tempProfile,
      userWorkProfileDetail: {
        ...profile?.userWorkProfileDetail,
        skill: tempArr,
      },
    });
    autoSaveResume();
  };

  const [components, setComponents] = useState([
    {
      id: "1",
      component: "professional_summary",
    },
    {
      id: "2",
      component: "employment",
    },
    {
      id: "3",
      component: "education",
    },
    {
      id: "4",
      component: "websites_social_links",
    },
    {
      id: "5",
      component: "skills",
    },
    {
      id: "6",
      component: "hobbies",
    },
    {
      id: "7",
      component: "languages",
    },
    {
      id: "8",
      component: "references",
    },
    {
      id: "9",
      component: "achievements",
    },
    {
      id: "10",
      component: "details",
    },
    {
      id: "11",
      component: "courses",
    },
    {
      id: "12",
      component: "extra",
    },
    {
      id: "13",
      component: "internships",
    },
  ]);

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedComponents = [...components];
    const [movedComponent] = reorderedComponents.splice(result.source.index, 1);
    reorderedComponents.splice(result.destination.index, 0, movedComponent);

    setComponents(reorderedComponents);
    setLocalStates({ ...localStates, isDraggable: !localStates.isDraggable });
  };

  const onDragStart = (result) => {
    setLocalStates({ ...localStates, isDraggable: true });
  };

  const experienceHandler = (e, type) => {
    var tempArray = { ...profile };
    if (
      tempArray.user_work_profile === null ||
      tempArray.user_work_profile === undefined
    ) {
      tempArray.user_work_profile = {};
      tempArray.user_work_profile.total_experience = 0;
    }
    if (type === "year") {
      var months = parseInt(e) * 12;
      tempArray.user_work_profile.total_experience =
        (tempArray.user_work_profile.total_experience % 12) + months;
      setProfile(tempArray);
    } else if (type === "month") {
      tempArray.user_work_profile.total_experience =
        Math.trunc(tempArray.user_work_profile.total_experience / 12) * 12 +
        parseInt(e);
      setProfile(tempArray);
    }
  };

  const autoSaveResume = async () => {
    try {
      // setLoading(true)
      if (profile?.email && !commonServices.checkEmailValid(profile?.email)) {
        toast.warn(errors.INCORRECT_EMAIL);
        return;
      }
      let data = {};
      if (isLoggedIn()) {
        data = { data: profile };
      } else {
        data = { data: profile, temp_user_id: tempUserID };
      }
      await ApiHelper.postResume(data).then((response) => {
        if (response.code !== 200) {
          // toast.warn("Error Saving Resume");
        } else {
          // toast.success("Resume Saved Successfully");
          // getResume();
          setLocalStates({ ...localStates, autoSaveResume: false });
        }
      });
    } catch (err) {
      console.log("error Saving :: data :: ", err);
      toast.warn("Error Saving Resume");
    } finally {
      // setLoading(false)
    }
  };

  const getResume = async () => {
    try {
      setLoading(true);
      let data = "";
      if (!isLoggedIn()) {
        data = "?temp_user_id=" + tempUserID;
        if (tempUserID === 0) {
          return;
        }
      }
      await ApiHelper.getResume(data).then((response) => {
        if (response.code !== 200) {
          if (isLoggedIn()) {
            getProfile();
          }
        } else {
          setProfile(response.data);
        }
      });
    } catch (err) {
      console.log("error Saving :: data :: ", err);
    } finally {
      setLoading(false);
    }
  };

  const showTemplates = () => {
    setShowTemplateModal(true);
  };

  const sendOtp = () => {
    if (!profile?.email) {
      toast.error("Please enter email");
      return;
    }
    const formData = new FormData();
    formData.append("email", profile?.email);
    setLoading(true);
    ApiHelper.sendResumeOtp(formData).then((response) => {
      if (response.isSuccess === true) {
        toast.success(response.message);
        setLoading(false);
        setIsSentOtp(true);
        setTempUserId(response?.data?.temp_user_id);
      } else {
        toast.warn(response.message);
        setLoading(false);
      }
    });
  };

  const verifyOtp = () => {
    if (!profile?.email) {
      toast.error("Please enter email");
      return;
    } else if (!otp) {
      toast.error("Please enter otp");
      return;
    }
    const formData = new FormData();
    formData.append("email", profile?.email);
    formData.append("otp", otp);
    setLoading(true);
    ApiHelper.verifyResumeOtp(formData).then((response) => {
      if (response.isSuccess === true) {
        toast.success(response.message);
        commonServices.storeLocalData(
          storageConstants.GUEST_USER,
          response.data
        );
        setLoading(false);
        setOtpVerified(true);
        getResume();
      } else {
        toast.warn(response.message);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    if (localStates?.autoSaveResume) {
      autoSaveResume();
    }
  }, [
    profile?.userWorkProfileDetail?.experience,
    profile?.userWorkProfileDetail?.degree,
    profile?.userWorkProfileDetail?.course,
    profile?.userWorkProfileDetail?.internship,
  ]);

  const [gstPercent, setGstPercent] = useState(0);

  useEffect(() => {
    if (masterData) {
      setGstPercent(masterData.system_config.gst.value);
    }
  }, [masterData]);

  const containerRef = useRef();

  const hidePageCanvas = useCallback(() => {
    const canvas = containerRef.current.querySelector("canvas");
    if (canvas) canvas.style.visibility = "hidden";
    console.log("hidePageCanvas");
  }, [containerRef]);

  const showPageCanvas = useCallback(() => {
    const canvas = containerRef.current.querySelector("canvas");
    if (canvas) canvas.style.visibility = "visible";
    console.log("showPageCanvas");
  }, [containerRef]);

  const onPageLoadSuccess = useCallback(() => {
    hidePageCanvas();
  }, [hidePageCanvas]);
  const onPageRenderSuccess = useCallback(() => {
    showPageCanvas();
  }, [showPageCanvas]);
  const onPageRenderError = useCallback(() => {
    showPageCanvas();
  }, [showPageCanvas]);

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (profile) {
      let tempProgress = 0;

      if (profile.first_name) {
        tempProgress += 10;
      }

      if (profile.last_name) {
        tempProgress += 10;
      }

      if (profile.email) {
        tempProgress += 10;
      }

      if (profile.contact) {
        tempProgress += 10;
      }

      if (profile?.user_work_profile?.total_experience) {
        tempProgress += 10;
      }

      if (profile.description) {
        tempProgress += 10;
      }

      if (profile.designation) {
        tempProgress += 10;
      }

      if (profile?.userWorkProfileDetail?.experience?.length) {
        tempProgress += 10;
      }

      if (profile?.userWorkProfileDetail?.degree?.length) {
        tempProgress += 10;
      }

      if (profile?.userWorkProfileDetail?.skill?.length > 0) {
        tempProgress += 10;
      }

      setProgress(tempProgress);
    }
  }, [profile]);

  const componentRef = useRef();

  const getOptionAmount = (amount) => {
    if (needRegistration) {
      let discount = 0;
      discount = masterData?.system_config?.resume_discount_on_signup?.value
        ? parseInt(masterData?.system_config?.resume_discount_on_signup?.value)
        : 0;
      amount = parseFloat(amount);
      return amount - (amount * discount) / 100;
    } else {
      return amount;
    }
  };

  return (
    <>
      {loading && <Loader />}
      <ModalAddEducation
        showModal={modalState.showModalEducation}
        setShowModal={(state) =>
          setModalState({ ...modalState, showModalEducation: state })
        }
        profile={profile}
        setProfile={setProfile}
        keyEmp={profile.id}
        setDataAutoSave={(state) => console.log(state)}
      />

      <ModalEmp
        showModal={modalState.showModalEmployment}
        setShowModal={(state) =>
          setModalState({ ...modalState, showModalEmployment: state })
        }
        profile={profile}
        setProfile={setProfile}
        keyEmp={profile.id}
        type={"experience"}
        setDataAutoSave={(state) => console.log(state)}
      />
      <ModalSkills
        showModal={modalState.showModalSkills}
        setShowModal={(state) =>
          setModalState({ ...modalState, showModalSkills: state })
        }
        skillData={skillData}
        profile={profile}
        setProfile={setProfile}
        setSkillData={setSkillData}
        setDataAutoSave={(state) => console.log(state)}
      />

      <ModalEmp
        showModal={modalState.showModalInternship}
        setShowModal={(state) =>
          setModalState({ ...modalState, showModalInternship: state })
        }
        profile={profile}
        setProfile={setProfile}
        keyEmp={profile.id}
        setDataAutoSave={(state) => console.log(state)}
        type={"internship"}
      />

      <ModalAddEducation
        showModal={modalState.showModalCourse}
        setShowModal={(state) =>
          setModalState({ ...modalState, showModalCourse: state })
        }
        profile={profile}
        setProfile={setProfile}
        keyEmp={profile.id}
        setDataAutoSave={(state) => console.log(state)}
        type="course"
      />
      <ModalSelectResumeTemplate
        showModal={showTemplateModal}
        setShowModal={setShowTemplateModal}
      />
      <ModalResumeWithAI
        showModal={showAIResumeModal}
        setShowModal={setShowAIResumeModal}
        profile={profile}
        setProfile={setProfile}
        template={template}
        components={components}
        localStates={localStates}
        customSections={customSections}
        downloadType={downloadType}
        styles={styles}
        componentRef={componentRef}
      />
      <DragDropContext onDragEnd={onDragEnd} nDragStart={onDragStart}>
        <MaxWidthContainer className="tt-container">
          <Droppable droppableId="characters">
            {(provided) => (
              <div
                className="characters"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                <div
                  className="d-flex flex-column flex-md-row gap-4"
                  flexDirection={["column", "row"]}
                  gap={"20px"}
                >
                  {!showDownloadResumeLink ? (
                    !isDownloading ? (
                      <div
                        style={{ padding: "100px 0" }}
                        className="w-100 w-md-50"
                      >
                        <Text
                          type="h2"
                          className="mb-2 text-center"
                          text="Resume Maker"
                          style={{ fontSize: "var(--h2)" }}
                          fontWeight={500}
                          textAlign={"center"}
                        />

                        {/* Resume progress */}

                        <div style={{ marginTop: "40px" }} mt={"40px"}>
                          <div className="mb-2">
                            <div
                              className="d-flex align-items-center gap-2"
                              display={"flex"}
                              gap={"10px"}
                            >
                              <div
                                style={{
                                  color: "white",
                                  backgroundColor: "var(--green)",
                                  borderRadius: "5px",
                                  padding: "3px 5px",
                                  height: "30px",
                                  width: "40px",
                                  fontSize: "var(--p1)",
                                }}
                              >
                                <Text
                                  type=""
                                  text={`${progress || 0}%`}
                                  fontSize={"xs"}
                                  style={{ marginBottom: "0" }}
                                />
                              </div>
                              <Text
                                type=""
                                text="Resume Completion Score"
                                fontSize={"xs"}
                                fontWeight={500}
                                style={{
                                  color: "var(--black2)",
                                  fontSize: "0.8rem",
                                  marginBottom: "0",
                                }}
                              />
                            </div>
                          </div>
                          <ProgressBar
                            variant="success"
                            now={Number(progress)}
                            style={{ borderRadius: "8px" }}
                          />{" "}
                        </div>

                        <div style={{ marginTop: "45px" }} mt={"45px"}>
                          <Text
                            type="h2"
                            className="mb-2"
                            text="Personal Details"
                            fontSize={"xl"}
                            fontWeight={600}
                          />

                          <div
                            style={{
                              display: "grid",
                              gap: "15px",
                              width: "100%",
                              gridTemplateColumns: "repeat(2, 1fr)",
                              marginTop: "15px",
                            }}
                          >
                            <div style={{ gridColumn: "span 2" }}>
                              <Input
                                onBlur={() => {
                                  autoSaveResume();
                                  setLocalStates({
                                    ...localStates,
                                    showPDF: true,
                                  });
                                }}
                                onFocus={() =>
                                  setLocalStates({
                                    ...localStates,
                                    showPDF: false,
                                  })
                                }
                                label={"Job Title"}
                                name={"designation"}
                                type={"text"}
                                width={"100%"}
                                placeholder={"Job title"}
                                value={profile.designation}
                                handleChange={(e) => {
                                  if (e.target.value.length <= 50) {
                                    setProfile({ ...profile, designation: e.target.value, });
                                  } else {
                                    toast.warn(errors.maxLengthAllowed(50));
                                  }
                                }}
                              />
                            </div>
                            <div style={{ gridColumn: "span 1" }}>
                              <Input
                                onBlur={() => autoSaveResume()}
                                label={"First Name"}
                                name={"firstName"}
                                type={"text"}
                                width={"100%"}
                                placeholder={"First Name"}
                                value={profile.first_name}
                                handleChange={(e) => {
                                  if (e.target.value.length <= 20) {
                                    setProfile({ ...profile, first_name: e.target.value, });
                                  } else {
                                    toast.warn(errors.maxLengthAllowed(20));
                                  }
                                }}
                              />
                            </div>
                            <div style={{ gridColumn: "span 1" }}>
                              <Input
                                onBlur={() => autoSaveResume()}
                                label={"Last Name"}
                                name={"lastName"}
                                type={"text"}
                                width={"100%"}
                                placeholder={"Last Name"}
                                value={profile.last_name}
                                handleChange={(e) => {
                                  if (e.target.value.length <= 20) {
                                    setProfile({ ...profile, last_name: e.target.value, });
                                  } else {
                                    toast.warn(errors.maxLengthAllowed(20));
                                  }
                                }}
                              />
                            </div>

                            <div style={{ gridColumn: "span 1" }}>
                              <Input
                                onBlur={() => autoSaveResume()}
                                label={"Email"}
                                name={"email"}
                                type={"text"}
                                width={"100%"}
                                placeholder={"Email"}
                                value={profile.email}
                                handleChange={(e) => {
                                  if (e.target.value.length <= 30) {
                                    setProfile({ ...profile, email: e.target.value, });
                                  } else {
                                    toast.warn(errors.maxLengthAllowed(30));
                                  }
                                }}
                              />
                              {!isLoggedIn() ? (
                                !isSentOtp ? (
                                  <Button
                                    buttonType="primary"
                                    style={{
                                      marginTop: "10px",
                                      fontSize: "xs",
                                    }}
                                    onClick={() => {
                                      sendOtp();
                                    }}
                                    text="Send OTP"
                                    disabled={
                                      !commonServices.checkEmailValid(
                                        profile?.email
                                      )
                                    }
                                  ></Button>
                                ) : !otpVerified ? (
                                  <div className="mt-2" mt={"10px"}>
                                    <Input
                                      mt={"10px"}
                                      label={"OTP"}
                                      name={"otp"}
                                      type="number"
                                      width={"100%"}
                                      placeholder={"OTP"}
                                      value={otp}
                                      handleChange={(e) => {
                                        setOtp(e.target.value.slice(0, 4));
                                      }}
                                    />
                                    <Button
                                      buttonType="primary"
                                      style={{
                                        fontSize: "xs",
                                        marginTop: "10px",
                                      }}
                                      onClick={() => {
                                        verifyOtp();
                                      }}
                                      text="Verify OTP"
                                    ></Button>
                                  </div>
                                ) : null
                              ) : null}
                            </div>

                            <div style={{ gridColumn: "span 1" }}>
                              <Input
                                label={"Phone"}
                                pattern="^(?=(?:[^+]*\+){0,1}[^+]{0,14}$)[0-9+]*$"
                                name={"contact"}
                                type={"tel"}
                                maxLength="10"
                                width={"100%"}
                                placeholder={"Phone"}
                                value={profile.contact}
                                handleChange={(e) => {

                                  setProfile((v) => ({
                                    ...profile,
                                    contact: e.target.validity.valid
                                      ? e.target.value
                                      : v.contact,
                                  }));

                                }}
                                onBlur={(e) => {
                                  const newValue = e.target.value;
                                  if (!isValidPhoneNumber(newValue, 'IN')) {
                                    warn(PHONE_NUMBER_INCORRECT)
                                  }
                                  autoSaveResume();
                                }
                                }
                              />
                            </div>
                          </div>

                          <div className="mt-45" mt={"45px"}>
                            <Text
                              type="h2"
                              text="Total Experience"
                              fontSize={"xl"}
                              fontWeight={600}
                            />
                            <div
                              style={{
                                display: "grid",
                                gap: "15px",
                                width: "100%",
                                gridTemplateColumns: "repeat(2, 1fr)",
                                marginTop: "15px",
                              }}
                            >
                              <div style={{ gridColumn: "span 1" }}>
                                <Dropdown
                                  className="form-select form-select-lg"
                                  value={
                                    profile.user_work_profile !== undefined &&
                                      profile.user_work_profile
                                        .total_experience !== undefined
                                      ? Math.trunc(
                                        profile.user_work_profile
                                          .total_experience / 12
                                      )
                                      : 0
                                  }
                                  onChange={(e) => {
                                    experienceHandler(
                                      parseInt(e.target.value),
                                      "year"
                                    );
                                  }}
                                  select={years}
                                  label={"Years"}
                                  showSearch={false}
                                  onBlur={() => autoSaveResume()}
                                  textValue="Year"
                                />
                              </div>

                              <div style={{ gridColumn: "span 1" }}>
                                <Dropdown
                                  className="form-select form-select-lg"
                                  value={
                                    profile.user_work_profile
                                      ? Math.trunc(
                                        profile.user_work_profile
                                          .total_experience % 12
                                      )
                                      : 0
                                  }
                                  onChange={(e) =>
                                    experienceHandler(
                                      parseInt(e.target.value),
                                      "month"
                                    )
                                  }
                                  select={months}
                                  label={"Months"}
                                  onBlur={() => autoSaveResume()}
                                  showSearch={false}
                                  textValue="Month"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        {profile &&
                          components.map((component, index) => {
                            return (
                              <Draggable
                                key={component.id}
                                draggableId={component.id}
                                index={Number(index)}
                                isDragDisabled={!localStates?.isDraggable}
                              >
                                {(provided, snapshot) => {
                                  return (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={{
                                        backgroundColor: snapshot.isDragging
                                          ? "gray.200"
                                          : "white",
                                        border: snapshot.isDragging
                                          ? "1px dashed gray"
                                          : "1px solid transparent",
                                        padding: "10px",
                                        position: "relative",
                                        ...provided.draggableProps.style,
                                      }}
                                    >
                                      {component.component &&
                                        ((isExist(
                                          customSections[component.component]
                                        ) &&
                                          customSections[
                                          component.component
                                          ]) ||
                                          !isExist(
                                            customSections[component.component]
                                          )) && (
                                          <Button
                                            buttonType={"light"}
                                            onMouseEnter={() => {
                                              if (!localStates?.isDraggable) {
                                                setLocalStates({
                                                  ...localStates,
                                                  isDraggable: true,
                                                });
                                              }
                                            }}
                                            onMouseLeave={() => {
                                              if (localStates?.isDraggable) {
                                                setLocalStates({
                                                  ...localStates,
                                                  isDraggable: false,
                                                });
                                              }
                                            }}
                                            buttonIcon={
                                              <>
                                                <PiDotsThreeOutlineVerticalFill
                                                  size={20}
                                                  color={
                                                    snapshot.isDragging
                                                      ? "var(--green)"
                                                      : "gray"
                                                  }
                                                />
                                                <PiDotsThreeOutlineVerticalFill
                                                  style={{
                                                    marginLeft: "-10px",
                                                  }}
                                                  size={20}
                                                  color={
                                                    snapshot.isDragging
                                                      ? "var(--green)"
                                                      : "gray"
                                                  }
                                                />
                                              </>
                                            }
                                            aria-label="Drag"
                                            style={{
                                              cursor: "all-scroll",
                                              position: "absolute",
                                              top: "27px",
                                              left: "-35px",
                                              padding: "5px",
                                            }}
                                          />
                                        )}

                                      {component.component ===
                                        "professional_summary" && (
                                          <div className="py-4">
                                            <Text
                                              type="h2"
                                              className="mb-2"
                                              text="Professional Summary"
                                              fontSize={"xl"}
                                              fontWeight={600}
                                              cursor={"pointer"}
                                              onClick={() => {
                                                setLocalStates({
                                                  ...localStates,
                                                  isDraggable:
                                                    !localStates.isDraggable,
                                                });
                                              }}
                                            />

                                            <Text
                                              type=""
                                              text="Write 2-4 short sentences that best showcases your experience, knowledge, skills and competencies."
                                              style={{
                                                fontSize: "var(--p1)",
                                                color: "var(--gray4)",
                                                fontSize: "0.8rem",
                                              }}
                                            />

                                            <div className="mt-2 mx-550" mt={"10px"}>
                                              <Editor
                                                desc={profile?.description}
                                                setDesc={(data) => {
                                                  if (profile?.description !== data) {
                                                    if (data.length <= 500) {
                                                      setProfile({ ...profile, description: data, });
                                                    } else {
                                                      toast.warn(errors.maxLengthAllowed(500))
                                                    }
                                                  }
                                                }}
                                                onBlur={() => autoSaveResume()}
                                              />
                                              <div className="mt-2">
                                                <Text
                                                  type=""
                                                  text="Recruiter tip: write 50-200 characters to increase interview chances"
                                                  style={{
                                                    fontSize: "var(--p1)",
                                                    color: "var(--gray4)",
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        )}

                                      {component.component === "employment" && (
                                        <div className="py-4" py={"20px"}>
                                          <Text
                                            type="h2"
                                            className="mb-2"
                                            text="Employment History"
                                            fontSize={"xl"}
                                            fontWeight={600}
                                            cursor={"pointer"}
                                            onClick={() => {
                                              setLocalStates({
                                                ...localStates,
                                                isDraggable:
                                                  !localStates.isDraggable,
                                              });
                                            }}
                                          />

                                          <Text
                                            type=""
                                            text="Give details about your employment over the years starting with your current employment. If you are starting your career, mention your career goals and aspirations, starting with, Looking forward to an exciting career."
                                            style={{
                                              fontSize: "var(--p1)",
                                              color: "var(--gray4)",
                                              fontSize: "0.8rem",
                                            }}
                                          />

                                          <div className="mt-2 w-100 d-flex flex-column gap-2">
                                            {profile?.userWorkProfileDetail?.experience?.map(
                                              (item, index) => (
                                                <CollapsibleBox
                                                  onClick={() => {
                                                    setModalState({
                                                      ...modalState,
                                                      showModalEmployment: true,
                                                    });
                                                  }}
                                                  title={
                                                    item?.title +
                                                    " at " +
                                                    item?.awarded_by
                                                  }
                                                  description={
                                                    item?.from_date +
                                                    " - " +
                                                    (item?.is_present === "1"
                                                      ? "Present"
                                                      : item?.to_date)
                                                  }
                                                ></CollapsibleBox>
                                              )
                                            )}
                                          </div>

                                          <div className="d-flex gap-2 mt-3 align-items-center">
                                            <Text
                                              type="t-icon"
                                              className="hand-hover"
                                              text={`Add one more employment`}
                                              tIcon={
                                                <IconResource
                                                  type="roundPlus"
                                                  color="var(--green)"
                                                  size={20}
                                                />
                                              }
                                              style={{
                                                fontSize: "var(--p1)",
                                                color: "var(--green)",
                                              }}
                                              cursor={"pointer"}
                                              onClick={() => {
                                                setModalState({
                                                  ...modalState,
                                                  showModalEmployment: true,
                                                });
                                                setLocalStates({
                                                  ...localStates,
                                                  autoSaveResume: true,
                                                });
                                              }}
                                            ></Text>
                                          </div>
                                        </div>
                                      )}

                                      {component.component === "education" && (
                                        <div className="py-4" py={"20px"}>
                                          <Text
                                            type="h2"
                                            className="mb-2"
                                            text="Education"
                                            fontSize={"xl"}
                                            fontWeight={600}
                                            cursor={"pointer"}
                                            onClick={() => {
                                              setLocalStates({
                                                ...localStates,
                                                isDraggable:
                                                  !localStates.isDraggable,
                                              });
                                            }}
                                          />

                                          <Text
                                            type=""
                                            text="Highlight your education details with details of your specific areas of learning"
                                            style={{
                                              fontSize: "var(--p1)",
                                              color: "var(--gray4)",
                                              fontSize: "0.8rem",
                                            }}
                                          />

                                          <div className="mt-2 w-100 d-flex flex-column gap-2">
                                            {profile?.userWorkProfileDetail?.degree?.map(
                                              (item, index) => (
                                                <CollapsibleBox
                                                  onClick={() => {
                                                    setModalState({
                                                      ...modalState,
                                                      showModalEducation: true,
                                                    });
                                                  }}
                                                  title={
                                                    item?.title +
                                                    " at " +
                                                    item?.awarded_by
                                                  }
                                                  description={
                                                    item?.from_date +
                                                    " - " +
                                                    (item?.is_present === "1"
                                                      ? "Present"
                                                      : item?.to_date)
                                                  }
                                                ></CollapsibleBox>
                                              )
                                            )}
                                          </div>

                                          <div className="d-flex gap-2 mt-3 align-items-center">
                                            <Text
                                              type="t-icon"
                                              className="hand-hover"
                                              text={`Add one more education`}
                                              tIcon={
                                                <IconResource
                                                  type="roundPlus"
                                                  color="var(--green)"
                                                  size={20}
                                                />
                                              }
                                              style={{
                                                fontSize: "var(--p1)",
                                                color: "var(--green)",
                                              }}
                                              cursor={"pointer"}
                                              onClick={() => {
                                                setModalState({
                                                  ...modalState,
                                                  showModalEducation: true,
                                                });
                                                setLocalStates({
                                                  ...localStates,
                                                  autoSaveResume: true,
                                                });
                                              }}
                                            />
                                          </div>
                                        </div>
                                      )}

                                      {component.component ===
                                        "websites_social_links" && (
                                          <div className="py-4" py={"20px"}>
                                            <Text
                                              type="h2"
                                              className="mb-2"
                                              text="Websites & Social Links"
                                              fontSize={"xl"}
                                              fontWeight={600}
                                              cursor={"pointer"}
                                              onClick={() => {
                                                setLocalStates({
                                                  ...localStates,
                                                  isDraggable:
                                                    !localStates.isDraggable,
                                                });
                                              }}
                                            />

                                            <Text
                                              type=""
                                              text="You can add links to websites you want hiring managers to see! Perhaps It will be a link to your
                                                                        portfolio, LinkedIn profile, or personal website"
                                              style={{
                                                fontSize: "var(--p1)",
                                                color: "var(--gray4)",
                                                fontSize: "0.8rem",
                                              }}
                                            />

                                            <div className="mt-2 w-100 d-flex flex-column gap-2">
                                              {profile?.userWorkProfileDetail?.website?.map(
                                                (item, index) => (
                                                  <WebsiteBox
                                                    item={item}
                                                    setProfile={setProfile}
                                                    profile={profile}
                                                    index={index}
                                                    onClick={() => {
                                                      setProfile({
                                                        ...profile,
                                                        userWorkProfileDetail: {
                                                          ...profile?.userWorkProfileDetail,
                                                          website:
                                                            profile?.userWorkProfileDetail?.website?.map(
                                                              (data, i) =>
                                                                i === index
                                                                  ? {
                                                                    ...data,
                                                                    isCollapsed:
                                                                      !data.isCollapsed,
                                                                  }
                                                                  : data
                                                            ),
                                                        },
                                                      });
                                                    }}
                                                    isCollapsed={
                                                      item?.isCollapsed
                                                    }
                                                    data={item}
                                                    autoSaveResume={
                                                      autoSaveResume
                                                    }
                                                  ></WebsiteBox>
                                                )
                                              )}
                                            </div>

                                            <div className="d-flex gap-2 mt-3 align-items-center">
                                              <Text
                                                type="t-icon"
                                                className="hand-hover"
                                                text={`Add one more link`}
                                                tIcon={
                                                  <IconResource
                                                    type="roundPlus"
                                                    color="var(--green)"
                                                    size={20}
                                                  />
                                                }
                                                style={{
                                                  fontSize: "var(--p1)",
                                                  color: "var(--green)",
                                                }}
                                                cursor={"pointer"}
                                                onClick={() => {
                                                  let tempProfile = {
                                                    ...profile,
                                                  };
                                                  if (
                                                    tempProfile.userWorkProfileDetail ===
                                                    undefined
                                                  ) {
                                                    tempProfile.userWorkProfileDetail =
                                                      [];
                                                  }
                                                  if (
                                                    tempProfile
                                                      ?.userWorkProfileDetail
                                                      ?.website === undefined
                                                  ) {
                                                    tempProfile.userWorkProfileDetail.website =
                                                      [];
                                                  }
                                                  setProfile({
                                                    ...tempProfile,
                                                    userWorkProfileDetail: {
                                                      ...tempProfile?.userWorkProfileDetail,
                                                      website: [
                                                        ...tempProfile
                                                          ?.userWorkProfileDetail
                                                          ?.website,
                                                        {
                                                          name: "",
                                                          url: "",
                                                          isCollapsed: false,
                                                        },
                                                      ],
                                                    },
                                                  });
                                                }}
                                              />
                                            </div>
                                          </div>
                                        )}

                                      {component.component === "skills" && (
                                        <div className="py-4" py={"20px"}>
                                          <Text
                                            type="h2"
                                            className="mb-2"
                                            text="Skills"
                                            fontSize={"xl"}
                                            fontWeight={600}
                                            cursor={"pointer"}
                                            onClick={() => {
                                              setLocalStates({
                                                ...localStates,
                                                isDraggable:
                                                  !localStates.isDraggable,
                                              });
                                            }}
                                          />

                                          <Text
                                            type=""
                                            text="Choose upto 5 skills that showcase your experience/expertise."
                                            style={{
                                              fontSize: "var(--p1)",
                                              color: "var(--gray4)",
                                              fontSize: "0.8rem",
                                              marginBottom: "0",
                                            }}
                                          />

                                          <div className="my-2 d-flex align-items-center">
                                            <Form>
                                              <Form.Check // prettier-ignore
                                                type="switch"
                                                checked={
                                                  localStates.showExperienceLevel
                                                }
                                                onChange={(e) => {
                                                  setLocalStates({
                                                    ...localStates,
                                                    showExperienceLevel:
                                                      e.target.checked,
                                                  });
                                                }}
                                              />
                                            </Form>
                                            <Text
                                              type=""
                                              text="Don't show experience level"
                                              mb={0}
                                              style={{
                                                fontSize: "var(--p1)",
                                                color: "var(--gray4)",
                                                fontSize: "0.8rem",
                                                marginBottom: "0",
                                              }}
                                            />
                                          </div>

                                          <div className="my-3 d-flex flex-wrap gap-2 align-items-center">
                                            {[...suggestionSkill].map(
                                              (item, index) => (
                                                <div
                                                  className="border rounded-3 px-2 py-1 bg-light hand-hover"
                                                  style={{
                                                    whiteSpace: "nowrap",
                                                  }}
                                                  key={index}
                                                  onClick={() =>
                                                    addSuggestionSkill(
                                                      item.id,
                                                      item.name
                                                    )
                                                  }
                                                >
                                                  <Text
                                                    className="flex-row-reverse"
                                                    type="t-icon"
                                                    tIcon={
                                                      <IconResource
                                                        type="roundPlus"
                                                        color="var(--green)"
                                                        size={20}
                                                      />
                                                    }
                                                    text={item.name}
                                                    display={"flex"}
                                                    gap={"10px"}
                                                    alignItems={"center"}
                                                    style={{
                                                      fontSize: "var(--p1)",
                                                      color: "var(--gray4)",
                                                      marginBottom: "0",
                                                    }}
                                                  />
                                                </div>
                                              )
                                            )}
                                          </div>

                                          <div className="mt-2 w-100 d-flex flex-column gap-2">
                                            {profile?.userWorkProfileDetail
                                              ?.skill &&
                                              profile?.userWorkProfileDetail
                                                ?.skill.length > 0 &&
                                              profile?.userWorkProfileDetail?.skill.map(
                                                (item, index) => (
                                                  <SkillBox
                                                    item={item}
                                                    setProfile={setProfile}
                                                    profile={profile}
                                                    index={index}
                                                    onClick={() => {
                                                      setProfile({
                                                        ...profile,
                                                        userWorkProfileDetail: {
                                                          ...profile?.userWorkProfileDetail,
                                                          skill:
                                                            profile?.userWorkProfileDetail?.skill?.map(
                                                              (data, i) =>
                                                                i === index
                                                                  ? {
                                                                    ...data,
                                                                    isCollapsed:
                                                                      !data.isCollapsed,
                                                                  }
                                                                  : data
                                                            ),
                                                        },
                                                      });
                                                    }}
                                                    isCollapsed={
                                                      item?.isCollapsed
                                                    }
                                                    data={item}
                                                    autoSaveResume={
                                                      autoSaveResume
                                                    }
                                                  ></SkillBox>
                                                )
                                              )}
                                          </div>

                                          <div
                                            className="d-flex gap-2 mt-3 align-items-center"
                                            display={"flex"}
                                            gap={"10px"}
                                            mt={"15px"}
                                            alignItems={"center"}
                                          >
                                            <Text
                                              type="t-icon"
                                              className="hand-hover"
                                              text={`Add one more skill`}
                                              tIcon={
                                                <IconResource
                                                  type="roundPlus"
                                                  color="var(--green)"
                                                  size={20}
                                                />
                                              }
                                              style={{
                                                fontSize: "var(--p1)",
                                                color: "var(--green)",
                                              }}
                                              cursor={"pointer"}
                                              onClick={() => {
                                                let tempProfile = {
                                                  ...profile,
                                                };
                                                if (
                                                  tempProfile.userWorkProfileDetail ===
                                                  undefined
                                                ) {
                                                  tempProfile.userWorkProfileDetail =
                                                    [];
                                                }
                                                if (
                                                  tempProfile
                                                    ?.userWorkProfileDetail
                                                    ?.skill === undefined
                                                ) {
                                                  tempProfile.userWorkProfileDetail.skill =
                                                    [];
                                                }
                                                if (
                                                  tempProfile?.user_work_profile ===
                                                  undefined
                                                ) {
                                                  tempProfile.user_work_profile =
                                                    {};
                                                }
                                                setProfile({
                                                  ...tempProfile,
                                                  userWorkProfileDetail: {
                                                    ...tempProfile?.userWorkProfileDetail,
                                                    skill: [
                                                      ...tempProfile
                                                        ?.userWorkProfileDetail
                                                        ?.skill,
                                                      {
                                                        skill_id: "",
                                                        skill_name: "",
                                                        isCollapsed: false,
                                                        experience: 0,
                                                      },
                                                    ],
                                                  },
                                                });
                                              }}
                                            />
                                          </div>
                                        </div>
                                      )}

                                      {component.component === "languages" &&
                                        customSections.languages && (
                                          <div className="py-4">
                                            <Text
                                              type="h2"
                                              className="mb-2"
                                              text="Languages"
                                              fontSize={"xl"}
                                              fontWeight={600}
                                              cursor={"pointer"}
                                              onClick={() => {
                                                setLocalStates({
                                                  ...localStates,
                                                  isDraggable:
                                                    !localStates.isDraggable,
                                                });
                                              }}
                                            />

                                            <div className="mt-2 w-100 d-flex flex-column gap-2">
                                              {profile?.userWorkProfileDetail?.language?.map(
                                                (item, index) => (
                                                  <LanguageBox
                                                    item={item}
                                                    setProfile={setProfile}
                                                    profile={profile}
                                                    index={index}
                                                    onClick={() => {
                                                      setProfile({
                                                        ...profile,
                                                        userWorkProfileDetail: {
                                                          ...profile?.userWorkProfileDetail,
                                                          language:
                                                            profile?.userWorkProfileDetail?.language?.map(
                                                              (data, i) =>
                                                                i === index
                                                                  ? {
                                                                    ...data,
                                                                    isCollapsed:
                                                                      !data.isCollapsed,
                                                                  }
                                                                  : data
                                                            ),
                                                        },
                                                      });
                                                    }}
                                                    isCollapsed={
                                                      item?.isCollapsed
                                                    }
                                                    data={item}
                                                    autoSaveResume={
                                                      autoSaveResume
                                                    }
                                                  ></LanguageBox>
                                                )
                                              )}
                                            </div>

                                            <div
                                              className="d-flex gap-2 mt-3 align-items-center"
                                              display={"flex"}
                                              gap={"10px"}
                                              mt={"15px"}
                                              alignItems={"center"}
                                            >
                                              <Text
                                                type="t-icon"
                                                className="hand-hover"
                                                text={`Add one more language`}
                                                tIcon={
                                                  <IconResource
                                                    type="roundPlus"
                                                    color="var(--green)"
                                                    size={20}
                                                  />
                                                }
                                                style={{
                                                  fontSize: "var(--p1)",
                                                  color: "var(--green)",
                                                }}
                                                cursor={"pointer"}
                                                onClick={() => {
                                                  let tempProfile = {
                                                    ...profile,
                                                  };
                                                  if (
                                                    tempProfile.userWorkProfileDetail ===
                                                    undefined
                                                  ) {
                                                    tempProfile.userWorkProfileDetail =
                                                      [];
                                                  }
                                                  if (
                                                    tempProfile
                                                      ?.userWorkProfileDetail
                                                      ?.language === undefined
                                                  ) {
                                                    tempProfile.userWorkProfileDetail.language =
                                                      [];
                                                  }
                                                  setProfile({
                                                    ...tempProfile,
                                                    userWorkProfileDetail: {
                                                      ...tempProfile?.userWorkProfileDetail,
                                                      language: [
                                                        ...tempProfile
                                                          ?.userWorkProfileDetail
                                                          ?.language,
                                                        {
                                                          skill_id: "",
                                                          skill_name: "",
                                                          isCollapsed: false,
                                                          experience: "",
                                                        },
                                                      ],
                                                    },
                                                  });
                                                }}
                                              />
                                            </div>
                                          </div>
                                        )}

                                      {component.component === "hobbies" &&
                                        customSections.hobbies && (
                                          <div className="py-4">
                                            <Text
                                              type="h2"
                                              className="mb-2"
                                              text="Hobbies"
                                              fontSize={"xl"}
                                              fontWeight={600}
                                              cursor={"pointer"}
                                              onClick={() => {
                                                setLocalStates({
                                                  ...localStates,
                                                  isDraggable:
                                                    !localStates.isDraggable,
                                                });
                                              }}
                                            />

                                            <Text
                                              type=""
                                              text="What do you like?"
                                              style={{
                                                fontSize: "var(--p1)",
                                                color: "var(--gray4)",
                                                fontSize: "0.8rem",
                                              }}
                                            />

                                            <div className="mt-2 mx-550" mt={"10px"}>
                                              <Editor
                                                desc={profile?.hobbies}
                                                setDesc={(data) => {
                                                  if (profile?.hobbies !== data) {
                                                    if (data.length <= 500) {
                                                      setProfile({
                                                        ...profile,
                                                        hobbies: data,
                                                      })
                                                    } else {
                                                      toast.warn(errors.maxLengthAllowed(500))
                                                    }
                                                  }
                                                }
                                                }
                                                onBlur={() => autoSaveResume()}
                                              />
                                            </div>
                                          </div>
                                        )}

                                      {component.component === "references" &&
                                        customSections.references && (
                                          <div className="py-4">
                                            <Text
                                              type="h2"
                                              className="mb-2"
                                              text="References"
                                              fontSize={"xl"}
                                              fontWeight={600}
                                              cursor={"pointer"}
                                              onClick={() => {
                                                setLocalStates({
                                                  ...localStates,
                                                  isDraggable:
                                                    !localStates.isDraggable,
                                                });
                                              }}
                                            />

                                            <div className="mt-2 mx-550">
                                              <Editor
                                                desc={profile?.reference}
                                                setDesc={(data) => {
                                                  if (profile?.reference !== data) {
                                                    if (data.length <= 500) {
                                                      setProfile({
                                                        ...profile,
                                                        reference: data,
                                                      })
                                                    } else {
                                                      toast.warn(errors.maxLengthAllowed(500))
                                                    }
                                                  }
                                                }
                                                }
                                                onBlur={() => autoSaveResume()}
                                              />
                                            </div>
                                          </div>
                                        )}

                                      {component.component === "achievements" &&
                                        customSections.achievements && (
                                          <div className="py-4">
                                            <Text
                                              type=""
                                              text="Achievements"
                                              fontSize={"xl"}
                                              fontWeight={600}
                                              cursor={"pointer"}
                                              onClick={() => {
                                                setLocalStates({
                                                  ...localStates,
                                                  isDraggable:
                                                    !localStates.isDraggable,
                                                });
                                              }}
                                            />

                                            <div className="mt-2 mx-550">
                                              <Editor
                                                desc={profile?.achievements}
                                                setDesc={(data) => {
                                                  if (profile?.achievements !== data) {
                                                    if (data.length <= 500) {
                                                      setProfile({
                                                        ...profile,
                                                        achievements: data,
                                                      })
                                                    } else {
                                                      toast.warn(errors.maxLengthAllowed(500))
                                                    }
                                                  }
                                                }
                                                }
                                                onBlur={() => autoSaveResume()}
                                              />
                                            </div>
                                          </div>
                                        )}

                                      {component.component === "details" &&
                                        customSections.details && (
                                          <div className="py-4">
                                            <div>
                                              {isEditing ? (
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    gap: "5px",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <input
                                                    value={odHeading}
                                                    onChange={handleInputChange}
                                                    onBlur={handleSaveClick}
                                                  />
                                                  <button
                                                    className="btn py-0"
                                                    onClick={handleSaveClick}
                                                  >
                                                    <FaCheckCircle />
                                                  </button>
                                                </div>
                                              ) : (
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    gap: "5px",
                                                    alignItems: "center",
                                                    whiteSpace: "nowrap",
                                                    textOverflow: "ellipsis",
                                                  }}
                                                >
                                                  <Text
                                                    type=""
                                                    text={odHeading}
                                                    fontSize={"xl"}
                                                    fontWeight={600}
                                                    cursor={"pointer"}
                                                    onClick={() => {
                                                      setLocalStates({
                                                        ...localStates,
                                                        isDraggable:
                                                          !localStates.isDraggable,
                                                      });
                                                    }}
                                                  />

                                                  <button
                                                    onClick={handleEditClick}
                                                    className="btn py-0"
                                                  >
                                                    <FaEdit />
                                                  </button>
                                                </div>
                                              )}
                                            </div>

                                            <div className="mt-2 mx-550">
                                              <Editor
                                                desc={profile[odHeading]}
                                                setDesc={(data) => {
                                                  if (profile?.[odHeading] !== data) {
                                                    if (data.length <= 500) {
                                                      setProfile({
                                                        ...profile,
                                                        [odHeading]: data,
                                                      })
                                                    } else {
                                                      toast.warn(errors.maxLengthAllowed(500))
                                                    }
                                                  }
                                                }}
                                                onBlur={() => autoSaveResume()}
                                              />
                                            </div>
                                          </div>
                                        )}

                                      {component.component === "courses" &&
                                        customSections.courses && (
                                          <div className="py-4">
                                            <Text
                                              type="h2"
                                              className="mb-2"
                                              text="Courses"
                                              fontSize={"xl"}
                                              fontWeight={600}
                                              cursor={"pointer"}
                                              onClick={() => {
                                                setLocalStates({
                                                  ...localStates,
                                                  isDraggable:
                                                    !localStates.isDraggable,
                                                });
                                              }}
                                            />

                                            <div className="mt-2 w-100 d-flex flex-column gap-2">
                                              {profile?.userWorkProfileDetail?.course?.map(
                                                (item, index) => (
                                                  <CollapsibleBox
                                                    onClick={() => {
                                                      setModalState({
                                                        ...modalState,
                                                        showModalCourse: true,
                                                      });
                                                    }}
                                                    title={
                                                      item?.title +
                                                      " at " +
                                                      item?.awarded_by
                                                    }
                                                    description={
                                                      item?.from_date +
                                                      " - " +
                                                      (item?.is_present === "1"
                                                        ? "Present"
                                                        : item?.to_date)
                                                    }
                                                  ></CollapsibleBox>
                                                )
                                              )}
                                            </div>

                                            <div className="d-flex align-items-center gap-2 mt-3">
                                              <Text
                                                type="t-icon"
                                                className="hand-hover"
                                                text={`Add one more course`}
                                                tIcon={
                                                  <IconResource
                                                    type="roundPlus"
                                                    color="var(--green)"
                                                    size={20}
                                                  />
                                                }
                                                style={{
                                                  fontSize: "var(--p1)",
                                                  color: "var(--green)",
                                                }}
                                                cursor={"pointer"}
                                                onClick={() => {
                                                  setModalState({
                                                    ...modalState,
                                                    showModalCourse: true,
                                                  });
                                                  setLocalStates({
                                                    ...localStates,
                                                    autoSaveResume: true,
                                                  });
                                                }}
                                              />
                                            </div>
                                          </div>
                                        )}

                                      {component.component === "internships" &&
                                        customSections.internships && (
                                          <div className="py-4">
                                            <Text
                                              type="h2"
                                              className="mb-2"
                                              text="Internships"
                                              fontSize={"xl"}
                                              fontWeight={600}
                                              cursor={"pointer"}
                                              onClick={() => {
                                                setLocalStates({
                                                  ...localStates,
                                                  isDraggable:
                                                    !localStates.isDraggable,
                                                });
                                              }}
                                            />

                                            <div className="mt-2 w-100 d-flex flex-column gap-2">
                                              {profile?.userWorkProfileDetail?.internship?.map(
                                                (item, index) => (
                                                  <CollapsibleBox
                                                    onClick={() => {
                                                      setModalState({
                                                        ...modalState,
                                                        showModalInternship: true,
                                                      });
                                                    }}
                                                    title={
                                                      item?.title +
                                                      " at " +
                                                      item?.awarded_by
                                                    }
                                                    description={
                                                      item?.from_date +
                                                      " - " +
                                                      (item?.is_present === "1"
                                                        ? "Present"
                                                        : item?.to_date)
                                                    }
                                                  ></CollapsibleBox>
                                                )
                                              )}
                                            </div>

                                            <div className="d-flex align-items-center gap-2 mt-3">
                                              <Text
                                                type="t-icon"
                                                className="hand-hover"
                                                text={`Add one more Internship`}
                                                tIcon={
                                                  <IconResource
                                                    type="roundPlus"
                                                    color="var(--green)"
                                                    size={20}
                                                  />
                                                }
                                                style={{
                                                  fontSize: "var(--p1)",
                                                  color: "var(--green)",
                                                }}
                                                cursor={"pointer"}
                                                onClick={() => {
                                                  setModalState({
                                                    ...modalState,
                                                    showModalInternship: true,
                                                  });
                                                  setLocalStates({
                                                    ...localStates,
                                                    autoSaveResume: true,
                                                  });
                                                }}
                                              />
                                            </div>
                                          </div>
                                        )}

                                      {component.component === "extra" &&
                                        customSections.extra && (
                                          <div className="py-4">
                                            <Text
                                              type="h2"
                                              className="mb-2"
                                              text="Extra-Curricular Activities"
                                              fontSize={"xl"}
                                              fontWeight={600}
                                              cursor={"pointer"}
                                              onClick={() => {
                                                setLocalStates({
                                                  ...localStates,
                                                  isDraggable:
                                                    !localStates.isDraggable,
                                                });
                                              }}
                                            />

                                            <div className="mt-2 mx-550">
                                              <Editor
                                                desc={
                                                  profile?.extra_curricular_activity
                                                }
                                                setDesc={(data) => {
                                                  if (profile?.extra_curricular_activity !== data) {

                                                    if (data.length <= 500) {
                                                      setProfile({
                                                        ...profile,
                                                        extra_curricular_activity: data,
                                                      })
                                                    } else {
                                                      toast.warn(errors.maxLengthAllowed(500))
                                                    }
                                                  }
                                                }
                                                }
                                                placeholder={
                                                  "Please Enter Extra-Curricular Activities"
                                                }
                                                onBlur={() => autoSaveResume()}
                                              />
                                            </div>
                                          </div>
                                        )}
                                    </div>
                                  );
                                }}
                              </Draggable>
                            );
                          })}

                        <div className="py-4" position={"relative"}>
                          <Text
                            type="h2"
                            className="mb-2"
                            text="Add Section"
                            fontSize={"xl"}
                            fontWeight={600}
                          />

                          <div
                            style={{
                              display: "grid",
                              gap: "15px",
                              width: "100%",
                              gridTemplateColumns: "repeat(2, 1fr)",
                              marginTop: "15px",
                            }}
                          >
                            {customSectionOptions.map((item, index) => (
                              <div
                                className="d-flex gap-1"
                                display={"flex"}
                                gap={"5px"}
                              >
                                <div
                                  type=""
                                  text=""
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "md",
                                    fontWeight: 400,
                                    display: "flex",
                                    gap: "10px",
                                    alignItems: "center",
                                  }}
                                  onClick={() => {
                                    setCustomSections({
                                      ...customSections,
                                      [item.key]: !customSections[item.key],
                                    });
                                  }}
                                >
                                  <Button
                                    buttonType="light"
                                    aria-label="Search database"
                                    buttonIcon={item.icon}
                                    colorScheme="transparent"
                                    padding={0}
                                    style={{
                                      color: customSections[item.key]
                                        ? "var(--green)"
                                        : "var(--black2)",
                                    }}
                                  />
                                  {item?.name}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="w-100 w-md-50"
                        style={{ padding: "100px, 0px" }}
                      >
                        {!isLoggedIn() ? (
                          <>
                            <h3 style={{ fontSize: "1em", fontWeight: "500" }}>
                              Do you want to register as a TrueTalent candidate?
                            </h3>
                            <div
                              className="row checkform"
                              style={{ marginTop: "10px", marginLeft: "0px" }}
                            >
                              <div key={"login1"} className="form-check">
                                <input
                                  type="radio"
                                  name="signup"
                                  className="form-check-input"
                                  id={"login1"}
                                  defaultChecked={needRegistration}
                                  onChange={(e) => setNeedRegistration(true)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={"login1"}
                                >
                                  Yes
                                </label>
                              </div>

                              <div key={"login2"} className="form-check">
                                <input
                                  type="radio"
                                  name="signup"
                                  className="form-check-input"
                                  id={"login2"}
                                  defaultChecked={!needRegistration}
                                  onChange={(e) => setNeedRegistration(false)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={"login2"}
                                >
                                  No
                                </label>
                              </div>
                            </div>
                          </>
                        ) : null}
                        <h3
                          style={{
                            marginTop: "30px",
                            fontSize: "1em",
                            fontWeight: "500",
                          }}
                        >
                          Select Option
                        </h3>
                        <div
                          className="row checkform"
                          style={{ marginTop: "10px", marginLeft: "0px" }}
                        >
                          {masterData &&
                            masterData.system_config.pdf_with_watermark_price !==
                            undefined ? (
                            <div key={1} className="form-check">
                              <input
                                type="radio"
                                name="download"
                                className="form-check-input"
                                id={1}
                                onChange={(e) => {
                                  setDownloadType(1);
                                  onOptionChange(
                                    e,
                                    getOptionAmount(
                                      masterData.system_config
                                        .pdf_with_watermark_price.value
                                    )
                                  );
                                }}
                              />
                              <label className="form-check-label" htmlFor={1}>
                                PDF with watermark (Rs.{" "}
                                {getOptionAmount(
                                  masterData.system_config
                                    .pdf_with_watermark_price.value
                                )}
                                )
                              </label>
                            </div>
                          ) : null}

                          {masterData &&
                            masterData.system_config
                              .pdf_without_watermark_price !== undefined ? (
                            <div key={2} className="form-check">
                              <input
                                type="radio"
                                name="download"
                                className="form-check-input"
                                id={2}
                                onChange={(e) => {
                                  setDownloadType(2);
                                  onOptionChange(
                                    e,
                                    getOptionAmount(
                                      masterData.system_config
                                        .pdf_without_watermark_price.value
                                    )
                                  );
                                }}
                              />
                              <label className="form-check-label" htmlFor={2}>
                                PDF without watermark (
                                {getOptionAmount(
                                  masterData.system_config
                                    .pdf_without_watermark_price.value
                                ) == 0
                                  ? "Complimentary for limited period"
                                  : "Rs. " +
                                  getOptionAmount(
                                    masterData.system_config
                                      .pdf_without_watermark_price.value
                                  )}
                                )
                              </label>
                            </div>
                          ) : null}
                        </div>
                        <div className="row mt-2">
                          <div className="col">
                            <div
                              className="alert alert-info mt-2 due-amount-bar"
                              role="alert"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "10px",
                                justifyContent: "center",
                              }}
                            >
                              <div
                                style={{
                                  display: "grid",
                                  gap: "15px",
                                  maxWidth: "300px",
                                  gridTemplateColumns: "repeat(3, 1fr)",
                                  margin: "auto",
                                }}
                                templateColumns="repeat(3, 1fr)"
                                gap={4}
                                maxW={"300px"}
                                margin={"auto"}
                              >
                                <div style={{ gridColumn: "span 2" }}>
                                  <Text
                                    type=""
                                    text="Purchase Amount:"
                                    className="text-start"
                                  />
                                </div>
                                <Text
                                  type=""
                                  text={
                                    CURRENCY +
                                    commonServices.currencyFormatter(amount)
                                  }
                                  className="text-end"
                                />

                                <div style={{ gridColumn: "span 2" }}>
                                  <Text
                                    type=""
                                    text={`Tax(${gstPercent}%):`}
                                    className="text-start"
                                  />
                                </div>
                                <Text
                                  type=""
                                  text={
                                    CURRENCY +
                                    commonServices.currencyFormatter(
                                      (amount * gstPercent) / 100,
                                      false
                                    )
                                  }
                                  className="text-end"
                                />

                                <div style={{ gridColumn: "span 2" }}>
                                  <Text
                                    type=""
                                    text="Payable Amount:"
                                    className="text-start"
                                  />
                                </div>
                                <Text
                                  type=""
                                  text={
                                    CURRENCY +
                                    commonServices.currencyFormatter(
                                      amount * (1 + gstPercent / 100),
                                      false
                                    )
                                  }
                                  className="text-end"
                                />

                                <div style={{ gridColumn: "span 2" }}>
                                  <Text
                                    type=""
                                    text="Rounding Off:"
                                    className="text-start"
                                  />
                                </div>
                                <Text
                                  type=""
                                  text={
                                    CURRENCY +
                                    commonServices.currencyFormatter(
                                      commonServices.removeCommaFromAmount(
                                        amount * (1 + gstPercent / 100),
                                        true
                                      ) -
                                      commonServices.removeCommaFromAmount(
                                        amount * (1 + gstPercent / 100),
                                        false
                                      ),
                                      false
                                    )
                                  }
                                  className="text-end"
                                />

                                <div style={{ gridColumn: "span 2" }}>
                                  <Text
                                    type=""
                                    text="Total Payable Amount:"
                                    className="text-start"
                                  />
                                </div>
                                <Text
                                  type=""
                                  text={
                                    CURRENCY +
                                    commonServices.currencyFormatter(
                                      amount * (1 + gstPercent / 100)
                                    )
                                  }
                                  className="text-end"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="w-100 w-md-50"
                          style={{
                            height: "fit-content",
                            padding: "60px, 0px",
                          }}
                        >
                          <Button
                            buttonType="secondary"
                            style={{ marginRight: "10px" }}
                            onClick={() => setIsDownloading(false)}
                            text="Back"
                          ></Button>
                          <Button
                            buttonType="primary"
                            onClick={() => downloadResume()}
                            text={amount <= 0 ? "Continue" : "Pay Now"}
                          ></Button>
                        </div>
                      </div>
                    )
                  ) : (
                    <div
                      className="w-100 w-md-50 text-center"
                      style={{ height: "fit-content", padding: "300px, 0px" }}
                    >
                      <img
                        className="mb-3"
                        style={{
                          width: "68px",
                          minWidth: "68px",
                          margin: "auto",
                        }}
                        onError={commonServices.imgError}
                        src={congratsImage}
                        alt=""
                      />
                      <p>Thank you for generating a resume with TrueTalent.</p>
                      <span>Click "Download Now" button</span>
                      {validity !== null ? (
                        <p>You can download your resume till {validity}</p>
                      ) : null}

                      <div
                        className="w-100 w-md-50 text-center m-auto"
                        style={{ padding: "50px, 0px" }}
                      >
                        <Button
                          buttonType="primary"
                          onClick={() => {
                            setIsDownloading(false);
                            setShowDownloadResumeLink(false);
                          }}
                          text="Edit Resume"
                        ></Button>
                      </div>
                    </div>
                  )}

                  <div
                    className="w-100 w-md-50"
                    style={{ height: "fit-content", paddingTop: "100px" }}
                    ref={containerRef}
                  >
                    <div
                      style={{
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "space-between",
                        display: "flex",
                      }}
                    >
                      <Button
                        buttonType="secondary"
                        style={{ marginRight: "10px", marginBottom: "10px" }}
                        onClick={() => showTemplates()}
                        text="Change Template"
                      ></Button>

                      {showDownloadResumeLink ? (
                        <>
                          <Button
                            buttonType="primary"
                            style={{ marginBottom: "10px" }}
                            onClick={() => {
                              const fileName =
                                "TT-" +
                                profile.first_name +
                                " " +
                                profile.last_name +
                                "-Resume.pdf";
                              var marginArr = [13, 10, 13, 10];
                              if (template === 1) {
                                marginArr = [13, 10, 12, 10];
                              } else if (template === 2) {
                                marginArr = [12, 10, 13, 10];
                              } else if (template === 3) {
                                marginArr = [11, 10, 12.5, 10];
                              } else {
                                marginArr = [11.5, 10, 12.5, 10];
                              }
                              var opt = {
                                margin: marginArr,
                                filename: fileName,
                                html2canvas: { scale: 2 },
                                jsPDF: {
                                  unit: "mm",
                                  format: "a4",
                                  orientation: "portrait",
                                  margin: "8px",
                                },
                              };
                              var element =
                                document.getElementById("resume-to-print");
                              html2pdf(element, opt);
                            }}
                            text="Download Now"
                          ></Button>
                        </>
                      ) : !isDownloading ? (
                        <>
                          <Button
                            buttonType="primary"
                            style={{ marginBottom: "10px" }}
                            onClick={() => {
                              if (!isLoggedIn() && !otpVerified) {
                                toast.error("Please verify your email");
                                return false;
                              } else {
                                setIsDownloading(true);
                              }
                            }}
                            text="Download Resume"
                          ></Button>
                        </>
                      ) : (
                        <Button
                          className="btn tt-btn__primary d-flex gap-2 align-items-center"
                          buttonType="primary"
                          mb={"10px"}
                          style={{ marginRight: "10px" }}
                          onClick={() => {
                            setShowAIResumeModal(true);
                          }}
                          text={`Generate with`}
                          buttonIcon={<p class="ai-btn px-1"> AI</p>}
                        ></Button>
                      )}
                    </div>
                    <div
                      size="A4"
                      style={{
                        ...styles.page,
                        marginTop: "3px",
                        minHeight: "700px",
                      }}
                      wrap={true}
                    >
                      <div
                        className="page-break"
                        id="resume-to-print"
                        ref={componentRef}
                        wrap={true}
                        style={{
                          backgroundColor: "white",
                          width: "100%",
                          height: "fit-content",
                          minHeight: "700px",
                          position: "relative",
                          marginTop: "-3px",
                          boxSizing: "border-box"
                        }}
                      >
                        {template === 1 ? (
                          <OneTemplate
                            profile={profile}
                            components={components}
                            localStates={localStates}
                            customSections={customSections}
                            downloadType={downloadType}
                            odHeading={odHeading}
                          />
                        ) : template === 2 ? (
                          <SecondTemplate
                            profile={profile}
                            components={components}
                            localStates={localStates}
                            customSections={customSections}
                            downloadType={downloadType}
                            odHeading={odHeading}
                          />
                        ) : template === 3 ? (
                          <ThirdTemplate
                            profile={profile}
                            components={components}
                            localStates={localStates}
                            customSections={customSections}
                            downloadType={downloadType}
                            odHeading={odHeading}
                          />
                        ) : template === 4 ? (
                          <FourTemplate
                            profile={profile}
                            components={components}
                            localStates={localStates}
                            customSections={customSections}
                            downloadType={downloadType}
                            odHeading={odHeading}
                          />
                        ) : // Handle other template cases or provide a default component
                          null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Droppable>
        </MaxWidthContainer>
      </DragDropContext>
    </>
  );
};

const CollapsibleBox = ({ title, description, onClick }) => {
  return (
    <div
      style={{
        borderRadius: "0.25rem",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "#CED4DA",
        cursor: "pointer",
        width: "100%",
        transition: "border-color 0.3s ease",
        overflow: "hidden"
      }}
      onClick={onClick}
    >
      <div className="w-100 d-flex justify-content-between gap-2 p-2 align-items-center">
        <div
          className="d-flex flex-column gap-1"
          display={"flex"}
          flexDirection={"column"}
          gap={"5px"}
        >
          <Text
            type=""
            text={title}
            style={{
              color: "var(--black4)",
              fontSize: "0.9rem",
              textTransform: "capitalize",
              textWrap: "wrap"
            }}
          />

          <Text
            type=""
            text={description}
            style={{ color: "var(--black)", fontSize: "0.7rem", textWrap: "wrap" }}
            fontSize={"xs"}
          />
        </div>

        <LiaAngleDownSolid color="gray" />
      </div>
    </div>
  );
};

const SkillBox = ({
  isCollapsed,
  index,
  onClick,
  setProfile,
  profile,
  item,
  type = "skill",
  autoSaveResume,
}) => {
  const [suggestion, setSuggestion] = useState([]);

  const [skill, setSkill] = useState(
    profile?.userWorkProfileDetail[type] || []
  );

  const onChangeHandlerSkill = (text, key) => {
    if (text) {
      if (text.length > storageConstants.SKILL_THRESHOLD) {
        if (
          skill?.find(
            (data) =>
              data?.skill_name?.toLowerCase() === text?.toLowerCase() &&
              data.skill_name?.length > 0
          )
        ) {
          toast.warn("Skill already added");
          return;
        }
      }
    }

    // setActiveRow(key);
    if (text && text.length > storageConstants.SKILL_THRESHOLD) {
      type == "skill" &&
        commonServices.getSkillByName(text).then((skills) => {
          setSuggestion(skills);
        });
    } else {
      setSuggestion([]);
    }
  };

  useEffect(() => {
    setSkill(profile?.userWorkProfileDetail[type] || []);
  }, [profile?.userWorkProfileDetail[type]]);

  const skillExperienceHandler = (e, type, key) => {
    var tempArray = [...skill];
    if (type === "year") {
      var months = e * 12;
      tempArray[key].experience = tempArray[key].experience
        ? (tempArray[key].experience % 12) + months
        : months;
      setProfile({
        ...profile,
        userWorkProfileDetail: {
          ...profile?.userWorkProfileDetail,
          [type]: tempArray,
        },
      });
    } else if (type === "month") {
      tempArray[key].experience = tempArray[key].experience
        ? Math.trunc(tempArray[key].experience / 12) * 12 + parseInt(e)
        : parseInt(e);
      setProfile({
        ...profile,
        userWorkProfileDetail: {
          ...profile?.userWorkProfileDetail,
          [type]: tempArray,
        },
      });
    }
  };

  return (
    <div
      style={{
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "#CED4DA",
        cursor: "pointer",
        width: "100%",
        transition: "border-color 0.3s ease",
      }}
    >
      <div className="w-100 d-flex justify-content-between p-2 align-items-center">
        {isCollapsed ? (
          <div
            className="d-flex flex-column gap-1 "
            display={"flex"}
            flexDirection={"column"}
            gap={"5px"}
          >
            <Text
              type=""
              text={item?.skill_name}
              style={{
                color: "var(--black4)",
                fontSize: "1rem",
                textTransform: "capitalize",
              }}
              fontWeight={500}
              fontSize={"md"}
            />

            <Text
              type=""
              text={`${item?.experience ? Math.trunc(item.experience / 12) : 0
                } years ${item?.experience ? Math.trunc(item.experience % 12) : 0
                } months`}
              style={{ color: "var(--black2)", fontSize: "0.8rem" }}
              fontSize={"xs"}
            />
          </div>
        ) : (
          <div
            style={{
              display: "grid",
              gap: "15px",
              width: "87%",
              gridTemplateColumns: "repeat(3, 1fr)",
              marginTop: "15px",
            }}
          >
            <div className="position-relative" position={"relative"}>
              <Input
                label={type == "language" ? "Language" : "Skill"}
                type={"text"}
                width={"100%"}
                placeholder={"Skill"}
                value={item?.skill_name}
                style={{ fontSize: "var(--p1) !important" }}
                handleChange={(e) => {
                  setProfile({
                    ...profile,
                    userWorkProfileDetail: {
                      ...profile?.userWorkProfileDetail,
                      [type]: profile?.userWorkProfileDetail[type]?.map(
                        (data, i) =>
                          i === index
                            ? { ...data, skill_name: e.target.value }
                            : data
                      ),
                    },
                  });
                  onChangeHandlerSkill(e.target.value, index);
                }}
              />
              {suggestion && suggestion.length > 0 && (
                <div
                  style={{
                    width: "100%",
                    maxHeight: "200px",
                    position: "absolute",
                    bottom: "-200px",
                    overflow: "scroll",
                    backgroundColor: "#fff",
                    zIndex: 200,
                    borderRadius: "10px",
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderColor: "#CED4DA",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  {suggestion &&
                    suggestion.map((suggestion, i) => (
                      <Text
                        type=""
                        text={suggestion.name}
                        style={{
                          borderBottom: "1px",
                          borderColor: "var(--border-light)",
                          borderStyle: "solid",
                          padding: "10px",
                          fontSize: "var(--p1)",
                          cursor: "pointer",
                        }}
                        key={i}
                        onClick={() => {
                          if (
                            skill.find(
                              (data) => data.skill_id === suggestion?.id
                            )
                          ) {
                            toast.warn("Skill already added");
                            return;
                          } else {
                            setSuggestion([]);
                            var tempArray = [...skill];
                            tempArray[index].skill_name = suggestion.name;
                            tempArray[index].skill_id = suggestion.id;
                            setProfile({
                              ...profile,
                              userWorkProfileDetail: {
                                ...profile?.userWorkProfileDetail,
                                [type]: tempArray,
                              },
                            });
                            autoSaveResume();
                          }
                        }}
                      />
                    ))}
                </div>
              )}
            </div>

            <Dropdown
              className="form-select form-select-md"
              id="year"
              value={item?.experience ? Math.trunc(item.experience / 12) : 0}
              onChange={(e) =>
                skillExperienceHandler(e.target.value, "year", index)
              }
              select={years}
              label={" Exp. (Yrs)"}
              onBlur={() => autoSaveResume()}
              textValue="Year"
            />

            <Dropdown
              className="form-select form-select-lg"
              id="month"
              value={item?.experience ? Math.trunc(item.experience % 12) : 0}
              onChange={(e) =>
                skillExperienceHandler(e.target.value, "month", index)
              }
              select={months}
              label={"Months"}
              textValue="Month"
            />
          </div>
        )}

        {isCollapsed ? (
          <LiaAngleDownSolid size={20} color="gray" onClick={onClick} />
        ) : (
          <>
            <LiaAngleUpSolid size={20} color="gray" onClick={onClick} />
            <span
              onClick={() => {
                setProfile({
                  ...profile,
                  userWorkProfileDetail: {
                    ...profile?.userWorkProfileDetail,
                    [type]: profile?.userWorkProfileDetail[type]?.filter(
                      (data, i) => i !== index
                    ),
                  },
                });
                autoSaveResume();
              }}
            >
              <AiOutlineDelete size={20} color="red" />
            </span>
          </>
        )}
      </div>
    </div>
  );
};

const WebsiteBox = ({
  isCollapsed,
  index,
  onClick,
  setProfile,
  profile,
  item,
  autoSaveResume,
}) => {
  return (
    <div
      style={{
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "#CED4DA",
        cursor: "pointer",
        width: "100%",
        transition: "border-color 0.3s ease",
      }}
    >
      <div className="w-100 d-flex justify-content-between p-2 align-items-center ">
        {isCollapsed ? (
          <div
            className="d-flex flex-column gap-1"
            display={"flex"}
            flexDirection={"column"}
            gap={"5px"}
          >
            <Text
              type=""
              text={item?.name}
              style={{
                color: "var(--black4)",
                fontSize: "1rem",
                textTransform: "capitalize",
              }}
              fontWeight={500}
              fontSize={"md"}
            />

            <Text
              type=""
              text={item?.url}
              style={{ color: "var(--black2)", fontSize: "0.8rem" }}
              fontSize={"xs"}
            />
          </div>
        ) : (
          <div
            style={{
              display: "grid",
              gap: "15px",
              width: "70%",
              gridTemplateColumns: "repeat(2, 1fr)",
              marginTop: "15px",
            }}
          >
            <div className="position-relative" position={"relative"}>
              <Input
                label={"Website Name"}
                type={"text"}
                width={"100%"}
                placeholder={"Name"}
                value={item?.name}
                handleChange={(e) => {

                  setProfile({
                    ...profile,
                    userWorkProfileDetail: {
                      ...profile?.userWorkProfileDetail,
                      website: profile?.userWorkProfileDetail["website"]?.map(
                        (data, i) =>
                          i === index ? { ...data, name: e.target.value.slice(0, 30) } : data
                      ),
                    },
                  });
                  if (e.target.value.length > 30) {
                    warn(MAXIMUM_NUMBER_ALLOWED(30))
                  }
                }}

                maxLength={30}
                onBlur={() => autoSaveResume()}
              />
            </div>

            <Input
              label={"Url"}
              type={"text"}
              width={"100%"}
              placeholder={"website url"}
              value={item?.url}
              handleChange={(e) => {
                setProfile({
                  ...profile,
                  userWorkProfileDetail: {
                    ...profile?.userWorkProfileDetail,
                    website: profile?.userWorkProfileDetail["website"]?.map(
                      (data, i) =>
                        i === index ? { ...data, url: e.target.value } : data
                    ),
                  },
                });
              }
              }
              onBlur={(e) => {
                if (!checkUrl(e.target.value)) {
                  warn(VALID_WEBSITE_URL);
                }
                autoSaveResume()
              }}
            />
          </div>
        )}

        {isCollapsed ? (
          <LiaAngleDownSolid size={20} color="gray" onClick={onClick} />
        ) : (
          <div
            style={{
              display: "flex",
              gap: "10px",
            }}
          >
            <LiaAngleUpSolid size={20} color="gray" onClick={onClick} />
            <AiOutlineDelete
              size={20}
              color="red"
              onClick={() => {
                setProfile({
                  ...profile,
                  userWorkProfileDetail: {
                    ...profile?.userWorkProfileDetail,
                    website: profile?.userWorkProfileDetail["website"]?.filter(
                      (data, i) => i !== index
                    ),
                  },
                });
                autoSaveResume();
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const LanguageBox = ({
  isCollapsed,
  index,
  onClick,
  setProfile,
  profile,
  item,
  autoSaveResume,
}) => {
  return (
    <div
      style={{
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "#CED4DA",
        cursor: "pointer",
        width: "100%",
        transition: "border-color 0.3s ease",
      }}
    >
      <div className="w-100 d-flex justify-content-between p-2 align-items-center">
        {isCollapsed ? (
          <div
            className="d-flex flex-column gap-1"
            display={"flex"}
            flexDirection={"column"}
            gap={"5px"}
          >
            <Text
              type=""
              text={item?.name}
              style={{
                color: "var(--black4)",
                fontSize: "1rem",
                textTransform: "capitalize",
              }}
              fontWeight={500}
              fontSize={"md"}
            />

            <Text
              type=""
              text={item?.experience}
              style={{ color: "var(--black2)", fontSize: "0.8rem" }}
              textTransform={"capitalize"}
              fontSize={"xs"}
            />
          </div>
        ) : (
          <div
            style={{
              display: "grid",
              gap: "15px",
              width: "70%",
              gridTemplateColumns: "repeat(2, 1fr)",
              marginTop: "15px",
            }}
          >
            <div className="position-relative" position={"relative"}>
              <Input
                label={"Language"}
                type={"text"}
                width={"100%"}
                placeholder={"Name"}
                value={item?.name}
                handleChange={(e) => {
                  if (e.target.value.length > 30) {
                    warn(maxLengthAllowed(30))
                  } else {
                    setProfile({
                      ...profile,
                      userWorkProfileDetail: {
                        ...profile?.userWorkProfileDetail,
                        language: profile?.userWorkProfileDetail["language"]?.map(
                          (data, i) =>
                            i === index ? { ...data, name: e.target.value.slice(0, 30) } : data
                        ),
                      },
                    });
                  }
                }}
                onBlur={() => autoSaveResume()}
              />
            </div>

            <Dropdown
              className="form-select form-select-lg"
              id="month"
              value={item?.experience}
              select={[
                {
                  id: "basic",
                  name: "Basic",
                },
                {
                  id: "conversational",
                  name: "Conversational",
                },
                {
                  id: "fluent",
                  name: "Fluent",
                },
                {
                  id: "native",
                  name: "Native",
                },
              ]}
              label={"Fluency"}
              onChange={(e) => {
                setProfile({
                  ...profile,
                  userWorkProfileDetail: {
                    ...profile?.userWorkProfileDetail,
                    language: profile?.userWorkProfileDetail["language"]?.map(
                      (data, i) =>
                        i === index
                          ? { ...data, experience: e.target.value }
                          : data
                    ),
                  },
                });
              }}
              onBlur={() => autoSaveResume()}
            />
          </div>
        )}

        {isCollapsed ? (
          <LiaAngleDownSolid size={20} color="gray" onClick={onClick} />
        ) : (
          <div
            style={{
              display: "flex",
              gap: "10px",
            }}
          >
            <LiaAngleUpSolid size={20} color="gray" onClick={onClick} />
            <AiOutlineDelete
              size={20}
              color="red"
              onClick={() => {
                setProfile({
                  ...profile,
                  userWorkProfileDetail: {
                    ...profile?.userWorkProfileDetail,
                    language: profile?.userWorkProfileDetail[
                      "language"
                    ]?.filter((data, i) => i !== index),
                  },
                });
                autoSaveResume();
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ResumeBuilder;
