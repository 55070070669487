import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import logo from "@/Assets/svgs/logo.svg";

import "./LoggedNavbar.scss";
import * as storageConstants from "@/constants/storageConstants";
import * as commonServices from "@/services/common";
import defaultAvatar from "@/Assets/svgs/default_avatar.svg";
import logoutSvg from "@/Assets/svgs/log-out.svg";
import * as ApiHelper from "@/services/apiHelper";

import messageIcon from "@/Assets/svgs/message.svg";
import {
  isLoggedIn,
  isCandidate,
  isEvaluator,
  isCompany,
  isCandidateProfileIncomplete,
  isPartner, isCompanyAdmin, isPartnerAdmin
} from "@/utils/authUtil";
import CompanyTabs from "./CompanyTabs";
import CandidateTabs from "./CandidateTabs";
import EvaluatorTabs from "./EvaluatorTabs";
import ModalInformation from "../Modals/ModalInformation";
import { StickySearchBar } from "../Common/StickySearchBar";
import Text from "../_widgets/Text/Text";
import CustomTooltip from "../../utils/CustomTooltip";

function LoggedNavbar({
  trigger,
  isHeaderUpdate,
  setIsHeaderUpdate,
  showStickySearchBar,
  setIsStickySearch,
  isStickySearch,
  searchFilterState,
}) {
  const [profile, setProfile] = useState(
    commonServices.getLocalData(storageConstants.PROFILE)
  );
  const [modalInfo, setModalInfo] = useState(false);
  const [intentedRoute, setIntentedRoute] = useState("/");
  const user_role = commonServices.getLocalData(storageConstants.USER_ROLE);
  const full_name = commonServices.getLocalData(
    storageConstants.PROFILE
  )?.full_name;
  const logout = () => {
    ApiHelper.logout([]).then((response) => {
      commonServices.clearLocalStorageData();
      navigate("/?auth=login");
      setIsHeaderUpdate(!isHeaderUpdate);
    });
  };

  const redirectToBecomepro = async () => {
    onNavbarClick("/get-evaluated");
  };

  const handleClick = (e) => {
    const currentClass = document.getElementsByClassName("logged-naving");
    console.log(currentClass);
    for (let i = 0; i < currentClass.length; i++) {
      // currentClass[i]?.classList.remove('active');
    }
    if (e === 0) {
      if (location.pathname === "/chat") {
        try {
          currentClass[2]?.classList.add("active");
        } catch (err) { }
      }
    } else {
      currentClass[e]?.classList.add("active");
    }
  };

  useEffect(() => {
    setProfile(commonServices.getLocalData(storageConstants.PROFILE));
  }, [trigger]);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
                in place of 'smooth' */
    });
  }, [location]);

  const onNavbarClick = (route) => {
    let currentPath = window.location.pathname;
    if (
      currentPath === "/workprofile" &&
      isLoggedIn() &&
      isCandidate() &&
      isCandidateProfileIncomplete()
    ) {
      setIntentedRoute(route);
      setModalInfo(true);
    } else {
      navigate(route);
    }
  };

  const fetchProfile = () => {
    ApiHelper.getProfile().then((response) => {
      
      if (response.isSuccess === true) {
        
        setProfile(response.data);

        commonServices.storeLocalData(storageConstants.PROFILE, response.data);
      }
    });
  }

  const hideInfoModal = () => {
    setModalInfo(false);
  };

  const redirectUser = () => {
    hideInfoModal();
    navigate(intentedRoute);
  };
  const paths = [
    "workprofile",
    "my-account",
    "referral",
    "jobs",
    "gigs",
    "posted",
    "user",
    "edit",
  ];
  const currentLocation = location.pathname;

  return (
    <div className="LoggedNav navbar-div">
      <ModalInformation
        showModal={modalInfo}
        setShowModal={setModalInfo}
        title={"Complete Your Profile for More Job Opportunities!"}
        text={
          "<b>Maximize Visibility</b>: Recruiters are more likely to find and consider profiles with complete information.</br></br><b>Personalized Recommendation</b>: A complete profile increases your chances of finding the perfect job.</br></br><b>Build Credibility</b>: A detailed profile reflects professionalism and builds trust with potential employers."
        }
        buttonOneText={"No"}
        buttonTwoText={"Yes"}
        buttonOneAction={hideInfoModal}
        buttonTwoAction={redirectUser}
        size={"lg"}
      />
      {process.env.REACT_APP_ENV !== "production" ? (
        <div className="ribbon ribbon-top-left">
          <span>{process.env.REACT_APP_ENV}</span>
        </div>
      ) : null}

      <nav
        className="navbar navbar-expand-lg logged-nav-menu-phone navbar-light"
        id="navbar-light"
      >
        <div className="tt-container d-flex justify-content-between">
          <a className="navbar-brand" onClick={() => onNavbarClick("/")}>
            <img onError={commonServices.imgError} src={logo ?? "N/A"} alt="" />
          </a>

          {showStickySearchBar && !isStickySearch ? (
            <div className="my-auto w-full d-block d-lg-none">
              <StickySearchBar
                setIsStickySearch={setIsStickySearch}
                searchFilterState={searchFilterState}
              />
            </div>
          ) : null}

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div
            className="collapse navbar-collapse justify-content-between"
            id="navbarSupportedContent"
          >
            <div className="tt-container w-100 d-flex justify-content-end justify-content-sm-between flex-wrap">
              <ul className="navbar-nav mb-2 flex-wrap mb-lg-0 d-flex flex-row">
                {isEvaluator() ? (
                  <>
                    <li className="nav-item ">
                      <a
                        className={
                          location.pathname === "/evaluator/dashboard"
                            ? "nav-link logged-naving active-green"
                            : "nav-link logged-naving "
                        }
                        data-bs-toggle="collapse"
                        data-bs-target=".navbar-collapse.show"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                        aria-current="page"
                        onClick={() => {
                          onNavbarClick("/evaluator/dashboard");
                        }}
                      >
                        Dashboard
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={
                          location.pathname === "/evaluator/calendar"
                            ? "nav-link logged-naving active-green"
                            : "nav-link logged-naving "
                        }
                        data-bs-toggle="collapse"
                        data-bs-target=".navbar-collapse.show"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                        onClick={() => {
                          onNavbarClick("/evaluator/calendar");
                        }}
                      >
                        Calendar
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={
                          location.pathname === "/evaluator/interviews"
                            ? "nav-link logged-naving active-green"
                            : "nav-link logged-naving "
                        }
                        data-bs-toggle="collapse"
                        data-bs-target=".navbar-collapse.show"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                        onClick={() => {
                          onNavbarClick("/evaluator/interviews");
                        }}
                      >
                        Interviews
                      </a>
                    </li>
                  </>
                ) : (
                  <>
                    <li className="nav-item ">
                      <a
                        className={
                          location.pathname === "/" ||
                            location.pathname.includes("/job/search")
                            ? "nav-link logged-naving active-green"
                            : "nav-link logged-naving "
                        }
                        data-bs-toggle="collapse"
                        data-bs-target=".navbar-collapse.show"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                        aria-current="page"
                        onClick={() => {
                          onNavbarClick("/");
                        }}
                      >
                        Job
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={
                          location.pathname === "/gig" ||
                            location.pathname.includes("/gig/search")
                            ? "nav-link logged-naving active-green"
                            : "nav-link logged-naving "
                        }
                        data-bs-toggle="collapse"
                        data-bs-target=".navbar-collapse.show"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                        onClick={() => {
                          onNavbarClick("/gig");
                        }}
                      >
                        Gig
                      </a>
                    </li>

                    {isCandidate() ? (
                      <li className="nav-item">
                        <a
                          className={
                            location.pathname === "/resume-builder" ||
                              location.pathname.includes("resume-builder") ||
                              location.pathname === "/candidate/resume" ||
                              location.pathname.includes("candidate/resume")
                              ? "nav-link logged-naving active-green"
                              : "nav-link logged-naving "
                          }
                          data-bs-toggle="collapse"
                          data-bs-target=".navbar-collapse.show"
                          aria-controls="navbarSupportedContent"
                          aria-expanded="false"
                          aria-label="Toggle navigation"
                          onClick={() => {
                            onNavbarClick("/resume-builder");
                          }}
                        >
                          Resume Maker
                        </a>
                      </li>
                    ) : null}
                  </>
                )}
              </ul>

              {showStickySearchBar && !isStickySearch ? (
                <div className="my-auto d-none d-lg-flex">
                  <StickySearchBar
                    setIsStickySearch={setIsStickySearch}
                    searchFilterState={searchFilterState}
                  />
                </div>
              ) : null}

              <div className="d-flex flex-row justify-content-between flex-wrap align-items-center">
                <div>
                  <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                    <li className="nav-item float-right right-nav-item">
                      {isCandidate() ? (
                        <div className="d-flex align-items-center">
                          <input
                            className="become_pro_btn"
                            type="submit"
                            value="Get Evaluated"
                            onClick={redirectToBecomepro}
                          />
                        </div>
                      ) : null}

                      <CustomTooltip text="Chat" placement="bottom">
                        <a
                          className={
                            location.pathname === "/chat"
                              ? "nav-link logged-naving msg-nav active-green"
                              : "nav-link logged-naving msg-nav"
                          }
                          data-bs-toggle="collapse"
                          data-bs-target=".navbar-collapse.show"
                          aria-controls="navbarSupportedContent"
                          aria-expanded="false"
                          aria-label="Toggle navigation"
                          aria-current="page"
                          onClick={() => {
                            onNavbarClick("/chat");
                          }}
                        >
                          {" "}
                          <img
                            id="message-icon"
                            src={messageIcon}
                            alt=""
                            style={{ width: "18px", minWidth: "18px", marginRight: "10px" }}
                          />{" "}
                        </a>
                      </CustomTooltip>
                    </li>
                  </ul>
                </div>
                {profile && (
                  <div
                    className={
                      paths.some((string) =>
                        currentLocation.includes(string)
                      ) || currentLocation === "/company-dashboard"
                        ? "profile_icon logged-naving active"
                        : "profile_icon logged-naving"
                    }
                  >
                    <div
                      className="dropdown"
                      onClick={() => {
                        user_role && user_role === "company"
                          ? handleClick(5)
                          : handleClick(4);
                      }}
                    >
                      <a
                        className="dropdown-toggle"
                        role="button"
                        id="dropdownMenuLink"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <div
                          className="round_profile img-border"
                          title={full_name}
                        >
                          {profile.avatar_location ? (
                            <img
                              onError={fetchProfile}
                              src={profile.avatar_location ?? defaultAvatar}
                              alt=""
                            />
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "100%",
                                backgroundColor: "#EDF2F7", // bg: "gray.100"
                                height: "100%",
                              }}
                            >
                              <Text
                                className="mb-0"
                                text={`${full_name?.split(" ")[0]
                                    ? full_name?.split(" ")[0]?.substring(0, 1)
                                    : null
                                  } ${full_name?.split(" ")[1]
                                    ? full_name?.split(" ")[1]?.substring(0, 1)
                                    : null
                                  }`}
                              />
                            </div>
                          )}
                        </div>
                      </a>

                      {/* {full_name} */}

                      <ul
                        className="dropdown-menu "
                        aria-labelledby="dropdownMenuLink"
                      >
                        {isLoggedIn() && (isCompany() || isPartner()) ? (
                          <CompanyTabs />
                        ) : isLoggedIn() && isCandidate() ? (
                          <CandidateTabs onNavbarClick={onNavbarClick} />
                        ) : isLoggedIn() && isEvaluator() ? (
                          <EvaluatorTabs />
                        ) : null}
                        {isCandidate() || isEvaluator() ? (
                          <li>
                            <a
                              className="dropdown-item logged-naving"
                              onClick={() => {
                                navigate("/workprofile");
                              }}
                              data-bs-toggle="collapse"
                              data-bs-target=".navbar-collapse.show"
                              aria-controls="navbarSupportedContent"
                              aria-expanded="false"
                              aria-label="Toggle navigation"
                            >
                              {isCandidate() ? "Work Profile" : "Profile"}
                            </a>{" "}
                          </li>
                        ) : null}
                        {!isPartner() ? (
                          <li>
                            <a
                              className="dropdown-item"
                              data-bs-toggle="collapse"
                              data-bs-target=".navbar-collapse.show"
                              aria-controls="navbarSupportedContent"
                              aria-expanded="false"
                              aria-label="Toggle navigation"
                              aria-current="page"
                              onClick={() => {
                                onNavbarClick("/referrals");
                              }}
                            >
                              Referral
                            </a>
                          </li>
                        ) : null}

                        <li className=""> <a className="dropdown-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={() => { onNavbarClick("/my-account"); }}>My Account</a>{" "} </li>

                        {isCompanyAdmin() || isPartnerAdmin() ? (<li><a className="dropdown-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={() => { isCompany() ? navigate('/company/edit') : navigate('/partner/view') }}>Company Profile</a> </li>) : null}

                        <li> <a className="dropdown-item log-out " onClick={() => { logout();
                            }}
                            data-bs-toggle="collapse"
                            data-bs-target=".navbar-collapse.show"
                            aria-controls="navbarSupportedContent"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                          >
                            Logout
                          </a>{" "}
                          <img
                            onError={commonServices.imgError}
                            src={logoutSvg}
                            alt=""
                            onClick={() => {
                              logout();
                            }}
                          />{" "}
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </nav>
    </div>
  );
}

export default LoggedNavbar;
