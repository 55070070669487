import React from 'react';
import Chart from "../Common/Chart";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import { MaxWidthContainer } from '../Common/MaxWidthContainer';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: var(--green);
  left: calc(50% - 75px);
    position: fixed;
`;

function PartnerDashboardLayout({
    loading,
    color,
    data,
    Chart1Ref,
    Chart2Ref,
    Chart3Ref,
    Chart4Ref,
    Chart5Ref,
    Chart6Ref,
}) {

    return (
        <>
            <MaxWidthContainer className="main-body"> 
                {loading ?
                    <div className='loader'>
                        <ClipLoader color={color} loading={true} css={override} size={150} /> </div> : null}
                <div className="tt-container">
                    <h2 className="mb-2">Dashboard</h2>
                    <div className="mb-3" id="featured-3">
                        <div className="row row-cols-1 mb-2">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className='card mb-2'>
                                    <Chart
                                        chartData={data?.onboarding}
                                        text={data?.onboarding?.title}
                                        chartRef={Chart1Ref}
                                        graphType={data?.onboarding?.graph}
                                        loading={''}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className='card mb-2'>
                                    <Chart
                                        chartData={data?.experience}
                                        text={data?.experience?.title}
                                        chartRef={Chart2Ref}
                                        graphType={data?.experience?.graph}
                                        loading={''}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row row-cols-1 mb-2">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className='card mb-2'>
                                    <Chart
                                        chartData={data?.resume_views}
                                        text={data?.resume_views?.title}
                                        chartRef={Chart3Ref}
                                        graphType={data?.resume_views?.graph}
                                        loading={''}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className='card mb-2'>
                                    <Chart
                                        chartData={data?.resume}
                                        text={data?.resume?.title}
                                        chartRef={Chart4Ref}
                                        graphType={data?.resume?.graph}
                                        loading={''}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row row-cols-1 mb-2">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className='card mb-2'>
                                    <Chart
                                        chartData={data?.job_applications}
                                        text={data?.job_applications?.title}
                                        chartRef={Chart5Ref}
                                        graphType={data?.job_applications?.graph}
                                        loading={''}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className='card mb-2'>
                                    <Chart
                                        chartData={data?.smart_apply}
                                        text={data?.smart_apply?.title}
                                        chartRef={Chart6Ref}
                                        graphType={data?.smart_apply?.graph}
                                        loading={''}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MaxWidthContainer>
        </>
    )
}

export default PartnerDashboardLayout