/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { css } from "@emotion/react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import ClipLoader from "react-spinners/ClipLoader";


const override = css`
  display: block;
  margin: 0 auto;
  border-color: var(--green);
  left: calc(50% - 75px);
  position: fixed;
`;

function ReferralJoin() {

    let [color] = useState("#000000");
    const [loading,] = useState(true);
    const location = useLocation();
    const navigate = useNavigate();


    useEffect(() => {
        let redirect = '/register' + location.search;
        navigate(redirect);
    }, [location?.search]);
    return (<> {
        loading ?
            <div className='loader'>
                <ClipLoader color={color} loading={true} css={override} size={150} /> </div > : null
    }
    </>
    )
}

export default ReferralJoin;