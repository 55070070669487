import React from 'react'
import { ClipLoader } from 'react-spinners';

import { css } from "@emotion/react";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: var(--green);
`;

const Loader = () => {
    return (
        <div className='loader'>
            <ClipLoader color={"#000000"} loading={true} css={override} size={150} />
        </div>
    )
}

export default Loader;