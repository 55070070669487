import React from 'react'
import DatePicker from "react-datepicker";
import * as commonServices from "../../services/common";
import IconResource from '../_widgets/IconResource/IconResource';

const DatePickerWrapper = (props) => {

    const {
        dateRef,
        customInput,
        selected,
        onChange,
        dateFormat = "dd/MM/yyyy",
        showYearDropdown,
        label,
        yearDropdownItemNumber,
        scrollableYearDropdown = false,
        placeholderText = "DD/MM/YYYY" } = props
    return (
        <div className='form-input-group w-100'>
            <label className="input-label" htmlFor="">
                {label}
            </label>
            <div className="input-group">
                <span className="input-group-text" id="basic-addon1"><IconResource color="var(--green)" fontSize={"20px"} onError={commonServices.imgError} type="calendar" /></span>
                <DatePicker
                    ref={dateRef}
                    customInput={customInput}
                    selected={selected}
                    onChange={onChange}
                    dateFormat={dateFormat}
                    showYearDropdown={showYearDropdown}
                    yearDropdownItemNumber={yearDropdownItemNumber}
                    scrollableYearDropdown={scrollableYearDropdown}
                    placeholderText={placeholderText}
                    {...props}
                />
            </div>
        </div>

    )
}

export default DatePickerWrapper