import React from "react";
import "./Modal.scss";
import { getCandidateExperienceText } from "@/services/common";

import AppModal from "./AppModal";

function ModalShowReferralData({
  showModal,
  setShowModal,
  jobData,
  referralData,
  showModalReferral,
  setShowModalReferral,
}) {
  const onSubmit = (e) => {
    setShowModal(!showModal);
    setShowModalReferral(!showModalReferral);
  };

  const isShow = (element) => {
    if (element === "otherRequirements") {
      return (
        referralData !== undefined &&
        referralData?.referral_data !== undefined &&
        referralData?.referral_data !== null &&
        referralData?.referral_data[element] !== ""
      );
    } else {
      return (
        referralData !== undefined &&
        referralData?.referral_data !== undefined &&
        referralData?.referral_data !== null &&
        referralData?.referral_data[element]
      );
    }
  };
  return (
    <>
      <AppModal
        title={"Refer a friend"}
        isOpen={showModal}
        closeModal={() => setShowModal((prev) => !prev)}
        submit={(e) => onSubmit(e)}
        size={"lg"}
        isFooter={true}
      >
        <div className="">
          <div className="modal_body">
            <h5 className="refer-confirm-heading mb-3">
              These are the mandatory criterias for the successful referral for
              this job.
            </h5>
            {isShow("isLocation") ? (
              <div className="row referral-data refer-data-show">
                <div className="col">
                  <div className="row" style={{ marginBottom: "0px" }}>
                    <div className="col check-div">
                      <label className="form-check-label" for="location">
                        Location
                      </label>
                    </div>
                  </div>
                  <div className="row" style={{ marginBottom: "0px" }}>
                    <div className="col">
                      <p>
                        {jobData?.job_locations !== null
                          ? jobData?.job_locations.split(",").join(", ")
                          : ""}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {isShow("isSkill") ? (
              <div className="row referral-data refer-data-show">
                <div className="col">
                  <div className="row" style={{ marginBottom: "0px" }}>
                    <div className="col check-div">
                      <label className="form-check-label" for="skill">
                        Required Skills
                      </label>
                    </div>
                  </div>
                  <div className="row" style={{ marginBottom: "0px" }}>
                    <div className="col">
                      <p>
                        {jobData?.job_required_skills !== null &&
                        jobData?.job_required_skills?.length > 0
                          ? jobData?.job_required_skills
                              ?.toString()
                              ?.split(",")
                              .join(", ")
                          : ""}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {isShow("isExperience") ? (
              <div className="row referral-data refer-data-show">
                <div className="col">
                  <div className="row" style={{ marginBottom: "0px" }}>
                    <div className="col check-div">
                      <label className="form-check-label" for="experience">
                        Experience
                      </label>
                    </div>
                  </div>
                  <div className="row" style={{ marginBottom: "0px" }}>
                    <div className="col">
                      <p>
                        {getCandidateExperienceText(
                          jobData?.minimum_experience_required
                        )}
                        -
                        {getCandidateExperienceText(
                          jobData?.maximum_experience_required
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {isShow("otherRequirements") ? (
              <div className="row referral-data refer-data-show">
                <div className="col">
                  <div className="row" style={{ marginBottom: "0px" }}>
                    <div className="col check-div">
                      <label
                        className="form-check-label"
                        style={{ margin: "0 0 0 0" }}
                      >
                        Other Requirements
                      </label>
                    </div>
                  </div>
                  <div className="row" style={{ marginBottom: "0px" }}>
                    <div className="col">
                      <p>{referralData?.referral_data?.otherRequirements}</p>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {/* <div className="row">
                            <div className="col"></div>
                            <div className="col">
                                <div className="form_buttons">
                                    <button className="btn btn-primary" style={{ marginRight: "10px", outline: "0px" }} onClick={() => setShowModal(prev => !prev)}>Cancel</button>
                                    <input type="submit" value={"Refer Now"} onClick={(e) => onSubmit(e)} />
                                </div>
                            </div>
                        </div> */}
          </div>
        </div>
      </AppModal>

      {/* {showModal ? (
                <>

                    <div className="modal" onClick={() => setShowModal(prev => !prev)}>
                    </div>

                    <div className="modal_content">
                        <div className="modal_top">
                            <div style={{ display: 'flex' }}>
                                <h2 style={{ marginLeft: '5px' }}>Refer a friend</h2>
                            </div>
                            <button onClick={() => setShowModal(prev => !prev)} ><img onError={commonServices.imgError} src="../../Assets/svgs/x.svg" alt="" /></button>
                        </div>
                        <hr />
                        
                    </div>
                </>

            ) : null} */}
    </>
  );
}

export default ModalShowReferralData;
