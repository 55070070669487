import { Mixpanel } from '@/services/mixPanel.js';
import { analyticsConstant } from '../constants/analyticsConstant';
import { PROFILE, USER_ROLE } from '../constants/storageConstants';
import { getLocalData } from "../services/common";

export const KEYS = {
    ID: 'id',
    USER_ROLE: 'User Role',
    USER_NAME: 'User Name',
    APP_ENV: 'App ENV',
    RESPONSE_TIME_MS: 'Response Time (ms)',
    FULL_URL: 'Full URL',
    ORIGIN: 'ORIGIN',
    ENDPOINT: 'ENDPOINT',
    MSG: 'message',
    STATUS: 'Status',
};

const getDefaultParams = () => {
    const userData = getLocalData(PROFILE);
    const userRole = getLocalData(USER_ROLE);

    return {
        [KEYS.ID]: userData?.id || '-',
        [KEYS.USER_NAME]: userData?.name || '-',
        [KEYS.USER_ROLE]: userRole || '-',
        [KEYS.APP_ENV]: process.env.REACT_APP_ENV || '-',
    };
};

export const setUser = (data) => {
    Mixpanel.identify(data.email)
    Mixpanel.people.set({
        id: data?.id,
        first_name: data?.first_name,
        full_name: data?.full_name,
        is_mobile_verified: data?.is_mobile_verified,
        is_profile_completed: data?.is_profile_completed,
        role: data?.role_type,
        email: data?.email
    });
}

export const trackEvent = (eventName, data) => {
    Mixpanel.track(eventName, { ...data });
}

export const trackLogin = (step, data = "") => {

    trackEvent(analyticsConstant.LOGIN + (step ? "_" + step : ""), {
        data: data
    })
}

export const trackCandidateOnboarding = (step, data = "") => {

    trackEvent(analyticsConstant.CANDIDATE_ONBOARDING + (step ? "_" + step : ""), {
        data: data
    })
}

export const trackEvaluatorOnboarding = (step, data = "") => {

    trackEvent(analyticsConstant.EVALUATOR_ONBOARDING + (step ? "_" + step : ""), {
        data: data
    })
}

export const trackCompanyOnboarding = (step, data = "") => {

    trackEvent(analyticsConstant.COMPANY_ONBOARDING + (step ? "_" + step : ""), {
        data: data
    })
}

export const trackJOB = (step, id = "", name = "") => {

    trackEvent(analyticsConstant.JOB + (step ? "_" + step : ""), {
        data: id,
        name: name
    })
}

export const trackGIG = (step, id = "", name = "") => {

    trackEvent(analyticsConstant.GIG + (step ? "_" + step : ""), {
        data: id,
        name: name
    })
}

export const trackAPITime = (time, url, status) => {
    let baseUrl = url;
    let endPoint = url;

    try {
        const parsedUrl = new URL(url);
        endPoint = parsedUrl.pathname.replace('/api', 'api');
        baseUrl = parsedUrl.origin;
    } catch (error) {
        console.error('Error extracting base URL:', error);
    }

    trackEvent(analyticsConstant.API_TIME, {
        ...getDefaultParams(),
        [KEYS.RESPONSE_TIME_MS]: time,
        [KEYS.STATUS]: status,
        [KEYS.FULL_URL]: url,
        [KEYS.ORIGIN]: baseUrl,
        [KEYS.ENDPOINT]: endPoint,
    });
};

