import React from "react";
import Text from "../../_widgets/Text/Text";
import logo from "@/Assets/images/watermark.png";
import "./oneTemplate.scss";
import * as commonServices from "@/services/common";

// Create styles
const styles = {
  page: {
    flexDirection: "row",
    backgroundColor: "#656E83",
    padding: "10px",
    // paddingTop: "0px",
    // height: "100vh",
    // minHeight: "100vh",
    height: "100%",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  textHeading: {
    fontSize: "15px",
    fontWeight: 700,
    color: "#000000",
    textDecoration: "underline",
    wordBreak: "break-all"
  },
  textHeading3: {
    fontSize: "15px",
    fontWeight: 700,
    color: "#000000",
    marginBottom: 10,
    wordBreak: "break-all"
  },
  textHeading2: {
    fontSize: "11px",
    fontWeight: 700,
    color: "#000000",
    // textDecoration: "underline",
    wordBreak: "break-all"
  },
  textPara: {
    fontSize: "10px",
    marginTop: "5px",
    fontWeight: 400,
    color: "#000000B2",
    wordBreak: "break-all"
  },
  listItem: {
    marginTop: "10px",
    flexDirection: "row",
    marginBottom: 5,
    alignItems: "flex-start",
  },
  dot: {
    width: 5,
    height: 5,
    backgroundColor: "black",
    borderRadius: "50%",
    marginRight: 5,
  },
  listPara: {
    marginTop: "-2px",
    fontSize: "9px",
    fontWeight: 400,
    color: "#000000B2",
    textWrap: "wrap",
    wordBreak: "break-all"
  },
};

const OneTemplate = ({
  profile,
  components,
  localStates,
  customSections,
  downloadType,
  odHeading,
}) => {
  return (
    <div
      wrap={true}
      className="oneTemplate"
      style={{
        backgroundColor: "white",
        width: "100%",
        height: "fit-content",
        borderRadius: "5px",
        padding: "2rem",
        minHeight: "700px",
        position: "relative",
        fontFamily: "Plus Jakarta Sans",
      }}
    >
      {downloadType === 1 || downloadType === null ? (
        <div
          style={{
            position: "absolute",
            justifyContent: "center",
            top: "10px",
            right: "10px",
            display: "flex",
            gap: "5px",
            alignItems: "center",
          }}
        >
          <img
            height="18px"
            onError={commonServices.imgError}
            src={logo ?? "N/A"}
            alt="/Assets/images/watermark.png"
          />
        </div>
      ) : null}
      <Text
        text={
          (profile?.first_name || profile?.last_name)
            ? `${profile?.first_name || ''} ${profile?.last_name || ''}`
            : ''
        }
        style={{
          fontSize: "28px",
          fontWeight: 700,
          marginTop: "10px",
        }}
      />
      <Text
        text={profile?.designation}
        style={{
          fontSize: "14px",
          fontWeight: 400,
          color: "#000000B3",
          marginTop: "5px",
        }}
      />

      <div
        style={{
          margin: "20px 0 0 0",
          height: "2px",
          backgroundColor: "#00000026",
        }}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div
          style={{
            width: "40%",
            height: "100%",
            minHeight: "100%",
            borderColor: "#00000026",
          }}
        >
          <div style={{ marginTop: "10px" }}>
            {profile?.email && <Text text="Personal Details" style={styles.textHeading3} />}
            {profile.contact ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0px",
                  marginTop: "10px",
                }}
              >
                <Text text="Phone" style={styles.textHeading2} />
                <Text text={profile.contact} style={styles.textPara} />
              </div>
            ) : null}
            {profile.email ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0px",
                  marginTop: "10px",
                }}
              >
                <Text text="Email" style={styles.textHeading2} />
                <Text text={profile.email} style={styles.textPara} />
              </div>
            ) : null}
          </div>

          {components.map((component, index) => {
            switch (component.component) {
              case "websites_social_links":
                return profile?.userWorkProfileDetail?.website?.length > 0 ? (
                  <div style={{ marginTop: "30px" }}>
                    <Text
                      text="Websites & Social Links"
                      style={styles.textHeading}
                    />

                    {profile?.userWorkProfileDetail?.website?.map(
                      (item, index) => (
                        <Text
                          text={`${item?.name} - ${item?.url}`}
                          style={{ ...styles.textPara, marginTop: "10px" }}
                        />
                      )
                    )}
                  </div>
                ) : (
                  <></>
                );

              case "skills":
                return profile?.userWorkProfileDetail?.skill !== undefined &&
                  profile?.userWorkProfileDetail?.skill.length > 0 ? (
                  <div style={{ marginTop: "30px" }}>

                    <Text text="SKILLS" style={styles.textHeading} />
                    {profile?.userWorkProfileDetail?.skill?.map(
                      (item, index) => (
                        <div
                          style={{
                            marginTop: "10px",
                            marginRight: "10px",
                          }}
                        >
                          <Text
                            text={item?.skill_name}
                            style={{ ...styles.textPara, marginTop: "10px" }}
                          />

                          {!localStates?.showExperienceLevel ? (
                            <div
                              style={{
                                height: "5px",
                                marginTop: "10px",
                                width: "100%",
                                backgroundColor: "#D9D9D9",
                              }}
                            >
                              <div
                                style={{
                                  width: `${item?.experience >=
                                    profile?.user_work_profile?.total_experience
                                    ? "100%"
                                    : (item?.experience /
                                      profile.user_work_profile
                                        .total_experience) *
                                    100 +
                                    "%"
                                    }`,

                                  height: "5px",
                                  backgroundColor: "black",
                                }}
                              ></div>
                            </div>
                          ) : null}
                        </div>
                      )
                    )}
                  </div>
                ) : null;

              case "languages":
                return (
                  customSections.languages && (
                    <>
                      {profile?.userWorkProfileDetail?.language?.length > 0 && (
                        <div style={{ marginTop: "30px" }}>
                          <Text text="LANGUAGES" style={styles.textHeading} />

                          {profile?.userWorkProfileDetail?.language?.map(
                            (item, index) => (
                              <div
                                style={{
                                  marginTop: "10px",
                                  marginRight: "10px",
                                }}
                              >
                                <Text
                                  text={item?.name}
                                  style={{
                                    ...styles.textPara,
                                    marginTop: "10px",
                                  }}
                                />

                                {
                                  <div
                                    style={{
                                      height: "5px",
                                      marginTop: "10px",
                                      width: "100%",
                                      backgroundColor: "#D9D9D9",
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: `${item?.experience === "basic"
                                          ? "25%"
                                          : item?.experience ===
                                            "conversational"
                                            ? "50%"
                                            : item?.experience === "fluent"
                                            ? "75%"
                                            : item?.experience === "native" ?  
                                            "100%"
                                            : "25%"
                                        }`,

                                        height: "5px",
                                        backgroundColor: "black",
                                      }}
                                    ></div>
                                  </div>
                                }
                              </div>
                            )
                          )}
                        </div>
                      )}
                    </>
                  )
                );

              case "hobbies":
                return (
                  customSections.hobbies && (
                    <>
                      {profile?.hobbies?.length > 0 && (
                        <div wrap={false} style={{ marginTop: "30px" }}>
                          <Text text="HOBBIES" style={styles.textHeading} />

                          <Text
                            text={profile?.hobbies || ""}
                            style={styles.textPara}
                          />
                        </div>
                      )}
                    </>
                  )
                );
              case "references":
                return (
                  customSections.references && (
                    <>
                      {profile?.reference?.length > 0 && (
                        <div style={{ marginTop: "30px" }}>
                          <Text text="REFERENCES" style={styles.textHeading} />
                          <Text
                            text={profile?.reference || ""}
                            style={styles.textPara}
                          />
                        </div>
                      )}
                    </>
                  )
                );
              case "achievements":
                return (
                  customSections.achievements && (
                    <>
                      {profile?.achievements?.length > 0 && (
                        <div style={{ marginTop: "30px" }}>
                          <Text
                            text="ACHIEVEMENTS"
                            style={styles.textHeading}
                          />
                          <Text
                            text={profile?.achievements || ""}
                            style={styles.textPara}
                          />
                        </div>
                      )}
                    </>
                  )
                );
              case "details":
                return (
                  customSections.details && (
                    <>
                      {profile[odHeading]?.length > 0 && (
                        <div style={{ marginTop: "30px" }}>
                          <Text text={odHeading} style={styles.textHeading} />
                          <Text
                            text={profile[odHeading] || ""}
                            style={styles.textPara}
                          />
                        </div>
                      )}
                    </>
                  )
                );
              case "extra":
                return (
                  customSections.extra && (
                    <>
                      {profile?.extra_curricular_activity?.length > 0 && (
                        <div style={{ marginTop: "30px" }}>
                          <Text
                            text="EXTRA-CURRICULAR ACTIVITIES"
                            style={styles.textHeading}
                          />
                          <Text
                            text={profile?.extra_curricular_activity || ""}
                            style={styles.textPara}
                          />
                        </div>
                      )}
                    </>
                  )
                );
            }
          })}
        </div>

        <div
          style={{
            width: "60%",
            borderLeft: "1px solid #00000026",
            padding: "0 0 0 20px",
          }}
        >
          {components.map((component, index) => {
            switch (component.component) {
              case "professional_summary":
                return profile.description ? (
                  <div wrap={false} style={{ marginTop: "10px" }}>
                    <Text
                      className="avoid-page-break"
                      text="PROFILE"
                      style={{
                        ...styles.textHeading,
                        pageBreakInside: "avoid",
                      }}
                    />

                    <div
                      className="avoid-page-break"
                      style={{
                        ...styles.textPara,
                        marginTop: "20px",
                        marginBottom: "30px",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: profile.description || "",
                      }}
                    />
                  </div>
                ) : null;

              case "employment":
                return profile?.userWorkProfileDetail?.experience !==
                  undefined &&
                  profile?.userWorkProfileDetail?.experience.length > 0 ? (
                  <div wrap={false} style={{ marginTop: "30px" }}>
                    <Text text="EXPERIENCE" style={styles.textHeading} />

                    {profile?.userWorkProfileDetail?.experience?.map(
                      (item, index) => (
                        <div style={{ marginTop: "20px" }}>
                          <Text
                            text={item?.awarded_by}
                            style={styles.textHeading2}
                          />
                          <Text text={item?.title} style={styles.textPara} />
                          <Text
                            text={`${item?.from_date} - ${item?.is_present === "1"
                              ? "Present"
                              : item?.to_date
                              }`}
                            style={styles.textPara}
                          />

                          <div style={styles.listItem}>
                            <Text
                              text={item?.description || ""}
                              style={styles.listPara}
                            />
                          </div>
                        </div>
                      )
                    )}
                  </div>
                ) : null;

              case "education":
                return profile?.userWorkProfileDetail?.degree !== undefined &&
                  profile?.userWorkProfileDetail?.degree.length > 0 ? (
                  <div wrap={false} style={{ marginTop: "30px" }}>
                    <Text text="EDUCATION" style={styles.textHeading} />

                    {profile?.userWorkProfileDetail?.degree?.map(
                      (item, index) => (
                        <div style={{ marginTop: "10px" }}>
                          <Text
                            text={item.awarded_by}
                            style={styles.textHeading2}
                          />
                          <Text text={item?.title} style={styles.textPara} />
                          <Text
                            text={`${item?.from_date} - ${item?.is_present === "1"
                              ? "Present"
                              : item?.to_date
                              }`}
                            style={styles.textPara}
                          />
                        </div>
                      )
                    )}
                  </div>
                ) : null;

              case "internships":
                return (
                  customSections?.internships && (
                    <>
                      {profile?.userWorkProfileDetail?.internship !==
                        undefined &&
                        profile?.userWorkProfileDetail?.internship.length > 0 ? (
                        <div wrap={false} style={{ marginTop: "30px" }}>
                          <Text text="INTERNSHIPS" style={styles.textHeading} />

                          {profile?.userWorkProfileDetail?.internship?.map(
                            (item, index) => (
                              <div style={{ marginTop: "20px" }}>
                                <Text
                                  text={item?.awarded_by}
                                  style={styles.textHeading2}
                                />
                                <Text
                                  text={item?.title}
                                  style={styles.textPara}
                                />
                                <Text
                                  text={`${item?.from_date} - ${item?.is_present === "1"
                                    ? "Present"
                                    : item?.to_date
                                    }`}
                                  style={styles.textPara}
                                />

                                <div style={styles.listItem}>
                                  <Text
                                    text={item.description || ""}
                                    style={styles.listPara}
                                  />
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      ) : null}
                    </>
                  )
                );

              case "courses":
                return (
                  <>
                    {" "}
                    {customSections.courses &&
                      profile?.userWorkProfileDetail?.course !== undefined &&
                      profile?.userWorkProfileDetail?.course.length > 0 ? (
                      <div wrap={false} style={{ marginTop: "30px" }}>
                        <Text text="COURSE" style={styles.textHeading} />

                        {profile?.userWorkProfileDetail?.course?.map(
                          (item, index) => (
                            <div style={{ marginTop: "10px" }}>
                              <Text
                                text={item.awarded_by}
                                style={styles.textHeading2}
                              />
                              <Text text={item.title} style={styles.textPara} />
                              <Text
                                text={`${item.from_date} - ${item.is_present === "1"
                                  ? "Present"
                                  : item.to_date
                                  }`}
                                style={styles.textPara}
                              />
                            </div>
                          )
                        )}
                      </div>
                    ) : null}{" "}
                  </>
                );
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default OneTemplate;
