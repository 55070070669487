import React from "react";
import "./Modal.scss";
import { useNavigate } from "react-router-dom";
import fireworkimage from "@/Assets/images/firework.png";
import AppModal from "./AppModal";
import Button from "../_widgets/Button/Button";

function ModalJobReferral({ showModal, setShowModal, id }) {
  const navigate = useNavigate();

  return (
    <AppModal
      title={"Get Referrals"}
      isOpen={showModal}
      size={"md"}
      closeModal={() => {
        setShowModal((prev) => !prev);
        navigate("/posted");
      }}
    >
      <div className="modal_body py-1">
        <div className="row refer-success my-1 job-referrals">
          <img src={fireworkimage} alt="success" />
          <p className="my-3">Job posted successfully!</p>
          <span className="mb-3">
            We have just sent the referral invite Successfully!
          </span>
          <span className="mb-3 boost-job">
            You can now boost your job post by attracting{" "}
            <span className="green-text">global referrals!!</span>
          </span>
          <div className="col-2"></div>
          <div className="col-12">
            <div className="d-flex align-items-center justify-content-center mt-2">
              <Button
                text="No, Thanks"
                buttonType="secondary"
                style={{ marginRight: "10px" }}
                onClick={() => navigate("/posted")}
              />

              <Button
                text="Get Referrals"
                buttonType="primary"
                onClick={() => {
                  navigate("/job/boost/" + id);
                }}
              />
            </div>
          </div>
          <div className="col-2"></div>
        </div>
      </div>
    </AppModal>
  );
}

export default ModalJobReferral;
