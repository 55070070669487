/* eslint-disable */
import React from "react";
import "./Register.scss";
import uploadIcon from "../../Assets/images/uploadIcon.png";
import arrowLeft from '../../Assets/svgs/arrow-left.svg';
import { useNavigate } from "react-router-dom";
import { ClearFileValues } from "../../utils/fileUtils";
import Button from "../../components/_widgets/Button/Button";
import IconResource from "../_widgets/IconResource/IconResource";



export default function ResumeUpload({ CurrentStep, profile, setProfile, selectedProfile, setSelectedProfile, getGoogleLogin, setRegisterSection, setTncIsOpen }) {

    const proceedToNextStep = () => {
        // setCurrentStep(1);
        setTncIsOpen(true);
    }

    const navigate = useNavigate();

    const onChange = (e) => {
        let file = e.target.files[0];
        let fileName = e.target.files[0].name;
        let tempProfile = { ...profile };
        tempProfile.resume_file = file;
        tempProfile.resume_file_name = fileName;
        setProfile(tempProfile)
        ClearFileValues();
        proceedToNextStep();
    }

    const back = () => {
        navigate("/?auth=register")
        // setCurrentStep(0);
        // setRegisterSection('select_profile');
        // setSelectedProfile('');
    }

    return (

        <div className="resume-upload-component h-100">
            <div className="select_profile upload-resume gap-5 h-100">
                <div className="resume-upload-component-header">
                    <a className='hand-hover' href="javascript:void(0)" onClick={() => back()}> <img className='back-arrow' src={arrowLeft} alt="icon" ></img></a>
                    <h2 className="text-center">
                        Upload Your Resume
                    </h2>
                </div>
                <div className="resume-upload-component-body">
                    <p>
                        Upload your resume and we will generate a work profile for you
                    </p>
                    <div class="file-upload-box">
                        <div className="file-upload-icon">
                            <img src={uploadIcon} alt="upload" />
                        </div>
                        <p className="file-upload-text"><strong className="tt-text-primary">Click to upload</strong> or drag and drop .doc, .docx, .pdf</p>
                        <input accept="image/*, .doc, .pdf, .docx, .ppt, .pptx" type="file" id="input-file-now" class="file-upload-input    " onChange={(e) => onChange(e)} />

                    </div>
                    <p className="text-center d-flex align-items-center gap-2">
                        I don’t have a resume
                        <Button buttonType="text" text="Create TrueTalent Profile" onClick={proceedToNextStep} />
                    </p>
                    {selectedProfile === 'candidate' ?
                        // <>
                        //     <div class="or-line mt-3">OR</div>
                        //     <Button
                        //     className="d-flex align-items-center w-100 justify-content-center py-2 btn big tt-btn__light flex-row-reverse"
                        //     buttonType="light"
                        //     text={`Sign in with Google`}
                        //     buttonIcon={<IconResource className="me-2" fontSize="24px" type={"google"} />}
                        //     onClick={(e) => {
                        //         getGoogleLogin(e)
                        //         trackLogin("GOOGLE_LOGIN")
                        //     }}
                        // />
                        <>
                        </>
                        : null}

                </div>
            </div>
        </div>
    )
}