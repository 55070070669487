/* eslint-disable */
import React, { useState, useEffect } from 'react'
import './Navbar.scss'
import { useNavigate, useSearchParams } from "react-router-dom";
import logo from "@/Assets/svgs/logo.svg"
import * as commonServices from '@/services/common';
import { useLocation } from 'react-router-dom';
import { addNavShadow } from '@/utils/styleUtils';
import { StickySearchBar } from '../Common/StickySearchBar';
import AuthPopup from '../Auth/AuthContainer';
import { trackLogin } from '../../helpers/analytics';
import { getLocalData } from '../../services/common';
import { AUTH } from '../../constants/storageConstants';


function Navbar({ activeNav, registerReset, setRegisterReset, showStickySearchBar, setIsStickySearch, isStickySearch, searchFilterState, isHeaderUpdate, setIsHeaderUpdate }) {
  const navigate = useNavigate();



  const location = useLocation();

  const handleClick = (e) => {
    // const currentClass = document.getElementsByClassName('naving');
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    addNavShadow();
  }, [location]);

  useEffect(() => {
    if (location.pathname === '/login') {
      const currentClass = document.getElementsByClassName('navingmob');
      for (let i = 0; i < currentClass.length; i++) {
        currentClass[i]?.classList?.remove('active');
      }
      currentClass[0]?.classList?.add('active');
    } else if (location.pathname === '/register') {
      const currentClass = document.getElementsByClassName('naving');
      for (let i = 0; i < currentClass.length; i++) {
        currentClass[i]?.classList?.remove('active');
      }
      currentClass[0]?.classList?.add('active');
    }

  }, [])

  useEffect(() => {
    if (activeNav === 'login') {
      const currentClass = document.getElementsByClassName('navingmob');
      for (let i = 0; i < currentClass.length; i++) {
        currentClass[i]?.classList?.remove('active');
      }
      currentClass[0]?.classList?.add('active');
    } else if (activeNav === 'register') {
      const currentClass = document.getElementsByClassName('naving');
      for (let i = 0; i < currentClass.length; i++) {
        currentClass[i]?.classList?.remove('active');
      }
      currentClass[1]?.classList?.add('active');
    }
  }, [activeNav])



  const handleClicklog = (e) => {

    const currentClass = document.getElementsByClassName('navingmob');
    for (let i = 0; i < currentClass.length; i++) {
      currentClass[i]?.classList?.remove('active');
    }
    currentClass[e]?.classList?.add('active');
  }


  const [authType, setAuthType] = useState('login');

  const [isOpen, setIsOpen] = useState(false);

  const onOpen = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
    navigate('/', { state: { auth: getLocalData(AUTH) || null } });
  };


  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get('auth') !== null) {
      if (searchParams.get('auth') === 'login') {
        setAuthType('login');
      } else if (searchParams.get('auth') === 'register') {
        setAuthType('register');
      }
      else if (searchParams.get('auth') === 'forgot') {
        setAuthType('forgot');
      }
      onOpen();
    }
  }, [searchParams]);

  const changeParams = (type) => {
    setSearchParams({ "auth": type })
  }


  return (
    <div className='navbar-div'>

      <AuthPopup type={authType} isOpen={isOpen} onOpen={onOpen} onClose={onClose} isHeaderUpdate={isHeaderUpdate} setIsHeaderUpdate={setIsHeaderUpdate} />


      {process.env.REACT_APP_ENV !== 'production' ?
        <div class="ribbon ribbon-top-left"><span>{process.env.REACT_APP_ENV}</span></div>
        : null}

      <nav className="navbar navbar-expand-lg navbar-light " id="navbar-light">
        <div className="tt-container d-flex">
          <div className='d-flex justify-content-between nav-logo-div '>

            <a className="navbar-brand" href={undefined} onClick={() => { navigate('/'); handleClick(0) }}><img onError={commonServices.imgError} src={logo ?? 'N/A'} alt="/Assets/svgs/logo.svg" /></a>

            {showStickySearchBar && !isStickySearch ?
              <div className='my-auto w-full d-lg-none d-block '>
                <StickySearchBar setIsStickySearch={setIsStickySearch} searchFilterState={searchFilterState} />
              </div>
              : null}

            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>
          <div className="collapse navbar-collapse " id="navbarSupportedContent">
            <div className='tt-container px-md-0 d-flex flex-row  w-100 justify-content-between align-items-center flex-wrap'>
              <ul className="navbar-nav mb-2 mb-lg-0 d-flex flex-row">
                <li className="nav-item">

                  <a title='Here you get to see full time jobs'
                    className={(location.pathname === "/"
                      || location.pathname === ("/job/search")
                    ) ? "nav-link logged-naving active-green" : "nav-link logged-naving "}
                    data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"
                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" aria-current="page" onClick={() => { navigate('/'); handleClick(0) }}>Job</a>
                </li>
                {/* TT Gig */}
                <li className="nav-item">
                  <a title='Here you can find freelance jobs' className={location.pathname === "/gig" || location.pathname.includes("gig") ? "nav-link logged-naving active-green" : "nav-link logged-naving "} data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"
                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={() => { navigate('/gig'); handleClick(1); }}>Gig</a>
                </li>
                <li className="nav-item">
                  <a
                    className={
                      location.pathname === "/resume-builder" ||
                        location.pathname.includes("resume-builder") ||
                        location.pathname === "/candidate/resume" ||
                        location.pathname.includes("candidate/resume")
                        ? "nav-link logged-naving active-green"
                        : "nav-link logged-naving "
                    }
                    data-bs-toggle="collapse"
                    data-bs-target=".navbar-collapse.show"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    onClick={() => {
                      navigate("/resume-builder");
                      handleClick(2);
                    }}
                  >
                    Resume Maker
                  </a>
                </li>
              </ul>

              {showStickySearchBar && !isStickySearch ?
                <div className='my-auto w-full d-none d-lg-block'>
                  <StickySearchBar setIsStickySearch={setIsStickySearch} searchFilterState={searchFilterState} />
                </div>
                : null}

              <div
              // className='ms-auto'
              >
                <button className="btn btn-login navingmob" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"
                  aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" type="submit" onClick={() => {
                    // changeLogin();
                    handleClicklog(0)
                    setAuthType('login');
                    changeParams('login');
                    onOpen();
                    trackLogin("START")
                  }}>Login</button>
                <button reloadDocument className="btn btn-register navingmob active" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"
                  aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" type="submit" onClick={() => {
                    if (registerReset !== undefined && setRegisterReset !== undefined) {
                      setRegisterReset(!registerReset);
                    }
                    //  navigate('/register'); 
                    handleClicklog(1)
                    setAuthType('register');
                    changeParams('register');
                    onOpen();
                  }}>Register</button>

              </div>
            </div>
          </div>




          {/* </div> */}
        </div>
      </nav>



    </div>
  )
}

export default Navbar