import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

export default function EvaluatorTabs() {
    const navigate = useNavigate();
    return (
        <>
            {/* <li><a className="dropdown-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" aria-current="page" onClick={() => { navigate('/jobs') }}>Dashboard</a>
            </li>

            <li><a className="dropdown-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" aria-current="page" onClick={() => { navigate('/gigs') }}>Calendar</a></li>

            <li><a className="dropdown-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" aria-current="page" onClick={() => { navigate('/gigs') }}>Requests</a></li> */}

        </>
    )
}