import { useCallback, useEffect, useState, useRef } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import "./multiRangeSlider.scss";
import { addSearchParamsInHistory, removeSearchParamsInHistoryWithoutValue } from "../../../utils/redirectUtil";

const TotalExpSlider = ({ min, max, onChange, resetSlider, defaultValue }) => {
    const [minVal, setMinVal] = useState(min);
    const [maxVal, setMaxVal] = useState(max);
    const [isTrigger, setIsTrigger] = useState(false);
    const minValRef = useRef(null);
    const maxValRef = useRef(null);
    const range = useRef(null);
    const [sliderMinVal, setSliderMinVal] = useState(minVal);
    const [sliderMaxVal, setSliderMaxVal] = useState(maxVal);

    // Convert to percentage
    const getPercent = useCallback(
        (value) => Math.round(((value - min) / (max - min)) * 100),
        [min, max]
    );

    // Set width of the range to decrease from the left side
    useEffect(() => {
        if (maxValRef.current) {
            const minPercent = getPercent(minVal);
            const maxPercent = getPercent(+maxValRef.current.value); // Preceding with '+' converts the value from type string to type number

            if (range.current) {
                range.current.style.left = `${minPercent}%`;
                range.current.style.width = `${maxPercent - minPercent}%`;
            }
        }
    }, [minVal, getPercent]);

    // Set width of the range to decrease from the right side
    useEffect(() => {
        if (minValRef.current) {
            const minPercent = getPercent(+minValRef.current.value);
            const maxPercent = getPercent(maxVal);

            if (range.current) {
                range.current.style.width = `${maxPercent - minPercent}%`;
            }
        }
    }, [maxVal, getPercent]);

    // Get min and max values when their state changes
    useEffect(() => {
        onChange({ min: Math.round(minVal), max: Math.round(maxVal) });
    }, [isTrigger]);

    // Set default values if provided
    useEffect(() => {
        if (defaultValue) {
            setMinVal(defaultValue.min);
            setMaxVal(defaultValue.max);
            setSliderMinVal(defaultValue.min);
            setSliderMaxVal(defaultValue.max);
        }
    }, [resetSlider]);

    // Function to handle click or touch on the slider
    const handleSliderClick = (event) => {
        const boundingRect = event.currentTarget.getBoundingClientRect();
        const offsetX = event.clientX - boundingRect.left;
        const percentage = (offsetX / boundingRect.width) * 100;

        // Calculate values for both sliders
        const minSliderValue = Math.round(min + (percentage * (max - min)) / 100);
        const maxSliderValue = Math.round(min + (percentage * (max - min)) / 100);

        // Ensure the values stay within the valid range
        const clampedMinValue = Math.max(min, Math.min(minSliderValue, max - 1));
        const clampedMaxValue = Math.max(min + 1, Math.min(maxSliderValue, max));

        // Determine which slider is closer to the clicked/touched position
        const distanceToMin = Math.abs(clampedMinValue - minVal);
        const distanceToMax = Math.abs(clampedMaxValue - maxVal);

        // Adjust the value of the closer slider
        if (distanceToMin < distanceToMax) {
            setMinVal(clampedMinValue);
            setSliderMinVal(clampedMinValue);
            clampedMinValue !== min ? addSearchParamsInHistory('min_exp', clampedMinValue) : removeSearchParamsInHistoryWithoutValue('min_exp');
        } else {
            setMaxVal(clampedMaxValue);
            setSliderMaxVal(clampedMaxValue);
            clampedMaxValue !== max ? addSearchParamsInHistory('max_exp', clampedMaxValue) : removeSearchParamsInHistoryWithoutValue('max_exp');
        }

        setIsTrigger(!isTrigger);
    };

    return (
        <>
            <div className="multiRangeSlider" onClick={handleSliderClick}>
                <input
                    type="range"
                    min={min}
                    max={max}
                    value={minVal}
                    ref={minValRef}
                    onChange={(event) => {
                        const value = Math.min(+event.target.value, maxVal - 1);
                        setMinVal(value);
                        setSliderMinVal(value);
                        event.target.value = value.toString();
                    }}
                    onMouseUp={() => {
                        setIsTrigger(!isTrigger);
                        minVal !== min ? addSearchParamsInHistory('min_exp', minVal) : removeSearchParamsInHistoryWithoutValue('min_exp');
                    }}
                    className={classnames("thumb thumb--zindex-3", {
                        "thumb--zindex-5": minVal > max - 100
                    })}
                />
                <input
                    type="range"
                    min={min}
                    max={max}
                    value={maxVal}
                    ref={maxValRef}
                    onChange={(event) => {
                        const value = Math.max(+event.target.value, minVal + 1);
                        setMaxVal(value);
                        setSliderMaxVal(value);
                        event.target.value = value.toString();
                    }}
                    onMouseUp={() => {
                        setIsTrigger(!isTrigger);
                        maxVal !== max ? addSearchParamsInHistory('max_exp', maxVal) : removeSearchParamsInHistoryWithoutValue('max_exp');
                    }}
                    className="thumb thumb--zindex-4"
                />

                <div className="slider_">
                    <div className="slider__track" />
                    <div ref={range} className="slider__range" />
                </div>
            </div>
            <div className="range_label">
                <div className="left_value">{sliderMinVal} years</div>
                <div className="right_value">{sliderMaxVal !== 20 ? sliderMaxVal : "20+"} years</div>
            </div>
        </>
    );
};

TotalExpSlider.propTypes = {
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired
};

export default TotalExpSlider;
