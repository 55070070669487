/* eslint-disable */
import React, { useState, useEffect } from 'react';
import Table from '../../_widgets/Table/Table';
import { useNavigate } from 'react-router-dom';
import { isCompany } from '../../../utils/authUtil';
import Dropdown from '../../_widgets/Dropdown/Dropdown';
import Button from '../../_widgets/Button/Button';

function JobReferrals({ jobUsers, activeJobPage, handlePaginationChange, jobs, setActiveJobIndex, activeJobIndex, setJobData }) {

    const navigate = useNavigate();
    var tableColumns = ['Program Name', 'Candidate ID', 'Name', 'Email', 'Phone Number', 'Candidate Status', 'Referred on'];
    const [tableData, setTableData] = useState([]);
    const [dropdownOptions, setDropdownOptions] = useState([]);
    useEffect(() => {

        var row = [];
        var row = jobUsers?.data && jobUsers?.data.map((user, i) => {
            var data = [];
            data.push(user?.referral.program_name);
            data.push(user?.referred_to_user !== null ? user?.referred_to_user.uid : 'N/A');
            data.push(user?.referred_to_user !== null ? user?.referred_to_user?.full_name : user?.first_name !== null ? user?.first_name + ' ' + user?.last_name : 'N/A');
            data.push(user?.email);
            data.push(user?.phone_number ? user?.phone_number : "N/A");
            data.push(<p className={user?.candidate_status_color}>{user?.candidate_status}</p>);
            data.push(user?.referred_on);
            return {
                data: data
            }
        }

        );

        setTableData(row)

    }, [jobUsers])
    useEffect(() => {

        var options = jobs.map((data, key) => {
            return {
                id: key,
                name: data.program_name,
            }
        });
        setDropdownOptions(options);

    }, [jobs])

    return (
        <>
            {isCompany() ?
                <div className='row mb-3'>
                    <div className='col-12'>
                        <Button buttonType="primary" onClick={() => navigate('/posted')} text={"Add New Referral"} />
                    </div>
                </div>
                : null}
            {jobs.length > 0 ?
                <>
                    <div className="row mb-3">
                        <div className='col-9 select'>
                            <Dropdown isRequired={false} showDefaultOption={false} label={"Select Job ID"} id="job_referrals" name='job_referrals' value={activeJobIndex} onChange={(e) => { setActiveJobIndex(e.target.value); setJobData(jobs[e.target.value]) }} select={dropdownOptions} />
                        </div>
                    </div>
                </>
                :
                <p className='mt-3 centre-div'>At the moment, there is no ongoing referral program. Please keep an eye on this area for future updates.</p>
            }



            <div className="result_section">



                <div className='main-div'>
                    <div className="col common_items">

                        {jobUsers.data !== undefined && jobUsers.data.length > 0 ?
                            <Table columns={tableColumns} data={tableData} activePage={activeJobPage} handlePaginationChange={handlePaginationChange} pagination={jobUsers} />
                            :

                            <p className='my-3 centre-div'>Within this section, you can view all your referrals. There are currently no referrals to display.</p>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default JobReferrals