import "./Upload.scss";
import IconResource from "../IconResource/IconResource";

const Upload = (props) => {
    const getHelpText = () => {
        if (!props?.helpText && !props?.helpTextNavigate) {
            return null;
        }
        return (<p className={props?.helpTextPosition === 'right' ? "help-text-right" : "help-text-left"}>
            {props?.helpText}
            <p className="hand-hover" onClick={props?.helpTextNavigateClick}>
                {props?.helpTextNavigate}
            </p>
        </p>)
    }
    const renderUpload = () => {
        switch (props?.type) {
            case "strip":
                return (
                    <>
                        <div className="resume-upload-section">
                            <div className="resume-upload-section-container">
                                <div className="resume-upload-section-content">
                                    <div className="d-flex w-100 align-items-center justify-content-center">
                                        <div className="d-flex align-items-center flex-40">
                                            {props?.fileLink ? <div className='resume-filename tt-text-primary' style={{ display: "flex", gap: "8px" }}>
                                                <IconResource type="file" />
                                                <div className='file-name-upload'>
                                                    <a className="pref-loc" href={props?.fileLink} target='_blank'>{props?.text}</a>
                                                </div>
                                            </div> : null}
                                        </div>

                                        <div className='file-name-upload'>
                                            <input className="upload-input" type="file" ref={props?.fileRef} accept={props?.accept} onChange={props?.onChange} />
                                            <IconResource type="upload" size="1.25em" />{props?.fileLink ? "Update " + props?.text : "Upload " + props?.text}
                                        </div>

                                    </div>

                                </div>
                                <div className="delete-section d-flex align-items-center">
                                    {props?.fileLink ? <a className='resume-upload-section-delete'
                                        onClick={props?.onDelete} > <IconResource type="delete"
                                            size="1.25em" color="var(--red)" /></a> : null}

                                </div>


                            </div>

                            {/* {props?.fileLink ? <div className='upload_r utxt'>
                                <IconResource type="file" size="1.25em" />
                                <div className='upload_resume'>
                                    <a href={props?.fileLink} target='_blank'>{props?.text}</a>
                                </div>
                            </div> : null}
                            <div className='upload_r'>
                                <div className='upload_resume '>
                                    <input type='file' ref={props?.fileRef} accept={props?.accept} onChange={props?.onChange} />
                                    <IconResource type="upload" size="1.25em" />{props?.fileLink ? "Update " + props?.text : "Upload " + props?.text}
                                </div>
                            </div>
                            <div className='upload_r'>
                                <div className='upload_resume '>
                                    {props?.fileLink ? <a className='deleteIcon hand-hover'
                                        onClick={props?.onDelete} > <IconResource type="delete"
                                            size="1.25em" color="var(--red)" /></a> : null}
                                </div>
                            </div> */}
                        </div>
                        {getHelpText()}
                    </>
                )
            case "square":
                return (
                    <>
                        <div className="resume-upload-section square">
                            <div className="resume-upload-section-container">
                                <div className="resume-upload-section-content">
                                    <div className="d-flex w-100 align-items-center">
                                        <div className="d-flex align-items-center w-50">
                                            {props?.fileLink ? <div className='resume-filename tt-text-primary' style={{ display: "flex", gap: "8px" }}>
                                                <IconResource type="file" />
                                                <div className='file-name-upload'>
                                                    <a href={props?.fileLink} target='_blank'>{props?.text}</a>
                                                </div>
                                            </div> : null}

                                        </div>
                                        <div>
                                            <div className='file-name-upload square'>
                                                <input className="upload-input" type='file' ref={props?.fileRef} accept={props?.accept} onChange={props?.onChange} />
                                                <IconResource type="upload" size="1.25em" />{props?.fileLink ? "Update " + props?.text : "Upload " + props?.text}
                                            </div>
                                        </div>

                                    </div>

                                </div>
                                <div className="delete-section d-flex align-items-center">
                                    {props?.fileLink ? <a className='resume-upload-section-delete'
                                        onClick={props?.onDelete} > <IconResource type="delete"
                                            size="1.25em" color="var(--red)" /></a> : null}

                                </div>


                            </div>

                        </div>
                    </>
                )
            case "image":
                return (
                    <>
                        <div className="upload-container">
                            {props?.fileList.length > 0 ? <div className="preview-container">
                                {props?.fileList.map((previewImage, index) => (

                                    <div key={index} className="preview-image-container">
                                        <div className="preview-image">
                                            <img
                                                src={previewImage?.url}
                                                alt={`Preview ${index + 1}`}
                                            />
                                            <div className="hover-box">
                                                <button
                                                    className="preview-button"
                                                    onClick={() => props?.handlePreview(previewImage)}
                                                >
                                                    <IconResource fontSize="18px" type={"eye"} />
                                                </button>
                                                <button
                                                    className="delete-button"
                                                    onClick={() => props?.onRemove(index)}
                                                >
                                                    <IconResource fontSize="18px" type={"delete"} />
                                                </button>

                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div> : null}
                            <input
                                type="file"
                                id="fileInput"
                                className="file-input"
                                accept="image/*"
                                multiple
                                onChange={props.onChange}
                            />
                            <label htmlFor="fileInput" className="upload-button">
                                <span><IconResource type={"plus"} /></span>Upload
                            </label>
                        </div>
                    </>
                )
            default:
                return (
                    <div className='upload_picture '>
                        <input type='file' accept={props?.accept} onChange={props?.onChange} />
                        <p>{props?.text}</p>
                    </div>
                )
        }
    }

    return (<>
        {renderUpload()}
    </>
    );
};

export default Upload;