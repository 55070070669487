import React, { useState, useEffect, useRef } from 'react';
import * as commonServices from "@/services/common";
import searchIcon from "@/Assets/svgs/search.svg";
import searchIconWhite from "@/Assets/svgs/searchw.svg";
import { removeNavShadow } from '@/utils/styleUtils';

export function StickySearchBar({ setIsStickySearch, searchFilterState }) {

    return (
        <>
            <div className=' sticky-search-bar-home sticky-search-bar-disabled hand-hover' onClick={() => { setIsStickySearch(true); removeNavShadow(); }}>
                <div className="banner_search sticky-search-bar" style={{
                    cursor: 'pointer',
                }} >

                    <div className="b_s_search">
                        <div className='search_bar divider border-right skill-search-bar'>
                            <div className="search_input">
                                <img onError={commonServices.imgError} src="/Assets/svgs/search.svg" alt="" />
                                <input style={{
                                    margin: 0,
                                    padding: 0,
                                    border: 0,
                                    background: 'transparent',
                                }} type="text" disabled
                                    placeholder={searchFilterState?.searchType === "keyword" ? "Keyword" : 'Job / Gig'}
                                    value={searchFilterState?.searchType === "keyword" ?
                                        searchFilterState?.keyword : searchFilterState?.skill[0] || null}
                                />
                            </div>
                        </div>



                        <div className='search_bar location-search-bar'>
                            <div className="search_input">
                                <img onError={commonServices.imgError} src="/Assets/svgs/map_pin.svg" alt="" />
                                <input style={{
                                    margin: 0,
                                    padding: 0,
                                    border: 0,
                                    background: 'transparent',
                                }} type="text" disabled readOnly
                                    placeholder='City'
                                    value={searchFilterState?.location[0] || null}
                                />
                            </div>

                        </div>
                        <div className="btn_search_bar ms-auto">

                            <button className="btn btn-search">
                                <img src={searchIconWhite} alt="" />
                            </button>
                        </div>

                    </div>
                    {/* </div > */}
                    {/* </div > */}


                </div>
            </div>
        </>
    )
}