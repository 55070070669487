import React from 'react'
import { Navigate } from 'react-router-dom'
import * as storageConstants from '../constants/storageConstants';
import * as commonServices from '../services/common';
import { isCompanyAdmin, isPartnerAdmin } from '../utils/authUtil';

function CompanyAdminProtected(props) {
    const Cmp = props.cmp;
    var userRole = commonServices.getLocalData(storageConstants.USER_ROLE);
    var auth = commonServices.getLocalData(storageConstants.AUTH);
    let partnerRoutes = ["/company/edit", "/partner/edit", "/partner/view", "/partner/dashboard"];
    let currentRoute = window.location.pathname;

    const isPartnerRoute = () => {
        return partnerRoutes.includes(currentRoute);
    }

    var redirect = "/";

    return (
        <>
            {auth && (isCompanyAdmin() || (isPartnerRoute() && isPartnerAdmin())) ? <Cmp jobSearch={props.jobSearch} locationSearch={props.locationSearch} locationSearchName={props.locationSearchName} jobSearchName={props.setJobSearchName} searchType={props.searchType} keywordSearch={props.keywordSearch}
                showStickySearchBar={props.showStickySearchBar} setShowStickySearchBar={props.setShowStickySearchBar} isStickySearch={props.isStickySearch} setIsStickySearch={props.setIsStickySearch}
                setSearchFilterState={props.setSearchFilterState} keywordSeachFilters={props.keywordSeachFilters} setKeywordSeachFilters={props.setKeywordSeachFilters} setSearchType={props.setSearchType} setKeywordSearch={props.setKeywordSearch} /> : <Navigate replace to={redirect} />}
        </>
    )
}

export default CompanyAdminProtected