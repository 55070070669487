import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import CompanyDashboardLayout from './CompanyDashboardLayout';
import * as toast from "@/wrapper/toast";
import * as ApiHelper from '@/services/apiHelper';
import { viewSimilarCandidates } from '../../services/common';

function CompanyDashboard({ keywordSeachFilters, setKeywordSeachFilters }) {
    const navigate = useNavigate();
    const [data, setData] = useState({});
    let [loading, setLoading] = useState(false);
    const [searchedLocations, setSearchedLocations] = useState([]);
    const [searchedSkills, setSearchedSkills] = useState([]);

    const [searchType, setSearchType] = useState("smart");
    const [keyword, setKeyword] = useState("");

    const [showReportsModal, setShowReportsModal] = useState(false);

    const [selectedCriterias, setSelectedCriterias] = useState([]);
    const [showSimilarCandidatesModal, setShowSimilarCandidatesModal] = useState(null);

    const isSimilarCriteriaChecked = (type) => {
        return selectedCriterias.includes(type);
    };

    const showSimilarSearch = () => {
        if (selectedCriterias.length < 1) {
            toast.warn("Please select at least one criteria");
            return;
        }

        const data = showSimilarCandidatesModal;
        let newData = {};

        newData.skills = data?.skills?.length > 0 ? data?.skills : [];
        newData.location_id = data?.location_id;
        newData.full_name = data?.full_name;
        newData.id = data?.id;

        viewSimilarCandidates(newData, setLoading, isSimilarCriteriaChecked);
    }

    useEffect(() => {
        const queryParam = new URLSearchParams(window.location.search);
        if (queryParam.get('action') === 'reports') {
            setShowReportsModal(true);
        }
    }, []);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        setLoading(true);
        await ApiHelper.companyStaticDashboard().then((response) => {
            if (response.isSuccess === true) {
                setData(response.data);
            }
            else {
                toast.warn(response.message);
            }
            setLoading(false);
        });
    }

    function getFirstDayOfMonth() {
        const today = new Date();
        const timeZoneOffset = 330; // Offset for GMT+5:30
        const adjustedDate = new Date(today.getTime() + timeZoneOffset * 60000);
        return new Date(adjustedDate.getFullYear(), adjustedDate.getMonth(), 1);
    }

    function getToday() {
        const today = new Date();
        const timeZoneOffset = 330; // Offset for GMT+5:30
        return new Date(today.getTime() + timeZoneOffset * 60000);
    }

    const [startDate, setStartDate] = useState(getFirstDayOfMonth());
    const [endDate, setEndDate] = useState(getToday());

    const handleDateChange = (date, type) => {
        if (!isNaN(date.getTime())) {
            if (type === 'start') {
                setStartDate(date);
            } else if (type === 'end') {
                if (date.getTime() >= startDate.getTime() && date.getTime() <= getToday().getTime()) {
                    setEndDate(date);
                } else {
                    toast.warn('Please enter a valid End Date within the allowed range.');
                }
            }
        } else {
            toast.warn('Please enter a valid Start/End Date.');
        }
    };

    const downloadReport = (report, startDate, endDate) => {
        const queryParams = `?report=${report}&startDate=${startDate}&endDate=${endDate}`;

        ApiHelper.downloadCompanyReport(queryParams).then((response) => {
            if (response.isSuccess === true) {
                const link = document.createElement('a');
                link.href = response.data?.file_url;
                link.target = '_blank';
                link.rel = 'noopener noreferrer';

                link.click();
                document.body.removeChild(link);
                toast.success(response.message);
            } else {
                toast.warn(response.message);
            }
        });
    };

    const resetDates = () => {
        setStartDate(getFirstDayOfMonth());
        setEndDate(getToday());
    }

    const FindWork = () => {
        var queryParam = '?';
        for (let i = 0; i < searchedSkills.length; i++) {
            queryParam += "skills=" + searchedSkills[i]?.id + "&";
        }
        for (let i = 0; i < searchedLocations.length; i++) {
            queryParam += "locations=" + searchedLocations[i]?.id + "&";
        }
        queryParam += "searchType=" + searchType + "&";
        queryParam += "keyword=" + keyword;
        navigate('/jobseeker/search' + queryParam);
    }

    const candidateProfile = (candidate_id) => {
        window.open("/workprofile/view/" + candidate_id, '_blank');
    };

    return (
        <CompanyDashboardLayout
            data={data}
            loading={loading}
            candidateProfile={candidateProfile}
            FindWork={FindWork}
            keywordSeachFilters={keywordSeachFilters}
            setKeywordSeachFilters={setKeywordSeachFilters}
            searchedLocations={searchedLocations}
            setSearchedLocations={setSearchedLocations}
            searchedSkills={searchedSkills}
            setSearchedSkills={setSearchedSkills}
            searchType={searchType}
            setSearchType={setSearchType}
            keyword={keyword}
            setKeyword={setKeyword}
            showReportsModal={showReportsModal}
            setShowReportsModal={setShowReportsModal}
            downloadReport={downloadReport}
            startDate={startDate}
            endDate={endDate}
            handleDateChange={handleDateChange}
            resetDates={resetDates}
            getFirstDayOfMonth={getFirstDayOfMonth}
            getToday={getToday}
            setLoading={setLoading}
            selectedCriterias={selectedCriterias}
            setSelectedCriterias={setSelectedCriterias}
            showSimilarCandidatesModal={showSimilarCandidatesModal}
            setShowSimilarCandidatesModal={setShowSimilarCandidatesModal}
            isSimilarCriteriaChecked={isSimilarCriteriaChecked}
            showSimilarSearch={showSimilarSearch}
        />
    )
}

export default CompanyDashboard;