import React from "react";
import "./Modal.scss";
import * as commonServices from "@/services/common";
import infoImage from "@/Assets/svgs/info-icon.svg";
import congratsImage from "@/Assets/svgs/congrats.svg";
import AppModal from "./AppModal";
import Text from "../_widgets/Text/Text";
import Button from "../_widgets/Button/Button";

function ModalInformation({
  showModal,
  setShowModal,
  title,
  text,
  buttonOneText,
  buttonOneAction,
  buttonTwoText,
  buttonTwoAction,
  heading,
  size = "md",
  setShowConfetti,
}) {
  return (
    <>
      <AppModal
        isOpen={showModal}
        closeModal={() => {
          setShowModal((prev) => !prev);
          if (setShowConfetti !== undefined && setShowConfetti !== null) {
            setShowConfetti(false);
          }
        }}
        size={size}
        isFooter={false}
        title={
          <div
            style={{
              alignItems: "center",
              justifyContent: "flex-start",
              display: "flex",
              gap: "10px",
              width: "100%",
              maxWidth: "90vw", // maxW: "90vw"
              overflowWrap: "break-word",
            }}
          >
            <img
              style={{ width: "48px", minWidth: "48px" }}
              onError={commonServices.imgError}
              src={title === "Congratulations" ? congratsImage : infoImage}
              alt=""
            />
            <div
              style={{
                maxWidth: "calc(100% - 48px)",
                display: "flex",
                flexWrap: "wrap",
                overflowWrap: "break-word",
              }}
            >
              {title}
            </div>
          </div>
        }
      >
        <div className="">
          <div className="modal_body">
            <div className="info-content">
              {heading !== undefined && heading !== "" ? (
                <Text
                  text={heading}
                  style={{
                    fontSize: "var(--p2)",
                    fontWeight: 600,
                    marginBottom: "20px",
                  }}
                />
              ) : null}
              {text !== undefined ? (
                <p
                  style={{ fontSize: "var(--p2)" }}
                  dangerouslySetInnerHTML={{ __html: text }}
                ></p>
              ) : null}
            </div>

            <div className="row">
              <div
                style={{
                  fontSize: "sm",
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                  marginTop: "20px", // mt: "20px"
                }}
              >
                {buttonOneAction !== undefined &&
                buttonOneText !== undefined &&
                buttonOneText !== "" ? (
                  <Button
                    buttonType="secondary"
                    style={{ marginRight: "0.75rem" }}
                    mr={3}
                    onClick={buttonOneAction}
                    text={buttonOneText}
                  />
                ) : null}
                {buttonTwoAction !== undefined &&
                buttonTwoText !== undefined &&
                buttonTwoText !== "" ? (
                  <Button
                    buttonType="primary"
                    style={{ marginLeft: "10px" }}
                    onClick={buttonTwoAction}
                    text={buttonTwoText}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </AppModal>
    </>
  );
}

export default ModalInformation;
