import moment from 'moment/moment';

export function changeDateFormat(date, format = "DD/MM/YYYY") {
    return date ? moment(date).format(format) : '';
}

export function tomorrowDate() {
    return moment().add(1, 'days').format("DD/MM/YYYY");
}

export function nowDateTime() {
    return moment().format("YYYY-MM-DD HH:mm:ss");
}

export function dateTime(hours = 0, date = moment()) {
    return moment(date).add(hours, 'hours').format("YYYY-MM-DD HH:mm:ss");
}

export function formatAndAddDate(days, format = "DD-MM-YYYY", date = new Date()) {
    return moment(date, format).add(days, 'days')
}

export function dateTimeFormatForApi(date, format = "YYYY-MM-DD") {
    return date ? moment(date).format(format) : '';
}


export function momentToDate(date) {
    return moment(date)?.toDate()
}


export function momentYear() {
    return moment().year()
}


export function isBetween(beginTime, startTime, endTime) {
    return moment(beginTime).isBetween(startTime, endTime) || (moment(beginTime).isSame(moment(startTime)))
}


export function momentResult(input) {
    return moment(input)
}

export function timeFormat(time, format = "h:mm a") {
    return moment(time, format)
}


export function momentDuration(input) {
    return moment.duration(input)
}



export const timeConstants = [
    // "12:00 am",
    // "12:30 am",
    // "01:00 am",
    // "01:30 am",
    // "02:00 am",
    // "02:30 am",
    // "03:00 am",
    // "03:30 am",
    // "04:00 am",
    // "04:30 am",
    // "05:00 am",
    // "05:30 am",
    // "06:00 am",
    // "06:30 am",
    "07:00 am",
    "07:30 am",
    "08:00 am",
    "08:30 am",
    "09:00 am",
    "09:30 am",
    "10:00 am",
    "10:30 am",
    "11:00 am",
    "11:30 am",
    "12:00 pm",
    "12:30 pm",
    "01:00 pm",
    "01:30 pm",
    "02:00 pm",
    "02:30 pm",
    "03:00 pm",
    "03:30 pm",
    "04:00 pm",
    "04:30 pm",
    "05:00 pm",
    "05:30 pm",
    "06:00 pm",
    "06:30 pm",
    "07:00 pm",
    "07:30 pm",
    "08:00 pm",
    "08:30 pm",
    "09:00 pm",
    "09:30 pm",
    "10:00 pm",
    "10:30 pm",
    "11:00 pm",
    // "11:30 PM"
]

export function getNextSixMonthsSlots() {
    const currentDate = new Date();
    const slots = [];

    // Generate slots for the next 6 months
    for (let i = 0; i < 6; i++) {
        const currentMonth = currentDate.getMonth() + i;
        const currentYear = currentDate.getFullYear();
        const firstDay = new Date(currentYear, currentMonth, 1);
        const lastDay = new Date(currentYear, currentMonth + 1, 0);

        const firstHalfStart = firstDay.getDate();
        const firstHalfEnd = Math.floor(lastDay.getDate() / 2);


        const secondHalfStart = firstHalfEnd + 1;
        const secondHalfEnd = lastDay.getDate();

        let format = "DD/MM/YYYY";
        slots.push(changeDateFormat(new Date(currentYear, currentMonth, firstHalfStart), format) + " - " + changeDateFormat(new Date(currentYear, currentMonth, firstHalfEnd), format))
        slots.push(changeDateFormat(new Date(currentYear, currentMonth, secondHalfStart), format) + " - " + changeDateFormat(new Date(currentYear, currentMonth, secondHalfEnd), format))
    }
    return slots;
}

export const years = [
    { id: 0, name: '0 years' },
    { id: 1, name: '1 year' },
    { id: 2, name: '2 years' },
    { id: 3, name: '3 years' },
    { id: 4, name: '4 years' },
    { id: 5, name: '5 years' },
    { id: 6, name: '6 years' },
    { id: 7, name: '7 years' },
    { id: 8, name: '8 years' },
    { id: 9, name: '9 years' },
    { id: 10, name: '10 years' },
    { id: 11, name: '11 years' },
    { id: 12, name: '12 years' },
    { id: 13, name: '13 years' },
    { id: 14, name: '14 years' },
    { id: 15, name: '15 years' },
    { id: 16, name: '16 years' },
    { id: 17, name: '17 years' },
    { id: 18, name: '18 years' },
    { id: 19, name: '19 years' },
    { id: 20, name: '20 years' },
];

export const months = [
    { id: 0, name: '0 months' },
    { id: 1, name: '1 month' },
    { id: 2, name: '2 months' },
    { id: 3, name: '3 months' },
    { id: 4, name: '4 months' },
    { id: 5, name: '5 months' },
    { id: 6, name: '6 months' },
    { id: 7, name: '7 months' },
    { id: 8, name: '8 months' },
    { id: 9, name: '9 months' },
    { id: 10, name: '10 months' },
    { id: 11, name: '11 months' },
];

export const monthsWithoutName = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

export const yearsWithoutName = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30];

export function getYearFromDate(dateStr) {
    const formats = ["MM-DD-YYYY", "MM/DD/YYYY"];
    const dateObject = moment(dateStr, formats, true);
    return dateObject.isValid() ? dateObject.year() : null;
}
export function getMonthYearFromDate(dateStr) {
    const formats = ["MM-DD-YYYY", "MM/DD/YYYY"];
    const dateObject = moment(dateStr, formats, true);
    return dateObject.isValid() ? `${dateObject.format('MMM')} ${dateObject.year()}` : null;
}

export function encodeDateURI(date) {
    return encodeURIComponent(date);
}

export function decodeDateURI(date) {
    return decodeURIComponent(date);
}