import React from "react";
import { Form } from "react-bootstrap";

const Input = ({
  label,
  required,
  name,
  type,
  width,
  placeholder,
  value,
  handleChange,
  onBlur,
  onFocus,
  disabled,
  pattern,
}) => {
  return (
    <div className="d-flex flex-column gap-1">
      <label
        style={{
          color: "var(--gray4)",
          fontSize: "var(--p1)",
          fontWeight: "medium",
        }}
      >
        {label} {required ? <span style={{ color: "#FF0000" }}>*</span> : null}
      </label>
      <Form.Control
        name={name}
        type={type}
        style={{
          fontSize: "0.8rem",
          height: "40px",
          border: "1px solid #ced4da",
          borderRadius: "4px",
          width: width || "100%",
          padding: "0px 10px 0px 10px",
        }}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        onBlur={onBlur}
        onFocus={onFocus}
        disabled={Boolean(disabled) || false}
        pattern={pattern}
      />
    </div>
  );
};

export default Input;
