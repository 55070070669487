import React from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./Calender.scss";
import { formatAndAddDate } from "../../../utils/dateUtil";
const TTCalendar = ({ date, onChange }) => {
  return <Calendar minDate={new Date(formatAndAddDate(1))} maxDate={new Date(formatAndAddDate(7))} value={date} onChange={(value, event) => onChange(value)} />;
};

export default TTCalendar;
