import { useEffect, useState } from "react";
import AppModal from "./AppModal";
import Input from "../_widgets/Input/Input";
import { imgError } from "../../services/common";
import Table from "../_widgets/Table/Table";
import Button from "../_widgets/Button/Button";
import Loader from "../Common/Loader";

const ModalSaveSearchCriteria = ({
  showSearchCriteriaModal,
  setShowSearchCriteriaModal,
  submitSaveSearchCriteria,
  searchCriteriaTitle,
  setSearchCriteriaTitle,
  savedSearchCriterias,
  deleteSavedSearchCriteria,
  applySavedSearchCriteria,
  modalLoading,
}) => {
  const [tableData, setTableData] = useState([1, 2, 3]);

  const columns = ["Title", "Saved At", "Actions"];

  useEffect(() => {
    if (
      savedSearchCriterias &&
      savedSearchCriterias?.data &&
      savedSearchCriterias.data.length > 0
    ) {
      const row = savedSearchCriterias?.data?.map((ct) => {
        const data = [];

        data.push(ct.title);
        data.push(ct.saved_at);
        data.push(
          <div className="d-flex gap-1 justify-content-between">
            <Button
              buttonType="primary"
              text="Apply"
              onClick={() => applySavedSearchCriteria(ct.json)}
            />
            <Button
              buttonType="danger"
              text="Delete"
              onClick={() => deleteSavedSearchCriteria(ct.id)}
            />
          </div>
        );

        return { data: data };
      });

      setTableData(row);
    }
  }, [savedSearchCriterias]);

  return (
    <AppModal
      title="Save Search Criteria"
      isOpen={showSearchCriteriaModal}
      closeModal={() => {
        setShowSearchCriteriaModal(false);
        setSearchCriteriaTitle("");
      }}
      isFooter={true}
      submitText="Save"
      submit={() => submitSaveSearchCriteria()}
      minH={"50vh"}
    >
      <Input
        type="text"
        isRequired={true}
        placeholder="Enter Title"
        value={searchCriteriaTitle}
        onChange={(e) => setSearchCriteriaTitle(e.target.value)}
      />

      {modalLoading ? (
        <Loader />
      ) : savedSearchCriterias?.data &&
        savedSearchCriterias?.data?.length > 0 ? (
        <div className="mt-5">
          <Table
            columns={columns}
            data={tableData}
            pagination={savedSearchCriterias}
          />
        </div>
      ) : (
        <div className="no_jobs mt-5">
          <img
            onError={imgError}
            src="../Assets/svgs/search_no_result.svg"
            alt=""
          />
          <h2>No Saved Criterias!</h2>
        </div>
      )}
    </AppModal>
  );
};

export default ModalSaveSearchCriteria;
