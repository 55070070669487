import React, { useEffect, useState } from 'react'
import * as commonServices from '@/services/common';
import { useNavigate } from "react-router-dom";

import { MaxWidthContainer } from '@/components/Common/MaxWidthContainer';
import sampleResume1 from "../../Assets/images/sample-resume-1.png"
import sampleResume2 from "../../Assets/images/sample-resume-2.png"
import sampleResume3 from "../../Assets/images/sample-resume-3.png"
import sampleResume4 from "../../Assets/images/sample-resume-4.png"
import './ResumeBuilderInfo.scss';
import * as toast from "@/wrapper/toast";

function ResumeBuilderInfo() {
    const navigate = useNavigate();
    const [template, setTemplate] = useState(0);
    const sampleResumes = [sampleResume1, sampleResume2, sampleResume3, sampleResume4];
    const navigateUser = (selectedTemplate) => {
        if (selectedTemplate === 0) {
            toast.error("Please select a template");
            return;
        }
        navigate('/candidate/resume?template=' + selectedTemplate);
    }

    return (
        <MaxWidthContainer pageKey="resume-builder">
            <div className='resume-builder-info main-body'>
                <div className='tt-container'>
                    <div className='body1'>
                        <div className="card_sec1">
                            <div className="card1">

                                <div className='emp-section'>
                                    <div className='section-first'>
                                        <div className='row mt-3'>
                                            <div className='col-md-12'>
                                                <h3 >TrueTalent Resume Maker</h3>
                                                <ul className="content-paragraph1 py-1">
                                                    <li class="diamond-bullet">Unleash great career opportunities with TrueTalent state-of-the-art Resume Maker.</li>
                                                    <li class="diamond-bullet">Create a customized, professional, and personalized resume in just minutes.</li>
                                                    <li class="diamond-bullet">Impress employers and secure your desired job effortlessly.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='section-first'>
                                        <div className='row mt-3'>
                                            <div className='col-md-12'>
                                                <h3 className='text-center' >Select a Template</h3>
                                                <div className='row gy-3 my-3'>
                                                    {sampleResumes.map((resume, index) => (
                                                        <div
                                                            key={index}
                                                            className='col-6 col-sm-4 col-md-3 hand-hover resume-card'
                                                            onClick={() => {
                                                                setTemplate(index + 1);
                                                                navigateUser(index + 1);
                                                            }}
                                                        >
                                                            <div style={{        }} className={template === index + 1 ? "card active" : "card"}>
                                                                <img className="sample-resume" src={resume} alt={`Card image ${index + 1}`} />
                                                                <div className="hover-content">
                                                                    <div className="use-this">Use this</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>


            </div >
        </MaxWidthContainer>
    )
}

export default ResumeBuilderInfo
