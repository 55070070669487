import React from "react";
import AppModal from "./AppModal";
import { useNavigate } from "react-router-dom";
import Text from "../_widgets/Text/Text";
import Button from "../_widgets/Button/Button";

const ModalSmartApply = ({ showModal, setShowModal }) => {
  const navigate = useNavigate();
  return (
    <>
      <AppModal
        isOpen={showModal}
        closeModal={() => {
          setShowModal(false);
          navigate("/my-account?tab=profile");
        }}
        title={`Free TT Cash Unlocked`}
        size="md"
        // submit={() => navigate('/my-account?tab=profile')}
        isFooter={false}
      >
        <div className="modal_body">
          <div className="modal_body_text">
            <Text text="Hey, you have unlocked <strong>Free TT Cash</strong> worth <strong>Rs 1000</strong>" />
            <Text text="Use this to apply to any job on TrueTalent." />
          </div>
          <div className="d-flex align-items-center justify-content-end">
            <Button
              buttonType="primary"
              text={"Ok"}
              onClick={() => {
                setShowModal(false);
                navigate("/my-account?tab=profile");
              }}
            />
          </div>
        </div>
      </AppModal>
    </>
  );
};

export default ModalSmartApply;
