import React, { useState } from "react";
import "./Modal.scss";
import * as ApiHelper from "@/services/apiHelper";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";
import { useEffect } from "react";
import * as toast from "@/wrapper/toast";
import AppModal from "./AppModal";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: var(--green);
  left: calc(50% - 75px);
  position: fixed;
`;

function ModalAddComp({ showModal, setShowModal, companySearch, loadCompany }) {
  let [loading, setLoading] = useState(false);
  let [color] = useState("#000000");

  const [add_company, setAdd_company] = useState(
    companySearch ? companySearch : ""
  );
  const [add_website, setAdd_website] = useState();

  const AddCompany = () => {
    setLoading(true);
    var comp = {
      company_name: add_company,
      website: add_website,
    };

    ApiHelper.addBlockCompany(comp).then((response) => {
      setLoading(false);
      if (response.isSuccess === true) {
        toast.success(response.message);
        setShowModal((prev) => !prev);
        loadCompany();
        document.getElementById("search_job").value = "";
      } else {
        toast.warn(response.message);
      }
    });
  };

  useEffect(() => {
    setAdd_company(companySearch);
  }, [companySearch]);

  return (
    <>
      <AppModal
        title={"Add Company"}
        isOpen={showModal}
        closeModal={() => {
          setShowModal(false);
        }}
        isFooter={true}
        submit={AddCompany}
        loading={loading}
        size={"md"}
      >
        <div className="">
          {loading ? (
            <div className="loader">
              <ClipLoader
                color={color}
                loading={true}
                css={override}
                size={150}
              />{" "}
            </div>
          ) : null}
          <div className="modal_body">
            <div className="row">
              <div className="col">
                <label htmlFor="" className="input-lebel">
                  Company Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Complete Company Name"
                  value={add_company}
                  onChange={(e) => setAdd_company(e.target.value)}
                />
              </div>
            </div>

            <div className="row">
              <div className="col">
                <label htmlFor="" className="input-lebel">
                  Company Website
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Company Website"
                  onChange={(e) => setAdd_website(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </AppModal>
    </>
  );
}

export default ModalAddComp;
