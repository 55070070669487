import React, { useState, useEffect } from 'react'
import * as ApiHelper from '@/services/apiHelper';
import { consoleLogE } from '@/utils/console';
import { MaxWidthContainer } from '@Components/Common/MaxWidthContainer';
import Text from "../_widgets/Text/Text";

function Giveaway() {

    const [winners, setWinners] = useState([]);
    useEffect(() => {
        getWinners();
    }, [])

    const getWinners = () => {
        ApiHelper.getGiveawayWinners().then((response) => {
            if (response.isSuccess === true) {
                setWinners(response.data);
            }
            else {
                consoleLogE('getGiveawayWinners', response.message);
            }
        });
    }

    return (
        <MaxWidthContainer >
            <div className='terms_page'>
                <div className='body tt-container'>
                    <div className="post_sec">
                        <div className="post_full">
                            <Text type='h2' text="Giveaway" />
                            <p><br /></p>

                            <Text type='h5' text="Eligibility: " />
                            <Text type='h7' text="All citizens of India above the age of 18 are eligible to participate in this contest." />
                            <p><br /></p>

                            <Text type='h5' text="Entry Requirements:" />
                            <Text type='h7' text="To participate in this giveaway, one must follow the below steps:" />
                            <Text type='h7' text="1. Register their profile (resume) at <a href='https://www.truetalent.io'>www.truetalent.io</a>. The registration must be complete with all details filled. Partially completed profile will make the participant ineligible." />
                            <Text type='h7' text="2. Besides completing the first step, participants must follow at least one of the social media (Facebook, Instagram, Linkedin, Twitter, Youtube) handles of TrueTalent.io. The links to the social media handles are provided below for easy reference." />
                            <Text type='h7' text="<a href='https://www.facebook.com/Truetalent.io'>https://www.facebook.com/Truetalent.io </a>" />
                            <Text type='h7' text="<a href='https://www.linkedin.com/company/truetalent-io/ '>https://www.linkedin.com/company/truetalent-io/ </a>" />
                            <Text type='h7' text="<a href='https://twitter.com/Truetalent_io'>https://twitter.com/Truetalent_io</a>" />
                            <Text type='h7' text="<a href='https://www.instagram.com/Truetalent_io/ '>https://www.instagram.com/Truetalent_io/ </a>" />
                            <Text type='h7' text="<a href='https://www.youtube.com/@TrueTalent_io '>https://www.youtube.com/@TrueTalent_io </a>" />

                            <p><br /></p>
                            <Text type='h5' text="Start and End Dates: " />
                            <Text type='h7' text="The giveaway begins on April 9, 2023, at 00:01 IST and ends on June 30, 2023, at 11:59 IST. Any participants outside of these dates would not be eligible for the giveaways." />
                            <p><br /></p>

                            <p><br /></p>
                            <Text type='h5' text="Winner Selection: " />
                            <Text type='h7' text="Winners would be chosen for the weekly, monthly, and grand prizes at the end of each week, month, and contest period through a lucky draw done through a computer-generated random selection process." />
                            <p><br /></p>

                            <p><br /></p>
                            <Text type='h5' text="Prize: " />
                            <Text type='h7' text="The grand prize for the contest period is a <b>HP laptop</b>, and there will be one grand prize. Every month, there will be one monthly prize winner who will receive a <b>Oneplus mobile phone</b>. Every week, there will be 5 winners who will receive <b>Amazon gift vouchers</b> worth INR 1,000. Winners of the weekly and monthly prizes would be able to enter the grand prize contest." />
                            <p><br /></p>

                            <p><br /></p>
                            <Text type='h5' text="Notification: " />
                            <Text type='h7' text="All the winner names would be announced on the <b>TrueTalent</b> website and on all social media handles of <b>TrueTalent</b>. Winners must claim their prize within 15 days of the result announcement." />
                            <p><br /></p>

                            <p><br /></p>
                            <Text type='h5' text="Publicity Rights: " />
                            <Text type='h7' text="TrueTalent.io and its parent company, FindrPro Technology Solutions Pvt. Ltd., will reserve the right to use the name, photograph, city of residence, and a brief profile of the person (without their personal details like phone number or email address) on their websites, social media handles, and other promotional channels like emails and other forms of communication for the purpose of publicity." />
                            <p><br /></p>

                            <p><br /></p>
                            <Text type='h5' text="Disclaimer: " />
                            <Text type='h7' text="This contest and giveaway are completely sponsored by FindrPro Technology Solutions, and the brands used for the giveaway items are in no way associated with this contest and giveaway." />
                            <p><br /></p>

                            <p><br /></p>
                            <Text type='h5' text="Limitation of Liability: " />
                            <Text type='h7' text="TrueTalent, its parent company, FindrPro Technology Solutions, or its promoters and employees are not responsible for any injury, loss, or damage resulting from participation in the contest." />
                            <p><br /></p>

                            <p><br /></p>
                            <Text type='h5' text="Governing Law: " />
                            <Text type='h7' text="Any dispute arising from this contest is governed by the legal jurisdiction of Bangalore, Karnataka, India." />
                            <p><br /></p>

                            <p><br /></p>
                            <Text type='h5' text="Employee Exclusion: " />
                            <Text type='h7' text="Employees and the immediate family members of TrueTalent, FindrPro Technology Solutions, their partners, and partner organization employees are not eligible to participate in the contest." />
                            <p><br /></p>

                            <p><br /></p>
                            <Text type='h5' text="Multiple Entries: " />
                            <Text type='h7' text="Participants registering on <a href='https://www.truetalent.io'>www.truetalent.io</a> and following multiple social media handles of TrueTalent would be eligible for more than one entry." />
                            <Text type='h7' text="All weekly winners would be eligible for the monthly giveaways, and all monthly winners would be eligible for the grand prize. " />
                            <p><br /></p>

                            <p><br /></p>
                            <Text type='h5' text="Disqualification: " />
                            <Text type='h7' text="In case any participant did not fulfill all the criteria mentioned in the entry requirement point, they would be ineligible for the lucky draw. The decision of TrueTalent, FindrPro, and their representatives would be final and binding." />
                            <p><br /></p>

                            <p><br /></p>
                            <Text type='h5' text="Modification or Cancellation: " />
                            <Text type='h7' text="TrueTalent.io and its parent company, FindrPro Technology Solutions Pvt. Ltd., reserve the right to modify or cancel the contest at any time if there are technical difficulties or if fraud is suspected." />
                            <p><br /></p>

                            <p><br /></p>
                            <Text type='h5' text="Taxes and Fees: " />
                            <Text type='h7' text="If any tax liability arises out of these give-aways, which are personal to the winners, the same would be borne in entirety by the winner. TrueTalent.io and its parent company, FindrPro Technology Solutions Pvt. Ltd., will not be liable to pay any taxes other than the tax collected during the purchase of the items." />
                            <p><br /></p>

                            <p><br /></p>
                            <Text type='h5' text="Transferability: " />
                            <Text type='h7' text="The prize(s) are non-transferable and cannot be converted to equivalent cash under any circumstance. " />
                            <p><br /></p>

                            <p><br /></p>
                            <Text type='h5' text="Release of Liability: " />
                            <Text type='h7' text="This giveaway requires participants to release TrueTalent.io and its parent company, FindrPro Technology Solutions Pvt. Ltd., from any liability associated with the contest or the prize given away." />
                            <p><br /></p>

                            <p><br /></p>
                            <Text type='h5' text="Intellectual Property: " />
                            <Text type='h7' text="TrueTalent.io and its parent company, FindrPro Technology Solutions Pvt. Ltd., own the intellectual property associated with the contest, including any content submitted by participants. All information collected during this contest period can be used by TrueTalent.io or FindrPro Technology Solutions Pvt. Ltd. for the primary purpose of making the resumes of such participants available on their website for the purpose of allowing their clients to search for and contact relevant candidates for offering jobs and/or gigs." />
                            <p><br /></p>

                            <p><br /></p>
                            <Text type='h5' text="Severability: " />
                            <Text type='h7' text="In the event that any part of these terms and conditions is found to be unenforceable or invalid, the remaining provisions will still be binding." />
                            <p><br /></p>


                            {winners && winners.length > 0 ?
                                <>
                                    <p><br /></p>
                                    <Text type='h5' text="Winners:" /><br />
                                    <div className='common_items' style={{ overflowX: "scroll" }}>
                                        <table className="table mb-5">
                                            <thead>
                                                <tr>
                                                    {/* <th scope="col">Jobseeker ID</th> */}
                                                    <th scope="col">#</th>
                                                    <th scope="col">Category</th>
                                                    <th width="10%" scope="col">Start Date</th>
                                                    <th scope="col">End Date</th>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Email</th>
                                                    <th scope="col">Location</th>
                                                    <th scope="col">Registration Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {winners.map((data, key) => {
                                                    return (
                                                        <tr key={key}>
                                                            <th scope="col">{key + 1}</th>
                                                            <th scope="col">{data.category}</th>
                                                            <th scope="col">{data.start_date}</th>
                                                            <th scope="col">{data.end_date}</th>
                                                            <th scope="col">{data.candidate_name}</th>
                                                            <th scope="col">{data.candidate_email}</th>
                                                            <th scope="col">{data.candidate_location}</th>
                                                            <th scope="col">{data.registration_date}</th>

                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </>
                                : null}

                        </div>

                    </div>
                </div>
            </div>
        </MaxWidthContainer>
    )
}

export default Giveaway;