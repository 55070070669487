import React, { useEffect, useState, useRef } from 'react';
import * as commonServices from '@/services/common';
import * as ApiHelper from '@/services/apiHelper';
import * as toast from "@/wrapper/toast";
import { changeDateFormat } from '../../../utils/dateUtil';
import Table from '../../_widgets/Table/Table';

function FeaturedGigSlotsHistory({ loading, setLoading, activeTab }) {
    const [data, setData] = useState({})

    const getData = async () => {
        setLoading(true);
        var queryString = "?pageNumber=" + activePage;
        await ApiHelper.getGigSlotHistory(queryString).then((response) => {
            if (response.isSuccess === true) {
                setData(response.data);
            }
            else {
                toast.warn(response.message);
            }
            setLoading(false);
        });
    }

    useEffect(() => {
        if (activeTab === "featured-gig-slots-history") {
            getData();
        }
    }, [activeTab]);

    var tableColumns = ['Slot Type', 'Count', 'Target', 'Purchased Date', 'Start Date', 'Expiry Date'];
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        var row = data?.gig_slots && data?.gig_slots?.data.map((slot, i) => {
            var data = [];
            data.push("Featured Gig Slot");
            data.push(<p className="number-div">{slot?.slots}</p>);
            data.push(slot?.page === 'homepage' ? "Home Page" : "Search Page");
            data.push(changeDateFormat(slot?.created_at));
            data.push(changeDateFormat(slot?.from_date));
            data.push(changeDateFormat(slot?.to_date));
            return {
                data: data
            }
        }
        );

        setTableData(row)
    }, [data])

    const [activePage, setActivePage] = useState(1);

    const handlePaginationChange = (e, { activePage }) => {
        window.scrollTo(0, 0);
        setActivePage(activePage);
    };

    useEffect(() => {
        getData();
    }, [activePage])

    return (
        <div className='feature-job'>
            {!loading ?
                <div className='row'>
                    {(data?.gig_slots && data?.gig_slots?.data.length > 0) ?
                        (
                            <div className="company-fix-table common_items">
                                <Table columns={tableColumns} data={tableData} activePage={activePage} handlePaginationChange={handlePaginationChange} pagination={data?.gig_slots} />
                            </div>)
                        : <div className='no_gigs' ><img onError={commonServices.imgError} src="@/Assets/svgs/search_no_result.svg" alt="" /><h2 style={{ marginTop: "100px", textAlign: "center" }}>No Slot(s) found!</h2></div>}
                </div>
                : null}
        </div>
    )
}

export default FeaturedGigSlotsHistory;