import { useNavigate } from "react-router-dom";
import './Modal.scss';
import AppModal from './AppModal';

function ModalLoginRequired({ showModal, setShowModal, candidate_id }) {

    const navigate = useNavigate();

    return (
        <AppModal
            title='Login Required'
            isOpen={showModal}
            closeModal={() => setShowModal(prev => !prev)}
            isFooter={true}
            submitText="Login"
            submit={() => navigate('/login?redirect=/workprofile/view/' + candidate_id)}
        >
            <div className='modal-body'>
                <span className="input-lebel">Please login as recuiter to proceed!</span>
            </div>
        </AppModal>
    )
}

export default ModalLoginRequired;