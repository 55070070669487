import './Common.scss';
import * as commonServices from '@/services/common';
import { useNavigate } from "react-router-dom";
import { useEffect } from 'react';


function PayWall({ desc, setDesc, isPaywallActive, onCancel }) {
    const navigate = useNavigate();

    useEffect(() => {
        window.document.getElementsByTagName('body')[0].style.overflow = isPaywallActive ? 'hidden' : 'auto';

        return function () {
            window.document.getElementsByTagName('body')[0].style.overflow = 'auto';            // perform cleanup...
        };
    }, [isPaywallActive])

    return (
        <div id="overlay" className={isPaywallActive ? 'active' : ""}>
            <div className="overlay__body">

            </div>
            <div className='paywall-body'>

                <div className="body">
                    <div className='right-align' onClick={onCancel}>
                        <img src="@/Assets/svgs/x.svg" alt="" />
                    </div>
                    <div className="left">

                        <img onError={commonServices.imgError} src='../Assets/images/paywall.png' alt="" />

                    </div>

                    <div className="right">

                        <h2><span className='blue-text'>{desc ? desc : 'Your Dream job'}</span> near you are just a click away!</h2>
                        <p>Create your free account or log in to continue</p>
                        <button className="form_button_login" onClick={() => { navigate('/?auth=login'); }}>Login</button>
                        <button className='form_button_register' onClick={() => { navigate('/?auth=register'); }}>Create Account</button>

                    </div>
                </div>



            </div>
        </div >
    )
}

export default PayWall
