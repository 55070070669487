import { getKeywordSearchTooltip, getSmartSearchTooltip } from '@/utils/stringUtils';
import Text from '../_widgets/Text/Text';
import { removeSearchParamsInHistoryWithoutValue } from '../../utils/redirectUtil';

const SearchType = ({ searchType, setSearchType, setKeywordSearch, searchBar, job_type, setJob_type, keywordSearch, isKeywordReset, setIsKeywordReset, keywordSeachFilters, setKeywordSeachFilters, module, saveSearchCriteria }) => {

    const onChange = (e, selected) => {
        setSearchType(e.target.value);
        if (selected === "smart") {
            setKeywordSearch('');
            if (keywordSearch !== undefined && keywordSearch !== '' && isKeywordReset !== undefined) {
                setIsKeywordReset(!isKeywordReset);
            }
        } else if (selected === "keyword") {
            if (job_type.length > 0) {
                setJob_type('skills', []);
                removeSearchParamsInHistoryWithoutValue(['skills', 'isSimilarSkills']);
            }
        }
    }

    const onChangeFilter = (e, key) => {
        let isChecked = e.target.checked;
        let tempKeywordSeachFilters = { ...keywordSeachFilters };

        tempKeywordSeachFilters[key][e.target.value] = isChecked;
        setKeywordSeachFilters(tempKeywordSeachFilters);
    }

    return (
        <div className='d-flex align-items-center justify-content-between my-1'>
            <div className='search-type'>
                <div className='w-auto d-flex align-items-center gap-1'>
                    <input className={"form-check-input "} type='radio' id={searchBar + 'smart-search'} value='smart' checked={searchType === 'smart'} onChange={(e) => { onChange(e, "smart"); }} />
                    <label title={getSmartSearchTooltip()} className={"form-check-label " + (searchType === 'smart' ? 'green-text' : '')} htmlFor={searchBar + 'smart-search'}>TT Smart Search</label>
                </div>

                <div className='w-auto d-flex align-items-center gap-1'>
                    <input className={"form-check-input"} type='radio' id={searchBar + 'keyword-search'} value='keyword' checked={searchType === 'keyword'} onChange={(e) => { onChange(e, "keyword"); }} />
                    <label title={getKeywordSearchTooltip()} className={"form-check-label " + (searchType === 'keyword' ? 'green-text' : '')} htmlFor={searchBar + 'keyword-search'}>Keyword Search</label>
                </div>

                {searchType === 'keyword'
                    ? (module === "Jobs" || module === "job" ?
                        <div className='search-sub-type-main'>
                            <div className='search-sub-type'>
                                <input className={"form-check-input "} type='checkbox' name={'title'} id={'title'} value='title' checked={keywordSeachFilters.jobs.title} onChange={(e) => { onChangeFilter(e, "jobs"); }} />
                                <label className={"form-check-label " + (keywordSeachFilters.jobs.title ? 'green-text' : '')} htmlFor={'title'}>Title</label>
                            </div>

                            <div className='search-sub-type'>
                                <input className={"form-check-input "} type='checkbox' name={'description'} id={'description'} value='description' checked={keywordSeachFilters.jobs.description ? true : false} onChange={(e) => { onChangeFilter(e, "jobs"); }} />
                                <label className={"form-check-label " + (keywordSeachFilters.jobs.description ? 'green-text' : '')} htmlFor={'description'}>Description</label>
                            </div>

                            <div className='search-sub-type'>
                                <input className={"form-check-input "} type='checkbox' name={'companyName'} id={'companyName'} value='companyName' checked={keywordSeachFilters.jobs.companyName ? true : false} onChange={(e) => { onChangeFilter(e, "jobs"); }} />
                                <label className={"form-check-label " + (keywordSeachFilters.jobs.companyName ? 'green-text' : '')} htmlFor={'companyName'}>Company Name</label>
                            </div>

                            <div className='search-sub-type'>
                                <input className={"form-check-input "} type='checkbox' name={'skills'} id={'skills'} value='skills' checked={keywordSeachFilters.jobs.skills ? true : false} onChange={(e) => { onChangeFilter(e, "jobs"); }} />
                                <label className={"form-check-label " + (keywordSeachFilters.jobs.skills ? 'green-text' : '')} htmlFor={'skills'}>Skills</label>
                            </div>
                        </div>

                        : (module === "jobseeker" || module === "Candidates" ?
                            <div className='search-type'>
                                <input className={"form-check-input "} type='checkbox' name={'name'} id={'name'} value='name' checked={keywordSeachFilters.candidates.name} onChange={(e) => { onChangeFilter(e, "candidates"); }} />
                                <label className={"form-check-label " + (keywordSeachFilters.candidates.name ? 'green-text' : '')} htmlFor={'name'}>Name</label>

                                <input className={"form-check-input "} type='checkbox' name={'email'} id={'email'} value='email' checked={keywordSeachFilters.candidates.email} onChange={(e) => { onChangeFilter(e, "candidates"); }} />
                                <label className={"form-check-label " + (keywordSeachFilters.candidates.email ? 'green-text' : '')} htmlFor={'email'}>Email</label>

                                <input className={"form-check-input "} type='checkbox' name={'skills'} id={'skills'} value='skills' checked={keywordSeachFilters.candidates.skills} onChange={(e) => { onChangeFilter(e, "candidates"); }} />
                                <label className={"form-check-label " + (keywordSeachFilters.candidates.skills ? 'green-text' : '')} htmlFor={'skills'}>Skills</label>

                                <input className={"form-check-input "} type='checkbox' name={'resume'} id={'resume'} value='resume' checked={keywordSeachFilters.candidates.resume} onChange={(e) => { onChangeFilter(e, "candidates"); }} />
                                <label className={"form-check-label " + (keywordSeachFilters.candidates.resume ? 'green-text' : '')} htmlFor={'resume'}>Resume</label>

                                <input className={"form-check-input "} type='checkbox' name={'summary'} id={'summary'} value='summary' checked={keywordSeachFilters.candidates.summary} onChange={(e) => { onChangeFilter(e, "candidates"); }} />
                                <label className={"form-check-label " + (keywordSeachFilters.candidates.resume ? 'green-text' : '')} htmlFor={'summary'}>Work Profile</label>
                            </div>
                            : null)
                    )
                    : null
                }
            </div>

            {window.location.href.includes('jobseeker/search') &&
                <div className='me-5 green-text hand-hover' onClick={saveSearchCriteria}>
                    <Text type='h2' text='Save Search Criteria' />
                </div>}
        </div>
    )
}

export default SearchType;
