
import React, { useState, useEffect } from 'react';
import './Modal.scss';
import * as commonServices from '@/services/common';
import AppModal from './AppModal';
import * as toast from "@/wrapper/toast";
import * as ApiHelper from "@/services/apiHelper";
import { PuffLoader } from 'react-spinners';
import CustomPagination from '@Components/Pagination/Pagination';
import { changeDateFormat } from '../../utils/dateUtil';

function PartnerCandidatesAppliedGigs({ showModal, setShowModal }) {

    const [data, setData] = useState([]);
    const [loader, setLoader] = useState(false);
    const [activePage, setActivePage] = useState(1);
    const handlePaginationChange = (e, { activePage }) => {

        window.scrollTo(0, 0);
        showCandidates(activePage);
        setActivePage(activePage);
    };

    useEffect(() => {
        if (showModal) {
            showCandidates();
        }

    }, [showModal])

    const showCandidates = async (activePage = 1) => {
        setLoader(true);
        let params = "?pageNumber=" + activePage;
        await ApiHelper.getPartnerCandidatesAppliedGigs(params).then((response) => {
            setLoader(false);
            if (response.isSuccess === true) {
                setData(response?.data);
                setActivePage(response?.data?.current_page);
            } else {
                toast.warn(response.message);
            }
        });
    }

    return (

        <AppModal
            isOpen={showModal}
            closeModal={() => setShowModal(prev => !prev)}
            title={`Applied Gigs (${data?.total ? data?.total : 0})`}
            size={"xl"}
            isFooter={false}

        >


            <div className="modal_body partner-candidates">
                {loader ?
                    <>
                        <div className='loader'>
                            <PuffLoader
                                color="#14BC9A"
                                loading={loader}
                                size={50}
                                height={50}
                                width={50}
                                radios={50}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                            />
                        </div>
                    </>
                    :

                    <div className="t_details common_items">
                        {data?.data && data?.data.length > 0 ?
                            <>
                                <table className="table">

                                    <thead>
                                        <tr>

                                            <th scope="col">Candidate Name</th>
                                            <th scope="col">Job Title</th>
                                            <th scope="col">Company Name</th>
                                            <th scope="col">Applied On</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data?.data && (data?.data.map((gig, i) => {
                                            return <tr className="results">
                                                <td>{gig.candidate.full_name}</td>
                                                <td>{gig.gig.title}</td>
                                                <td>{gig.gig.company.name}</td>
                                                <td>{changeDateFormat(gig.created_at)}</td>
                                            </tr>
                                        }

                                        ))}
                                    </tbody>
                                </table>
                                < div className='my-3'>
                                    <CustomPagination
                                        activePage={activePage}
                                        currentPage={setActivePage}
                                        onPageChange={handlePaginationChange}
                                        totalData={data?.total}
                                        pageLength={data?.per_page}
                                        lastPage={data?.last_page}
                                        tag="pages"
                                        dataFrom={data.from}
                                        dataTo={data.to}
                                    />
                                </div>
                            </>

                            : <div className='no_jobs' ><img onError={commonServices.imgError} src="../Assets/svgs/search_no_result.svg" alt="" /><h2>No gig found!</h2></div>
                        }
                    </div>
                }

            </div>

        </AppModal>
    )
}

export default PartnerCandidatesAppliedGigs
