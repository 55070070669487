import AppModal from './AppModal';
import { isNullOrEmpty } from '../../utils/stringUtils';
import Checkbox from '../_widgets/Checkbox/Checkbox';
import Text from '../_widgets/Text/Text';
import { SIMILAR_SEARCH_CRITERIA } from '../../constants/searchConstants';

const ModalShowSimilarCandidates = ({ showSimilarCandidatesModal, setShowSimilarCandidatesModal, showSimilarSearch, setSelectedCriterias, selectedCriterias, isSimilarCriteriaChecked }) => {

    return (
        <AppModal
            title='View Similar Candidates'
            isOpen={!isNullOrEmpty(showSimilarCandidatesModal)}
            closeModal={() => setShowSimilarCandidatesModal(null)}
            isFooter={true}
            submitText="Search"
            submit={() => showSimilarSearch()}
        >
            <Text type='h7' text='Select One or More Criteria for Searching Similar Candidates:' />

            <div className="mt-2">
                <Checkbox
                    label='Preferred Skills'
                    id="criteria1"
                    checked={isSimilarCriteriaChecked(SIMILAR_SEARCH_CRITERIA.SKILLS)}
                    onChange={(e) => {
                        if (e.target.checked) {
                            setSelectedCriterias([...selectedCriterias, SIMILAR_SEARCH_CRITERIA.SKILLS]);
                        } else {
                            setSelectedCriterias(selectedCriterias.filter(item => item !== SIMILAR_SEARCH_CRITERIA.SKILLS));
                        }
                    }}
                />
                <Checkbox
                    label='Current Location'
                    id='criteria2'
                    checked={isSimilarCriteriaChecked(SIMILAR_SEARCH_CRITERIA.LOCATION)}
                    onChange={(e) => {
                        if (e.target.checked) {
                            setSelectedCriterias([...selectedCriterias, SIMILAR_SEARCH_CRITERIA.LOCATION]);
                        } else {
                            setSelectedCriterias(selectedCriterias.filter(item => item !== SIMILAR_SEARCH_CRITERIA.LOCATION));
                        }
                    }}
                />
                {/* <Checkbox
                    label='Education History'
                    id='criteria3'
                    checked={isSimilarCriteriaChecked(SIMILAR_SEARCH_CRITERIA.EDUCATION)}
                    onChange={(e) => {
                        if (e.target.checked) {
                            setSelectedCriterias([...selectedCriterias, SIMILAR_SEARCH_CRITERIA.EDUCATION]);
                        } else {
                            setSelectedCriterias(selectedCriterias.filter(item => item !== SIMILAR_SEARCH_CRITERIA.EDUCATION));
                        }
                    }}
                />
                <Checkbox
                    label='Employment History'
                    id='criteria4'
                    checked={isSimilarCriteriaChecked(SIMILAR_SEARCH_CRITERIA.EMPLOYMENT)}
                    onChange={(e) => {
                        if (e.target.checked) {
                            setSelectedCriterias([...selectedCriterias, SIMILAR_SEARCH_CRITERIA.EMPLOYMENT]);
                        } else {
                            setSelectedCriterias(selectedCriterias.filter(item => item !== SIMILAR_SEARCH_CRITERIA.EMPLOYMENT));
                        }
                    }}
                /> */}
            </div>
        </AppModal>
    )
}

export default ModalShowSimilarCandidates;