import { useEffect, useState } from "react";
import "./ViewLater.scss";
import * as ApiHelper from "../../services/apiHelper";
import ViewLaterLayout from "./ViewLaterLayout";
import * as toast from "../../wrapper/toast";
import { isNullOrEmpty } from "../../utils/stringUtils";
import { AUTH } from "../../constants/storageConstants";
import { getLocalData, viewSimilarCandidates } from "../../services/common";

const ViewLater = () => {
  const auth = getLocalData(AUTH);
  const [loading, setLoading] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [currentCandidateId, setCurrentCandidateId] = useState();
  const [savedCandidates, setSavedCandidates] = useState([]);

  const [selectedCriterias, setSelectedCriterias] = useState([]);
  const [showSimilarCandidatesModal, setShowSimilarCandidatesModal] = useState(null);

  const isSimilarCriteriaChecked = (type) => {
    return selectedCriterias.includes(type);
  };

  const showSimilarSearch = () => {
    if (selectedCriterias.length < 1) {
      toast.warn("Please select at least one criteria");
      return;
    }

    const data = showSimilarCandidatesModal?.candidate;
    let newData = {};

    newData.skills = data?.skills?.length > 0 ? data?.skills : [];
    newData.location_id = data?.user_work_profile?.location_id;
    newData.full_name = data?.full_name;
    newData.id = data?.candidate_id;

    viewSimilarCandidates(newData, setLoading, isSimilarCriteriaChecked);
  }

  const handlePaginationChange = (e, { activePage }) => {
    getSavedCandidates(activePage);
  };

  useEffect(() => {
    getSavedCandidates(activePage);
  }, [activePage]);

  const getSavedCandidates = (activePage) => {
    const query = `?pageNumber=${isNullOrEmpty(activePage) ? 1 : activePage
      }&perPage=12`;
    setLoading(true);
    ApiHelper.getSavedCandidates(query).then((response) => {
      if (response.isSuccess === true) {
        setSavedCandidates(response?.data);
        setActivePage(isNullOrEmpty(activePage) ? 1 : activePage);
        setLoading(false);
      } else {
        toast.warn(response.message);
      }
      setLoading(false);
    });
  };

  const removeFromViewLater = (userId) => {
    setLoading(true);
    ApiHelper.deleteSavedCandidate(userId).then((response) => {
      if (response.isSuccess === true) {
        toast.success(response.message);
        getSavedCandidates(activePage);
      } else {
        toast.warn(response.message);
      }
      setLoading(false);
    });
  };

  const candidateProfile = (candidate_id) => {
    setCurrentCandidateId(candidate_id);
    if (auth) {
      window.open(
        "/workprofile/view/" + candidate_id + "?q=" + keyword,
        "_blank"
      );
    } else {
      setShowModal(true);
    }
  };

  return (
    <ViewLaterLayout
      loading={loading}
      setLoading={setLoading}
      savedCandidates={savedCandidates}
      keyword={keyword}
      removeFromViewLater={removeFromViewLater}
      handlePaginationChange={handlePaginationChange}
      setActivePage={setActivePage}
      activePage={activePage}
      showModal={showModal}
      setShowModal={setShowModal}
      currentCandidateId={currentCandidateId}
      candidateProfile={candidateProfile}
      selectedCriterias={selectedCriterias}
      setSelectedCriterias={setSelectedCriterias}
      showSimilarCandidatesModal={showSimilarCandidatesModal}
      setShowSimilarCandidatesModal={setShowSimilarCandidatesModal}
      isSimilarCriteriaChecked={isSimilarCriteriaChecked}
      showSimilarSearch={showSimilarSearch}
    />
  );
};

export default ViewLater;
