/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react'
import './Modal.scss';
import * as commonServices from '@/services/common';
import "react-datepicker/dist/react-datepicker.css";
import * as storageConstants from '@/constants/storageConstants';
import Editor from '../Common/WorkProfileEditor';
import * as erros from '@/constants/stringConstants';
import InputMask from 'react-input-mask';
import { isExist, isNotNullOrEmpty } from '@/utils/objectUtil';
import * as toast from "@/wrapper/toast";
import AppModal from './AppModal';
import DatePickerWrapper from '../Common/DatePicker';
import Input from '../_widgets/Input/Input';
import { isNullOrEmpty } from '../../utils/stringUtils';

function ModalEmp({ showModal, setShowModal, profile, setProfile, setDataAutoSave, type = "experience" }) {
    const [experience, setExperience] = useState([]);
    const [company] = useState([]);
    const [suggestions, setSuggestions] = useState([]);
    const datePicker1Ref = useRef();
    const datePicker2Ref = useRef();
    const datePicker3Ref = useRef();
    const [activeRow, setActiveRow] = useState(0);

    useEffect(() => {
        setExperience((profile?.userWorkProfileDetail && !isNullOrEmpty(profile?.userWorkProfileDetail[type])) ? profile.userWorkProfileDetail[type] : [{}]);
    }, [showModal, profile]);

    const onChange = (e, key) => {
        var tempArray = [...experience];
        tempArray[key][e.target.name] = e.target.value;
        setExperience(tempArray);
    }

    const changePresent = (e, key) => {
        let tempArray = []
        if (e.target.checked) {
            tempArray = [...experience];
            tempArray[key]['is_present'] = '1';
            tempArray[key]['to_date'] = null;
            setExperience(tempArray);
        } else {
            tempArray = [...experience];
            tempArray[key]['is_present'] = '0';
            setExperience(tempArray);
        }

    }

    const onCompanyChangeHandler = (text, key) => {
        var tempArray = [...experience];
        tempArray[key]['awarded_by'] = text;
        setExperience(tempArray);
        setActiveRow(key);
        let matches = [];
        if (text.length > 3) {
            matches = company.filter(company => {
                const regex = commonServices.getRegularExpression(text);
                return company.name.match(regex);
            })
        }

        setSuggestions(matches);
    }

    const addExperience = (e) => {
        var isError = false;
        experience && experience.map((element, key) => {
            var currentRow = key + 1;
            if (!experience[key].title) {
                toast.warn(erros.JOB_TITLE_ERROR + " at row " + currentRow);
                isError = true;
            } else if (!experience[key].awarded_by) {
                toast.warn(erros.EMPTY_COMPANY_ERROR + " at row " + currentRow);
                isError = true;
            } else if (!experience[key].from_date) {
                toast.warn(erros.EMPTY_FROM_DATE_ERROR + " at row " + currentRow);
                isError = true;
            }
            else if ((!experience[key].to_date && experience[key].is_present === '0') || (!experience[key].to_date && !experience[key].is_present)) {
                toast.warn(erros.EMPTY_TO_DATE_ERROR + " at row " + currentRow);
                isError = true;
            } else if (experience[key].is_present === '0' && new Date(experience[key].from_date) > new Date(experience[key].to_date)) {
                toast.warn(erros.EMPLOYMENT_FROM_TO_DATE_ERROR + " at row " + currentRow);
                isError = true;
            }

        });

        if (!isError) {
            var tempArray = { ...profile };
            if (tempArray.userWorkProfileDetail === undefined) {
                tempArray.userWorkProfileDetail = {};
            }
            tempArray.userWorkProfileDetail[type] = experience;
            setProfile(tempArray);
            setDataAutoSave(true);
            setShowModal(false);
        }

    }


    const deleteRow = async (e, key) => {
        e.preventDefault();
        var tempProfileArr = [...experience];
        tempProfileArr.splice(key, 1);
        setExperience(tempProfileArr);
    }

    return (

        <>

            <AppModal
                title={"Add " + (type === "experience" ? "Employment History" : "Internship")}
                isOpen={showModal}
                closeModal={() => setShowModal(false)}
                isFooter={true}
                size={"xl"}
                submit={addExperience}
                // scrollHidden
                scrollBehaviour="outside"
            >

                <div style={{ minHeight: "400px" }} className="modal_body">
                    <div className=''>
                        {experience && experience.map((element, key) => {

                            return <>
                                <div className="row" style={{ rowGap: "10px", marginBottom: "0" }}>
                                    <div className="col-12 col-sm-6 col-md-3">
                                        <Input maxLength={30} type="text" id="job_title" value={experience && experience[key] && experience[key].title ? experience[key].title : ""} placeholder="Job Title" name='title' onChange={(e) => onChange(e, key)} />
                                    </div>

                                    <div className="col-12 col-sm-6 col-md-3">
                                        <Input maxLength={30} label="Company / Employer" type="text" className="form-control" placeholder="Enter Company / Employer" value={experience && experience[key] && experience[key].awarded_by ? experience[key].awarded_by : ""} name='awarded_by' onChange={(e) => onCompanyChangeHandler(e.target.value, key)} />
                                    </div>

                                    <div className="col-12 col-sm-6 col-md-3 ">
                                        <DatePickerWrapper
                                            dateRef={datePicker1Ref}
                                            label="Worked From"
                                            customInput={<InputMask mask="99/99/9999" />}
                                            placeholderText="DD/MM/YYYY"
                                            selected={experience && experience[key] && experience[key].from_date ? new Date(experience[key].from_date) : null}
                                            onChange={(date) => {
                                                datePicker1Ref?.current?.setOpen(false);
                                                date.setDate(date.getDate() + 1);
                                                var fDate = date.toISOString().substring(0, 10).split('-').reverse();
                                                var tempArray = [...experience]; tempArray[key]['from_date'] = fDate[1] + '/' + fDate[0] + '/' + fDate[2];
                                                setExperience(tempArray);
                                            }}
                                            dateFormat="dd/MM/yyyy"
                                            maxDate={new Date()}
                                            showYearDropdown
                                            yearDropdownItemNumber={storageConstants.YEARS_TOTAL}
                                            scrollableYearDropdown />
                                    </div>

                                    <div className="col-12 col-sm-6 col-md-3 mb-0">
                                        {experience && experience[key].is_present && experience[key].is_present === "1" ?
                                            <div>

                                                <DatePickerWrapper
                                                    dateRef={datePicker2Ref}
                                                    label="Worked Till"
                                                    style={{ marginBottom: "0 !important" }}
                                                    customInput={<InputMask mask="99/99/9999" />}
                                                    selected={experience && experience[key].to_date ? new Date(experience[key].to_date) : null}
                                                    onChange={(date) => {
                                                        datePicker2Ref?.current?.setOpen(false);
                                                        date.setDate(date.getDate() + 1); var fDate = date.toISOString().substring(0, 10).split('-').reverse(); var tempArray = [...experience]; tempArray[key]['to_date'] = fDate[1] + '/' + fDate[0] + '/' + fDate[2]; tempArray[key]['is_present'] = '0'; setExperience(tempArray);
                                                    }}
                                                    dateFormat="dd/MM/yyyy"
                                                    minDate={experience && experience.from_date ? new Date(experience.from_date) : null} maxDate={new Date()} showYearDropdown yearDropdownItemNumber={storageConstants.YEARS_TOTAL} scrollableYearDropdown
                                                    disabled
                                                    placeholderText="Present"
                                                />
                                                <div className=" d-flex justify-content-start">
                                                    <input className='present-check' id={"c_b" + key} type="checkbox" onChange={(e) => changePresent(e, key)} checked={experience && experience[key].is_present && experience[key].is_present === "1" ? true : false} />
                                                    <label htmlFor={"c_b" + key} className="input-lebel3" >Present</label>
                                                </div>
                                            </div>
                                            :
                                            <div>

                                                <DatePickerWrapper
                                                    dateRef={datePicker3Ref}
                                                    label="Worked Till"
                                                    style={{ marginBottom: "0 !important" }}
                                                    customInput={<InputMask mask="99/99/9999" />}
                                                    selected={experience && experience[key].to_date ? new Date(experience[key].to_date) : null}
                                                    onChange={(date) => { datePicker3Ref?.current?.setOpen(false); date.setDate(date.getDate() + 1); var fDate = date.toISOString().substring(0, 10).split('-').reverse(); var tempArray = [...experience]; tempArray[key]['to_date'] = fDate[1] + '/' + fDate[0] + '/' + fDate[2]; tempArray[key]['is_present'] = '0'; setExperience(tempArray); }} dateFormat="dd/MM/yyyy"
                                                    placeholderText="DD/MM/YYYY" minDate={experience && experience[key].from_date ? new Date(experience[key].from_date) : null} maxDate={new Date()} showYearDropdown yearDropdownItemNumber={storageConstants.YEARS_TOTAL} scrollableYearDropdown
                                                />
                                                <div className=" d-flex justify-content-start">
                                                    <input className='present-check' id={"c_b" + key} type="checkbox" onChange={(e) => changePresent(e, key)} checked={experience && experience[key].is_present && experience[key].is_present === "1" ? true : false} />
                                                    <label htmlFor={"c_b" + key} className="input-lebel3" >Present</label>
                                                </div>
                                            </div>
                                        }
                                    </div>

                                </div>

                                {/* <div className="row mt-0">
                                    <div className="col d-flex justify-content-end">
                                        <input className='present-check' id={"c_b" + key} type="checkbox" onChange={(e) => changePresent(e, key)} checked={experience && experience[key].is_present && experience[key].is_present === "1" ? true : false} />
                                        <label htmlFor={"c_b" + key} className="input-lebel3" >Present</label>
                                    </div>
                                </div> */}

                                <div className="row">
                                    <div className="col">
                                        <label htmlFor="" className="input-lebel">
                                            Role / Responsibilities / Job-Profile / Project details
                                        </label>
                                        <Editor index={key} workprofileData={experience} setWorkprofileData={setExperience} element={element} />
                                    </div>
                                </div>
                                <div className='row mb-4'>
                                    <div className="col-md-2 col-6">
                                        <a className='delete-row' onClick={(e) => { deleteRow(e, key) }}> <img className='' src='../Assets/svgs/trash.svg'></img> Remove</a>
                                    </div>
                                    {key === experience.length - 1 ?

                                        <div className="col-md-3 col-6">
                                            <a className='add-more' onClick={() => { setExperience([...experience, {}]) }}> <img src='../Assets/svgs/plus_circle.svg'></img> Add More</a>
                                        </div>

                                        : ''}

                                    <div className="col"></div>
                                    <div className="col"></div>
                                    <div className="col"></div>
                                    <div className="col"></div>
                                    <div className="col"></div>
                                    <div className="col"></div>
                                </div>
                            </>
                        })}
                    </div>

                </div>
            </AppModal>


        </>
    )
}

export default ModalEmp
