import React, { useEffect, useState } from "react";
import "./Modal.scss";
import Dropdown from "../_widgets/Dropdown/Dropdown";
import AppModal from "./AppModal";
import * as ApiHelper from "@/services/apiHelper";
import * as toast from "@/wrapper/toast";
import { TARGET_USER_REQUIRED } from "../../constants/stringConstants";
import Text from "../_widgets/Text/Text";
import * as commonServices from "@/services/common";

function ModalDeactivateCompanyUser({
  showModal,
  setShowModal,
  user,
  users,
  targetDeactivateUser,
  setTargetDeactivateUser,
  setLoading,
  loading,
  getUsers,
}) {
  const [dropdownOptions, setDropdownOptions] = useState([]);
  useEffect(() => {
    if (showModal) {
      var options = users
        .map((usr) => {
          if (user?.id != usr?.id) return { id: usr?.id, name: usr?.full_name };
        })
        .filter((option) => option !== undefined);

      setDropdownOptions(options);
      setTargetDeactivateUser(null);
    }
  }, [showModal]);

  const onSubmit = async () => {
    if (targetDeactivateUser == null || targetDeactivateUser == "") {
      toast.warn(TARGET_USER_REQUIRED);
      return;
    }
    let userId = user?.id;
    const formData = new FormData();
    formData.append("user_id", userId);
    formData.append("targetDeactivateUser", targetDeactivateUser);
    formData.append("status", "");
    setLoading(true);
    await ApiHelper.updateCompanyUserStatus(formData).then((response) => {
      setLoading(false);
      if (response.isSuccess === true) {
        // getUsers();
        toast.success(response.message);
      } else {
        toast.warn(response.message);
      }
      setShowModal((prev) => !prev);
      getUsers();
    });
  };

  return (
    <>
      <AppModal
        isOpen={showModal}
        closeModal={() => {
          setShowModal((prev) => !prev);
        }}
        title={"Deactivate User - " + user?.full_name}
        size="lg"
        submit={onSubmit}
        isFooter={true}
        submitText={"Deactivate"}
        loading={loading}
      >
        <div className="modal_body">
          <div className="row">
            <Text
              type="h6"
              text={"<b>Total Jobs: </b>" + user?.jobs_count}
            ></Text>
          </div>
          <div className="row">
            <Text
              type="h6"
              text={"<b>Total Gigs: </b>" + user?.gigs_count}
            ></Text>
          </div>
          <div className="row">
            <Text
              type="h6"
              text={
                "<b>Pending TT Cash: </b>" +
                commonServices.currencyFormatter(user?.remaining_views)
              }
            ></Text>
          </div>
          <div className="row">
            <Text
              type="small"
              text="All Jobs, Gigs and Pending TT Cash including Expiring and Locked Cash will be assigned to selected user"
            ></Text>
          </div>
          <div className="row">
            <Dropdown
              isRequired={true}
              showDefaultOption={true}
              label={"Target User"}
              id="target_user"
              name="target_user"
              value={targetDeactivateUser}
              onChange={(e) => setTargetDeactivateUser(e.target.value)}
              select={dropdownOptions}
            />
          </div>
        </div>
      </AppModal>
    </>
  );
}

export default ModalDeactivateCompanyUser;
