import React from 'react';
import * as commonServices from '@/services/common';
import Card from '../../Common/Card';
import { createSlugFromString } from '@/utils/stringUtils';
import emptyResult from '@/Assets/svgs/empty_result.svg';

const Jobs = ({
    profile, Text
}) => {

    return (
        <div className="container" id="featured-3">
            <div className="job__card__section row row-cols-1">
                {profile?.jobs && profile?.jobs.length > 0 ? profile?.jobs.map((jobData, key) => {
                    return (<div onClick={() => window.open('/job/details/' + jobData.eid + '/' + createSlugFromString(jobData.title), '_blank')} className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-12 hand-hover" style={{ padding: '10px' }}>
                        <Card key={jobData.id} data={jobData} type={'job'} />
                    </div>)

                })
                    : <div className='no_jobs'><img onError={commonServices.imgError} src={emptyResult} alt="" /><Text type="h2" text="No job found!"></Text></div>
                }
            </div>
        </div>
    )
}

export default Jobs