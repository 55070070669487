import React, { useState, useEffect } from 'react'
import './Common.scss'
import accessDenied from '@/Assets/images/access_denied.png?1';
import noCash from '@/Assets/images/no-cash.png';
import CommonLayout from "./Common";
import { closeCurrentTab } from '@/utils/redirectUtil';

function AccessDenied() {
    var url_string = window.location.href;
    var url = new URL(url_string);
    var message = url.searchParams.get("message");
    return (
        <CommonLayout
            image={message !== null ? noCash : accessDenied}
            title={message !== null ? message : "Access Denied"}
            callBack={closeCurrentTab}
            buyTTCash={true} />
    )
}

export default AccessDenied