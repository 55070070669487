import * as storageConstants from '../constants/storageConstants';
import * as commonServices from '../services/common';

var user_role = commonServices.getLocalData(storageConstants.SESSION, storageConstants.USER_ROLE);
export function canAccessDashboard() {
    return (user_role === 'CA' || user_role === 'CU');
}

export function canUpdateCompanyProfile() {
    return (user_role === 'CA');
}