import React from 'react'
import { MaxWidthContainer } from '@Components/Common/MaxWidthContainer'
import ManageAvailabilty from './ManageAvailabilty'
import MyCalendar from './MyCalendar'
import { useSearchParams } from 'react-router-dom';

import Text from '../../_widgets/Text/Text';

const tab = [
    {
        id: 1,
        name: "My Calendar",
        component: MyCalendar
    },
    {
        id: 2,
        name: "Manage Availability",
        component: ManageAvailabilty
    }
]

const Calendar = () => {

    const [selectedTab, setSelectedTab] = React.useState(tab[0])
    const [currentQueryParameters, setSearchParams] = useSearchParams();


    React.useEffect(() => {
        if (currentQueryParameters.get("tab")) {
            const tabId = parseInt(currentQueryParameters.get("tab"))
            const selectedTab = tab.find((item) => item.id === tabId)
            if (selectedTab) {
                setSelectedTab(selectedTab)
            }
        }
    }, [currentQueryParameters])


    return (
        <MaxWidthContainer className="main-body">
            <div className='tt-container' style={{ fontSize: "13px" }}
            // fontSize={"13px"}
            >
                <div className='w-100 d-flex gap-4' style={{ marginBottom: "12px", gap: "1.5rem" }}>
                    {
                        tab.map((item) => (
                            <div
                                style={{
                                    borderBottom: selectedTab?.id === item.id ? "3px solid var(--green)" : "3px solid transparent",
                                    paddingTop: "0.5rem", // py: 2 * 0.25 = 0.5rem
                                    paddingRight: "0", // px: 0
                                    paddingBottom: "0.5rem", // py: 2 * 0.25 = 0.5rem
                                    paddingLeft: "0", // px: 0
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    setSelectedTab(item);
                                    setSearchParams({ tab: item.id });
                                }}
                            >
                                <Text
                                    text={item.name}
                                    style={{
                                        fontWeight: 500,
                                        fontSize: "1em",
                                        color: selectedTab?.id === item.id ? "#000" : "#A0AEC0"
                                    }}
                                />
                            </div>
                        ))
                    }
                </div>

                <div className='w-100'>
                    <selectedTab.component />
                </div>
            </div>
        </MaxWidthContainer>
    )
}

export default Calendar