/* eslint-disable */
import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import PartnerCompanyProfileLayout from './PartnerCompanyProfileLayout';


const PartnerCompanyProfile = ({ company }) => {

    let [loading] = useState(false);
    let [color] = useState("#000000");

    const [showCandidatesModal, setShowCandidatesModal] = useState(false);
    const [showCandidatesAppliedJobsModal, setShowCandidatesAppliedJobsModal] = useState(false);
    const [showCandidatesAppliedGigsModal, setShowCandidatesAppliedGigsModal] = useState(false);

    const [profile] = useState(company);


    const navigate = useNavigate();

    return (
        <PartnerCompanyProfileLayout
            loading={loading}
            color={color}
            showCandidatesModal={showCandidatesModal}
            setShowCandidatesModal={setShowCandidatesModal}
            showCandidatesAppliedJobsModal={showCandidatesAppliedJobsModal}
            setShowCandidatesAppliedJobsModal={setShowCandidatesAppliedJobsModal}
            showCandidatesAppliedGigsModal={showCandidatesAppliedGigsModal}
            setShowCandidatesAppliedGigsModal={setShowCandidatesAppliedGigsModal}
            profile={profile}
            navigate={navigate}
        />
    )
}

export default PartnerCompanyProfile