import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import sampleResume1 from "../../Assets/images/sample-resume-1.png";
import sampleResume2 from "../../Assets/images/sample-resume-2.png";
import sampleResume3 from "../../Assets/images/sample-resume-3.png";
import sampleResume4 from "../../Assets/images/sample-resume-4.png";
import * as toast from "@/wrapper/toast";
import AppModal from "./AppModal";

function ModalSelectResumeTemplate({ showModal, setShowModal }) {
  const navigate = useNavigate();
  const [template, setTemplate] = useState(0);
  const sampleResumes = [
    sampleResume1,
    sampleResume2,
    sampleResume3,
    sampleResume4,
  ];
  const navigateUser = (selectedTemplate) => {
    if (selectedTemplate === 0) {
      toast.error("Please select a template");
      return;
    }
    navigate("/candidate/resume?template=" + selectedTemplate);
    setShowModal(false);
  };

  return (
    <AppModal
      isOpen={showModal}
      closeModal={() => setShowModal((prev) => !prev)}
      title={"Resume Templates"}
      size="xl"
    >
      <div className="resume-builder-info" style={{ paddingTop: "0px" }}>
        <div className="container">
          <div className="body1">
            <div className="card_sec1">
              <div className="card1">
                <div className="emp-section my-3">
                  <div className="section-first">
                    <div className="row mt-3">
                      <div className="col-md-12">
                        <h3 className="text-center">Select a Template</h3>
                        <div className="row my-3">
                          {sampleResumes.map((resume, index) => (
                            <div
                              key={index}
                              className="col-3 hand-hover resume-card"
                              onClick={() => {
                                setTemplate(index + 1);
                                navigateUser(index + 1);
                              }}
                            >
                              <div
                                style={{ width: "80%" }}
                                className={
                                  template === index + 1
                                    ? "card active"
                                    : "card"
                                }
                              >
                                <img
                                  className="sample-resume"
                                  src={resume}
                                  alt={`Card image ${index + 1}`}
                                />
                                <div className="hover-content">
                                  <div className="use-this">Use this</div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AppModal>
  );
}

export default ModalSelectResumeTemplate;
