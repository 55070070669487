import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

export function initiateFirebase() {
    firebase.initializeApp(firebaseConfig);
}

export function handleSendOTP(phoneNumber, handleSendOTPSucess, handleSendOTPFail) {
    const phoneNumberWithCountryCode = phoneNumber;

    if (window.recaptchaVerifier === undefined) {
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
            size: "invisible"
        });
    }

    firebase.auth().signInWithPhoneNumber(phoneNumberWithCountryCode, window.recaptchaVerifier)
        .then((confirmationResult) => {
            handleSendOTPSucess(confirmationResult);
        })
        .catch((error) => {

            console.error('Error sending OTP:', error);
            handleSendOTPFail();
        });
}

export function handleVerifyOTP(confirmationResult, verificationCode, verificationSuccess, verificationFailure) {
    const credential = firebase.auth.PhoneAuthProvider.credential(confirmationResult.verificationId, verificationCode);
    firebase.auth().signInWithCredential(credential)
        .then((userCredential) => {
            if (verificationSuccess !== undefined) {
                verificationSuccess();
            }
        })
        .catch((error) => {
            console.error('Error verifying OTP:', error);
            if (verificationFailure !== undefined) {
                verificationFailure();
            }
        });
}