import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as toast from "@/wrapper/toast";
import AppModal from "./AppModal";
import OneTemplate from "../ResumeBuilder/templates/OneTemplate";
import SecondTemplate from "../ResumeBuilder/templates/secondTemplate";
import ThirdTemplate from "../ResumeBuilder/templates/thirdTemplate";
import FourTemplate from "../ResumeBuilder/templates/fourTemplate";
import Loader from "@/components/Common/Loader";
import * as ApiHelper from "@/services/apiHelper";

function ModalResumeWithAI({
  showModal,
  setShowModal,
  profile,
  setProfile,
  template,
  components,
  localStates,
  customSections,
  downloadType,
  styles,
  componentRef,
}) {
  const [loading, setLoading] = useState(false);
  const [profileByAI, setProfileByAI] = useState({});

  const getResumeByAI = async () => {
    setLoading(true);
    await ApiHelper.resumeByAI(profile).then((response) => {
      if (response.isSuccess === true) {
        setLoading(false);
        setProfileByAI(response?.data);
      } else {
        setLoading(false);
        toast.warn(response.message);
        closeModal();
      }
    });
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const useMyVersion = () => {
    closeModal();
  };

  const useAIVersion = () => {
    setProfile(profileByAI);
    closeModal();
  };

  useEffect(() => {
    if (showModal) {
      getResumeByAI();
    }
  }, [showModal]);

  return (
    <AppModal
      isOpen={showModal}
      title={"Resume generated by AI"}
      size="xl"
      isFooter={true}
      submitText="Use AI Version"
      submit={useAIVersion}
      cancelText="Use My Version"
      closeModal={useMyVersion}
    >
      {loading && <Loader />}
      <div
        size="A4"
        style={{ ...styles.page, marginTop: "6px", minHeight: "700px" }}
        wrap={true}
      >
        <div
          id="resume-to-print"
          ref={componentRef}
          wrap={true}
          style={{
            backgroundColor: "white",
            width: "100%",
            height: "fit-content",
            minHeight: "700px",
            position: "relative",
          }}
        >
          {template === 1 ? (
            <OneTemplate
              profile={profileByAI}
              components={components}
              localStates={localStates}
              customSections={customSections}
              downloadType={downloadType}
            />
          ) : template === 2 ? (
            <SecondTemplate
              profile={profileByAI}
              components={components}
              localStates={localStates}
              customSections={customSections}
              downloadType={downloadType}
            />
          ) : template === 3 ? (
            <ThirdTemplate
              profile={profileByAI}
              components={components}
              localStates={localStates}
              customSections={customSections}
              downloadType={downloadType}
            />
          ) : template === 4 ? (
            <FourTemplate
              profile={profileByAI}
              components={components}
              localStates={localStates}
              customSections={customSections}
              downloadType={downloadType}
            />
          ) : // Handle other template cases or provide a default component
          null}
        </div>
      </div>
    </AppModal>
  );
}

export default ModalResumeWithAI;
