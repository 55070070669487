import React from "react";
import "./Steps.scss";
import underReviewImg from "../../../../Assets/images/under-review.png"
import { MaxWidthContainer } from "../../../Common/MaxWidthContainer";
import Text from "../../../_widgets/Text/Text";

export default function ProfileInReview() {

    return (

        <MaxWidthContainer className="main-body">
            <div className="under-review">
                    <img src={underReviewImg} style={{
                        margin: "0 auto"
                    }} alt="under-review-img" />
                    <Text text="Profile Verification in Progress" type={"h2"} className="my-3"></Text>
                    <Text text="Thank you for submitting your profile to TrueTalent. A member of our team will reach out to you in the next 48 hours to help you onboard our platform." type="h7"></Text>
            </div>
        </MaxWidthContainer>

    )
}