import React from 'react';
import { css } from "@emotion/react";
import * as commonServices from '@/services/common';
import defaultAvatar from '@/Assets/svgs/default_avatar.svg';
import { textCapitalise } from "@/utils/stringUtils";
import emptyResult from '@/Assets/svgs/empty_result.svg';

const Recruiters = ({
    profile,
    recruiterProfile,
    Text
}) => {

    return (
        <div className="container" id="featured-3">
            <div className="row" style={{ gridColumnGap: "10px" }}>
                {profile?.recruiters && profile?.recruiters.length > 0 ? profile?.recruiters.map((data, key) => {
                    return <div className="feature recruiter-feature box-hover col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={key}>
                        <div className="feature_content hand-hover recruiter-content" onClick={() => recruiterProfile(data.id)}>
                            <div className='row'>
                                <div className='col-3'>
                                    <img src={profile.avatar_location ?? defaultAvatar} alt="" />
                                </div>
                                <div className='col-9'>
                                    <Text text={textCapitalise(data.full_name)} className="paragraph_1 truncate-1 name" title={textCapitalise(data.full_name)}></Text>
                                    {/* <p className="paragraph_3">Exp: {getCandidateExperienceText(data.total_experience)}</p> */}

                                    {/* <p className='paragraph_3 d-flex gap-1' style={{ color: '#4B8BFF' }}> <img style={{ height: "12px" }} src={mapPin} alt="" /> <span className='truncate-1' title={'Current Location'}>{data?.location_name ? data?.location_name : "NA"}</span></p> */}
                                    <button className='jobs-count'>{data?.jobs_count} jobs</button>
                                    <button className='jobs-count' style={{ marginLeft: "5px" }}>{data?.gigs_count} gigs</button>
                                </div>
                            </div>
                        </div>


                    </div>

                })
                    : <div className='no_jobs'><img onError={commonServices.imgError} src={emptyResult} alt="" /><Text type="h2" text="No recruiter found!"></Text></div>
                }
            </div>
        </div>
    )
}

export default Recruiters