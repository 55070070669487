import DatePicker from 'react-datepicker';
import { DATEPICKER_PLACEHOLDER } from '@/constants/stringConstants';
import * as commonServices from '@/services/common';
import * as storageConstants from '@/constants/storageConstants';
import { addMultipleSearchParamsInHistory } from '@/utils/redirectUtil';
import IconResource from '../_widgets/IconResource/IconResource';
import { changeDateFormat } from '../../utils/dateUtil';
import Button from "../_widgets/Button/Button"

function DateRangePicker({ startDate, showDateShortcuts, setStartDate, endDate, setEndDate, onChange, datePickerStartRef, datePickerEndRef, isDateFilter, setIsDateFilter, usedInSearchComp }) {

    const handleStartDateChange = (date) => {
        usedInSearchComp ? setStartDate('start_date', date) : setStartDate(date);
        datePickerStartRef?.current.setOpen(false);
        if (endDate !== null) {
            addMultipleSearchParamsInHistory(['start_date', 'end_date'], [changeDateFormat(date, 'YYYY-MM-DD'), changeDateFormat(endDate, 'YYYY-MM-DD')]);
            setIsDateFilter && setIsDateFilter(!isDateFilter);
        }
        datePickerEndRef?.current.setOpen(true);
    };

    const handleEndDateChange = (date) => {
        usedInSearchComp ? setEndDate('end_date', date) : setEndDate(date);
        datePickerEndRef?.current.setOpen(false);
        addMultipleSearchParamsInHistory(['start_date', 'end_date'], [changeDateFormat(startDate, 'YYYY-MM-DD'), changeDateFormat(date, 'YYYY-MM-DD')]);
        setIsDateFilter && setIsDateFilter(!isDateFilter);
    };

    const ShortcutType = {
        'This Month': {
            key: 'THIS_MONTH',
            displayValue: 'This Month'
        },
        'Last Month': {
            key: 'LAST_MONTH',
            displayValue: 'Last Month'
        },
        'This Year': {
            key: 'THIS_YEAR',
            displayValue: 'This Year'
        },
        'Last Year': {
            key: 'LAST_YEAR',
            displayValue: 'Last Year'
        }
    };

    // Dynamically create key-value pairs for shortcut types
    const shortcutTypes = {};
    for (const key in ShortcutType) {
        if (ShortcutType.hasOwnProperty(key)) {
            shortcutTypes[ShortcutType[key].displayValue] = ShortcutType[key].key;
        }
    }

    const handleShortcutClick = (shortcut, e) => {
        const today = new Date();
        let start, end;
        e.preventDefault()
        switch (shortcut) {
            case ShortcutType['This Month'].displayValue:
                start = new Date(today.getFullYear(), today.getMonth(), 1);
                end = new Date(today.getFullYear(), today.getMonth() + 1, 0);
                break;
            case ShortcutType['Last Month'].displayValue:
                start = new Date(today.getFullYear(), today.getMonth() - 1, 1);
                end = new Date(today.getFullYear(), today.getMonth(), 0);
                break;
            case ShortcutType['This Year'].displayValue:
                start = new Date(today.getFullYear(), 0, 1);
                end = new Date(today.getFullYear(), 11, 31);
                break;
            case ShortcutType['Last Year'].displayValue:
                start = new Date(today.getFullYear() - 1, 0, 1);
                end = new Date(today.getFullYear() - 1, 11, 31);
                break;
            default:
                return;
        }

        onChange(start, end);
        addMultipleSearchParamsInHistory(['start_date', 'end_date'], [changeDateFormat(start, 'YYYY-MM-DD'), changeDateFormat(end, 'YYYY-MM-DD')]);
        setIsDateFilter && setIsDateFilter(!isDateFilter);
    };

    return (
        <div>
            <div className="d-flex align-items-center gap-2">
                <div className="form-input-group w-100">
                    <div className="input-group flex-nowrap">
                        <span className="input-group-text" id="basic-addon1">
                            <IconResource color="var(--green)" fontSize={'20px'} onError={commonServices.imgError} type="calendar" />
                        </span>
                        <DatePicker
                            ref={datePickerStartRef}
                            selected={startDate}
                            onChange={handleStartDateChange}
                            selectsStart
                            startDate={startDate}
                            endDate={endDate}
                            maxDate={new Date()}
                            placeholderText={DATEPICKER_PLACEHOLDER}
                            dateFormat="dd/MM/yyyy"
                            showMonthDropdown
                            scrollableMonthYearDropdown
                            showYearDropdown
                            yearDropdownItemNumber={storageConstants.YEARS_TOTAL}
                            scrollableYearDropdown
                        />
                    </div>
                </div>
                <IconResource type="rightArrow" fontSize={"40px"} color={"var(--gray4)"} />
                <div className="form-input-group w-100">
                    <div className="input-group flex-nowrap">
                        <span className="input-group-text" id="basic-addon1">
                            <IconResource color="var(--green)" fontSize={'20px'} onError={commonServices.imgError} type="calendar" />
                        </span>
                        <DatePicker
                            ref={datePickerEndRef}
                            selected={endDate}
                            onChange={handleEndDateChange}
                            selectsEnd
                            endDate={endDate}
                            startDate={startDate}
                            minDate={startDate}
                            maxDate={new Date()}
                            placeholderText={DATEPICKER_PLACEHOLDER}
                            dateFormat="dd/MM/yyyy"
                            showMonthDropdown
                            scrollableMonthYearDropdown
                            showYearDropdown
                            yearDropdownItemNumber={storageConstants.YEARS_TOTAL}
                            scrollableYearDropdown
                        />
                    </div>
                </div>
            </div>
            {showDateShortcuts ? <div className='d-flex align-items-center justify-content-center '>
                <Button type="text" style={{ fontSize: "var(--p0)", padding: "10px 12px" }} text="This Month" onClick={(e) => handleShortcutClick('This Month', e)} /> |
                <Button type="text" style={{ fontSize: "var(--p0)", padding: "10px 12px" }} text="Last Month" onClick={(e) => handleShortcutClick('Last Month', e)} /> |
                <Button type="text" style={{ fontSize: "var(--p0)", padding: "10px 12px" }} text="This Year" onClick={(e) => handleShortcutClick('This Year', e)} /> |
                <Button type="text" style={{ fontSize: "var(--p0)", padding: "10px 12px" }} text="Last Year" onClick={(e) => handleShortcutClick('Last Year', e)} />
            </div> : null}
        </div>
    );
}

export default DateRangePicker;
